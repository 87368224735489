import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { PnkHtmlForm } from '../pnk-react/pnk-controls/pnk-html-form';
import DatePicker from "react-datepicker";
import { FilterPage } from './filter'
import AppUtils from '../apputils'
import "react-datepicker/dist/react-datepicker.css";
export default class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showFilterWindow: false,
            startDate: new Date()
        }
    }
    ShowFilter = () => {
        document.getElementById("searchBar").style.zIndex = 9999
        document.getElementById("search-icon-header").style.top = '18px'
        this.setState({ showFilterWindow: true })
    }
    HideFilter = () => {
        document.getElementById("searchBar").style.zIndex = ''
        document.getElementById("search-icon-header").style.top = '30px'
        this.setState({ showFilterWindow: false })
    }
    HandleChange = date => {
        this.setState({
            startDate: date
        });
    };
    HandleRoute = (route) => {
        AppUtils.HandlePageClick(this.props, route);
    }

    CallMethod = () => {
        this.props.CallMethod();
    }

    render() {

        return (
            <div className="searchBar" id="searchBar">

                <Form.Control className="header-search hide-mob" placeholder="Search by Lead, Booking or Property Master" value="" onClick={() => this.ShowFilter()} />
                <FontAwesomeIcon icon={faSearch} className="search-icon-header" id="search-icon-header" />
                {this.state.showFilterWindow === true ? (
                    <div className="filter-div">
                        <FilterPage HideFilter={this.HideFilter} CallMethod={this.props.CallMethod} HandleChange={this.HandleChange} mystate={this.state} HandleRoute={this.props.HandleRoute} />
                    </div>
                ) : null
                }

            </div>
        )
    }
}





