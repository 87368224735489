import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Form, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import CsvDownload from 'react-json-to-csv'

export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {

        this.props.SortData(e, e.target.value)
    }
    render() {
        console.log("profile", this.props.PnkStore.GetData("profilelist").rows)
        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div mob-action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                            {this.props.mystate.currentChoice !== 'meeting-tracker' && this.props.mystate.currentChoice !== "callback" && this.props.mystate.currentChoice !== "patch-report" && this.props.mystate.currentChoice !== "campaign-report" && this.props.mystate.currentChoice !== "lead-tracking-report" ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0 padding-form">


                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlPreferredCity'
                                        id='ddlPreferredCity'
                                        onChange={this.props.HandleUserInput}
                                        pnkdisplayerrormsg='Select City'
                                        value={this.props.mystate.ddlPreferredCity}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="-1" disabled selected>Select City</option>
                                        <option value=''>No City</option>
                                        {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                            this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                                <option value={item.city_name}>{item.city_name}</option>) : null
                                        }

                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice === 'sales-meeting-count' || (this.props.mystate.currentChoice === 'patch-report' && this.props.mystate.patchView === 'default') ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlHcSalesUser'
                                        id='ddlHcSalesUser'
                                        onChange={this.props.HandleUserInput}
                                        value={this.props.mystate.ddlHcSalesUser}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="-1" disabled selected>Sales</option>
                                        <option value=""  >All</option>
                                        {this.props.PnkStore.GetData('userlist') && this.props.PnkStore.GetData("userlist") && this.props.PnkStore.GetData("userlist").rows &&
                                            this.props.PnkStore.GetData("userlist").rows.length > 0
                                            ? this.props.PnkStore.GetData("userlist").rows.map(
                                                (item, i) => (
                                                    item.user_profile[0].profile_name.includes('Sales') === true ?
                                                        <option value={item.user_name} >{item.user_name}</option> : ""
                                                )) : ""}
                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice === 'patch-report' && this.props.mystate.patchView === 'default' ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlTelecallingUser'
                                        id='ddlTelecallingUser'
                                        onChange={this.props.HandleUserInput}
                                        value={this.props.mystate.ddlTelecallingUser}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="-1" disabled selected>Telecalling</option>
                                        <option value="" >All</option>
                                        {this.props.PnkStore.GetData('userlist') && this.props.PnkStore.GetData("userlist") && this.props.PnkStore.GetData("userlist").rows &&
                                            this.props.PnkStore.GetData("userlist").rows.length > 0
                                            ? this.props.PnkStore.GetData("userlist").rows.map(
                                                (item, i) => (
                                                    item.user_profile[0].profile_name.includes('Telecalling') === true || item.user_profile[0].profile_name.includes('Administrator') === true ?
                                                        <option value={item.user_name} >{item.user_name}</option> : ""
                                                )) : ""}
                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice === "design-tracker" ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlCurrentDesigner'
                                        id='ddlCurrentDesigner'
                                        onChange={this.props.HandleUserInput}
                                        value={this.props.mystate.ddlCurrentDesigner}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="" disabled selected>Designer</option>
                                        {this.props.PnkStore.GetData('userlist') && this.props.PnkStore.GetData("userlist") && this.props.PnkStore.GetData("userlist").rows &&
                                            this.props.PnkStore.GetData("userlist").rows.length > 0
                                            ? this.props.PnkStore.GetData("userlist").rows.map(
                                                (item, i) => (
                                                    item.user_profile[0].profile_name.includes('Designer') === true ?
                                                        <option value={item.user_name} >{item.user_name}</option> : ""
                                                )) : ""}
                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice === "callback" ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlCurrentCallBackBprofile'
                                        id='ddlCurrentCallBackBprofile'
                                        onChange={this.props.HandleUserInput}

                                        value={this.props.mystate.ddlCurrentCallBackBprofile}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="" disabled selected>Profiles</option>
                                        {this.props.PnkStore.GetData('profilelist') && this.props.PnkStore.GetData("profilelist") && this.props.PnkStore.GetData("profilelist").rows &&
                                            this.props.PnkStore.GetData("profilelist").rows.length > 0
                                            ? this.props.PnkStore.GetData("profilelist").rows.map(
                                                (item, i) => (

                                                    item.profile_name === 'Administrator' || item.profile_name === "Telecalling" || item.profile_name === "Sales TL" || item.profile_name === "Sales" ?
                                                        <option value={item.profile_name} id={item._id} >{item.profile_name}</option>
                                                        : ""
                                                )) : ""}
                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice === "callback" && this.props.mystate.userListByProfile && this.props.mystate.userListByProfile.rows && this.props.mystate.userListByProfile.rows.length > 0 ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlcurrentCallBackUser'
                                        id='ddlcurrentCallBackUser'
                                        onChange={this.props.HandleUserInput}

                                        value={this.props.mystate.ddlcurrentCallBackUser}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="" disabled selected>Users</option>

                                        {this.props.mystate.userListByProfile.rows.map(
                                            (item, i) => (

                                                <option value={item.user_name} >{item.user_name}</option>)
                                        )}
                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice !== "callback" ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0 show-mob  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='currentSalesReportType'
                                        id='currentSalesReportType'
                                        value={this.props.mystate.currentSalesReportType}
                                        style={{ padding: '4px', width: '100%' }}
                                        onChange={(e) => this.props.ChangeSalesReportTypeMobile(e)}>
                                        <option value="" disabled selected>Select</option>
                                        {this.props.salesReportType.map(item =>
                                            <option value={item} >{item}</option>)}
                                    </Form.Control>

                                </Col> :
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0 show-mob  padding-form">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='currentSalesReportType'
                                        id='currentSalesReportType'
                                        value={this.props.mystate.currentSalesReportType}
                                        style={{ padding: '4px', width: '100%' }}
                                        onChange={(e) => this.props.ChangeSalesReportTypeMobile(e)}>
                                        <option value="" disabled selected>Select</option>
                                        {this.props.callBackReportType.map(item =>
                                            <option value={item} >{item}</option>)}
                                    </Form.Control>

                                </Col>
                            }
                            {this.props.mystate.currentChoice === "inside-sales-report" ?

                                <Col lg={1} xs={3} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.currentInsideSalesType === "default" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleInsideSalesOptions('default')}>Default</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleInsideSalesOptions('default')}>Default</Button>}


                                </Col> : null}
                            {this.props.mystate.currentChoice === "inside-sales-report" ?

                                <Col lg={1} xs={3} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.currentInsideSalesType === "dead" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleInsideSalesOptions('dead')}>Dead</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleInsideSalesOptions('dead')}>Dead</Button>}


                                </Col> : null}
                            {this.props.mystate.currentChoice === "inside-sales-report" ?
                                <Col lg={1} xs={3} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.currentInsideSalesType === "pending" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleInsideSalesOptions('pending')}>Pending</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleInsideSalesOptions('pending')}>Pending</Button>
                                    }

                                </Col> : null}
                            {/* {this.props.mystate.currentChoice === "HC-PM Schedule"  ?

                                <Col lg={1} xs={2} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.hcPmScheduleType === "hc" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('hc')}>HC</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('hc')}>HC</Button>}


                                </Col> : null}
                            {this.props.mystate.currentChoice === "HC-PM Schedule"  ?
                                <Col lg={2} xs={4} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.hcPmScheduleType === "project-meeting" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('project-meeting')}>Project Meeting</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('project-meeting')}>Project Meeting</Button>
                                    }

                                </Col> : null}
                                {this.props.mystate.currentChoice === "HC-PM Schedule"  ?
                                <Col lg={2} xs={4} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.hcPmScheduleType === "vc" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('vc')}>VC</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('vc')}>VC</Button>
                                    }

                                </Col> : null} */}
                            {/* { this.props.mystate.currentChoice==='meeting-tracker' ?

                                <Col lg={1} xs={2} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.hcPmScheduleType === "hc" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleMeetingType('hc')}>HC</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleMeetingType('hc')}>HC</Button>}


                                </Col> : null} */}
                            {/* {  this.props.mystate.currentChoice==='meeting-tracker' ?
                                <Col lg={2} xs={4} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.hcPmScheduleType === "project-meeting" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleMeetingType('project-meeting')}>Project Meeting</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleMeetingType('project-meeting')}>Project Meeting</Button>
                                    }

                                </Col> : null} */}
                            {/* { this.props.mystate.currentChoice==='meeting-tracker' ?
                                <Col lg={2} xs={4} className="flex-left col-padding-0  padding-form">
                                    {this.props.mystate.hcPmScheduleType === "vc" ?
                                        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleMeetingType('vc')}>VC</Button> :
                                        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleMeetingType('vc')}>VC</Button>
                                    }

                                </Col> : null} */}





                            {/* meeting */}


                            {/* <Col lg={2} md={3} xs={3} className="flex-left col-padding-0">
                                <CsvDownload 
    data={this.props.mystate.reportData}
    filename="good_data.csv"
    style={{ //pass other props, like styles
      boxShadow:"inset 0px 1px 0px 0px #e184f3",
      background:"linear-gradient(to bottom, #c123de 5%, #a20dbd 100%)",
      backgroundColor:"#c123de",
      borderRadius:"6px",
      border:"1px solid #a511c0",
      display:"inline-block",
      cursor:"pointer","color":"#ffffff",
      fontSize:"15px",
      fontWeight:"bold",
      padding:"6px 24px",
      textDecoration:"none",
      textShadow:"0px 1px 0px #9b14b3"
      }}
  >
   Download Report
  </CsvDownload>
                            </Col> */}
                            {this.props.mystate.currentChoice === 'patch-report' && this.props.mystate.patchView === 'details' ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <button className='btn btn-secondary' onClick={() => this.props.PatchReportDefaultView()}>Go Back</button></Col> : null
                            }
                            {this.props.mystate.currentChoice === 'campaign-report' && this.props.mystate.lead_campaign_view === 'details' ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <button className='btn btn-secondary' onClick={() => this.props.CampaignReportDefaultView()}>Go Back</button></Col> : null
                            }
                            {this.props.mystate.currentChoice === 'patch-report' ?
                                this.props.mystate.patchView === 'details' ?
                                    <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                        <Form.Control as="select" className="dashboard-option-select"
                                            name='ddlCurrentLeadStage'
                                            id='ddlCurrentLeadStage'
                                            onChange={this.props.HandleUserInput}
                                            value={this.props.mystate.ddlCurrentLeadStage}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected>Lead Stages</option>
                                            {this.props.mystate.profileStageData &&
                                                this.props.mystate.profileStageData.length > 0
                                                ? this.props.mystate.profileStageData.map(
                                                    (item, i) => (

                                                        <option value={item} >{item}</option>
                                                    )) : ""}
                                        </Form.Control>

                                    </Col>
                                    : null : null
                            }


                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}