import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Form, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Reload from '../../assets/images/reload.svg'
import AppUtils from '../../apputils';
export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }
    GoToEntry = () => {
        AppUtils.HandlePageClick(this.props.componentprops, 'entry/' + this.props.mystate.currentChoice)
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {
       
        this.props.SortData(e, e.target.value)
    }
    render() {
       


        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row className="entry-row" >

                            <Col lg={2} md={3} className="flex-left col-padding-0 hide-mob">
                                {sessionStorage.getItem('current_user_allowed_routes').includes('Lead Entry') === true ?
                                    <div className="btn-div">
                                        <Button type="button" className="add-button" onClick={() => this.GoToEntry()}>+ Add List</Button>
                                    </div> : null}
                            </Col>
                            {this.props.mystate.currentChoice === "area" ?
                                <Col lg={2} md={3} xs={6} className="flex-left  col-padding-0">


                                    <Form.Control as="select" className="entry-option-select"
                                        name='ddlPreferredCity'
                                        id='ddlPreferredCity'
                                        onChange={this.props.HandleUserInput}
                                        pnkdisplayerrormsg='Select City'
                                        value={this.props.mystate.ddlPreferredCity}

                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="" disabled selected>City</option>
                                        {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                            this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                                <option value={item.city_name}>{item.city_name}</option>) : null
                                        }

                                    </Form.Control>

                                </Col> : null}
                            {this.props.mystate.currentChoice === "area" ?
                                <Col lg={3} md={3} className="col-padding-0 hide-mob">
                                    <InputGroup className="profile-search-box">
                                        <FormControl
                                            placeholder="Search by Area Name"
                                            className="profile-search-box"
                                            name="txtSearchAreaKey" id="txtSearchAreaKey"
                                            value={this.props.mystate.txtSearchAreaKey}
                                            onChange={this.props.HandleUserSearch}

                                        />
                                        <InputGroup.Append >
                                            <InputGroup.Text id="basic-addon2" className="profile-search-text search-icon-lead">
                                                {/* <Button variant="light"> */}
                                                <FontAwesomeIcon icon={faSearch} className="profile-search-icon" />
                                                {/* </Button> */}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col> : null}
                            <Col lg={4} md={1} className="hide-mob"></Col>
                            <Col lg={1} md={2} xs={6}>
                                <Image src={Reload} className="reload-img" onClick={() => this.props.Reload()} />
                            </Col>


                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}