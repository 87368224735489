import React from 'react'
import { Modal, Form, Button, Image } from 'react-bootstrap'
import CallNow from '../../assets/images/callnow.gif'
export default class CancelModal extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
       
        return (

            <div className="">
                <div className="rem-img-div">
                    <Image src={CallNow} className="callnow-img" />
                </div>
                <h3 className="rem-modal-head-text">You Have One Call Back Reminder</h3>
                <h3 className="rem-modal-sub-text">Please Go The Lead By Clicking On OK Button</h3>
                <div className="rem-modal-btn-div">
                    <Button variant="light" className="rem-modal-cancel-btn mr-3" onClick={() => this.props.HideCancel()} >Cancel</Button>
                    <Button variant="light" className="rem-modal-save " onClick={() => this.props.HandlePressYes('leads')}>Ok</Button>


                </div>
            </div>

        )
    }
} 