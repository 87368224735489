import React from 'react'
import { Row, Col, Form, Image, } from 'react-bootstrap'
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '../Pagination'
import PnkImage from '../../pnk-react/pnk-ctrl/pnk-image';


export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            selectAll: false,

            checkedC: true,
            showProfileEntry: false,
            numItemsPerPage: 10,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 0


        }
        this.target = React.createRef()
    }
    componentDidMount = () => {
        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })


    }
    SelectAll = () => {
        this.setState({
            selectAll: true
        })
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };
    ChangePage = (number) => {
       
        if (number - 1 === 0) {
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage) });
        }
        else {
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage) });
        }
    }

    render() {
       
        let totalPages = 0; let length = 0;
        if (this.props.LeadSourceData && this.props.LeadSourceData.rows !== undefined && this.props.LeadSourceData.rows.length > 0) {
            length = this.props.LeadSourceData.rows.length
            totalPages = Math.ceil(length / this.state.numItemsPerPage);
        }


        const AntSwitch = withStyles((theme) => ({
            root: {
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.grey[500],
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: ' #5d8614',
                        borderColor: '#5d8614',
                    },
                },
            },
            thumb: {
                width: 12,
                height: 12,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
        }))(Switch);
        let background = '';
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">

                                    <Row className="mob-row-align">

                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                        </Col>

                                        <Col lg={3} xs={3}>
                                            <h3 className="table-head-lead ">Source Name</h3>
                                        </Col>
                                        {/* <Col xs={5} className="show-mob">
                                            <h3 className="table-head-lead ">User Details</h3>
                                        </Col> */}
                                        <Col lg={2} xs={3}>
                                            <h3 className="table-head-lead">Source Image</h3>
                                        </Col>
                                        {/* <Col lg={2} className="hide-mob">
                                            <h3 className="table-head-lead ">Default Assignee</h3>
                                        </Col>
                                        <Col lg={2} xs={3} className="">
                                            <h3 className="table-head-lead">Mail Forward To</h3>
                                        </Col> */}
                                        <Col lg={2} xs={3} className="hide-mob">
                                            <h3 className="table-head-lead">Create At</h3>
                                        </Col>
                                        <Col lg={2} xs={3} className="">
                                            <h3 className="table-head-lead">Current State </h3>
                                        </Col>

                                        <Col lg={2} className="" xs={3}>
                                            <h3 className="table-head-lead">Action</h3>
                                        </Col>

                                    </Row>
                                    <div>
                                        {this.props.LeadSourceData && this.props.LeadSourceData.rows !== undefined && this.props.LeadSourceData.rows.length > 0
                                            ? this.props.LeadSourceData.rows.map(
                                                (item, i) => (


                                                    <div className="lead-table-data-user">
                                                        {i >= this.state.startValue && i < this.state.endValue ?

                                                            <Row className="table-items-user" style={{ background: '#ffffff' }}>

                                                                <Col lg={1} className="hide-mob">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div> {i + 1}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>

                                                                <Col lg={3} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.lead_source_name}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>

                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>
                                                                                <Image style={{ height: "42px", width: "62px", objectFit: 'contain' }} src={this.props.serverPath + item.lead_source_image} alt={item.lead_source_image} />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Col>

                                                              
                                                                <Col lg={2} className="hide-mob">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{item.created_on}</div>
                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                               
                                                                <Col lg={2} xs={3} className="">
                                                                    <div className="table-data-user">

                                                                        <div className="lead-data">
                                                                            {item.lead_source_status === 1 ?
                                                                                <AntSwitch checked={true} name="ChangeLeadSourceStatus" value={item.lead_source_status} onChange={(e) => this.props.HandleUserAction(e, item._id, item.lead_source_status)} /> :
                                                                                <AntSwitch checked={false} name="ChangeLeadSourceStatus" value={item.lead_source_status} onChange={(e) => this.props.HandleUserAction(e, item._id, item.lead_source_status)} />
                                                                            }
                                                                        </div>


                                                                    </div>
                                                                </Col>


                                                                <Col lg={2} xs={3} className="">
                                                                    <div className="table-data-user">


                                                                        <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.profile_status)}>
                                                                            <option value="" selected>Take Action</option>
                                                                            <option value="Detail">Detail</option>
                                                                            {sessionStorage.getItem('current_user_profile') === "Administrator" ?
                                                                                <option value="Delete">Delete</option> : null}

                                                                        </Form.Control>

                                                                    </div>



                                                                </Col>

                                                            </Row> : null}

                                                    </div>



                                                )) : null}
                                        {this.state.numItemsPerPage < length ?
                                            <div className="pagination-div-profile">
                                                <Pagination totalNumber={totalPages} currentPageNumber={this.state.currentPageNumber} ChangePage={this.ChangePage} />
                                            </div> : null}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        )
    }
}