import React, { Component } from 'react';
// import { Header } from '../common/header';
export default class NotFound extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // let imgUrl = require('../images/404.gif');
    let imgUrl = require('../assets/images/404.png');
    return (
      <div>
        {/* <Header {...this.props} /> */}

        <div style={{ width: '100%', height: '500px', backgroundImage: 'url(' + imgUrl + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

          {/* <img src={require('../images/404.gif')} height='100%'/> */}
        </div>
      </div>
    );
  }
}
