import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { PnkConnect } from "../../../pnk-react/pnk-miniredux/";
import Report from './report'
import Options from './options';
import { GetUserProfileList, GetCityList, GetUserList, GetLeadData, GetLeadSource } from '../../../models/users';
import { AdminSalesReport ,CallBackList} from '../../../models/admin-dashboard'
import ViewData from './view-data-modal'
import MobCustomDatePicker from './mob-custom-picker'
import CallbackReport from './callback'
import SalesReport from './sales-report'
import HCTracker from './hc-tracker'
import DesignTeamReport from'./design-team-report'
import HCPMSChedule from './hc-pm-schedule'
import AppUtils from '../../../apputils'
import MeetingTracker from './meeting-tracker'
import RMDailyReport from './RM-report';
import UnderConstructionComp from './under-construction'

class reportPage extends React.Component {
    constructor(props) {
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.state = {
            currentSalesReportType: 'Today',
            txtCustomStartDate: new Date().setDate(new Date().getDate() - 29),
            txtCustomEndDate: new Date(),
            currentViewType: (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "")?this.props.componentprops.match.params.type :'default',
            startDate: '',
            endDate: '',
            currentDisplayType: '',
            todayDate: '',
            yesterdayDate: '',
            weekDate: '',
            monthDate: '',
            getUser: false,
            getReportData: false,
            reportData: [],
            ddlPreferredCity: '-1',
            getCity: true,
            showCustomDateModal: false,
            ddlCurrentSalesUser:-1,
            getCallbackData:false,
            callbackReportData:[],
            hcPmScheduleType:'hc',
            getCustomCallbackData:false,
            dayBeforeYestrday:'',
            meetingWeekStartDate:'',
            meetingWeekEndDate:'',
            getMeetingData:false,
            txtCustomMeetingStartDate:new Date(),
            txtCustomMeetingEndDate: new Date().setDate(new Date().getDate() + 7),
            
            

        }
        this.salesReportType = [
            'Today',
            'Yesterday',
            'Last 7 Days',
            // 'Last 30 Days',
            'Custom'
        ];
        this.callBackReportType=[
            'Today',
            'Coming Days',
            'Custom'
        ]
        this.customData = []
        this.currentCity = ''
        this.currentUser=''
        this.todayAllData=[]
        this.yesterdayAllData=[]
        this.weekAllData=[]
        this.monthAllData=[]
        this.salesTLReportData=[]
        this.currentDesigner=''
        this.hcPmScheduleType='hc'
         // Follow Up
  this.todayFollowupReport=[]
  this.yesterdayFollowupReport=[]
  this.weekFollowupReport=[]
  this.monthFollowupReport=[]

 // Hc Booked
 this.todayHCBookedReport = []
 this.yesterdayHCBookedReport = []
 this.weekHCBookedReport = []

  // PM Booked
  this.todayPMBookedReport = []
  this.yesterdayPMBookedReport = []
  this.weekPMBookedReport = []
  // VC
  this.todayVCCompletedReport = []
  this.yesterdayVCCompletedReport = []
  this.weekVCCompletedReport = []

//   RM Daily Report
this.todayDailyReport=[]
this.yesterdayDailyReport=[]
this.weekDailyReport=[]

 // Under Construction
 this.todayUnderConstruction=[]
 this.yesterdayUnderConstruction=[]
 this.weekUnderConstruction=[]
//  
this.monthData=[]
    }
    HandleMeetingType=(type)=>{
      //  alert(type)
        this.setState({
            hcPmScheduleType: type,
            // currentSalesReportType:'Today'

        })
        this.hcPmScheduleType=type;
        this.RenderReport("meeting-tracker",'Today')
    }

    componentDidMount = () => {

        
        var today = new Date();

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let endDate = yyyy + '-' + mm + '-' + dd;

        let beforeThirtyDays = new Date(new Date().setDate(new Date().getDate() - 6))
        var before_dd = String(beforeThirtyDays.getDate()).padStart(2, '0');
        var before_mm = String(beforeThirtyDays.getMonth() + 1).padStart(2, '0'); //January is 0!
        var before_yyyy = beforeThirtyDays.getFullYear();
        let startDate = before_yyyy + '-' + before_mm + '-' + before_dd;

        let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
        var yester_dd = String(yesterdayDate.getDate()).padStart(2, '0');
        var yester_mm = String(yesterdayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yester_yyyy = yesterdayDate.getFullYear();
        let yesterday = yester_yyyy + '-' + yester_mm + '-' + yester_dd;

        let weekDate = new Date(new Date().setDate(new Date().getDate() - 6))
        var week_dd = String(weekDate.getDate()).padStart(2, '0');
        var week_mm = String(weekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var week_yyyy = weekDate.getFullYear();
        let weekStartDate = week_yyyy + '-' + week_mm + '-' + week_dd;

        let monthDate = new Date(new Date().setDate(new Date().getDate() - 29))
        var month_dd = String(monthDate.getDate()).padStart(2, '0');
        var month_mm = String(monthDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var month_yyyy = monthDate.getFullYear();
        let monthStartDate = month_yyyy + '-' + month_mm + '-' + month_dd;
      
        let dayBeforeYestrday = new Date(new Date().setDate(new Date().getDate() - 2))
        var dy_bf_yst = String(dayBeforeYestrday.getDate()).padStart(2, '0');
        var month_dy_bg_yst = String(dayBeforeYestrday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var year_dy_bf_yst = dayBeforeYestrday.getFullYear();
        let dayBfYest = year_dy_bf_yst + '-' + month_dy_bg_yst + '-' + dy_bf_yst;

        let comingWeekDate = new Date(new Date().setDate(new Date().getDate() +7))
        var meeting_week_dd = String(comingWeekDate.getDate()).padStart(2, '0');
        var meeting_week_mm = String(comingWeekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var meeting_week_yyyy = comingWeekDate.getFullYear();
        let meetingWeekEndDate = meeting_week_yyyy + '-' + meeting_week_mm + '-' + meeting_week_dd;

        this.setState({
            startDate: startDate, endDate: endDate, getReportData: false, currentDisplayType: endDate,
            todayDate: endDate, yesterdayDate: yesterday, weekDate: weekStartDate, monthDate: monthStartDate,
            dayBeforeYestrday:dayBfYest,meetingWeekStartDate:endDate,meetingWeekEndDate:meetingWeekEndDate
        })

        if (JSON.stringify(this.props.PnkStore.GetData('userlist')) === '{}') {
            this.setState({
                getUser: true
            })
        }
        if(this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== ""){
            if(this.props.componentprops.match.params.type==='meeting-tracker'){
                this.setState({
                    getMeetingData:true
                })
            }
            else if(this.props.componentprops.match.params.type==='callback'){
                this.setState({
                    getCallbackData:true
                })    
            }
            else{
                this.setState({
                    getReportData:true,
                   
                })
            }
        }
        else{
        this.setState({
            getReportData:true
        })
    }
        
    }

    HandleChangeDate = (type, date) => {
       
        this.setState({
            [type]: date
        });

    };
    HandleUserInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ddlPreferredCity") {
            this.currentCity = e.target.value;
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }
        if(e.target.name==="ddlCurrentSalesUser"){
            this.currentUser = e.target.value;
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }


    }
    HandleInsideSalesOptions = (type) => {
        this.currentInsideSalesType = type
        this.setState({ currentInsideSalesType: type })
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport( this.state.currentViewType,this.state.currentSalesReportType)
        }
        else {
            this.RenderCustomReport()
        }
    }


    ShowSalesInsideModal = (data) => {
        this.props.componentprops.OpenModal(ViewData, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: '', icon: 'close' }, { action: this.state, name: 'Profile', close: this.HideModal, data: data })
    }
    HideModal = () => {
        this.props.componentprops.HandleCloseModal();
    }

    HandleGetReportResponse = (res) => {

       
       
        var users = this.props.PnkStore.GetData('userlist').rows;
        this.setState({ getReportData: false, isOptionDisabled: false })
        if (res !== false) {
            if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.monthData=res.data.adminfirstmonthdata.rows;
               
            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')
               ) {
                    if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                       
                        res.data.adminfirstmonthdata.rows.forEach((element, i) => {
                           if(element.lead_department[0].profile_name.includes("Sales")){
                        if(user.user_name===element.lead_owner){
                            if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.yesterdayDate
                                || element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate)
                                || element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)
                                )) {
                                    this.todayDailyReport.push(element)
                            }
                                else if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.dayBeforeYestrday)
                                || element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.dayBeforeYestrday)
                                    || element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.dayBeforeYestrday)
                                ) {
                                    this.yesterdayDailyReport.push(element);
                                }
                                if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.weekDate
                                || element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate
                                    || element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate
                
                                ) {
                                    this.weekDailyReport.push(element)
                                }
                
                
                                if (element.lead_current_stage==='Under Construction' && element.under_const_date_time !=='' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.todayDate)) {
                                    this.todayUnderConstruction.push(element)
                                }
                                else if (element.lead_current_stage==='Under Construction' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.yesterdayDate)) {
                                    this.yesterdayUnderConstruction.push(element);
                                }
                                if (element.lead_current_stage==='Under Construction' && element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.todayDate+' 23:59:59' && element.under_const_date_time >= this.state.weekDate) {
                                    this.weekUnderConstruction.push(element)
                                
                                }            

                                                        // Follow Up
                            if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.todayDate)) {
                                this.todayFollowupReport.push(element)
                            }
                            else if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.yesterdayDate)) {
                                this.yesterdayFollowupReport.push(element);
                            }
                            if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate+' 23:59:59' && element.follow_up_date_time >= this.state.weekDate) {
                                this.weekFollowupReport.push(element)
                            }
                            if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate+' 23:59:59' && element.follow_up_date_time >= this.state.monthDate) {
                                this.monthFollowupReport.push(element)
                            }
                            if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                                this.todayHCBookedReport.push(element)
                            }
                           
                            else if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                                this.yesterdayHCBookedReport.push(element);
                            }
                            if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                                this.weekHCBookedReport.push(element)
                            }
                          
                             // PM todayPMBookedReport
                             if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate)) {
                                this.todayPMBookedReport.push(element)
                            }
                            else if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate)) {
                                this.yesterdayPMBookedReport.push(element);
                            }
                            if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate) {
                                this.weekPMBookedReport.push(element)
                            }
                           
                            // VC Completed
    
                            if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate)) {
                                this.todayVCCompletedReport.push(element)
                            }
                            else if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                                this.yesterdayVCCompletedReport.push(element);
                            }
                            if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate) {
                                this.weekVCCompletedReport.push(element)
                            }
                          
                           
                        if (
                            (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate))
                        || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate))
                        || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.todayDate))
                        || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.todayDate))
                        || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.todayDate))
                        || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time.includes(this.state.todayDate))
                        || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.todayDate))
                        || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate))
                        || (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate))
                        || (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.todayDate))

                        ) {
                            this.todayAllData.push(element)
                        }
                         if ((element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate))
                        || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate))
                        || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate))
                        || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                        || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.yesterdayDate))
                        || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time.includes(this.state.yesterdayDate))
                        || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                        || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate))
                        || (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)
                        || (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.yesterdayDate))
                        )
                        ) {
                            this.yesterdayAllData.push(element);
                        }
                        if ((element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate+' 23:59:59' && element.patch_date_time >= this.state.weekDate)
                        ||(element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate)
                        ||(element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate)
                        ||(element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate+' 23:59:59' && element.handover_to_designer_date_time >= this.state.weekDate)
                        ||(element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate+' 23:59:59' && element.proposal_sent_date_time >= this.state.weekDate)
                        ||(element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.todayDate+' 23:59:59' && element.proposal_uploaded_date_time >= this.state.weekDate)
                        ||(element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate+' 23:59:59' && element.booking_amount_received_date_time >= this.state.weekDate)
                        || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate)
                        || (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate)
                        || (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.weekDate)
                     ) {
                            this.weekAllData.push(element)
                        }
                        if ((element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate+' 23:59:59' && element.patch_date_time >= this.state.monthDate)
                        ||(element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.monthDate)
                        ||(element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.hc_completed_date_time >= this.state.monthDate)
                        ||(element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate+' 23:59:59' && element.handover_to_designer_date_time >= this.state.monthDate)
                        ||(element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate+' 23:59:59' && element.proposal_sent_date_time >= this.state.monthDate)
                        ||(element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.todayDate+' 23:59:59' && element.proposal_uploaded_date_time >= this.state.monthDate)
                        ||(element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate+' 23:59:59' && element.booking_amount_received_date_time >= this.state.monthDate)
                        || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.monthDate)
                        || (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.monthDate)
                        || (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.monthDate)
                        ) {
                            
                            this.monthAllData.push(element)
                        }

                    }
                }
                   
                });
                }
                }   
               
                if (users.length - 1 === i)
                    this.RenderReport(this.state.currentViewType, this.state.currentSalesReportType);
            })
        }
    }
}
    RenderReport = (viewType, type) => {

      
       
      
        let sales_report = [];
        let current_details=[]
        if (viewType === "default") {
            let total_in_progress = 0, total_hc_booked = 0, total_hc_completed = 0, total_design_handovered = 0, total_proposal_sent = 0,total_proposal_uploaded=0,total_booking_amount_received=0;
 
                    if (type === "Today") {
                        this.todayAllData.forEach(todayData => {
                           
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                             if(this.currentUser===''){
                                    if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                                    total_in_progress++
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                                    total_hc_booked++
                                if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                                    total_hc_completed++
                                if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                                    total_design_handovered++
                                if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                                    total_proposal_sent++
                                if (todayData.proposal_uploaded_date_time !== undefined && todayData.proposal_uploaded_date_time !== '' && todayData.proposal_uploaded_date_time.includes(this.state.todayDate))
                                    total_proposal_uploaded++
                                if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                                    total_booking_amount_received++
                             }
                             else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                                total_in_progress++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                                total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                                total_hc_completed++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                                total_design_handovered++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                                total_proposal_sent++
                            if (todayData.proposal_uploaded_date_time !== undefined && todayData.proposal_uploaded_date_time !== '' && todayData.proposal_uploaded_date_time.includes(this.state.todayDate))
                                total_proposal_uploaded++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                                total_booking_amount_received++
                             }
                                
                            }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                                    total_in_progress++
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                                    total_hc_booked++
                                if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                                    total_hc_completed++
                                if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                                    total_design_handovered++
                                if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                                    total_proposal_sent++
                                if (todayData.proposal_uploaded_date_time !== undefined && todayData.proposal_uploaded_date_time !== '' && todayData.proposal_uploaded_date_time.includes(this.state.todayDate))
                                    total_proposal_uploaded++
                                if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                                    total_booking_amount_received++
                             }
                             else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                                total_in_progress++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                                total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                                total_hc_completed++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                                total_design_handovered++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                                total_proposal_sent++
                            if (todayData.proposal_uploaded_date_time !== undefined && todayData.proposal_uploaded_date_time !== '' && todayData.proposal_uploaded_date_time.includes(this.state.todayDate))
                                total_proposal_uploaded++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                                total_booking_amount_received++
                             }
                                }
                            
                        })
                       
                    }
                    else if (type === "Yesterday") {
                        this.yesterdayAllData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(this.currentUser===''){
                                if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                                total_in_progress++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                                total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                                total_hc_completed++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                                total_design_handovered++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                                total_proposal_sent++
                            if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time.includes(this.state.yesterdayDate))
                                total_proposal_uploaded++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                                total_booking_amount_received++
                                }
                                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                    if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                                    total_in_progress++
                                if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                                    total_hc_booked++
                                if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                                    total_hc_completed++
                                if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                                    total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                                    total_proposal_sent++
                                if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time.includes(this.state.yesterdayDate))
                                    total_proposal_uploaded++
                                if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                                    total_booking_amount_received++
                                }
                            
                        }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                                    total_in_progress++
                                if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                                    total_hc_booked++
                                if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                                    total_hc_completed++
                                if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                                    total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                                    total_proposal_sent++
                                if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time.includes(this.state.yesterdayDate))
                                    total_proposal_uploaded++
                                if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                                    total_booking_amount_received++
                                    }
                                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                                        total_in_progress++
                                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                                        total_hc_booked++
                                    if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                                        total_hc_completed++
                                    if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                                        total_design_handovered++
                                    if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                                        total_proposal_sent++
                                    if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time.includes(this.state.yesterdayDate))
                                        total_proposal_uploaded++
                                    if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                                        total_booking_amount_received++
                                    }
                                
                            }
                        })
                      
                      
                    }
                    else if (type === 'Last 7 Days') {
                       
                        this.weekAllData.forEach(report => {

                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(this.currentUser===''){
                                    if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                                    total_in_progress++
                                if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                                    total_hc_booked++
                                if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                                    total_hc_completed++
                                if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                                    total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' &&  report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                                    total_proposal_sent++
                                if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' &&  report.proposal_uploaded_date_time <= this.state.todayDate && report.proposal_uploaded_date_time >= this.state.weekDate)
                                    total_proposal_uploaded++
                                if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                                    total_booking_amount_received++
                                    }
                                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                                    total_in_progress++
                                if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                                    total_hc_booked++
                                if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                                    total_hc_completed++
                                if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                                    total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' &&  report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                                    total_proposal_sent++
                                if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' &&  report.proposal_uploaded_date_time <= this.state.todayDate && report.proposal_uploaded_date_time >= this.state.weekDate)
                                    total_proposal_uploaded++
                                if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                                    total_booking_amount_received++
                                    }
                                
                            }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                                    total_in_progress++
                                if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                                    total_hc_booked++
                                if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                                    total_hc_completed++
                                if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                                    total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' &&  report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                                    total_proposal_sent++
                                if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' &&  report.proposal_uploaded_date_time <= this.state.todayDate && report.proposal_uploaded_date_time >= this.state.weekDate)
                                    total_proposal_uploaded++
                                if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                                    total_booking_amount_received++
                                    }
                                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                                        total_in_progress++
                                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                                        total_hc_booked++
                                    if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                                        total_hc_completed++
                                    if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                                        total_design_handovered++
                                    if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' &&  report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                                        total_proposal_sent++
                                    if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' &&  report.proposal_uploaded_date_time <= this.state.todayDate && report.proposal_uploaded_date_time >= this.state.weekDate)
                                        total_proposal_uploaded++
                                    if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                                        total_booking_amount_received++
                                    }
                                    
                                
                            }
                        })
                       
                     
                    }

                   
    
        // if (total_in_progress > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_design_handovered>0 || total_proposal_sent>0 || total_proposal_uploaded>0|| total_booking_amount_received>0) {
            sales_report.push({
                total_in_progress: total_in_progress, total_hc_booked: total_hc_booked,
                total_hc_completed: total_hc_completed, total_design_handovered: total_design_handovered,
                total_proposal_sent: total_proposal_sent,total_proposal_uploaded:total_proposal_uploaded,
                total_booking_amount_received:total_booking_amount_received
            })
        // }
       
       
        this.setState({ reportData: sales_report })
        }
        else if(viewType==='under-construction'){
            let under_const_arr=[]
            if(type==="Today"){
                this.setState({reportData:[]})
                this.todayUnderConstruction.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) 
                        {
                            if(this.currentUser==='')
                            {
                                under_const_arr.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                under_const_date_time: report.under_const_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id,
                                date_time:report.date_time,
                                update_on:report.updated_on
                                });
                            }
                            else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                under_const_arr.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    under_const_date_time: report.under_const_date_time, 
                                    client_mobile:report.user_mobile_no,
                                    id:report._id,
                                    date_time:report.date_time,
                                    update_on:report.updated_on
                                    });
                            }
                }
                else if (this.currentCity === '') {
                    if(this.currentUser==='')
                    {
                        under_const_arr.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        under_const_date_time: report.under_const_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id,
                        date_time:report.date_time,
                        update_on:report.updated_on
                        });
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on
                            });
                    }
                }
                  
                
        });
            }
            if(type==="Yesterday"){
                this.setState({reportData:[]})
                this.yesterdayUnderConstruction.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) 
                        {
                            if(this.currentUser==='')
                            {
                                under_const_arr.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                under_const_date_time: report.under_const_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id,
                                date_time:report.date_time,
                                update_on:report.updated_on
                                });
                            }
                            else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                under_const_arr.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    under_const_date_time: report.under_const_date_time, 
                                    client_mobile:report.user_mobile_no,
                                    id:report._id,
                                    date_time:report.date_time,
                                    update_on:report.updated_on
                                    });
                            }
                }
                else if (this.currentCity === '') {
                    if(this.currentUser==='')
                    {
                        under_const_arr.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        under_const_date_time: report.under_const_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id,
                        date_time:report.date_time,
                        update_on:report.updated_on
                        });
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on
                            });
                    }
                }
                    
                
        });
            }
            if(type==="Last 7 Days"){
                this.setState({reportData:[]})
                this.weekUnderConstruction.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) 
                        {
                            if(this.currentUser==='')
                            {
                                under_const_arr.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                under_const_date_time: report.under_const_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id,
                                date_time:report.date_time,
                                update_on:report.updated_on
                                });
                            }
                            else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                under_const_arr.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    under_const_date_time: report.under_const_date_time, 
                                    client_mobile:report.user_mobile_no,
                                    id:report._id,
                                    date_time:report.date_time,
                                    update_on:report.updated_on
                                    });
                            }
                }
                else if (this.currentCity === '') {
                    if(this.currentUser==='')
                    {
                        under_const_arr.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        under_const_date_time: report.under_const_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id,
                        date_time:report.date_time,
                        update_on:report.updated_on
                        });
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on
                            });
                    }
                }
                
        });
            }
            this.setState({
                reportData:under_const_arr
            })
        }
        else if(viewType==='sales-rm-daily-report'){
            let rmDailyReport=[];
            let users = this.props.PnkStore.GetData('userlist').rows;
    users.forEach((user, i) => {    
           
        if (type === "Today") {
            this.todayDailyReport.forEach(reportData => {
               

                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time.includes(this.state.yesterdayDate)
                    || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time.includes(this.state.yesterdayDate)
                    || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time.includes(this.state.yesterdayDate)){
                 if(user.user_name===reportData.lead_owner){
                    if (this.currentCity !== '' && (this.currentCity === reportData.lead_city)) {
                        if(this.currentUser===''){
                    // console.loge.log("reportData",reportData)
                    rmDailyReport.push({
                        'date':reportData.updated_on,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }
            else if (this.currentCity === '') {
                if(this.currentUser===''){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }

        }
    }

            })

        }
        if(type==='Yesterday'){
           
            this.yesterdayDailyReport.forEach(reportData => {
              

                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time.includes(this.state.dayBeforeYestrday)
                || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time.includes(this.state.dayBeforeYestrday)
                || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time.includes(this.state.dayBeforeYestrday)){
                if(user.user_name===reportData.lead_owner){
                 
                    if (this.currentCity !== '' && (this.currentCity === reportData.lead_city)) {
                        if(this.currentUser===''){
                    // console.loge.log("reportData",reportData)
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }
            else if (this.currentCity === '') {
                if(this.currentUser===''){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }
                }
            }

            })
           
        }
        if(type==='Last 7 Days'){
            this.weekDailyReport.forEach(reportData => {
                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && reportData.project_meeting_completed_date_time >= this.state.weekDate
                || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && reportData.hc_completed_date_time >= this.state.weekDate
                    || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && reportData.vc_completed_date_time >= this.state.weekDate){

                if(user.user_name===reportData.lead_owner){
                    // console.loge.log("reportData",reportData)
                    if (this.currentCity !== '' && (this.currentCity === reportData.lead_city)) {
                        if(this.currentUser===''){
                    // console.loge.log("reportData",reportData)
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }
            else if (this.currentCity === '') {
                if(this.currentUser===''){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    rmDailyReport.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }
                }
            }

            })
           
        
    
}

    })
            this.setState({ reportData: rmDailyReport })
        }
        else if (viewType === "meeting-tracker") {
          
          
            let users = this.props.PnkStore.GetData('userlist').rows;
          
            var hc_report = [];
            let meeting_tracker_data=[]

            let total_closure = 0, lead_owner = '', client_name = '', project_value = '', hc_date_time = '', stage = '', name_of_designer = '';
            // if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
               
             if (type === "Today") {
                 alert(1)
                if(this.hcPmScheduleType==='hc')
                {
                    this.setState({
                        reportData:[]
                    })
                     alert(1)
                this.todayHCBookedReport.forEach(todayHCData => {
                 console.log("todayHCData",todayHCData)
                        if ( this.currentUser !== '' && ( this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                               
                                
                               
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time,
                                    

                                    
                                });
                            }
                            else if (this.currentCity === '') {
                               
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                    
                                });
                                console.log("hc_report",hc_report)
                            }

                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                    
                                });
                            }
                        }
                    
                
                   
                });
            }
            else if(this.hcPmScheduleType==="project-meeting"){
                this.setState({
                    reportData:[]
                })
                this.todayPMBookedReport.forEach(report => {
                 
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                    }
                
                })
               
            }
            else if(this.hcPmScheduleType==="vc"){
                this.setState({
                    reportData:[]
                })
                this.todayVCCompletedReport.forEach(report => {
         
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                    }

                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            console.log("report",report)
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                    }
            
               
            })
        }
    }

           else if (type === "Yesterday") {
            if(this.hcPmScheduleType==='hc'){
                this.setState({
                    reportData:[]
                })
                this.yesterdayHCBookedReport.forEach(yesterdayHCData => {
                
                        if ( this.currentUser !== '' && ( this.currentUser === yesterdayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                hc_report.push({
                                    lead_owner: yesterdayHCData.lead_owner, 
                                    client_name: yesterdayHCData.user_name, 
                                    hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:yesterdayHCData.preferred_area,
                                    hc_completed_date_time:yesterdayHCData.hc_completed_date_time

                                    
                                });
                            }
                            else if (this.currentCity === '') {
                              
                                hc_report.push({
                                    lead_owner: yesterdayHCData.lead_owner, 
                                    client_name: yesterdayHCData.user_name, 
                                    hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:yesterdayHCData.preferred_area,
                                    hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                    
                                });
                            }

                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                hc_report.push({
                                    lead_owner: yesterdayHCData.lead_owner, 
                                    client_name: yesterdayHCData.user_name, 
                                    hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:yesterdayHCData.preferred_area,
                                    hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: yesterdayHCData.lead_owner, 
                                    client_name: yesterdayHCData.user_name, 
                                    hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:yesterdayHCData.preferred_area,
                                    hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                    
                                });
                            }
                        }
                    
                    
                });
            }
            else if(this.hcPmScheduleType==="project-meeting"){
                this.setState({
                    reportData:[]
                })
                this.yesterdayPMBookedReport.forEach(report => {
                  
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           

                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name


                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            console.log("report",report)
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                    }
                
                })
               
            }
            else if(this.hcPmScheduleType==="vc"){
                this.setState({
                    reportData:[]
                })
                this.yesterdayVCCompletedReport.forEach(report => {
                 
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                    }

                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            console.log("report",report)
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                            });
                        }
                    }
            
               
            })
        }

            }
            else if (type === "Last 7 Days") {
                this.setState({
                    reportData:[]
                })
                if(this.hcPmScheduleType==='hc'){
                this.weekHCBookedReport.forEach(weekyHCData => {
                 
                        if ( this.currentUser !== '' && ( this.currentUser === weekyHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                hc_report.push({
                                    lead_owner: weekyHCData.lead_owner, 
                                    client_name: weekyHCData.user_name, 
                                    hc_date_time: weekyHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:weekyHCData.preferred_area,
                                    hc_completed_date_time:weekyHCData.hc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: weekyHCData.lead_owner, 
                                    client_name: weekyHCData.user_name, 
                                    hc_date_time: weekyHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:weekyHCData.preferred_area,
                                    hc_completed_date_time:weekyHCData.hc_completed_date_time
                                    
                                });
                            }

                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                hc_report.push({
                                    lead_owner: weekyHCData.lead_owner, 
                                    client_name: weekyHCData.user_name, 
                                    hc_date_time: weekyHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:weekyHCData.preferred_area,
                                    hc_completed_date_time:weekyHCData.hc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: weekyHCData.lead_owner, 
                                    client_name: weekyHCData.user_name, 
                                   
                                    hc_date_time: weekyHCData.hc_booked_date_time, 
                                    sales_tl:sessionStorage.getItem('current_user_name'),
                                    location:weekyHCData.preferred_area,
                                    hc_completed_date_time:weekyHCData.hc_completed_date_time
                                    
                                });
                            }
                        }
                    
                   
                });
            }
            else if(this.hcPmScheduleType==="project-meeting"){
                this.setState({
                    reportData:[]
                })
                this.weekPMBookedReport.forEach(report => {
                 
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name,
                                assist_name:report.assist_name
                                
                            });
                        }
                    }
                
                })
               
            }
            else if(this.hcPmScheduleType==="vc"){
                this.setState({
                    reportData:[]
                })
                this.weekVCCompletedReport.forEach(report => {
                  
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                    }

                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            console.log("report",report)
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:sessionStorage.getItem('current_user_name'),
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                    }
            
               
            })
        }
            }
           
        
          
            this.setState({ reportData: hc_report })
        }
        else if(viewType==="followupreport"){
          
            let follow_up_report = [];
            if(type==="Today"){
                follow_up_report=[]
            this.todayFollowupReport.forEach(report => {
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if(this.currentUser===''){
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                            follow_up_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                follow_up_date_time: report.follow_up_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id
                               
                            });
                        }
                       
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                            follow_up_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                follow_up_date_time: report.follow_up_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id
                               
                            });
                        } 
                    }
                    
                       
                }
                else if (this.currentCity === '') {
                    if(this.currentUser===''){
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                            follow_up_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                follow_up_date_time: report.follow_up_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id
                               
                            });
                           
                        }
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                            follow_up_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                follow_up_date_time: report.follow_up_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id
                               
                            });
                        } 
                    }
                   
                }
            })
        }
        else if(type==="Yesterday"){
            follow_up_report=[]
        this.yesterdayFollowupReport.forEach(report => {
            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                if(this.currentUser===''){
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                    }
                   
                }
                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                    } 
                }
                
                   
            }
            else if (this.currentCity === '') {
                if(this.currentUser===''){
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                       
                    }
                }
                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                    } 
                }
               
            }
        })
    }
    if(type==="Last 7 Days"){
        follow_up_report=[]
    this.weekFollowupReport.forEach(report => {

        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
            if(this.currentUser===''){
                if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                }
               
            }
            else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                } 
            }
            
               
        }
        else if (this.currentCity === '') {
            if(this.currentUser===''){
                if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                   
                }
            }
            else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                } 
            }
           
        }
    })
}
else if (type === 'Last 30 Days' || type === 'Custom') {
    follow_up_report=[]
this.monthFollowupReport.forEach(report => {
    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
        if(this.currentUser===''){
            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                follow_up_report.push({
                    lead_owner: report.lead_owner, 
                    client_name: report.user_name, 
                    follow_up_date_time: report.follow_up_date_time, 
                    client_mobile:report.user_mobile_no,
                    id:report._id
                   
                });
            }
           
        }
        else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                follow_up_report.push({
                    lead_owner: report.lead_owner, 
                    client_name: report.user_name, 
                    follow_up_date_time: report.follow_up_date_time, 
                    client_mobile:report.user_mobile_no,
                    id:report._id
                   
                });
            } 
        }
        
           
    }
    else if (this.currentCity === '') {
        if(this.currentUser===''){
            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                follow_up_report.push({
                    lead_owner: report.lead_owner, 
                    client_name: report.user_name, 
                    follow_up_date_time: report.follow_up_date_time, 
                    client_mobile:report.user_mobile_no,
                    id:report._id
                   
                });
               
            }
        }
        else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                follow_up_report.push({
                    lead_owner: report.lead_owner, 
                    client_name: report.user_name, 
                    follow_up_date_time: report.follow_up_date_time, 
                    client_mobile:report.user_mobile_no,
                    id:report._id
                   
                });
            } 
        }
       
    }
})
}
follow_up_report.sort((a,b)=> new Date(b.follow_up_date_time).getTime()-new Date(a.follow_up_date_time).getTime());

        this.setState({
            reportData:follow_up_report
        })
        }
        else if (viewType === "details") {
           
                let name = '', caller = '', source = '', number = '', stage = '', lead_acquisition_date = '', last_updated = '', remarks = '', lead_patched_date;
                    if (type === "Today") {
                        current_details=[]
                        this.todayAllData.forEach(todayData => {
                          
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if(this.currentUser===''){
                                        current_details.push({
                                            lead_patched_date:todayData.patch_date_time,
                                              last_edited: todayData.updated_on,
                                            client_name: todayData.user_name, 
                                            client_number: todayData.user_mobile_no,
                                              lead_stage: todayData.lead_current_stage,
                                              lead_owner:todayData.lead_owner,
                                              comment:todayData.crmComments
                                        })
                                }
                                else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    }) 
                                }

                                
                            }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                
                            }
                        })
                       

                    }
                    else if (type === "Yesterday") {
                        current_details=[]
                        this.yesterdayAllData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                   
                            }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                    }
                           
                        })
                        
                    }
                    else if (type === 'Last 7 Days') {
                        current_details=[]
                        this.weekAllData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                   
                            }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                    }
                                
                        })
                        
                    }

                    else if (type === 'Last 30 Days' || type === 'Custom') {
                        this.monthAllData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                   
                            }
                            else if (this.currentCity === '') {
                                if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:todayData.patch_date_time,
                                          last_edited: todayData.updated_on,
                                        client_name: todayData.user_name, 
                                        client_number: todayData.user_mobile_no,
                                          lead_stage: todayData.lead_current_stage,
                                          lead_owner:todayData.lead_owner,
                                          comment:todayData.crmComments
                                    })
                            }
                            else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                                current_details.push({
                                    lead_patched_date:todayData.patch_date_time,
                                      last_edited: todayData.updated_on,
                                    client_name: todayData.user_name, 
                                    client_number: todayData.user_mobile_no,
                                      lead_stage: todayData.lead_current_stage,
                                      lead_owner:todayData.lead_owner,
                                      comment:todayData.crmComments
                                }) 
                            }
                                    }
                               
                        })
                       
                    }
                    
                    this.setState({ reportData: current_details })
        }
        else if(viewType==="salesreport"){
            let sales_report=[]
            var users = this.props.PnkStore.GetData('userlist').rows;
            users.forEach((user, i) => {
                let total_patch = 0, total_hc_booked = 0, total_hc_completed = 0, total_follow = 0, total_pending_design = 0,lead_owner='',reporting_to='',
                    total_handover_to_design = 0, total_estimate_pending = 0, total_proposal_sent = 0, total_booking_amount_received = 0, total_project_closed = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
            if (type === "Today") {
               
                this.todayAllData.forEach(todayData => {
                   
                    if (todayData.lead_owner === user.user_name) {
                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if(this.currentUser===''){
                            lead_owner=todayData.lead_owner
                            reporting_to= user.reporting_to
                            if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time.includes(this.state.todayDate))
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time.includes(this.state.todayDate))
                            total_project_closed++
                            
                            
                        }
                        else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                            lead_owner=todayData.lead_owner
                            reporting_to= user.reporting_to
                            if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time.includes(this.state.todayDate))
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time.includes(this.state.todayDate))
                            total_project_closed++

                        }

                        
                    }
                    else if (this.currentCity === '') {
                        if(this.currentUser===''){
                            lead_owner=todayData.lead_owner
                            reporting_to= user.reporting_to
                            if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time.includes(this.state.todayDate))
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time.includes(this.state.todayDate))
                            total_project_closed++
                    }
                    else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                        lead_owner=todayData.lead_owner
                        reporting_to= user.reporting_to
                        if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time.includes(this.state.todayDate))
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time.includes(this.state.todayDate))
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time.includes(this.state.todayDate))
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time.includes(this.state.todayDate))
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time.includes(this.state.todayDate))
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time.includes(this.state.todayDate))
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== '' && todayData.booking_amount_received_date_time.includes(this.state.todayDate))
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time.includes(this.state.todayDate))
                            total_project_closed++
                    }
                        
                    }
                }
                })
               

            }
            else if (type === "Yesterday") {
               
                this.yesterdayAllData.forEach(report => {
                    
                    if (report.lead_owner === user.user_name) {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if(this.currentUser===''){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time.includes(this.state.yesterdayDate))
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time.includes(this.state.yesterdayDate))
                            total_project_closed++
                            
                            
                        }
                        else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time.includes(this.state.yesterdayDate))
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time.includes(this.state.yesterdayDate))
                            total_project_closed++

                        }

                        
                    }
                    else if (this.currentCity === '') {
                        if(this.currentUser===''){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time.includes(this.state.yesterdayDate))
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time.includes(this.state.yesterdayDate))
                            total_project_closed++
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        lead_owner=report.lead_owner
                        reporting_to= user.reporting_to
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate))
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate))
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time.includes(this.state.yesterdayDate))
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time.includes(this.state.yesterdayDate))
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate))
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time.includes(this.state.yesterdayDate))
                            total_project_closed++
                    }
                        
                    }
                }
                })
               

            }
           
            else if (type === 'Last 7 Days') {
               
                this.weekAllData.forEach(report => {
                   
                    if (report.lead_owner === user.user_name) {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if(this.currentUser===''){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.weekDate)
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.weekDate)
                            total_project_closed++
                            
                            
                        }
                        else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.weekDate)
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.weekDate)
                            total_project_closed++

                        }

                        
                    }
                    else if (this.currentCity === '') {
                        if(this.currentUser===''){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.weekDate)
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.weekDate)
                            total_project_closed++
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        lead_owner=report.lead_owner
                        reporting_to= user.reporting_to
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate)
                        total_patch++
                        if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate)
                        total_hc_booked++
                        if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.weekDate)
                        total_hc_completed++
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.weekDate)
                        total_follow++
                        if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate)
                        total_handover_to_design++
                        if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.weekDate)
                        total_proposal_sent++
                        if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.weekDate)
                        total_booking_amount_received++
                        if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.weekDate)
                        total_project_closed++
                    }
                        
                    }
                }
                })
               

            }
            else if (type === 'Last 30 Days' || type === 'Custom') {
               
                this.monthAllData.forEach(report => {
                  
                    if (report.lead_owner === user.user_name) {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if(this.currentUser===''){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.monthDate)
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.monthDate)
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.monthDate)
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.monthDate)
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.monthDate)
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.monthDate)
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.monthDate)
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.monthDate)
                            total_project_closed++
                            
                            
                        }
                        else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.monthDate)
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.monthDate)
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.monthDate)
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.monthDate)
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.monthDate)
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.monthDate)
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.monthDate)
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.monthDate)
                            total_project_closed++

                        }

                        
                    }
                    else if (this.currentCity === '') {
                        if(this.currentUser===''){
                            lead_owner=report.lead_owner
                            reporting_to= user.reporting_to
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.monthDate)
                            total_patch++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.monthDate)
                            total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.monthDate)
                            total_hc_completed++
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.monthDate)
                            total_follow++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.monthDate)
                            total_handover_to_design++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.monthDate)
                            total_proposal_sent++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.monthDate)
                            total_booking_amount_received++
                            if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.monthDate)
                            total_project_closed++
                    }
                    else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                        lead_owner=report.lead_owner
                        reporting_to= user.reporting_to
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.monthDate)
                        total_patch++
                        if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.monthDate)
                        total_hc_booked++
                        if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.todayDate && report.hc_completed_date_time >= this.state.monthDate)
                        total_hc_completed++
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' && report.follow_up_date_time <= this.state.todayDate && report.follow_up_date_time >= this.state.monthDate)
                        total_follow++
                        if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.monthDate)
                        total_handover_to_design++
                        if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.todayDate && report.proposal_sent_date_time >= this.state.monthDate)
                        total_proposal_sent++
                        if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.todayDate && report.booking_amount_received_date_time >= this.state.monthDate)
                        total_booking_amount_received++
                        if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.monthDate)
                        total_project_closed++
                    }
                        
                    }
                }
                })
               

            }
        }
    }
    if(lead_owner!==''){
    sales_report.push({
        lead_owner: lead_owner, reporting_to: reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
        total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
        total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
        total_project_closed: total_project_closed, date_time: this.state.todayDate, total_lead_patched: total_patch
    });
}
    })
    this.setState({ reportData: sales_report })
        }
        else if(viewType==="HCTracker"){
            var hc_report = [];
            let name_of_designer='',  total_closure = 0;
            if (type === "Today") {
                this.todayAllData.forEach(todayHCData => {
                    if (todayHCData.hc_booked_date_time !== undefined && todayHCData.hc_booked_date_time !== '' && todayHCData.hc_booked_date_time.includes(this.state.todayDate)){
                        if (this.currentUser !== '' && (this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                        }
                    }
                
                   
                });
                
            }
            else if (type === "Yesterday") {
                this.yesterdayAllData.forEach(report => {
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                        }
                    }
                
                   
                });
            }
            else if (type === 'Last 7 Days') {
                this.weekAllData.forEach(report => {
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                        }
                    }
                
                   
                });
            }
            else if (type === 'Last 30 Days' || type === 'Custom') {
                this.monthAllData.forEach(report => {
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' &&  report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.monthDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (report.designer_name !== '' && report.designer_name !== null && report.designer_name !== undefined)
                                name_of_designer = report.designer_name
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    project_value: report.project_estimate, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    stage: report.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                        }
                    }
                
                   
                });
            }
           
            this.setState({ reportData: hc_report })
        }
        else if(viewType==="DesignTeamReport"){
            let design_tracker_report=[]
            var users = this.props.PnkStore.GetData('userlist').rows;
            users.forEach((user, i) => {
                let total_handover = 0, lead_owner = '', total_project_closed = 0, design_in_progress = 0, proposal_uploaded = 0, design_change = 0,designer='',total_proposal_sent=0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Designer')) {   
            if (type === "Today") {
                this.todayAllData.forEach(report => {
                    if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.todayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                                // designer=
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }


                    }
                     if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time.includes(this.state.todayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_project_closed++
                                lead_owner = report.lead_owner
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                     }
                     if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.todayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_proposal_sent++
                               
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
    
                    }
                })
              
                if (lead_owner!=='') {
                    design_tracker_report.push({
                        designer_name:designer,
                         project_closed: total_project_closed, total_handover: total_handover,
                        handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                        design_change: design_change,total_proposal_sent:total_proposal_sent
                    });
                }
            }
            else if (type === "Yesterday") {
                this.yesterdayAllData.forEach(report => {
                    if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time.includes(this.state.yesterdayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                                // designer=
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }

                    }
                    if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_proposal_sent++
                               
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
    
                    }
                    if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time.includes(this.state.yesterdayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_project_closed++
                                lead_owner = report.lead_owner
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                    }
                })
                if (lead_owner!=='') {
                    design_tracker_report.push({
                        designer_name:designer,
                        handover_to_designer: designer, project_closed: total_project_closed, total_handover: total_handover,
                        handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                        design_change: design_change,total_proposal_sent:total_proposal_sent
                    });
                }
            }
            else if (type === 'Last 7 Days') {
                this.weekAllData.forEach(report => {
                    if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.weekDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                                // designer=
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }
                    }
                    if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time.includes(this.state.yesterdayDate)){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_proposal_sent++
                               
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
    
                    }
                    if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.weekDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_project_closed++
                                lead_owner = report.lead_owner
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                    }
                })
                if (lead_owner!=='') {
                    design_tracker_report.push({
                        designer_name:designer,
                        handover_to_designer: designer, project_closed: total_project_closed, total_handover: total_handover,
                        handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                        design_change: design_change,total_proposal_sent:total_proposal_sent
                    });
                }
            }
            else if (type === 'Last 30 Days' || type === 'Custom') {
                this.monthAllData.forEach(report => {
                    if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.monthDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                                // designer=
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name)
                                {
                                lead_owner = report.lead_owner
                                designer=report.designer_name
                                total_handover++
                                }
                            }
                        }
                    }
                    if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.handover_to_designer_date_time <= this.state.todayDate && report.handover_to_designer_date_time >= this.state.monthDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_proposal_sent++
                               
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                }
                            }
                        }
    
                    }
                    if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.todayDate && report.project_close_date_time >= this.state.monthDate){
                        if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                total_project_closed++
                                lead_owner = report.lead_owner
                            }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                            else if (this.currentCity === '') {
                                if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                            }
                        }
                    }
                })
                if (lead_owner!=='') {
                    design_tracker_report.push({
                        designer_name:designer,
                        handover_to_designer: designer, project_closed: total_project_closed, total_handover: total_handover,
                        handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                        design_change: design_change,total_proposal_sent:total_proposal_sent
                    });
                }
            }
        
    }
})
this.setState({ reportData: design_tracker_report })
}
  else if(viewType==="HC-PM-Schedule")     {

    let hc_pm_report = []
    let hc_user_list = []
    let hc_time_list = []
    let final_hc_array = []
    let current_array = []
    let hc_user_list_temp = []
    let final_date_array = []
    if (type === "Today") {
        if(this.hcPmScheduleType==='hc')
        {
            this.todayAllData.forEach(report => {
            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.todayDate)){
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                if (this.currentCity === '') {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                current_array.push(report)
            }
        })
          
        }
        else if(this.hcPmScheduleType==="project-meeting"){
            
            this.todayAllData.forEach(report => {
                if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '' && report.project_meeting_date_time.includes(this.state.todayDate)){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })
        }
        else if(this.hcPmScheduleType==="vc"){
            this.todayAllData.forEach(report => {
                if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '' && report.vc_completed_date_time.includes(this.state.todayDate)){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })

        }
    }
    if (type === "Yesterday") {
        if(this.hcPmScheduleType==='hc')
        {
            this.yesterdayAllData.forEach(report => {
            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time.includes(this.state.yesterdayDate)){
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                if (this.currentCity === '') {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                current_array.push(report)
            }
        })
          
        }
        else if(this.hcPmScheduleType==="project-meeting"){
            
            this.yesterdayAllData.forEach(report => {
                if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '' && report.project_meeting_date_time.includes(this.state.yesterdayDate)){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })
        }
        else if(this.hcPmScheduleType==="vc"){
            this.yesterdayAllData.forEach(report => {
                if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '' && report.vc_completed_date_time.includes(this.state.yesterdayDate)){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })

        }
    }
    else if (type === 'Last 7 Days') {
        if(this.hcPmScheduleType==='hc')
        {
            this.weekAllData.forEach(report => {
            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' &&  report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.weekDate){
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                if (this.currentCity === '') {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                current_array.push(report)
            }
        })
          
        }
        else if(this.hcPmScheduleType==="project-meeting"){
            
            this.weekAllData.forEach(report => {
                if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '' &&  report.project_meeting_date_time <= this.state.todayDate && report.project_meeting_date_time >= this.state.weekDate){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })
        }
        else if(this.hcPmScheduleType==="vc"){
            this.weekAllData.forEach(report => {
                if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '' &&  report.vc_completed_date_time <= this.state.todayDate && report.vc_completed_date_time >= this.state.weekDate){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })

        }
    }
    else if (type === 'Last 30 Days' || type === 'Custom') {
        if(this.hcPmScheduleType==='hc')
        {
            this.monthAllData.forEach(report => {
            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.todayDate && report.hc_booked_date_time >= this.state.monthDate){
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                if (this.currentCity === '') {
                    if (report.lead_owner !== undefined && report.lead_owner !== '') {
                        if (!hc_user_list_temp.includes(report.lead_owner)) {
                            hc_user_list_temp.push(report.lead_owner);
                        }
                    }
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                        hc_time_list.push(report.hc_booked_date_time);
                    }
                }
                current_array.push(report)
            }
        })
          
        }
        else if(this.hcPmScheduleType==="project-meeting"){
            
            this.monthAllData.forEach(report => {
                if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '' && report.project_meeting_date_time <= this.state.todayDate && report.project_meeting_date_time >= this.state.monthDate){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                            hc_time_list.push(report.project_meeting_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })
        }
        else if(this.hcPmScheduleType==="vc"){
            this.monthAllData.forEach(report => {
                if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '' && report.vc_completed_date_time <= this.state.todayDate && report.vc_completed_date_time >= this.state.monthDate){
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (report.lead_owner !== undefined && report.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(report.lead_owner)) {
                                hc_user_list_temp.push(report.lead_owner);
                            }
                        }
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                            hc_time_list.push(report.vc_completed_date_time);
                        }
                    }
                    current_array.push(report)
                }
            })

        }
    }
    hc_user_list_temp.forEach(element => {
        hc_user_list.push({ 'val': element });
    })

    hc_user_list.sort();

    hc_user_list.sort((a, b) => a.val.localeCompare(b.val))
           

            final_hc_array.push({ 'Time': 'Time', list: hc_user_list })
          
            var final_time_array = hc_time_list.filter((v, i, a) => a.indexOf(v) === i);
            final_time_array.sort((a, b) => a.localeCompare(b))
            final_time_array.forEach(date_time => {
                let typeIndex = final_date_array.findIndex(item => date_time.substr(0, 10) === item);
                if (typeIndex === -1) {
                    final_date_array.push(date_time.substr(0, 10))
                }
            })

            let arr_date = [];
            final_date_array.forEach(date => {
                let arr_list = [];
                final_time_array.forEach(time => {
                    if (time.includes(date)) {

                        let user_list_arr = [];

                        hc_user_list.forEach(user => {
                            let count = 0;
                            let details = [];
                            current_array.forEach((item, i) => {

                                if (this.hcPmScheduleType === 'hc') {
                                    if (item.hc_booked_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }

                                else if (this.hcPmScheduleType === 'project-meeting') {
                                    if (item.project_meeting_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }
                                else if (this.hcPmScheduleType === 'vc') {
                                    if (item.vc_completed_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }
                            });

                            user_list_arr.push({ lead_owner: user.val, lead_details: details })

                        });
                        

                        let typeIndex = arr_list.findIndex(item => time.substr(0, 10) === item.Time);
                        // alert(typeIndex);
                        if (typeIndex === -1) {
                            // arr_date.push({ list: arr_list })
                            arr_list.push({ 'Time': time, list: user_list_arr })
                        }
                        else {
                            arr_list[typeIndex].list = user_list_arr
                        }
                        
                    }
                });
           
                final_hc_array.push({ 'date': date, 'timearr': arr_list });

            });

  
           
            this.setState({ reportData: final_hc_array, timeArray: [] })

  }
           
       
    }
    RenderCustomReport = (viewtype) => {

      

        let patch_date_time_data = [];
        let lead_generate_data = []
        let dead_lead_data = []
        let pending_lead_data = []
        let sales_report = [];
        let customAllData=[]
        var users = this.props.PnkStore.GetData('userlist').rows;
        let total_in_progress = 0, total_hc_booked = 0, total_hc_completed = 0, total_design_handovered = 0, total_proposal_sent = 0,total_proposal_uploaded=0,total_booking_amount_received=0;
        users.forEach((user, i) => {
            if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')
           ) {
                if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                    this.customData.forEach((element, i) => {
                       if(element.lead_department[0].profile_name.includes("Sales")){
                    if(user.user_name===element.lead_owner){
                       
                        if ((element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate+' 23:59:59' && element.patch_date_time >= this.state.customStartDate)
                        ||(element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate)
                        ||(element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate)
                        ||(element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && element.handover_to_designer_date_time >= this.state.customStartDate)
                        ||(element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && element.proposal_sent_date_time >= this.state.customStartDate)
                        ||(element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.customEndDate+' 23:59:59' && element.proposal_uploaded_date_time >= this.state.customStartDate)
                        ||(element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && element.booking_amount_received_date_time >= this.state.customStartDate)
                        || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate)
                        || (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && element.follow_up_date_time >= this.state.customStartDate)
                        || (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate)
                        ||(element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.customStartDate) 
                        ||(element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.customEndDate+' 23:59:59' && element.under_const_date_time >= this.state.customStartDate) 

                        ) { 
                            customAllData.push(element)
                        }
                    }
                }
            })
        }
    }
                 
        })
        if (viewtype === "default") {

            customAllData.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if(this.currentUser===''){
                            if (report.patch_date_time !== undefined && report.patch_date_time !== '' &&  report.patch_date_time <= this.state.customEndDate+' 23:59:59' && report.patch_date_time >= this.state.customStartDate)
                            total_in_progress++
                        if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_booked_date_time >= this.state.customStartDate)
                            total_hc_booked++
                        if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_completed_date_time >= this.state.customStartDate)
                            total_hc_completed++
                        if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && report.handover_to_designer_date_time >= this.state.customStartDate)
                            total_design_handovered++
                            if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_sent_date_time >= this.state.customStartDate)
                            total_proposal_sent++
                        if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_uploaded_date_time >= this.state.customStartDate)
                            total_proposal_uploaded++
                        if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && report.booking_amount_received_date_time >= this.state.customStartDate)
                            total_booking_amount_received++
                            }
                            else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                if (report.patch_date_time !== undefined && report.patch_date_time !== '' &&  report.patch_date_time <= this.state.customEndDate+' 23:59:59' && report.patch_date_time >= this.state.customStartDate)
                                total_in_progress++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_booked_date_time >= this.state.customStartDate)
                                total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_completed_date_time >= this.state.customStartDate)
                                total_hc_completed++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && report.handover_to_designer_date_time >= this.state.customStartDate)
                                total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_sent_date_time >= this.state.customStartDate)
                                total_proposal_sent++
                            if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_uploaded_date_time >= this.state.customStartDate)
                                total_proposal_uploaded++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && report.booking_amount_received_date_time >= this.state.customStartDate)
                                total_booking_amount_received++
                            }
                            
                        }

                  
                        else if (this.currentCity === '') {
                            if(this.currentUser===''){
                                if (report.patch_date_time !== undefined && report.patch_date_time !== '' &&  report.patch_date_time <= this.state.customEndDate+' 23:59:59' && report.patch_date_time >= this.state.customStartDate)
                                total_in_progress++
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_booked_date_time >= this.state.customStartDate)
                                total_hc_booked++
                            if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_completed_date_time >= this.state.customStartDate)
                                total_hc_completed++
                            if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && report.handover_to_designer_date_time >= this.state.customStartDate)
                                total_design_handovered++
                                if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_sent_date_time >= this.state.customStartDate)
                                total_proposal_sent++
                            if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_uploaded_date_time >= this.state.customStartDate)
                                total_proposal_uploaded++
                            if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && report.booking_amount_received_date_time >= this.state.customStartDate)
                                total_booking_amount_received++
                                }
                                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                    if (report.patch_date_time !== undefined && report.patch_date_time !== '' &&  report.patch_date_time <= this.state.customEndDate+' 23:59:59' && report.patch_date_time >= this.state.customStartDate)
                                    total_in_progress++
                                if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '' && report.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_booked_date_time >= this.state.customStartDate)
                                    total_hc_booked++
                                if (report.hc_completed_date_time !== undefined && report.hc_completed_date_time !== '' && report.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_completed_date_time >= this.state.customStartDate)
                                    total_hc_completed++
                                if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && report.handover_to_designer_date_time >= this.state.customStartDate)
                                    total_design_handovered++
                                    if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_sent_date_time >= this.state.customStartDate)
                                    total_proposal_sent++
                                if (report.proposal_uploaded_date_time !== undefined && report.proposal_uploaded_date_time !== '' && report.proposal_uploaded_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_uploaded_date_time >= this.state.customStartDate)
                                    total_proposal_uploaded++
                                if (report.booking_amount_received_date_time !== undefined && report.booking_amount_received_date_time !== '' && report.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && report.booking_amount_received_date_time >= this.state.customStartDate)
                                    total_booking_amount_received++
                                }
                            
                        }
                    })
                  
               
                    // if (total_in_progress > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_design_handovered>0 || total_proposal_sent>0 || total_proposal_uploaded>0|| total_booking_amount_received>0) {
                        sales_report.push({
                            total_in_progress: total_in_progress, total_hc_booked: total_hc_booked,
                            total_hc_completed: total_hc_completed, total_design_handovered: total_design_handovered,
                            total_proposal_sent: total_proposal_sent,total_proposal_uploaded:total_proposal_uploaded,
                            total_booking_amount_received:total_booking_amount_received
                        })
                    // }
                    this.setState({ reportData: sales_report })
        }
        else if (viewtype === "under-construction") {
            let under_const_data=[],under_const_arr=[];

            customAllData.forEach(report => {
               
                        if (report.under_const_date_time !== undefined && report.under_const_date_time !== '' &&  report.under_const_date_time <= this.state.customEndDate+' 23:59:59' && report.under_const_date_time >= this.state.customStartDate)
                        {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) 
                            {
                                if(this.currentUser==='')
                                {
                                    under_const_arr.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    under_const_date_time: report.under_const_date_time, 
                                    client_mobile:report.user_mobile_no,
                                    id:report._id,
                                    date_time:report.date_time,
                                    update_on:report.updated_on
                                    });
                                }
                                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                                    under_const_arr.push({
                                        lead_owner: report.lead_owner, 
                                        client_name: report.user_name, 
                                        under_const_date_time: report.under_const_date_time, 
                                        client_mobile:report.user_mobile_no,
                                        id:report._id,
                                        date_time:report.date_time,
                                        update_on:report.updated_on
                                        });
                                }
                    }
                    else if (this.currentCity === '') {
                        if(this.currentUser==='')
                        {
                            under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on
                            });
                        }
                        else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                            under_const_arr.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                under_const_date_time: report.under_const_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id,
                                date_time:report.date_time,
                                update_on:report.updated_on
                                });
                        }
                    }

                }
                
            
    });
    this.setState({
       reportData: under_const_arr
    })
        }
        else if(this.state.currentViewType==='sales-rm-daily-report'){
            let customDailyReport=[];
        let daily_report_array=[]
        users.forEach((user, i) => {
            customAllData.forEach(reportData=>{
                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time <= this.state.customEndDate+' 23:59:59' && reportData.project_meeting_completed_date_time >= this.state.customStartDate
                || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && reportData.hc_completed_date_time >= this.state.customStartDate
                    || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && reportData.vc_completed_date_time >= this.state.customStartDate){

                if(user.user_name===reportData.lead_owner){
                    if (this.currentCity !== '' && (this.currentCity === reportData.lead_city)) {
                        if(this.currentUser===''){
                    // console.loge.log("reportData",reportData)
                    daily_report_array.push({
                        'date':reportData.updated_on,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    daily_report_array.push({
                        'date':reportData.updated_on,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }

            }
            else if (this.currentCity === '') {
                if(this.currentUser===''){
                    daily_report_array.push({
                        'date':reportData.updated_on,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }
                else if(this.currentUser!=='' && this.currentUser===reportData.lead_owner){
                    daily_report_array.push({
                        'date':reportData.updated_on,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })   
                }
            }
                  
                }
            }
            })
        })
        this.setState({
            reportData:daily_report_array
        })



        }
        else if (this.state.currentViewType === 'followupreport') {
            let follow_up_report = [];
            let follow_up_date_time_report = []
            
           
              
            customAllData.forEach(report => {
                      
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if(this.currentUser===''){
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' &&  report.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && report.follow_up_date_time >= this.state.customStartDate){
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                }
                }
                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' &&  report.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && report.follow_up_date_time >= this.state.customStartDate){
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                    }
                }
            }
                else if (this.currentCity === '') {
                  
                    if(this.currentUser===''){
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' &&  report.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && report.follow_up_date_time >= this.state.customStartDate){
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                }
                }
                else if(this.currentUser!=='' && this.currentUser===report.lead_owner){
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' &&  report.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && report.follow_up_date_time >= this.state.customStartDate){
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                    }
                }
                }
                    
                

            })
            follow_up_report.sort((a,b)=> new Date(b.follow_up_date_time).getTime()-new Date(a.follow_up_date_time).getTime());
            this.setState({
                reportData: follow_up_report
            })
        }
        else if (viewtype === "details") {
           let current_details=[]
                
                    customAllData.forEach(patchData => {
                        if (this.currentCity !== '' && (this.currentCity === patchData.lead_city)) {
                            if(this.currentUser===''){
                                    current_details.push({
                                        lead_patched_date:patchData.patch_date_time,
                                        last_edited: patchData.updated_on,
                                      client_name: patchData.user_name, 
                                      client_number: patchData.user_mobile_no,
                                        lead_stage: patchData.lead_current_stage,
                                        lead_owner:patchData.lead_owner,
                                        comment:patchData.crmComments
                                    })
                                }
                                    else if(this.currentUser!=='' && this.currentUser===patchData.lead_owner){
                                        current_details.push({
                                            lead_patched_date:patchData.patch_date_time,
                                            last_edited: patchData.updated_on,
                                          client_name: patchData.user_name, 
                                          client_number: patchData.user_mobile_no,
                                            lead_stage: patchData.lead_current_stage,
                                            lead_owner:patchData.lead_owner,
                                            comment:patchData.crmComments
                                        })
                                    }
                                
                        }
                        else if (this.currentCity === '') {
                            if(this.currentUser===''){
                                current_details.push({
                                    lead_patched_date:patchData.patch_date_time,
                                    last_edited: patchData.updated_on,
                                  client_name: patchData.user_name, 
                                  client_number: patchData.user_mobile_no,
                                    lead_stage: patchData.lead_current_stage,
                                    lead_owner:patchData.lead_owner,
                                    comment:patchData.crmComments
                                })
                            }
                                else if(this.currentUser!=='' && this.currentUser===patchData.lead_owner){
                                    current_details.push({
                                        lead_patched_date:patchData.patch_date_time,
                                        last_edited: patchData.updated_on,
                                      client_name: patchData.user_name, 
                                      client_number: patchData.user_mobile_no,
                                        lead_stage: patchData.lead_current_stage,
                                        lead_owner:patchData.lead_owner,
                                        comment:patchData.crmComments
                                    })
                                } 
                        }
                    })
                   
                    this.setState({ reportData: current_details }) 
        }
        else if (viewtype === "meeting-tracker") {
           //alert('jkhjgh')
            let hc_reports = [];
            let hc_datetime_report = []
            let pm_date_time_report=[]
            let vc_date_time_report=[]
            let users = this.props.PnkStore.GetData('userlist').rows;
            // console.log("this.customData",this.customData)
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                        hc_datetime_report.push(element)
                    }
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate) {
                        pm_date_time_report.push(element)
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                        vc_date_time_report.push(element)
                    }
                }
                
               
            })
            // console.log("hc_datetime_report",hc_datetime_report)
            users.forEach((user, i) => {
                // let lead_owner = '', client_name = '', project_value = '', hc_date_time = '', stage = '', name_of_designer = '';
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                    if(this.hcPmScheduleType==='hc')
                    {
                      
                    hc_datetime_report.forEach(customHcData => {
                        if ( this.currentUser !== '' && ( this.currentUser === customHcData.lead_owner)) {
                            if (customHcData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customHcData.lead_city)) {
                                  
                                    hc_reports.push({
                                        client_name : customHcData.user_name,
                                        hc_date_time :customHcData.hc_booked_date_time,
                                        lead_owner:customHcData.lead_owner,
                                        sales_tl:user.reporting_to,
                                        location:customHcData.preferred_area,
                                        hc_completed_date_time:customHcData.hc_completed_date_time
                                    })

                                    
                                }
                                else if (this.currentCity === '') {
                                    hc_reports.push({
                                        client_name : customHcData.user_name,
                                        hc_date_time :customHcData.hc_booked_date_time,
                                        lead_owner:customHcData.lead_owner,
                                        sales_tl:user.reporting_to,
                                        location:customHcData.preferred_area,
                                        hc_completed_date_time:customHcData.hc_completed_date_time
                                    })
                                }
                            }
                        }
                        else if ( this.currentUser === '') {
                            if (customHcData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customHcData.lead_city)) {
                                    hc_reports.push({
                                        client_name : customHcData.user_name,
                                        hc_date_time :customHcData.hc_booked_date_time,
                                        lead_owner:customHcData.lead_owner,
                                        sales_tl:user.reporting_to,
                                        location:customHcData.preferred_area,
                                        hc_completed_date_time:customHcData.hc_completed_date_time
                                    })
                                }
                                else if (this.currentCity === '') {
                                    hc_reports.push({
                                        client_name : customHcData.user_name,
                                        hc_date_time :customHcData.hc_booked_date_time,
                                        lead_owner:customHcData.lead_owner,
                                        sales_tl:user.reporting_to,
                                        location:customHcData.preferred_area,
                                        hc_completed_date_time:customHcData.hc_completed_date_time
                                    })
                                }
                            }
                        }
                       
                    });
                }
                if(this.hcPmScheduleType==='project-meeting')
                {
                    pm_date_time_report.forEach(report => {
                        if(user.user_name===report.lead_owner){
                        if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
    
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                        }
                    }
                    })
                }
                if(this.hcPmScheduleType==="vc"){
                    vc_date_time_report.forEach(report => {
                        if(user.user_name===report.lead_owner){
                        if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                   
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                   
                                    
                                });
                            }
                        }
    
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                console.log("report",report)
                                hc_reports.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                        }
                }
                   
                })
                }
                    
                }
            }

            })
            this.setState({
                reportData: hc_reports
            })
        }

       else if (viewtype === "salesreport") {
            let sales_report=[]
            var users = this.props.PnkStore.GetData('userlist').rows;
            users.forEach((user, i) => {
                let total_patch = 0, total_hc_booked = 0, total_hc_completed = 0, total_follow = 0, total_pending_design = 0,lead_owner='',reporting_to='',
                    total_handover_to_design = 0, total_estimate_pending = 0, total_proposal_sent = 0, total_booking_amount_received = 0, total_project_closed = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                customAllData.forEach(todayData => {
                   
                    if (todayData.lead_owner === user.user_name) {
                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if(this.currentUser===''){
                            lead_owner=todayData.lead_owner
                            reporting_to= user.reporting_to
                            if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time <= this.state.customEndDate+' 23:59:59' && todayData.patch_date_time >= this.state.customStartDate)
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_booked_date_time >= this.state.customStartDate)
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_completed_date_time >= this.state.customStartDate)
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && todayData.follow_up_date_time >= this.state.customStartDate)
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && todayData.handover_to_designer_date_time >= this.state.customStartDate)
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && todayData.proposal_sent_date_time >= this.state.customStartDate)
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== ''  && todayData.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && todayData.booking_amount_received_date_time >= this.state.customStartDate)
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time <= this.state.customEndDate+' 23:59:59' && todayData.project_close_date_time >= this.state.customStartDate)
                            total_project_closed++
                            
                            
                        }
                        else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                            lead_owner=todayData.lead_owner
                            reporting_to= user.reporting_to
                            if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time <= this.state.customEndDate+' 23:59:59' && todayData.patch_date_time >= this.state.customStartDate)
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_booked_date_time >= this.state.customStartDate)
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_completed_date_time >= this.state.customStartDate)
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && todayData.follow_up_date_time >= this.state.customStartDate)
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && todayData.handover_to_designer_date_time >= this.state.customStartDate)
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && todayData.proposal_sent_date_time >= this.state.customStartDate)
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== ''  && todayData.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && todayData.booking_amount_received_date_time >= this.state.customStartDate)
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time <= this.state.customEndDate+' 23:59:59' && todayData.project_close_date_time >= this.state.customStartDate)
                            total_project_closed++

                        }

                        
                    }
                    else if (this.currentCity === '') {
                        if(this.currentUser===''){
                            lead_owner=todayData.lead_owner
                            reporting_to= user.reporting_to
                            if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time <= this.state.customEndDate+' 23:59:59' && todayData.patch_date_time >= this.state.customStartDate)
                            total_patch++
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_booked_date_time >= this.state.customStartDate)
                            total_hc_booked++
                            if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_completed_date_time >= this.state.customStartDate)
                            total_hc_completed++
                            if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && todayData.follow_up_date_time >= this.state.customStartDate)
                            total_follow++
                            if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && todayData.handover_to_designer_date_time >= this.state.customStartDate)
                            total_handover_to_design++
                            if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && todayData.proposal_sent_date_time >= this.state.customStartDate)
                            total_proposal_sent++
                            if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== ''  && todayData.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && todayData.booking_amount_received_date_time >= this.state.customStartDate)
                            total_booking_amount_received++
                            if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time <= this.state.customEndDate+' 23:59:59' && todayData.project_close_date_time >= this.state.customStartDate)
                            total_project_closed++
                    }
                    else if(this.currentUser!=='' && this.currentUser===todayData.lead_owner){
                        lead_owner=todayData.lead_owner
                        reporting_to= user.reporting_to
                        if (todayData.patch_date_time !== undefined && todayData.patch_date_time !== '' && todayData.patch_date_time <= this.state.customEndDate+' 23:59:59' && todayData.patch_date_time >= this.state.customStartDate)
                        total_patch++
                        if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '' && todayData.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_booked_date_time >= this.state.customStartDate)
                        total_hc_booked++
                        if (todayData.hc_completed_date_time !== undefined && todayData.hc_completed_date_time !== '' && todayData.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && todayData.hc_completed_date_time >= this.state.customStartDate)
                        total_hc_completed++
                        if (todayData.follow_up_date_time !== undefined && todayData.follow_up_date_time !== '' && todayData.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && todayData.follow_up_date_time >= this.state.customStartDate)
                        total_follow++
                        if (todayData.handover_to_designer_date_time !== undefined && todayData.handover_to_designer_date_time !== '' && todayData.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && todayData.handover_to_designer_date_time >= this.state.customStartDate)
                        total_handover_to_design++
                        if (todayData.proposal_sent_date_time !== undefined && todayData.proposal_sent_date_time !== '' && todayData.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && todayData.proposal_sent_date_time >= this.state.customStartDate)
                        total_proposal_sent++
                        if (todayData.booking_amount_received_date_time !== undefined && todayData.booking_amount_received_date_time !== ''  && todayData.booking_amount_received_date_time <= this.state.customEndDate+' 23:59:59' && todayData.booking_amount_received_date_time >= this.state.customStartDate)
                        total_booking_amount_received++
                        if (todayData.project_close_date_time !== undefined && todayData.project_close_date_time !== '' && todayData.project_close_date_time <= this.state.customEndDate+' 23:59:59' && todayData.project_close_date_time >= this.state.customStartDate)
                        total_project_closed++
                    }
                        
                    }
                }
                })
               

            
        }
    }
    if(lead_owner!==''){
        sales_report.push({
            lead_owner: lead_owner, reporting_to: reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
            total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
            total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
            total_project_closed: total_project_closed, date_time: this.state.todayDate, total_lead_patched: total_patch
        });
    }
        })
        this.setState({ reportData: sales_report })
        }
        else if(viewtype==="HCTracker"){
            var hc_report = [];
            let name_of_designer='',  total_closure = 0;
               customAllData.forEach(todayHCData => {
                    if (todayHCData.hc_booked_date_time !== undefined && todayHCData.hc_booked_date_time !== '' &&  todayHCData.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && todayHCData.hc_booked_date_time >= this.state.customStartDate){
                        if (this.currentUser !== '' && (this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                            else if (this.currentCity === '') {
                                if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                                name_of_designer = todayHCData.designer_name
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    project_value: todayHCData.project_estimate, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    stage: todayHCData.lead_current_stage,
                                    name_of_designer: name_of_designer, 
                                    total_closure: total_closure
                                });
                            }
                        }
                    }
                
                   
                });
                this.setState({ reportData: hc_report })
            }
            else if(viewtype==="DesignTeamReport"){
                let design_tracker_report=[]
                var users = this.props.PnkStore.GetData('userlist').rows;
                users.forEach((user, i) => {
                    let total_handover = 0, lead_owner = '', total_project_closed = 0, design_in_progress = 0, proposal_uploaded = 0, design_change = 0,designer='',total_proposal_sent=0;
                    if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Designer')) {   
               
                    customAllData.forEach(report => {
                        if (report.handover_to_designer_date_time !== undefined && report.handover_to_designer_date_time !== '' && report.handover_to_designer_date_time <= this.state.customEndDate+' 23:59:59' && report.handover_to_designer_date_time >= this.state.customStartDate){
                            if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if(report.designer_name===user.user_name)
                                    {
                                    lead_owner = report.lead_owner
                                    designer=report.designer_name
                                    total_handover++
                                    }
                                    // designer=
                                }
                                else if (this.currentCity === '') {
                                    if(report.designer_name===user.user_name)
                                    {
                                    lead_owner = report.lead_owner
                                    designer=report.designer_name
                                    total_handover++
                                    }
                                }
                            }
                            else if (this.currentUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if(report.designer_name===user.user_name)
                                    {
                                    lead_owner = report.lead_owner
                                    designer=report.designer_name
                                    total_handover++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if(report.designer_name===user.user_name)
                                    {
                                    lead_owner = report.lead_owner
                                    designer=report.designer_name
                                    total_handover++
                                    }
                                }
                            }
    
    
                        }
                         if (report.project_close_date_time !== undefined && report.project_close_date_time !== '' && report.project_close_date_time <= this.state.customEndDate+' 23:59:59' && report.project_close_date_time >= this.state.customStartDate){
                            if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if(report.designer_name===user.user_name){
                                    total_project_closed++
                                    lead_owner = report.lead_owner
                                }
                                }
                                else if (this.currentCity === '') {
                                    if(report.designer_name===user.user_name){
                                        total_project_closed++
                                        lead_owner = report.lead_owner
                                    }
                                }
                            }
                            else if (this.currentUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if(report.designer_name===user.user_name){
                                        total_project_closed++
                                        lead_owner = report.lead_owner
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if(report.designer_name===user.user_name){
                                        total_project_closed++
                                        lead_owner = report.lead_owner
                                    }
                                }
                            }
                         }
                         if (report.proposal_sent_date_time !== undefined && report.proposal_sent_date_time !== '' && report.proposal_sent_date_time <= this.state.customEndDate+' 23:59:59' && report.proposal_sent_date_time >= this.state.customStartDate){
                            if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if(report.designer_name===user.user_name){
                                    total_proposal_sent++
                                   
                                }
                                }
                                else if (this.currentCity === '') {
                                    if(report.designer_name===user.user_name){
                                        total_proposal_sent++
                                    }
                                }
                            }
                            else if (this.currentUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if(report.designer_name===user.user_name){
                                        total_proposal_sent++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if(report.designer_name===user.user_name){
                                        total_proposal_sent++
                                    }
                                }
                            }
        
                        }
                    })
                  
                    if (lead_owner!=='') {
                        design_tracker_report.push({
                            designer_name:designer,
                             project_closed: total_project_closed, total_handover: total_handover,
                            handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                            design_change: design_change,total_proposal_sent:total_proposal_sent
                        });
                    }
                }
            })
            this.setState({ reportData: design_tracker_report })
        }
        else if(viewtype==="HC-PM-Schedule")     {

            let hc_pm_report = []
            let hc_user_list = []
            let hc_time_list = []
            let final_hc_array = []
            let current_array = []
            let hc_user_list_temp = []
            let final_date_array = []
           
                if(this.hcPmScheduleType==='hc')
                {
                    customAllData.forEach(report => {
                    if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== ''  && report.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && report.hc_booked_date_time >= this.state.customStartDate){
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if (report.lead_owner !== undefined && report.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(report.lead_owner)) {
                                    hc_user_list_temp.push(report.lead_owner);
                                }
                            }
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                                hc_time_list.push(report.hc_booked_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (report.lead_owner !== undefined && report.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(report.lead_owner)) {
                                    hc_user_list_temp.push(report.lead_owner);
                                }
                            }
                            if (report.hc_booked_date_time !== undefined && report.hc_booked_date_time !== '') {
                                hc_time_list.push(report.hc_booked_date_time);
                            }
                        }
                        current_array.push(report)
                    }
                })
                  
                }
                else if(this.hcPmScheduleType==="project-meeting"){
                    
                    this.todayAllData.forEach(report => {
                        if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '' && report.project_meeting_date_time <= this.state.customEndDate+' 23:59:59' && report.project_meeting_date_time >= this.state.customStartDate){
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner !== undefined && report.lead_owner !== '') {
                                    if (!hc_user_list_temp.includes(report.lead_owner)) {
                                        hc_user_list_temp.push(report.lead_owner);
                                    }
                                }
                                if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                                    hc_time_list.push(report.project_meeting_date_time);
                                }
                            }
                            if (this.currentCity === '') {
                                if (report.lead_owner !== undefined && report.lead_owner !== '') {
                                    if (!hc_user_list_temp.includes(report.lead_owner)) {
                                        hc_user_list_temp.push(report.lead_owner);
                                    }
                                }
                                if (report.project_meeting_date_time !== undefined && report.project_meeting_date_time !== '') {
                                    hc_time_list.push(report.project_meeting_date_time);
                                }
                            }
                            current_array.push(report)
                        }
                    })
                }
                else if(this.hcPmScheduleType==="vc"){
                    this.todayAllData.forEach(report => {
                        if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '' && report.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && report.vc_completed_date_time >= this.state.customStartDate){
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner !== undefined && report.lead_owner !== '') {
                                    if (!hc_user_list_temp.includes(report.lead_owner)) {
                                        hc_user_list_temp.push(report.lead_owner);
                                    }
                                }
                                if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                                    hc_time_list.push(report.vc_completed_date_time);
                                }
                            }
                            if (this.currentCity === '') {
                                if (report.lead_owner !== undefined && report.lead_owner !== '') {
                                    if (!hc_user_list_temp.includes(report.lead_owner)) {
                                        hc_user_list_temp.push(report.lead_owner);
                                    }
                                }
                                if (report.vc_completed_date_time !== undefined && report.vc_completed_date_time !== '') {
                                    hc_time_list.push(report.vc_completed_date_time);
                                }
                            }
                            current_array.push(report)
                        }
                    })
        
                }
                hc_user_list_temp.forEach(element => {
                    hc_user_list.push({ 'val': element });
                })
                hc_user_list.sort();
                final_hc_array.push({ 'Time': 'Time', list: hc_user_list })
             
                var final_time_array = hc_time_list.filter((v, i, a) => a.indexOf(v) === i);
                final_time_array.sort((a, b) => a.localeCompare(b))
                final_time_array.forEach(date_time => {
                    let typeIndex = final_date_array.findIndex(item => date_time.substr(0, 10) === item);
                    if (typeIndex === -1) {
                        final_date_array.push(date_time.substr(0, 10))
                    }
                })
    
                let arr_date = [];
                final_date_array.forEach(date => {
                    let arr_list = [];
                    final_time_array.forEach(time => {
                        if (time.includes(date)) {
    
                            let user_list_arr = [];
    
                            hc_user_list.forEach(user => {
                                let count = 0;
                                let details = [];
                                current_array.forEach((item, i) => {
    
                                    if (this.hcPmScheduleType === 'hc') {
                                        if (item.hc_booked_date_time === time && user.val === item.lead_owner) {
                                            details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                        }
                                    }
    
                                    else if (this.hcPmScheduleType === 'project-meeting') {
                                        if (item.project_meeting_date_time === time && user.val === item.lead_owner) {
                                            details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                        }
                                    }
                                    else if (this.hcPmScheduleType === 'vc') {
                                        if (item.vc_completed_date_time === time && user.val === item.lead_owner) {
                                            details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                        }
                                    }
                                });
    
                                user_list_arr.push({ lead_owner: user.val, lead_details: details })
    
                            });
                            
    
                            let typeIndex = arr_list.findIndex(item => time.substr(0, 10) === item.Time);
                            // alert(typeIndex);
                            if (typeIndex === -1) {
                                // arr_date.push({ list: arr_list })
                                arr_list.push({ 'Time': time, list: user_list_arr })
                            }
                            else {
                                arr_list[typeIndex].list = user_list_arr
                            }
                            
                        }
                    });
               
                    final_hc_array.push({ 'date': date, 'timearr': arr_list });
    
                });
    
      
            
                this.setState({ reportData: final_hc_array, timeArray: [] })

            }
        
        
    }
    ChangeSalesReportType = (type) => {
        this.setState({
            currentSalesReportType: type,hcPmScheduleType:'hc'
            
        })
        if(this.state.currentViewType==='callback'){
            this.RenderCallbackReport(this.salesTLReportData,type)
        }
         if(type!=='Custom'){
            this.RenderReport(this.state.currentViewType,type )
            }
            else if(type==='Custom'){
                this.RenderCustomReport(this.state.currentViewType)
            }
      
   
       

    }
    ChangeSalesReportTypeMobile = (event) => {
        this.setState({
            currentSalesReportType: event.target.value
        })

        if(this.state.currentViewType==="callback"){
            this.setState({
                getCallbackData:true
            })
        }
        else
        {
        if (event.target.value !== "Custom") {
            this.setState({ showCustomDateModal: false })
            this.RenderReport(this.state.currentViewType, event.target.value)
        }
        else if (event.target.value === "Custom") {
            this.setState({ showCustomDateModal: true })
            // this.RenderCustomReport(this.state.currentViewType)
        }
    }
    }
    HideCustomDateModal = () => {
        this.setState({ showCustomDateModal: false })
    }
    HandleGetUserListResponse = (res) => {
        if (res.data && res.data.userlist && res.data.userlist) {
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({ getUser: false })
    }


    GetCustomData = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;

        if(this.state.currentViewType==='callback')
        {
            this.setState({
                customStartDate: startDate,
                customEndDate: endDate, getCustomData: false,
                ddlPreferredCity: '-1',
                ddlCurrentSalesUser:'-1',
                getCustomCallbackData:true
            })
        }
        else{
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate, getCustomData: true,
            ddlPreferredCity: '-1',
            ddlCurrentSalesUser:'-1',
            getCustomCallbackData:false
        })
    }
        this.currentCity = ''
        this.currentUser=''
    }
    HandleGetCustomDatatResponse = (res) => {
      
        if (res !== false) {
            this.setState({
                getCustomData: false,
                showCustomDateModal: false
            })
           
            if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.customData = res.data.adminfirstmonthdata.rows
                this.RenderCustomReport(this.state.currentViewType)
            }
        }
        this.setState({
            getCustomData: false,
            showCustomDateModal: false
        })
    }

    ChangeViewReport = (event) => {
        
        this.setState({ currentViewType: event.target.value,currentSalesReportType:'Today' })
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport(event.target.value, 'Today')
        }
        else if (this.state.currentSalesReportType === "Custom") {
            this.RenderCustomReport(event.target.value)
        }
        if(event.target.name==='currentViewType')
        {
            if(event.target.value==='callback'){
                this.setState({getCallbackData:true})
            }
           else if(event.target.value==='meeting-tracker'){
                this.setState({getCallbackData:false,
                    getMeetingData:true,reportData:[]
                })
            }
            else if(this.monthData.length<0){
                this.setState({
                    getReportData:true
                })
            }
           
    
        } 
        if (window.location.href.indexOf("/dashboard/") > -1)
        this.props.componentprops.history.push(event.target.value);
    else
        this.props.componentprops.history.push('dashboard/' + event.target.value);
    }
    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    HandleHCSchedule = (type) => {
      
        this.setState({
            hcPmScheduleType: type,
            currentSalesReportType:'Today'

        })
        this.hcPmScheduleType=type;
        this.RenderReport("HC-PM-Schedule",'Today')
    }
    HandleGetCallbackReportResponse=(res)=>{
      
        if (res !== false) {
            if (res.data && res.data.callbackleads && res.data.callbackleads.rows && res.data.callbackleads.rows.length > 0) {
                this.salesTLReportData=res.data.callbackleads;
      
        this.RenderCallbackReport(res.data.callbackleads,'Today')

    }
   
}
this.setState({getCallbackData:false,getCustomCallbackData:false})
    }
    RenderSalesReport=(data,type)=>{

    }
    RenderCallbackReport=(data,type)=>{
 
       var callbackReportData=[]
        if(type==='Today'){
            if(data.rows && data.rows.length>0 ){
            data.rows.forEach((element, i) => {
             
                if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.todayDate)) {
                    if(element.lead_department[0].profile_name.includes('Sales')){
                        if(this.currentUser===''){
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }
                else if(this.currentUser!=='' && this.currentUser===element.lead_owner){
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }
                }
                    
                }
               
            })
        }
        }
        if(type==='Coming Days'){
            if(data.rows && data.rows.length>0 ){
            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time>this.state.todayDate+' 23:59:59') {
                    if(element.lead_department[0].profile_name.includes('Sales')){
                        if(this.currentUser===''){
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }

                else if(this.currentUser!=='' && this.currentUser===element.lead_owner){
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }
                
            }
                }
            })
            }        }
        // if(type==='Custom'){
        //     data.rows.forEach((element, i) => {
        //         if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate+' 23:59:59' &&  element.callback_date_time >= this.state.customStartDate) {
        //             if(element.lead_department[0].profile_name.includes('Sales')){
        //                 if(this.currentUser===''){
        //             callbackReportData.push({
        //                 acquisitionDate:element.date_time,
        //                 updatedOn:element.updated_on,
        //                 customerName:element.user_name,
        //                 customerContact:element.user_mobile_no,
        //                 customerEmail:element.user_email,
        //                 callbackDateTime:element.callback_date_time,
        //                 leadOwner:element.lead_owner,
        //                 id:element._id
        //             })
        //         }

        //         else if(this.currentUser!=='' && this.currentUser===element.lead_owner){
        //             callbackReportData.push({
        //                 acquisitionDate:element.date_time,
        //                 updatedOn:element.updated_on,
        //                 customerName:element.user_name,
        //                 customerContact:element.user_mobile_no,
        //                 customerEmail:element.user_email,
        //                 callbackDateTime:element.callback_date_time,
        //                 leadOwner:element.lead_owner,
        //                 id:element._id
        //             })
        //         }
                
        //     }
        //         }
        //     })
        // }
    
        callbackReportData.sort((a,b)=> new Date(a.callbackDateTime).getTime()-new Date(b.callbackDateTime).getTime());

      this.setState({
        callbackReportData:callbackReportData
      })
}
GotoLeadEntry=(lead_id)=>{

  
    AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/'+lead_id+ '/' + this.state.currentViewType);
}
GetMeetingCustomData=()=>{
    var dd = String(new Date(this.state.txtCustomMeetingStartDate).getDate()).padStart(2, '0');
    var mm = String(new Date(this.state.txtCustomMeetingStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = new Date(this.state.txtCustomMeetingStartDate).getFullYear();
    let startDate = yyyy + '-' + mm + '-' + dd;
    var end_dd = String(new Date(this.state.txtCustomMeetingEndDate).getDate()).padStart(2, '0');
    var end_mm = String(new Date(this.state.txtCustomMeetingEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
    var end_yyyy = new Date(this.state.txtCustomMeetingEndDate).getFullYear();
    let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
    this.setState({
        meetingWeekStartDate:startDate,
        meetingWeekEndDate:endDate,
        getMeetingData:true
    })
   
}
HandleGetMeetingDatatResponse=(res)=>{

   
   
    console.log("HandleGetMeetingDatatResponse",res.data)
    var users = this.props.PnkStore.GetData('userlist').rows;
    let meetingArray=[]
    if(res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length>0){
    //    alert(JSON.stringify(users))
        users.forEach((user, i) => {
            if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')
           ) {
                if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                    console.log("user_name",user.user_name)
        res.data.adminfirstmonthdata.rows.forEach(element=>{

            if(element.lead_current_stage==="HC Booked" && element.hc_booked_date_time!==undefined && element.hc_booked_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate
            ||
            element.lead_current_stage==="Project Meeting" && element.project_meeting_date_time!==undefined && element.project_meeting_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate
            ){
                if(user.user_name=== element.lead_owner){
                console.log("",element.hc_booked_date_time,element.project_meeting_date_time)
                if(element.lead_current_stage==="HC Booked" && element.hc_booked_date_time !==undefined && element.hc_booked_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate){
                    let typeIndex = meetingArray.findIndex(type => element.hc_booked_date_time.substr(0,10) ===type.date_time);
                    if (typeIndex !== -1) {
                        let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                        if (valIndex === -1) {
                            meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                            // meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type:'HC',user_details:[{client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}] });
                        }
                        else{
                            meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                          //  meetingArray[typeIndex].list[valIndex].user_details.push({client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id})
                        }
                    }
                    else{
                        meetingArray.push({ 'date_time': element.hc_booked_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                      //  meetingArray.push({'date_time': element.hc_booked_date_time.substr(0,10),list:[{'lead_owner': element.lead_owner, type:'HC',user_details:[{client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}]}]});
                    }
                }
                if( element.lead_current_stage==="Project Meeting" && element.project_meeting_date_time !==undefined && element.project_meeting_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate){
                    let typeIndex = meetingArray.findIndex(type => element.project_meeting_date_time.substr(0,10) ===type.date_time);
                    if (typeIndex !== -1) {
                        let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                        if (valIndex === -1) {
                            meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                           // meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type:'PM',user_details:[{client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}] });
                        }
                        else{
                            meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                           // meetingArray[typeIndex].list[valIndex].user_details.push({client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id})
                        }
                    }
                    else{
                        meetingArray.push({ 'date_time': element.project_meeting_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                     //   meetingArray.push({'date_time': element.project_meeting_date_time.substr(0,10),list:[{'lead_owner': element.lead_owner, type:'PM',user_details:[{client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}]}]});
                    }
                }
           
            }
        }
        })
    }
}
        })

    }
    console.log("meetingArray",meetingArray)
    meetingArray.sort((a,b)=> new Date(b.date_time).getTime()-new Date(a.date_time).getTime());
    
    this.setState({
        getMeetingData:false,
        reportData:meetingArray
    })

   // alert(JSON.stringify(this.state.reportData))
}
    render() {
        console.log("reportData", this.yesterdayAllData)
        return (
            <Container fluid>
                <div className="tele-caller-db-head">Sales TL Dashboard</div>
                <Options mystate={this.state} {...this.props} HandleUserInput={this.HandleUserInput}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    ChangeViewReport={this.ChangeViewReport}
                    ChangeSalesReportTypeMobile={this.ChangeSalesReportTypeMobile}
                    HandleHCSchedule={this.HandleHCSchedule}
                    callBackReportType={this.callBackReportType}
                    HandleMeetingType={this.HandleMeetingType}
                />
            {this.state.currentViewType === "default" || this.state.currentViewType === "details" || this.state.currentViewType==='followupreport' ?
                <Report mystate={this.state} {...this.props}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    HandleChangeDate={this.HandleChangeDate}
                    ShowSalesInsideModal={this.ShowSalesInsideModal}
                    GetCustomData={this.GetCustomData}
                    GotoLeadEntry={this.GotoLeadEntry}
                />:null}
                {this.state.showCustomDateModal === true ?
                    <MobCustomDatePicker
                        visible={this.state.showCustomDateModal}
                        mystate={this.state}
                        {...this.props}
                        HandleUserInput={this.HandleUserInput}
                        HideCustomDateModal={this.HideCustomDateModal}
                        GetCustomData={this.GetCustomData}
                    /> : null
                }
                {this.state.currentViewType === "callback" ?
                        <CallbackReport 
                        mystate={this.state} {...this.props}
                        callBackReportType={this.callBackReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        GotoLeadEntry={this.GotoLeadEntry}
                        />
                        :null
                }
                {this.state.currentViewType === "salesreport" ?
                <SalesReport
                 mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GetCustomData={this.GetCustomData}/>:null}

                  {this.state.currentViewType === "meeting-tracker" ?
                <MeetingTracker
                 mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GotoLeadEntry={this.GotoLeadEntry}
                 GetMeetingCustomData={this.GetMeetingCustomData}/>:null}
                   {this.state.currentViewType === "DesignTeamReport" ?
                <DesignTeamReport
                 mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GetCustomData={this.GetCustomData}/>:null}
               
                  {this.state.currentViewType === "HC-PM-Schedule" ?
                <HCPMSChedule
                 mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GotoLeadEntry={this.GotoLeadEntry}
                 GetCustomData={this.GetCustomData}/>:null}
                    {this.state.currentViewType === "sales-rm-daily-report" ?
                <RMDailyReport
                 mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GotoLeadEntry={this.GotoLeadEntry}
                 GetCustomData={this.GetCustomData}/>:null}
                   {this.state.currentViewType === "under-construction" ?
                        <UnderConstructionComp 
                        mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GetCustomData={this.GetCustomData}
                GotoLeadEntry={this.GotoLeadEntry}
                        />
                        :null
                }

                {this.state.getUser === true ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : ''}
                {(this.state.getReportData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetReportResponse) : ''}
                {(this.state.getCustomData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomDatatResponse) : ''}
                {((this.props.PnkStore.GetData("citylist") === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {(this.state.getCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetCallbackReportResponse) : ''}
                {(this.state.getCustomCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCallbackReportResponse) : ''}
{(this.state.getMeetingData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.meetingWeekStartDate, this.state.meetingWeekEndDate, this.HandleGetMeetingDatatResponse) : ''}
                
                {/*     {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
               

                {((this.state.getLeadData === true) ? GetLeadSource(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")} */}
            </Container>
        )
    }
}
export const SalesTLReportPage = PnkConnect(reportPage, "");