import React from 'react'
export default class Alert extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div id="oprationDiv" class="dropDown hideBlock popover">
                <div class="triangle"></div>
                <div class="popover-content">
                    {this.props.message}
                </div>
            </div>
            //     {this.props.message}
            // </div>
        )
    }
} 