
export const routes = [
    {
        name: "Dashboard"
    },
    {
        name: "Bulk Edit"
    },
    {
        name: "User Entry"
    },
    {
        name: "User List"
    },
    {
        name: "Lead Entry"
    },
    {
        name: "Lead List"
    },
    {
        name:"HC List"
    },
    // {
    //     name: "Booking Entry"
    // },
    // {
    //     name: "Booking List"
    // },
    // {
    //     name: "Activities"
    // },
    {
        name: "Profile List"
    },
    {
        name: "Profile Entry"
    },
    {
        name: "Lead Sources Entry"
    },
    {
        name: "Lead Sources List"
    },
     {
        name: "Reports"
    },
    // {
    //     name: "Property Masters"
    // },
    // {
    //     name: "Templates"
    // },
    // {
    //     name: "Map"
    // },
    // {
    //     name: "Reports"
    // },
    // {
    //     name: "Convert To Booking"
    // },
    // {
    //     name: "Incentives"
    // },
    // {
    //     name: "Pre Sales Automation"
    // },
    // {
    //     name: "Per-day Call Automation"
    // },
    // {
    //     name: "Call Plan Automation"
    // },
    // {
    //     name: "Partial Assignment"
    // },
    // {
    //     name: "Audit"
    // },
    {
        name: "Settings"
    },
    {
        name: "New Entry"
    },
    {
        name: "Exotel Calls"
    },

]

export const bookingstages = [
    {

        name: "Pending"
    },
    // {

    //     name: "In Negotiation"
    // },
    // {

    //     name: "Deal Closed"
    // },
    // {

    //     name: "Agreement Done By Buyer"
    // },
    // {

    //     name: "Builder Confirmed Booking"
    // },
    // {

    //     name: "Performa Invoice Raised"
    // },
    // {

    //     name: "Payment Received From Builder"
    // },
    // {

    //     name: "Cancelled"
    // },
]
export const leadstages = [
   
    {

        name: "Pending"
    },
    {

        name: "In Progress"
    },
    {

        name: "Dead"
    },
    {
        name: "Call Back"
    },
    {
        name: "RNR"
    },
    {
        name: "HC Booked"
    },
    {
        name: "VC Booked"
    },
    {
        name: "VC Completed"
    },
    {
        name: "HC Completed"
    },
    {
        name: "Handover to Designer"
    },
    {
        name: "Design In Progress"
    },
    {
        name: "Quote uploaded"
    },
    {
        name: "Design Uploaded"
    },
    {
        name: "Proposal Sent"
    },
    {
        name: "Follow up"
    },
    {
        name: "Design change"
    },
    {
        name: "Booking Amount Received"
    },
    {
        name: "Project Meeting"
    },
    {
        name: "Project closed"
    },
    {
        name: "Hold"
    },
    {
        name: "PP Follow Up"
    },
    {

        name: "PM Completed"
    },
    {

        name: "Under Construction"
    },
    {
        name: "HC NA"
    },
    {
        name:"B2B"
    },
    {
        name:'Partial Assignment'
    }
  
   
    
]
