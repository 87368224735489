import React from 'react'
import { Row, Col, Button, ButtonGroup, Form, Image } from 'react-bootstrap'
import DatePicker from "react-datepicker";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-solid-svg-icons'
export default class CallBackReport extends React.Component {
    render() {
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    {this.props.mystate.lead_campaign_view === 'default' ?
                                        <Row className="mob-row-align mob-row-color">
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Sr. No.</h3>
                                            </Col>
                                            <Col lg={1} xs={3} className="" >
                                                <h3 className="table-head-lead ">Lead Source</h3>
                                            </Col>
                                            <Col lg={1} xs={3} className="" >
                                                <h3 className="table-head-lead ">Campaign Name</h3>
                                            </Col>
                                            <Col lg={1} xs={4} className="">
                                                <h3 className="table-head-lead">Count</h3>
                                            </Col>
                                            <Col lg={2} xs={4} className="">
                                            <h3 className="table-head-lead">Adset</h3>
                                        </Col>
                                        <Col lg={2} xs={4} className="">
                                        <h3 className="table-head-lead">Ads</h3>
                                    </Col>
                                            <Col lg={1} xs={4} className="">
                                            <h3 className="table-head-lead">Patch</h3>
                                        </Col>
                                        <Col lg={1} xs={4} className="">
                                        <h3 className="table-head-lead">Dead</h3>
                                    </Col>
                                    <Col lg={1} xs={4} className="">
                                    <h3 className="table-head-lead">Callback</h3>
                                </Col>
                                            <Col lg={1} xs={4} className="">
                                                <h3 className="table-head-lead">Action</h3>
                                            </Col>

                                        </Row> :
                                        <Row className="mob-row-align mob-row-color">

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">Date Time</h3>
                                            </Col>

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">Updated On</h3>
                                            </Col>

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">Patch Date Time</h3>
                                            </Col>

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">CallBack Date Time</h3>
                                            </Col>

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">Follow Up Date Time</h3>
                                            </Col>

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">Dead Date Time</h3>
                                            </Col>

                                            <Col xs={1} className="hide-mob">
                                                <h3 className="table-head-lead">Under Const Date Time</h3>
                                            </Col>

                                            <Col lg={2} xs={4} className="hide-mob">
                                                <h3 className="table-head-lead">User Details</h3>
                                            </Col>

                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead ">Tele Assignee</h3>
                                            </Col>
                                            <Col xs={1} className="">
                                                <h3 className="table-head-lead">Lead Owner</h3>
                                            </Col>
                                            <Col xs={1} className="">
                                                <h3 className="table-head-lead">Current Stage</h3>
                                            </Col>


                                        </Row>

                                    }
                                    {this.props.mystate.lead_campaign_view === 'default' ?
                                        <div>
                                            {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                                (item, i) => (
                                                    <div className="lead-table-data-user">
                                                {/*<div>{console.log(this.props.mystate.reportData)}</div>*/}
                                                        {((i + 1) % 2 === 0) ?
                                                            (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                                <Col lg={1} className="hide-mob">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div> {i + 1}</div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={1} className="" xs={3} >
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="">{item.lead_source}</div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={1} className="" xs={3} >
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="">{item.campaign_name}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} className="hide-mob" xs={4}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="">{item.campaign_count}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>

                                                                <Col lg={2} className="hide-mob" xs={4}>
                                                                <div className="table-data-user">
                                                                {item.adset.length>0 ?
                                                                
                                                                    item.adset.map((items)=>(
                                                                       
                                                                        <div className="lead-data">
                                                                            <div className="">{items.adset_name +  ' : ' + items.adset_count}</div>
                                                                        </div>
                                                                  
    
                                                                    ))
                                                                
                                                               :''}
                                                               </div>
                                                            </Col>

                                                            <Col lg={2} className="hide-mob" xs={4}>
                                                            <div className="table-data-user">
                                                            {item.ads.length>0 ?
                                                            
                                                                item.ads.map((items)=>(
                                                                   
                                                                    <div className="lead-data">
                                                                        <div className="">{items.ads_name +  ' : ' + items.ads_count}</div>
                                                                    </div>
                                                              

                                                                ))
                                                            
                                                           :''}
                                                           </div>
                                                           
                                                        </Col>
                                                                <Col lg={1} className="hide-mob" xs={4}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="">{item.patch_count}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col lg={1} className="hide-mob" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.dead_count}</div>

                                                                </div>
                                                            </div>

                                                        </Col>

                                                        <Col lg={1} className="hide-mob" xs={4}>
                                                        <div className="table-data-user">
                                                            <div className="lead-data">
                                                                <div className="">{item.callback_count}</div>

                                                            </div>
                                                        </div>

                                                    </Col>

                                                                <Col lg={1} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">

                                                                            <button className='btn btn-success' style={{ width: 'max-content', fontSize: '12px' }} onClick={() => this.props.ChangeCampaignReportView(item)}>Details</button>

                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                            </Row>) : (
                                                                <Row className="table-items-user" style={{ background: '#ffffff' }}>
                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div> {i + 1}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="" xs={3} >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="">{item.lead_source}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={1} className="" xs={3} >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="">{item.campaign_name}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" xs={4}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="">{item.campaign_count}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                           
                                                                    <Col lg={2} className="hide-mob" xs={4}>
                                                                    <div className="table-data-user">
                                                                {item.adset.length>0 ?
                                                                
                                                                    item.adset.map((items)=>(
                                                                       
                                                                        <div className="lead-data">
                                                                            <div className="">{items.adset_name +  ' : ' + items.adset_count}</div>
                                                                        </div>
                                                                  
    
                                                                    ))
                                                                
                                                               :''}
                                                               </div>  
                                                            </Col>
                                                            <Col lg={2} className="hide-mob" xs={4}>
                                                            <div className="table-data-user">
                                                            {item.ads.length>0 ?
                                                            
                                                                item.ads.map((items)=>(
                                                                   
                                                                    <div className="lead-data">
                                                                        <div className="">{items.ads_name +  ' : ' + items.ads_count}</div>
                                                                    </div>
                                                              

                                                                ))
                                                            
                                                           :''}
                                                           </div>
                                                           
                                                        </Col>

                                                                    <Col lg={1} className="hide-mob" xs={4}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="">{item.patch_count}</div>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col lg={1} className="hide-mob" xs={4}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="">{item.dead_count}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col lg={1} className="hide-mob" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.callback_count}</div>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                                    <Col lg={1} className="" xs={3}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <button className='btn btn-success' style={{ width: 'max-content', fontSize: '12px' }} onClick={() => this.props.ChangeCampaignReportView(item)}>Details</button>
                                                                            </div>

                                                                        </div>
                                                                    </Col>

                                                                </Row>)}

                                                    </div>



                                                )) : null}

                                        </div> : null}
                                    {this.props.mystate.lead_campaign_view === 'details' ?

                                        this.props.mystate.campaignDetails !== [] && this.props.mystate.campaignDetails && this.props.mystate.campaignDetails.user_details.length > 0 ? this.props.mystate.campaignDetails.user_details.map(
                                            (userdetails, j) => (

                                                <div className="lead-table-data-user">


                                                    <Row className="table-items-user" style={{ background: '#fbfbfb' }}>


                                                        <Col lg={1} className="" xs={1}>

                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div >{userdetails.date_time.substring(0, 10)}</div>
                                                                    <div >{userdetails.date_time.substring(10, 19)}</div>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col lg={1} xs={1} className="">
                                                            {userdetails.update_time != undefined ?
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div >{userdetails.update_time.substring(0, 10)}</div>
                                                                        <div >{userdetails.update_time.substring(10, 19)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </Col>


                                                        <Col lg={1} xs={1} className="">
                                                            {userdetails.patch_date_time != undefined ?
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div >{userdetails.patch_date_time.substring(0, 10)}</div>
                                                                        <div >{userdetails.patch_date_time.substring(10, 19)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </Col>

                                                        <Col lg={1} xs={1} className="">
                                                            {userdetails.callback_date_time != undefined ?
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div >{userdetails.callback_date_time.substring(0, 10)}</div>
                                                                        <div >{userdetails.callback_date_time.substring(10, 19)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </Col>

                                                        <Col lg={1} xs={1} className="">
                                                            {userdetails.follow_up_date_time != undefined ?
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div >{userdetails.follow_up_date_time.substring(0, 10)}</div>
                                                                        <div >{userdetails.follow_up_date_time.substring(10, 19)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </Col>

                                                        <Col lg={1} xs={1} className="">
                                                            {userdetails.under_const_date_time != undefined ?
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div >{userdetails.under_const_date_time.substring(0, 10)}</div>
                                                                        <div >{userdetails.under_const_date_time.substring(10, 19)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </Col>

                                                        <Col lg={1} xs={1} className="">
                                                            {userdetails.lead_dead_date != undefined ?
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div >{userdetails.lead_dead_date.substring(0, 10)}</div>
                                                                        <div >{userdetails.lead_dead_date.substring(10, 19)}</div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </Col>

                                                        <Col lg={2} xs={2} className="">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{userdetails.client_name}</div>
                                                                    <div>{userdetails.client_contact}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1} xs={1} className="">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{userdetails.tele_assignee}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1} xs={1} className="">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{userdetails.lead_owner}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1} xs={1} className="">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{userdetails.lead_current_stage}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )) : null : null}
                                    {this.props.mystate.lead_campaign_view === 'default' ?
                                        <Row className="table-items-user mt-3" style={{ background: 'rgb(204 201 201)' }}>

                                            <Col lg={1} className="" xs={6}>
                                                <div className="table-data-user">
                                                    <div className="lead-data">
                                                        <div className="text-overflow" style={{ fontWeight: 600, fontSize: '15px' }}>Total</div>

                                                    </div>
                                                </div>

                                            </Col>
                                            <Col lg={2} className=""></Col>
                                            <Col lg={3} className="" xs={6}>
                                                <div className="table-data-user">
                                                    <div className="lead-data">
                                                        <div className="text-overflow" style={{ fontWeight: 600, fontSize: '15px' }}>{this.props.mystate.total_campaign_count}</div>

                                                    </div>
                                                </div>

                                            </Col>
                                        </Row> : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}