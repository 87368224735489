import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { GetAreaListByCity, GetLeadData, GetUserByProfile, AdvancedSearch } from '../models/users'
import $ from 'jquery';

import { PnkConnect } from "../pnk-react/pnk-miniredux/";
class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            getCity: true,
            startDate: '',
            areaList: [],
            profileStageData: [],
            getUsersListByProfile: false,
            userListByProfile: [],
            getLeadData: false,
            rdSearchType: 'all',
            getArea: false,
            ddlPreferedCity: '',
            ddlPreferedArea: '-1',
            ddlLeadProfile: '',
            ddlLeadOwner: '',
            ddlLeadSource: '',
            ddlLeadStage: '',
            ddlLeadRequirement: '',
            ddlLeadSolution: '',
            txtStartDate: '',
            txtEndDate: '',
            ddlProjectEstimate: '',
            txtLeadPatchEndDate: '',
            txtLeadPatchStartDate: '',
            sendAdvanceSearchData: false,
            ddlLeadProfileId:''


        }
        this.spaceType = [
            'Balcony',
            'Terrace',
            'Garden',
            'Full House',
            'Indoors'
        ];
        this.projectEstimate = [
            'Below 1 lakh',
            '1-2 lakhs',
            '2-4 lakhs',
            '4-6 lakhs',
            '6-8 lakhs',
            ' 8 lakhs and above'
        ]
        this.data = {}
    }
    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }

    componentDidMount = () => {
        if (JSON.stringify(this.props.PnkStore.GetData('leaddata')) == '{}')
            this.setState({ getLeadData: true })

        let allowedLeadStages = [];
        if (sessionStorage.getItem('current_user_allowed_lead_stages')) {
            allowedLeadStages = sessionStorage.getItem('current_user_allowed_lead_stages');
            allowedLeadStages = allowedLeadStages.split(',');
            this.setState({
                profileStageData: allowedLeadStages
            })
        }
    }

    HandleChange = (type, date) => {

        this.setState({
            [type]: date
        });

    };
    HandleUserInput = (e) => {

        var name = e.target.name
        var value = e.target.value
        if (name === "ddlPreferedCity" && value !== '') {
            this.setState({
                getArea: true
            })
        }
        if (name === "ddlLeadProfile" && value !== '') {
            let profileData = [];
            if (this.props.PnkStore.GetData("leaddata") &&
                this.props.PnkStore.GetData("leaddata").length > 0) {

                // if (this.props.PnkStore.GetData("leaddata")[3] && this.props.PnkStore.GetData("leaddata")[3].profilelist && this.props.PnkStore.GetData("leaddata")[3].profilelist.rows) {
                //     profileData = this.props.PnkStore.GetData("leaddata")[3].profilelist.rows;
                //     profileData.map(item =>
                //         item.profile_name === value ?
                //             this.setState({
                //                 profileStageData: item.allowed_lead_stages
                //             }) : null)


                // }

            }
            var sel = document.getElementById('ddlLeadProfile');
            var opt = sel.options[sel.selectedIndex];

            this.setState({
                ddlLeadProfileId: opt.id
            })
            this.setState({
                getUsersListByProfile: true
            })
        }
        this.setState({
            [name]: value
        })
    }
    HandleGetAreaSuccessResponse = (res) => {

        if (res.data && res.data.arealist && res.data.arealist.rows) {

            this.setState({ areaList: res.data.arealist })

        }

        this.setState({
            getArea: false
        })
    }
    HandleGetLeadDataResponse = (res) => {
        if (res.data) {
            this.props.PnkStore.SetData('leaddata', res.data);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getLeadData: false
        })
    }
    HandleGetUserByProfileSuccessResponse = (res) => {

        if (res.data && res.data.userlistbyprofile) {
            if(sessionStorage.getItem("current_user_profile")==='Sales TL' ){
                if(this.state.ddlLeadProfile==='Sales TL'){
                    let sales_tl_array=[]
            res.data.userlistbyprofile.rows.map(lead_owner=>{
                
                if(lead_owner.user_name===sessionStorage.getItem("current_user_name")){
                    sales_tl_array.push(lead_owner)
                    this.setState({
                        userListByProfile: sales_tl_array,
                        getUsersListByProfile: false,
                       
                    })
                }
            })
        }
        else if(this.state.ddlLeadProfile==='Sales'){
            let sales_tl_reporting_to_array=[]
            res.data.userlistbyprofile.rows.map(lead_owner=>{
                
                if(lead_owner.reporting_to===sessionStorage.getItem("current_user_name")){
                    sales_tl_reporting_to_array.push(lead_owner)
                    this.setState({
                        userListByProfile: sales_tl_reporting_to_array,
                        getUsersListByProfile: false,
                        
                    })
                }
            }) 
        }
        }
        else if(sessionStorage.getItem("current_user_profile")==='Sales' ){
            let sales_array=[]
            res.data.userlistbyprofile.rows.map(lead_owner=>{
                
                if(lead_owner.user_name===sessionStorage.getItem("current_user_name")){
                    sales_array.push(lead_owner)
                    this.setState({
                        userListByProfile: sales_array,
                        getUsersListByProfile: false,
                       
                    })
                }
            })
        }
        else if(sessionStorage.getItem("current_user_profile")==='Telecalling' ){
            let sales_array=[]
            res.data.userlistbyprofile.rows.map(lead_owner=>{
                if(lead_owner.user_name===sessionStorage.getItem("current_user_name")){
                    sales_array.push(lead_owner)
                    this.setState({
                        userListByProfile: sales_array,
                        getUsersListByProfile: false,
                       
                    })
                }
            })
        }
        else{
           
            this.setState({
                userListByProfile: res.data.userlistbyprofile.rows,
                getUsersListByProfile: false
            })
        }
    }
    }
    GenerateAdvanceSearch = () => {
        this.data = {
            ddlPreferedCity: this.state.ddlPreferedCity,
            ddlPreferedArea: this.state.ddlPreferedArea,
            ddlLeadProfile: this.state.ddlLeadProfileId,
            ddlLeadOwner: this.state.ddlLeadOwner,
            ddlLeadSource: this.state.ddlLeadSource,
            ddlLeadStage: this.state.ddlLeadStage,
            ddlLeadRequirement: this.state.ddlLeadRequirement,
            ddlLeadSolution: this.state.ddlLeadSolution,
            txtStartDate: this.state.txtStartDate,
            txtEndDate: this.state.txtEndDate,
            ddlProjectEstimate: this.state.ddlProjectEstimate,
            txtLeadPatchStartDate: this.state.txtLeadPatchStartDate,
            txtLeadPatchEndDate: this.state.txtLeadPatchEndDate
        }
        this.setState({
            sendAdvanceSearchData: true
        })

    }

    HandleSuccessAdvanceSearch = (res) => {
        let that = this;

        if (res !== false) {

            if (res.data && res.data.leadlist) {
                // that.props.PnkStore.SetData('advancesearchlist', res.data.leadlist);
                that.props.PnkStore.SetData('leadlist', res.data.leadlist);
                that.props.PnkStore.BroadCast();

                that.setState({ sendAdvanceSearchData: false })
                that.props.componentprops.HideFilter();
                // if (window.location.href.includes('leads'))
                //     window.location.reload()
                // // that.props.componentprops.HandleRoute('leads');
                // else

                this.props.PnkStore.SetData('lastSearchBy', 'advance')
                this.props.PnkStore.SetData('advanceSearch', this.data)
                this.props.PnkStore.SetData('advanceSearchtype', this.state.rdSearchType)
                this.props.PnkStore.SetData('searchData', this.state.txtUserSearch)
                this.props.PnkStore.SetData('setNo', 1)

                if (window.location.href.includes('leads')) {
                    that.props.componentprops.CallMethod();
                }
                else {
                    that.props.componentprops.HandleRoute('leads');
                }
            }

            // AppUtils.HandlePageClick(that.props, 'leads');
        }
        else {
            let error = 'Something went wrong';
            let errorData = this.props.PnkStore.GetData('error');
            if (typeof this.props.PnkStore.GetData('error') !== 'object')
                errorData = JSON.stringify(this.props.PnkStore.GetData('error'));
            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({ sendAdvanceSearchData: false })
        }
    }
    ClearSeletion=()=>{
        this.setState({
            ddlPreferedCity: '',
            ddlPreferedArea: '',
            ddlLeadProfile: '',
            ddlLeadOwner: '',
            ddlLeadSource:'',
            ddlLeadStage: '',
            ddlLeadRequirement: '',
            ddlLeadSolution: '',
            txtStartDate: '',
            txtEndDate: '',
            ddlProjectEstimate: '',
            txtLeadPatchStartDate: '',
            txtLeadPatchEndDate: ''
        })
    }
    render() {
        // let citylist = this.props.PnkStore.GetData('citylist');
        let arealist = this.state.areaList
        let cityData = []; let profileData = []; let propertyData = []; let leadSourceData = [];let filteredData=[]
        if (this.props.PnkStore.GetData("leaddata") &&
            this.props.PnkStore.GetData("leaddata").length > 0) {

            if (this.props.PnkStore.GetData("leaddata")[0] && this.props.PnkStore.GetData("leaddata")[0].citylist && this.props.PnkStore.GetData("leaddata")[0].citylist.rows) {
                cityData = this.props.PnkStore.GetData("leaddata")[0].citylist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[1] && this.props.PnkStore.GetData("leaddata")[1].proptypelist && this.props.PnkStore.GetData("leaddata")[1].proptypelist.rows) {
                propertyData = this.props.PnkStore.GetData("leaddata")[1].proptypelist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[2] && this.props.PnkStore.GetData("leaddata")[2].leadsourcelist && this.props.PnkStore.GetData("leaddata")[2].leadsourcelist.rows) {
                leadSourceData = this.props.PnkStore.GetData("leaddata")[2].leadsourcelist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[3] && this.props.PnkStore.GetData("leaddata")[3].profilelist && this.props.PnkStore.GetData("leaddata")[3].profilelist.rows) {
                profileData = this.props.PnkStore.GetData("leaddata")[3].profilelist.rows;
                if(profileData.length>0)
                {

                    if(sessionStorage.getItem('current_user_profile')==='Sales TL'){
                        filteredData=[]
                        profileData.map(profile=>{
                            if(profile.profile_name=='Sales' || profile.profile_name=='Sales TL'){
                                filteredData.push(profile)
                            }
                            
                        })
                    }
                    else if(sessionStorage.getItem('current_user_profile')==='Telecalling'){
                        filteredData=[]
                        profileData.map(profile=>{
                            if(profile.profile_name==sessionStorage.getItem('current_user_profile')){
                                filteredData.push(profile)
                            }
                            
                        })
                    }
                    else if(sessionStorage.getItem('current_user_profile')==='Sales'){
                        filteredData=[]
                        profileData.map(profile=>{
                            if(profile.profile_name==sessionStorage.getItem('current_user_profile')){
                                filteredData.push(profile)
                            }
                            
                        })
                    }
                    else{
                        filteredData=profileData
                    }
                }
            }
        }

        return (
            <div className="filter-main-div">
                <div className="filter-head-div">
                    <Row style={{ width: '100%' }}>

                        <Col lg={6} xs={6}>
                            <div className="filter-header">
                                <label className="filter-container">Match all the following
                        <input type="radio" name="rdSearchType" value="all" onChange={this.HandleUserInput} defaultChecked />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </Col>
                        <Col lg={6} xs={6}>
                            <div className="filter-header">
                                <label className="filter-container">Match Any of the following
                        <input type="radio" name="rdSearchType" value="any" onChange={this.HandleUserInput} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className="filter-body-div">
                    <div className="filter-connr">
                        <Row className="filter-pad-bottom">
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">City</h3>
                                    </div>
                                </div>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlPreferedCity" id="ddlPreferedCity"
                                            value={this.state.ddlPreferedCity}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected>Select City</option>
                                            {(cityData && cityData.length > 0) ? cityData.map(
                                                item => (

                                                    <option value={item.city_name} id={item._id}>{item.city_name}</option>
                                                )
                                            ) : ""}
                                        </Form.Control>

                                    </Form.Group>
                                </div>

                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-se-body">
                                    <div className="fil-section">
                                        <div className="fil-se-head">
                                            <h3 className="fil-se-titile">Area</h3>
                                        </div>
                                    </div>
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlPreferedArea" id="ddlPreferedArea"
                                            value={this.state.ddlPreferedArea}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="-1" disabled selected>Select Area</option>
                                            {arealist !== undefined && arealist.rows && arealist.rows.length > 0 && arealist.rows.map(
                                                item => (
                                                    <option value={item.area_name} id={item._id}>{item.area_name}</option>
                                                )
                                            )}


                                        </Form.Control>
                                        {/* <span className="floating-label">City</span> */}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Lead Owner</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="filter-pad-bottom">
                            <Col lg={6} xs={6}>

                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadProfile" id="ddlLeadProfile"
                                            value={this.state.ddlLeadProfile}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected>Profile</option>
                                            {(filteredData && filteredData.length > 0) ? filteredData.map(item =>
                                               
                                                <option value={item.profile_name} id={item._id}>{item.profile_name}</option>

                                            ) : ""}
                                        </Form.Control>
                                        {/* <span className="floating-label">City</span> */}
                                    </Form.Group>
                                </div>

                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadOwner" id="ddlLeadOwner"
                                            value={this.state.ddlLeadOwner}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected>Users </option>
                                            {(this.state.userListByProfile &&  this.state.userListByProfile.length > 0) ? this.state.userListByProfile.map(item =>
                                                item.user_name === this.state.ddlLeadOwner ?
                                                    <option value={item.user_name} selected>{item.user_name}</option>
                                                    :
                                                    <option value={item.user_name}>{item.user_name}</option>

                                            ) : ""}
                                        </Form.Control>
                                        {/* <span className="floating-label">City</span> */}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Source</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Stage</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="filter-pad-bottom">

                            <Col lg={6} xs={6}>

                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadSource" id="ddlLeadSource"
                                            value={this.state.ddlLeadSource}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected>Source</option>
                                            {(leadSourceData && leadSourceData.length > 0) ? leadSourceData.map(item =>
                                                <option value={item.lead_source_name}>{item.lead_source_name}</option>
                                            ) : ""}
                                        </Form.Control>

                                    </Form.Group>
                                </div>

                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadStage" id="ddlLeadStage"
                                            value={this.state.ddlLeadStage}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected >Stage</option>
                                            {(this.state.profileStageData && this.state.profileStageData.length > 0) ? this.state.profileStageData.map(item =>
                                                <option value={item}>{item}</option>
                                            ) : ""}
                                        </Form.Control>

                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Requirement</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Type Of Solution</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="filter-pad-bottom">
                            <Col lg={6} xs={6}>

                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadRequirement" id="ddlLeadRequirement"
                                            value={this.state.ddlLeadRequirement}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected >Requirement</option>
                                            {this.spaceType.map(item => (
                                                <option value={item}>{item}</option>
                                            ))}


                                        </Form.Control>

                                    </Form.Group>
                                </div>

                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">

                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadSolution" id="ddlLeadSolution"
                                            value={this.state.ddlLeadSolution}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected >Type Of Solution</option>
                                            <option value="VegetableGarden">Vegetable Garden</option>
                                            <option value="VerticalGarden">Vertical Garden</option>
                                            <option value="Both">Both</option>

                                        </Form.Control>

                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>


                        <Row className="filter-pad-bottom">
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Acquisition from</h3>
                                    </div>
                                </div>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">
                                        <DatePicker
                                            name="txtStartDate" id="txtStartDate"
                                            selected={this.state.txtStartDate}
                                            onChange={(e) => this.HandleChange("txtStartDate", e)}
                                            className="filter-form-cntrl"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Acquisition from"
                                        />


                                    </Form.Group>
                                </div>

                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-se-body">
                                    <div className="fil-section">
                                        <div className="fil-se-head">
                                            <h3 className="fil-se-titile">Acquisition to</h3>
                                        </div>
                                    </div>
                                    <Form.Group className="margin-bottom-t-filter">
                                        <DatePicker
                                            name="txtEndDate" id="txtEndDate"
                                            selected={this.state.txtEndDate}
                                            onChange={(e) => this.HandleChange("txtEndDate", e)}
                                            className="filter-form-cntrl"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Acquisition to"
                                        />

                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row className="filter-pad-bottom">
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Lead Patch from</h3>
                                    </div>
                                </div>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">
                                        <DatePicker
                                            name="txtLeadPatchStartDate" id="txtLeadPatchStartDate"
                                            selected={this.state.txtLeadPatchStartDate}
                                            onChange={(e) => this.HandleChange("txtLeadPatchStartDate", e)}
                                            className="filter-form-cntrl"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Lead Patch from"
                                        />


                                    </Form.Group>
                                </div>

                            </Col>
                            <Col lg={6} xs={6}>
                                <div className="fil-se-body">
                                    <div className="fil-section">
                                        <div className="fil-se-head">
                                            <h3 className="fil-se-titile">Lead Patch to</h3>
                                        </div>
                                    </div>
                                    <Form.Group className="margin-bottom-t-filter">
                                        <DatePicker
                                            name="txtLeadPatchEndDate" id="txtLeadPatchEndDate"
                                            selected={this.state.txtLeadPatchEndDate}
                                            onChange={(e) => this.HandleChange("txtLeadPatchEndDate", e)}
                                            className="filter-form-cntrl"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Lead Patch to"
                                        />

                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row className="filter-pad-bottom">
                            <Col lg={6} xs={6}>
                                <div className="fil-section">
                                    <div className="fil-se-head">
                                        <h3 className="fil-se-titile">Project Estimate</h3>
                                    </div>
                                </div>
                                <div className="fil-se-body">
                                    <Form.Group className="margin-bottom-t-filter">
                                        <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlProjectEstimate" id="ddlProjectEstimate"
                                            value={this.state.ddlProjectEstimate}
                                            onChange={this.HandleUserInput}
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected>Project Estimate</option>

                                            {(this.projectEstimate && this.projectEstimate.length > 0) ?
                                                this.projectEstimate.map(item =>
                                                    <option value={item} id="">{item}</option>

                                                ) : ""}

                                        </Form.Control>


                                    </Form.Group>
                                </div>

                            </Col>
                        </Row>

                        <Row className="filter-pad-bottom justify-content-center">
                            <Col lg={3} xs={3}>
                                <Button variant="light" className="filter-btn-close" onClick={() => this.props.componentprops.HideFilter()}>Close</Button>
                            </Col>
                            <Col lg={3} xs={3}>
                                <Button variant="light" className="filter-btn-close" onClick={()=>this.ClearSeletion()}>Clear</Button>
                            </Col>
                            <Col lg={3} xs={3}>
                                <Button variant="light" className="filter-btn-generate" onClick={() => this.GenerateAdvanceSearch()}>Generate</Button>
                            </Col>
                        </Row>
                    </div>

                </div>
                {/* {((JSON.stringify(citylist) === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")} */}
                {(( this.state.getArea === true) ? GetAreaListByCity(this.props.PnkStore, this.state.ddlPreferedCity, '', this.HandleGetAreaSuccessResponse) : "")}
                {((this.state.getLeadData === true) ? GetLeadData(this.props.PnkStore, this.HandleGetLeadDataResponse) : "")}
                {((this.state.getUsersListByProfile === true) ? GetUserByProfile(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.ddlLeadProfileId, this.state.ddlPreferredCity, this.HandleGetUserByProfileSuccessResponse) : "")}
                {((this.state.sendAdvanceSearchData === true) ? AdvancedSearch(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.rdSearchType, this.data, 1, 50, this.HandleSuccessAdvanceSearch) : "")}
            </div>

        )
    }
}
export const FilterPage = PnkConnect(Filter, "");