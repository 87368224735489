import React from 'react'
import { Row, Col, Button, ButtonGroup, Accordion, Card } from 'react-bootstrap'
import DatePicker from "react-datepicker";
export default class SalesReport extends React.Component {
    constructor(props) {
        super(props)
      
    }
    ShowPatchDetails=()=>{
        return<div>hai</div>
    }
    render() {
       
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    <Row className="mob-row-align mob-row-color">
                         

                                        <Col lg={3} xs={3} className="hide-mob">
                                            <h3 className="table-head-lead">Lead Patched To</h3>
                                        </Col>
                                        <Col xs={3} className="">
                                            <h3 className="table-head-lead">Count</h3>
                                        </Col>
                                        <Col xs={3} className="">
                                            <h3 className="table-head-lead">Details</h3>
                                        </Col>
                                       

                                    </Row>
                                    <div>
                                    {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                            (item, j) => (
                                            <Row className="table-items-user" style={{ background: '#fff',marginBottom:'10px' }}>
                                                <Col lg={3} className="" xs={2}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.lead_owner}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={3} className="" xs={2}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.patch_count}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={3} className="" xs={2}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                    <div>

<Accordion defaultActiveKey="0" style={{position:'absolute'}}>
<Card>

<Accordion.Toggle variant="link" eventKey={item.lead_owner}>
Details
</Accordion.Toggle>

<Accordion.Collapse eventKey={item.lead_owner} style={{background:'cadetblue',zIndex:'9999'}}>

<React.Fragment>
<div style={{display:'flex'}}>
<div style={{width:'150px',color: 'aliceblue',fontWeight:600}}>Client Name</div>

<div style={{width:'150px',color: 'aliceblue',fontWeight:600}}>Acquisition Date </div>
    <div style={{width:'100px',color: 'aliceblue',fontWeight:600}}>Client Contact </div>
    <div style={{width:'150px',color: 'aliceblue',fontWeight:600}}>Date Time </div>
    <div style={{width:'150px',color: 'aliceblue',fontWeight:600}}>Patch Date Time</div>
    </div>
{item.user_details && item.user_details.length>0 && item.user_details.map(user=>

<React.Fragment>
<div style={{display:'flex'}}>
<div style={{width:'150px'}}>{user.client_name}</div>
<div style={{width:'150px'}}>{user.date_time} </div>
    <div style={{width:'100px'}}>{user.client_contact} </div>
    <div style={{width:'150px'}}>{user.date_time} </div>
    <div style={{width:'150px'}}>{user.patch_date_time}</div>
    </div>
{/* <Card.Body style={{padding:'10px',width:'max-content'}}>{user.client_name}{' '}{user.client_contact}{' '} {user.date_time} {' '} {user.patch_date_time} </Card.Body> */}

</React.Fragment>

)}
</React.Fragment>
</Accordion.Collapse>
</Card>

</Accordion>
</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                </Row>
                                         )
                                     ):null}
                                            
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}