import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Reload from '../../assets/images/reload.svg'
export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }
    GoToEntry = () => {
        this.setState({
            gotoEntry: true
        })
        // 

    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {
       
        this.props.SortData(e, e.target.value)
    }
    render() {
       
        if (this.state.gotoEntry === true) {
            return <Redirect to='/lead-entry' push={true} />
        }

        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >

                            <Col lg={1} className="col-padding-0">
                                <Image src={Reload} className="reload-img" title="Reload" onClick={this.props.ReloadPage}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}