import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
export default class ViewData extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        console.log("this.props.currentAction",this.props.currentAction)
        return (
            <div>
                <FontAwesomeIcon icon={faTimes} className="modal-header-close" onClick={() => this.props.currentAction.close()} />
                 {this.props.currentAction.action.currentChoice === "Daily-Report" ?
 Object.keys(this.props.currentAction.data).length > 0 ?
 <Row>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Telecaller</h3>
             <span className="view-data-data">{this.props.currentAction.data.tele_caller}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Lead Generate</h3>
             <span className="view-data-data">{this.props.currentAction.data.lead_generated}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Lead Patched</h3>
             <span className="view-data-data">{this.props.currentAction.data.lead_patched}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">RNR</h3>
             <span className="view-data-data">{this.props.currentAction.data.total_rnr}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Pending</h3>
             <span className="view-data-data">{this.props.currentAction.data.total_pending}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Callbacks</h3>
             <span className="view-data-data">{this.props.currentAction.data.total_callback}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Hold</h3>
             <span className="view-data-data">{this.props.currentAction.data.total_hold}</span>
         </div>
     </Col>
     <Col xs={6}>
         <div className="view-data-div">
             <h3 className="view-data-label">Dead</h3>
             <span className="view-data-data">{this.props.currentAction.data.total_dead}</span>
         </div>
     </Col>
   
     </Row>
     :null

                 :null}
                {this.props.currentAction.action.currentChoice === "Inside Sales Report" ?
                    Object.keys(this.props.currentAction.data).length > 0 ?
                        <Row>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Date</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.date_time}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Lead Generate</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.lead_generated}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Lead Called</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.lead_called}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Lead Patched</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.lead_patched}</span>
                                </div>
                            </Col>
                            {this.props.currentAction.action.currentInsideSalesType === "dead" ?
                                <React.Fragment>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Total</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.total_dead}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">No Budget</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.no_budget}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Not Answered</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.not_answered}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Not Interested</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.not_interested}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Other City</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.other_city}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Window Shopping</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.window_shopping}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Others</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.other_reason}</span>
                                        </div>
                                    </Col>
                                </React.Fragment> : null}
                            {this.props.currentAction.action.currentInsideSalesType === "pending" ?
                                <React.Fragment>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Total</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.total_pending}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">RNR</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.total_rnr}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Hold</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.total_hold}</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="view-data-div">
                                            <h3 className="view-data-label">Callback</h3>
                                            <span className="view-data-data">{this.props.currentAction.data.total_callback}</span>
                                        </div>
                                    </Col>
                                </React.Fragment> : null}

                        </Row>

                        : null : null}
                {this.props.currentAction.action.currentChoice === "Sales Report" ?
                    Object.keys(this.props.currentAction.data).length > 0 ?

                        <Row>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Date</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.date_time}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Lead Owner</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.lead_owner}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Team Lead</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.reporting_to}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Lead Patched</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_lead_patched}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">HC Booked</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_hc_booked}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">HC Completed</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_hc_completed}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Follow Up</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_follow}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Pending Design Handover</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_pending_design}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Handover to Design</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_handover_to_design}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Estimate Pending</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_estimate_pending}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Proposal Sent </h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_proposal_sent}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Booking Amount Received </h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_booking_amount_received}</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="view-data-div">
                                    <h3 className="view-data-label">Project Closed</h3>
                                    <span className="view-data-data">{this.props.currentAction.data.total_project_closed}</span>
                                </div>
                            </Col>
                        </Row> : null
                    : null}
                {this.props.currentAction.action.currentChoice === "HC Tracker" ?

                    <Row>

                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Lead Owner</h3>
                                <span className="view-data-data">{this.props.currentAction.data.lead_owner}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Client Name</h3>
                                <span className="view-data-data">{this.props.currentAction.data.client_name}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Project value</h3>
                                <span className="view-data-data">{this.props.currentAction.data.project_value}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">HC Date Time</h3>
                                <span className="view-data-data">{this.props.currentAction.data.hc_date_time}</span>
                            </div>
                        </Col>

                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Stage </h3>
                                <span className="view-data-data">{this.props.currentAction.data.stage}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Name of Designer</h3>
                                <span className="view-data-data">{this.props.currentAction.data.name_of_designer}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Closure </h3>
                                <span className="view-data-data">{this.props.currentAction.data.total_closure}</span>
                            </div>
                        </Col>
                    </Row>
                    : null}
                       {this.props.currentAction.action.currentChoice === "Patch-Report" ?
                    
                     this.props.currentAction.data.list.length>0 ?
                     <React.Fragment>
<Row>
<Col xs={6}>
    <div className="view-data-div">
        <h3 className="view-data-label">Telecalling User</h3>
        <span className="view-data-data">{this.props.currentAction.data.tele_assignee}</span>
    </div>
</Col>
</Row>
                   { this.props.currentAction.data.list.map(sales_details=>(
<Row>

<Col xs={6}>
    <div className="view-data-div">
        <h3 className="view-data-label">Lead Owner</h3>
        <span className="view-data-data">{sales_details.lead_owner}</span>
    </div>
</Col>
<Col xs={6}>
    <div className="view-data-div">
        <h3 className="view-data-label">Count</h3>
        <span className="view-data-data">{sales_details.patch_count}</span>
    </div>
</Col>

</Row>

                    ))}
                    </React.Fragment>:null



                         : null}
                {this.props.currentAction.action.currentChoice === "Design Tracker" ?

                    <Row>

                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Handover Dater</h3>
                                <span className="view-data-data">2020-09-28</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Lead Owner</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Handover to Designer</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Design in Progress</h3>
                                <span className="view-data-data">0</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Design Uploaded</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Design Change </h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Project Closed</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>

                    </Row>
                    : null}
                {this.props.currentAction.action.currentChoice === "Lead Patched" ?

                    <Row>

                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Lead Patched date</h3>
                                <span className="view-data-data">2020-09-28</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Lead Aquistion date</h3>
                                <span className="view-data-data">2020-09-28</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Last edited</h3>
                                <span className="view-data-data">2020-09-28</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Name</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Number</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Source </h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Caller</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Status </h3>
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='ddlLeadPatchedStatus'
                                    id='ddlLeadPatchedStatus'
                                    onChange={this.props.HandleUserInput}
                                    pnkdisplayerrormsg='Select City'
                                    value={this.props.mystate.ddlLeadPatchedStatus}
                                    style={{ padding: '4px', width: '100%' }}>
                                    <option>Select</option>
                                </Form.Control>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Remarks</h3>
                                <span className="view-data-data">Lorem Ipsum</span>
                            </div>
                        </Col>

                    </Row>
                    : null}
            </div>
        )
    }
} 