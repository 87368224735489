import React, { Component } from 'react';
import axios from 'axios';
import { pnkstore } from "../../stores/pnk-store";
import { PnkLoader } from "../loader/pnk-loader";
//pnkstore.api.baseurl + "/" + url
// import './pnkimage.css';
// import FormData from 'form-data';
//  import {FileUpload} from 'file-upload-react';
//  const FileUpload = require('react-fileupload');
// var FileReader = require('filereader')
export default class PnkImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedFile: null,
            // imagePreviewUrl: "",
            //renderImageComponent:true,
            progressCompleted: 0
        }
        // this.serverPath = "http://ec2-18-219-200-46.us-east-2.compute.amazonaws.com:3000/";
        this.serverPath = pnkstore.api.baseurl + "/";
        // this.serverPath = "http://localhost:3000/";
        this.uploadApi = (this.props.uploadApi && this.props.uploadApi != "") ? this.props.uploadApi : "uploadfile";
        this.file = null;
        this.buttonStyle = this.props.buttonStyle;
        this.buttonClass = this.props.buttonClass ? this.props.buttonClass : '';
        this.outerStyle = this.props.outerStyle ? this.props.outerStyle : {};
        this.previewType = this.props.previewType ? this.props.previewType : 'image';
        this.itemClass = this.props.itemClass ? this.props.itemClass : 'col-md-4 col-xs-12';
        this.itemStyle = this.props.itemStyle ? this.props.itemStyle : '';

        this.count = 0;
        this.destinationFolder = (this.props.destinationFolder && this.props.destinationFolder != "") ? this.props.destinationFolder : "application/public/uploads";
        this.pnkvalidate = (this.props.pnkvalidate && this.props.pnkvalidate != "") ? this.props.pnkvalidate : "";
        this.pnkvalue = (this.props.pnkvalue) ? this.props.pnkvalue : "";

        this.id = this.props.id ? this.props.id : "fupUploadedFile";


        this.name = this.props.name ? this.props.name : "fupUploadedFile";
        this.pnkdisplayerrormsg = (this.props.pnkdisplayerrormsg && this.props.pnkdisplayerrormsg != "") ? this.props.pnkdisplayerrormsg : "";
        this.enctype = (this.props.enctype && this.props.enctype != "") ? this.props.enctype : "";
        this.btnPreviewText = (this.props.btnPreviewText && this.props.btnPreviewText != "") ? this.props.btnPreviewText : "Select Some Files";
        this.allowedTypeStr = (this.props.allowedTypeStr && this.props.allowedTypeStr != "") ? this.props.allowedTypeStr : "jpg,jpeg,png";
        this.multiple = (this.props.multiple && this.props.multiple != "") ? this.props.multiple : "false";
        this.limitFiles = (this.props.limitFiles && this.props.limitFiles != "") ? this.props.limitFiles : 20;

        // this.previewImageSrc = (this.props.previewImageSrc && this.props.previewImageSrc != "") ? this.props.previewImageSrc : "";
        /*this.props.validateevent(event);
        this.props.onchangeevent(event);*/
        // this.gotovalidation()
        this.prev_called_count = 0;
    }
    GetCurrentDateTime = () => {
        var currentdate = new Date();
        var datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + "@"
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();
        return datetime;
    }
    componentWillUpdate = () => {
        // alert("will");
        // this.previewImageSrc = (this.props.previewImageSrc != '') ? this.props.previewImageSrc : "";
        //  alert(this.previewImageSrc+"will mount")
    }
    HandlePreviewImage = () => {
        // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        /*********************For Edit Mode************************* */
        // console.log(this.prev_called_count+"-prop prev src--"+this.props.previewImageSrc+"\tComponent Label Images src-"+this.previewImageSrc);
        if (this.props.previewImageSrc != '' && this.props.previewImageSrc != undefined && this.props.mode == 'edit' && this.props.renderChild != undefined && this.props.renderChild != false) {
            // if (this.props.previewImageSrc != undefined && this.props.previewImageSrc != "" && this.props.mode == 'edit' && this.props.renderChild != undefined && this.props.renderChild != false) {
            document.getElementById("lead-entry-img-file").style.width = "100%";
            var str_array = this.props.previewImageSrc.replace(/^\,+|\,+$/, '').split(',');
            this.prev_called_count++;
            // alert(this.prev_called_count + '################################' + JSON.stringify(str_array.length));
            if (this.prev_called_count == 1) {
                for (var i = 0; i < str_array.length; i++) {
                    // alert(str_array[i]);
                    // Trim the excess whitespace.
                    str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
                    // Add additional code here, such as:
                    var div = document.createElement("div");
                    div.setAttribute('class', "divUploadFile " + this.itemClass);
                    // div.setAttribute('class',this.itemClass);
                    div.setAttribute('style', 'margin-bottom: 10px;' + this.itemStyle);
                    div.setAttribute('id', 'divOuter_' + str_array[i]);
                    var img = document.createElement("img");
                    //var x = document.createElement("input");
                    var editBtn = document.createElement("input");
                    //x.setAttribute('style', 'top:-40px;position: relative;right: 22px;background: black;color: white;');
                    editBtn.setAttribute('style', 'top:18px;position: relative;background: black;color: white;');
                    img.setAttribute('style', 'margin-bottom: 10px;height: 90%;border: 2px solid darkblue;width:90%;');
                    img.setAttribute('id', 'editImg_' + str_array[i]);
                    img.setAttribute('title', str_array[i]);
                    var p = document.createElement("p");
                    p.classList.add('fileName');
                    p.setAttribute('title', str_array[i]);
                    p.setAttribute('style', 'white-space:nowrap;width: 100%;overflow: hidden;text-overflow:ellipsis;');
                    var content = "";
                    // alert(str_array[i]+"---");
                    if (str_array[i])
                        content = document.createTextNode(str_array[i]);
                    else
                        content = "not find";
                    // p.appendChild(content);
                    p.innerHtml = content;
                    //x.setAttribute("type", "button");
                    //x.setAttribute("value", "x");
                    //x.setAttribute("title", str_array[i]);
                    //x.setAttribute("name", this.props.destinationFolder);
                    editBtn.setAttribute("type", "button");
                    editBtn.setAttribute("value", "edit");
                    editBtn.setAttribute("title", str_array[i]);
                    const that = this;
                    var myFileName = str_array[i];
                    //x.addEventListener("click", function (e) {
                    //    that.RemoveUploadedFile(e);
                    //});
                    editBtn.addEventListener("click", function (e) {
                        // alert("click");
                        document.getElementById('currentChangingImage_' + that.id).textContent = e.target.title;
                        that.ChangeUploadedFile(e);
                    });

                    div.appendChild(editBtn);
                    div.appendChild(img);
                    // div.appendChild(br);
                    // div.appendChild(x);
                    div.appendChild(p);

                    // img.style.height = '100px';
                    // img.classList.add("obj");

                    var xhr = new XMLHttpRequest();
                    var fd = new FormData();
                    xhr.open("POST", this.serverPath + 'checkfile', false);
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            var resp = JSON.parse(xhr.responseText);
                            // alert(resp.response);
                            if (resp.response == true) {


                                var ext = myFileName.substring(myFileName.lastIndexOf('.') + 1);

                                if (ext == "pdf") {
                                    img.src = require('./img/pdf.png');
                                }
                                else if (ext == "jpg" || ext == "png" || ext == "JPEG" || ext == "JPG" || ext == "PNG" || ext == "jpeg") {
                                    img.src = resp.path;
                                }
                                else {
                                    img.src = require('./img/file.png');
                                }
                            }
                            else {
                                img.src = require('./img/notfound.png');
                            }
                            document.getElementById('previewDiv_' + that.id).appendChild(div);
                        }
                        else {
                        }
                    };
                    fd.append("myFile", myFileName);
                    fd.append("destination", this.props.destinationFolder);
                    xhr.send(fd);


                }
            }
            // document.getElementById('myfile_' + this.id).style.display = 'block';
            return false;
        }
    }
    componentDidMount = () => {
        if (this.multiple == "true") {
            document.getElementById("myfile_" + this.id).setAttribute('multiple', '');
        }
        var fileSelect = document.getElementById("fileSelect_" + this.id),
            myfile = document.getElementById("myfile_" + this.id);

        fileSelect.addEventListener("click", function (e) {
            e.preventDefault();
            if (myfile) {
                myfile.click();
            }
        }, false);


    }

    fileChangedHandler = (event) => {
        var numFiles;
        //let file = document.getElementById('file').files[0];
        //alert("Before Count" + this.count);
        this.count = event.target.files.length;
        // alert("Count" + this.count);
        let total_file = document.getElementById(this.id).value;
        let files_count = 0;
        if (total_file != undefined && total_file != '') {
            total_file = total_file.split(',')
            files_count = total_file.length - 1;
        }
        this.count = parseInt(this.count) + parseInt(files_count);
        // alert(files_count + "--Count--" + this.id + "---" + this.count);
        //alert("Leng" + event.target.files.length);
        if (this.count <= this.limitFiles && event.target.files.length <= this.limitFiles) {
            document.getElementById("lead-entry-img-file").style.width = "100%";
            for (var i = 0, numFiles = event.target.files.length; i < numFiles; i++) {
                var file = event.target.files[i];
                this.file = file;
                //alert(this.allowedTypeStr);
                if (this.FileValidation(file, this.allowedTypeStr)) {
                    this.count++;
                    // alert(this.count);
                    var div = document.createElement("div");
                    div.setAttribute('class', "divUploadFile " + this.itemClass);
                    div.setAttribute('style', 'margin-bottom: 50px;' + this.itemStyle);
                    div.setAttribute('id', 'divOuter_' + file.name);
                    var img = document.createElement("img");
                    var x = document.createElement("input");
                    x.setAttribute('style', 'top:-40px;position: relative;right: 22px;background: black;color: white;');
                    img.setAttribute('style', 'margin-bottom: 10px;height: 100%;border: 2px solid darkblue;width:90%;');

                    var p = document.createElement("p");
                    p.classList.add('fileName');
                    p.setAttribute('title', file.name);
                    p.setAttribute('style', 'white-space:nowrap;width: 100%;overflow: hidden;text-overflow:ellipsis;margin-bottom:2px');
                    var content = "";
                    if (file.name)
                        content = document.createTextNode(file.name);
                    else
                        content = "not find";
                    p.appendChild(content);
                    x.setAttribute("type", "button");
                    x.setAttribute("value", "x");
                    x.setAttribute("title", file.name);
                    const that = this;
                    x.addEventListener("click", function (e) {
                        that.RemoveUploadedFile(e);
                    });
                    div.appendChild(img);
                    // div.appendChild(br);
                    div.appendChild(x);
                    div.appendChild(p);

                    // img.style.height = '100px';
                    img.classList.add("obj");
                    img.file = file;
                    var progressDiv = document.createElement("div");
                    progressDiv.classList.add('progress');
                    progressDiv.setAttribute('style', 'height:20px');
                    document.getElementById('currentFileName_' + this.id).innerText = file.name;
                    progressDiv.innerHTML += '<div id="progress_' + file.name + this.GetCurrentDateTime() + '" class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" aria-valuenow="" aria-valuemin="0" aria-valuemax="100" style="width: 0%">';
                    progressDiv.innerHTML += ' </div>';

                    div.appendChild(progressDiv);
                    document.getElementById('previewDiv_' + this.id).appendChild(div); // Assuming that "preview" is the div output where the content will be displayed.

                    var reader = new FileReader();
                    reader.onload = (function (aImg) {
                        return function (e) {
                            // alert(file.type);
                            if (file.type.startsWith('application/pdf')) {
                                aImg.src = require('./img/pdf.png');
                            }
                            else if (file.type.startsWith('image/')) {
                                aImg.src = e.target.result;

                            }
                            else {
                                aImg.src = require('./img/file.png');
                            }


                        };
                    })(img);
                    if (this.previewType == "file") {
                        img.setAttribute('style', 'display:none');
                        p.setAttribute('style', 'margin-left: 25px;');
                        x.setAttribute('style', 'top: 25px;position: relative;background: black;color: white;');
                        div.setAttribute('class', "col-xs-12 divUploadFile");
                        div.setAttribute('style', "height:auto");
                    }
                    else {

                    }
                    reader.readAsDataURL(file);
                    this.uploadHandler(event);
                }
                else {

                }
            }
        }
        else {
            alert("You can upload Maximum " + this.limitFiles + " files");
        }

    }
    changeCurrentFileHandler = (event) => {
        //alert("chenge")
        var oldimage_name = document.getElementById('currentChangingImage_' + this.id).textContent;
        //alert(oldimage_name+"old--");
        var file = event.target.files[0];
        this.file = file;
        if (this.FileValidation(file, this.allowedTypeStr)) {
            var div = document.createElement("div");
            div.setAttribute('class', "divUploadFile " + this.itemClass);
            div.setAttribute('style', 'margin-bottom: 10px;' + this.itemStyle);
            div.setAttribute('id', 'divOuter_' + file.name);
            var img = document.createElement("img");
            var editBtn = document.createElement("input");
            editBtn.setAttribute('style', 'top:18px;position: relative;background: black;color: white;');
            //var x = document.createElement("input");
            //x.setAttribute('style', 'top:-40px;position: relative;right: 22px;background: black;color: white;');
            img.setAttribute('style', 'margin-bottom: 10px;height: 83px;border: 2px solid darkblue;width:90px;');

            var p = document.createElement("p");
            p.classList.add('fileName');
            p.setAttribute('title', file.name);
            p.setAttribute('style', 'white-space:nowrap;width: 100%;overflow: hidden;text-overflow:ellipsis;');
            var content = "";
            if (file.name)
                content = document.createTextNode(file.name);
            else
                content = "not find";
            p.appendChild(content);
            editBtn.setAttribute("type", "button");
            editBtn.setAttribute("value", "edit");
            editBtn.setAttribute("title", file.name);
            // x.setAttribute("type", "button");
            // x.setAttribute("value", "x");
            // x.setAttribute("title", file.name);
            // x.setAttribute("name", this.props.destinationFolder);
            const that = this;
            // x.addEventListener("click", function (e) {
            //     that.RemoveUploadedFile(e);
            // });
            editBtn.addEventListener("click", function (e) {
                // alert("click");
                document.getElementById('currentChangingImage_' + that.id).textContent = e.target.title;
                that.ChangeUploadedFile(e);
            });

            div.appendChild(editBtn);
            div.appendChild(img);
            // div.appendChild(br);
            //div.appendChild(x);
            div.appendChild(p);

            // img.style.height = '100px';
            img.classList.add("obj");
            img.file = file;
            var progressDiv = document.createElement("div");
            progressDiv.classList.add('progress');
            progressDiv.setAttribute('style', 'height:20px');
            document.getElementById('currentFileName_' + this.id).innerText = file.name;
            progressDiv.innerHTML += '<div id="progress_' + file.name + this.GetCurrentDateTime() + '" class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" aria-valuenow="" aria-valuemin="0" aria-valuemax="100" style="width: 0%">';
            progressDiv.innerHTML += ' </div>';

            div.appendChild(progressDiv);
            // alert(oldimage_name+"-----&&&");
            //document.getElementById('previewDiv_').appendChild(div); // Assuming that "preview" is the div output where the content will be displayed.
            var outerDiv = document.getElementById('divOuter_' + oldimage_name);
            outerDiv.replaceWith(div);
            var reader = new FileReader();
            reader.onload = (function (aImg) {
                return function (e) {
                    //alert(file.type);
                    if (file.type.startsWith('application/pdf')) {
                        aImg.src = require('./img/pdf.png');
                    }
                    else if (file.type.startsWith('image/')) {
                        aImg.src = e.target.result;

                    }
                    else {
                        aImg.src = require('./img/file.png');
                    }


                };
            })(img);

            reader.readAsDataURL(file);
            this.uploadHandler(event, oldimage_name);
        }
        else {

        }
    }

    // changeCurrentFileHandler1 = (event) => {
    //     var file = event.target.files[0];
    //     this.file = file;
    //     if (this.FileValidation(file, this.allowedTypeStr)) {
    //         //alert(file.name);
    //         var image = document.getElementById('editImg_' + file.name);
    //         var div = document.createElement('div');
    //         var progressDiv = document.createElement("div");
    //         progressDiv.classList.add('progress');
    //         progressDiv.setAttribute('style', 'height:20px');
    //         document.getElementById('currentFileName_' + this.id).innerText = file.name;
    //         progressDiv.innerHTML += '<div id="progress_'+file.name+this.GetCurrentDateTime()+'" class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" aria-valuenow="" aria-valuemin="0" aria-valuemax="100" style="width: 0%">';
    //         progressDiv.innerHTML += ' </div>';


    //         var oldimage = document.getElementById('currentChangingImage_' + this.id).textContent;
    //        // alert("oldimage--" + document.getElementById('currentChangingImage_' + this.id).textContent);

    //         var img = document.createElement("img");
    //         img.setAttribute('style', 'margin-bottom: 10px;height: 83px;border: 2px solid darkblue;width:90px;');
    //         img.setAttribute('title', file.name);
    //         img.classList.add("obj");
    //         img.file = file;
    //         // document.getElementById('editImg_' + document.getElementById('currentChangingImage_'+this.id).textContent).setAttribute('src', file);
    //         // var img = document.getElementById('editImg_' + document.getElementById('currentChangingImage_'+this.id));
    //         // img.setAttribute('class', "obj");
    //         // img.file = file;
    //         var reader = new FileReader();
    //         reader.onload = (function (aImg) {
    //             return function (e) {
    //                 //alert(file.type);
    //                 if (file.type.startsWith('application/pdf')) {
    //                     aImg.src = require('./img/pdf.png');
    //                 }
    //                 else if (file.type.startsWith('image/')) {
    //                     aImg.src = e.target.result;

    //                 }
    //                 else {
    //                     aImg.src = require('./img/file.png');
    //                 }


    //             };
    //         })(img);
    //         div.appendChild(img);
    //         div.appendChild(progressDiv);
    //         reader.readAsDataURL(file);

    //         var html = '<div class="divUploadFile '+this.itemClass+'" style="margin-bottom: 10px;" id="divOuter_'+file.name+'"><input style="top:-40px;position: relative;left: 22px;background: black;color: white;" type="button" value="edit" title="' + file.name + '">' + img + '<input style="top:-40px;position: relative;right: 22px;background: black;color: white;" type="button" value="x" title="' + file.name + '"><p class="fileName" title="' + file.name + '" style="white-space:nowrap;width: 100%;overflow: hidden;text-overflow:ellipsis;">' + file.name + '</p></div>';


    //         //      var A = document.getElementById('editImg_' + document.getElementById('currentChangingImage_'+this.id).textContent);
    //         var A = document.getElementById('divOuter_'+oldimage);
    //         A.replaceWith(html);
    //         this.uploadHandler(event);
    //     }

    // }
    RemoveUploadedFile = (e) => {
        var selectedFileName = e.target.title;
        var destinationPath = e.target.name;
        //alert(destinationPath);
        var r = window.confirm("Are you sure you want to delete this Image?")
        if (r == true) {
            var xhr = new XMLHttpRequest();
            var fd = new FormData();
            fd.append('destinationPath', destinationPath);
            fd.append('filename', selectedFileName);
            xhr.open("POST", this.serverPath + 'removeupload', false);
            let that = this;
            xhr.onreadystatechange = function () {
                // alert(JSON.stringify(xhr));
                if (xhr.readyState == 4 && xhr.status == 200) {
                    var uploadedFile = document.getElementById(that.id).value;
                    uploadedFile = uploadedFile.trim(',');
                    var uploadedFileArray = uploadedFile.split(',');
                    var remainingFile = "";
                    for (var i = 0; i < uploadedFileArray.length; i++) {
                        //alert(uploadedFileArray[i]+"$$$$$$$$$$$$$$$$$$"+selectedFileName);
                        if (uploadedFileArray[i] === selectedFileName) {

                        }
                        else {
                            remainingFile += uploadedFileArray[i] + ",";
                        }
                    }
                    remainingFile = remainingFile.replace(/(^[,\s]+)|([,\s]+$)/g, '');
                    document.getElementById(that.id).value = remainingFile;
                    document.getElementById(that.id).click();
                    // document.getElementById('fupUploadedFile').onchange();
                    //do what you want with the image name returned
                    //e.g update the interface

                }
                else {
                    // alert("Server Error...");
                    // var currentFileName = document.getElementById('currentFileName_'+this.id).innerText;
                    // var progressBar = document.getElementById('progress_' + currentFileName);
                    // // progressBar.style.width = "100%";
                    // progressBar.textContent = 'Failed Removing from the Server !';
                    // progressBar.setAttribute('class', 'progress-bar progress-bar-danger progress-bar-striped active');

                }
                e.target.closest('div').remove();
            };
            xhr.send(fd);
        }
    }
    ChangeUploadedFile = (e) => {
        var selectedFileName = e.target.title;
        // var src = image.getAttribute('src');
        // alert("hhh");
        var changeCurrentfile = document.getElementById("changeCurrentfile_" + this.id);
        // alert(changeCurrentfile+"pppp");
        //e.target.addEventListener("click", function (e) {
        // e.preventDefault();
        if (changeCurrentfile) {
            changeCurrentfile.click();
        }
        // }, false);
        // var xhr = new XMLHttpRequest();
        // xhr.open("GET", this.serverPath + 'changeupload/' + selectedFileName, false);
        // xhr.onreadystatechange = function () {
        //     if (xhr.readyState == 4 && xhr.status == 200) {
        //         alert(xhr.responseText);
        //     }
        // };
        // xhr.send();
    }
    uploadHandler = (e, oldimage_name = '') => {
        e.preventDefault();
        let localdatetime = this.GetCurrentDateTime();
        var xhr = new XMLHttpRequest();
        var fd = new FormData();

        // start-----loader---

        var myloaderdiv = document.createElement("div");
        var childloaderdiv = document.createElement("div");
        myloaderdiv.setAttribute('class', 'custom_loader');
        childloaderdiv.setAttribute('class', 'custom_loader_overlay');
        childloaderdiv.appendChild(myloaderdiv)
        // myloaderdiv.style = "opacity:0.6;padding:0;border-radius:6px;position:fixed;text-align:center;z-index:99999;left:50%;top:50%;"
        //myloaderdiv.appendChild(<img height="100" width="100" src={require("../images/small.gif")} alt="" />);
        document.body.insertBefore(childloaderdiv, document.body.firstChild)

        // xhr.open('POST', this.serverPath + 'uploadfile', true);
        xhr.open('POST', this.serverPath + this.uploadApi, true);
        //  xhr.setRequestHeader("Content-Type", "multipart/form-data");
        // xhr.setRequestHeader("Content-Type", "text/plain");
        const that = this;
        xhr.onreadystatechange = function () {
            //close loader------
            setTimeout(function () {
                document.getElementsByClassName('custom_loader_overlay')[0].style.display = 'none'
            }, 10000);

            //alert(JSON.stringify(xhr));
            if (xhr.readyState == 4 && xhr.status == 200) {
                var resp = JSON.parse(xhr.responseText);
                //alert(resp.response);
                if (resp.response == true) {
                    var imageName = resp.fileName;
                    // document.getElementById('fupUploadedFile').addEventListener('change',that.props.validateevent);

                    if (oldimage_name != '') {
                        // alert("if");
                        var final_val = document.getElementById(that.id).value;
                        // alert("--old image---"+oldimage_name+"---final val--"+final_val+"---New imag--"+resp.fileName);
                        final_val = final_val.replace(oldimage_name, resp.fileName);
                        //alert(final_val);
                        document.getElementById(that.id).value = final_val;
                        // alert(final_val.replace(oldimage_name,""));
                        // document.getElementById(that.id).value += resp.fileName + ",";
                    }
                    else {
                        // alert("else")
                        document.getElementById(that.id).value += resp.fileName + ",";
                    }
                    // document.getElementById('fupUploadedFile').onchange();
                    document.getElementById(that.id).click();
                    //  alert(that.id)
                    var lastdiv = document.getElementById('previewDiv_' + that.id).querySelector('.divUploadFile:last-child');
                    lastdiv = lastdiv.querySelector('input');
                    lastdiv.setAttribute('title', imageName);
                    lastdiv.setAttribute('name', that.props.destinationFolder);
                }
                else {
                    // alert(JSON.stringify(resp))
                    var currentFileName = document.getElementById('currentFileName_' + this.id).innerText;
                    var progressBar = document.getElementById('progress_' + currentFileName + localdatetime);
                    progressBar.textContent = 'Failed Uploading to the Server !';
                    progressBar.setAttribute('class', 'progress-bar progress-bar-danger progress-bar-striped active');
                }
                //do what you want with the image name returned
                //e.g update the interface
            }
            // else if (JSON.stringify(xhr) === '{}') {
            //     alert("Server Error...");

            // }
        };
        xhr.upload.addEventListener('progress', function (e) {
            // console.log(Math.ceil(e.loaded / e.total) * 100 + '%');
            var currentFileName = document.getElementById('currentFileName_' + that.id).innerText;
            var progressBar = document.getElementById('progress_' + currentFileName + localdatetime);
            progressBar.style.width = Math.ceil(e.loaded / e.total) * 100 + '%';
            if (isNaN(e.loaded / e.total)) {
                progressBar.style.width = "100%";
                progressBar.textContent = 'Failed Uploading to the Server !';
                progressBar.setAttribute('class', 'progress-bar progress-bar-danger progress-bar-striped active');
            }
            else {

                progressBar.textContent = Math.ceil(e.loaded / e.total) * 100 + '%' + " Completed";
                progressBar.setAttribute('aria-valuenow', Math.ceil(e.loaded / e.total) * 100 + '%');
            }

            if (progressBar && progressBar.style.width === "100%") {
                //progressBar.setAttribute('style', 'display:none');
            }
        }, false);
        fd.append("myFile", this.file, this.file.Name);
        fd.append("destination", this.destinationFolder);
        xhr.send(fd);

    }

    UpdateProgressBarValue = (pe) => {
        // alert(pe);
    }

    FileValidation = (file, allowedTypeStr) => {
        if (file.name && file.name != undefined) {
            let ext = file.name.substring(file.name.lastIndexOf('.') + 1);
            var re = new RegExp(ext, 'gi');
            if (allowedTypeStr.search(re) == -1) {
                alert("Allowed File Types are: " + this.allowedTypeStr);
                return false;
            }
        }
        if (file.size && file.size > 5242880) {
            alert("Document size should be less than 5MB !");
            return false;
        }
        return true;
    }
    HandleImageChangeValue = (e) => {
        if (this.props.validateevent(e)) {
            this.props.onchangeevent(e)
        } else {
            console.log('in pnk image else part.');
        }
    }


    render() {
        // alert("render--");
        this.HandlePreviewImage();
        return (
            <div>
                <div style={{ border: '1px solid black', padding: '20px' }} style={this.outerStyle}>
                    {/*<form encType="multipart/mixed" method="post" id="frmUpload">*/}
                    {/*<form encType="multipart/form-data" method="post" id="frmUpload">*/}
                    <div id={"previewDiv_" + this.id} class="row" style={{ margin: 0 }}></div>
                    <div style={{ display: 'none' }} id={"currentFileName_" + this.id}></div>
                    <div style={{ display: 'none' }} id={"currentChangingImage_" + this.id}></div>
                    {/*<img src={this.state.imagePreviewUrl} id="previewImage" height="100px" />*/}
                    <input style={{ display: 'none' }} type="file"
                        accept="file_extension|audio/*|video/*|image/*|media_type" id={"myfile_" + this.id} name={"myfile_" + this.id}
                        onChange={this.fileChangedHandler} />
                    <input style={{ display: 'none' }} type="file" id={"changeCurrentfile_" + this.id} name={"changeCurrentfile_" + this.id}
                        onChange={this.changeCurrentFileHandler} />
                    {/*<br />*/}
                    {/* {this.props.mode != 'edit' ? */}
                    {(this.props.previewImageSrc == '' || this.props.previewImageSrc == undefined) ?
                        <button style={this.buttonStyle} className={this.buttonClass} id={"fileSelect_" + this.id}>{this.btnPreviewText}</button>
                        :
                        this.multiple == 'true' ?
                            <button style={this.buttonStyle} className={this.buttonClass} id={"fileSelect_" + this.id}>{this.btnPreviewText}</button>
                            : ''
                    }
                    {/*<input type="hidden" id="fupUploadedFile" name="fupUploadedFile" value='' />*/}
                    {/*{console.log('in pnk image '+this.props.pnkvalue)}*/}
                    <input type="hidden" onClick={this.HandleImageChangeValue} pnkvalidate={this.pnkvalidate} pnkdisplayerrormsg={this.pnkdisplayerrormsg} id={this.id}
                        name={this.name} value={this.props.pnkvalue} />
                    {/*{this.pnkvalue !== '' ?
                        <input type="hidden" onClick={this.HandleImageChangeValue} pnkvalidate={this.pnkvalidate} pnkdisplayerrormsg={this.pnkdisplayerrormsg} id={this.id}
                               name={this.name} value={this.props.pnkvalue}/>
                        :
                        <input type="hidden" onClick={this.HandleImageChangeValue} pnkvalidate={this.pnkvalidate} pnkdisplayerrormsg={this.pnkdisplayerrormsg} id={this.id}
                               name={this.name} />
                    }*/}

                    {/*<input type="hidden" pnkvalidate={this.pnkvalidate} pnkdisplayerrormsg={this.pnkdisplayerrormsg} id={this.id}
                           name={this.name} value={this.pnkvalue}/>*/}
                    {/*<input type="hidden" onClick={this.props.validateevent} pnkvalidate={this.pnkvalidate} pnkdisplayerrormsg={this.pnkdisplayerrormsg} id="fupUploadedFile" name="fupUploadedFile" value='' />*/}

                    {/*</form>*/}
                </div>
            </div>
        );
    }
}

