import React from 'react'
import { Row, Col, Button, Form, Tooltip, Image, Overlay } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/images/Final_logo_NEO_ROOTS.png'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'

export default class ResetForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showForgotPassword: false
        }
    }

    render() {
        return (
            <div className="login-main-div" style={{ display: this.props.mystate.showResetForm }}>
                <Row style={{ height: '100%' }}>
                    <Col lg={6} style={{ height: '100%' }} xs={12}>
                        <div className="log-left-img"></div>
                    </Col>

                    <Col lg={6} className="log-form-col">
                        <div style={{ height: '100%' }} className="log-padding-adj">
                            <div className="login-container">


                                <div className="div-logo-main">
                                    <FontAwesomeIcon icon={faArrowLeft} className="logo-login-action" />

                                    <Image src={Logo} style={{ height: '100%' }} className="hide-mob" />
                                </div>



                            </div>

                            <div className="form-div">

                                <div className="login-container">
                                    <h3 className="login-heading">Reset Password</h3>
                                    <Form onSubmit={(e) => this.props.HandleSubmit(e)}>
                                        <div className="form-ctrl-div">
                                            <TextField
                                                id="txtResetPass"
                                                name="txtResetPass"
                                                value={this.props.mystate.txtResetPass}
                                                onChange={this.props.HandleUserInput}
                                                label="Password"
                                                type="password"

                                                variant="outlined"
                                                className="login-form-controls"
                                            />
                                            {this.props.mystate.invalidPass === true ? <div className="error-feedback">Invalid Password</div> : null}
                                        </div>
                                        <div className="form-ctrl-div">
                                            <TextField
                                                id="txtConfirmPass"
                                                name="txtConfirmPass"
                                                value={this.props.mystate.txtConfirmPass}
                                                onChange={this.props.HandleUserInput}
                                                label="Confirm Password"
                                                type="password"
                                                variant="outlined"
                                                className="login-form-controls"
                                            />
                                            {this.props.mystate.invalidCPass === true ? <div className="error-feedback">Invalid Password</div> : null}
                                            {this.props.mystate.passwordMismatch === true ? <div className="error-feedback">Password Mismatch</div> : null}
                                        </div>

                                        <div className="log-btn-div">
                                            <Button className="login-btn" type="submit">Reset</Button>
                                        </div>
                                    </Form>
                                </div>


                            </div>
                        </div>
                    </Col>
                </Row>

            </div >

        )
    }
}