import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import Options from './options'
import Table from './leads-list'
import Footer from './footer'
import { Container } from 'react-bootstrap'
import CallModal from './call-modal'
import { GetLeadList, SearchLeadList, GetUserProfileList, GetUserByProfile, AdvancedSearch } from '../../models/users';
import { MakeCall, MakeWhite } from '../../models/calls'
import { MultiEditpage } from './multieditindex';
import AppUtils from '../../apputils';
import Header from '../../common/header';
import $ from 'jquery';


class leadsPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            txtFromNumber: '',
            txtCustomerNumber: '',
            invalidtxtFromNumber: '',
            invalidtxtCustomerNumber: '',
            showCallModal: false,
            makeCall: false,
            getWhite: false,
            userNumberDisable: true,
            custNumberDisable: true,
            getLeadList: false,
            ddlLeadSort: 'All Lead',
            txtUserSearch: '',
            getSearch: false,
            numItemsPerPage: 50,
            totalRecordsInDb: 0,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,
            txtManualPageNumber: '',


            getProfileData: false,
            profileDataList: [],
            getUsersListByProfile: false,
            userListByProfile: [],
            ddlCallProfile: '',
            ddlCallUser: '',
            ddlCallProfileId: '',
            selectAll: false,
            selectedIds: [],
            sorting: { column: '', order: 'ASC' },

            rdSearchType: 'all',
            sendAdvanceSearchData: false,
            //   // Counts in footer-
            //   pageStart:0,
            //   pageEnd:0,


        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Lead');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.pageentries = [10, 25, 50, 100, 200, 300, 500]

    }

    HandleUserInputs = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    SortData = (event, key) => {

        const { sorting } = this.state;
        var column = event.target.getAttribute('column');

        if (sorting.column === column) {
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            sorting.order = 'asc';
        }

        sorting.column = column;
        this.setState({ sorting: sorting });
        this.props.PnkStore.GetData('leadlist').rows.sort(function (index, order) {
            return function (a, b) {
                var valueA, valueB;
                // valueA = a[key.item]; // Where 1 is your index, from your example
                valueA = a[key]; // Where 1 is your index, from your example
                valueB = b[key];
                // valueB = b[key.item];
                // valueA = a.data[index]['fieldValue']; // Where 1 is your index, from your example
                // valueB = b.data[index]['fieldValue'];
                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));
    }

    componentDidMount = () => {
        console.log("lastSearchBy", this.props.PnkStore.GetData('lastSearchBy'))
        // let advancesearchlist = this.props.PnkStore.GetData('advancesearchlist');
        let leadlist = this.props.PnkStore.GetData('leadlist');

        if (leadlist && leadlist.rows && leadlist.rows.length > 0) {
            // this.setState({ getLeadList: false,currentPageNumber:0,startValue:0,endValue:0 })
            let total = 0;
            let totalPages = 0;
            total = leadlist.metarow.totalRecordsInDb

            totalPages = Math.ceil(total / this.state.numItemsPerPage);

            let number = this.props.PnkStore.GetData('setNo');
            console.log("number", number)
            if (number - 1 === 0) {
                this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            else {

                this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }


            if (this.props.PnkStore.GetData('lastSearchBy') === 'search') {
                this.setState({
                    txtUserSearch: this.props.PnkStore.GetData('searchData')
                })
            }
            else if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
                this.advanceData = this.props.PnkStore.GetData('advanceSearch');

                this.setState({
                    rdSearchType: this.props.PnkStore.GetData('advanceSearchtype'),
                    currentPageNumber: 1
                })
            }
        }
        else {
            this.setState({ getLeadList: true })
        }

        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })

    }

    ShowCallModal = (customerPhone, userPhone) => {
        if (sessionStorage.getItem('current_user_profile') === "Administrator") {
            this.setState({
                userNumberDisable: false, custNumberDisable: false
            })
        }
        this.setState({
            showCallModal: true, makeCall: false, txtCustomerNumber: customerPhone, txtFromNumber: userPhone
        })
    }

    HideCallModal = () => {
        this.setState({
            showCallModal: false, txtCustomerNumber: ''
        })
    }

    HandleMakeCallSuccessResponse = (res) => {
        // alert(JSON.stringify(res.data.callresponse.message.value.RestException.Message))
        // document.getElementById('spnSuccessMessageTitle').innerText = "Call Response - ";
        if (res.data.callresponse.message.value.RestException == undefined) {
            // alert(res.data.callresponse.message.value.Call.Status);
            // document.getElementById('spnSuccessMessage').innerText = res.data.callresponse.message.value.Call.Status;
        }
        else {
            alert(res.data.callresponse.message.value.RestException.Message);
            // document.getElementById('spnSuccessMessage').innerText = res.data.callresponse.message.value.RestException.Message;
            this.setState({ getWhite: true })
        }
        // $('#crmSuccessAlert').slideDown().slideUp(10000)

        this.setState({ makeCall: false, showCallModal: false })
    }

    ChangePage = (number) => {
        // alert(number)
        // console.log("ChangePage", number)
        this.props.PnkStore.SetData('setNo', number)

        if (this.props.PnkStore.GetData('lastSearchBy') === 'search') {
            this.setState({
                getSearch: true
            })
        }
        else if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
            this.advanceData = this.props.PnkStore.GetData('advanceSearch');
            this.setState({
                sendAdvanceSearchData: true
            })
        }
        else {
            this.setState({ getLeadList: true })
        }

        if (number - 1 === 0) {
            // this.setState({ getLeadList: true, currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
        else {
            // this.setState({ getLeadList: true, currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
    }

    HandleWhiteCallSuccessResponse = (res) => {
        alert("Please make call after 1-2 minutes");
        // document.getElementById('spnSuccessMessageTitle').innerText = "DND Call Response - ";
        // document.getElementById('spnSuccessMessage').innerText = "Please make call after 1-2 minutes";
        // $('#crmSuccessAlert').slideDown().slideUp(3000)
        this.setState({ getWhite: false })
    }

    HandleUserInput = (e) => {

        let val = e.target.value;
        let name = e.target.name;
        if (name === 'txtFromNumber') {
            var numbers = /^[0-9]+$/;
            if (!val.match(numbers) || val === '') {
                this.setState({ invalidtxtFromNumber: true, [name]: '' })
            }
            else {
                this.setState({ invalidtxtFromNumber: false, [name]: val })
            }
        }
        if (name === 'txtFromNumber' && val.length === 10) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(val)) {
                this.setState({ invalidtxtFromNumber: true })


            } else {
                this.setState({ invalidtxtFromNumber: false })
            }
        }
        if (name === 'txtCustomerNumber') {
            var numbers = /^[0-9]+$/;
            if (!val.match(numbers) || val === '') {
                this.setState({ invalidtxtCustomerNumber: true, [name]: '' })
            }
            else {
                this.setState({ invalidtxtCustomerNumber: false, [name]: val })
            }
        }
        if (name === 'txtCustomerNumber' && val.length === 10) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(val)) {
                this.setState({ invalidtxtCustomerNumber: true })


            } else {
                this.setState({ invalidtxtCustomerNumber: false })
            }
        }
        if (name === "numItemsPerPage") {
            this.setState({
                [name]: val
                // getLeadList: true
            })

            if (this.props.PnkStore.GetData('lastSearchBy') === 'search') {
                this.setState({
                    getSearch: true
                })
            }
            else if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
                this.advanceData = this.props.PnkStore.GetData('advanceSearch');
                this.setState({
                    sendAdvanceSearchData: true
                })
            }
            else {
                this.setState({ getLeadList: true })
            }
        }
        if (name === "txtUserSearch" && val !== "") {
            this.setState({
                [name]: val,
                // getLeadList: true
            })
        }
        if (name === "txtUserSearch" && val == "") {
            this.setState({
                [name]: val,


                getSearch: false,
                getLeadList: true

            })
        }
        if (name === "ddlCallProfile") {
            this.setState({
                [name]: val,
                getUsersListByProfile: true
            })
        }
        if (name === "currentPageNumber")
            this.setState({ [name]: val })
    }

    MakeCall = (event) => {

        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
        }
        if (this.state.txtFromNumber === '') {
            this.setState({ invalidtxtFromNumber: true })
        }
        else if (this.state.txtFromNumber !== '' && (this.state.txtFromNumber.length === 10)) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(this.state.txtFromNumber)) {
                this.setState({ invalidtxtFromNumber: true })


            } else {
                this.setState({ invalidtxtFromNumber: false })
            }
        }
        else if (this.state.txtFromNumber !== '' && (this.state.txtFromNumber.length < 10)) {
            this.setState({ invalidtxtFromNumber: true })
        }
        else {
            this.setState({ invalidtxtFromNumber: false })
        }

        // alert(this.state.txtCustomerNumber + "---" + this.state.txtFromNumber);

        if (this.state.txtCustomerNumber === '') {
            this.setState({ invalidtxtCustomerNumber: true })
        }
        else if (this.state.txtCustomerNumber !== '' && (this.state.txtCustomerNumber.length === 10)) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(this.state.txtCustomerNumber)) {
                this.setState({ invalidtxtCustomerNumber: true })


            }
            else {
                this.setState({ invalidtxtCustomerNumber: false, makeCall: true })
            }
        }
        else if (this.state.txtCustomerNumber !== '' && (this.state.txtCustomerNumber.length < 10)) {
            this.setState({ invalidtxtCustomerNumber: true })
        }
        else {
            this.setState({ invalidtxtCustomerNumber: false, makeCall: true })
        }

    }

    HandleGetLeadDataResponse = (res) => {

        let total = 0;
        let totalPages = 0;

        if (res.data && res.data.leadlist) {

            total = res.data.leadlist.metarow.totalRecordsInDb
            this.props.PnkStore.SetData('leadlist', res.data.leadlist);
            this.props.PnkStore.BroadCast();

            if (res.data.leadlist && res.data.leadlist.rows.length > 0) {
                // console.log("length", total)
                totalPages = Math.ceil(total / this.state.numItemsPerPage);
            }

            this.props.PnkStore.SetData('lastSearchBy', 'all')
            this.props.PnkStore.SetData('searchData', '')
            this.props.PnkStore.SetData('advanceSearch', {})
            this.props.PnkStore.SetData('setNo', 1)
            this.props.PnkStore.BroadCast();
        }
        else if (res == false) {
            this.props.PnkStore.SetData('leadlist', {});
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
        this.setState({ getLeadList: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
    }

    ChangeStatusColor = (status, id) => {
        let classlist = 'crmTextWhite ';
        $(document).ready(function () {
            if (status === "On Hold" || status === "Hold")
                classlist += 'label label-warning'
            else if (status === "RNR")
                classlist += 'label label-info'
            else if (status === "Pending")
                classlist += 'label label-danger crm_theme_color'
            else if (status === "In Progress")
                classlist += 'label label-success'
            else if (status === "Converted To Booking")
                classlist += 'label colorBlue'
            else if (status === "SV")
                classlist += 'label colorPink'
            else if (status === "F2F")
                classlist += 'label colorSky'
            else if (status === "Won")
                classlist += 'label colorPurple'
            else if (status === "FN")
                classlist += 'label colorParrot'
            else if (status === "Dead")
                classlist += 'label label-default'
            else if (status === "Call Back")
                classlist += 'label colorCallback'
            else if (status === "Temporary Assignment")
                classlist += 'label colorLightYellow'
            // New
            else if (status === "HC Booked")
                classlist += 'label colorBlueViolet'
            else if (status === "HC Completed")
                classlist += 'label colorBrown'
            else if (status === "Handover to Designer")
                classlist += 'label colorDesignHanded'
            else if (status === "Design Uploaded")
                classlist += 'label colorProposalUuploaded'
            else if (status === "Proposal Sent")
                classlist += 'label colorProposalSent'
            else if (status === "Booking Amount Received")
                classlist += 'label colorBookingAmount'
            else if (status === "Follow up")
                classlist += 'label colorFollowUp'
            else if (status === "Project closed")
                classlist += 'label colorProjectClose'
            else if (status === "HC In Progress")
                classlist += 'label colorHCProgress'
            else
                classlist += 'label colorDefault'
            if (document.getElementById('spnStatus_' + id) != null)
                document.getElementById('spnStatus_' + id).setAttribute('class', classlist)
        })

    }

    HandleUserSearch = (e) => {
        if (this.state.txtUserSearch.length >= 3) {
            this.setState({
                getSearch: true
            })
        }
        else {
            this.setState({
                getSearch: false,
            })
        }
        if (this.state.txtUserSearch === '') {
            this.setState({
                getSearch: false,
                getLeadList: true
            });
        }

    }

    HandleSuccessSearchLeadListResponse = (res) => {
        let total = 0;
        let totalPages = 0;
        if (res !== false) {
            // alert(res.data.leadlist.rows.length);
            if (res.data && res.data.leadlist && res.data.leadlist.rows && res.data.leadlist.rows.length > 0) {
                total = res.data.leadlist.metarow.totalRecordsInDb

                if (res.data.leadlist && res.data.leadlist.rows.length > 0) {
                    // console.log("length", total)
                    totalPages = Math.ceil(total / this.state.numItemsPerPage);
                }

                this.props.PnkStore.SetData('leadlist', res.data.leadlist);

                this.props.PnkStore.SetData('lastSearchBy', 'search')
                this.props.PnkStore.SetData('searchData', this.state.txtUserSearch)
                this.props.PnkStore.SetData('advanceSearch', {})
                this.props.PnkStore.SetData('setNo', 1)
                this.props.PnkStore.BroadCast();
            }
            else {
                let error = 'No Record Found';
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
                document.getElementById('spnWarningMessage').innerText = error;
                $('#crmWarningAlert').slideDown().slideUp(5000, function () {
                })
            }
            this.setState({ getSearch: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
        }
        else {
            let error = 'Something went wrong';
            let errorData = this.props.PnkStore.GetData('error');
            if (typeof this.props.PnkStore.GetData('error') !== 'object')
                errorData = JSON.stringify(this.props.PnkStore.GetData('error'));
            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
    }

    HandleProfileGetResponse = (res) => {
        if (res.data && res.data.profilelist && res.data.profilelist.rows) {
            // console.log("profilelist", res.data.profilelist)
            // this.props.PnkStore.SetData('profilelist', res.data.profilelist);
            // this.props.PnkStore.BroadCast();
            this.setState({
                profileDataList: res.data.profilelist
            })
        }
        this.setState({
            getProfileData: false
        })

    }

    HandleGetUserByProfileSuccessResponse = (res) => {
        // console.log("HandleGetUserByProfileSuccessResponse", res.data)
        if (res.data && res.data.userlistbyprofile) {
            this.setState({
                userListByProfile: res.data.userlistbyprofile,
                getUsersListByProfile: false
            })
        }
    }

    SelectAll = (e) => {
        // console.log("SelectAll", e)
        if (e.target.checked === true) {
            this.setState({
                selectAll: true
            })
            var checkboxes = document.getElementsByName('list-id-chk');

            var checkboxesChecked = [];
            // loop over them all
            for (var i = 0; i < checkboxes.length; i++) {
                // And stick the checked ones onto an array...
                if (checkboxes[i].value !== "") {
                    // console.log("checkbox", checkboxes[i])
                    if (this.state.selectedIds.includes(checkboxes[i].value) === false) {
                        document.getElementById(checkboxes[i].value).checked = true
                        checkboxesChecked.push(checkboxes[i].value);
                    }


                }
            }
            this.setState({
                selectedIds: this.state.selectedIds.concat(checkboxesChecked)
            })

        }
        else {
            this.setState({
                selectAll: false, selectedIds: []
            })
        }
    }

    SelectSingleRow = (e) => {
        // console.log("SelectSingleRow", e.target)
        let checkedBoxes = []
        if (this.state.selectedIds.length > 0 && this.state.selectedIds.includes(e.target.value) === true) {
            document.getElementById(e.target.value).checked = false;
            let result = this.state.selectedIds.filter(x => x !== e.target.value)
            // console.log("result", result)
            this.setState({
                selectedIds: result
            })
        }
        else {
            checkedBoxes.push(e.target.value)
            this.setState({
                selectedIds: this.state.selectedIds.concat(checkedBoxes)
            })
            document.getElementById(e.target.value).checked = true;


        }
    }

    HandleCheckBoxes = (e) => {
        if (e.target.id === 'chkSelectAllLeads') {
            let checkboxes = document.getElementsByName('chkLeads');
            for (let i = 0, n = checkboxes.length; i < n; i++) {
                checkboxes[i].checked = e.target.checked;
            }
            if (e.target.checked === true) {
                this.setState({ showEditBox: true, selectAll: true })
            }
            else {
                this.setState({ showEditBox: false, selectAll: false })
            }
        }
        else {
            //single checkbox
            let flag = false;
            let checkboxes = document.getElementsByName('chkLeads');
            for (let i = 0, n = checkboxes.length; i < n; i++) {
                if (checkboxes[i].checked === true) {
                    flag = true;
                    break;
                }
            }
            if (flag === true) {
                this.setState({ showEditBox: true, selectAll: false })
            }
            else {
                this.setState({ showEditBox: false, selectAll: false })
            }

        }
    }

    EditAndDeleteLead = (lead_id, action) => {
        let leadArr = [];
        if (lead_id == '') {
            let checkboxes = document.getElementsByName('chkLeads');
            for (let i = 0, n = checkboxes.length; i < n; i++) {
                if (checkboxes[i].checked === true && checkboxes[i].id !== 'chkSelectAllLeads') {
                    lead_id = (checkboxes[i].id).replace('chkLead_', '');
                    leadArr.push(lead_id);
                    // break;
                }
            }
        }
        if (action == 'edit') {
            // this.props.componentprops.OpenModal(MultiEditpage, 'sm', { lead_ids: leadArr })
            //Open Multi Edit
            if (sessionStorage.getItem('current_user_profile') !== 'Marketing')
                AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/' + lead_id)
        }
        else if (action == 'edit_multi') {
            // this.props.componentprops.OpenModal(MultiEditpage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Add User Profile' }, { action: 'profile', name: 'Profile', close: this.CloseModal })
            this.props.componentprops.OpenModal(MultiEditpage, 'md', { modalClass: 'lead-entry-modal', headerClass: 'lead-entry-header', titleClass: 'lead-entry-title', title: 'Lead Transfer' }, { action: 'lead', name: 'Lead', close: this.CloseModal, lead_ids: leadArr })
            // this.props.componentprops.OpenModal(MultiEditpage, 'md', { lead_ids: leadArr })
            //Open Multi Edit
            // {sessionStorage.getItem('current_user_profile') !== 'Marketing' ?
            //     AppUtils.HandlePageClick(this.props.componentprops, 'lead/edit/' + lead_id)
            // :''}
        }
        else if (window.confirm("Are you sure?")) {
            this.deleteLeadIds = this.deleteLeadIds.concat(leadArr);
            this.setState({ deleteData: true })
        }
    }

    CloseModal = () => {
        this.props.componentprops.HandleCloseModal();
        this.setState({ getLeadList: true })
    }

    LogOut = () => {
        console.log("logout clicked")
        sessionStorage.clear();
        localStorage.clear();
        this.setState({ loginStatus: 'logout' });
        // AppUtils.HandlePageClick(this.props, '')
        window.location.href = '/'
    }

    GetAdvanceSearchData = () => {
        // let advancesearchlist = this.props.PnkStore.GetData('advancesearchlist');
        // console.log(JSON.stringify(advancesearchlist))
        // if (advancesearchlist && advancesearchlist.rows && advancesearchlist.rows.length > 0) {
        //     this.props.PnkStore.SetData('leadlist', advancesearchlist);
        //     this.props.PnkStore.BroadCast();
        //     this.props.PnkStore.SetData('advancesearchlist', {});
        let leadlist = this.props.PnkStore.GetData('leadlist');

        let total = 0;
        let totalPages = 0;
        if (leadlist.rows) {
            total = leadlist.metarow.totalRecordsInDb

            if (leadlist.rows.length > 0) {
                // console.log("length", total)
                totalPages = Math.ceil(total / this.state.numItemsPerPage);
            }
        }

        // this.setState({ getLeadList: false })

        this.setState({ getLeadList: false, sendAdvanceSearchData: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
    }

    HandleSuccessAdvanceSearch = (res) => {
        // alert(1)
        // console.log("HandleSuccessAdvanceSearch",res.data)
        let total = 0;
        let totalPages = 0;
        if (res !== false) {
            // alert(res.data.leadlist.rows.length);
            // this.setState({ getSearch: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
            if (res.data && res.data.leadlist && res.data.leadlist.rows) {
                total = res.data.leadlist.metarow.totalRecordsInDb

                if (res.data.leadlist && res.data.leadlist.rows.length > 0) {
                    // console.log("length", total)
                    totalPages = Math.ceil(total / this.state.numItemsPerPage);
                }

                this.props.PnkStore.SetData('leadlist', res.data.leadlist);
                // this.props.PnkStore.SetData('setNo', this.state.currentPageNumber+1) 
                // this.setState({
                //     currentPageNumber:1,startValue:0,endValue:0
                // })
                this.props.PnkStore.BroadCast();


            }
            else {
                let error = 'No Record Found';
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
                document.getElementById('spnWarningMessage').innerText = error;
                $('#crmWarningAlert').slideDown().slideUp(5000, function () {
                })
            }
        }
        else {
            let error = 'Something went wrong';
            let errorData = this.props.PnkStore.GetData('error');
            if (typeof this.props.PnkStore.GetData('error') !== 'object')
                errorData = JSON.stringify(this.props.PnkStore.GetData('error'));
            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }

        this.setState({ sendAdvanceSearchData: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
    }

    Reload = () => {
        this.setState({
            currentPageNumber: 1,
            startValue: 0,
            endValue: (this.state.startValue + this.state.numItemsPerPage),
            getLeadList: true
        })
    }
    render() {
        let currentPageNumber = 1;
        if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
            console.log("currentPageNumber", this.props.PnkStore.GetData('setNo'))
            currentPageNumber = this.props.PnkStore.GetData('setNo')
        }
        else {
            currentPageNumber = this.state.currentPageNumber
        }

        // alert(JSON.stringify(this.props.PnkStore.GetData('allowed_routes')))
        // console.log("lead list state", this.state)
        let leadlist = this.props.PnkStore.GetData('leadlist');
        // let advancesearchlist = this.props.PnkStore.GetData('advancesearchlist');

        return (

            <Container fluid className="data-table-lead">
                {this.props.componentprops.showHeader === undefined && this.props.componentprops.showHeader !== false ?
                    <Header showBackButton={false} GetAdvanceSearchData={this.GetAdvanceSearchData} showSearch={true} title='Lead' {...this.props} LogOut={this.LogOut} /> : null}
                <Options HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInput} SortData={this.SortData}
                    Reload={this.Reload}
                    {...this.props} />
                <Table mystate={this.state} ShowCallModal={this.ShowCallModal}
                    leadData={leadlist.rows} ChangeStatusColor={this.ChangeStatusColor}
                    HandleUserInput={this.HandleUserInput}
                    ChangePage={this.ChangePage}
                    pageentries={this.pageentries} {...this.props}
                    SelectAll={this.SelectAll}
                    SelectSingleRow={this.SelectSingleRow}
                    HandleCheckBoxes={this.HandleCheckBoxes}
                    EditAndDeleteLead={this.EditAndDeleteLead}
                    allowed_route={(this.props.PnkStore.GetData('allowed_routes')).includes('Bulk Edit')}
                    ShowReminderModal={this.ShowReminderModal}
                    currentPageNumber={currentPageNumber}
                />
                <Footer {...this.props}
                    SortData={this.SortData}
                    HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInput}
                />


                {this.state.showCallModal === true ?
                    <CallModal
                        visible={this.state.showCallModal}
                        HideCallModal={this.HideCallModal}
                        mystate={this.state}
                        profileData={this.state.profileDataList}
                        userData={this.state.userListByProfile}
                        HandleUserInput={this.HandleUserInput}
                        CallNow={this.MakeCall} /> : null}
                {((this.state.getLeadList === true) ? GetLeadList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.currentPageNumber, this.state.numItemsPerPage, 'All Lead', '', this.HandleGetLeadDataResponse) : "")}
                {this.state.getSearch === true ? SearchLeadList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.currentPageNumber, this.state.numItemsPerPage, 'user_name', this.state.txtUserSearch, this.HandleSuccessSearchLeadListResponse) : ''}
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
                {((this.state.getUsersListByProfile === true) ? GetUserByProfile(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.ddlCallProfile, '', this.HandleGetUserByProfileSuccessResponse) : "")}
                {((this.state.sendAdvanceSearchData === true) ? AdvancedSearch(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.rdSearchType, this.advanceData, this.state.currentPageNumber, this.state.numItemsPerPage, this.HandleSuccessAdvanceSearch) : "")}

                {/* EXOTEL CALLING */}
                {((this.state.makeCall === true) ? MakeCall(this.props.PnkStore, this.state.txtFromNumber, this.state.txtCustomerNumber, this.state.txtCustomerName, sessionStorage.getItem('current_user_name'), this.HandleMakeCallSuccessResponse) : "")}
                {((this.state.getWhite === true) ? MakeWhite(this.props.PnkStore, this.state.txtFromNumber, this.state.txtCustomerNumber, this.HandleWhiteCallSuccessResponse) : "")}
            </Container >
        )
    }
}
export const LeadPage = PnkConnect(leadsPage, "leadlist");