import React from 'react'
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { AddLeadSource } from '../../models/users'
import PnkImage from '../../pnk-react/pnk-ctrl/pnk-image'
export default class SourceEntry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {


        }
    }

    render() {
       
        return (


            <PnkHtmlForm id="frmLeadSourceEntry" onsubmit={this.props.SaveLeadSourceData} submitbtn="btnSourceEntry">

                <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                    <Button variant="light" className="profile-entry-cancel-btn" onClick={() => this.props.ShowEditMode()}>Edit</Button>
                </div>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Source Name</Form.Label>
                    <Form.Control type="text" placeholder="Source Name" className="profile-entry-controls"
                        name="txtSourceName" id="txtSourceName"
                        pnkvalidate="forceentry"
                        pnkvalue={this.props.editDataObj.txtSourceName}
                        value={this.props.mystate.txtSourceName}
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Source Name" />
                </Form.Group>
                {/* <Form.Group >
                    <Form.Label className="profile-entry-label">Mail Forward To</Form.Label>
                    <Form.Control type="text" placeholder="Mail Forward To" className="profile-entry-controls"
                        name="txtMailForwardTo" id="txtMailForwardTo"
                        pnkvalidate="forceemail"
                        pnkvalue={this.props.editDataObj.txtMailForwardTo}
                        value={this.props.editDataObj.txtMailForwardTo}
                        onChange={this.props.HandleUserInputs}
                        pnkdisplayerrormsg="Invalid Email" />
                </Form.Group> */}
                {/* <Form.Group >
                    <Form.Label className="profile-entry-label">Default Assignee</Form.Label>
                    <Form.Control type="text" placeholder="Default Assignee" className="profile-entry-controls"
                        name="txtDefaultAssignee" id="txtDefaultAssignee"
                        pnkvalidate="forceentry"
                        pnkvalue={this.props.editDataObj.txtDefaultAssignee}
                        value={this.props.editDataObj.txtDefaultAssignee}

                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Default Assignee" />
                </Form.Group> */}
                <Form.Group >
                    <Form.Label className="profile-entry-label">Source Image</Form.Label>
                    <div className="padding-left-0 padding-right-0">
                        <div className="lead-source-img-div" id="lead-entry-img-file">
                            <div className="padding-img-class1">

                                <div className="booking-browse-label text-center" style={this.props.image_editmode}>

                                    <PnkImage
                                        onChange={this.props.HandleImage}
                                        destinationFolder="application/public/images/uploads/crm/lead_source"
                                        allowedTypeStr="png,jpg,jpeg,pdf"
                                        btnPreviewText="Upload Site Pictures"
                                        multiple="true"
                                        limitFiles="1"
                                        id="fileAdditionalAttachment"
                                        name="fileAdditionalAttachment"
                                        buttonClass="btn-bs-file btn btn-sm lead-img-browse-label"
                                        pnkvalue={this.props.editDataObj.fileAdditionalAttachment}
                                        mode={this.props.mystate.mode}
                                        renderChild={this.props.mystate.renderChild}
                                        previewImageSrc={this.props.editDataObj.fileAdditionalAttachment}

                                        itemStyle="padding:10px;height:150px;text-align:center"

                                    />
                                </div>
                                <div className="lead-img-text-head">
                                    (pdf and jpeg)
                                                                        </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Source Status</Form.Label>
                    <Form.Control as="select" className="le-form-controls-select source-ctrl-select"
                        name="ddlSourceStatus" id="ddlSourceStatus"
                        onChange={this.props.HandleUserInputs}
                        pnkvalidate="forceselect"
                        pnkdisplayerrormsg='Select One'
                        pnkvalue={this.props.editDataObj.ddlSourceStatus}
                        value={this.props.editDataObj.ddlSourceStatus}
                        // pnkvalue={this.props.editDataObj.ddlDesigner}
                        // value={this.props.mystate.ddlDesigner}
                        style={{ padding: '4px', width: '100%' }}>
                        <option value="" disabled selected>Source Status	</option>
                        <option value="1">Active</option>
                        <option value="0">In Active</option>


                    </Form.Control>
                </Form.Group>




                <div className="profile-btn-div">
                    <Row className="flex-end">
                        <Col lg={3} xs={5}>
                            <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.HideModal}>Cancel</Button>
                        </Col>
                        <Col lg={2} xs={5}>
                            <button className="profile-entry-save-btn pnkvalidatesubmit" name="Save" id="btnSourceEntry" onClick={() => this.props.SaveLeadSourceData}>{this.props.mystate.showButtonType}</button>
                            {/* <Button variant="light" type="button" className="profile-entry-save-btn pnkvalidatesubmit" id="btnProfileEntry" onClick={this.props.SaveProfileData}  > Save</Button> */}
                        </Col>
                    </Row>
                </div>
            </PnkHtmlForm >
        )
    }
}