import React from 'react'
import { Alert, ProgressBar } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
export default class LoginAlert extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
       
        return (

            <div className="loginAlertDiv">
                {this.props.mystate.type === "success" ?
                    <React.Fragment>
                        <ProgressBar variant="success" striped now={this.props.currentProgess} />
                        <Alert variant="success">
                            {this.props.mystate.messageToDisplay}
                        </Alert>
                    </React.Fragment> :
                    <React.Fragment>
                        <ProgressBar variant="danger" now={this.props.currentProgess} />
                        <Alert variant="danger">
                            {this.props.mystate.messageToDisplay}
                        </Alert>
                    </React.Fragment>
                }

            </div>
        )

    }
}