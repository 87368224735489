import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }
    GoToEntry = () => {
        this.setState({
            gotoEntry: true
        })
        // 

    }
    render() {


        return (
            <div className="show-mob le-mob-header">
                <div className="crm-container">
                    <div className="mob-container">
                        <Row >
                            <Col xs={6} className=" flex-left">
                                <Button variant="light" className="le-mob-header-plus-btn">+</Button>
                            </Col>
                            <Col xs={3} className="">
                                <Button variant="light" className="le-cancel mr-3">CANCEL</Button>

                            </Col>
                            <Col xs={3} className="">
                                <Button variant="light" className="le-save">SAVE</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}