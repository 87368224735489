import React from 'react'
import { Row, Col, Button, ButtonGroup, Form, Image } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { designerReport } from './data'
export default class SalesReport extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    <Row className="mob-row-align mob-row-color">
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                        </Col>
                                        <Col lg={3} xs={3}>
                                            <h3 className="table-head-lead">Designer Name</h3>
                                        </Col>
                                        <Col lg={3} xs={3}>
                                            <h3 className="table-head-lead ">No of Handovers</h3>
                                        </Col>
                                        <Col lg={3} className="" xs={3}>
                                            <h3 className="table-head-lead ">Proposals Sent</h3>
                                        </Col>
                                        <Col lg={2} className="" xs={3}>
                                            <h3 className="table-head-lead ">Projects Closed</h3>
                                        </Col>


                                    </Row>
                                    <div>
                                        {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map((item, i) =>
                                            <div className="lead-table-data-user">

                                                {((i + 1) % 2 === 0) ?
                                                    (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                        <Col lg={1} className="hide-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div> {i + 1}</div>

                                                                </div>
                                                            </div>

                                                        </Col>

                                                        <Col lg={3} className="" xs={3}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.designer_user}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={3} className="" xs={3}>

                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{item.total_handover}</div>
                                                                </div>
                                                            </div>

                                                        </Col>

                                                        <Col lg={3} className="" xs={3}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{item.proposal_sent}</div>
                                                                </div>

                                                            </div>
                                                        </Col>


                                                        <Col lg={2} className="" xs={3} >
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.project_closed}</div>

                                                                </div>
                                                            </div>

                                                        </Col>




                                                    </Row>) : (
                                                        <Row className="table-items-user" style={{ background: '#ffffff' }}>
                                                            <Col lg={1} className="hide-mob">
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div> {i + 1}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col lg={3} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.designer_user}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={3} className="" xs={3}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.total_handover}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col lg={3} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.proposal_sent}</div>
                                                                    </div>

                                                                </div>
                                                            </Col>


                                                            <Col lg={2} className="" xs={3} >
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.project_closed}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>


                                                        </Row>)}

                                            </div>
                                        ) : null}





                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}