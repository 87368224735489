import React from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { GetAreaListByCity, GetLeadData, GetUserByProfile, AdvancedSearch } from '../../models/users'
import $ from 'jquery';
import { CSVLink, CSVDownload } from 'react-csv';
// import { Multiselect } from 'multiselect-react-dropdown';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import { PnkConnect } from "../../pnk-react/pnk-miniredux";
import MenuItem from '@material-ui/core/MenuItem';
class SearchForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            getCity: true,
            startDate: '',
            areaList: [],
            profileStageData: [],
            getUsersListByProfile: false,
            userListByProfile: [],
            getLeadData: false,
            rdSearchType: 'all',
            getArea: false,
            ddlPreferedCity: '',
            ddlPreferedArea: '',
            ddlLeadProfile: '',
            ddlLeadOwner: '',
            ddlLeadSource: '',
            ddlLeadStage: [],
            ddlLeadRequirement: '',
            ddlLeadSolution: '',
            txtStartDate: '',
            txtEndDate: '',
            ddlProjectEstimate: '',
            txtLeadPatchEndDate: '',
            txtLeadPatchStartDate: '',
            sendAdvanceSearchData: false,
            ddlLeadProfileId: '',
            showExportToCSV: false,
            showGenerateBtn: true,
            csvData: []


        }
        this.spaceType = [
            'Balcony',
            'Terrace',
            'Garden',
            'Full House',
            'Indoors'
        ];
        this.projectEstimate = [
            'Below 1 lakh',
            '1-2 lakhs',
            '2-4 lakhs',
            '4-6 lakhs',
            '6-8 lakhs',
            ' 8 lakhs and above'
        ]
        this.data = {}
        this.props.componentprops.HandleTitle('Reports');
    }
    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }

    componentDidMount = () => {
        if (JSON.stringify(this.props.PnkStore.GetData('leaddata')) == '{}')
            this.setState({ getLeadData: true })

        let allowedLeadStages = [];
        if (sessionStorage.getItem('current_user_allowed_lead_stages')) {
            allowedLeadStages = sessionStorage.getItem('current_user_allowed_lead_stages');
            allowedLeadStages = allowedLeadStages.split(',');
            this.setState({
                profileStageData: allowedLeadStages
            })
        }
    }

    HandleChange = (type, date) => {

        this.setState({
            [type]: date
        });

    };
    HandleUserInput = (e) => {
        this.setState({
            showExportToCSV: false,
            showGenerateBtn: true
        })

        var name = e.target.name
        var value = e.target.value
        if (name === "ddlPreferedCity" && value !== '') {
            this.setState({
                getArea: true
            })
        }
        if (name === "ddlLeadProfile" && value !== '') {
            let profileData = [];
            if (this.props.PnkStore.GetData("leaddata") &&
                this.props.PnkStore.GetData("leaddata").length > 0) {


            }
            var sel = document.getElementById('ddlLeadProfile');
            var opt = sel.options[sel.selectedIndex];

            this.setState({
                ddlLeadProfileId: opt.id
            })
            this.setState({
                getUsersListByProfile: true
            })
        }
        this.setState({
            [name]: value
        })
    }
    HandleGetAreaSuccessResponse = (res) => {

        if (res.data && res.data.arealist && res.data.arealist.rows) {

            this.setState({ areaList: res.data.arealist })

        }

        this.setState({
            getCity: false
        })
    }
    HandleGetLeadDataResponse = (res) => {
        if (res.data) {
            this.props.PnkStore.SetData('leaddata', res.data);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getLeadData: false
        })
    }
    HandleGetUserByProfileSuccessResponse = (res) => {

        if (res.data && res.data.userlistbyprofile) {
            this.setState({
                userListByProfile: res.data.userlistbyprofile,
                getUsersListByProfile: false
            })
        }
    }
    GenerateAdvanceSearch = () => {
        this.data = {
            ddlPreferedCity: this.state.ddlPreferedCity,
            ddlPreferedArea: this.state.ddlPreferedArea,
            ddlLeadProfile: this.state.ddlLeadProfileId,
            ddlLeadOwner: this.state.ddlLeadOwner,
            ddlLeadSource: this.state.ddlLeadSource,
            ddlLeadStage: this.state.ddlLeadStage,
            ddlLeadRequirement: this.state.ddlLeadRequirement,
            ddlLeadSolution: this.state.ddlLeadSolution,
            txtStartDate: this.state.txtStartDate,
            txtEndDate: this.state.txtEndDate,
            ddlProjectEstimate: this.state.ddlProjectEstimate,
            txtLeadPatchStartDate: this.state.txtLeadPatchStartDate,
            txtLeadPatchEndDate: this.state.txtLeadPatchEndDate
        }
        this.setState({
            sendAdvanceSearchData: true
        })

    }
    ClearData = () => {
        this.setState({
            ddlPreferedCity: '',
            ddlPreferedArea: '',
            ddlLeadProfile: '',
            ddlLeadOwner: '',
            ddlLeadSource: '',
            ddlLeadStage: '',
            ddlLeadRequirement: '',
            ddlLeadSolution: '',
            txtStartDate: '',
            txtEndDate: '',
            ddlProjectEstimate: '',
            txtLeadPatchStartDate: '',
            txtLeadPatchEndDate: ''
        })
    }
    SelectMultipleStages = (selectedList, selectedItem) => {
        this.setState({
            ddlLeadStage: selectedList
        })

    }
    HandleSuccessAdvanceSearch = (res) => {
        let that = this;
        let array = []
        if (res !== false) {

            if (res.data && res.data.leadlist) {

                // that.props.PnkStore.SetData('advancesearchlist', res.data.leadlist);
                that.props.PnkStore.SetData('leadlist', res.data.leadlist);
                that.props.PnkStore.BroadCast();

                that.setState({ sendAdvanceSearchData: false, showGenerateBtn: false, showExportToCSV: true })
                let data = ['Date Time', 'Update On', 'Lead Owner', 'Profile', 'Lead Source', 'Lead Stage', 'Tele Assignee', 'Lead City', 'User Name', 'User Email', 'User Contact', 'Space', 'Comments', 'Patch Date', 'Callback Date Time', 'HC Completed', 'HC Booked', 'Design Uploaded', 'Proposal Sent',
                    'Booking Amount Received', 'Follow Up', 'Project Closed', 'VC Booked', 'VC Completed', 'Project Meeting', 'Dead', 'Description', 'TL Comments', 'Campaign Name','Ad Set Name', 'Ad Name']
                array.push(data)
                let csv_data = []
                if (res && res.data && res.data.leadlist && res.data.leadlist.rows && res.data.leadlist.rows.length > 0)
                    res.data.leadlist.rows.map(item => {
                        let callback_date_time = '', hc_completed_date_time = '', hc_booked_date_time = '', proposal_uploaded_date_time = '', proposal_sent_date_time = '', booking_amount_received_date_time = '', follow_up_date_time = '', project_close_date_time = '', vc_booked_date_time = '', vc_completed_date_time = '', project_meeting_date_time = '', lead_dead_date = '', lead_campaign = '', lead_adset = '', lead_ad;
                        if (item.callback_date_time !== undefined || item.callback_date_time !== null) {
                            callback_date_time = item.callback_date_time
                        }
                        if (item.hc_completed_date_time !== undefined || item.hc_completed_date_time !== null) {
                            hc_completed_date_time = item.hc_completed_date_time
                        }
                        if (item.hc_booked_date_time !== undefined || item.hc_booked_date_time !== null) {
                            hc_booked_date_time = item.hc_booked_date_time
                        }
                        if (item.proposal_uploaded_date_time !== undefined || item.proposal_uploaded_date_time !== null) {
                            proposal_uploaded_date_time = item.proposal_uploaded_date_time
                        }
                        if (item.proposal_sent_date_time !== undefined || item.proposal_sent_date_time !== null) {
                            proposal_sent_date_time = item.proposal_sent_date_time
                        }
                        if (item.booking_amount_received_date_time !== undefined || item.booking_amount_received_date_time !== null) {
                            booking_amount_received_date_time = item.booking_amount_received_date_time
                        }
                        if (item.follow_up_date_time !== undefined || item.follow_up_date_time !== null) {
                            follow_up_date_time = item.follow_up_date_time
                        }
                        if (item.project_close_date_time !== undefined || item.project_close_date_time !== null) {
                            project_close_date_time = item.project_close_date_time
                        }

                        if (item.vc_booked_date_time !== undefined || item.vc_booked_date_time !== null) {
                            vc_booked_date_time = item.vc_booked_date_time
                        }
                        if (item.vc_completed_date_time !== undefined || item.vc_completed_date_time !== null) {
                            vc_completed_date_time = item.vc_completed_date_time
                        }
                        if (item.project_meeting_date_time !== undefined || item.project_meeting_date_time !== null) {
                            project_meeting_date_time = item.project_meeting_date_time
                        }
                        if (item.lead_dead_date !== undefined || item.lead_dead_date !== null) {
                            lead_dead_date = item.lead_dead_date
                        }
                        if (item.lead_campaign !== undefined || item.lead_campaign !== null) {
                            lead_campaign = item.lead_campaign
                        }
                        if (item.lead_adset !== undefined || item.lead_adset !== null) {
                            lead_adset = item.lead_adset
                        }
                        if (item.lead_ad !== undefined || item.lead_ad !== null) {
                            lead_ad = item.lead_ad
                        }

                        csv_data = [item.date_time, item.updated_on, item.lead_owner, item.lead_department[0].profile_name, item.lead_source, item.lead_current_stage, item.tele_assignee, item.lead_city, item.user_name, item.user_email, item.user_mobile_no, item.spaceType, item.crmComments, item.patch_date_time,
                            callback_date_time, hc_completed_date_time, hc_booked_date_time, proposal_uploaded_date_time, proposal_sent_date_time, booking_amount_received_date_time, follow_up_date_time, project_close_date_time, vc_booked_date_time, vc_completed_date_time, project_meeting_date_time, lead_dead_date, item.description, item.tlComments, lead_campaign, lead_adset, lead_ad]
                        array.push(csv_data)

                    })


            }

            // AppUtils.HandlePageClick(that.props, 'leads');
        }
        else {
            let error = 'Something went wrong';
            let errorData = this.props.PnkStore.GetData('error');
            if (typeof this.props.PnkStore.GetData('error') !== 'object')
                errorData = JSON.stringify(this.props.PnkStore.GetData('error'));
            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({ sendAdvanceSearchData: false })
        }
        this.setState({
            csvData: array
        })

    }
    render() {
        var currentdate = new Date();
        let filename = 'Lead_Report_' + currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + " @ "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();;
        let arealist = this.state.areaList
        let cityData = []; let profileData = []; let propertyData = []; let leadSourceData = [];
        if (this.props.PnkStore.GetData("leaddata") &&
            this.props.PnkStore.GetData("leaddata").length > 0) {

            if (this.props.PnkStore.GetData("leaddata")[0] && this.props.PnkStore.GetData("leaddata")[0].citylist && this.props.PnkStore.GetData("leaddata")[0].citylist.rows) {
                cityData = this.props.PnkStore.GetData("leaddata")[0].citylist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[1] && this.props.PnkStore.GetData("leaddata")[1].proptypelist && this.props.PnkStore.GetData("leaddata")[1].proptypelist.rows) {
                propertyData = this.props.PnkStore.GetData("leaddata")[1].proptypelist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[2] && this.props.PnkStore.GetData("leaddata")[2].leadsourcelist && this.props.PnkStore.GetData("leaddata")[2].leadsourcelist.rows) {
                leadSourceData = this.props.PnkStore.GetData("leaddata")[2].leadsourcelist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[3] && this.props.PnkStore.GetData("leaddata")[3].profilelist && this.props.PnkStore.GetData("leaddata")[3].profilelist.rows) {
                profileData = this.props.PnkStore.GetData("leaddata")[3].profilelist.rows;
            }
        }
        console.log("profileData", this.state.profileStageData)
        return (

            <Row className="paddingReport">
                <Col lg={12} className="sidebar-col">

                    <div className="filter-body-div">
                        <div className="filter-connr">



                            <Row className="filter-pad-bottom">
                                <Col lg={6} xs={6}>

                                    <div className="fil-se-body">
                                        <Form.Group className="margin-bottom-t-filter">
                                            <DatePicker
                                                name="txtStartDate" id="txtStartDate"
                                                selected={this.state.txtStartDate}
                                                onChange={(e) => this.HandleChange("txtStartDate", e)}
                                                className="filter-form-cntrl"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Date from"
                                            />


                                        </Form.Group>
                                    </div>

                                </Col>
                                <Col lg={6} xs={6}>
                                    <div className="fil-se-body">

                                        <Form.Group className="margin-bottom-t-filter">
                                            <DatePicker
                                                name="txtEndDate" id="txtEndDate"
                                                selected={this.state.txtEndDate}
                                                onChange={(e) => this.HandleChange("txtEndDate", e)}
                                                className="filter-form-cntrl"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Date to"
                                            />

                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="filter-pad-bottom">
                                <Col lg={6} xs={6}>

                                    <div className="fil-se-body">
                                        <Form.Group className="margin-bottom-t-filter">

                                            <Form.Control as="select" className="filter-form-cntrl"
                                                name="ddlLeadProfile" id="ddlLeadProfile"
                                                value={this.state.ddlLeadProfile}
                                                onChange={this.HandleUserInput}
                                                style={{ padding: '4px', width: '100%' }}>
                                                <option value="" disabled selected>Profile</option>
                                                {(profileData && profileData.length > 0) ? profileData.map(item =>
                                                    <option value={item.profile_name} id={item._id}>{item.profile_name}</option>

                                                ) : ""}
                                            </Form.Control>
                                            {/* <span className="floating-label">City</span> */}
                                        </Form.Group>
                                    </div>

                                </Col>
                                <Col lg={6} xs={6}>
                                    <div className="fil-se-body">
                                        <Form.Group className="margin-bottom-t-filter">

                                            <Form.Control as="select" className="filter-form-cntrl"
                                                name="ddlLeadOwner" id="ddlLeadOwner"
                                                value={this.state.ddlLeadOwner}
                                                onChange={this.HandleUserInput}
                                                style={{ padding: '4px', width: '100%' }}>
                                                <option value="" disabled selected>Users </option>
                                                {(this.state.userListByProfile && this.state.userListByProfile.rows && this.state.userListByProfile.rows.length > 0) ? this.state.userListByProfile.rows.map(item =>
                                                    item.user_name === this.state.ddlLeadOwner ?
                                                        <option value={item.user_name} selected>{item.user_name}</option>
                                                        :
                                                        <option value={item.user_name}>{item.user_name}</option>

                                                ) : ""}
                                            </Form.Control>
                                            {/* <span className="floating-label">City</span> */}
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="filter-pad-bottom">

                                <Col lg={6} xs={6}>

                                    <div className="fil-se-body">
                                        <Form.Group className="margin-bottom-t-filter">

                                            <Form.Control as="select" className="filter-form-cntrl"
                                                name="ddlLeadSource" id="ddlLeadSource"
                                                value={this.state.ddlLeadSource}
                                                onChange={this.HandleUserInput}
                                                style={{ padding: '4px', width: '100%' }}>
                                                <option value="" disabled selected>Source</option>
                                                {(leadSourceData && leadSourceData.length > 0) ? leadSourceData.map(item =>
                                                    <option value={item.lead_source_name}>{item.lead_source_name}</option>
                                                ) : ""}
                                            </Form.Control>

                                        </Form.Group>
                                    </div>

                                </Col>
                                <Col lg={6} xs={6}>
                                    <div className="fil-se-body">

                                        <Form.Group className="margin-bottom-t-filter">
                                            <FormControl variant="outlined" className="width_100" >
                                                <InputLabel htmlFor="ddlLeadStage" className="le-label-multi">Stage</InputLabel>
                                                <Select
                                                    value={this.state.ddlLeadStage}
                                                    onChange={this.HandleUserInput}
                                                    inputProps={{
                                                        name: 'ddlLeadStage',
                                                        id: 'ddlLeadStage',
                                                        // pnkvalidate: 'forceselect',

                                                    }}
                                                    className="filter-form-cntrl "
                                                    multiple={true}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    placeholder="Stage"
                                                    style={{ textAlign: 'left', background: 'white', border: 'solid 0px #979797 !important' }}
                                                >
                                                    {(this.state.profileStageData && this.state.profileStageData.length > 0) ? this.state.profileStageData.map(item =>
                                                        <MenuItem key={item} value={item}>
                                                            <ListItemText primary={item} />

                                                        </MenuItem>
                                                    ) : null}


                                                </Select>

                                            </FormControl>


                                            {/* <Multiselect
                                     selectedValues={this.state.ddlLeadStage}
                                     className="filter-form-cntrl"
                                    options={this.state.profileStageData}
                                    name="ddlLeadStage"
                                    id="ddlLeadStage"
                                    isObject={false}
                                    onSelect={this.SelectMultipleStages}
                                /> */}

                                            {/* <Form.Control as="select" className="filter-form-cntrl"
                                            name="ddlLeadStage" id="ddlLeadStage"
                                            value={this.state.ddlLeadStage}
                                            onChange={this.HandleUserInput}
                                            
                                            style={{ padding: '4px', width: '100%' }}>
                                            <option value="" disabled selected >Stage</option>
                                            {(this.state.profileStageData && this.state.profileStageData.length > 0) ? this.state.profileStageData.map(item =>
                                                <option value={item}>{item}</option>
                                            ) : ""}
                                        </Form.Control> */}

                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>








                            <Row className="filter-pad-bottom justify-content-center">
                                {this.state.showExportToCSV === true ?
                                    <Col lg={3} xs={3}>
                                        <CSVLink className="btn btn-primary" style={{ width: '150px', height: '40px' }} filename={filename + '.csv'} data={this.state.csvData}>Export to CSV</CSVLink>
                                        {/* <CSVDownload data={this.state.csvData} target="_blank" /> */}
                                        {/* <Button variant="primary" className="filter-btn-export" style={{width:'150px'}}>Export to CSV</Button> */}
                                    </Col> : null}
                                <Col lg={3} xs={3}>
                                    <Button variant="light" className="filter-btn-close" onClick={this.ClearData}>Clear</Button>
                                </Col>
                                {this.state.showGenerateBtn === true ?
                                    <Col lg={3} xs={3}>
                                        <Button variant="light" className="filter-btn-generate" onClick={() => this.GenerateAdvanceSearch()}>Generate</Button>
                                    </Col> : null}
                            </Row>
                        </div>

                    </div>
                </Col>
                {((JSON.stringify(arealist) === {} || this.state.getArea === true) ? GetAreaListByCity(this.props.PnkStore, this.state.ddlPreferedCity, '', this.HandleGetAreaSuccessResponse) : "")}
                {((this.state.getLeadData === true) ? GetLeadData(this.props.PnkStore, this.HandleGetLeadDataResponse) : "")}
                {((this.state.getUsersListByProfile === true) ? GetUserByProfile(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.ddlLeadProfileId, this.state.ddlPreferredCity, this.HandleGetUserByProfileSuccessResponse) : "")}
                {((this.state.sendAdvanceSearchData === true) ? AdvancedSearch(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.rdSearchType, this.data, 1, '', this.HandleSuccessAdvanceSearch) : "")}
            </Row>
        )
    }
}
export const SearchFormPage = PnkConnect(SearchForm, "");