import { PnkAjax } from "../pnk-react/ajax/pnk-ajax";
export function AdminSalesReport(store, user_profile, user_name, start_date, end_date, successFunc) {
    let current_profile='';
    if(user_profile==='Telecalling')
    current_profile='Telecalling'
    else if(user_profile==='Sales')
    current_profile='Sales'
   
    // else if(user_profile==='Sales TL')
    // current_profile='Sales TL'
    let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
    if(current_profile!==''){
    return PnkAjax(store).Get("crm/admindashboard/salesreport/json/" + user_profile + '/' + user_name + '/' + start_date + '/' + end_date+'/'+current_profile).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    }
    else{
    return PnkAjax(store).Get("crm/admindashboard/salesreport/json/" + 'Administrator' + '/' + user_name + '/' + start_date + '/' + end_date).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();

    }

}
export function CallBackList(store, user_profile, user_name,start_date,end_date, successFunc){
    let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
    if(start_date!=='' && end_date!==''){
        return PnkAjax(store).Get("crm/callback/json/" + user_profile + '/' + user_name + '/'+'dashboard/'+ start_date + '/' + end_date).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();

    }
    else
    return PnkAjax(store).Get("crm/callback/json/" + user_profile + '/' + user_name + '/'+'dashboard').AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    
}