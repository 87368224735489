import React, { Component } from 'react'
import AppUtils from '../../apputils';
import MultiEditComponent from './multieditleads';
import { PnkConnect } from '../../pnk-react/pnk-miniredux';
import { GetUserByProfile, MultiEdit } from "../../models/users";
import { GetUserProfileList } from '../../models/users';
import $ from 'jquery';
import { Container } from 'react-bootstrap'

class multiEditpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getProfileList: false,
            ddlLeadHandledByProfile: '',
            ddlLeadStage: '',
            getUsersListByProfile: false,
            ddlLeadOwner: '',
            editLead: false,
            ddlProfileName:'',
            ddlStageData:[],
            allowedLeadStages:[]

        }
        this.profileList = [];
        this.userlistbyprofile = [];
        this.telecallingProfileId = '';
        this.salesProfileId = '';
        this.lead_data = {};

        // alert(this.props.componentprops.currentAction.lead_ids)
    }

    HideModal = () => {
        this.props.componentprops.currentAction.close();
    }

    componentDidMount = () => {
        
    }

    componentWillMount = () => {
        this.setState({
            getProfileList: true
        })
    }

    HandleSuccessProfile = (res) => {
        this.profileList = res.data.profilelist.rows;
        this.SetTelecallingId();
        this.setState({
            getProfileList: false
        })
    }
  
HandleUserSelectInput=(id, name, evt, key, payload)=>{
    const nameOrg = id.target.name;
    const val = id.target.value;
    this.setState({ [nameOrg]: val })
    if (nameOrg === 'ddlLeadHandledByProfile' && val != '-1') {
       
        this.setState({ddlProfileName: name.props.children.toString(),getUsersListByProfile: true })
    }
    
    console.log(name.props.children.toString()); //name of name
   
}
    HandleUserInputs = (e) => {
        const name = e.target.name;
        const val = e.target.value;
    
        this.setState({ [name]: val })

    }

    HandleEditLeadDataSuccessResponse = (res) => {
        if (res !== false) {
            document.getElementById("frmLeadEntry").reset();
            document.getElementById('spnSuccessMessageTitle').innerText = "Lead Response - ";
            document.getElementById('spnSuccessMessage').innerText = res.data.multileadupdated.message.value;
            let that = this;
            $('#crmSuccessAlert').slideDown().slideUp(3000, function () {
                AppUtils.HandlePageClick(that.props.componentprops, 'leads');
                that.props.componentprops.currentAction.close();
            })
        }
        this.setState({ editLead: false });
    }

    HandleGetUserByProfileSuccessResponse = (res) => {
        this.userlistbyprofile = res.data.userlistbyprofile.rows;
        this.setState({ getUsersListByProfile: false })
    }

    UpdateLeadData = () => {
        // alert(JSON.stringify(this.props.componentprops.currentAction.lead_ids))
        // alert(this.telecallingProfileId+"-----"+this.state.ddlLeadStage+"---"+this.state.ddlLeadHandledByProfile+"---"+this.state.ddlLeadOwner);
        if (this.state.ddlLeadOwner !== '' ) {
            let profile = 'others';
            if (this.state.ddlLeadOwner === '') {

            }
            else {
                if (this.telecallingProfileId == this.state.ddlLeadHandledByProfile) {
                    profile = 'Telecalling';
                }
                else if (this.salesProfileId == this.state.ddlLeadHandledByProfile) {
                    profile = 'Sales';
                }
            }
            // alert(profile + "---" + this.state.ddlLeadStage)
            if ((profile == 'Telecalling' && this.state.ddlLeadStage == 'In Progress') || (profile.includes('Sales') && this.state.ddlLeadStage == 'Pending')) {
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Update Message - ";
                document.getElementById('spnWarningMessage').innerText = "Sorry Wrong Combination";
                //window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
                $('#crmWarningAlert').slideDown().slideUp(5000);
                return false
                // document.getElementById('spnSuccessMessageTitle').innerText = "Lead Status Response - ";
                // document.getElementById('spnSuccessMessage').innerText = 'Sorry';
                // this.setState({ ddlAction: 'fetched', refreshList: true })
                // $('#crmSuccessAlert').slideDown().slideUp(3000)
            }
            else {
                if (window.confirm("Are you sure?")) {
                    this.lead_data = {
                        lead_owner: this.state.ddlLeadOwner,
                        profile_name: profile,
                        profile_id: this.state.ddlLeadHandledByProfile,
                        lead_ids: this.props.componentprops.currentAction.lead_ids,
                        stage: this.state.ddlLeadStage
                    }
                    this.setState({
                        editLead: true
                    })
                }
            }
        }
        else {
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Update Message - ";
            document.getElementById('spnWarningMessage').innerText = "All Fields are Mandatory";
            //window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
            $('#crmWarningAlert').slideDown().slideUp(5000);
            return false
        }

    }

    SetTelecallingId = () => {
        let profileslist = [];
        profileslist = this.profileList;
        for (let i = 0; i < profileslist.length; i++) {
            //alert(profileslist[i].profile_name)
            // if (profileslist[i].profile_name == 'Sales') {
            //     this.salesProfileId = profileslist[i]._id
            // }
            // else if (profileslist[i].profile_name == 'Sales TL') {
            //     this.salesTLProfileId = profileslist[i]._id
            // }
            // else if (profileslist[i].profile_name == 'Sales Head') {
            //     this.salesHeadProfileId = profileslist[i]._id
            // }
            // else 
            if (profileslist[i].profile_name == 'Telecalling') {
                this.telecallingProfileId = profileslist[i]._id
            }
            else if (profileslist[i].profile_name == 'Sales') {
                this.salesProfileId = profileslist[i]._id
            }
        }
    }

    render() {
        return (
            <Container>
                <MultiEditComponent profileList={this.profileList} HandleUserInputs={this.HandleUserInputs}
                    userlistbyprofile={this.userlistbyprofile} UpdateLeadData={this.UpdateLeadData}
                    HandleUserSelectInput={this.HandleUserSelectInput}
                    mystate={this.state} {...this.props} HideModal={this.HideModal} />

                {((this.state.getProfileList === true) ? GetUserProfileList(this.props.PnkStore, 0, 1, this.HandleSuccessProfile) : "")}
                {((this.state.getUsersListByProfile === true) ? GetUserByProfile(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.ddlLeadHandledByProfile, '', this.HandleGetUserByProfileSuccessResponse) : "")}
                {((this.state.editLead === true) ? MultiEdit(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.lead_data, this.HandleEditLeadDataSuccessResponse) : "")}

                {/* {((this.state.saveLead === true) ? AddLead(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.lead_data, this.HandleSaveLeadDataSuccessResponse) : "")}
                {((this.state.editLead === true) ? EditLead(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.lead_data, this.props.componentprops.match.params.id, this.HandleEditLeadDataSuccessResponse) : "")}
                {((this.state.getLeadEntryData === true) ? GetLeadData(this.props.PnkStore, this.HandleSuccessGetLeadDataResp) : "")}
                {((this.state.getArea === true) ? GetAreaListByCity(this.props.PnkStore, this.state.ddlPreferredCity, this.HandleGetAreaSuccessResponse) : "")}
                {((this.state.getEditData === true) ? GetLeadById(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.props.componentprops.match.params.id, this.HandleGetLeadByIdSuccessResponse) : "")}*/}
            </Container>
        );
    }
}
export const MultiEditpage = PnkConnect(multiEditpage, '');