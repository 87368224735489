import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
export default class EntryForm extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount = () => {

    }
    render() {

        return (
            <div className="entry-foom-main-div">
                <div className="entry-container">
                    <Row>
                        <Col lg={4}>
                            <div className="entry-btn-pills">
                                {this.props.mystate.hideCity === false ?
                                    this.props.mystate.currentChoice === "city" ?
                                        <Button variant="light" active={true} className="entry-btn" onClick={() => this.props.ChangeOption('city')}>
                                            City Entry
                                    </Button> :
                                        <Button variant="light" active={false} className="entry-btn" onClick={() => this.props.ChangeOption('city')}>
                                            City Entry
                                    </Button> :
                                    null}
                                {this.props.mystate.hideArea === false ?
                                    this.props.mystate.currentChoice === "area" ?
                                        <Button variant="light" active={true} className="entry-btn" onClick={() => this.props.ChangeOption('area')}>
                                            Area Entry
                                    </Button> :
                                        <Button variant="light" active={false} className="entry-btn" onClick={() => this.props.ChangeOption('area')}>
                                            Area Entry
                                    </Button> : null}
                                {this.props.mystate.hideState === false ?
                                    this.props.mystate.currentChoice === "state" ?
                                        <Button variant="light" active={true} className="entry-btn" onClick={() => this.props.ChangeOption('state')}>
                                            State Entry
                                    </Button> :
                                        <Button variant="light" active={false} className="entry-btn" onClick={() => this.props.ChangeOption('state')}>
                                            State Entry
                                     </Button> : null
                                }


                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="entry-form-div">
                                {this.props.mystate.currentChoice === "city" ?
                                    <React.Fragment>
                                        <div className="entry-form-title">
                                            City {this.props.mystate.actionHeading}
                                        </div>
                                        <div className="entry-form">
                                            <PnkHtmlForm id="frmEntry" onsubmit={this.props.SaveEntryData} submitbtn="btnCityEntry">
                                                <fieldset>
                                                    <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                                        <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                                                    </div>
                                                    <Form.Group >
                                                        <Form.Label className="entry-form-label">City Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter City" className="entry-form-cntrl"
                                                            name="txtCityName" id="txtCityName"
                                                            value={this.props.mystate.txtChoice}
                                                            pnkvalue={this.props.mystate.txtChoice}
                                                            onChange={this.props.HandleUserInput}
                                                            disabled={this.props.mystate.formModeActivity}
                                                        />
                                                        {this.props.mystate.invalidTxtChoice === true ?
                                                            <div className="errorMessage">Invalid Name</div> :
                                                            ""}
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Form.Label className="entry-form-label">State Name </Form.Label>
                                                        <Form.Control as="select" className="entry-form-cntrl" id="ddlState" name="ddlState"
                                                            value={this.props.mystate.ddlChoice}
                                                            pnkvalue={this.props.mystate.ddlChoice}
                                                            onChange={this.props.HandleUserInput}
                                                            disabled={this.props.mystate.formModeActivity}>
                                                            <option value="" selected disabled>Select State</option>
                                                            {JSON.stringify(this.props.mystate.stateList) !== '{}' && this.props.mystate.stateList.rows.length > 0 ? this.props.mystate.stateList.rows.map(state =>
                                                                state.state_status == 1 ?
                                                                    <option value={state.state_name}>{state.state_name}</option> :
                                                                    ""
                                                            ) : ''}

                                                        </Form.Control>
                                                        {this.props.mystate.invalidDDlChoice === true ?
                                                            <div className="errorMessage">Select One</div> :
                                                            ""}
                                                    </Form.Group>
                                                    <Form.Group >
                                                        {this.props.mystate.chkEntryStatus === 'on' ?
                                                            <Form.Check disabled={this.props.mystate.formModeActivity} type="checkbox" checked={true} label="Status" id="chkCityEntryStatus" name="chkCityEntryStatus" className="entry-chk" onChange={this.props.HandleUserInput} /> :
                                                            <Form.Check disabled={this.props.mystate.formModeActivity} type="checkbox" checked={false} label="Status" id="chkCityEntryStatus" name="chkCityEntryStatus" className="entry-chk" onChange={this.props.HandleUserInput} />
                                                        }

                                                    </Form.Group>
                                                    <Form.Group >
                                                        <button variant="light" className="entry-add-btn" id="btnCityEntry" onClick={() => this.props.SaveEntryData()}>{this.props.mystate.showButtonType} City</button>
                                                    </Form.Group>
                                                </fieldset>
                                            </PnkHtmlForm>
                                        </div>
                                    </React.Fragment> : ""}
                                {this.props.mystate.currentChoice === "area" ?
                                    <React.Fragment>
                                        <div className="entry-form-title">
                                            Area {this.props.mystate.actionHeading}
                                        </div>
                                        <div className="entry-form">
                                            <PnkHtmlForm id="frmEntry" onsubmit={this.props.SaveEntryData} submitbtn="btnCityEntry">
                                                <fieldset>
                                                    <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                                        <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                                                    </div>
                                                    <Form.Group >
                                                        <Form.Label className="entry-form-label">Area Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Area" className="entry-form-cntrl"
                                                            name="txtAreaName" id="txtAreaName"
                                                            value={this.props.mystate.txtChoice}
                                                            onChange={this.props.HandleUserInput}
                                                            disabled={this.props.mystate.formModeActivity} />
                                                        {this.props.mystate.invalidTxtChoice === true ?
                                                            <div className="errorMessage">Invalid Name</div> :
                                                            ""}
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Form.Label className="entry-form-label">City Name </Form.Label>
                                                        <Form.Control as="select" className="entry-form-cntrl" id="ddlCity" name="ddlCity"
                                                            value={this.props.mystate.ddlChoice}
                                                            onChange={this.props.HandleUserInput}
                                                            disabled={this.props.mystate.formModeActivity}>
                                                            <option value="" selected disabled>Select City</option>
                                                            {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                                                this.props.PnkStore.GetData("citylist").rows.map(city =>
                                                                    city.city_status === 1 ?
                                                                        <option value={city.city_name}>{city.city_name}</option> : ""
                                                                ) : ""}

                                                        </Form.Control>
                                                        {this.props.mystate.invalidDDlChoice === true ?
                                                            <div className="errorMessage">Select One</div> :
                                                            ""}
                                                    </Form.Group>
                                                    <Form.Group >
                                                        {this.props.mystate.chkEntryStatus === 'on' ?
                                                            <Form.Check type="checkbox" checked={true} label="Status" className="entry-chk" id="chkAreaEntryStatus" name="chkAreaEntryStatus" onChange={this.props.HandleUserInput} /> :
                                                            <Form.Check type="checkbox" checked={false} label="Status" className="entry-chk" id="chkAreaEntryStatus" name="chkAreaEntryStatus" onChange={this.props.HandleUserInput} />
                                                        }
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Button variant="light" className="entry-add-btn" onClick={() => this.props.SaveEntryData()}>{this.props.mystate.showButtonType} Area</Button>
                                                    </Form.Group>
                                                </fieldset>
                                            </PnkHtmlForm>
                                        </div>
                                    </React.Fragment> : ""}
                                {this.props.mystate.currentChoice === "state" ?
                                    <React.Fragment>
                                        <div className="entry-form-title">
                                            State {this.props.mystate.actionHeading}
                                        </div>
                                        <div className="entry-form">
                                            <PnkHtmlForm id="frmEntry" onsubmit={this.props.SaveEntryData} submitbtn="btnCityEntry">
                                                <fieldset>
                                                    <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                                        <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                                                    </div>
                                                    <Form.Group >
                                                        <Form.Label className="entry-form-label">State Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter State" className="entry-form-cntrl"
                                                            name="txtStateName" id="txtStateName" value={this.props.mystate.txtStateName}
                                                            onChange={this.props.HandleUserInput}
                                                            value={this.props.mystate.txtChoice}
                                                            disabled={this.props.mystate.formModeActivity}
                                                        />
                                                        {this.props.mystate.invalidTxtChoice === true ?
                                                            <div className="errorMessage">Invalid Name</div> :
                                                            ""}
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Form.Label className="entry-form-label">Country Name </Form.Label>
                                                        <Form.Control as="select" className="entry-form-cntrl" id="ddlCountry" name="ddlCountry"
                                                            onChange={this.props.HandleUserInput}
                                                            value={this.props.mystate.ddlChoice}
                                                            disabled={this.props.mystate.formModeActivity}
                                                        >
                                                            <option value="" selected disabled>Select Country</option>
                                                            {JSON.stringify(this.props.mystate.countryList) !== '{}' && this.props.mystate.countryList.rows.length > 0 ? this.props.mystate.countryList.rows.map(country =>
                                                                <option value={country.country_name}>{country.country_name}</option>
                                                            ) : ''}
                                                        </Form.Control>
                                                        {this.props.mystate.invalidDDlChoice === true ?
                                                            <div className="errorMessage">Select One</div> :
                                                            ""}
                                                    </Form.Group>
                                                    <Form.Group >
                                                        {this.props.mystate.chkEntryStatus === 'on' ?
                                                            <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} checked={true} label="Status" className="entry-chk" id="chkStateEntryStatus" name="chkStateEntryStatus" onChange={this.props.HandleUserInput} /> :
                                                            <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} label="Status" className="entry-chk" id="chkStateEntryStatus" name="chkStateEntryStatus" onChange={this.props.HandleUserInput} />
                                                        }
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Button variant="light" className="entry-add-btn" onClick={() => this.props.SaveEntryData()}>{this.props.mystate.showButtonType} State</Button>
                                                    </Form.Group>
                                                </fieldset>
                                            </PnkHtmlForm>
                                        </div>
                                    </React.Fragment> : ""}

                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}