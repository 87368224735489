import React from 'react'
import { Row, Col, Button, Form, Tooltip, Image, Overlay } from 'react-bootstrap'
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '../Pagination'
import Album from '../../assets/images/album.png'


export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            selectAll: false,

            checkedC: true,
            showProfileEntry: false,
            numItemsPerPage: 10,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 0


        }
        this.target = React.createRef()
    }
    componentDidMount = () => {
        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })
        // this.props.PnkStore.GetData("profileList")[0].profilelist.rows.length > 0


    }
    SelectAll = () => {
        this.setState({
            selectAll: true
        })
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };
    ChangePage = (number) => {
        if (number - 1 === 0) {
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage) });
        }
        else {
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage) });
        }
    }

    render() {
        let totalPages = 0; let length = 0;
        if (this.props.PnkStore.GetData("profilelist") && this.props.PnkStore.GetData("profilelist") && this.props.PnkStore.GetData("profilelist").rows &&
            this.props.PnkStore.GetData("profilelist").rows.length > 0) {
            length = this.props.PnkStore.GetData("profilelist").rows.length
            totalPages = Math.ceil(length / this.state.numItemsPerPage);
        }


        const AntSwitch = withStyles((theme) => ({
            root: {
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.grey[500],
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: ' #5d8614',
                        borderColor: '#5d8614',
                    },
                },
            },
            thumb: {
                width: 12,
                height: 12,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
        }))(Switch);
        let background = '';
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">

                                    <Row className="mob-row-align">
                                        {/* <Col lg={1}>

                                </Col> */}
                                        {/* <Col lg={1} className="order-col hide-mob">
                                            <label class="chk-container">
                                                <input type="checkbox" onClick={() => this.SelectAll()} />
                                                <span class="checkmark-form "></span>
                                            </label>


                                        </Col> */}
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                        </Col>

                                        <Col lg={2} xs={3}>
                                            <h3 className="table-head-lead ">Name of Client</h3>
                                        </Col>

                                        <Col lg={1} xs={3}>
                                            <h3 className="table-head-lead">Booked</h3>
                                        </Col>
                                        <Col lg={2} className="hide-mob">
                                            <h3 className="table-head-lead ">Sales Executive Name</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead">Costings</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead">Home Consultant Fee</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead">Photos</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead">Status</h3>
                                        </Col>

                                        <Col lg={2} className="" xs={3}>
                                            <h3 className="table-head-lead">Action</h3>
                                        </Col>

                                    </Row>
                                    <div>
                                        {this.props.PnkStore.GetData("profilelist") && this.props.PnkStore.GetData("profilelist") && this.props.PnkStore.GetData("profilelist").rows &&
                                            this.props.PnkStore.GetData("profilelist").rows.length > 0
                                            ? this.props.PnkStore.GetData("profilelist").rows.map(
                                                (item, i) => (


                                                    <div className="lead-table-data-user">
                                                        {i >= this.state.startValue && i < this.state.endValue ?
                                                            ((i + 1) % 2 === 0) ?
                                                                (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div> {i + 1}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="" xs={3}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.profile_name}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="" xs={3}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.profile_desc}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={2} className="" xs={3}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.profile_desc}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.created_on}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.created_on}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div><a onClick={() => this.props.ShowAlbum()}><Image src={Album} className="booking-album-icon" /></a></div>

                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={1} xs={3} className="">
                                                                        <div className="table-data-user">

                                                                            <div className="lead-data">
                                                                                {item.profile_status === 1 ?
                                                                                    <AntSwitch checked={true} onChange={this.handleChange} name="checkedC" /> :
                                                                                    <AntSwitch checked={false} onChange={this.handleChange} name="checkedC" />
                                                                                }
                                                                            </div>


                                                                        </div>
                                                                    </Col>


                                                                    <Col lg={2} xs={3} className="">
                                                                        <div className="table-data-user">


                                                                            <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.profile_status)}>
                                                                                <option value="" selected>Take Action</option>
                                                                                <option value="Detail">Detail</option>
                                                                                <option value="Delete">Delete</option>

                                                                            </Form.Control>

                                                                        </div>



                                                                    </Col>

                                                                </Row>) : (
                                                                    <Row className="table-items-user" style={{ background: '#ffffff' }}>
                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div> {i + 1}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>

                                                                        <Col lg={2} className="" xs={3}>
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.profile_name}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="" xs={3}>

                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{item.profile_desc}</div>
                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={2} className="" xs={3}>

                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{item.profile_desc}</div>
                                                                                </div>
                                                                            </div>

                                                                        </Col>

                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{item.created_on}</div>
                                                                                </div>

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{item.created_on}</div>
                                                                                </div>

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div><a onClick={() => this.props.ShowAlbum()}><Image src={Album} className="booking-album-icon" /></a></div>

                                                                                </div>

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={1} xs={3} className="">
                                                                            <div className="table-data-user">

                                                                                <div className="lead-data">
                                                                                    {item.profile_status === 1 ?
                                                                                        <AntSwitch checked={true} onChange={this.handleChange} name="checkedC" /> :
                                                                                        <AntSwitch checked={false} onChange={this.handleChange} name="checkedC" />
                                                                                    }
                                                                                </div>


                                                                            </div>
                                                                        </Col>


                                                                        <Col lg={2} xs={3} className="">
                                                                            <div className="table-data-user">


                                                                                <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.profile_status)}>
                                                                                    <option value="" selected>Take Action</option>
                                                                                    <option value="Detail">Detail</option>
                                                                                    <option value="Delete">Delete</option>

                                                                                </Form.Control>

                                                                            </div>



                                                                        </Col>

                                                                    </Row>) : null}

                                                    </div>



                                                )) : null}
                                        {this.state.numItemsPerPage < length ?
                                            <div className="pagination-div-profile">
                                                <Pagination totalNumber={totalPages} currentPageNumber={this.state.currentPageNumber} ChangePage={this.ChangePage} />
                                            </div> : null}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        )
    }
}