import { PnkAjax } from "../pnk-react/ajax/pnk-ajax";

// export function SubscribeNewsletter(store, email, successFunc) {
//   let loader = { isLoader: true, containerId: "testpnk", size: "small" };
//   return PnkAjax(store).Get("subscribe/" + email).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function SendContactMail(store, data, successFunc) {
//   console.log("data store", data)
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("contact").ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function SendGetQuoteForm(store, data, successFunc) {
//   console.log("data store", data)
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("usersentryforquote/json").ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function GetQuoteVerifyMobileNumber(store, mobile, successFunc) {
//   console.log("object", mobile)
//   let loader = { isLoader: false, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Get("mobileverify/" + mobile).AttachLoader(loader).OnSuccess(successFunc).Do();

// }
// export function VerifyOTPNumber(store, mobile, otp, successFunc) {
//   console.log("object", mobile)
//   let loader = { isLoader: false, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Get("verifymobileotp/" + mobile + "/" + otp).AttachLoader(loader).OnSuccess(successFunc).Do();

// }
// export function ResendOTPNumber(store, mobile, successFunc) {
//   console.log("object", mobile)
//   let loader = { isLoader: false, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Get("resendotp/" + mobile).AttachLoader(loader).OnSuccess(successFunc).Do();

// }
// export function SendGetQuoteQuestionnaire(store, data, type, successFunc) {
//   console.log("data store", data)
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("quoteentry/json/" + type).ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function SendNewLeadEntry(store, data, successFunc) {
//   console.log("data store", data)
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("newleadentry/json").ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function SendReferFriendData(store, data, successFunc) {
//   console.log("data store", data)
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("referer/json").ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function SendGetQuoteForm(store, data, successFunc) {
//   console.log("data store", data)
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("contact").ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
// export function GetCityList(store, successFunc) {
//   let loader = { isLoader: true, containerId: "testpnk", size: "small" };
//   if (!(store.GetData("citylist").length > 0)) {
//     return PnkAjax(store)
//       .Get("admin/property/cities/json")
//       .AttachLoader(loader)
//       .OnSuccess(successFunc)
//       .Do();
//   } else return successFunc(store.GetData("citylist"), store);
// }

// export function GetTopDevelopersList(store, successFunc) {
//   let loader = { isLoader: false, containerId: "testpnk", size: "small" };
//   if (!(store.GetData("topdevelopers").length > 0)) {
//     return PnkAjax(store).Get("admin/builders/topdevelopers/json").AttachLoader(loader).OnSuccess(successFunc).Do();
//   } else return successFunc(store.GetData("topdevelopers"), store);
// }

// export function GetLocalitiesToInvestList(store, successFunc) {
//   let loader = { isLoader: false, containerId: "testpnk", size: "small" };
//   if (!(store.GetData("localities").length > 0)) {
//     return PnkAjax(store).Get("admin/property/localities/json").AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
//   }
//   else return successFunc(store.GetData("localities"), store);
// }

// export function GetSearchKeys(store, city, key, successFunc) {
//   if (city == "") city = "none";
//   key = "";
//   let loader = { isLoader: true, containerId: "testpnk", size: "small" };
//   if (!(store.GetData("searchFullData").length > 0))
//     return PnkAjax(store)
//       .Get("admin/property/keysearch/" + city + "/" + key)
//       .AttachLoader(loader)
//       .WetherPinakaResponse(false)
//       .OnSuccess(successFunc)
//       .Do();
//   else return successFunc(store.GetData("searchFullData"), store);
// }

// export function GetPropDetails(store, city, area, seo_url, successFunc) {
//   let loader = { isLoader: false, containerId: "testpnk", size: "small" };
//   return PnkAjax(store)
//     .Get("admin/property/row/json/seourl/" + seo_url)
//     .AttachLoader(loader)
//     .WetherPinakaResponse(false)
//     .OnSuccess(successFunc)
//     .Do();
// }

// export function GetAreaListByCity(store, cityname, successFunc) {
//   let loader = { isLoader: false, containerId: "testpnk", size: "small" };
//   // if (!(store.GetData('arealist').length > 0)) {
//   return PnkAjax(store)
//     .Get("admin/property/areasbycity/json/" + cityname)
//     .AttachLoader(loader)
//     .WetherPinakaResponse(false)
//     .AddHeader({
//       "x-access-token": sessionStorage.getItem("current_user_login_token"),
//     })
//     .OnSuccess(successFunc)
//     .Do();
//   // }
//   // else return HandleSuccessGetAreaListByCity(store.GetData('arealist'), store);
// }

// export function GetPropertyList(
//   store,
//   search_type,
//   search_key,
//   setNo,
//   SuccessCallBackFunc
// ) {
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };

//   //alert(JSON.stringify(store.GetData('categories')));

//   if (!(store.GetData("propertyListBySearch").length > 0)) {
//     //alert("fetching starting");
//     return PnkAjax(store)
//       .Get(
//         "admin/property/search/" +
//         search_type +
//         "/" +
//         search_key +
//         "/web/" +
//         setNo
//       )
//       .AttachLoader(loader)
//       .WetherPinakaResponse(false)
//       .OnSuccess(SuccessCallBackFunc)
//       .Do();
//   } else
//     return SuccessCallBackFunc(store.GetData("propertyListBySearch"), store);
// }

// export function SendMailForLead(store, data, successFunc) {
//   let loader = { isLoader: true, containecccrId: "testpnk", size: "small" };
//   return PnkAjax(store).Post("admin/property/leadrequest/json").ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
// }

// export function GetBuildersList(store, successFunc) {
//   let loader = { isLoader: true, containerId: "testpnk", size: "small" };
//   if (!(store.GetData("buildersList").length > 0))
//     return PnkAjax(store).Get("admin/builders/json/0").AttachLoader(loader).OnSuccess(successFunc).Do();
//   else return successFunc(store.GetData("buildersList"), store);
// }
export function AddUserProfile(store, userObj, successFunc) {

  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('crm/profiles/json').ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function IncomingCallList(store, setNo = 0, set_length = 0, sort_by = '', sort_order = '', successFunc) {
  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('exotel/incomingcalls/json/' + setNo + '/' + set_length + '/' + sort_by + '/' + sort_order).AttachLoader(loader).OnSuccess(successFunc).Do();
}
// export function AllCallList(store, setNo = 0, set_length = 0, successFunc) {
//   let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
//   return PnkAjax(store).Get('exotel/calls/json/' + setNo + '/' + set_length + '/' + 'All Call').AttachLoader(loader).OnSuccess(successFunc).Do();
// }
export function AllCallList(store, user_profile, user_name, start_date, end_date, successFunc) {
  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('exotel/calls/json/' + user_profile + '/' + user_name + '/' + start_date + '/' + end_date).AttachLoader(loader).OnSuccess(successFunc).Do();
}
// exotel/calls/json/:user_profile/:user_name/:start_date?/:end_date?/:set_no?/:per_set?/:sort_by?/:sort_order?

export function SaveHCForm(store, user_profile, user_name, lead_id, userObj, successFunc) {
  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('crm/hcform/json/' + user_profile + '/' + user_name + '/' + lead_id).ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

export function GetHcList(store, user_profile, user_name, setNo = 0, set_length = 0, successFunc) {
  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Get('crm/hclist/json/' + user_profile + '/' + user_name + '/' + setNo + '/' + set_length).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

export function SendEmail(store, user_profile, user_name, userObj, successFunc) {
  let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
  return PnkAjax(store).Post('crm/sendhcdetails/json/' + user_profile + '/' + user_name).ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}