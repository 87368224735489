import React from 'react'
import { Row, Col, Button, Form, Image, OverlayTrigger, Overlay } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';

export default class Table extends React.Component {
    constructor(props) {
        super(props)
    }
    ShowCancelModal = (customer_name, customer_no) => {

        this.props.componentprops.OpenModal(Table, 'sm', { customer_no: customer_no.replace(/[^0-9]/g, ""), customer_name: 'customer_name' })

    }
    ToggleView = (divId) => {
        if (this.props.mystate.isMobile === true) {
            var x = document.getElementById(divId);
            if (x.style.display === 'none') {
                x.style.display = 'block';
            } else {
                x.style.display = 'none';
            }
        }

    }
    OnFileChange = (event) => {
        const fileData = event.target.files;
        const blob = new Blob([fileData]);
        const reader = new FileReader();
        var fileArray = []

        reader.onloadend = () => {
            for (let i = 0; i < fileData.length; i++) {

                fileArray.push({
                    imagePreview: URL.createObjectURL(fileData[i]),
                    fileName: fileData[i].name,
                    fileSize: fileData[i].size,
                    fileContentType: fileData[i].type,
                    file: reader.result,
                    id: i + 1
                })


            }
            this.props.GetMultipleFiles(fileArray)
        }
        reader.readAsDataURL(blob);
    }
    render() {
        return (
            <div className="lead-entry">
                <div className="lead-entry-main">
                    <div className="crm-container">
                        {/* <div className="personal-div-main"></div> */}

                        <PnkHtmlForm id="frmLeadEntry" onsubmit={this.props.SaveLeadData} submitbtn="btnLeadEntry">
                            <fieldset>
                                <div id="frmLeadEntryActivity">
                                    <div className="le-section" >
                                        <div className="mob-container">
                                            <Row>
                                                <Col lg={7}>
                                                    <div className="personal-div">
                                                        <h3 className="form-heading"> Customer Details</h3>
                                                        <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("personalDetails")} />

                                                    </div>
                                                </Col>
                                                <Col lg={5} className="flex-end hide-mob">
                                                    <div className="lead-entry-btn-group">
                                                        <Button variant="light" className="upload-builk-lead mr-3">+ Upload Bulk User</Button>
                                                        <Button variant="light" className="le-cancel mr-3" >CANCEL</Button>
                                                        <Button variant="light" className="le-save">SAVE</Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row id="personalDetails">
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >

                                                        <Form.Control type="text" className="le-form-controls"
                                                            name="txtName" id="txtName" pnkvalidate="forceentry"
                                                            pnkvalue={this.props.editDataObj.txtName}
                                                            onBlur={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Name" />
                                                        <label class="floating-label" >Name</label>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control type="text" className="le-form-controls" id="txtEmail" name="txtEmail" pnkvalidate="forceentry"
                                                            pnkvalue={this.props.editDataObj.txtEmail}
                                                            onBlur={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Email" />
                                                        <span class="floating-label" for="txtEmail">Email</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control type="text" className="le-form-controls"
                                                            id="txtPhone"
                                                            name="txtPhone"
                                                            pnkvalidate="forceentry"
                                                            pnkvalue={this.props.editDataObj.txtPhone}
                                                            onBlur={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Phone" />
                                                        <span className="floating-label" for="txtPhone">Phone No</span>
                                                    </Form.Group>
                                                </Col>


                                            </Row>
                                        </div>
                                    </div>

                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div ">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Attended By</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("landscapeDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="landscapeDiv">
                                                    <Row>


                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlSalesPerson'
                                                                    id='ddlSalesPerson'


                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Sales Person'
                                                                    pnkvalue={this.props.editDataObj.ddlSalesPerson}


                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" >Sales Person</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                                {/* <span className="floating-label">City</span> */}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlProjectManager"
                                                                    id="ddlProjectManager"

                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Project Manager'
                                                                    pnkvalue={this.props.editDataObj.ddlProjectManager}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Project Manager </option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                                {/* <span className="floating-label">Locality</span> */}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select" name="ddlOperationsPerson" id="ddlOperationsPerson"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Operations Person'
                                                                    pnkvalue={this.props.editDataObj.ddlOperationsPerson}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="">Operations Person</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>



                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Photos</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("reqDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="reqDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            {/* <div className="le-up-pics">
                                                                <div>
                                                                    <div className="le-up-pics-icon">
                                                                        <FontAwesomeIcon icon={faPlus} className="le-up-icon" />
                                                                    </div>
                                                                    <div className="le-up-pic-title">
                                                                        <h3 className="le-up-pic-tit-text">Upload Site Pictures</h3>
                                                                    </div>
                                                                    <div className="le-up-pic-title">
                                                                        <h4 className="le-up-pic-sub-text">(pdf and jpeg)</h4>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="file-up-div le-up-pics">
                                                                <label for="file-upload" class="custom-file-upload">
                                                                    <div className="le-up-pics-icon">
                                                                        <FontAwesomeIcon icon={faPlus} className="le-up-icon" />
                                                                    </div>
                                                                    <div className="le-up-pic-title">
                                                                        <h3 className="le-up-pic-tit-text">Upload Site Pictures</h3>
                                                                    </div>
                                                                    <div className="le-up-pic-title">
                                                                        <h4 className="le-up-pic-sub-text">(pdf and jpeg)</h4>
                                                                    </div>
                                                                </label>
                                                                <input id="file-upload" type="file" multiple onChange={this.OnFileChange} />
                                                            </div>
                                                            {/* {this.props.mystate.fileDetails && this.props.mystate.fileDetails.length > 0 ?

                                                                this.props.mystate.fileDetails.map(item =>
                                                                    <Col lg={4} md={4} xs={12} sm={12} className="" key={item.id}>
                                                                        <div className="file-up-div">
                                                                            <div>
                                                                                <FontAwesomeIcon icon={faTimes} className="le-up-icon" onClick={() => this.props.RemoveImage(item.id, 'upload-pictures')} />
                                                                            </div>
                                                                            <label for="file-upload" class="custom-file-upload">

                                                                                <div className="file-img-div">
                                                                                    <Image src={item.imagePreview} style={{ height: '100%', width: '100%', borderRadius: 8 }} />
                                                                                </div>

                                                                            </label>

                                                                        </div>
                                                                    </Col>
                                                                )


                                                                : null} */}

                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Specifications</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("additionalDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="additionalDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlFlooring" id="ddlFlooring"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Flooring'
                                                                    pnkvalue={this.props.editDataObj.ddlFlooring}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Flooring</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlWall" id="ddlWall"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Wall '
                                                                    pnkvalue={this.props.editDataObj.ddlWall}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Wall</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlRailing" id="ddlRailing"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Railing '
                                                                    pnkvalue={this.props.editDataObj.ddlRailing}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Railing</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlPlant" id="ddlPlant"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Plant '
                                                                    pnkvalue={this.props.editDataObj.ddlPlant}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Plant</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlPots" id="ddlPots"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Pots '
                                                                    pnkvalue={this.props.editDataObj.ddlPots}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Pots</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlAnythingElse" id="ddlAnythingElse"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Anything else '
                                                                    pnkvalue={this.props.editDataObj.ddlAnythingElse}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Anything else</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                    </Row>


                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Costings</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("leadDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="leadDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlBudgeBalcony" id="ddlBudgeBalcony"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Budget - Balcony '
                                                                    pnkvalue={this.props.editDataObj.ddlBudgeBalcony}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Budget - Balcony</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlBudgetTerrace" id="ddlBudgetTerrace"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Budget - Terrace '
                                                                    pnkvalue={this.props.editDataObj.ddlBudgetTerrace}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Budget - Terrace </option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>


                                                    </Row>

                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Home Consultant Fee</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("descDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="descDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlPaid" id="ddlPaid"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Paid'
                                                                    pnkvalue={this.props.editDataObj.ddlPaid}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="">Paid</option>
                                                                    <option value="2">2</option>
                                                                    <option value="2">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <button className="le-save-submit mr-3 pnkvalidatesubmit" name="Save" id="btnLeadEntry" onClick={this.props.SaveLeadData}>SAVE</button>
                                                    {/* <Button variant="light" className="le-save-submit mr-3">SAVE</Button> */}
                                                    <Button variant="light" className="le-cancel-submit ">CANCEL</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </PnkHtmlForm>
                    </div>
                </div>
            </div>
        )
    }
}