import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import HcList from './hc-list'
// import Footer from './footer'
import { Container } from 'react-bootstrap'
// import CallModal from './call-modal'
import { GetHcList } from '../../models/services';
// import { MakeCall, MakeWhite } from '../../models/calls'
// import { MultiEditpage } from './multieditindex';
// import AppUtils from '../../apputils';
import Header from '../../common/header';
import $ from 'jquery';


class hcPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            getHcList: false,
            numItemsPerPage: 50,
            totalRecordsInDb: 0,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,
            txtManualPageNumber: ''
        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('HC List');
        this.pageentries = [10, 25, 50, 100, 200, 300, 500]
        this.props.componentprops.ShowAdvanceSearch(false);
    }

    // HandleUserInputs = (e) => {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     })
    // }

    componentDidMount = () => {
        let hclist = this.props.PnkStore.GetData('hclist');

        if (hclist && hclist.rows && hclist.rows.length > 0) {
            // this.setState({ getHcList: false,currentPageNumber:0,startValue:0,endValue:0 })
            let total = 0;
            let totalPages = 0;
            total = hclist.metarow.totalRecordsInDb

            totalPages = Math.ceil(total / this.state.numItemsPerPage);

            let number = this.props.PnkStore.GetData('setNo');
            console.log("number", number)
            if (number - 1 === 0) {
                this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            else {
                this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }


            // if (this.props.PnkStore.GetData('lastSearchBy') === 'search') {
            //     this.setState({
            //         txtUserSearch: this.props.PnkStore.GetData('searchData')
            //     })
            // }
            // else if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
            //     this.advanceData = this.props.PnkStore.GetData('advanceSearch');
            //     this.setState({
            //         rdSearchType: this.props.PnkStore.GetData('advanceSearchtype'),
            //         currentPageNumber: 1
            //     })
            // }
        }
        else {
            this.setState({ getHcList: true })
        }

        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })

    }

    // ShowCallModal = (customerPhone, userPhone) => {
    //     if (sessionStorage.getItem('current_user_profile') === "Administrator") {
    //         this.setState({
    //             userNumberDisable: false, custNumberDisable: false
    //         })
    //     }
    //     this.setState({
    //         showCallModal: true, makeCall: false, txtCustomerNumber: customerPhone, txtFromNumber: userPhone
    //     })
    // }

    // HideCallModal = () => {
    //     this.setState({
    //         showCallModal: false, txtCustomerNumber: ''
    //     })
    // }

    ChangePage = (number) => {
        // alert(number)
        // console.log("ChangePage", number)
        this.props.PnkStore.SetData('setNo', number)

        if (this.props.PnkStore.GetData('lastSearchBy') === 'search') {
            this.setState({
                getSearch: true
            })
        }
        else if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
            this.advanceData = this.props.PnkStore.GetData('advanceSearch');
            this.setState({
                sendAdvanceSearchData: true
            })
        }
        else {
            this.setState({ getHcList: true })
        }

        if (number - 1 === 0) {
            // this.setState({ getHcList: true, currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
        else {
            // this.setState({ getHcList: true, currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
    }

    HandleUserInput = (e) => {

        let val = e.target.value;
        let name = e.target.name;
        if (name === 'txtFromNumber') {
            var numbers = /^[0-9]+$/;
            if (!val.match(numbers) || val === '') {
                this.setState({ invalidtxtFromNumber: true, [name]: '' })
            }
            else {
                this.setState({ invalidtxtFromNumber: false, [name]: val })
            }
        }
        if (name === 'txtFromNumber' && val.length === 10) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(val)) {
                this.setState({ invalidtxtFromNumber: true })


            } else {
                this.setState({ invalidtxtFromNumber: false })
            }
        }
        if (name === 'txtCustomerNumber') {
            var numbers = /^[0-9]+$/;
            if (!val.match(numbers) || val === '') {
                this.setState({ invalidtxtCustomerNumber: true, [name]: '' })
            }
            else {
                this.setState({ invalidtxtCustomerNumber: false, [name]: val })
            }
        }
        if (name === 'txtCustomerNumber' && val.length === 10) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(val)) {
                this.setState({ invalidtxtCustomerNumber: true })


            } else {
                this.setState({ invalidtxtCustomerNumber: false })
            }
        }
        if (name === "numItemsPerPage") {
            this.setState({
                [name]: val
                // getHcList: true
            })

            if (this.props.PnkStore.GetData('lastSearchBy') === 'search') {
                this.setState({
                    getSearch: true
                })
            }
            else if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
                this.advanceData = this.props.PnkStore.GetData('advanceSearch');
                this.setState({
                    sendAdvanceSearchData: true
                })
            }
            else {
                this.setState({ getHcList: true })
            }
        }
        if (name === "txtUserSearch" && val !== "") {
            this.setState({
                [name]: val,
                // getHcList: true
            })
        }
        if (name === "txtUserSearch" && val == "") {
            this.setState({
                [name]: val,


                getSearch: false,
                getHcList: true

            })
        }
        if (name === "ddlCallProfile") {
            this.setState({
                [name]: val,
                getUsersListByProfile: true
            })
        }
        if (name === "currentPageNumber")
            this.setState({ [name]: val })
    }

    HandleGetHcDataResponse = (res) => {

        let total = 0;
        let totalPages = 0;

        if (res.data && res.data.hclist) {

            total = res.data.hclist.metarow.totalRecordsInDb
            this.props.PnkStore.SetData('hclist', res.data.hclist);
            this.props.PnkStore.BroadCast();

            if (res.data.hclist && res.data.hclist.rows.length > 0) {
                // console.log("length", total)
                totalPages = Math.ceil(total / this.state.numItemsPerPage);
            }

            this.props.PnkStore.SetData('lastSearchBy', 'all')
            this.props.PnkStore.SetData('searchData', '')
            this.props.PnkStore.SetData('advanceSearch', {})
            this.props.PnkStore.SetData('setNo', 1)
            this.props.PnkStore.BroadCast();
        }
        else if (res == false) {
            this.props.PnkStore.SetData('hclist', {});
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
        this.setState({ getHcList: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
    }

    ChangeStatusColor = (status, id) => {
        let classlist = 'crmTextWhite ';
        $(document).ready(function () {
            if (status === "On Hold" || status === "Hold")
                classlist += 'label label-warning'
            else if (status === "RNR")
                classlist += 'label label-info'
            else if (status === "Pending")
                classlist += 'label label-danger crm_theme_color'
            else if (status === "In Progress")
                classlist += 'label label-success'
            else if (status === "Converted To Booking")
                classlist += 'label colorBlue'
            else if (status === "SV")
                classlist += 'label colorPink'
            else if (status === "F2F")
                classlist += 'label colorSky'
            else if (status === "Won")
                classlist += 'label colorPurple'
            else if (status === "FN")
                classlist += 'label colorParrot'
            else if (status === "Dead")
                classlist += 'label label-default'
            else if (status === "Call Back")
                classlist += 'label colorCallback'
            else if (status === "Temporary Assignment")
                classlist += 'label colorLightYellow'
            // New
            else if (status === "HC Booked")
                classlist += 'label colorBlueViolet'
            else if (status === "HC Completed")
                classlist += 'label colorBrown'
            else if (status === "Handover to Designer")
                classlist += 'label colorDesignHanded'
            else if (status === "Design Uploaded")
                classlist += 'label colorProposalUuploaded'
            else if (status === "Proposal Sent")
                classlist += 'label colorProposalSent'
            else if (status === "Booking Amount Received")
                classlist += 'label colorBookingAmount'
            else if (status === "Follow up")
                classlist += 'label colorFollowUp'
            else if (status === "Project closed")
                classlist += 'label colorProjectClose'
            else if (status === "HC In Progress")
                classlist += 'label colorHCProgress'
            else
                classlist += 'label colorDefault'
            if (document.getElementById('spnStatus_' + id) != null)
                document.getElementById('spnStatus_' + id).setAttribute('class', classlist)
        })

    }

    HandleUserSearch = (e) => {
        if (this.state.txtUserSearch.length >= 3) {
            this.setState({
                getSearch: true
            })
        }
        else {
            this.setState({
                getSearch: false,
            })
        }
        if (this.state.txtUserSearch === '') {
            this.setState({
                getSearch: false,
                getHcList: true
            });
        }

    }

    HandleSuccessSearchLeadListResponse = (res) => {
        let total = 0;
        let totalPages = 0;
        if (res !== false) {
            // alert(res.data.hclist.rows.length);
            if (res.data && res.data.hclist && res.data.hclist.rows && res.data.hclist.rows.length > 0) {
                total = res.data.hclist.metarow.totalRecordsInDb

                if (res.data.hclist && res.data.hclist.rows.length > 0) {
                    // console.log("length", total)
                    totalPages = Math.ceil(total / this.state.numItemsPerPage);
                }

                this.props.PnkStore.SetData('hclist', res.data.hclist);

                this.props.PnkStore.SetData('lastSearchBy', 'search')
                this.props.PnkStore.SetData('searchData', this.state.txtUserSearch)
                this.props.PnkStore.SetData('advanceSearch', {})
                this.props.PnkStore.SetData('setNo', 1)
                this.props.PnkStore.BroadCast();
            }
            else {
                let error = 'No Record Found';
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
                document.getElementById('spnWarningMessage').innerText = error;
                $('#crmWarningAlert').slideDown().slideUp(5000, function () {
                })
            }
            this.setState({ getSearch: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false })
        }
        else {
            let error = 'Something went wrong';
            let errorData = this.props.PnkStore.GetData('error');
            if (typeof this.props.PnkStore.GetData('error') !== 'object')
                errorData = JSON.stringify(this.props.PnkStore.GetData('error'));
            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
    }

    HandleProfileGetResponse = (res) => {
        if (res.data && res.data.profilelist && res.data.profilelist.rows) {
            // console.log("profilelist", res.data.profilelist)
            // this.props.PnkStore.SetData('profilelist', res.data.profilelist);
            // this.props.PnkStore.BroadCast();
            this.setState({
                profileDataList: res.data.profilelist
            })
        }
        this.setState({
            getProfileData: false
        })

    }

    HandleGetUserByProfileSuccessResponse = (res) => {
        // console.log("HandleGetUserByProfileSuccessResponse", res.data)
        if (res.data && res.data.userlistbyprofile) {
            this.setState({
                userListByProfile: res.data.userlistbyprofile,
                getUsersListByProfile: false
            })
        }
    }

    CloseModal = () => {
        this.props.componentprops.HandleCloseModal();
        this.setState({ getHcList: true })
    }

    LogOut = () => {
        console.log("logout clicked")
        sessionStorage.clear();
        localStorage.clear();
        this.setState({ loginStatus: 'logout' });
        // AppUtils.HandlePageClick(this.props, '')
        window.location.href = '/'
    }

    Reload = () => {
        this.setState({
            currentPageNumber: 1,
            startValue: 0,
            endValue: (this.state.startValue + this.state.numItemsPerPage),
            getHcList: true
        })
    }
    render() {
        let currentPageNumber = 1;
        if (this.props.PnkStore.GetData('lastSearchBy') === 'advance') {
            console.log("currentPageNumber", this.props.PnkStore.GetData('setNo'))
            currentPageNumber = this.props.PnkStore.GetData('setNo')
        }
        else {
            currentPageNumber = this.state.currentPageNumber
        }

        // alert(JSON.stringify(this.props.PnkStore.GetData('allowed_routes')))
        // console.log("lead list state", this.state)
        let hclist = this.props.PnkStore.GetData('hclist');
        // let advancesearchlist = this.props.PnkStore.GetData('advancesearchlist');

        return (

            <Container fluid className="data-table-lead">
                {/* {this.props.componentprops.showHeader === undefined && this.props.componentprops.showHeader !== false ?
                    <Header showBackButton={false} GetAdvanceSearchData={this.GetAdvanceSearchData} showSearch={false} title='HC List' {...this.props} LogOut={this.LogOut} /> : null} */}
                {/* <Options HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInput} SortData={this.SortData}
                    Reload={this.Reload}
                    {...this.props} /> */}
                <HcList mystate={this.state}
                    hcData={hclist.rows}
                    HandleUserInput={this.HandleUserInput}
                    ChangePage={this.ChangePage}
                    pageentries={this.pageentries} {...this.props}
                    currentPageNumber={currentPageNumber}
                />
                {/* <Footer {...this.props}
                    SortData={this.SortData}
                    HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInput}
                /> */}


                {((this.state.getHcList === true) ? GetHcList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.currentPageNumber, this.state.numItemsPerPage, this.HandleGetHcDataResponse) : "")}
                {/* {this.state.getSearch === true ? SearchLeadList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.currentPageNumber, this.state.numItemsPerPage, 'user_name', this.state.txtUserSearch, this.HandleSuccessSearchLeadListResponse) : ''}
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
                {((this.state.getUsersListByProfile === true) ? GetUserByProfile(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.ddlCallProfile, '', this.HandleGetUserByProfileSuccessResponse) : "")}
                {((this.state.sendAdvanceSearchData === true) ? AdvancedSearch(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.rdSearchType, this.advanceData, this.state.currentPageNumber, this.state.numItemsPerPage, this.HandleSuccessAdvanceSearch) : "")} */}

                {/* EXOTEL CALLING */}
                {/* {((this.state.makeCall === true) ? MakeCall(this.props.PnkStore, this.state.txtFromNumber, this.state.txtCustomerNumber, this.state.txtCustomerName, sessionStorage.getItem('current_user_name'), this.HandleMakeCallSuccessResponse) : "")}
                {((this.state.getWhite === true) ? MakeWhite(this.props.PnkStore, this.state.txtFromNumber, this.state.txtCustomerNumber, this.HandleWhiteCallSuccessResponse) : "")} */}
            </Container >
        )
    }
}
export const HCListPage = PnkConnect(hcPage, "");