import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
export default class CancelModal extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (

            <React.Fragment>

                <h3 className="cancel-modal-text">Are you sure you wanted to cancel this ?</h3>
                <div className="cancel-modal-btn-div">
                    <Button variant="light" className="cancel-modal-save mr-3" onClick={() => this.props.HandlePressYes('leads')}>Yes</Button>
                    <Button variant="light" className="cancel-modal-cancel-btn" onClick={() => this.props.HideCancel()} >No</Button>

                </div>
            </React.Fragment>

        )
    }
} 