import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Reload from '../../assets/images/reload.svg'
export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }
    GoToEntry = () => {
        this.setState({
            gotoEntry: true
        })
        // 

    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {
       
        this.props.SortData(e, e.target.value)
    }
    render() {
       
        if (this.state.gotoEntry === true) {
            return <Redirect to='/lead-entry' push={true} />
        }

        return (
            <Row className="hide-mob">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >

                            <Col lg={2} md={4} className="flex-left col-padding-0">
                                {sessionStorage.getItem('current_user_allowed_routes').includes('Lead Entry') === true ?
                                    <div className="btn-div">
                                        <Button type="button" className="add-button" onClick={() => this.props.ShowProfileModal()}>+ Add New Source</Button>
                                    </div> : null}
                            </Col>
                            {/* <Col lg={2} md={4} className="flex-left">
                                <div className="btn-div">
                                    <Button className="filter-button">Filter</Button>
                                </div>
                            </Col> */}
                            <Col lg={3} md={3} className="flex-left  col-padding-0">

                                <div class="searchDropdownDiv">
                                    <div class="input-group" style={{ float: "right", height: 38 }} >
                                        <div className="input-group-prepend">
                                            <button className="SortByButton" type="button">Sort By:</button>
                                        </div>
                                        <select name="sortBy" className="selectSearch" id="inputGroupSelect03" aria-label="Example select with button addon"
                                            onChange={this.HandleChange}
                                        >
                                            <option value="">Sort By</option>
                                            <option selected value="All Lead">All Lead</option>
                                            <option value="date_time" column="date_time" >Date</option>
                                            <option value="lead_city" column="lead_city" >Location</option>
                                            <option value="lead_current_stage" column="lead_current_stage">Status</option>
                                            <option value="lead_source" column="lead_source" >Source</option>
                                            <option value="lead_owner" column="lead_owner" >Owner</option>
                                            <option value="user_name" column="user_name" >Customer</option>
                                        </select>
                                    </div>
                                </div>


                            </Col>
                            <Col lg={3} md={4} className="col-padding-0">
                                <InputGroup className="profile-search-box">
                                    <FormControl
                                        placeholder="Search by Name, Contact, Email"
                                        className="profile-search-box"
                                        name="txtUserSearch" id="txtUserSearch"
                                        value={this.props.mystate.txtUserSearch}
                                        onChange={this.props.HandleUserSearch}

                                    />
                                    <InputGroup.Append onClick={this.props.HandleUserSearch}>
                                        <InputGroup.Text id="basic-addon2" className="profile-search-text search-icon-lead">
                                            {/* <Button variant="light"> */}
                                            <FontAwesomeIcon icon={faSearch} className="profile-search-icon" />
                                            {/* </Button> */}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            <Col lg={1} className="col-padding-0">
                                <Image src={Reload} className="reload-img" />
                            </Col>
                            {/* <Col lg={2}>
                                {sessionStorage.getItem('current_user_allowed_routes').includes('Lead Entry') === true ?
                                    <Button variant="light" className="upload-builk-lead mr-3" onClick={() => this.props.ShowBulkUpload()}>+ Upload Bulk lead</Button> : null}
                            </Col> */}
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}