import React from 'react'
import { Row, Col, Button, Form, Tooltip, OverlayTrigger, Overlay } from 'react-bootstrap'
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '../Pagination'

export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            selectAll: false,
            numItemsPerPage: 10,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 0

        }
        this.target = React.createRef()
    }
    componentDidMount = () => {
        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })
    }
    SelectAll = () => {
        this.setState({
            selectAll: true
        })
    }

    ChangePage = (number) => {
       
        if (number - 1 === 0) {
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage) });
        }
        else {
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage) });
        }
    }

    render() {
        let totalPages = 0; let length = 0;
        if (this.props.PnkStore.GetData("userlist") && this.props.PnkStore.GetData("userlist") && this.props.PnkStore.GetData("userlist").rows &&
            this.props.PnkStore.GetData("userlist").rows.length > 0) {
           
            length = this.props.PnkStore.GetData("userlist").rows.length
            totalPages = Math.ceil(length / this.state.numItemsPerPage);
        }

        const AntSwitch = withStyles((theme) => ({
            root: {
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.grey[500],
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: ' #5d8614',
                        borderColor: '#5d8614',
                    },
                },
            },
            thumb: {
                width: 12,
                height: 12,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
        }))(Switch);
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">

                                    <Row className="mob-row-align">

                                        <Col lg={1} className="order-col hide-mob">

                                            <h3 className="table-head-lead">Sr. No.</h3>

                                        </Col>

                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead ">User Name</h3>
                                        </Col>
                                        <Col xs={5} className="show-mob">
                                            <h3 className="table-head-lead ">User Details</h3>
                                        </Col>
                                        <Col lg={2} className="hide-mob">
                                            <h3 className="table-head-lead">Email</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead ">Mobile</h3>
                                        </Col>
                                        <Col lg={1} xs={4} className="">
                                            <h3 className="table-head-lead">Profile</h3>
                                        </Col>
                                        <Col lg={1} className=" hide-mob" >
                                            <h3 className="table-head-lead margin-5">Created By</h3>

                                        </Col>
                                        <Col lg={2} className="hide-mob">
                                            <h3 className="table-head-lead">Last Login</h3>
                                        </Col>
                                        <Col lg={1} className=" hide-mob">
                                            <h3 className="table-head-lead margin-5">Reporting To</h3>

                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Status</h3>
                                        </Col>
                                        <Col lg={1} className="" xs={3}>
                                            <h3 className="table-head-lead">Action</h3>
                                        </Col>

                                    </Row>
                                    {JSON.stringify(this.props.userlist) !== '{}' && this.props.userlist.rows.length > 0 ? this.props.userlist.rows.map((item, i) => (
                                        <div className="lead-table-data-user">
                                            {i >= this.state.startValue && i < this.state.endValue ?
                                                (i + 1) % 2 === 0 ?
                                                    (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>
                                                        <Col lg={1} className="order-col hide-mob">
                                                            <div className="table-data-user">
                                                                {/* <label class="chk-container">
                                                                    {this.state.selectAll === true ?
                                                                        <input type="checkbox" checked /> :
                                                                        <input type="checkbox" />}

                                                                    <span class="checkmark-form "></span>

                                                                </label> */}
                                                                <div className="lead-data">
                                                                    <div> {i + 1}</div>
                                                                </div>



                                                            </div>
                                                        </Col>

                                                        <Col lg={1} xs={5} className="hide-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.user_full_name + "(" + item.user_name + ")"}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={2} className="hide-mob">

                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.user_email}</div>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col xs={5} className="show-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{item.user_full_name}</div>
                                                                    <div>{item.user_email}</div>
                                                                    <div>{item.user_mobile}</div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col lg={1} className="hide-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div>{item.user_mobile}</div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col lg={1} xs={4} className="">
                                                            <div className="table-data-user">


                                                                <div className="lead-data">
                                                                    {item.user_profile[0] && item.user_profile[0].profile_name !== "" ?

                                                                        <div className="text-overflow">{item.user_profile[0].profile_name}</div> :
                                                                        <div className="text-overflow"></div>}
                                                                </div>



                                                            </div>
                                                        </Col>
                                                        <Col lg={1} className=" hide-mob">
                                                            <div className="table-data-user margin-5">
                                                                <div className="lead-data">
                                                                    <div>{item.created_by}</div>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={2} className="order-col hide-mob">

                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    {/* <div className="text-overflow"> */}
                                                                    <div className="text-overflow">{item.last_login}</div>
                                                                    <div>{item.last_login_platform}</div>
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>


                                                        </Col>
                                                        <Col lg={1} className="order-col hide-mob">
                                                            <div className="table-data-user margin-5">
                                                                <div className="lead-data">
                                                                    <div>{item.reporting_to}</div>
                                                                </div>
                                                            </div>

                                                        </Col>

                                                        <Col lg={1} className="hide-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    {(item.user_name !== 'prabhash') ?
                                                                        item.user_status === "1" ?
                                                                            <AntSwitch checked={true} value={item.user_status} onChange={(e) => this.props.HandleUserAction(e, item._id, item.user_status)} name="ChangeStatus" /> :
                                                                            <AntSwitch checked={false} value={item.user_status} onChange={(e) => this.props.HandleUserAction(e, item._id, item.user_status)} name="ChangeStatus" />
                                                                        :
                                                                        <AntSwitch checked={true} value={item.user_status} />}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={1} xs={3} className="">
                                                            <div className="table-data-user">


                                                                {(item.user_name !== 'prabhash') ?
                                                                    <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.user_status)}>
                                                                        <option value="">Take Action</option>
                                                                        <option value="Detail">Detail</option>
                                                                        {sessionStorage.getItem('current_user_profile') === "Administrator" ?
                                                                            <option value="Delete">Delete</option> : null}
                                                                    </Form.Control> : null}

                                                            </div>



                                                        </Col>

                                                    </Row>) : (
                                                        <Row className="table-items-user" style={{ background: '#ffffff' }}>
                                                            <Col lg={1} className="order-col hide-mob">
                                                                <div className="table-data-user">
                                                                    {/* <label class="chk-container">
                                                                        {this.state.selectAll === true ?
                                                                            <input type="checkbox" checked /> :
                                                                            <input type="checkbox" />}

                                                                        <span class="checkmark-form "></span>
                                                                       
                                                                    </label> */}
                                                                    <div className="lead-data">
                                                                        <div> {i + 1}</div>
                                                                    </div>


                                                                </div>
                                                            </Col>

                                                            <Col lg={1} xs={5} className="hide-mob">
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.user_full_name}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={2} className="hide-mob">

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.user_email}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xs={5} className="show-mob">
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.user_full_name}</div>
                                                                        <div>{item.user_email}</div>
                                                                        <div>{item.user_mobile}</div>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <Col lg={1} className="hide-mob">
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.user_mobile}</div>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <Col lg={1} xs={4} className="">
                                                                <div className="table-data-user">

                                                                    <div className="lead-data">
                                                                        {item.user_profile[0] && item.user_profile[0].profile_name !== "" ?

                                                                            <div className="text-overflow">{item.user_profile[0].profile_name}</div> :
                                                                            <div className="text-overflow"></div>}
                                                                    </div>


                                                                </div>
                                                            </Col>
                                                            <Col lg={1} className=" hide-mob">
                                                                <div className="table-data-user margin-5">
                                                                    <div className="lead-data">
                                                                        <div>{item.created_by}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={2} className="order-col hide-mob">

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        {/* <div className="text-overflow"> */}
                                                                        <div className="text-overflow">{item.last_login}</div>
                                                                        <div>{item.last_login_platform}</div>
                                                                        {/* </div> */}

                                                                    </div>
                                                                </div>


                                                            </Col>
                                                            <Col lg={1} className="order-col hide-mob">
                                                                <div className="table-data-user margin-5">
                                                                    <div className="lead-data">
                                                                        <div>{item.reporting_to}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col lg={1} className="hide-mob">
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        {(item.user_name !== 'prabhash') ?
                                                                            item.user_status === "1" ?
                                                                                <AntSwitch checked={true} value={item.user_status} onChange={(e) => this.props.HandleUserAction(e, item._id, item.user_status)} name="ChangeStatus" /> :
                                                                                <AntSwitch checked={false} value={item.user_status} onChange={(e) => this.props.HandleUserAction(e, item._id, item.user_status)} name="ChangeStatus" />
                                                                            :
                                                                            <AntSwitch checked={true} value={item.user_status} />}

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={1} xs={3} className="">
                                                                <div className="table-data-user">

                                                                    {(item.user_name !== 'prabhash') ?
                                                                        <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.user_status)}>
                                                                            <option value="">Take Action</option>
                                                                            <option value="Detail">Detail</option>
                                                                            {sessionStorage.getItem('current_user_profile') === "Administrator" ?
                                                                                <option value="Delete">Delete</option> : null}
                                                                        </Form.Control> : null}

                                                                </div>



                                                            </Col>

                                                        </Row>) : null}

                                        </div>


                                    )) : null}
                                    {this.state.numItemsPerPage < length ?
                                        <div className="pagination-div-profile">
                                            <Pagination totalNumber={totalPages} currentPageNumber={this.state.currentPageNumber} ChangePage={this.ChangePage} />
                                        </div> : null}


                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row >
        )
    }
}