import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { PnkConnect } from "../../../pnk-react/pnk-miniredux/";
import Report from './report'
import Options from './options';
import { GetUserProfileList, GetCityList, GetUserList, GetLeadData, GetLeadSource } from '../../../models/users';
import { AdminSalesReport, CallBackList } from '../../../models/admin-dashboard'
import ViewData from './view-data-modal'
import MobCustomDatePicker from './mob-custom-picker'
import CallbackReport from './callback'
import AppUtils from '../../../apputils'
import PatchReport from './patch-report'
import MeetingTracker from './meeting-tracker'
import UnderConstructionComp from './under-construction'
class reportPage extends React.Component {
    constructor(props) {
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.state = {
            currentSalesReportType: 'Today',
            txtCustomStartDate: new Date().setDate(new Date().getDate() - 29),
            txtCustomEndDate: new Date(),
            currentViewType: (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "") ? this.props.componentprops.match.params.type : 'default',
            startDate: '',
            endDate: '',
            currentDisplayType: '',
            todayDate: '',
            yesterdayDate: '',
            weekDate: '',
            monthDate: '',
            getUser: false,
            getReportData: false,
            reportData: [],
            ddlPreferredCity: '-1',
            getCity: true,
            showCustomDateModal: false,
            getCallbackData: false,
            callbackReportData: [],
            hcPmScheduleType: 'hc',
            patchView: 'default',
            patchDetailReport: [],
            profileStageData: [],
            ddlCurrentLeadStage: '',
            total_patch_count_today: 0,
            total_patch_count_yesterday: 0,
            total_patch_count_week: 0,
            total_patch_count_month: 0,
            getCustomCallbackData: false,
            meetingWeekStartDate: '',
            meetingWeekEndDate: '',
            getMeetingData: false,
            txtCustomMeetingStartDate: new Date(),
            txtCustomMeetingEndDate: new Date().setDate(new Date().getDate() + 7),

        }
        this.salesReportType = [
            'Today',
            'Yesterday',
            'Last 7 Days',
            // 'Last 30 Days',
            'Custom'
        ];
        this.callBackReportType = [
            'Today',
            'Coming Days',
            'Custom'
        ]
        this.currentCity = '';
        // Lead Generate
        this.todayLeadGeneratedData = []
        this.yesterdayLeadGeneratedData = []
        this.weekLeadGeneratedData = []
        this.monthLeadGeneratedData = []

        // Leads Pending
        this.todayPendingData = []
        this.yesterdayPendingData = []
        this.weekPendingData = []
        this.monthPendingData = []

        // Dead Lead
        this.todayDeadData = []
        this.yesterdayDeadData = []
        this.weekDeadData = []
        this.monthDeadData = []

        // Patch
        this.todayPatchSalesReport = []
        this.yesterdayPatchSalesReport = []
        this.weekPatchSalesReport = []
        this.monthPatchSalesReport = []

        this.customData = []
        this.currentCity = ''

        this.todayAllData = []
        this.yesterdayAllData = []
        this.weekAllData = []
        this.monthAllData = []
        this.callbackReportData = []
        this.hcPmScheduleType = 'hc'

        // Follow Up
        this.todayFollowupReport = []
        this.yesterdayFollowupReport = []
        this.weekFollowupReport = []
        this.monthFollowupReport = []
        // Patch
        this.todayPatchData = [];
        this.yesterdayPatchData = [];
        this.weekPatchData = [];
        this.monthPatchData = [];

        // Hc Booked
        this.todayHCBookedReport = []
        this.yesterdayHCBookedReport = []
        this.weekHCBookedReport = []

        // PM Booked
        this.todayPMBookedReport = []
        this.yesterdayPMBookedReport = []
        this.weekPMBookedReport = []
        // VC
        this.todayVCCompletedReport = []
        this.yesterdayVCCompletedReport = []
        this.weekVCCompletedReport = []
        // Under Construction
        this.todayUnderConstruction = []
        this.yesterdayUnderConstruction = []
        this.weekUnderConstruction = []
        //   
        this.adminReportAll = []
    }
    componentDidMount = () => {
        var today = new Date();

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let endDate = yyyy + '-' + mm + '-' + dd;

        let beforeThirtyDays = new Date(new Date().setDate(new Date().getDate() - 6))
        var before_dd = String(beforeThirtyDays.getDate()).padStart(2, '0');
        var before_mm = String(beforeThirtyDays.getMonth() + 1).padStart(2, '0'); //January is 0!
        var before_yyyy = beforeThirtyDays.getFullYear();
        let startDate = before_yyyy + '-' + before_mm + '-' + before_dd;

        let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
        var yester_dd = String(yesterdayDate.getDate()).padStart(2, '0');
        var yester_mm = String(yesterdayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yester_yyyy = yesterdayDate.getFullYear();
        let yesterday = yester_yyyy + '-' + yester_mm + '-' + yester_dd;

        let weekDate = new Date(new Date().setDate(new Date().getDate() - 6))
        var week_dd = String(weekDate.getDate()).padStart(2, '0');
        var week_mm = String(weekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var week_yyyy = weekDate.getFullYear();
        let weekStartDate = week_yyyy + '-' + week_mm + '-' + week_dd;

        let monthDate = new Date(new Date().setDate(new Date().getDate() - 29))
        var month_dd = String(monthDate.getDate()).padStart(2, '0');
        var month_mm = String(monthDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var month_yyyy = monthDate.getFullYear();
        let monthStartDate = month_yyyy + '-' + month_mm + '-' + month_dd;

        let comingWeekDate = new Date(new Date().setDate(new Date().getDate() + 7))
        var meeting_week_dd = String(comingWeekDate.getDate()).padStart(2, '0');
        var meeting_week_mm = String(comingWeekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var meeting_week_yyyy = comingWeekDate.getFullYear();
        let meetingWeekEndDate = meeting_week_yyyy + '-' + meeting_week_mm + '-' + meeting_week_dd;

        this.setState({
            startDate: startDate, endDate: endDate, getReportData: false, currentDisplayType: endDate,
            todayDate: endDate, yesterdayDate: yesterday, weekDate: weekStartDate, monthDate: monthStartDate,
            meetingWeekStartDate: endDate, meetingWeekEndDate: meetingWeekEndDate
        })

        if (JSON.stringify(this.props.PnkStore.GetData('userlist')) === '{}') {
            this.setState({
                getUser: true
            })
        }
        if (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "") {
            if (this.props.componentprops.match.params.type === 'meeting-tracker') {
                this.setState({
                    getMeetingData: true
                })
            }
            else if (this.props.componentprops.match.params.type === 'callback') {
                this.setState({
                    getCallbackData: true
                })
            }
            else {
                this.setState({
                    getReportData: true,

                })
            }
        }
        else {
            this.setState({
                getReportData: true
            })
        }
    }
    HandleHCSchedule = (type) => {

        this.setState({
            hcPmScheduleType: type,
            currentSalesReportType: 'Today'

        })
        this.hcPmScheduleType = type;
        this.RenderReport("HCPMSchedule", 'Today',)
    }
    HandleChangeDate = (type, date) => {
        this.setState({
            [type]: date
        });

    };
    HandleUserInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ddlPreferredCity") {
            this.currentCity = e.target.value;
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }


    }
    HandleInsideSalesOptions = (type) => {
        this.currentInsideSalesType = type
        this.setState({ currentInsideSalesType: type })
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport(this.state.currentChoice, this.state.currentSalesReportType)
        }
        else {
            this.RenderCustomReport()
        }
    }


    ShowSalesInsideModal = (data) => {
        this.props.componentprops.OpenModal(ViewData, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: '', icon: 'close' }, { action: this.state, name: 'Profile', close: this.HideModal, data: data })
    }
    HideModal = () => {
        this.props.componentprops.HandleCloseModal();
    }

    HandleGetReportResponse = (res) => {

        this.setState({ getReportData: false, isOptionDisabled: false })
        if (res !== false) {
            if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.adminReportAll = res.data.adminfirstmonthdata.rows
                res.data.adminfirstmonthdata.rows.forEach((element, i) => {

                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                        this.todayHCBookedReport.push(element)
                    }

                    else if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayHCBookedReport.push(element);
                    }
                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                        this.weekHCBookedReport.push(element)
                    }

                    // PM todayPMBookedReport
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate)) {
                        this.todayPMBookedReport.push(element)
                    }
                    else if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayPMBookedReport.push(element);
                    }
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate) {
                        this.weekPMBookedReport.push(element)
                    }

                    // VC Completed

                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate)) {
                        this.todayVCCompletedReport.push(element)
                    }
                    else if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayVCCompletedReport.push(element);
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate) {
                        this.weekVCCompletedReport.push(element)
                    }




                    if (element.lead_department[0].profile_name.includes("Sales")) {
                        // Under Construction

                        if (element.under_const_date_time !== '' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.todayDate)) {
                            this.todayUnderConstruction.push(element)
                        }
                        else if (element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayUnderConstruction.push(element);
                        }
                        if (element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.todayDate + ' 23:59:59' && element.under_const_date_time >= this.state.weekDate) {
                            this.weekUnderConstruction.push(element)
                        }
                        if ((element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate))
                            || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate))
                            || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.todayDate))
                            || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.todayDate))
                            || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.todayDate))
                            || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time.includes(this.state.todayDate))
                            || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.todayDate))
                            || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate))

                        ) {
                            this.todayAllData.push(element)
                        }
                        else if ((element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate))
                            || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate))
                            || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate))
                            || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.yesterdayDate))
                            || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.yesterdayDate))
                            || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time.includes(this.state.yesterdayDate))
                            || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.yesterdayDate))
                            || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate))
                        ) {
                            this.yesterdayAllData.push(element);
                        }
                        if ((element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.weekDate)
                            || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate)
                            || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate)
                            || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.weekDate)
                            || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.weekDate)
                            || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_uploaded_date_time >= this.state.weekDate)
                            || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.weekDate)
                            || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate)

                        ) {
                            this.weekAllData.push(element)
                        }
                        if ((element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.monthDate)
                            || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.monthDate)
                            || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.monthDate)
                            || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.monthDate)
                            || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.monthDate)
                            || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_uploaded_date_time >= this.state.monthDate)
                            || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.monthDate)
                            || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.monthDate)) {
                            this.monthAllData.push(element)
                        }

                        // Follow Up
                        if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.todayDate)) {
                            this.todayFollowupReport.push(element)
                        }
                        else if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayFollowupReport.push(element);
                        }
                        if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate + ' 23:59:59' && element.follow_up_date_time >= this.state.weekDate) {
                            this.weekFollowupReport.push(element)
                        }
                        if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate + ' 23:59:59' && element.follow_up_date_time >= this.state.monthDate) {
                            this.monthFollowupReport.push(element)
                        }
                        // Patch Data for other reports
                        if (element.patch_date_time !== undefined && element.patch_date_time !== '' && element.patch_date_time.includes(this.state.todayDate)) {
                            this.todayPatchData.push(element)
                        }
                        else if (element.patch_date_time !== undefined && element.patch_date_time !== '' && element.patch_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPatchData.push(element);
                        }
                        if (element.patch_date_time !== undefined && element.patch_date_time !== '' && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.weekDate) {
                            this.weekPatchData.push(element)
                        }
                        if (element.patch_date_time !== undefined && element.patch_date_time !== '' && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.monthDate) {
                            this.monthPatchData.push(element)
                        }

                    }

                    if (res.data.adminfirstmonthdata.rows.length - 1 === i)
                        this.RenderReport(this.state.currentViewType, this.state.currentSalesReportType);
                });
            }
        }
    }
    RenderReport = (viewType, type) => {
        var users = this.props.PnkStore.GetData('userlist').rows;
        let sales_report = [];
        let current_details = []
        let total_in_progress = 0, total_hc_booked = 0, total_hc_completed = 0, total_design_handovered = 0, total_proposal_sent = 0, total_proposal_uploaded = 0, total_booking_amount_received = 0;
        if (viewType === "default") {
            this.setState({ reportData: [] })
            if (type === "Today") {
                this.todayAllData.forEach(todayData => {
                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (todayData.lead_current_stage === "In Progress" && (todayData.patch_date_time !== ''))
                                total_in_progress++
                            if (todayData.lead_current_stage === "HC Booked" && (todayData.hc_booked_date_time !== ''))
                                total_hc_booked++
                            if (todayData.lead_current_stage === "HC Completed" && (todayData.hc_completed_date_time !== ''))
                                total_hc_completed++
                            if (todayData.lead_current_stage === "Handover to Designer" && (todayData.handover_to_designer_date_time !== ''))
                                total_design_handovered++
                            if (todayData.lead_current_stage === "Proposal Sent" && (todayData.proposal_sent_date_time !== ''))
                                total_proposal_sent++
                            if (todayData.lead_current_stage === "Design Uploaded" && (todayData.proposal_uploaded_date_time !== ''))
                                total_proposal_uploaded++
                            if (todayData.lead_current_stage === "Booking Amount Received" && (todayData.booking_amount_received_date_time !== ''))
                                total_booking_amount_received++
                        }
                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (todayData.lead_current_stage === "In Progress" && (todayData.patch_date_time !== ''))
                                total_in_progress++
                            if (todayData.lead_current_stage === "HC Booked" && (todayData.hc_booked_date_time !== ''))
                                total_hc_booked++
                            if (todayData.lead_current_stage === "HC Completed" && (todayData.hc_completed_date_time !== ''))
                                total_hc_completed++
                            if (todayData.lead_current_stage === "Handover to Designer" && (todayData.handover_to_designer_date_time !== ''))
                                total_design_handovered++
                            if (todayData.lead_current_stage === "Proposal Sent" && (todayData.proposal_sent_date_time !== ''))
                                total_proposal_sent++
                            if (todayData.lead_current_stage === "Design Uploaded" && (todayData.proposal_uploaded_date_time !== ''))
                                total_proposal_uploaded++
                            if (todayData.lead_current_stage === "Booking Amount Received" && (todayData.booking_amount_received_date_time !== ''))
                                total_booking_amount_received++
                        }
                    }
                })



            }
            else if (type === "Yesterday") {
                this.yesterdayAllData.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_current_stage === "In Progress" && (report.patch_date_time !== ''))
                            total_in_progress++
                        if (report.lead_current_stage === "HC Booked" && (report.hc_booked_date_time !== ''))
                            total_hc_booked++
                        if (report.lead_current_stage === "HC Completed" && (report.hc_completed_date_time !== ''))
                            total_hc_completed++
                        if (report.lead_current_stage === "Handover to Designer" && (report.handover_to_designer_date_time !== ''))
                            total_design_handovered++
                        if (report.lead_current_stage === "Proposal Sent" && (report.proposal_sent_date_time !== ''))
                            total_proposal_sent++
                        if (report.lead_current_stage === "Design Uploaded" && (report.proposal_uploaded_date_time !== ''))
                            total_proposal_uploaded++
                        if (report.lead_current_stage === "Booking Amount Received" && (report.booking_amount_received_date_time !== ''))
                            total_booking_amount_received++
                    }
                    else if (this.currentCity === '') {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.lead_current_stage === "In Progress" && (report.patch_date_time !== ''))
                                total_in_progress++
                            if (report.lead_current_stage === "HC Booked" && (report.hc_booked_date_time !== ''))
                                total_hc_booked++
                            if (report.lead_current_stage === "HC Completed" && (report.hc_completed_date_time !== ''))
                                total_hc_completed++
                            if (report.lead_current_stage === "Handover to Designer" && (report.handover_to_designer_date_time !== ''))
                                total_design_handovered++
                            if (report.lead_current_stage === "Proposal Sent" && (report.proposal_sent_date_time !== ''))
                                total_proposal_sent++
                            if (report.lead_current_stage === "Design Uploaded" && (report.proposal_uploaded_date_time !== ''))
                                total_proposal_uploaded++
                            if (report.lead_current_stage === "Booking Amount Received" && (report.booking_amount_received_date_time !== ''))
                                total_booking_amount_received++
                        }
                    }
                })

            }
            else if (type === 'Last 7 Days') {
                this.weekAllData.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.lead_current_stage === "In Progress" && (report.patch_date_time !== ''))
                                total_in_progress++
                            if (report.lead_current_stage === "HC Booked" && (report.hc_booked_date_time !== ''))
                                total_hc_booked++
                            if (report.lead_current_stage === "HC Completed" && (report.hc_completed_date_time !== ''))
                                total_hc_completed++
                            if (report.lead_current_stage === "Handover to Designer" && (report.handover_to_designer_date_time !== ''))
                                total_design_handovered++
                            if (report.lead_current_stage === "Proposal Sent" && (report.proposal_sent_date_time !== ''))
                                total_proposal_sent++
                            if (report.lead_current_stage === "Design Uploaded" && (report.proposal_uploaded_date_time !== ''))
                                total_proposal_uploaded++
                            if (report.lead_current_stage === "Booking Amount Received" && (report.booking_amount_received_date_time !== ''))
                                total_booking_amount_received++
                        }
                    }
                    else if (this.currentCity === '') {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.lead_current_stage === "In Progress" && (report.patch_date_time !== ''))
                                total_in_progress++
                            if (report.lead_current_stage === "HC Booked" && (report.hc_booked_date_time !== ''))
                                total_hc_booked++
                            if (report.lead_current_stage === "HC Completed" && (report.hc_completed_date_time !== ''))
                                total_hc_completed++
                            if (report.lead_current_stage === "Handover to Designer" && (report.handover_to_designer_date_time !== ''))
                                total_design_handovered++
                            if (report.lead_current_stage === "Proposal Sent" && (report.proposal_sent_date_time !== ''))
                                total_proposal_sent++
                            if (report.lead_current_stage === "Design Uploaded" && (report.proposal_uploaded_date_time !== ''))
                                total_proposal_uploaded++
                            if (report.lead_current_stage === "Booking Amount Received" && (report.booking_amount_received_date_time !== ''))
                                total_booking_amount_received++
                        }
                    }
                })

            }




            if (total_in_progress > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_design_handovered > 0 || total_proposal_sent > 0 || total_proposal_uploaded > 0 || total_booking_amount_received > 0) {
                sales_report.push({
                    total_in_progress: total_in_progress, total_hc_booked: total_hc_booked,
                    total_hc_completed: total_hc_completed, total_design_handovered: total_design_handovered,
                    total_proposal_sent: total_proposal_sent, total_proposal_uploaded: total_proposal_uploaded,
                    total_booking_amount_received: total_booking_amount_received
                })
            }
            this.setState({ reportData: sales_report })

        }
        else if (viewType === 'under-construction') {
            let under_const_arr = []
            if (type === "Today") {
                this.setState({ reportData: [] })
                this.todayUnderConstruction.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {

                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on


                        });

                    }
                });
            }
            if (type === "Yesterday") {
                this.setState({ reportData: [] })
                this.yesterdayUnderConstruction.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {


                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });

                    }
                });
            }
            if (type === "Last 7 Days") {
                this.setState({ reportData: [] })
                this.weekUnderConstruction.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {


                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });

                    }
                });
            }
            this.setState({
                reportData: under_const_arr
            })
        }
        else if (viewType === "meeting-tracker") {

            let users = this.props.PnkStore.GetData('userlist').rows;

            var hc_report = [];

            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if (user.user_name === sessionStorage.getItem('current_user_name')) {
                        if (type === "Today") {

                            if (this.hcPmScheduleType === 'hc') {
                                this.setState({
                                    reportData: []
                                })
                                // alert(1)
                                this.todayHCBookedReport.forEach(todayHCData => {
                                    if (user.user_name === todayHCData.lead_owner) {
                                        if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {


                                            hc_report.push({
                                                lead_owner: todayHCData.lead_owner,
                                                client_name: todayHCData.user_name,
                                                hc_date_time: todayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: todayHCData.preferred_area,
                                                hc_completed_date_time: todayHCData.hc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {

                                            hc_report.push({
                                                lead_owner: todayHCData.lead_owner,
                                                client_name: todayHCData.user_name,
                                                hc_date_time: todayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: todayHCData.preferred_area,
                                                hc_completed_date_time: todayHCData.hc_completed_date_time

                                            });
                                        }



                                    }


                                });
                            }
                            else if (this.hcPmScheduleType === "project-meeting") {
                                this.setState({
                                    reportData: []
                                })
                                this.todayPMBookedReport.forEach(report => {
                                    if (user.user_name === report.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name

                                            });
                                        }


                                    }
                                })

                            }
                            else if (this.hcPmScheduleType === "vc") {
                                this.setState({
                                    reportData: []
                                })
                                this.todayVCCompletedReport.forEach(report => {
                                    if (user.user_name === report.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time


                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time


                                            });
                                        }
                                    }



                                })
                            }
                        }

                        else if (type === "Yesterday") {
                            if (this.hcPmScheduleType === 'hc') {
                                this.setState({
                                    reportData: []
                                })
                                this.yesterdayHCBookedReport.forEach(yesterdayHCData => {
                                    if (user.user_name === yesterdayHCData.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner,
                                                client_name: yesterdayHCData.user_name,
                                                hc_date_time: yesterdayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: yesterdayHCData.preferred_area,
                                                hc_completed_date_time: yesterdayHCData.hc_completed_date_time


                                            });
                                        }
                                        else if (this.currentCity === '') {

                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner,
                                                client_name: yesterdayHCData.user_name,
                                                hc_date_time: yesterdayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: yesterdayHCData.preferred_area,
                                                hc_completed_date_time: yesterdayHCData.hc_completed_date_time

                                            });
                                        }



                                    }
                                });
                            }
                            else if (this.hcPmScheduleType === "project-meeting") {
                                this.setState({
                                    reportData: []
                                })
                                this.yesterdayPMBookedReport.forEach(report => {
                                    if (user.user_name === report.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {


                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name



                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name

                                            });
                                        }

                                    }
                                })

                            }
                            else if (this.hcPmScheduleType === "vc") {
                                this.setState({
                                    reportData: []
                                })
                                this.yesterdayVCCompletedReport.forEach(report => {
                                    if (user.user_name === report.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time


                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time


                                            });
                                        }

                                    }

                                })
                            }

                        }
                        else if (type === "Last 7 Days") {
                            this.setState({
                                reportData: []
                            })
                            if (this.hcPmScheduleType === 'hc') {
                                this.weekHCBookedReport.forEach(weekyHCData => {
                                    if (user.user_name === weekyHCData.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: weekyHCData.lead_owner,
                                                client_name: weekyHCData.user_name,
                                                hc_date_time: weekyHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: weekyHCData.preferred_area,
                                                hc_completed_date_time: weekyHCData.hc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: weekyHCData.lead_owner,
                                                client_name: weekyHCData.user_name,
                                                hc_date_time: weekyHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: weekyHCData.preferred_area,
                                                hc_completed_date_time: weekyHCData.hc_completed_date_time

                                            });
                                        }


                                    }

                                });
                            }
                            else if (this.hcPmScheduleType === "project-meeting") {
                                this.setState({
                                    reportData: []
                                })
                                this.weekPMBookedReport.forEach(report => {
                                    if (user.user_name === report.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name

                                            });
                                        }

                                    }
                                })

                            }
                            else if (this.hcPmScheduleType === "vc") {
                                this.setState({
                                    reportData: []
                                })
                                this.weekVCCompletedReport.forEach(report => {
                                    if (user.user_name === report.lead_owner) {

                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }

                                    }

                                })
                            }
                        }

                    }
                }

            })

            this.setState({ reportData: hc_report })
        }
        else if (viewType === 'patch-report') {
            let users = this.props.PnkStore.GetData('userlist').rows;
            let total_patch_count_today = 0;
            let total_patch_count_yesterday = 0;
            let total_patch_count_week = 0;
            let total_patch_count_month = 0;
            let tele_assignee = []
            let patch_report = [];
            if (type === "Today") {
                this.todayPatchData.forEach(report => {
                    if (report.patch_date_time !== '' && report.patch_date_time !== undefined)
                        tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.todayAllData.forEach(report => {
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.todayDate)) {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (report.tele_assignee === tele_assignee) {
                                    let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                    if (typeIndex !== -1) {
                                        total_patch_count_today++
                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count_today++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count_today++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count_today++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }



                                }
                            }
                        }

                    })

                })

            }
            if (type === "Yesterday") {
                this.yesterdayPatchData.forEach(report => {
                    if (report.patch_date_time !== '' && report.patch_date_time !== undefined)
                        tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.yesterdayAllData.forEach(report => {
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time.includes(this.state.yesterdayDate)) {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (report.tele_assignee === tele_assignee) {
                                    let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }



                                }
                            }
                        }

                    })

                })

            }
            if (type === "Last 7 Days") {
                this.weekPatchData.forEach(report => {
                    if (report.patch_date_time !== '' && report.patch_date_time !== undefined)
                        tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.weekAllData.forEach(report => {
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.weekDate) {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (report.tele_assignee === tele_assignee) {
                                    let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }



                                }
                            }
                        }

                    })

                })

            }
            if (type === 'Last 30 Days' || type === 'Custom') {
                this.monthPatchData.forEach(report => {
                    if (report.patch_date_time !== '' && report.patch_date_time !== undefined)
                        tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.monthAllData.forEach(report => {
                        if (report.patch_date_time !== undefined && report.patch_date_time !== '' && report.patch_date_time <= this.state.todayDate && report.patch_date_time >= this.state.monthDate) {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (report.tele_assignee === tele_assignee) {
                                    let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }



                                }
                            }
                        }

                    })

                })

            }

            this.setState({ reportData: patch_report, total_patch_count_today: total_patch_count_today })
        }
        else if (viewType === "followupreport") {

            let follow_up_report = [];
            if (type === "Today") {
                follow_up_report = []
                this.todayFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });
                            }

                        }


                    }
                    else if (this.currentCity === '') {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });

                            }
                        }

                    }
                })
            }
            else if (type === "Yesterday") {
                follow_up_report = []
                this.yesterdayFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });
                            }

                        }


                    }
                    else if (this.currentCity === '') {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });

                            }
                        }

                    }
                })
            }
            if (type === "Last 7 Days") {
                follow_up_report = []
                this.weekFollowupReport.forEach(report => {

                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {


                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });
                            }

                        }


                    }
                    else if (this.currentCity === '') {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {

                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });

                            }
                        }

                    }
                })
            }
            else if (type === 'Last 30 Days' || type === 'Custom') {
                follow_up_report = []
                this.monthFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });
                            }

                        }


                    }
                    else if (this.currentCity === '') {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                                follow_up_report.push({
                                    lead_owner: report.lead_owner,
                                    client_name: report.user_name,
                                    follow_up_date_time: report.follow_up_date_time,
                                    client_mobile: report.user_mobile_no,
                                    id: report._id

                                });

                            }
                        }

                    }
                })
            }
            follow_up_report.sort((a, b) => new Date(b.follow_up_date_time).getTime() - new Date(a.follow_up_date_time).getTime());

            this.setState({
                reportData: follow_up_report
            })
        }
        else if (viewType === "details") {

            let name = '', caller = '', source = '', number = '', stage = '', lead_acquisition_date = '', last_updated = '', remarks = '', lead_patched_date;
            if (type === "Today") {
                current_details = []
                this.todayAllData.forEach(todayData => {

                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                            // }
                        }
                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.patch_date_time!==''){

                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                            // }
                        }
                    }
                })


            }
            else if (type === "Yesterday") {
                current_details = []
                this.yesterdayPatchSalesReport.forEach(todayData => {
                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                            // }
                        }
                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                        }
                    }
                    // }
                })

            }
            else if (type === 'Last 7 Days') {
                current_details = []
                this.weekPatchSalesReport.forEach(todayData => {
                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                        }
                        // }
                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                        }
                    }
                    // }
                })

            }

            else if (type === 'Last 30 Days' || type === 'Custom') {
                this.monthPatchSalesReport.forEach(todayData => {
                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                        }
                        // }
                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            // if(todayData.lead_patched_date!==''){
                            current_details.push({
                                lead_patched_date: todayData.patch_date_time,
                                last_edited: todayData.updated_on,
                                client_name: todayData.user_name,
                                client_number: todayData.user_mobile_no,
                                lead_source: todayData.lead_source,
                                lead_stage: todayData.lead_current_stage,
                                lead_city: todayData.lead_city,
                                reporting_to: ''
                            })
                        }
                        // }
                    }
                })

            }
            this.setState({ reportData: current_details })
        }
        else if (viewType === 'HCPMSchedule') {

            let hc_time_list = []
            if (type === "Today") {
                if (this.hcPmScheduleType === 'hc') {
                    this.todayAllData.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }

                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });

                                }
                            }

                        }
                    })
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.todayAllData.forEach(todayData => {

                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }

                        }
                    })

                }
            }
            else if (type === "Yesterday") {
                if (this.hcPmScheduleType === 'hc') {
                    this.yesterdayAllData.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }

                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });

                                }
                            }

                        }
                    })
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.yesterdayAllData.forEach(todayData => {

                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }

                        }
                    })

                }

            }
            else if (type === "Last 7 Days") {
                if (this.hcPmScheduleType === 'hc') {
                    this.weekAllData.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }

                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });

                                }
                            }

                        }
                    })
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.weekAllData.forEach(todayData => {

                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }

                        }
                    })

                }
            }
            else if (type === "Last 30 Days" || type === "Custom") {
                if (this.hcPmScheduleType === 'hc') {
                    this.monthAllData.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }

                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {

                                    hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });

                                }
                            }

                        }
                    })
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.monthAllData.forEach(todayData => {

                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }


                        }
                        else if (this.currentCity === '') {
                            if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                                if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                    hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                                }
                            }

                        }
                    })

                }
            }

            hc_time_list.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

            this.setState({ reportData: hc_time_list })
        }

    }
    RenderCustomReport = (viewtype) => {

        let patch_date_time_data = [];
        let lead_generate_data = []
        let dead_lead_data = []
        let pending_lead_data = []
        let sales_report = [];
        let customAllData = []
        let total_in_progress = 0, total_hc_booked = 0, total_hc_completed = 0, total_design_handovered = 0, total_proposal_sent = 0, total_proposal_uploaded = 0, total_booking_amount_received = 0;

        this.customData.forEach((element, i) => {
            if (element.lead_department[0].profile_name.includes("Sales")) {

                if ((element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate)
                    || (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate)
                    || (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate)
                    || (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.customEndDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.customStartDate)
                    || (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.customEndDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.customStartDate)
                    || (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.customEndDate + ' 23:59:59' && element.proposal_uploaded_date_time >= this.state.customStartDate)
                    || (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate)
                    || (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.customEndDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.customStartDate)) {
                    customAllData.push(element)
                }
                // if (element.date_time !== undefined && element.date_time <= this.state.customEndDate && element.date_time >= this.state.customStartDate) {
                //     lead_generate_data.push(element)
                // }
                // if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.customEndDate && element.lead_dead_date >= this.state.customStartDate) {
                //     dead_lead_data.push(element)
                // }
                // if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate && element.date_time >= this.state.customStartDate) {
                //     pending_lead_data.push(element)
                // }
            }
        })
        if (viewtype === "default") {

            customAllData.forEach(report => {
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (report.lead_current_stage === "In Progress" && (report.patch_date_time !== ''))
                            total_in_progress++
                        if (report.lead_current_stage === "HC Booked" && (report.hc_booked_date_time !== ''))
                            total_hc_booked++
                        if (report.lead_current_stage === "HC Completed" && (report.hc_completed_date_time !== ''))
                            total_hc_completed++
                        if (report.lead_current_stage === "Handover to Designer" && (report.handover_to_designer_date_time !== ''))
                            total_design_handovered++
                        if (report.lead_current_stage === "Proposal Sent" && (report.proposal_sent_date_time !== ''))
                            total_proposal_sent++
                        if (report.lead_current_stage === "Design Uploaded" && (report.proposal_uploaded_date_time !== ''))
                            total_proposal_uploaded++
                        if (report.lead_current_stage === "Booking Amount Received" && (report.booking_amount_received_date_time !== ''))
                            total_booking_amount_received++
                    }
                }
                else if (this.currentCity === '') {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (report.lead_current_stage === "In Progress" && (report.patch_date_time !== ''))
                            total_in_progress++
                        if (report.lead_current_stage === "HC Booked" && (report.hc_booked_date_time !== ''))
                            total_hc_booked++
                        if (report.lead_current_stage === "HC Completed" && (report.hc_completed_date_time !== ''))
                            total_hc_completed++
                        if (report.lead_current_stage === "Handover to Designer" && (report.handover_to_designer_date_time !== ''))
                            total_design_handovered++
                        if (report.lead_current_stage === "Proposal Sent" && (report.proposal_sent_date_time !== ''))
                            total_proposal_sent++
                        if (report.lead_current_stage === "Design Uploaded" && (report.proposal_uploaded_date_time !== ''))
                            total_proposal_uploaded++
                        if (report.lead_current_stage === "Booking Amount Received" && (report.booking_amount_received_date_time !== ''))
                            total_booking_amount_received++
                    }
                }
            })


            if (total_in_progress > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_design_handovered > 0 || total_proposal_sent > 0 || total_proposal_uploaded > 0 || total_booking_amount_received > 0) {
                sales_report.push({
                    total_in_progress: total_in_progress, total_hc_booked: total_hc_booked,
                    total_hc_completed: total_hc_completed, total_design_handovered: total_design_handovered,
                    total_proposal_sent: total_proposal_sent, total_proposal_uploaded: total_proposal_uploaded,
                    total_booking_amount_received: total_booking_amount_received
                })
            }
            this.setState({ reportData: sales_report })
        }
        else if (viewtype === "under-construction") {
            let under_const_data = [], under_const_arr = [];
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.customEndDate + ' 23:59:59' && element.under_const_date_time >= this.state.customStartDate) {
                            under_const_data.push(element)
                        }
                    }
                }
            })
            console.log("under_const_data", under_const_data)
            under_const_data.forEach(report => {

                console.log("custome", report)

                under_const_arr.push({
                    lead_owner: report.lead_owner,
                    client_name: report.user_name,
                    under_const_date_time: report.under_const_date_time,
                    client_mobile: report.user_mobile_no,
                    id: report._id,
                    date_time: report.date_time,
                    update_on: report.updated_on



                });


            });
            this.setState({
                reportData: under_const_arr
            })
        }
        else if (viewtype === 'Meeting-Tracker') {
            let hc_reports = [];
            let hc_datetime_report = []
            let pm_date_time_report = []
            let vc_date_time_report = []
            let users = this.props.PnkStore.GetData('userlist').rows;
            // console.log("this.customData",this.customData)
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                        hc_datetime_report.push(element)
                    }
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate) {
                        pm_date_time_report.push(element)
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                        vc_date_time_report.push(element)
                    }
                }


            })
            // console.log("hc_datetime_report",hc_datetime_report)
            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {

                    if (user.user_name === sessionStorage.getItem('current_user_name')) {
                        if (this.hcPmScheduleType === 'hc') {

                            hc_datetime_report.forEach(customHcData => {

                                if (customHcData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === customHcData.lead_city)) {

                                        hc_reports.push({
                                            client_name: customHcData.user_name,
                                            hc_date_time: customHcData.hc_booked_date_time,
                                            lead_owner: customHcData.lead_owner,
                                            sales_tl: user.reporting_to,
                                            location: customHcData.preferred_area,
                                            hc_completed_date_time: customHcData.hc_completed_date_time

                                        })


                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            client_name: customHcData.user_name,
                                            hc_date_time: customHcData.hc_booked_date_time,
                                            lead_owner: customHcData.lead_owner,
                                            sales_tl: user.reporting_to,
                                            location: customHcData.preferred_area,
                                            hc_completed_date_time: customHcData.hc_completed_date_time
                                        })
                                    }
                                }



                            });
                        }
                        if (this.hcPmScheduleType === 'project-meeting') {
                            pm_date_time_report.forEach(report => {
                                if (user.user_name === report.lead_owner) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            project_estimate: report.project_estimate,
                                            hc_completed_date_time: report.hc_completed_date_time,
                                            project_meeting_date_time: report.project_meeting_date_time,
                                            designer_name: report.designer_name

                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            project_estimate: report.project_estimate,
                                            hc_completed_date_time: report.hc_completed_date_time,
                                            project_meeting_date_time: report.project_meeting_date_time,
                                            designer_name: report.designer_name

                                        });
                                    }

                                }
                            })
                        }

                        if (this.hcPmScheduleType === "vc") {
                            vc_date_time_report.forEach(report => {
                                if (user.user_name === report.lead_owner) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            location: report.preferred_area,
                                            vc_completed_date_time: report.vc_completed_date_time


                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            location: report.preferred_area,
                                            vc_completed_date_time: report.vc_completed_date_time


                                        });
                                    }



                                }

                            })
                        }

                    }
                }
            })
            this.setState({
                reportData: hc_reports
            })

        }
        else if (viewtype === 'patch-report') {
            let patch_data_report = []
            let tele_assignee = []
            let patch_report = []

            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                        patch_data_report.push(element)
                    }
                }

            })
            patch_data_report.forEach(report => {
                tele_assignee.push(report.tele_assignee)
            })
            let unique_tele_assignee = [...new Set(tele_assignee)];
            unique_tele_assignee.map(tele_assignee => {
                patch_data_report.forEach(report => {
                    if (report.tele_assignee === tele_assignee) {
                        let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);

                        if (typeIndex !== -1) {

                            let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                            if (valIndex === -1) {
                                patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                            }
                            else {
                                let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                            }

                        }
                        else {
                            patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                        }
                    }

                })

            })
            this.setState({ reportData: patch_report })
        }
        else if (viewtype === 'followupreport') {
            let follow_up_report = [];
            let follow_up_date_time_report = []
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {

                    if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.customEndDate + ' 23:59:59' && element.follow_up_date_time >= this.state.customStartDate) {
                        follow_up_date_time_report.push(element)
                    }
                }
            })


            follow_up_date_time_report.forEach(report => {

                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    follow_up_report.push({
                        lead_owner: report.lead_owner,
                        client_name: report.user_name,
                        follow_up_date_time: report.follow_up_date_time,
                        client_mobile: report.user_mobile_no,
                        id: report._id

                    });
                }
                else if (this.currentCity === '') {

                    follow_up_report.push({
                        lead_owner: report.lead_owner,
                        client_name: report.user_name,
                        follow_up_date_time: report.follow_up_date_time,
                        client_mobile: report.user_mobile_no,
                        id: report._id

                    });
                }



            })
            follow_up_report.sort((a, b) => new Date(b.follow_up_date_time).getTime() - new Date(a.follow_up_date_time).getTime());
            this.setState({
                reportData: follow_up_report
            })
        }
        else if (viewtype === "details") {
            let current_details = []

            customAllData.forEach(patchData => {
                if (this.currentCity !== '' && (this.currentCity === patchData.lead_city)) {
                    if (patchData.lead_owner === sessionStorage.getItem('current_user_name')) {
                        // if(todayData.lead_patched_date!==''){
                        current_details.push({
                            lead_patched_date: patchData.patch_date_time,
                            last_edited: patchData.updated_on,
                            client_name: patchData.user_name,
                            client_number: patchData.user_mobile_no,
                            lead_source: patchData.lead_source,
                            lead_stage: patchData.lead_current_stage,
                            lead_city: patchData.lead_city,
                            reporting_to: ''
                        })
                    }
                }
                else if (this.currentCity === '') {
                    if (patchData.lead_owner === sessionStorage.getItem('current_user_name')) {
                        // if(todayData.lead_patched_date!==''){
                        current_details.push({
                            lead_patched_date: patchData.patch_date_time,
                            last_edited: patchData.updated_on,
                            client_name: patchData.user_name,
                            client_number: patchData.user_mobile_no,
                            lead_source: patchData.lead_source,
                            lead_stage: patchData.lead_current_stage,
                            lead_city: patchData.lead_city,
                            reporting_to: ''
                        })
                    }
                }
            })

            this.setState({ reportData: current_details })
        }
        else if (viewtype === "HCPMSchedule") {
            let hc_time_list = []
            if (this.hcPmScheduleType === 'hc') {
                customAllData.forEach(todayData => {

                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                            }

                        }


                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {

                                hc_time_list.push({ 'time': todayData.hc_booked_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });

                            }
                        }

                    }
                })
            }
            else if (this.hcPmScheduleType === "project-meeting") {
                customAllData.forEach(todayData => {

                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                            if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                            }
                        }


                    }
                    else if (this.currentCity === '') {
                        if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {

                            if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                hc_time_list.push({ 'time': todayData.project_meeting_date_time, 'user_name': todayData.user_name, 'user_contact': todayData.user_mobile_no, 'user_email': todayData.user_email, id: todayData._id });
                            }
                        }

                    }
                })

            }

            hc_time_list.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

            this.setState({ reportData: hc_time_list })
        }

    }
    ChangeSalesReportType = (type) => {
        this.setState({
            currentSalesReportType: type,
            hcPmScheduleType: 'hc'
        })
        if (this.state.currentViewType !== 'callback')
            this.RenderReport(this.state.currentViewType, type)
        else
            this.RenderCallbackReport(this.callbackReportData, type)
    }
    ChangeSalesReportTypeMobile = (event) => {
        this.setState({
            currentSalesReportType: event.target.value
        })

        if (event.target.value !== "Custom") {
            this.setState({ showCustomDateModal: false })
            this.RenderReport(this.state.currentViewType, event.target.value)
        }
        else if (event.target.value === "Custom") {
            this.setState({ showCustomDateModal: true })
            // this.RenderCustomReport(this.state.currentViewType)
        }
    }
    HideCustomDateModal = () => {
        this.setState({ showCustomDateModal: false })
    }
    HandleGetUserListResponse = (res) => {
        if (res.data && res.data.userlist && res.data.userlist) {
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({ getUser: false })
    }


    GetCustomData = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        if (this.state.currentViewType === 'callback') {
            this.setState({
                customStartDate: startDate,
                customEndDate: endDate,
                getCustomCallbackData: true
            })

        }
        else {
            this.setState({
                customStartDate: startDate,
                customEndDate: endDate, getCustomData: true,
                ddlPreferredCity: '-1'
            })
        }
        this.currentCity = ''
    }
    HandleGetCustomDatatResponse = (res) => {
        if (res !== false) {

            if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.customData = res.data.adminfirstmonthdata.rows
                this.RenderCustomReport(this.state.currentViewType)
            }
        }
        this.setState({
            getCustomData: false,
            showCustomDateModal: false
        })
    }
    HandleMeetingType = (type) => {
        this.setState({
            hcPmScheduleType: type,
            // currentSalesReportType:'Today'

        })
        this.hcPmScheduleType = type;
        this.RenderReport("meeting-tracker", 'Today')
    }
    ChangeViewReport = (event) => {
        console.log("adminReportAll", this.adminReportAll)
        this.setState({ currentViewType: event.target.value, currentSalesReportType: 'Today' })
        if (event.target.name === 'currentViewType') {
            if (event.target.value === 'callback') {
                this.setState({ getCallbackData: true })
            }
            else if (event.target.value === 'Meeting-Tracker') {
                this.setState({
                    getCallbackData: false,
                    getMeetingData: true, reportData: []
                })
            }
            else if (this.adminReportAll.length < 0) {
                this.setState({
                    getReportData: true
                })
            }

        }
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport(event.target.value, 'Today')
        }
        else if (this.state.currentSalesReportType === "Custom") {
            this.RenderCustomReport(event.target.value)
        }

        // window.location.href= window.location.protocol + '//' + window.location.host+'/dashboard/'+event.target.value;
        if (window.location.href.indexOf("/dashboard/") > -1)
            this.props.componentprops.history.push(event.target.value);
        else
            this.props.componentprops.history.push('dashboard/' + event.target.value);

    }
    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    HandleGetCallbackReportResponse = (res) => {

        if (res !== false) {
            if (res.data && res.data.callbackleads && res.data.callbackleads.rows && res.data.callbackleads.rows.length > 0) {
                this.callbackReportData = res.data.callbackleads;

                this.RenderCallbackReport(res.data.callbackleads, this.state.currentSalesReportType)

            }
        }
        this.setState({ getCallbackData: false, getCustomCallbackData: false })
    }

    RenderCallbackReport = (data, type) => {
        var callbackReportData = []
        if (type === 'Today') {
            data.rows.forEach((element, i) => {

                if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.todayDate)) {
                    if (element.lead_department[0].profile_name.includes('Sales')) {
                        if (element.lead_owner === sessionStorage.getItem('current_user_name')) {
                            callbackReportData.push({
                                acquisitionDate: element.date_time,
                                updatedOn: element.updated_on,
                                customerName: element.user_name,
                                customerContact: element.user_mobile_no,
                                customerEmail: element.user_email,
                                callbackDateTime: element.callback_date_time,
                                leadOwner: element.lead_owner,
                                id: element._id
                            })
                        }
                    }
                }

            })
        }
        if (type === 'Coming Days') {
            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time > this.state.todayDate + ' 23:59:59') {
                    if (element.lead_department[0].profile_name.includes('Sales')) {
                        if (element.lead_owner === sessionStorage.getItem('current_user_name')) {
                            callbackReportData.push({
                                acquisitionDate: element.date_time,
                                updatedOn: element.updated_on,
                                customerName: element.user_name,
                                customerContact: element.user_mobile_no,
                                customerEmail: element.user_email,
                                callbackDateTime: element.callback_date_time,
                                leadOwner: element.lead_owner,
                                id: element._id
                            })
                        }
                    }
                }
            })
        }
        if (type === 'Custom') {
            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate + ' 23:59:59' && element.callback_date_time >= this.state.customStartDate) {
                    if (element.lead_department[0].profile_name.includes('Sales')) {
                        if (element.lead_owner === sessionStorage.getItem('current_user_name')) {
                            callbackReportData.push({
                                acquisitionDate: element.date_time,
                                updatedOn: element.updated_on,
                                customerName: element.user_name,
                                customerContact: element.user_mobile_no,
                                customerEmail: element.user_email,
                                callbackDateTime: element.callback_date_time,
                                leadOwner: element.lead_owner,
                                id: element._id
                            })
                        }
                    }
                }
            })
        }


        callbackReportData.sort((a, b) => new Date(a.callbackDateTime).getTime() - new Date(b.callbackDateTime).getTime());

        this.setState({
            callbackReportData: callbackReportData
        })
    }
    GotoLeadEntry = (lead_id) => {

        AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/' + lead_id + '/' + this.state.currentViewType);
        // AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/'+lead_id);
    }
    PatchDetailedReport = (details) => {
        let allowedLeadStages = [];
        if (sessionStorage.getItem('current_user_allowed_lead_stages')) {
            allowedLeadStages = sessionStorage.getItem('current_user_allowed_lead_stages');
            allowedLeadStages = allowedLeadStages.split(',');
        }


        this.setState({
            patchView: 'details',
            patchDetailReport: details,
            profileStageData: allowedLeadStages
        })

    }
    PatchReportDefaultView = () => {
        this.setState({
            patchView: 'default',
            patchDetailReport: []
        })
    }
    // GotoLeadEntry=(lead_id)=>{

    //     AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/'+lead_id);
    // }
    GetMeetingCustomData = () => {
        var dd = String(new Date(this.state.txtCustomMeetingStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomMeetingStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomMeetingStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomMeetingEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomMeetingEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomMeetingEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        this.setState({
            meetingWeekStartDate: startDate,
            meetingWeekEndDate: endDate,
            getMeetingData: true
        })

    }
    HandleGetMeetingDatatResponse = (res) => {

        let meetingArray = []
        if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
            res.data.adminfirstmonthdata.rows.forEach(element => {
                if (element.lead_current_stage === "HC Booked" && element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate
                    ||
                    element.lead_current_stage === "Project Meeting" && element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate
                ) {
                    // console.log("", element.hc_booked_date_time, element.project_meeting_date_time)
                    if (element.lead_current_stage === "HC Booked" && element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate) {
                        let typeIndex = meetingArray.findIndex(type => element.hc_booked_date_time.substr(0, 10) === type.date_time);
                        if (typeIndex !== -1) {
                            let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                            if (valIndex === -1) {
                                meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                            }
                            else {
                                meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                            }
                        }
                        else {
                            meetingArray.push({ 'date_time': element.hc_booked_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                        }
                    }
                    else if (element.lead_current_stage === "Project Meeting" && element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate) {
                        let typeIndex = meetingArray.findIndex(type => element.project_meeting_date_time.substr(0, 10) === type.date_time);
                        if (typeIndex !== -1) {
                            let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                            if (valIndex === -1) {
                                meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                            }
                            else {
                                meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                            }
                        }
                        else {
                            meetingArray.push({ 'date_time': element.project_meeting_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                        }
                    }

                }
            })
        }
        // console.log("meetingArray", meetingArray)
        meetingArray.sort((a, b) => new Date(b.date_time).getTime() - new Date(a.date_time).getTime());

        this.setState({
            getMeetingData: false,
            isOptionDisabled: false,
            reportData: meetingArray
        })
    }
    HandleGetMeetingDatatResponse1 = (res) => {
        // console.log("HandleGetMeetingDatatResponse",res.data)
        var users = this.props.PnkStore.GetData('userlist').rows;
        let meetingArray = []
        if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')
                ) {
                    if (user.user_name === sessionStorage.getItem('current_user_name')) {
                        console.log("user_name", user.user_name)
                        res.data.adminfirstmonthdata.rows.forEach(element => {

                            if (element.lead_current_stage === "HC Booked" && element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate
                                ||
                                element.lead_current_stage === "Project Meeting" && element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate
                            ) {
                                if (user.user_name === element.lead_owner) {
                                    console.log("", element.hc_booked_date_time, element.project_meeting_date_time)
                                    if (element.lead_current_stage === "HC Booked" && element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate) {
                                        let typeIndex = meetingArray.findIndex(type => element.hc_booked_date_time.substr(0, 10) === type.date_time);
                                        if (typeIndex !== -1) {
                                            let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                                            if (valIndex === -1) {
                                                meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                                            }
                                            else {
                                                meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                                            }
                                        }
                                        else {
                                            meetingArray.push({ 'date_time': element.hc_booked_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                                        }
                                    }
                                    if (element.lead_current_stage === "Project Meeting" && element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate) {
                                        let typeIndex = meetingArray.findIndex(type => element.project_meeting_date_time.substr(0, 10) === type.date_time);
                                        if (typeIndex !== -1) {
                                            let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                                            if (valIndex === -1) {
                                                meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                                            }
                                            else {
                                                meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                                            }
                                        }
                                        else {
                                            meetingArray.push({ 'date_time': element.project_meeting_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                                        }
                                    }

                                }
                            }
                        })
                    }
                }
            })

        }
        console.log("meetingArray", meetingArray)
        meetingArray.sort((a, b) => new Date(b.date_time).getTime() - new Date(a.date_time).getTime());

        this.setState({
            getMeetingData: false,
            reportData: meetingArray
        })
    }
    render() {

        return (
            <Container fluid>
                <div className="tele-caller-db-head">Sales Dashboard</div>
                <Options mystate={this.state} {...this.props} HandleUserInput={this.HandleUserInput}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    ChangeViewReport={this.ChangeViewReport}
                    ChangeSalesReportTypeMobile={this.ChangeSalesReportTypeMobile}
                    HandleHCSchedule={this.HandleHCSchedule}
                    PatchReportDefaultView={this.PatchReportDefaultView}
                    HandleMeetingType={this.HandleMeetingType}
                />
                {this.state.currentViewType === "default" || this.state.currentViewType === "details" || this.state.currentViewType === 'followupreport' || this.state.currentViewType === 'HCPMSchedule' ?
                    <Report mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        GotoLeadEntry={this.GotoLeadEntry}

                    /> : null}
                {this.state.showCustomDateModal === true ?
                    <MobCustomDatePicker
                        visible={this.state.showCustomDateModal}
                        mystate={this.state}
                        {...this.props}
                        HandleUserInput={this.HandleUserInput}
                        HideCustomDateModal={this.HideCustomDateModal}
                        GetCustomData={this.GetCustomData}
                    /> : null
                }
                {this.state.currentViewType === "callback" ?
                    <CallbackReport
                        mystate={this.state} {...this.props}
                        callBackReportType={this.callBackReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        GotoLeadEntry={this.GotoLeadEntry}
                    />
                    : null
                }
                {this.state.currentViewType === "patch-report" ?
                    <PatchReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        // ShowPatchDetails={this.ShowPatchDetails}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        PatchDetailedReport={this.PatchDetailedReport}
                    />
                    : null
                }
                {this.state.currentViewType === "under-construction" ?
                    <UnderConstructionComp
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        GotoLeadEntry={this.GotoLeadEntry}
                    />
                    : null
                }
                {this.state.currentViewType === "meeting-tracker" ?
                    <MeetingTracker
                        GotoLeadEntry={this.GotoLeadEntry}
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetMeetingCustomData={this.GetMeetingCustomData} /> : null}

                {this.state.getUser === true ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : ''}
                {(this.state.getReportData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetReportResponse) : ''}
                {(this.state.getCustomData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomDatatResponse) : ''}
                {((this.props.PnkStore.GetData("citylist") === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {(this.state.getCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetCallbackReportResponse) : ''}
                {(this.state.getCustomCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCallbackReportResponse) : ''}
                {(this.state.getMeetingData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.meetingWeekStartDate, this.state.meetingWeekEndDate, this.HandleGetMeetingDatatResponse) : ''}

                {/*     {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
               

                {((this.state.getLeadData === true) ? GetLeadSource(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")} */}
            </Container>
        )
    }
}
export const SalesReportPage = PnkConnect(reportPage, "");