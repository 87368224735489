import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Form, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {
      
        this.props.SortData(e, e.target.value)
    }
    render() {
      

        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentViewType'
                                    id='currentViewType'
                                    value={this.props.mystate.currentViewType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeViewReport(e)}>
                                    <option value="default">Default</option>
                                    <option value="details">Details</option>
                                    <option value="callback">Callback</option>
                                    {/* <option value="HCPMSchedule">HC-PM Schedule Report</option> */}
                                    <option value="followupreport">Followup Report</option>
                                    <option value="patch-report">Patch Report</option>
                                    <option value="meeting-tracker" >Meeting Tracker</option>
                                    <option value="under-construction">Under Construction Report</option>

                                </Form.Control>
                            </Col>
                            {/* {this.props.mystate.currentViewType === "HCPMSchedule" ?

<Col lg={1} xs={2} className="flex-left col-padding-0">
    {this.props.mystate.hcPmScheduleType === "hc" ?
        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('hc')}>HC</Button> :
        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('hc')}>HC</Button>}


</Col> : null} */}
{/* {this.props.mystate.currentViewType === "HCPMSchedule" ?
<Col lg={2} xs={4} className="flex-left col-padding-0">
    {this.props.mystate.hcPmScheduleType === "project-meeting" ?
        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('project-meeting')}>Project Meeting</Button> :
        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('project-meeting')}>Project Meeting</Button>
    }

</Col> : null} */}
                            
                            {this.props.mystate.currentViewType!=="under-construction" && this.props.mystate.currentViewType!=="callback" && this.props.mystate.currentViewType !== "patch-report" && this.props.mystate.currentViewType!=='meeting-tracker'?
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0">


                                <Form.Control as="select" className="dashboard-option-select"
                                    name='ddlPreferredCity'
                                    id='ddlPreferredCity'
                                    onChange={this.props.HandleUserInput}
                                    pnkdisplayerrormsg='Select City'
                                    value={this.props.mystate.ddlPreferredCity}
                                    style={{ padding: '4px', width: '100%' }}>
                                    <option value="-1" disabled selected>Select City</option>
                                    <option value=''>No City</option>
                                    {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                        this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                            <option value={item.city_name}>{item.city_name}</option>) : null
                                    }

                                </Form.Control>

                            </Col>:null}
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0 show-mob">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentSalesReportType'
                                    id='currentSalesReportType'
                                    value={this.props.mystate.currentSalesReportType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeSalesReportTypeMobile(e)}>
                                    <option value="" disabled selected>Select</option>
                                    {this.props.salesReportType.map(item =>
                                        <option value={item} >{item}</option>)}
                                </Form.Control>

                            </Col>
                            {this.props.mystate.currentViewType ==='patch-report' && this.props.mystate.patchView==='details'?
                             <Col lg={2} md={3} xs={3} className="flex-left col-padding-0">
                        <button className='btn btn-secondary' onClick={()=>this.props.PatchReportDefaultView()}>Go Back</button></Col>    :null
                        }
                          {this.props.mystate.currentViewType ==='patch-report' && this.props.mystate.patchView==='details'?
                        
                          <Col lg={2} md={3} xs={3} className="flex-left col-padding-0">
                          <Form.Control as="select" className="dashboard-option-select"
                              name='ddlCurrentLeadStage'
                              id='ddlCurrentLeadStage'
                              onChange={this.props.HandleUserInput}
                              value={this.props.mystate.ddlCurrentLeadStage}
                              style={{ padding: '4px', width: '100%' }}>
                              <option value="" disabled selected>Lead Stages</option>
                              {this.props.mystate.profileStageData && 
                                  this.props.mystate.profileStageData.length > 0
                                  ? this.props.mystate.profileStageData.map(
                                      (item, i) => (
                                          
                                              <option value={item} >{item}</option> 
                                      )) : ""}
                          </Form.Control>

                      </Col>
                           :null
                        }


                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}