import React, { Component } from 'react';
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';

export default class UserChangePasswordComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="main-content">
                {/*<div id="page-wrapper">*/}
                <div id="">
                    <div class="main-page login-page">
                        <h2 class="resetpass_title">Change Password</h2>
                        <div class="widget-shadow">
                            <div class="login-body login_change_pwd">
                                <PnkHtmlForm onsubmit={this.props.ChangePassword} submitbtn="btnResetPassword" id="frmResetPassword">
                                    <input type="password" pnkvalidate="forcepassword" pnkdisplayerrormsg="Old Password is Invalid" value={this.props.mystate.txtOldPassword}
                                           onBlur={this.props.HandleUserInput} name="txtOldPassword" id="txtOldPassword" placeholder="Old Password" required="" />
                                    <input type="password" pnkvalidate="forcepassword" pnkdisplayerrormsg="New Password is Invalid" value={this.props.mystate.txtResetPassword}
                                           onBlur={this.props.HandleUserInput} name="txtResetPassword" id="txtResetPassword" placeholder="New Password" required="" />
                                    <input type="password" pnkvalidate="forcepassword" pnkdisplayerrormsg="Confirm New Password is Invalid" value={this.props.mystate.txtCResetPassword}
                                           onBlur={this.props.HandleUserInput} name="txtCResetPassword" id="txtCResetPassword" placeholder="Confirm Password" required="" />
                                    <div class="sub_home login_reset">
                                        <input type="button" value="Update" id="btnResetPassword" onClick={this.props.ChangePassword} className="crmTextWhite" />
                                        <div class="clearfix"> </div>
                                    </div>
                                </PnkHtmlForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

