import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap'
export default class Header extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="footer show-mob">
                <Row className="footer-row">
                    <div style={{ width: '35%', border: 'solid 1px #d6d6d6' }}>
                        <InputGroup className="profile-search-box">
                            <FormControl
                                placeholder="Search by Name, Contact, Email"
                                className="profile-search-box"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2" className="profile-search-text">
                                    <FontAwesomeIcon icon={faSearch} className="profile-search-icon" />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    {/* </Col>
                    <Col xs={7}> */}
                    <div style={{ width: '55%', border: 'solid 1px #d6d6d6' }}>
                        <div class="searchDropdownDiv ">
                            <div class="input-group" style={{ float: "right", height: 38 }} >
                                <div className="input-group-prepend">
                                    <button className="SortByButton" type="button">
                                        Sort By:
        </button>
                                </div>
                                <select name="sortBy" className="selectSearch-profile" id="inputGroupSelect03" aria-label="Example select with button addon" >
                                    <option value="">Sort By</option>
                                    <option selected value="Name">All Lead</option>
                                    <option value="Location">Location</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '10%', border: 'solid 1px #d6d6d6', paddingLeft: 5 }}>
                        {/* </Col>
                    <Col xs={2}> */}
                        <Button className="add-footer" onClick={() => this.props.ShowProfileModal()}><FontAwesomeIcon icon={faPlus} /></Button>
                        {/* </Col> */}
                    </div>
                </Row>
            </div>
        )
    }
}