import React from 'react'
import { Row, Col, Button, Form, Image, OverlayTrigger, Overlay } from 'react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons'
// import Thumbnail from '../../assets/images/thumbnail.jpg'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
// import { vegList, herbList, fruitList, greenList } from './data'
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import "react-datepicker/dist/react-datepicker.css";
// import DateTimePicker from 'date-time-picker'
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Input from '@material-ui/core/Input';
import 'date-time-picker/dist/date-time-picker.min.css'
// import DatePicker from "react-datepicker";
// import TextField from '@material-ui/core/TextField';
// import PnkImage from '../../pnk-react/pnk-ctrl/pnk-image'
// import Exotel_Calls from '../../assets/images/Exotel_Calls.svg'
// import { FormGroup } from '@material-ui/core'

export default class HCForm extends React.Component {
    render() {
        return (
            <div className="lead-entry">
                <div className="lead-entry-main">
                    <div className="crm-container">
                        <PnkHtmlForm id="frmHCEntry" onsubmit={() => this.props.SaveHCForm()} submitbtn="btnHCEntry">
                            <fieldset>
                                <div id="frmLeadEntryActivity">
                                    <div className="le-section" >
                                        <div className="mob-container">
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlPropertType'
                                                            id='ddlPropertType'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Property Type'
                                                            pnkvalue={this.props.editDataObj.ddlPropertType}
                                                            value={this.props.editDataObj.ddlPropertType}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Property Type</option>
                                                            <option value="Villa">Villa</option>
                                                            <option value="Apartment">Apartment</option>
                                                            <option value="Penthouse">Penthouse</option>
                                                            <option value="Office">Office</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Control>

                                                    </Form.Group>
                                                </Col>
                                                {this.props.editDataObj.ddlPropertType === "Other" ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtOtherPropertyType" id="txtOtherPropertyType"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtOtherPropertyType}
                                                                value={this.props.editDataObj.txtOtherPropertyType}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtOtherPropertyType">Other Property Type</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlOwnershipType'
                                                            id='ddlOwnershipType'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Ownership'
                                                            pnkvalue={this.props.editDataObj.ddlOwnershipType}
                                                            value={this.props.editDataObj.ddlOwnershipType}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Ownership</option>
                                                            <option value="Rented">Rented</option>
                                                            <option value="Own">Own</option>
                                                        </Form.Control>

                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlSpaceType" className="le-label-multi">Space</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlSpaceType}
                                                                pnkvalue={this.props.editDataObj.ddlSpaceType}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlSpaceType',
                                                                    id: 'ddlSpaceType',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Space"
                                                            >
                                                                {this.props.spaceList.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.spaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>

                                                </Col>
                                                {/* {this.props.editDataObj!==null && this.props.editDataObj.ddlSpaceType!==null &&  this.props.editDataObj.ddlSpaceType.length>0 && this.props.editDataObj.ddlSpaceType.map(item=>
                                                <React.Fragment> */}
                                                {/* <Col lg={12}>
                                                    <h6> {item}Details</h6>
                                                </Col> */}
                                                {/* <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name={`txt${item}Length`}   id={`txt${item}Length`}
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    // pnkvalue={`${this.props.editDataObj}txt${item}Length`}
                                                                    // value={`${this.props.editDataObj}txt${item}Length`}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for={`txt${item}Length`}>Length(ft)</span>
                                                            </Form.Group>
                                            </Col>*/}
                                                {/* </React.Fragment>
                                                
                                                )}  */}
                                                {this.props.editDataObj.ddlSpaceType.includes("Balcony/Patio") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 className="subHeadHC">Balcony/Patio Details</h6>
                                                        </Col>
                                                        {/* <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtBalconyLength" id="txtBalconyLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtBalconyLength}
                                                                    value={this.props.editDataObj.txtBalconyLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtBalconyLength">Length(ft)</span>
                                                            </Form.Group>
                                            </Col>
                                            <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtBalconyBreadth" id="txtBalconyBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                  
                                                                    pnkvalue={this.props.editDataObj.txtBalconyBreadth}
                                                                    value={this.props.editDataObj.txtBalconyBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtBalconyBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                                            </Col>*/}

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtBalconyTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    //  pnkvalidate="forceentry"
                                                                    //  value={this.props.editDataObj.txtBalconyBreadth * this.props.editDataObj.txtBalconyLength}
                                                                    pnkvalue={this.props.editDataObj.txtBalconyTotal}
                                                                    value={this.props.editDataObj.txtBalconyTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col"> {// space facing of balcony
                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconySpaceFacing" className="le-label-multi">Space Facing</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconySpaceFacing}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconySpaceFacing}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconySpaceFacing',
                                                                            id: 'ddlBalconySpaceFacing',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Space Facing"
                                                                    >
                                                                        {this.props.spaceFaceList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.spaceFaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">{ // Balcony Utilization of Space

                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconyUtilisationSpace" className="le-label-multi">Utilisation of Space</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconyUtilisationSpace}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconyUtilisationSpace}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconyUtilisationSpace',
                                                                            id: 'ddlBalconyUtilisationSpace',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Utilisation of Space"
                                                                    >
                                                                        {this.props.utilisationSpace.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.utilisationSpace.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        {this.props.editDataObj.ddlBalconyUtilisationSpace.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtBalconyOtherUtilSpace" id="txtBalconyOtherUtilSpace"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtBalconyOtherUtilSpace}
                                                                        value={this.props.editDataObj.txtBalconyOtherUtilSpace}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtBalconyOtherUtilSpace">Other Utilisation Space</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconyGardenNeeds" className="le-label-multi">Garden Needs</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconyGardenNeeds}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconyGardenNeeds}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconyGardenNeeds',
                                                                            id: 'ddlBalconyGardenNeeds',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Garden Needs"
                                                                    >
                                                                        {this.props.gardenNeeds.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.gardenNeeds.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlBalconyGardenNeeds.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtBalconyOtherGardenNeeds" id="txtBalconyOtherGardenNeeds"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtBalconyOtherGardenNeeds}
                                                                        value={this.props.editDataObj.txtBalconyOtherGardenNeeds}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtBalconyOtherGardenNeeds">Other Garden Needs</span>
                                                                </Form.Group>
                                                            </Col> : ''}

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconyLookPreferred" className="le-label-multi">Any Type of Look Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconyLookPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconyLookPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconyLookPreferred',
                                                                            id: 'ddlBalconyLookPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Look Preferred"
                                                                    >
                                                                        {this.props.lookPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.lookPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>


                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconyPlantsPreferred" className="le-label-multi">Any Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconyPlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconyPlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconyPlantsPreferred',
                                                                            id: 'ddlBalconyPlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Plants Preferred"
                                                                    >
                                                                        {this.props.plantsPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.plantsPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        {this.props.editDataObj.ddlBalconyPlantsPreferred.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtBalconyOtherPlantPreferred" id="txtOtherPlantPreferred"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtBalconyOtherPlantPreferred}
                                                                        value={this.props.editDataObj.txtBalconyOtherPlantPreferred}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtBalconyOtherPlantPreferred">Other Plants Preferred</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlBalconyAllergicPlant'
                                                                    id='ddlBalconyAllergicPlant'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlBalconyAllergicPlant}
                                                                    value={this.props.editDataObj.ddlBalconyAllergicPlant}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>any plants you or your family (including pets) are allergic to or dislike</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlBalconyAllergicPlant === "Yes" ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtBalconyAllergicPlant" id="txtBalconyAllergicPlant"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtBalconyAllergicPlant}
                                                                        value={this.props.editDataObj.txtBalconyAllergicPlant}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtBalconyAllergicPlant">Specify Alergic/Dislike Plant</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconyColorPalette" className="le-label-multi">Planter Colour Palette</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconyColorPalette}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconyColorPalette}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconyColorPalette',
                                                                            id: 'ddlBalconyColorPalette',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Colour Palette"
                                                                    >
                                                                        {this.props.colorPalette.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.colorPalette.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlBalconyPlanterMaterial" className="le-label-multi">Planter Material</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlBalconyPlanterMaterial}
                                                                        pnkvalue={this.props.editDataObj.ddlBalconyPlanterMaterial}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlBalconyPlanterMaterial',
                                                                            id: 'ddlBalconyPlanterMaterial',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Material"
                                                                    >
                                                                        {this.props.planterMaterial.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.planterMaterial.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>

                                                    </React.Fragment> :
                                                    ''}
                                                {this.props.editDataObj.ddlSpaceType.includes("Terrace") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 className="subHeadHC">Terrace Details</h6>
                                                        </Col>
                                                        {/*<Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtTerraceLength" id="txtTerraceLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtTerraceLength}
                                                                    value={this.props.editDataObj.txtTerraceLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtTerraceLength">Length(ft)</span>
                                                            </Form.Group>
                                            </Col>
                                            <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtTerraceBreadth" id="txtTerraceBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                  
                                                                    pnkvalue={this.props.editDataObj.txtTerraceBreadth}
                                                                    value={this.props.editDataObj.txtTerraceBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtTerraceBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                                        </Col>*/}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtTerraceTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // value={this.props.editDataObj.txtTerraceBreadth * this.props.editDataObj.txtTerraceLength}
                                                                    pnkvalue={this.props.editDataObj.txtTerraceTotal}
                                                                    value={this.props.editDataObj.txtTerraceTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="txtBreadth">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col"> {// space facing of Terrace
                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerraceSpaceFacing" className="le-label-multi">Space Facing</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerraceSpaceFacing}
                                                                        pnkvalue={this.props.editDataObj.ddlTerraceSpaceFacing}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerraceSpaceFacing',
                                                                            id: 'ddlTerraceSpaceFacing',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Space Facing"
                                                                    >
                                                                        {this.props.spaceFaceList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.spaceFaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">{ // Terrace Utilization of Space

                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerraceUtilisationSpace" className="le-label-multi">Utilisation of Space</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerraceUtilisationSpace}
                                                                        pnkvalue={this.props.editDataObj.ddlTerraceUtilisationSpace}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerraceUtilisationSpace',
                                                                            id: 'ddlTerraceUtilisationSpace',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Utilisation of Space"
                                                                    >
                                                                        {this.props.utilisationSpace.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.utilisationSpace.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        {this.props.editDataObj.ddlTerraceUtilisationSpace.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtTerraceOtherUtilSpace" id="txtTerraceOtherUtilSpace"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtTerraceOtherUtilSpace}
                                                                        value={this.props.editDataObj.txtTerraceOtherUtilSpace}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtTerraceOtherUtilSpace">Other Utilisation Space</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerraceGardenNeeds" className="le-label-multi">Garden Needs</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerraceGardenNeeds}
                                                                        pnkvalue={this.props.editDataObj.ddlTerraceGardenNeeds}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerraceGardenNeeds',
                                                                            id: 'ddlTerraceGardenNeeds',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Garden Needs"
                                                                    >
                                                                        {this.props.gardenNeeds.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.gardenNeeds.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlTerraceGardenNeeds.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtTerraceOtherGardenNeeds" id="txtTerraceOtherGardenNeeds"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtTerraceOtherGardenNeeds}
                                                                        value={this.props.editDataObj.txtTerraceOtherGardenNeeds}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtTerraceOtherGardenNeeds">Other Garden Needs</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerraceLookPreferred" className="le-label-multi">Any Type of Look Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerraceLookPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlTerraceLookPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerraceLookPreferred',
                                                                            id: 'ddlTerraceLookPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Look Preferred"
                                                                    >
                                                                        {this.props.lookPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.lookPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerracePlantsPreferred" className="le-label-multi">Any Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerracePlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlTerracePlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerracePlantsPreferred',
                                                                            id: 'ddlTerracePlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Plants Preferred"
                                                                    >
                                                                        {this.props.plantsPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.plantsPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        {this.props.editDataObj.ddlTerracePlantsPreferred.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtTerraceOtherPlantPreferred" id="txtOtherPlantPreferred"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtTerraceOtherPlantPreferred}
                                                                        value={this.props.editDataObj.txtTerraceOtherPlantPreferred}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtTerraceOtherPlantPreferred">Other Plants Preferred</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlTerraceAllergicPlant'
                                                                    id='ddlTerraceAllergicPlant'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceAllergicPlant}
                                                                    value={this.props.editDataObj.ddlTerraceAllergicPlant}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>any plants you or your family (including pets) are allergic to or dislike</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlTerraceAllergicPlant === "Yes" ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtTerraceAllergicPlant" id="txtTerraceAllergicPlant"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtTerraceAllergicPlant}
                                                                        value={this.props.editDataObj.txtTerraceAllergicPlant}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtTerraceAllergicPlant">Specify Alergic/Dislike Plant</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerraceColorPalette" className="le-label-multi">Planter Colour Palette</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerraceColorPalette}
                                                                        pnkvalue={this.props.editDataObj.ddlTerraceColorPalette}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerraceColorPalette',
                                                                            id: 'ddlTerraceColorPalette',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Colour Palette"
                                                                    >
                                                                        {this.props.colorPalette.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.colorPalette.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlTerracePlanterMaterial" className="le-label-multi">Planter Material</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerracePlanterMaterial}
                                                                        pnkvalue={this.props.editDataObj.ddlTerracePlanterMaterial}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerracePlanterMaterial',
                                                                            id: 'ddlTerracePlanterMaterial',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Material"
                                                                    >
                                                                        {this.props.planterMaterial.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.planterMaterial.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                    </React.Fragment> :
                                                    ''}
                                                {this.props.editDataObj.ddlSpaceType.includes("Indoors") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 className="subHeadHC">Indoors Details</h6>
                                                        </Col>
                                                        {/*<Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtIndoorsLength" id="txtIndoorsLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtIndoorsLength}
                                                                    value={this.props.editDataObj.txtIndoorsLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtIndoorsLength">Length(ft)</span>
                                                            </Form.Group>
                                            </Col>
                                            <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtIndoorsBreadth" id="txtIndoorsBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                  
                                                                    pnkvalue={this.props.editDataObj.txtIndoorsBreadth}
                                                                    value={this.props.editDataObj.txtIndoorsBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtIndoorsBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                                    </Col>*/}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtIndoorsTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    //disabled
                                                                    //value={this.props.editDataObj.txtIndoorsBreadth * this.props.editDataObj.txtIndoorsLength}
                                                                    pnkvalue={this.props.editDataObj.txtIndoorsTotal}
                                                                    value={this.props.editDataObj.txtIndoorsTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="txtBreadth">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col"> {// space facing of Indoors
                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsSpaceFacing" className="le-label-multi">Space Facing</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsSpaceFacing}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsSpaceFacing}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsSpaceFacing',
                                                                            id: 'ddlIndoorsSpaceFacing',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Space Facing"
                                                                    >
                                                                        {this.props.spaceFaceList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.spaceFaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">{ // Indoors Utilization of Space

                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsUtilisationSpace" className="le-label-multi">Utilisation of Space</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsUtilisationSpace}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsUtilisationSpace}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsUtilisationSpace',
                                                                            id: 'ddlIndoorsUtilisationSpace',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Utilisation of Space"
                                                                    >
                                                                        {this.props.utilisationSpace.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.utilisationSpace.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        {this.props.editDataObj.ddlIndoorsUtilisationSpace.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtIndoorsOtherUtilSpace" id="txtIndoorsOtherUtilSpace"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtIndoorsOtherUtilSpace}
                                                                        value={this.props.editDataObj.txtIndoorsOtherUtilSpace}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtIndoorsOtherUtilSpace">Other Utilisation Space</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsGardenNeeds" className="le-label-multi">Garden Needs</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsGardenNeeds}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsGardenNeeds}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsGardenNeeds',
                                                                            id: 'ddlIndoorsGardenNeeds',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Garden Needs"
                                                                    >
                                                                        {this.props.gardenNeeds.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.gardenNeeds.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlIndoorsGardenNeeds.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtIndoorsOtherGardenNeeds" id="txtIndoorsOtherGardenNeeds"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtIndoorsOtherGardenNeeds}
                                                                        value={this.props.editDataObj.txtIndoorsOtherGardenNeeds}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtIndoorsOtherGardenNeeds">Other Garden Needs</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsLookPreferred" className="le-label-multi">Any Type of Look Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsLookPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsLookPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsLookPreferred',
                                                                            id: 'ddlIndoorsLookPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Look Preferred"
                                                                    >
                                                                        {this.props.lookPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.lookPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsPlantsPreferred" className="le-label-multi">Any Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsPlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsPlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsPlantsPreferred',
                                                                            id: 'ddlIndoorsPlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Plants Preferred"
                                                                    >
                                                                        {this.props.plantsPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.plantsPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        {this.props.editDataObj.ddlIndoorsPlantsPreferred.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtIndoorsOtherPlantPreferred" id="txtOtherPlantPreferred"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtIndoorsOtherPlantPreferred}
                                                                        value={this.props.editDataObj.txtIndoorsOtherPlantPreferred}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtIndoorsOtherPlantPreferred">Other Plants Preferred</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlIndoorsAllergicPlant'
                                                                    id='ddlIndoorsAllergicPlant'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlIndoorsAllergicPlant}
                                                                    value={this.props.editDataObj.ddlIndoorsAllergicPlant}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>any plants you or your family (including pets) are allergic to or dislike</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlIndoorsAllergicPlant === "Yes" ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtIndoorsAllergicPlant" id="txtIndoorsAllergicPlant"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtIndoorsAllergicPlant}
                                                                        value={this.props.editDataObj.txtIndoorsAllergicPlant}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtIndoorsAllergicPlant">Specify Alergic/Dislike Plant</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsColorPalette" className="le-label-multi">Planter Colour Palette</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsColorPalette}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsColorPalette}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsColorPalette',
                                                                            id: 'ddlIndoorsColorPalette',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Colour Palette"
                                                                    >
                                                                        {this.props.colorPalette.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.colorPalette.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorsPlanterMaterial" className="le-label-multi">Planter Material</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorsPlanterMaterial}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorsPlanterMaterial}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorsPlanterMaterial',
                                                                            id: 'ddlIndoorsPlanterMaterial',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Material"
                                                                    >
                                                                        {this.props.planterMaterial.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.planterMaterial.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                    </React.Fragment> :
                                                    ''}
                                                {this.props.editDataObj.ddlSpaceType.includes("Landscaping (Garden)") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 className="subHeadHC">Landscaping (Garden) Details</h6>
                                                        </Col>
                                                        {/*<Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtLandscapingLength" id="txtLandscapingLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtLandscapingLength}
                                                                    value={this.props.editDataObj.txtLandscapingLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtLandscapingLength">Length(ft)</span>
                                                            </Form.Group>
                                            </Col>
                                            <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtLandscapingBreadth" id="txtLandscapingBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                  
                                                                    pnkvalue={this.props.editDataObj.txtLandscapingBreadth}
                                                                    value={this.props.editDataObj.txtLandscapingBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtLandscapingBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                                </Col>*/}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtLandscapingTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    //  disabled
                                                                    //value={this.props.editDataObj.txtLandscapingBreadth * this.props.editDataObj.txtLandscapingLength}
                                                                    pnkvalue={this.props.editDataObj.txtLandscapingTotal}
                                                                    value={this.props.editDataObj.txtLandscapingTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="txtBreadth">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col"> {// space facing of Landscaping
                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingSpaceFacing" className="le-label-multi">Space Facing</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingSpaceFacing}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingSpaceFacing}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingSpaceFacing',
                                                                            id: 'ddlLandscapingSpaceFacing',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Space Facing"
                                                                    >
                                                                        {this.props.spaceFaceList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.spaceFaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">{ // Landscaping Utilization of Space

                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingUtilisationSpace" className="le-label-multi">Utilisation of Space</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingUtilisationSpace}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingUtilisationSpace}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingUtilisationSpace',
                                                                            id: 'ddlLandscapingUtilisationSpace',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Utilisation of Space"
                                                                    >
                                                                        {this.props.utilisationSpace.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.utilisationSpace.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        {this.props.editDataObj.ddlLandscapingUtilisationSpace.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtLandscapingOtherUtilSpace" id="txtLandscapingOtherUtilSpace"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtLandscapingOtherUtilSpace}
                                                                        value={this.props.editDataObj.txtLandscapingOtherUtilSpace}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtLandscapingOtherUtilSpace">Other Utilisation Space</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingGardenNeeds" className="le-label-multi">Garden Needs</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingGardenNeeds}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingGardenNeeds}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingGardenNeeds',
                                                                            id: 'ddlLandscapingGardenNeeds',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Garden Needs"
                                                                    >
                                                                        {this.props.gardenNeeds.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.gardenNeeds.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlLandscapingGardenNeeds.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtLandscapingOtherGardenNeeds" id="txtLandscapingOtherGardenNeeds"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtLandscapingOtherGardenNeeds}
                                                                        value={this.props.editDataObj.txtLandscapingOtherGardenNeeds}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtLandscapingOtherGardenNeeds">Other Garden Needs</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingLookPreferred" className="le-label-multi">Any Type of Look Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingLookPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingLookPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingLookPreferred',
                                                                            id: 'ddlLandscapingLookPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Look Preferred"
                                                                    >
                                                                        {this.props.lookPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.lookPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingPlantsPreferred" className="le-label-multi">Any Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingPlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingPlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingPlantsPreferred',
                                                                            id: 'ddlLandscapingPlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Plants Preferred"
                                                                    >
                                                                        {this.props.plantsPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.plantsPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        {this.props.editDataObj.ddlLandscapingPlantsPreferred.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtLandscapingOtherPlantPreferred" id="txtOtherPlantPreferred"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtLandscapingOtherPlantPreferred}
                                                                        value={this.props.editDataObj.txtLandscapingOtherPlantPreferred}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtLandscapingOtherPlantPreferred">Other Plants Preferred</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlLandscapingAllergicPlant'
                                                                    id='ddlLandscapingAllergicPlant'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlLandscapingAllergicPlant}
                                                                    value={this.props.editDataObj.ddlLandscapingAllergicPlant}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>any plants you or your family (including pets) are allergic to or dislike</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlLandscapingAllergicPlant === "Yes" ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtLandscapingAllergicPlant" id="txtLandscapingAllergicPlant"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtLandscapingAllergicPlant}
                                                                        value={this.props.editDataObj.txtLandscapingAllergicPlant}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtLandscapingAllergicPlant">Specify Alergic/Dislike Plant</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingColorPalette" className="le-label-multi">Planter Colour Palette</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingColorPalette}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingColorPalette}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingColorPalette',
                                                                            id: 'ddlLandscapingColorPalette',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Colour Palette"
                                                                    >
                                                                        {this.props.colorPalette.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.colorPalette.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlLandscapingPlanterMaterial" className="le-label-multi">Planter Material</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlLandscapingPlanterMaterial}
                                                                        pnkvalue={this.props.editDataObj.ddlLandscapingPlanterMaterial}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlLandscapingPlanterMaterial',
                                                                            id: 'ddlLandscapingPlanterMaterial',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Material"
                                                                    >
                                                                        {this.props.planterMaterial.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.planterMaterial.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                    </React.Fragment> :
                                                    ''}
                                                {this.props.editDataObj.ddlSpaceType.includes("Full House") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 className="subHeadHC">Full House Details</h6>
                                                        </Col>
                                                        {/*<Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtFullHouseLength" id="txtFullHouseLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtFullHouseLength}
                                                                    value={this.props.editDataObj.txtFullHouseLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtFullHouseLength">Length(ft)</span>
                                                            </Form.Group>
                                            </Col>
                                            <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtFullHouseBreadth" id="txtFullHouseBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                  
                                                                    pnkvalue={this.props.editDataObj.txtFullHouseBreadth}
                                                                    value={this.props.editDataObj.txtFullHouseBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtFullHouseBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                            </Col>*/}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtFullHouseTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    //disabled
                                                                    //value={this.props.editDataObj.txtFullHouseBreadth * this.props.editDataObj.txtFullHouseLength}
                                                                    pnkvalue={this.props.editDataObj.txtFullHouseTotal}
                                                                    value={this.props.editDataObj.txtFullHouseTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="txtBreadth">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col"> {// space facing of FullHouse
                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHouseSpaceFacing" className="le-label-multi">Space Facing</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHouseSpaceFacing}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHouseSpaceFacing}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHouseSpaceFacing',
                                                                            id: 'ddlFullHouseSpaceFacing',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Space Facing"
                                                                    >
                                                                        {this.props.spaceFaceList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.spaceFaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">{ // FullHouse Utilization of Space

                                                        }
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHouseUtilisationSpace" className="le-label-multi">Utilisation of Space</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHouseUtilisationSpace}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHouseUtilisationSpace}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHouseUtilisationSpace',
                                                                            id: 'ddlFullHouseUtilisationSpace',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Utilisation of Space"
                                                                    >
                                                                        {this.props.utilisationSpace.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.utilisationSpace.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>

                                                        {this.props.editDataObj.ddlFullHouseUtilisationSpace.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtFullHouseOtherUtilSpace" id="txtFullHouseOtherUtilSpace"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtFullHouseOtherUtilSpace}
                                                                        value={this.props.editDataObj.txtFullHouseOtherUtilSpace}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtFullHouseOtherUtilSpace">Other Utilisation Space</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHouseGardenNeeds" className="le-label-multi">Garden Needs</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHouseGardenNeeds}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHouseGardenNeeds}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHouseGardenNeeds',
                                                                            id: 'ddlFullHouseGardenNeeds',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Garden Needs"
                                                                    >
                                                                        {this.props.gardenNeeds.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.gardenNeeds.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlFullHouseGardenNeeds.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtFullHouseOtherGardenNeeds" id="txtFullHouseOtherGardenNeeds"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtFullHouseOtherGardenNeeds}
                                                                        value={this.props.editDataObj.txtFullHouseOtherGardenNeeds}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtFullHouseOtherGardenNeeds">Other Garden Needs</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHouseLookPreferred" className="le-label-multi">Any Type of Look Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHouseLookPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHouseLookPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHouseLookPreferred',
                                                                            id: 'ddlFullHouseLookPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Look Preferred"
                                                                    >
                                                                        {this.props.lookPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.lookPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHousePlantsPreferred" className="le-label-multi">Any Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHousePlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHousePlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHousePlantsPreferred',
                                                                            id: 'ddlFullHousePlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any Type of Plants Preferred"
                                                                    >
                                                                        {this.props.plantsPreferred.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.plantsPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        {this.props.editDataObj.ddlFullHousePlantsPreferred.includes("Others") ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtFullHouseOtherPlantPreferred" id="txtOtherPlantPreferred"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtFullHouseOtherPlantPreferred}
                                                                        value={this.props.editDataObj.txtFullHouseOtherPlantPreferred}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtFullHouseOtherPlantPreferred">Other Plants Preferred</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlFullHouseAllergicPlant'
                                                                    id='ddlFullHouseAllergicPlant'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlFullHouseAllergicPlant}
                                                                    value={this.props.editDataObj.ddlFullHouseAllergicPlant}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>any plants you or your family (including pets) are allergic to or dislike</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        {this.props.editDataObj.ddlFullHouseAllergicPlant === "Yes" ?
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtFullHouseAllergicPlant" id="txtFullHouseAllergicPlant"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtFullHouseAllergicPlant}
                                                                        value={this.props.editDataObj.txtFullHouseAllergicPlant}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtFullHouseAllergicPlant">Specify Alergic/Dislike Plant</span>
                                                                </Form.Group>
                                                            </Col> : ''}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHouseColorPalette" className="le-label-multi">Planter Colour Palette</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHouseColorPalette}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHouseColorPalette}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHouseColorPalette',
                                                                            id: 'ddlFullHouseColorPalette',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Colour Palette"
                                                                    >
                                                                        {this.props.colorPalette.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.colorPalette.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlFullHousePlanterMaterial" className="le-label-multi">Planter Material</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlFullHousePlanterMaterial}
                                                                        pnkvalue={this.props.editDataObj.ddlFullHousePlanterMaterial}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlFullHousePlanterMaterial',
                                                                            id: 'ddlFullHousePlanterMaterial',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Planter Material"
                                                                    >
                                                                        {this.props.planterMaterial.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.planterMaterial.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                    </React.Fragment> :
                                                    ''}
                                                <Col lg={12}>
                                                    <h6 style={{ color: '#000' }} className="subHeadHC">Vertical Garden </h6>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlVerticalGarden" className="le-label-multi">Vertical Garden</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlVerticalGarden}
                                                                pnkvalue={this.props.editDataObj.ddlVerticalGarden}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlVerticalGarden',
                                                                    id: 'ddlVerticalGarden',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Type of Space to be designed	"
                                                            >
                                                                {this.props.verticalList.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.verticalList.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                </Col>
                                                {this.props.editDataObj.ddlVerticalGarden.includes("Indoor") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 style={{ color: '#000' }} className="subHeadHC">Indoor Details</h6>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlIndoorWaterType" className="le-label-multi">Water available</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlIndoorWaterType}
                                                                        pnkvalue={this.props.editDataObj.ddlIndoorWaterType}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlIndoorWaterType',
                                                                            id: 'ddlIndoorWaterType',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Water available"
                                                                    >
                                                                        {this.props.waterAvailable.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.waterAvailable.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>

                                                            {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlIndoorWaterType'
                                                        id='ddlIndoorWaterType'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlIndoorWaterType}
                                                        value={this.props.editDataObj.ddlIndoorWaterType}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Water available</option>
                                                        <option value="Inlet">Inlet</option>
                                                        <option value="Outlet">Outlet</option>

                                                    </Form.Control>

                                                </Form.Group> */}
                                                        </Col>
                                                        {/* <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtIndoorWaterTypeVerticalGardenLength" id="txtIndoorWaterTypeVerticalGardenLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtIndoorWaterTypeVerticalGardenLength}
                                                                    value={this.props.editDataObj.txtIndoorWaterTypeVerticalGardenLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtIndoorWaterTypeVerticalGardenLength">Length(ft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtIndoorVerticalGardenBreadth" id="txtIndoorVerticalGardenBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"

                                                                    pnkvalue={this.props.editDataObj.txtIndoorVerticalGardenBreadth}
                                                                    value={this.props.editDataObj.txtIndoorVerticalGardenBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtIndoorVerticalGardenBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                                            </Col>*/}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtVerticalGardenIndoorTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalue={this.props.editDataObj.txtVerticalGardenIndoorTotal}
                                                                    value={this.props.editDataObj.txtVerticalGardenIndoorTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlIndoorAutoDroppingSys'
                                                                    id='ddlIndoorAutoDroppingSys'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlIndoorAutoDroppingSys}
                                                                    value={this.props.editDataObj.ddlIndoorAutoDroppingSys}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Automatic Dripping System</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlIndoorSupportStructure'
                                                                    id='ddlIndoorSupportStructure'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlIndoorSupportStructure}
                                                                    value={this.props.editDataObj.ddlIndoorSupportStructure}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Support structure available</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlIndoorSunlightSpec'
                                                                    id='ddlIndoorSunlightSpec'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlIndoorSunlightSpec}
                                                                    value={this.props.editDataObj.ddlIndoorSunlightSpec}

                                                                    style={{ padding: '4px', width: '100%', fontWeight: 600 }}>
                                                                    <option value="" disabled selected>Sunlight Conditions:
                                                                        (specify for each area)</option>
                                                                    <option value="Full Shade (complete indoors, no sunlight)">Full Shade (complete indoors, no sunlight)</option>
                                                                    <option value="Shade (no direct sunlight but outdoor)">Shade (no direct sunlight but outdoor)</option>
                                                                    <option value="Semi Shade(1-2 hrs)">Semi Shade(1-2 hrs) </option>
                                                                    <option value="Sunny (2-4 hrs)">Sunny (2-4 hrs)</option>
                                                                    <option value="Full Sun (>6 hrs)">{'Full Sun (>6 hrs)'}</option>

                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                    </React.Fragment>
                                                    : ''}

                                                {this.props.editDataObj.ddlVerticalGarden.includes("Outdoor") ?
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 style={{ color: '#000' }} className="subHeadHC">Outdoor Details</h6>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group>
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlOutdoorWaterType" className="le-label-multi">Water available</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlOutdoorWaterType}
                                                                        pnkvalue={this.props.editDataObj.ddlOutdoorWaterType}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlOutdoorWaterType',
                                                                            id: 'ddlOutdoorWaterType',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Water available"
                                                                    >
                                                                        {this.props.waterAvailable.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.waterAvailable.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                            {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlOutdoorWaterType'
                                                        id='ddlOutdoorWaterType'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlOutdoorWaterType}
                                                        value={this.props.editDataObj.ddlOutdoorWaterType}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Water available</option>
                                                        <option value="Inlet">Inlet</option>
                                                        <option value="Outlet">Outlet</option>

                                                    </Form.Control>

                                                </Form.Group> */}
                                                        </Col>
                                                        {/*<Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtOutdoorWaterTypeVerticalGardenLength" id="txtOutdoorWaterTypeVerticalGardenLength"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtOutdoorWaterTypeVerticalGardenLength}
                                                                    value={this.props.editDataObj.txtOutdoorWaterTypeVerticalGardenLength}
                                                                    pnkdisplayerrormsg="Inavlid Length" />
                                                                <span className="floating-label" for="txtOutdoorWaterTypeVerticalGardenLength">Length(ft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtOutdoorVerticalGardenBreadth" id="txtOutdoorVerticalGardenBreadth"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"

                                                                    pnkvalue={this.props.editDataObj.txtOutdoorVerticalGardenBreadth}
                                                                    value={this.props.editDataObj.txtOutdoorVerticalGardenBreadth}
                                                                    pnkdisplayerrormsg="Invalid Breadth" />
                                                                <span className="floating-label" for="txtOutdoorVerticalGardenBreadth">Breadth(ft)</span>
                                                            </Form.Group>
                                            </Col>*/}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtVerticalGardenOutdoorTotal"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalue={this.props.editDataObj.txtVerticalGardenOutdoorTotal}
                                                                    value={this.props.editDataObj.txtVerticalGardenOutdoorTotal}
                                                                    pnkdisplayerrormsg="Invalid Value"
                                                                />
                                                                <span className="floating-label" for="">Total(sqft)</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlOutdoorAutoDroppingSys'
                                                                    id='ddlOutdoorAutoDroppingSys'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlOutdoorAutoDroppingSys}
                                                                    value={this.props.editDataObj.ddlOutdoorAutoDroppingSys}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Automatic Dripping System</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlOutdoorSupportStructure'
                                                                    id='ddlOutdoorSupportStructure'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlOutdoorSupportStructure}
                                                                    value={this.props.editDataObj.ddlOutdoorSupportStructure}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Support structure available</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlOutdoorSunlightSpec'
                                                                    id='ddlOutdoorSunlightSpec'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlOutdoorSunlightSpec}
                                                                    value={this.props.editDataObj.ddlOutdoorSunlightSpec}

                                                                    style={{ padding: '4px', width: '100%', fontWeight: 600 }}>
                                                                    <option value="" disabled selected>Sunlight Conditions: (specify for each area)</option>
                                                                    <option value="Full Shade (complete indoors, no sunlight)">Full Shade (complete indoors, no sunlight)</option>
                                                                    <option value="Shade (no direct sunlight but outdoor)">Shade (no direct sunlight but outdoor)</option>
                                                                    <option value="Semi Shade(1-2 hrs)">Semi Shade(1-2 hrs) </option>
                                                                    <option value="Sunny (2-4 hrs)">Sunny (2-4 hrs)</option>
                                                                    <option value="Full Sun (>6 hrs)">{'Full Sun (>6 hrs)'}</option>

                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                    </React.Fragment>
                                                    : ''}
                                            </Row>
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlSpaceFacing" className="le-label-multi">Space Facing</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlSpaceFacing}
                                                                pnkvalue={this.props.editDataObj.ddlSpaceFacing}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlSpaceFacing',
                                                                    id: 'ddlSpaceFacing',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Space Facing"
                                                            >
                                                                {this.props.spaceFaceList.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.spaceFaceList.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                    {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlSpaceFacing'
                                                        id='ddlSpaceFacing'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlSpaceFacing}
                                                        value={this.props.editDataObj.ddlSpaceFacing}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Space Facing</option>
                                                        <option value="North">North</option>
                                                        <option value="South">South</option>
                                                        <option value="East">East</option>
                                                        <option value="West">West</option>

                                                    </Form.Control>

                                                </Form.Group> */}
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlUtilisationSpace" className="le-label-multi">Utilisation of Space</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlUtilisationSpace}
                                                                pnkvalue={this.props.editDataObj.ddlUtilisationSpace}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlUtilisationSpace',
                                                                    id: 'ddlUtilisationSpace',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Utilisation of Space"
                                                            >
                                                                {this.props.utilisationSpace.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.utilisationSpace.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                    {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlUtilisationSpace'
                                                        id='ddlUtilisationSpace'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlUtilisationSpace}
                                                        value={this.props.editDataObj.ddlUtilisationSpace}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Utilisation of Space</option>
                                                        <option value="Relaxation">Relaxation</option>
                                                        <option value="Entertainment">Entertainment</option>
                                                        <option value="Home Office">Home Office</option>
                                                        <option value="Exercise Area/Yoga Area">Exercise Area/Yoga Area</option>
                                                        <option value="Play Area">Play Area</option>
                                                        <option value="Others">Others</option>
                                                        

                                                    </Form.Control>

                                                </Form.Group> */}
                                                </Col>
                                                {this.props.editDataObj.ddlUtilisationSpace.includes("Others") ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtOtherUtilSpace" id="txtOtherUtilSpace"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtOtherUtilSpace}
                                                                value={this.props.editDataObj.txtOtherUtilSpace}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtOtherUtilSpace">Other Utilisation Space</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlGardenNeeds" className="le-label-multi">Garden Needs</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlGardenNeeds}
                                                                pnkvalue={this.props.editDataObj.ddlGardenNeeds}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlGardenNeeds',
                                                                    id: 'ddlGardenNeeds',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Garden Needs"
                                                            >
                                                                {this.props.gardenNeeds.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.gardenNeeds.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                    {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='dllGardenNeeds'
                                                        id='dllGardenNeeds'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.dllGardenNeeds}
                                                        value={this.props.editDataObj.dllGardenNeeds}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Garden Needs</option>
                                                        <option value="Start Garden from scratch">Start Garden from scratch</option>
                                                        <option value="Redo Existing Place">Redo Existing Place</option>
                                                        <option value="Plants Replaced">Plants Replaced</option>
                                                        <option value="Hardscaping Work">Hardscaping Work</option>
                                                        <option value="Others">Others</option>
                                                        

                                                    </Form.Control>

                                                </Form.Group> */}
                                                </Col>
                                                {this.props.editDataObj.ddlGardenNeeds.includes("Others") ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtOtherGardenNeeds" id="txtOtherGardenNeeds"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtOtherGardenNeeds}
                                                                value={this.props.editDataObj.txtOtherGardenNeeds}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtOtherGardenNeeds">Other Garden Needs</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="dllLookPreferred" className="le-label-multi">Any Type of Look Preferred</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.dllLookPreferred}
                                                                pnkvalue={this.props.editDataObj.dllLookPreferred}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'dllLookPreferred',
                                                                    id: 'dllLookPreferred',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Any Type of Look Preferred"
                                                            >
                                                                {this.props.lookPreferred.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.lookPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>

                                                </Col>

                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlPlantsPreferred" className="le-label-multi">Any Type of Plants Preferred</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlPlantsPreferred}
                                                                pnkvalue={this.props.editDataObj.ddlPlantsPreferred}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlPlantsPreferred',
                                                                    id: 'ddlPlantsPreferred',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Any Type of Plants Preferred"
                                                            >
                                                                {this.props.plantsPreferred.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.plantsPreferred.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>

                                                </Col>
                                                {this.props.editDataObj.ddlPlantsPreferred.includes("Others") ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtOtherPlantPreferred" id="txtOtherPlantPreferred"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtOtherPlantPreferred}
                                                                value={this.props.editDataObj.txtOtherPlantPreferred}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtOtherPlantPreferred">Other Plants Preferred</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlAllergicPlant'
                                                            id='ddlAllergicPlant'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Ownership'
                                                            pnkvalue={this.props.editDataObj.ddlAllergicPlant}
                                                            value={this.props.editDataObj.ddlAllergicPlant}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>any plants you or your family (including pets) are allergic to or dislike</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>


                                                        </Form.Control>

                                                    </Form.Group>
                                                </Col>
                                                {this.props.editDataObj.ddlAllergicPlant === "Yes" ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtAllergicPlant" id="txtAllergicPlant"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtAllergicPlant}
                                                                value={this.props.editDataObj.txtAllergicPlant}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtAllergicPlant">Specify Alergic/Dislike Plant</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlColorPalette" className="le-label-multi">Planter Colour Palette</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlColorPalette}
                                                                pnkvalue={this.props.editDataObj.ddlColorPalette}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlColorPalette',
                                                                    id: 'ddlColorPalette',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Planter Colour Palette"
                                                            >
                                                                {this.props.colorPalette.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.colorPalette.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                    {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlColorPalette'
                                                        id='ddlColorPalette'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlColorPalette}
                                                        value={this.props.editDataObj.ddlColorPalette}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Planter Colour Palette </option>
                                                        <option value="White">White</option>
                                                        <option value="Black">Black</option>
                                                        <option value="Pastels">Pastels</option>
                                                        <option value="Brown">Brown</option>
                                                        <option value="Clay">Clay</option>
                                                        <option value="Bright">Bright</option>

                                                    </Form.Control>

                                                </Form.Group> */}
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group>
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlPlanterMaterial" className="le-label-multi">Planter Material</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlPlanterMaterial}
                                                                pnkvalue={this.props.editDataObj.ddlPlanterMaterial}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlPlanterMaterial',
                                                                    id: 'ddlPlanterMaterial',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Planter Material"
                                                            >
                                                                {this.props.planterMaterial.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.planterMaterial.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                    {/* <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlPlanterMaterial'
                                                        id='ddlPlanterMaterial'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlPlanterMaterial}
                                                        value={this.props.editDataObj.ddlPlanterMaterial}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Planter Material</option>
                                                        <option value="FRP">FRP</option>
                                                        <option value="Terracotta">Terracotta</option>
                                                        <option value="Ceramic">Ceramic</option>
                                                        <option value="Plastic">Plastic</option>
                                                        <option value="Metal">Metal</option>
                                                        <option value="LED Planters">LED Planters</option>
                                                    </Form.Control>
                                                </Form.Group> */}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <h6 style={{ color: '#000' }} className="subHeadHC">Hardscaping Requirements</h6>
                                                   
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlShadeSolutions" className="le-label-multi">Shade Solutions</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlShadeSolutions}
                                                                pnkvalue={this.props.editDataObj.ddlShadeSolutions}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlShadeSolutions',
                                                                    id: 'ddlShadeSolutions',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Shade Solutions"
                                                            >
                                                                {this.props.shadeSolutions.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                    
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.shadeSolutions.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}


                                                            </Select>

                                                        </FormControl>

                                                    </Form.Group>
                                                </Col>
                                                {
                                                    this.props.editDataObj.ddlShadeSolutions.length>0 ?
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                            
                                                                <Form.Control as="textarea" rows="3" className="le-form-controls-ta" name="txtShadeSolutions" id="txtShadeSolutions"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtShadeSolutions}
                                                                    pnkvalue={this.props.editDataObj.txtShadeSolutions}
                                                                    placeholder='(Seperate by #)'
                                                                    pnkvalidate="forceentry"
                                                                    pnkdisplayerrormsg="manadatory Field" />
                                                                <span className="floating-label">Specify/Area/Space for element proposed</span>
                                                            </div>

                                                        </Col>

                                                        :
                                                        ""
                                                }
                                                {this.props.editDataObj.ddlShadeSolutions.length > 0 ? this.props.editDataObj.ddlShadeSolutions.map(item =>
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <h6 style={{ color: '#000' }}>{item} Details</h6>
                                                        </Col>
                                                        {item === "Pergola" ?
                                                            <React.Fragment>
                                                                <Col lg={4} className="lead-col">
                                                                    <Form.Group >
                                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                            <InputLabel htmlFor="ddlFull" className="le-label-multi">Full</InputLabel>
                                                                            <Select
                                                                                value={this.props.editDataObj.ddlFull}
                                                                                pnkvalue={this.props.editDataObj.ddlFull}
                                                                                onChange={this.props.HandleUserInputs}
                                                                                inputProps={{
                                                                                    name: 'ddlFull',
                                                                                    id: 'ddlFull',
                                                                                    // pnkvalidate: 'forceselect',
                                                                                    pnkdisplayerrormsg: 'Select one'
                                                                                }}
                                                                                className="le-form-controls-select"
                                                                                multiple={true}
                                                                                renderValue={(selected) => selected.join(', ')}
                                                                                placeholder="Full"
                                                                            >
                                                                                {this.props.fullOptions.map((name) => (
                                                                                    <MenuItem key={name} value={name}>
                                                                                        <ListItemText primary={name} />

                                                                                        <Checkbox checked={this.props.fullOptions.indexOf(name) > -1} className="le-checkbox" />
                                                                                    </MenuItem>
                                                                                ))}


                                                                            </Select>

                                                                        </FormControl>

                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={4} className="lead-col">
                                                                    <Form.Group >
                                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                            <InputLabel htmlFor="ddlPartial" className="le-label-multi">Partial</InputLabel>
                                                                            <Select
                                                                                value={this.props.editDataObj.ddlPartial}
                                                                                pnkvalue={this.props.editDataObj.ddlPartial}
                                                                                onChange={this.props.HandleUserInputs}
                                                                                inputProps={{
                                                                                    name: 'ddlPartial',
                                                                                    id: 'ddlPartial',
                                                                                    // pnkvalidate: 'forceselect',
                                                                                    pnkdisplayerrormsg: 'Select one'
                                                                                }}
                                                                                className="le-form-controls-select"
                                                                                multiple={true}
                                                                                renderValue={(selected) => selected.join(', ')}
                                                                                placeholder="Partial"
                                                                            >
                                                                                {this.props.partialOptions.map((name) => (
                                                                                    <MenuItem key={name} value={name}>
                                                                                        <ListItemText primary={name} />

                                                                                        <Checkbox checked={this.props.partialOptions.indexOf(name) > -1} className="le-checkbox" />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={4} className="lead-col">
                                                                    <Form.Group >
                                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                            <InputLabel htmlFor="ddlOpen" className="le-label-multi">Open</InputLabel>
                                                                            <Select
                                                                                value={this.props.editDataObj.ddlOpen}
                                                                                pnkvalue={this.props.editDataObj.ddlOpen}
                                                                                onChange={this.props.HandleUserInputs}
                                                                                inputProps={{
                                                                                    name: 'ddlOpen',
                                                                                    id: 'ddlOpen',
                                                                                    // pnkvalidate: 'forceselect',
                                                                                    pnkdisplayerrormsg: 'Select one'
                                                                                }}
                                                                                className="le-form-controls-select"
                                                                                multiple={true}
                                                                                renderValue={(selected) => selected.join(', ')}
                                                                                placeholder="Open"
                                                                            >
                                                                                {this.props.openOptions.map((name) => (
                                                                                    <MenuItem key={name} value={name}>
                                                                                        <ListItemText primary={name} />

                                                                                        <Checkbox checked={this.props.openOptions.indexOf(name) > -1} className="le-checkbox" />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Form.Group>
                                                                </Col>
                                                            </React.Fragment> : ''}
                                                        {item === "Umbrella" ?
                                                            <Col lg={12}>
                                                                <Col lg={4} className="lead-col" style={{ padding: 0 }}>
                                                                    <Form.Group >
                                                                        <Form.Control type="text" className="le-form-controls" name="txtUmbrellaColor" id="txtUmbrellaColor"
                                                                            onChange={this.props.HandleUserInputs}

                                                                            pnkvalue={this.props.editDataObj.txtUmbrellaColor}
                                                                            value={this.props.editDataObj.txtUmbrellaColor}
                                                                            pnkdisplayerrormsg="Invalid Pincode" />
                                                                        <span className="floating-label" for="txtUmbrellaColor">Umbrella Color</span>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Col> : ''

                                                        }
                                                        {item === "Awning" ?
                                                            <Row style={{ paddingLeft: 15 }}>
                                                                <Col lg={4} className="lead-col" >
                                                                    <Form.Group >
                                                                        <Form.Control type="text" className="le-form-controls" name="txtAwningShadeLength" id="txtAwningShadeLength"
                                                                            onChange={this.props.HandleUserInputs}

                                                                            pnkvalue={this.props.editDataObj.txtAwningShadeLength}
                                                                            value={this.props.editDataObj.txtAwningShadeLength}
                                                                            pnkdisplayerrormsg="Invalid Pincode" />
                                                                        <span className="floating-label" for="txtAwningShadeLength">Awning Shade Length(ft)</span>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={4} className="lead-col" >
                                                                    <Form.Group >
                                                                        <Form.Control type="text" className="le-form-controls" name="txtAwningShadeBreadth" id="txtAwningShadeBreadth"
                                                                            onChange={this.props.HandleUserInputs}

                                                                            pnkvalue={this.props.editDataObj.txtAwningShadeBreadth}
                                                                            value={this.props.editDataObj.txtAwningShadeBreadth}
                                                                            pnkdisplayerrormsg="Invalid Pincode" />
                                                                        <span className="floating-label" for="txtAwningShadeBreadth">Awning Shade Width(ft)</span>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={4} className="lead-col">
                                                                    <Form.Group >
                                                                        <Form.Control type="text" className="le-form-controls"

                                                                            disabled
                                                                            value={this.props.editDataObj.txtAwningShadeLength * this.props.editDataObj.txtAwningShadeBreadth}
                                                                            pnkdisplayerrormsg="Invalid Pincode" />
                                                                        <span className="floating-label" for="txtAwningShadeLength">Total(sqft)</span>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row> : ''

                                                        }
                                                        {item === "Combination of Full/Partial/Open" ?
                                                            <Col lg={12}>
                                                                <Col lg={4} className="lead-col" style={{ padding: 0 }}>
                                                                    <Form.Group >
                                                                        <Form.Control type="text" className="le-form-controls" name="txtCombinationShade" id="txtCombinationShade"
                                                                            onChange={this.props.HandleUserInputs}

                                                                            pnkvalue={this.props.editDataObj.txtCombinationShade}
                                                                            value={this.props.editDataObj.txtCombinationShade}
                                                                            pnkdisplayerrormsg="Invalid Pincode" />
                                                                        <span className="floating-label" for="txtCombinationShade">Combination of Full/Partial/Open</span>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Col> : ''
                                                        }
                                                        {item === "Others" ?
                                                            <Col lg={12}>
                                                                <Col lg={4} className="lead-col" style={{ padding: 0 }}>
                                                                    <Form.Group >
                                                                        <Form.Control type="text" className="le-form-controls" name="txtOtherShades" id="txtOtherShades"
                                                                            onChange={this.props.HandleUserInputs}

                                                                            pnkvalue={this.props.editDataObj.txtOtherShades}
                                                                            value={this.props.editDataObj.txtOtherShades}
                                                                            pnkdisplayerrormsg="Invalid Pincode" />
                                                                        <span className="floating-label" for="txtOtherShades">Specify Other Shades</span>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Col> : ''
                                                        }

                                                    </React.Fragment>
                                                ) : ''}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlInnerRoofCladding'
                                                            id='ddlInnerRoofCladding'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Ownership'
                                                            pnkvalue={this.props.editDataObj.ddlInnerRoofCladding}
                                                            value={this.props.editDataObj.ddlInnerRoofCladding}
                                                            renderValue
                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Inner roof Cladding (Sheraboard, FRP jhalli, etc) required</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                {this.props.editDataObj.ddlInnerRoofCladding === "Yes" ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" rows="2" className="le-form-controls" name="txtInnerRoofCladding" id="txtOtherPropertyType"
                                                                onChange={this.props.HandleUserInputs}
                                                                pnkvalue={this.props.editDataObj.txtInnerRoofCladding}
                                                                value={this.props.editDataObj.txtInnerRoofCladding}
                                                                pnkdisplayerrormsg="Invalid Details" />
                                                            <span className="floating-label" for="txtInnerRoofCladding">InnerRoof Cladding Details</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                            </Row>

                                            <Row>
                                                <Col lg={12}>
                                                    <h6 className="subHeadHC" style={{ color: '#000' }}>Flooring</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlFlooringSolutions" className="le-label-multi">Flooring Solutions</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlFlooringSolutions}
                                                                pnkvalue={this.props.editDataObj.ddlFlooringSolutions}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlFlooringSolutions',
                                                                    id: 'ddlFlooringSolutions',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Flooring Solutions"
                                                            >
                                                                {this.props.flooringSolutions.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.flooringSolutions.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>

                                                {
                                                    this.props.editDataObj.ddlFlooringSolutions.length>0 ?
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                            
                                                                <Form.Control as="textarea" rows="3" className="le-form-controls-ta" name="txtFlooringSolutions" id="txtFlooringSolutions"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtFlooringSolutions}
                                                                    pnkvalue={this.props.editDataObj.txtFlooringSolutions}
                                                                    placeholder='(Seperate by #)'
                                                                    pnkvalidate="forceentry"
                                                                    pnkdisplayerrormsg="manadatory Field" />
                                                                <span className="floating-label">Specify/Area/Space for element proposed</span>
                                                            </div>

                                                        </Col>

                                                        :
                                                        ""
                                                }
                                                
                                                {this.props.editDataObj.ddlFlooringSolutions.includes("Combination Flooring") ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtCombinationFlooring" id="txtCombinationFlooring"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtCombinationFlooring}
                                                                value={this.props.editDataObj.txtCombinationFlooring}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtCombinationFlooring">Specify Combination Flooring</span>
                                                        </Form.Group>
                                                    </Col>
                                                    : ''
                                                }
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlFloorPreparation" className="le-label-multi"> Preparation of Floor</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlFloorPreparation}
                                                                pnkvalue={this.props.editDataObj.ddlFloorPreparation}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlFloorPreparation',
                                                                    id: 'ddlFloorPreparation',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder=" Preparation of Floor"
                                                            >
                                                                {this.props.floorPrep.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.floorPrep.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {this.props.editDataObj.ddlFloorPreparation.length > 0 && this.props.editDataObj.ddlFloorPreparation.includes("Others") ?
                                                    <Col lg={4} className="lead-col">
                                                        <Form.Group >
                                                            <Form.Control type="text" className="le-form-controls" name="txtOtherFloorPrep" id="txtOtherFloorPrep"
                                                                onChange={this.props.HandleUserInputs}

                                                                pnkvalue={this.props.editDataObj.txtOtherFloorPrep}
                                                                value={this.props.editDataObj.txtOtherFloorPrep}
                                                                pnkdisplayerrormsg="Invalid Pincode" />
                                                            <span className="floating-label" for="txtOtherFloorPrep">Other Preparation of Floor</span>
                                                        </Form.Group>
                                                    </Col> : ''}
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <h6 className="subHeadHC" style={{ color: '#000' }}>Wall Solutions</h6>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlWallSolutions" className="le-label-multi">Wall Solutions</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlWallSolutions}
                                                                pnkvalue={this.props.editDataObj.ddlWallSolutions}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlWallSolutions',
                                                                    id: 'ddlWallSolutions',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Wall Solutions"
                                                            >
                                                                {this.props.wallSolutions.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.wallSolutions.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>

                                                {
                                                    this.props.editDataObj.ddlWallSolutions.length>0 ?
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                            
                                                                <Form.Control as="textarea" rows="3" className="le-form-controls-ta" name="txtWallSolutions" id="txtWallSolutions"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtWallSolutions}
                                                                    pnkvalue={this.props.editDataObj.txtWallSolutions}
                                                                    placeholder='(Seperate by #)'
                                                                    pnkvalidate="forceentry"
                                                                    pnkdisplayerrormsg="manadatory Field" />
                                                                <span className="floating-label">Specify/Area/Space for element proposed</span>
                                                            </div>

                                                        </Col>

                                                        :
                                                        ""
                                                }
                                             

                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlTrellis" className="le-label-multi">Trellis for creepers</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlTrellis}
                                                                pnkvalue={this.props.editDataObj.ddlTrellis}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlTrellis',
                                                                    id: 'ddlTrellis',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Trellis for creepers"
                                                            >
                                                                {this.props.trellis.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.trellis.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlCladding" className="le-label-multi">Cladding</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlCladding}
                                                                pnkvalue={this.props.editDataObj.ddlCladding}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlCladding',
                                                                    id: 'ddlCladding',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Cladding"
                                                            >
                                                                {this.props.cladding.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.cladding.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <h5 className="subHeadHC" style={{ color: '#000' }}>Partition</h5>
                                                </Col>

                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlPartitions" className="le-label-multi">Partitions</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlPartitions}
                                                                pnkvalue={this.props.editDataObj.ddlPartitions}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlPartitions',
                                                                    id: 'ddlPartitions',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Partitions"
                                                            >
                                                                {this.props.partitions.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.partitions.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    this.props.editDataObj.ddlPartitions.length>0 ?
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                            
                                                                <Form.Control as="textarea" rows="3" className="le-form-controls-ta" name="txtPartitions" id="txtPartitions"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtPartitions}
                                                                    pnkvalue={this.props.editDataObj.txtPartitions}
                                                                    placeholder='(Seperate by #)'
                                                                    pnkvalidate="forceentry"
                                                                    pnkdisplayerrormsg="manadatory Field" />
                                                                <span className="floating-label">Specify/Area/Space for element proposed</span>
                                                            </div>

                                                        </Col>

                                                        :
                                                        ""
                                                }
                                             
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlPartitionWithPlanters'
                                                            id='ddlPartitionWithPlanters'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Ownership'
                                                            pnkvalue={this.props.editDataObj.ddlPartitionWithPlanters}
                                                            value={this.props.editDataObj.ddlPartitionWithPlanters}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Partition with Planters</option>
                                                            <option value="Metal">Metal</option>
                                                            <option value="Wood">Wood</option>

                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col lg={4} className="lead-col">
                                                <Form.Group >
                                                    <Form.Control as="select" className="le-form-controls-select"
                                                        name='ddlCombination'
                                                        id='ddlCombination'
                                                        onChange={this.props.HandleUserInputs}
                                                        // pnkvalidate='forceselect'
                                                        pnkdisplayerrormsg='Select Ownership'
                                                        pnkvalue={this.props.editDataObj.ddlCombination}
                                                        value={this.props.editDataObj.ddlCombination}

                                                        style={{ padding: '4px', width: '100%' }}>
                                                        <option value="" disabled selected>Combination</option>
                                                        <option value="Metal">Metal</option>
                                                        <option value="Glass">Glass</option>
                                                        <option value="Wood">Wood</option>
 
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col> */}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlMetalStructureSupport'
                                                            id='ddlMetalStructureSupport'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Ownership'
                                                            pnkvalue={this.props.editDataObj.ddlMetalStructureSupport}
                                                            value={this.props.editDataObj.ddlMetalStructureSupport}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Metal Structural Support for partition required</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>


                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlOutdoorLighting" className="le-label-multi">Outdoor Lighting</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlOutdoorLighting}
                                                                pnkvalue={this.props.editDataObj.ddlOutdoorLighting}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlOutdoorLighting',
                                                                    id: 'ddlOutdoorLighting',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Outdoor Lighting"
                                                            >
                                                                {this.props.outdoorlighting.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.outdoorlighting.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlOutdoorFurniture" className="le-label-multi">Outdoor Furniture</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlOutdoorFurniture}
                                                                pnkvalue={this.props.editDataObj.ddlOutdoorFurniture}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlOutdoorFurniture',
                                                                    id: 'ddlOutdoorFurniture',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Outdoor Furniture"
                                                            >
                                                                {this.props.outdoorFurnture.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.outdoorFurnture.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    this.props.editDataObj.ddlOutdoorFurniture.length > 0 && this.props.editDataObj.ddlOutdoorFurniture.includes('Coffee Set') ?
                                                        <React.Fragment>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control as="select" className="le-form-controls-select"
                                                                        name='ddlCoffeeSet'
                                                                        id='ddlCoffeeSet'
                                                                        onChange={this.props.HandleUserInputs}
                                                                        // pnkvalidate='forceselect'
                                                                        pnkdisplayerrormsg='Select Ownership'
                                                                        pnkvalue={this.props.editDataObj.ddlCoffeeSet}
                                                                        value={this.props.editDataObj.ddlCoffeeSet}

                                                                        style={{ padding: '4px', width: '100%' }}>
                                                                        <option value="" disabled selected>Select Coffee Type</option>
                                                                        <option value="Metal">Metal</option>
                                                                        <option value="Rattan">Rattan</option>
                                                                        <option value="Wood">Wood</option>


                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtCoffeeSeater" id="txtCoffeeSeater"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtCoffeeSeater}
                                                                        value={this.props.editDataObj.txtCoffeeSeater}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtCoffeeSeater">Seater</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlOutdoorFurniture.length > 0 && this.props.editDataObj.ddlOutdoorFurniture.includes('Sofa Set') ?
                                                        <React.Fragment>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control as="select" className="le-form-controls-select"
                                                                        name='ddlSofaSet'
                                                                        id='ddlSofaSet'
                                                                        onChange={this.props.HandleUserInputs}
                                                                        // pnkvalidate='forceselect'
                                                                        pnkdisplayerrormsg='Select Ownership'
                                                                        pnkvalue={this.props.editDataObj.ddlSofaSet}
                                                                        value={this.props.editDataObj.ddlSofaSet}

                                                                        style={{ padding: '4px', width: '100%' }}>
                                                                        <option value="" disabled selected>Select Sofa Set Type</option>
                                                                        <option value="Rattan">Rattan</option>
                                                                        <option value="Wood">Wood</option>


                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtSofaSeater" id="txtSofaSeater"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtSofaSeater}
                                                                        value={this.props.editDataObj.txtSofaSeater}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtSofaSeater">Seater</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlOutdoorFurniture.length > 0 && this.props.editDataObj.ddlOutdoorFurniture.includes('Bench') ?
                                                        <React.Fragment>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control as="select" className="le-form-controls-select"
                                                                        name='ddlBenchSet'
                                                                        id='ddlBenchSet'
                                                                        onChange={this.props.HandleUserInputs}
                                                                        // pnkvalidate='forceselect'
                                                                        pnkdisplayerrormsg='Select Ownership'
                                                                        pnkvalue={this.props.editDataObj.ddlBenchSet}
                                                                        value={this.props.editDataObj.ddlBenchSet}

                                                                        style={{ padding: '4px', width: '100%' }}>
                                                                        <option value="" disabled selected>Select Bench Type</option>
                                                                        <option value="Metal">Metal</option>
                                                                        <option value="Wood">Wood</option>
                                                                        <option value="Stone">Stone</option>
                                                                        <option value="Cement">Cement</option>
                                                                        <option value="Bricks">Bricks</option>



                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtBenchSize" id="txtCoffeeSeater"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtBenchSize}
                                                                        value={this.props.editDataObj.txtBenchSize}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtBenchSize">Size</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlOutdoorFurniture.length > 0 && this.props.editDataObj.ddlOutdoorFurniture.includes('Bar Counters') ?
                                                        <React.Fragment>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control as="select" className="le-form-controls-select"
                                                                        name='ddlBarCounterType'
                                                                        id='ddlBarCounterType'
                                                                        onChange={this.props.HandleUserInputs}
                                                                        // pnkvalidate='forceselect'
                                                                        pnkdisplayerrormsg='Select Ownership'
                                                                        pnkvalue={this.props.editDataObj.ddlBarCounterType}
                                                                        value={this.props.editDataObj.ddlBarCounterType}

                                                                        style={{ padding: '4px', width: '100%' }}>
                                                                        <option value="" disabled selected>Select Bar Counter Type</option>
                                                                        <option value="Rattan">Rattan</option>
                                                                        <option value="Wood">Wood</option>
                                                                        <option value="Railing Mounted">Railing Mounted</option>
                                                                        <option value="Constructed">Constructed</option>


                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtBarSeater" id="txtBarSeater"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtBarSeater}
                                                                        value={this.props.editDataObj.txtBarSeater}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtBarSeater">Seater</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlOutdoorFurniture.length > 0 && this.props.editDataObj.ddlOutdoorFurniture.includes('Dining Set') ?
                                                        <React.Fragment>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control as="select" className="le-form-controls-select"
                                                                        name='ddlDiningSet'
                                                                        id='ddlDiningSet'
                                                                        onChange={this.props.HandleUserInputs}
                                                                        // pnkvalidate='forceselect'
                                                                        pnkdisplayerrormsg='Select Ownership'
                                                                        pnkvalue={this.props.editDataObj.ddlDiningSet}
                                                                        value={this.props.editDataObj.ddlDiningSet}

                                                                        style={{ padding: '4px', width: '100%' }}>
                                                                        <option value="" disabled selected>Select Dining Set</option>
                                                                        <option value="Rattan">Rattan</option>
                                                                        <option value="Wood">Wood</option>
                                                                        <option value="Metal">Metal</option>


                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtDiningSeater" id="txtDiningSeater"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtDiningSeater}
                                                                        value={this.props.editDataObj.txtDiningSeater}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtDiningSeater">Seater</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlOutdoorFurniture.length > 0 && this.props.editDataObj.ddlOutdoorFurniture.includes('Swing') ?
                                                        <React.Fragment>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control as="select" className="le-form-controls-select"
                                                                        name='ddlSwing'
                                                                        id='ddlSwing'
                                                                        onChange={this.props.HandleUserInputs}
                                                                        // pnkvalidate='forceselect'
                                                                        pnkdisplayerrormsg='Select Ownership'
                                                                        pnkvalue={this.props.editDataObj.ddlSwing}
                                                                        value={this.props.editDataObj.ddlSwing}

                                                                        style={{ padding: '4px', width: '100%' }}>
                                                                        <option value="" disabled selected>Select Swing Type</option>
                                                                        <option value="Rattan">Rattan</option>
                                                                        <option value="Wood">Wood</option>
                                                                        <option value="Acrylic">Acrylic</option>
                                                                        <option value="Metal">Metal</option>
                                                                        <option value="Hammock">Hammock</option>


                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} className="lead-col">
                                                                <Form.Group >
                                                                    <Form.Control type="text" className="le-form-controls" name="txtSwingSeater" id="txtSwingSeater"
                                                                        onChange={this.props.HandleUserInputs}

                                                                        pnkvalue={this.props.editDataObj.txtSwingSeater}
                                                                        value={this.props.editDataObj.txtSwingSeater}
                                                                        pnkdisplayerrormsg="Invalid Pincode" />
                                                                    <span className="floating-label" for="txtSwingSeater">Seater</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment> : ''
                                                }
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <h6 className="subHeadHC" style={{ color: '#000' }}>Decor Options</h6>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlDecorOptions" className="le-label-multi">Decor Options</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlDecorOptions}
                                                                pnkvalue={this.props.editDataObj.ddlDecorOptions}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlDecorOptions',
                                                                    id: 'ddlDecorOptions',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Decor Options"
                                                            >
                                                                {this.props.decorOptions.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.decorOptions.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>

                                                {
                                                    this.props.editDataObj.ddlDecorOptions.length>0 ?
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                            
                                                                <Form.Control as="textarea" rows="3" className="le-form-controls-ta" name="txtDecorOptions" id="txtDecorOptions"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtDecorOptions}
                                                                    pnkvalue={this.props.editDataObj.txtDecorOptions}
                                                                    placeholder='(Seperate by #)'
                                                                    pnkvalidate="forceentry"
                                                                    pnkdisplayerrormsg="manadatory Field" />
                                                                <span className="floating-label">Specify/Area/Space for element proposed</span>
                                                            </div>

                                                        </Col>

                                                        :
                                                        ""
                                                }
                                             

                                                {
                                                    this.props.editDataObj.ddlDecorOptions.length > 0 && this.props.editDataObj.ddlDecorOptions.includes('Wall Decor') ?

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlWallDecor'
                                                                    id='ddlWallDecor'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlWallDecor}
                                                                    value={this.props.editDataObj.ddlWallDecor}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Select Wall Decor</option>
                                                                    <option value="Metal">Metal</option>
                                                                    <option value="Ceramic">Ceramic </option>
                                                                    <option value="Wood">Wood</option>
                                                                    <option value="Mirror">Mirror</option>
                                                                    <option value="Window">Window</option>


                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlDecorOptions.length > 0 && this.props.editDataObj.ddlDecorOptions.includes('Wall Painting & Mosaics') ?

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlWallPainting'
                                                                    id='ddlWallPainting'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlWallPainting}
                                                                    value={this.props.editDataObj.ddlWallPainting}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Select Wall Paiting & Mosaics </option>
                                                                    <option value="Paint">Paint</option>
                                                                    <option value="Graffiti">Graffiti </option>
                                                                    <option value="Stencil">Stencil</option>
                                                                    <option value="Mosaic">Mosaic </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlDecorOptions.length > 0 && this.props.editDataObj.ddlDecorOptions.includes('Statues') ?

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlStatues" className="le-label-multi">Select Statues Type</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlStatues}
                                                                        pnkvalue={this.props.editDataObj.ddlStatues}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlStatues',
                                                                            id: 'ddlStatues',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Space"
                                                                    >
                                                                        {this.props.statueList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.statueList.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                            {/* <Form.Group >
                                                                                         <Form.Control as="select" className="le-form-controls-select"
                                                                                             name='ddlStatues'
                                                                                             id='ddlStatues'
                                                                                             onChange={this.props.HandleUserInputs}
                                                                                             // pnkvalidate='forceselect'
                                                                                             pnkdisplayerrormsg='Select Ownership'
                                                                                             pnkvalue={this.props.editDataObj.ddlStatues}
                                                                                             value={this.props.editDataObj.ddlStatues}
                                     
                                                                                             style={{ padding: '4px', width: '100%' }}>
                                                                                             <option value="" disabled selected>Select Statues Type</option>
                                                                                             <option value="FRP Statue">FRP Statue</option>
                                                                                             <option value="Stone Statue">Stone Statue </option>
                                                                                             <option value="Cement Statue">Cement Statue</option>                                    
                                                                                         </Form.Control>
                                                                                     </Form.Group> */}
                                                        </Col> : ''
                                                }
                                                {
                                                    this.props.editDataObj.ddlDecorOptions.length > 0 && this.props.editDataObj.ddlDecorOptions.includes('Water Features') ?

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlWaterFeatures'
                                                                    id='ddlWaterFeatures'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlWaterFeatures}
                                                                    value={this.props.editDataObj.ddlWaterFeatures}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Select Water Feature </option>
                                                                    <option value="Plug and Play Fountain">Plug & Play Fountain</option>
                                                                    <option value="Customised">Customised </option>
                                                                    <option value="Lily pond">Lily pond</option>
                                                                    <option value="Bird bath">Bird bath</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col> : ''
                                                }
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                            <InputLabel htmlFor="ddlOtherOptions" className="le-label-multi">Other Options</InputLabel>
                                                            <Select
                                                                value={this.props.editDataObj.ddlOtherOptions}
                                                                pnkvalue={this.props.editDataObj.ddlOtherOptions}
                                                                onChange={this.props.HandleUserInputs}
                                                                inputProps={{
                                                                    name: 'ddlOtherOptions',
                                                                    id: 'ddlOtherOptions',
                                                                    // pnkvalidate: 'forceselect',
                                                                    pnkdisplayerrormsg: 'Select one'
                                                                }}
                                                                className="le-form-controls-select"
                                                                multiple={true}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                placeholder="Other Options"
                                                            >
                                                                {this.props.otherOptions.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <ListItemText primary={name} />

                                                                        <Checkbox checked={this.props.otherOptions.indexOf(name) > -1} className="le-checkbox" />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <div className="le-teaxt-area">
                                                        <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtElectricalRequirement" id="txtElectricalRequirement"
                                                            onChange={this.props.HandleUserInputs}
                                                            value={this.props.editDataObj.txtElectricalRequirement}
                                                            pnkvalue={this.props.editDataObj.txtElectricalRequirement}

                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                        <span className="floating-label">Electrical requirement</span>
                                                    </div>

                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <div className="le-teaxt-area">
                                                        <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtPlumbingRequirement" id="txtPlumbingRequirement"
                                                            onChange={this.props.HandleUserInputs}
                                                            value={this.props.editDataObj.txtPlumbingRequirement}
                                                            pnkvalue={this.props.editDataObj.txtPlumbingRequirement}

                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                        <span className="floating-label">Plumbing requirement</span>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4} className="lead-col">
                                                    <div className="le-teaxt-area">
                                                        <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtFeatureMaterial" id="txtFeatureMaterial"
                                                            onChange={this.props.HandleUserInputs}
                                                            value={this.props.editDataObj.txtFeatureMaterial}
                                                            pnkvalue={this.props.editDataObj.txtFeatureMaterial}

                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                        <span className="floating-label">Is there any feature/material you would like to retain in your design?</span>
                                                    </div>

                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <div className="le-teaxt-area">
                                                        <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtIssues" id="txtIssues"
                                                            onChange={this.props.HandleUserInputs}
                                                            value={this.props.editDataObj.txtIssues}
                                                            pnkvalue={this.props.editDataObj.txtIssues}

                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                        <span className="floating-label">Any issues encountered – pests, drainage, leakage etc?</span>
                                                    </div>

                                                </Col>

                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlFloorPlanReceived'
                                                            id='ddlFloorPlanReceived'
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Ownership'
                                                            pnkvalue={this.props.editDataObj.ddlFloorPlanReceived}
                                                            value={this.props.editDataObj.ddlFloorPlanReceived}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Floor Plan Received </option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No </option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12} className="lead-col">
                                                    <div className="le-teaxt-area">
                                                        <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtClientSuggestions" id="txtClientSuggestions"
                                                            onChange={this.props.HandleUserInputs}
                                                            value={this.props.editDataObj.txtClientSuggestions}
                                                            pnkvalue={this.props.editDataObj.txtClientSuggestions}

                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                        <span className="floating-label">Description: Note:(Fill in the detailed suggestions/queries by clients</span>
                                                    </div>

                                                </Col>
                                                {/*    <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtElectricalRequirement" id="txtElectricalRequirement"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtElectricalRequirement}
                                                                    pnkvalue={this.props.editDataObj.txtElectricalRequirement}

                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Electrical requirement</span>
                                                            </div>

                                                                </Col>*/}
                                                {/*} <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtPlumbingRequirement" id="txtPlumbingRequirement"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtPlumbingRequirement}
                                                                    pnkvalue={this.props.editDataObj.txtPlumbingRequirement}

                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Plumbing requirement</span>
                                                            </div>

                                                            </Col>*/}
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select"
                                                            name='ddlClientBudget'
                                                            id='ddlClientBudget'
                                                            onChange={this.props.HandleUserInputs}
                                                            pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Please select one..'
                                                            pnkvalue={this.props.editDataObj.ddlClientBudget}
                                                            value={this.props.editDataObj.ddlClientBudget}

                                                            style={{ padding: '4px', width: '100%' }}>
                                                            <option value="" disabled selected>Client Budget</option>
                                                            {this.props.clientBudget.map(item =>
                                                                <option value={item}>{item}</option>
                                                            )}

                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <h6 className="subHeadHC" style={{ color: '#000' }}>R M Checklist</h6>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <div className="le-teaxt-area">
                                                        <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtRMChecklist" id="txtRMChecklist"
                                                            onChange={this.props.HandleUserInputs}
                                                            value={this.props.editDataObj.txtRMChecklist}
                                                            pnkvalue={this.props.editDataObj.txtRMChecklist}

                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                        <span className="floating-label">RM Checklist</span>
                                                    </div>

                                                </Col>
                                                {/* <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtRMPhotoVideos" id="txtRMPhotoVideos"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtRMPhotoVideos}
                                                                    pnkvalue={this.props.editDataObj.txtRMPhotoVideos}

                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Photos/Videos</span>
                                                            </div>

                                                        </Col> */}
                                                {/* <Col lg={4} className="lead-col">
                                                                                     <Form.Group >
                                                                                         <Form.Control as="select" className="le-form-controls-select"
                                                                                             name='ddlRMEstimatedBudget'
                                                                                             id='ddlRMEstimatedBudget'
                                                                                             onChange={this.props.HandleUserInputs}
                                                                                             // pnkvalidate='forceselect'
                                                                                             pnkdisplayerrormsg='Select Ownership'
                                                                                             pnkvalue={this.props.editDataObj.ddlRMEstimatedBudget}
                                                                                             value={this.props.editDataObj.ddlRMEstimatedBudget}
                                     
                                                                                             style={{ padding: '4px', width: '100%' }}>
                                                                                             <option value="" disabled selected>Estimated Budget</option>
                                                                                             {this.props.clientBudget.map(item=>
                                                                                                 <option value={item}>{item}</option>
                                                                                                )}
                                                                                           
                                                                                         </Form.Control>
                                                                                     </Form.Group>
                                                                                 </Col> */}

                                            </Row>              
                                            <Row style={{ marginTop: 16 }}>
                                                <Col lg={12}>
                                                    {/* <Button className="le-save-submit mr-3" onClick={()=>this.props.SaveHCForm()}>Save</Button> */}
                                                    {!this.props.mystate.showDownload ?
                                                        <button type="button" className="le-save-submit pnkvalidatesubmit mr-3" name="Save" id="btnHCEntry" onClick={() => this.props.SaveHCForm("save")}>Save</button>
                                                        : ''}
                                                    {/*<button className="le-download-submit pnkvalidatesubmit mr-3" name="Download"  onClick={()=>this.props.SaveHCForm("download")}>Download</button>*/}
                                                    {this.props.mystate.showDownload ?
                                                        <button type="button" className="le-download-submit mr-3" name="Download" onClick={() => this.props.DownloadPdf('download')}>Download</button>
                                                        : ''}
                                                    {/*<button className="le-download-submit pnkvalidatesubmit mr-3" name="Email To Client"  onClick={()=>this.props.SaveHCForm("email")}>Email To Client</button>*/}
                                                    {this.props.mystate.showDownload ?
                                                        <button type="button" className="le-download-submit mr-3" name="Email To Client" onClick={() => this.props.DownloadPdf('email')}>Email To Client</button>
                                                        : ''}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </PnkHtmlForm>

                    </div>
                </div>
            </div >
        )
    }
}
