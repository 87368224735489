import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import Options from './options'
import Table from './users'
import Footer from './footer'
import { Container } from 'react-bootstrap'
import { UserEntryPage } from '../User-Entry'
import { GetUserList, GetCityList, ChangeUserStatus, SearchUserList, DeleteUser } from '../../models/users'
import $ from 'jquery';
class User extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showUserEntryModal: false,
            getUserList: true,
            setNo: 0,
            setLength: 0,
            getCity: true,
            changeStatus: false,
            requiredUserStatus: '',
            currentSeletedUserId: '',
            getSearch: false,
            txtUserSearch: '',
            deleteData: false
        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Users');
        this.props.componentprops.ShowAdvanceSearch(true);
    }
    ShowUserEntryModal = () => {
        this.props.componentprops.OpenModal(UserEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-title', titleClass: 'profile-entry-title', title: 'Add User' }, { action: 'user', name: 'user', close: this.CloseModal })
        // this.props.componentprops.OpenModal(UserEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Add User Profile' }, { action: 'profile', name: 'Profile', close: this.CloseModal })
    }
    HideUserEntryModal = () => {
        this.setState({
            showUserEntryModal: false, txtCustomerNumber: ''
        })
    }
    HandleGetUserListResponse = (res) => {
        // alert(JSON.stringify(res.data))
        if (res.data && res.data.userlist && res.data.userlist.rows) {
           
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "User List Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
        this.setState({
            getUserList: false
        })
    }
    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    CloseModal = () => {
        this.props.componentprops.HandleCloseModal();
        this.setState({
            getUserList: true
        })
    }
    HandleUserAction = (e, id, status) => {
       
        const val = e.target.value;
        const name = e.target.name;
       


        if (val === 'Detail') {
            this.setState({
                getUserList: true
            })
            let action = { id: id, action: val, status: status };
            this.props.componentprops.OpenModal(UserEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'User Details' }, { action: action, name: 'User Details', close: this.CloseModal })
        }
        else if (val === 'Delete') {
            if (window.confirm("Are you sure?"))
                this.setState({ deleteData: true, currentSeletedUserId: id, getUserList: false });
        }
        else if (val === 'ChangeStatus') {
            status === '0' ? status = '1' : status = '0'
            this.setState({
                changeStatus: true, requiredUserStatus: status, currentSeletedUserId: id
            })
        }
        else if (name === 'ChangeStatus') {
            status === '0' ? status = '1' : status = '0'
            this.setState({
                changeStatus: true, requiredUserStatus: status, currentSeletedUserId: id
            })
        }
        this.setState({ [name]: '' });
    }
    HandleSuccessChangeUserStatusResponse = (res) => {
        if (res !== false) {
            if (res.data && res.data.userstatusresp.value === "SUCCESS") {
               
                this.setState({

                    changeStatus: false, requiredUserStatus: '', currentSeletedUserId: '', getUserList: true

                })
            }

        }
        else {
            // Track Error
        }
    }
    HandleUserSearch = (e) => {
        let val = e.target.value;
        let name = e.target.name;
       
        this.setState({
            [name]: val,

        })
        if (val.length >= 3) {
            this.setState({

                getSearch: true
            })
        }
        else {
            this.setState({

                getSearch: false,
            })
        }
        if (val === '') {
            this.setState({

                getSearch: false,
                getUserList: true
            })
        }

    }
    HandleSuccessSearchUserListResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.userlist && res.data.userlist.rows) {
                this.props.PnkStore.SetData('userlist', res.data.userlist);
                this.props.PnkStore.BroadCast();
            }
            this.setState({
                getSearch: false
            })
        }
    }
    HandleSuccessDeleteUserResponse = (res) => {
        this.setState({
            deleteData: false,
            getUserList: true
        })
    }
    render() {
        return (
            <Container fluid className="data-table">
                <Options ShowUserEntryModal={this.ShowUserEntryModal} mystate={this.state} HandleUserSearch={this.HandleUserSearch} />
                <Table mystate={this.state} {...this.props} userlist={this.props.PnkStore.GetData('userlist')} HandleUserAction={this.HandleUserAction} />
                <Footer {...this.props} ShowUserEntryModal={this.ShowUserEntryModal} />

                {this.state.getUserList === true ? GetUserList(this.props.PnkStore, this.state.setNo, this.state.setLength, this.HandleGetUserListResponse) : ''}
                {((this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {this.state.changeStatus === true ? ChangeUserStatus(this.props.PnkStore, {}, this.state.requiredUserStatus, this.state.currentSeletedUserId, this.HandleSuccessChangeUserStatusResponse) : ''}
                {this.state.getSearch === true ? SearchUserList(this.props.PnkStore, 'user_name', this.state.txtUserSearch, this.HandleSuccessSearchUserListResponse) : ''}
                {this.state.deleteData === true ? DeleteUser(this.props.PnkStore, this.state.currentSeletedUserId, this.HandleSuccessDeleteUserResponse) : ''}
            </Container >
        )
    }
}
export const UserPage = PnkConnect(User, "searchFullData");