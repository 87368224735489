import React from 'react'
import { Row, Col, Button, Form, Tooltip, OverlayTrigger, Overlay } from 'react-bootstrap'
import Pagination from '../Pagination'
export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            selectAll: false,
            checkedC: true,
            showProfileEntry: false,
            numItemsPerPage: 10,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 0


        }
        this.target = React.createRef()
    }


    render() {
        let totalPages = 0; let length = 0;
        if (this.props.mystate.currentChoice === "city") {

            if (this.props.PnkStore.GetData("citylist") && this.props.PnkStore.GetData("citylist") && this.props.PnkStore.GetData("citylist").rows &&
                this.props.PnkStore.GetData("citylist").rows.length > 0) {
                length = this.props.PnkStore.GetData("citylist").rows.length
                totalPages = Math.ceil(length / this.state.numItemsPerPage);
            }
        }
        if (this.props.mystate.currentChoice === "area") {

            if (this.props.PnkStore.GetData("arealist") && this.props.PnkStore.GetData("arealist") && this.props.PnkStore.GetData("arealist").rows &&
                this.props.PnkStore.GetData("arealist").rows.length > 0) {
                length = this.props.PnkStore.GetData("arealist").rows.length
                totalPages = Math.ceil(length / this.state.numItemsPerPage);
            }
        }
        if (this.props.mystate.currentChoice === "state") {

            if (JSON.stringify(this.props.mystate.stateList) !== '{}' && this.props.mystate.stateList.rows.length > 0) {
                length = this.props.mystate.stateList.rows.length
                totalPages = Math.ceil(length / this.state.numItemsPerPage);
            }
        }
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">

                                    <Row className="mob-row-align">

                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                        </Col>

                                        <Col lg={2} xs={4}>
                                            {this.props.mystate.currentChoice === "city" ?
                                                <h3 className="table-head-lead ">City Name</h3> : null}
                                            {this.props.mystate.currentChoice === "area" ?
                                                <h3 className="table-head-lead ">Area Name</h3> : null}
                                            {this.props.mystate.currentChoice === "state" ?
                                                <h3 className="table-head-lead ">State Name</h3> : null}
                                        </Col>

                                        <Col lg={3} xs={4}>
                                            {this.props.mystate.currentChoice === "city" ?
                                                <h3 className="table-head-lead">State Name</h3> : null}
                                            {this.props.mystate.currentChoice === "area" ?
                                                <h3 className="table-head-lead">City Name</h3> : null}
                                            {this.props.mystate.currentChoice === "state" ?
                                                <h3 className="table-head-lead">Country Name</h3> : null}
                                        </Col>

                                        <Col lg={2} className="hide-mob">
                                            <h3 className="table-head-lead ">Added on</h3>
                                        </Col>
                                        <Col lg={2} xs={3} className="hide-mob">
                                            <h3 className="table-head-lead">Status</h3>
                                        </Col>

                                        <Col lg={2} className="" xs={4}>
                                            <h3 className="table-head-lead">Action</h3>
                                        </Col>

                                    </Row>
                                    <div>
                                        <div>
                                            {this.props.mystate.currentChoice === "city" ?
                                                this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                                    this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                                        (i >= this.props.mystate.startValue) && (i < this.props.mystate.endValue) ?
                                                            <div className="lead-table-data-user">
                                                                <Row className="table-items-user" style={{ background: '#ffffff' }}>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div> {i + 1}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="" xs={4}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.city_name}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={3} className="" xs={4}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.state_name}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.city_added_on}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} xs={3} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            {item.city_status === 1 ?
                                                                                <div className="lead-data">
                                                                                    <div className="status-entry-list-active">
                                                                                        Active
                                                                        </div>
                                                                                </div> :
                                                                                <div className="lead-data">
                                                                                    <div className="status-entry-list-error">
                                                                                        Inactive
                                                                        </div>
                                                                                </div>
                                                                            }



                                                                        </div>
                                                                    </Col>


                                                                    <Col lg={2} xs={4} className="">
                                                                        <div className="table-data-user">


                                                                            <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.profile_status)}>
                                                                                <option value="" selected>Take Action</option>
                                                                                <option value="Detail">Detail</option>
                                                                                {sessionStorage.getItem('current_user_profile') === "Administrator" ?
                                                                                    <option value="Delete">Delete</option> : null}

                                                                            </Form.Control>

                                                                        </div>



                                                                    </Col>

                                                                </Row>
                                                            </div> : null
                                                    ) : null
                                                : ""}

                                        </div>
                                        <div>
                                            {this.props.mystate.currentChoice === "area" ?
                                                this.props.PnkStore.GetData("arealist") !== '{}' && this.props.PnkStore.GetData("arealist").rows !== undefined && this.props.PnkStore.GetData("arealist").rows.length > 0 ?
                                                    this.props.PnkStore.GetData("arealist").rows.map((item, i) =>
                                                        (i >= this.props.mystate.startValue) && (i < this.props.mystate.endValue) ?
                                                            <div className="lead-table-data-user">
                                                                <Row className="table-items-user" style={{ background: '#ffffff' }}>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div> {i + 1}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="" xs={4}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.area_name}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={3} className="" xs={4}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.city_name}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.area_added_on}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} xs={3} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            {item.area_status === 1 ?
                                                                                <div className="lead-data">
                                                                                    <div className="status-entry-list-active">
                                                                                        Active
                                                                        </div>
                                                                                </div> :
                                                                                <div className="lead-data">
                                                                                    <div className="status-entry-list-error">
                                                                                        Inactive
                                                                        </div>
                                                                                </div>
                                                                            }



                                                                        </div>
                                                                    </Col>


                                                                    <Col lg={2} xs={4} className="">
                                                                        <div className="table-data-user">


                                                                            <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.profile_status)}>
                                                                                <option value="" selected>Take Action</option>
                                                                                <option value="Detail">Detail</option>
                                                                                {sessionStorage.getItem('current_user_profile') === "Administrator" ?
                                                                                    <option value="Delete">Delete</option> : null}

                                                                            </Form.Control>

                                                                        </div>



                                                                    </Col>

                                                                </Row>
                                                            </div> : null
                                                    ) : null
                                                : ""

                                            }
                                        </div>
                                        <div>
                                            {this.props.mystate.currentChoice === "state" ?
                                                JSON.stringify(this.props.mystate.stateList) !== '{}' && this.props.mystate.stateList.rows.length > 0 ?
                                                    this.props.mystate.stateList.rows.map((item, i) => (
                                                        (i >= this.props.mystate.startValue) && (i < this.props.mystate.endValue) ?

                                                            <div className="lead-table-data-user">
                                                                <Row className="table-items-user" style={{ background: '#ffffff' }}>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div> {i + 1}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="" xs={4}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.state_name}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={3} className="" xs={4}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.country_name}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.state_added_on}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} xs={3} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            {item.state_status === 1 ?
                                                                                <div className="lead-data">
                                                                                    <div className="status-entry-list-active">
                                                                                        Active
                                                                        </div>
                                                                                </div> :
                                                                                <div className="lead-data">
                                                                                    <div className="status-entry-list-error">
                                                                                        Inactive
                                                                        </div>
                                                                                </div>
                                                                            }



                                                                        </div>
                                                                    </Col>


                                                                    <Col lg={2} xs={4} className="">
                                                                        <div className="table-data-user">


                                                                            <Form.Control as="select" className="lead-action-select" onChange={(e) => this.props.HandleUserAction(e, item._id, item.profile_status)}>
                                                                                <option value="" selected>Take Action</option>
                                                                                <option value="Detail">Detail</option>
                                                                                {sessionStorage.getItem('current_user_profile') === "Administrator" ?
                                                                                    <option value="Delete">Delete</option> : null}

                                                                            </Form.Control>

                                                                        </div>



                                                                    </Col>

                                                                </Row>

                                                            </div>


                                                            : null
                                                    )

                                                    ) : null
                                                : ""}

                                            {this.state.numItemsPerPage < length && this.props.mystate.currentChoice === "area" ?
                                                <div className="pagination-div-profile">
                                                    <Pagination totalNumber={totalPages} currentPageNumber={this.props.mystate.currentPageNumber} ChangePage={this.props.ChangePage} />
                                                </div> : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row >
        )
    }
}