import React from 'react'
import { Row, Col, Button, ButtonGroup, Form, Image } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
export default class DailyReport extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    <Row className="mob-row-align mob-row-color">
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Date</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">RM NAME</h3>
                                        </Col>
                                        {/* <Col lg={1} className="hide-mob">
                                    <h3 className="table-head-lead">Reporting To</h3>
                                </Col> */}
                                        <Col lg={3} className="hide-mob">
                                            <h3 className="table-head-lead">Client Details</h3>
                                        </Col>
                                        {/* <Col lg={1} className="hide-mob">
                                    <h3 className="table-head-lead">Contact Number</h3>
                                </Col> */}
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Location</h3>
                                        </Col>


                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">HC Completed.</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">PM Completed</h3>
                                        </Col>
                                        {/* <Col lg={1} className="hide-mob">
                                    <h3 className="table-head-lead">VC Completed</h3>
                                </Col> */}

                                    </Row>
                                </div>
                                <div>
                                    {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                        (item, i) => (
                                            <div className="lead-table-data-user">

                                                {((i + 1) % 2 === 0) ?
                                                    (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                        <Col lg={1} className="hide-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div> {i + 1}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.date}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.rm_name}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={3} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.client_name}
                                                                        <a onClick={() => this.props.GotoLeadEntry(item.id)} className="ml-1 icon-padding ">Details</a>

                                                                    </div>
                                                                    <div className="text-overflow">{item.client_contact}</div>


                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.location}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.hc_completed_date_time}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.project_completed_date_time}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        {/* <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.vc_completed_date_time}</div>

                                                                </div>
                                                            </div>

                                                        </Col> */}
                                                    </Row>) :
                                                    (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                        <Col lg={1} className="hide-mob">
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div> {i + 1}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.date}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.rm_name}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={3} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.client_name}
                                                                        <a onClick={() => this.props.GotoLeadEntry(item.id)} className="ml-1 icon-padding ">Details</a>
                                                                    </div>
                                                                    <div className="text-overflow">{item.client_contact}</div>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="text-overflow">{item.location}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.hc_completed_date_time}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.project_completed_date_time}</div>

                                                                </div>
                                                            </div>

                                                        </Col>
                                                        {/* <Col lg={1} className="" xs={4}>
                                                            <div className="table-data-user">
                                                                <div className="lead-data">
                                                                    <div className="">{item.vc_completed_date_time}</div>

                                                                </div>
                                                            </div>

                                                        </Col> */}
                                                    </Row>)
                                                }
                                            </div>
                                        )) : null}
                                </div>


                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )

    }
}