import React from 'react'
import { Row, Col, Button, Form, Image, OverlayTrigger, Overlay } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons'
import Thumbnail from '../../assets/images/thumbnail.jpg'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { vegList, herbList, fruitList, greenList } from './data'
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'date-time-picker'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Input from '@material-ui/core/Input';
import 'date-time-picker/dist/date-time-picker.min.css'
// import DatePicker from "react-datepicker";
import TextField from '@material-ui/core/TextField';
import PnkImage from '../../pnk-react/pnk-ctrl/pnk-image'
import Exotel_Calls from '../../assets/images/Exotel_Calls.svg'

// import {
//     MuiPickersUtilsProvider,
//     KeyboardTimePicker,
//     KeyboardDatePicker
//   } from "@material-ui/pickers";
export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            vegOpen: false,
            greenOpen: false,
            herbOpen: false,
            fruitOpen: false
        }
    }
    ShowCancelModal = (customer_name, customer_no) => {

        this.props.componentprops.OpenModal(Table, 'sm', { customer_no: customer_no.replace(/[^0-9]/g, ""), customer_name: 'customer_name' })

    }
    ToggleView = (divId) => {
        if (this.props.mystate.isMobile === true) {
            var x = document.getElementById(divId);
            if (x.style.display === 'none') {
                x.style.display = 'block';
            } else {
                x.style.display = 'none';
            }
        }

    }
    ShowDatePicker = () => {
        let config = {
            lang: 'EN', // default 'EN'. One of 'EN', 'zh-CN'
            format: 'yyyy-MM-dd', // default 'yyyy-MM-dd'
            default: '2016-10-19', // default `new Date()`. If `default` type is string, then it will be parsed to `Date` instance by `format` . Or it can be a `Date` instance
            min: '2020-02-10', // min date value, `{String | Date}`, default `new Date(1900, 0, 1, 0, 0, 0, 0)`
            max: '2028-11-08' // max date value, `{String | Date}`, default `new Date(2100, 11, 31, 23, 59, 59, 999)`
        }
        var datePicker = new DateTimePicker.Date(config)
        datePicker.on('selected', function (formatDate, now) {


        })
        datePicker.on('cleared', function () {
            // clicked clear btn
        })
    }
    ShowTimePicker = () => {
        let config = {
            lang: 'EN', // default 'EN'
            format: 'HH:mm', // default 'HH:mm'
            default: '12:27', // default `new Date()`. If `default` type is string, then it will be parsed to `Date` instance by `format` . Or it can be a `Date` instance
            minuteStep: 5, // default 5. Select minutes step, must be one of [1, 5, 10]
            min: '00:00', // min time value, `{String | Date}`, default `new Date(1900, 0, 1, 0, 0, 0, 0)`
            max: '23:59' // max time value, `{String | Date}`, default `new Date(2100, 11, 31, 23, 59, 59, 999)`
        }
        var timePicker = new DateTimePicker.Time(config)
        timePicker.on('selected', function (formatTime, now) {

        })
        timePicker.on('cleared', function () {
            // clicked clear btn
        })
    }
    OpenDialog = (type) => {
        let stateVar = type + 'Open'
        this.setState({
            [stateVar]: true
        })
    }
    CloseDialog = (type) => {
        let stateVar = type + 'Open'
        this.setState({
            [stateVar]: false
        })
    }


    HandleGrowListChange = (key, type, action) => {


        this.props.GetCurrentGrowLits(key, type, action)
    }
    componentWillReceiveProps = (props) => {
        if (props.mystate.ddlVegList && props.mystate.ddlVegList.length > 0) {
            props.mystate.ddlVegList.map(list => {

                if (list.type === 'vegetables') {

                    if (document.getElementById(list.item) !== null) {
                        document.getElementById(list.item).value = list.quantity
                    }
                }

            })
        }
        if (props.mystate.ddlGreenList && props.mystate.ddlGreenList.length > 0) {
            props.mystate.ddlGreenList.map(list => {
                if (list.type === 'greens') {
                    if (document.getElementById(list.item) !== null) {
                        document.getElementById(list.item).value = list.quantity
                    }
                }
            })
        }
        if (props.mystate.ddlHerbList && props.mystate.ddlHerbList.length > 0) {
            props.mystate.ddlHerbList.map(list => {
                if (list.type === 'herbs') {
                    if (document.getElementById(list.item) !== null) {
                        document.getElementById(list.item).value = list.quantity
                    }
                }
            })
        }
        if (props.mystate.ddlFruitList && props.mystate.ddlFruitList.length > 0) {
            props.mystate.ddlFruitList.map(list => {
                if (list.type === 'fruits') {
                    if (document.getElementById(list.item) !== null) {
                        document.getElementById(list.item).value = list.quantity
                    }
                }
            })
        }
    }



    render() {


        // const showtxtTerraceBudgetOtherOption = this.props.mystate.showtxtTerraceBudgetOtherOption

        let cityData = []; let profileData = []; let propertyData = []; let leadSourceData = []; let filteredData = []
        if (this.props.PnkStore.GetData("leaddata") &&
            this.props.PnkStore.GetData("leaddata").length > 0) {

            if (this.props.PnkStore.GetData("leaddata")[0] && this.props.PnkStore.GetData("leaddata")[0].citylist && this.props.PnkStore.GetData("leaddata")[0].citylist.rows) {
                cityData = this.props.PnkStore.GetData("leaddata")[0].citylist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[1] && this.props.PnkStore.GetData("leaddata")[1].proptypelist && this.props.PnkStore.GetData("leaddata")[1].proptypelist.rows) {
                propertyData = this.props.PnkStore.GetData("leaddata")[1].proptypelist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[2] && this.props.PnkStore.GetData("leaddata")[2].leadsourcelist && this.props.PnkStore.GetData("leaddata")[2].leadsourcelist.rows) {
                leadSourceData = this.props.PnkStore.GetData("leaddata")[2].leadsourcelist.rows;
            }
            if (this.props.PnkStore.GetData("leaddata")[3] && this.props.PnkStore.GetData("leaddata")[3].profilelist && this.props.PnkStore.GetData("leaddata")[3].profilelist.rows) {
                profileData = this.props.PnkStore.GetData("leaddata")[3].profilelist.rows;
                if (profileData.length > 0) {

                    if (sessionStorage.getItem('current_user_profile') === 'Sales TL') {
                        filteredData = []
                        profileData.map(profile => {
                            if (profile.profile_name == 'Sales' || profile.profile_name == 'Sales TL') {
                                filteredData.push(profile)
                            }

                        })
                    }


                    else {
                        filteredData = profileData
                    }
                }
            }

        }
        let userMobileNumber = sessionStorage.getItem("current_user_mobile")
        return (
            <div className="lead-entry">
                <div className="lead-entry-main">
                    <div className="crm-container">
                        {/* <div className="personal-div-main"></div> */}

                        <PnkHtmlForm id="frmLeadEntry" onsubmit={this.props.SaveLeadData} submitbtn="btnLeadEntry">
                            <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>

                            </div>

                            <fieldset>
                                <div id="frmLeadEntryActivity">
                                    <div className="le-section" >
                                        <div className="mob-container">
                                            <Row>
                                                <Col lg={9}>
                                                    <div className="personal-div">
                                                        <h3 className="form-heading"> Personal Details</h3>
                                                        <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("personalDetails")} />

                                                    </div>
                                                </Col>
                                                <Col lg={3} className="flex-end hide-mob">
                                                    <div className="lead-entry-btn-group">
                                                        {this.props.mystate.mode === 'edit' ?

                                                            <a onClick={() => this.props.ShowCallModal(this.props.editDataObj.txtPhone, userMobileNumber)} className="lead-entry-exotel-call-btn icon-padding "><img src={Exotel_Calls} style={{ height: 15 }} /></a>

                                                            : ''}
                                                        {/* <a  className="icon-padding mr-2"><img src={Exotel_Calls} style={{ height: 15 }} /></a> */}
                                                        {/* <Button variant="light" className="upload-builk-lead mr-3" onClick={() => this.props.ShowBulkUpload()}>+ Upload Bulk lead</Button> */}
                                                        <Button variant="light" className="le-cancel mr-3" onClick={() => this.props.ShowCancel()}>CANCEL</Button>
                                                        <button className="le-save pnkvalidatesubmit" name="Save" id="btnLeadEntry" onClick={this.props.SaveLeadData}>{this.props.mystate.showButtonType}</button>






                                                        {/* <Button variant="light" className="le-save" onClick={this.props.SaveLeadData}>{this.props.mystate.showButtonType}</Button> */}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row id="personalDetails">
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >

                                                        <Form.Control type="text" className="le-form-controls"
                                                            name="txtName" id="txtName"
                                                            pnkvalidate="forceentry"
                                                            value={this.props.editDataObj.txtName}
                                                            pnkvalue={this.props.editDataObj.txtName}
                                                            onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Name" />
                                                        <label class="floating-label" >Name</label>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control type="text" className="le-form-controls" id="txtEmail" name="txtEmail"
                                                            pnkvalidate="forceentry"
                                                            pnkvalue={this.props.editDataObj.txtEmail}
                                                            value={this.props.editDataObj.txtEmail}
                                                            onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Email" />
                                                        <span class="floating-label" for="txtEmail">Email</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control type="text" className="le-form-controls"
                                                            id="txtPhone"
                                                            name="txtPhone"
                                                            pnkvalidate="forcemobile"
                                                            pnkvalue={this.props.editDataObj.txtPhone}
                                                            maxLength={10}
                                                            value={this.props.editDataObj.txtPhone}
                                                            onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Phone" />
                                                        <span className="floating-label" for="txtPhone">Phone No</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control type="text" className="le-form-controls" id="txtCity" name="txtCity"
                                                            pnkvalue={this.props.editDataObj.txtCity}
                                                            value={this.props.editDataObj.txtCity}
                                                            onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid City" />
                                                        <span className="floating-label" for="txtCity">City </span>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >
                                                        <Form.Control as="select" className="le-form-controls-select" name="txtPreferedTime" id="txtPreferedTime"
                                                            onChange={this.props.HandleUserInputs}
                                                            // pnkvalidate='forceselect'
                                                            pnkdisplayerrormsg='Select Type of Solution'
                                                            pnkvalue={this.props.editDataObj.txtPreferedTime}
                                                            value={this.props.editDataObj.txtPreferedTime}
                                                            style={{ padding: '4px', width: '100%' }} >
                                                            <option value="" disabled selected>Preferred Time</option>
                                                            <option value="00:00">00:00</option>
                                                            <option value="00:30">00:30</option>
                                                            <option value="01:00">01:00</option>
                                                            <option value="01:30">01:30</option>
                                                            <option value="02:00">02:00</option>
                                                            <option value="02:30">02:30</option>
                                                            <option value="03:00">03:00</option>
                                                            <option value="03:30">03:30</option>
                                                            <option value="04:00">04:00</option>
                                                            <option value="04:30">04:30</option>
                                                            <option value="05:00">05:00</option>
                                                            <option value="05:30">05:30</option>
                                                            <option value="06:00">06:00</option>
                                                            <option value="06:30">06:30</option>
                                                            <option value="07:00">07:00</option>
                                                            <option value="07:30">07:30</option>
                                                            <option value="08:00">08:00</option>
                                                            <option value="08:30">08:30</option>
                                                            <option value="09:00">09:00</option>
                                                            <option value="09:30">09:30</option>
                                                            <option value="10:00">10:00</option>
                                                            <option value="10:30">10:30</option>
                                                            <option value="11:00">11:00</option>
                                                            <option value="11:30">11:30</option>
                                                            <option value="12:00">12:00</option>
                                                            <option value="12:30">12:30</option>
                                                            <option value="03:00">13:00</option>
                                                            <option value="13:30">13:30</option>
                                                            <option value="14:00">14:00</option>
                                                            <option value="14:30">14:30</option>
                                                            <option value="15:00">15:00</option>
                                                            <option value="15:30">15:30</option>
                                                            <option value="16:00">16:00</option>
                                                            <option value="16:30">16:30</option>
                                                            <option value="17:00">17:00</option>
                                                            <option value="17:30">17:30</option>
                                                            <option value="18:00">18:00</option>
                                                            <option value="18:30">18:30</option>
                                                            <option value="19:00">19:00</option>
                                                            <option value="19:30">19:30</option>
                                                            <option value="20:00">20:00</option>
                                                            <option value="20:30">20:30</option>
                                                            <option value="21:00">21:00</option>
                                                            <option value="21:30">21:30</option>
                                                            <option value="22:00">22:00</option>
                                                            <option value="22:30">22:30</option>
                                                            <option value="23:00">23:00</option>
                                                            <option value="23:30">23:30</option>

                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} className="lead-col">
                                                    <Form.Group >

                                                        <TextField
                                                            id="txtPreferedDate"
                                                            name="txtPreferedDate"
                                                            // label="txtPreferedDate"
                                                            type="date"
                                                            pnkvalue={this.props.editDataObj.txtPreferedDate}
                                                            value={this.props.editDataObj.txtPreferedDate}
                                                            onChange={this.props.HandleUserInputs}
                                                            style={{ width: '100%' }}

                                                        />

                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>

                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div ">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Site Requirement</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("landscapeDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="landscapeDiv">
                                                    <Row>


                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name='ddlPreferredCity'
                                                                    id='ddlPreferredCity'
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select City'
                                                                    pnkvalue={this.props.editDataObj.ddlPreferredCity}
                                                                    value={this.props.editDataObj.ddlPreferredCity}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>City</option>
                                                                    {(cityData && cityData.length > 0) ? cityData.map(item =>
                                                                        item.city_name !== 'NA' ?
                                                                            <option value={item.city_name}>{item.city_name}</option>
                                                                            : ''
                                                                    ) : ""}
                                                                </Form.Control>
                                                                {/* <span className="floating-label">City</span> */}
                                                            </Form.Group>

                                                        </Col>


                                                        <Col lg={4} className="lead-col">

                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlLocality"
                                                                    id="ddlLocality"

                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Locality'
                                                                    pnkvalue={this.props.editDataObj.ddlLocality}
                                                                    value={this.props.editDataObj.ddlLocality}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Locality</option>

                                                                    {(this.props.mystate.areaList && this.props.mystate.areaList.rows && this.props.mystate.areaList.rows.length > 0) ? this.props.mystate.areaList.rows.map(item =>
                                                                        <option value={item.area_name}>{item.area_name}</option>
                                                                    ) : ""}
                                                                </Form.Control>
                                                                {/* <span className="floating-label">Locality</span> */}
                                                            </Form.Group>
                                                        </Col>



                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtAddress" id="txtAddress"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    pnkvalue={this.props.editDataObj.txtAddress}
                                                                    value={this.props.editDataObj.txtAddress}
                                                                    pnkdisplayerrormsg="Invalid Address" />
                                                                <span className="floating-label" for="txtAddress">Address</span>
                                                            </Form.Group>
                                                        </Col>





                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" name="txtPincode" id="txtPincode"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate="forceentry"
                                                                    maxLength={6}
                                                                    pnkvalue={this.props.editDataObj.txtPincode}
                                                                    value={this.props.editDataObj.txtPincode}
                                                                    pnkdisplayerrormsg="Invalid Pincode" />
                                                                <span className="floating-label" for="txtPincode">Pin Code</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select" name="ddlPropertyType" id="ddlPropertyType"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Property Type'
                                                                    pnkvalue={this.props.editDataObj.ddlPropertyType}
                                                                    value={this.props.editDataObj.ddlPropertyType}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Property Type</option>
                                                                    <option value="Office">Office</option>
                                                                    <option value="Villa">Villa</option>
                                                                    <option value="Apartment">Apartment</option>
                                                                    <option value="PentHouse">Pent House</option>
                                                                </Form.Control>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select" name="ddlOwnership" id="ddlOwnership"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Ownership'
                                                                    pnkvalue={this.props.editDataObj.ddlOwnership}
                                                                    value={this.props.editDataObj.ddlOwnership}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Ownership</option>
                                                                    <option value="Own">Own</option>
                                                                    <option value="Rented">Rented</option>

                                                                </Form.Control>

                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                    <InputLabel htmlFor="ddlSpace" className="le-label-multi">Type of Space to be designed</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlSpace}
                                                                        pnkvalue={this.props.editDataObj.ddlSpace}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlSpace',
                                                                            id: 'ddlSpace',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Type of Space to be designed	"
                                                                    >
                                                                        {this.props.spaceType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                                <Checkbox checked={this.props.spaceType.indexOf(name) > -1} className="le-checkbox" />
                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select" name="ddlSolution" id="ddlSolution"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Type of Solution'
                                                                    pnkvalue={this.props.editDataObj.ddlSolution}
                                                                    value={this.props.editDataObj.ddlSolution}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Type of Solution</option>
                                                                    <option value="VegetableGarden">Vegetable Garden</option>
                                                                    <option value="VerticalGarden">Vertical Garden</option>
                                                                    <option value="Both">Both</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>


                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="le-section" style={{ display: this.props.mystate.showBalconyDescDiv }}>
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Balcony Description</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("reqDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="reqDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlUtilisationSpace" id="ddlUtilisationSpace"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Utilisation of space'
                                                                    pnkvalue={this.props.editDataObj.ddlUtilisationSpace}
                                                                    value={this.props.editDataObj.ddlUtilisationSpace}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Utilisation of space</option>
                                                                    <option value="Entertainment">Entertainment</option>
                                                                    <option value="Relaxation">Relaxation</option>
                                                                    <option value=" Exercise"> Exercise/Yoga space</option>
                                                                    <option value=" HomeOffice"> Home Office</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenNeed" id="ddlGardenNeed"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Garden Need'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenNeed}
                                                                    value={this.props.editDataObj.ddlGardenNeed}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden Need	</option>
                                                                    <option value="Sctrach">Start from Sctrach</option>
                                                                    <option value="Re-do">Re-do garden</option>
                                                                    <option value="Replaced">Plants to be replaced</option>
                                                                    <option value="Hardscaping">Need some hardscaping</option>
                                                                    <option value="Others">Others</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlApproxSize" id="ddlApproxSize"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Approx Size'
                                                                    pnkvalue={this.props.editDataObj.ddlApproxSize}
                                                                    value={this.props.editDataObj.ddlApproxSize}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Approx Size	</option>
                                                                    <option value="0 to 50">0 to 50(sqft)</option>
                                                                    <option value="50 to 100">50 to 100(sqft)</option>
                                                                    <option value="100 to 150">100 to 150(sqft)</option>
                                                                    <option value="150 to 300">150 to 300(sqft)</option>
                                                                    <option value="300 to 500">300 to 500(sqft)</option>
                                                                    <option value="500 to 700">500 to 700(sqft)</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlSunlight" id="ddlSunlight"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Sunlight'
                                                                    pnkvalue={this.props.editDataObj.ddlSunlight}
                                                                    value={this.props.editDataObj.ddlSunlight}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Sunlight	</option>
                                                                    <option value="Complete Indoor">Complete Indoor</option>
                                                                    <option value="No direct sunlight">No direct sunlight</option>
                                                                    <option value="1-2 hours">1-2 hours</option>
                                                                    <option value="2-4 hours">2-4 hours</option>
                                                                    <option value=">6 hours"> &gt;6 hours</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlLookPreferred" id="ddlLookPreferred"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Type of Looks Preferred'
                                                                    pnkvalue={this.props.editDataObj.ddlLookPreferred}
                                                                    value={this.props.editDataObj.ddlLookPreferred}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Type of Looks Preferred?</option>
                                                                    <option value="Contemporary">Contemporary </option>
                                                                    <option value="Indian">Indian</option>
                                                                    <option value="Mediterranean">Mediterranean</option>
                                                                    <option value="Tropical">Tropical</option>
                                                                    <option value="Zen">Zen</option>
                                                                    <option value="NotSpecified">Not Specified</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <FormControl variant="outlined" className="width_100" >
                                                                    <InputLabel htmlFor="ddlPlantsPreferred" className="le-label-multi">Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlPlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlPlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlPlantsPreferred',
                                                                            id: 'ddlPlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Type of Plants Preferred	"
                                                                    >
                                                                        {this.props.plantsType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtBalconyAnyIssues" name="txtBalconyAnyIssues"
                                                                    pnkvalue={this.props.editDataObj.txtBalconyAnyIssues}
                                                                    value={this.props.editDataObj.txtBalconyAnyIssues}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtEmail">Any issues encountered</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenFacing" id="ddlGardenFacing"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Garden Facing'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenFacing}
                                                                    value={this.props.editDataObj.ddlGardenFacing}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden facing</option>
                                                                    <option value="North">North</option>
                                                                    <option value="South">South</option>
                                                                    <option value="East">East</option>
                                                                    <option value="West">West</option>
                                                                    <option value="NotSpecified">Not Specified</option>
                                                                    {/* <option value="5">5</option> */}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlBalconyBudget" id="ddlBalconyBudget"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.balconyValidation}
                                                                    pnkdisplayerrormsg='Select Garden Budget'
                                                                    pnkvalue={this.props.editDataObj.ddlBalconyBudget}
                                                                    value={this.props.editDataObj.ddlBalconyBudget}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Balcony Budget</option>
                                                                    {this.props.budget.length > 0 && this.props.budget.map(item =>
                                                                        <option value={item}>{item}</option>

                                                                    )}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showOtherBalconyBudget }}>
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtOtherBalconyBudget" name="txtOtherBalconyBudget"
                                                                    pnkvalue={this.props.editDataObj.txtOtherBalconyBudget}
                                                                    value={this.props.editDataObj.txtOtherBalconyBudget}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtEmail">Other Balcony Budget</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section" style={{ display: this.props.mystate.showTerraceDescDiv }}>
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Terrace Description</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("reqDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="reqDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceUtilisationSpace" id="ddlTerraceUtilisationSpace"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Utilisation of space'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceUtilisationSpace}
                                                                    value={this.props.editDataObj.ddlTerraceUtilisationSpace}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Utilisation of space</option>
                                                                    <option value="Entertainment">Entertainment</option>
                                                                    <option value="Relaxation">Relaxation</option>
                                                                    <option value=" Exercise"> Exercise/Yoga space</option>
                                                                    <option value=" HomeOffice"> Home Office</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceGardenNeed" id="ddlTerraceGardenNeed"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Garden Need'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceGardenNeed}
                                                                    value={this.props.editDataObj.ddlTerraceGardenNeed}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden Need	</option>
                                                                    <option value="Sctrach">Start from Sctrach</option>
                                                                    <option value="Re-do">Re-do garden</option>
                                                                    <option value="Replaced">Plants to be replaced</option>
                                                                    <option value="Hardscaping">Need some hardscaping</option>
                                                                    <option value="Others">Others</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceApproxSize" id="ddlTerraceApproxSize"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Approx Size'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceApproxSize}
                                                                    value={this.props.editDataObj.ddlTerraceApproxSize}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Approx Size	</option>
                                                                    <option value="0 to 50">0 to 50(sqft)</option>
                                                                    <option value="50 to 100">50 to 100(sqft)</option>
                                                                    <option value="100 to 150">100 to 150(sqft)</option>
                                                                    <option value="150 to 300">150 to 300(sqft)</option>
                                                                    <option value="300 to 500">300 to 500(sqft)</option>
                                                                    <option value="500 to 700">500 to 700(sqft)</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceSunlight" id="ddlTerraceSunlight"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Sunlight'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceSunlight}
                                                                    value={this.props.editDataObj.ddlTerraceSunlight}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Sunlight	</option>
                                                                    <option value="Complete Indoor">Complete Indoor</option>
                                                                    <option value="No direct sunlight">No direct sunlight</option>
                                                                    <option value="1-2 hours">1-2 hours</option>
                                                                    <option value="2-4 hours">2-4 hours</option>
                                                                    <option value=">6 hours"> &gt;6 hours</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceLookPreferred" id="ddlTerraceLookPreferred"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Type of Looks Preferred'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceLookPreferred}
                                                                    value={this.props.editDataObj.ddlTerraceLookPreferred}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Type of Looks Preferred?</option>
                                                                    <option value="Contemporary">Contemporary </option>
                                                                    <option value="Indian">Indian</option>
                                                                    <option value="Mediterranean">Mediterranean</option>
                                                                    <option value="Tropical">Tropical</option>
                                                                    <option value="Zen">Zen</option>
                                                                    <option value="NotSpecified">Not Specified</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <FormControl variant="outlined" className="width_100" >
                                                                    <InputLabel htmlFor="ddlTerracePlantsPreferred" className="le-label-multi">Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlTerracePlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlTerracePlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlTerracePlantsPreferred',
                                                                            id: 'ddlTerracePlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Type of Plants Preferred	"
                                                                    >
                                                                        {this.props.plantsType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>
                                                                {/* <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerracePlantsPreferred" id="ddlTerracePlantsPreferred"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Type of Plants Preferred'
                                                                    pnkvalue={this.props.editDataObj.ddlTerracePlantsPreferred}
                                                                    value={this.props.editDataObj.ddlTerracePlantsPreferred}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Type of Plants Preferred</option>
                                                                    <option value="Climbers">Climbers</option>
                                                                    <option value="Evergreens">Evergreens</option>
                                                                    <option value="Flowers">Flowers</option>
                                                                    <option value="Lawn">Lawn</option>
                                                                    <option value="Succulents">Succulents</option>
                                                                    <option value="Trees">Trees</option>
                                                                </Form.Control> */}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <Form.Control type="text" className="le-form-controls" id="txtTerraceIssueEncountered" name="txtTerraceIssueEncountered"
                                                                    pnkvalue={this.props.editDataObj.txtTerraceIssueEncountered}
                                                                    value={this.props.editDataObj.txtTerraceIssueEncountered}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtEmail">Any issues encountered</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceGardenFacing" id="ddlTerraceGardenFacing"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Garden Facing'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceGardenFacing}
                                                                    value={this.props.editDataObj.ddlTerraceGardenFacing}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden facing</option>
                                                                    <option value="North">North</option>
                                                                    <option value="South">South</option>
                                                                    <option value="East">East</option>
                                                                    <option value="West">West</option>
                                                                    <option value="NotSpecified">Not Specified</option>
                                                                    {/* <option value="5">5</option> */}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlTerraceBudget" id="ddlTerraceBudget"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.terraceValidation}
                                                                    pnkdisplayerrormsg='Select Terrace Budget'
                                                                    pnkvalue={this.props.editDataObj.ddlTerraceBudget}
                                                                    value={this.props.editDataObj.ddlTerraceBudget}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Terrace Budget</option>
                                                                    {this.props.budget.length > 0 && this.props.budget.map(item =>
                                                                        <option value={item}>{item}</option>

                                                                    )}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showOtherTerraceBudget }}>
                                                            <Form.Group >

                                                                <Form.Control type="text" className="le-form-controls" id="txtOtherTerraceBudget" name="txtOtherTerraceBudget"
                                                                    pnkvalue={this.props.editDataObj.txtOtherTerraceBudget}
                                                                    value={this.props.editDataObj.txtOtherTerraceBudget}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtOtherTerraceBudget">Other Terrace Budget</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section" style={{ display: this.props.mystate.showGardenDescDiv }}>
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Garden Description</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("reqDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="reqDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGrdenUtilisationSpace" id="ddlGrdenUtilisationSpace"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Utilisation of space'
                                                                    pnkvalue={this.props.editDataObj.ddlGrdenUtilisationSpace}
                                                                    value={this.props.editDataObj.ddlGrdenUtilisationSpace}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Utilisation of space</option>
                                                                    <option value="Entertainment">Entertainment</option>
                                                                    <option value="Relaxation">Relaxation</option>
                                                                    <option value=" Exercise"> Exercise/Yoga space</option>
                                                                    <option value=" HomeOffice"> Home Office</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenGardenNeed" id="ddlGardenGardenNeed"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Garden Need'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenGardenNeed}
                                                                    value={this.props.editDataObj.ddlGardenGardenNeed}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden Need	</option>
                                                                    <option value="Sctrach">Start from Sctrach</option>
                                                                    <option value="Re-do">Re-do garden</option>
                                                                    <option value="Replaced">Plants to be replaced</option>
                                                                    <option value="Hardscaping">Need some hardscaping</option>
                                                                    <option value="Others">Others</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenApproxSize" id="ddlGardenApproxSize"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Approx Size'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenApproxSize}
                                                                    value={this.props.editDataObj.ddlGardenApproxSize}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Approx Size	</option>
                                                                    <option value="0 to 50">0 to 50(sqft)</option>
                                                                    <option value="50 to 100">50 to 100(sqft)</option>
                                                                    <option value="100 to 150">100 to 150(sqft)</option>
                                                                    <option value="150 to 300">150 to 300(sqft)</option>
                                                                    <option value="300 to 500">300 to 500(sqft)</option>
                                                                    <option value="500 to 700">500 to 700(sqft)</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenSunlight" id="ddlGardenSunlight"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Sunlight'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenSunlight}
                                                                    value={this.props.editDataObj.ddlGardenSunlight}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Sunlight	</option>
                                                                    <option value="Complete Indoor">Complete Indoor</option>
                                                                    <option value="No direct sunlight">No direct sunlight</option>
                                                                    <option value="1-2 hours">1-2 hours</option>
                                                                    <option value="2-4 hours">2-4 hours</option>
                                                                    <option value=">6 hours"> &gt;6 hours</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenLookPreferred" id="ddlGardenLookPreferred"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Type of Looks Preferred'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenLookPreferred}
                                                                    value={this.props.editDataObj.ddlGardenLookPreferred}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Type of Looks Preferred?</option>
                                                                    <option value="Contemporary">Contemporary </option>
                                                                    <option value="Indian">Indian</option>
                                                                    <option value="Mediterranean">Mediterranean</option>
                                                                    <option value="Tropical">Tropical</option>
                                                                    <option value="Zen">Zen</option>
                                                                    <option value="NotSpecified">Not Specified</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <FormControl variant="outlined" className="width_100" >
                                                                    <InputLabel htmlFor="ddlGardenPlantsPreferred" className="le-label-multi">Type of Plants Preferred</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlGardenPlantsPreferred}
                                                                        pnkvalue={this.props.editDataObj.ddlGardenPlantsPreferred}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlGardenPlantsPreferred',
                                                                            id: 'ddlGardenPlantsPreferred',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Type of Plants Preferred	"
                                                                    >
                                                                        {this.props.plantsType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>
                                                                {/* <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenPlantsPreferred" id="ddlGardenPlantsPreferred"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Type of Plants Preferred'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenPlantsPreferred}
                                                                    value={this.props.editDataObj.ddlGardenPlantsPreferred}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Type of Plants Preferred</option>
                                                                    <option value="Climbers">Climbers</option>
                                                                    <option value="Evergreens">Evergreens</option>
                                                                    <option value="Flowers">Flowers</option>
                                                                    <option value="Lawn">Lawn</option>
                                                                    <option value="Succulents">Succulents</option>
                                                                    <option value="Trees">Trees</option>
                                                                </Form.Control> */}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <Form.Control type="text" className="le-form-controls" id="txtGardenIssueEncountered" name="txtGardenIssueEncountered"
                                                                    pnkvalue={this.props.editDataObj.txtGardenIssueEncountered}
                                                                    value={this.props.editDataObj.txtGardenIssueEncountered}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtEmail">Any issues encountered</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenGardenFacing" id="ddlGardenGardenFacing"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Garden Facing'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenGardenFacing}
                                                                    value={this.props.editDataObj.ddlGardenGardenFacing}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden facing</option>
                                                                    <option value="North">North</option>
                                                                    <option value="South">South</option>
                                                                    <option value="East">East</option>
                                                                    <option value="West">West</option>
                                                                    <option value="NotSpecified">Not Specified</option>
                                                                    {/* <option value="5">5</option> */}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGardenBudget" id="ddlGardenBudget"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.gardenValidation}
                                                                    pnkdisplayerrormsg='Select Garden Budget'
                                                                    pnkvalue={this.props.editDataObj.ddlGardenBudget}
                                                                    value={this.props.editDataObj.ddlGardenBudget}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Garden Budget</option>
                                                                    {this.props.budget.length > 0 && this.props.budget.map(item =>
                                                                        <option value={item}>{item}</option>

                                                                    )}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showOtherGardenBudget }}>
                                                            <Form.Group >

                                                                <Form.Control type="text" className="le-form-controls" id="txtOtherGardenBudget" name="txtOtherGardenBudget"
                                                                    pnkvalue={this.props.editDataObj.txtOtherGardenBudget}
                                                                    value={this.props.editDataObj.txtOtherGardenBudget}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtOtherGardenBudget">Other Garden Budget</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section" style={{ display: this.props.mystate.showVegGardenDescDiv }}>
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Vegetable Garden Description</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("reqDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="reqDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlWhereToSetupVegGarden" id="ddlWhereToSetupVegGarden"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.vegGardenValidation}
                                                                    pnkdisplayerrormsg='Select Utilisation of space'
                                                                    pnkvalue={this.props.editDataObj.ddlWhereToSetupVegGarden}
                                                                    value={this.props.editDataObj.ddlWhereToSetupVegGarden}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Where would you like to set up your vegetable garden?</option>
                                                                    <option value="Balcony">Balcony/Patio</option>
                                                                    <option value="Terrace">Terrace</option>
                                                                    <option value="Garden">Garden</option>
                                                                    <option value="Others">Others</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>


                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVegGardenApproxSize" id="ddlVegGardenApproxSize"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.vegGardenValidation}
                                                                    pnkdisplayerrormsg='Select Approx Size'
                                                                    pnkvalue={this.props.editDataObj.ddlVegGardenApproxSize}
                                                                    value={this.props.editDataObj.ddlVegGardenApproxSize}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Approx Size	</option>
                                                                    <option value="0 to 50">0 to 50(sqft)</option>
                                                                    <option value="50 to 100">50 to 100(sqft)</option>
                                                                    <option value="100 to 150">100 to 150(sqft)</option>
                                                                    <option value="150 to 300">150 to 300(sqft)</option>
                                                                    <option value="300 to 500">300 to 500(sqft)</option>
                                                                    <option value="500 to 700">500 to 700(sqft)</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVegHowBigFamily" id="ddlVegHowBigFamily"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.vegGardenValidation}
                                                                    pnkdisplayerrormsg='Select How big is your family'
                                                                    pnkvalue={this.props.editDataObj.ddlVegHowBigFamily}
                                                                    value={this.props.editDataObj.ddlVegHowBigFamily}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>How big is your family?	</option>
                                                                    <option value="2-4 Member">2-4 Member</option>
                                                                    <option value="4-6 Member">4-6 Member</option>
                                                                    <option value="MoreThan6">More than 6 Members</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <Form.Control className="le-form-controls-select"
                                                                    name="ddlVegList" id="ddlVegList"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    onClick={() => this.OpenDialog("veg")}
                                                                    value={this.props.mystate.selectedVegList}
                                                                    pnkvalue={this.props.mystate.selectedVegList}
                                                                    placeholder="Vegetable List"

                                                                />
                                                                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.vegOpen} >
                                                                    <DialogTitle>Vegetable List</DialogTitle>
                                                                    <DialogContent>
                                                                        <form >

                                                                            <FormControl >

                                                                                {vegList.map(item =>
                                                                                    <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>

                                                                                        <div className="quantity-label" style={{ display: 'flex', width: '40%' }}>
                                                                                            <label >{item.name}</label>
                                                                                        </div>
                                                                                        <div className="quantity-div" style={{ display: 'flex', width: '60%' }}>
                                                                                            <Button className="le-minus" onClick={(e) => this.HandleGrowListChange(item.key, "vegetables", "minus")}>-</Button>

                                                                                            <Form.Control
                                                                                                className="list-quantity-ctrl"
                                                                                                readOnly
                                                                                                id={item.key}
                                                                                                defaultValue={0}

                                                                                            />
                                                                                            <Button className="le-plus" onClick={(e) => this.HandleGrowListChange(item.key, "vegetables", "add")}>+</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}


                                                                            </FormControl>
                                                                        </form>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button color="primary" onClick={() => this.CloseDialog("veg")}>
                                                                            OK
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                                {/* <option value="" disabled selected>Vegetable List	</option>
                                                                    {vegList.map(item =>
                                                                        <option value={item.name}>{item.name}</option>

                                                                    )} */}

                                                                {/* </Form.Control> */}

                                                                {/* <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                                                        <InputLabel htmlFor="ddlVegGardenWhatPreferToPlant" className="le-label-multi">What would you love to grow in your home?</InputLabel>
                                                                        <Select
                                                                            value={this.props.mystate.ddlVegGardenWhatPreferToPlant}
                                                                            pnkvalue={this.props.editDataObj.ddlVegGardenWhatPreferToPlant}
                                                                            onChange={this.props.HandleUserInputs}
                                                                            inputProps={{
                                                                                name: 'ddlVegGardenWhatPreferToPlant',
                                                                                id: 'ddlVegGardenWhatPreferToPlant',
                                                                                pnkvalidate: 'forceselect',
                                                                                pnkdisplayerrormsg: 'Select one'
                                                                            }}
                                                                            className="le-form-controls-select"
                                                                            multiple={true}
                                                                            placeholder="What would you love to grow in your home?	"
                                                                        >
                                                                            <option aria-label="None" value="" />
                                                                            <optgroup label="Vegetable">
                                                                                {vegList.map(item =>
                                                                                    <option value={item.name}>{item.name}</option>

                                                                                )}

                                                                            </optgroup>
                                                                            <optgroup label="Greens">
                                                                                {greenList.map(item =>
                                                                                    <option value={item.name}>{item.name}</option>

                                                                                )}
                                                                            </optgroup>
                                                                            <optgroup label="Herbs">
                                                                                {herbList.map(item =>
                                                                                    <option value={item.name}>{item.name}</option>

                                                                                )}
                                                                            </optgroup>
                                                                            <optgroup label="Fruits">
                                                                                {fruitList.map(item =>
                                                                                    <option value={item.name}>{item.name}</option>

                                                                                )}
                                                                            </optgroup>

                                                                        </Select>
                                                                    </FormControl> */}

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                {/* <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlGreenList" id="ddlGreenList"
                                                                    onChange={this.props.HandleUserInputs}

                                                                    pnkdisplayerrormsg='Select How big is your family'
                                                                    pnkvalue={this.props.editDataObj.ddlGreenList}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Green List	</option>
                                                                    {greenList.map(item =>
                                                                        <option value={item.name}>{item.name}</option>

                                                                    )}

                                                                </Form.Control> */}
                                                                <Form.Control className="le-form-controls-select"
                                                                    name="ddlGreenList" id="ddlGreenList"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    onClick={() => this.OpenDialog("green")}
                                                                    value={this.props.mystate.selectedGreenList}
                                                                    pnkvalue={this.props.editDataObj.ddlGreenList}
                                                                    placeholder="Green List"
                                                                />
                                                                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.greenOpen} >
                                                                    <DialogTitle>Green List</DialogTitle>
                                                                    <DialogContent>
                                                                        <form >

                                                                            <FormControl >

                                                                                {greenList.map(item =>
                                                                                    <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>

                                                                                        <div className="quantity-label" style={{ display: 'flex', width: '40%' }}>
                                                                                            <label >{item.name}</label>
                                                                                        </div>
                                                                                        <div className="quantity-div" style={{ display: 'flex', width: '60%' }}>
                                                                                            <Button className="le-minus" onClick={(e) => this.HandleGrowListChange(item.key, "greens", "minus")}>-</Button>
                                                                                            <Form.Control
                                                                                                className="list-quantity-ctrl"
                                                                                                readOnly
                                                                                                id={item.key}

                                                                                                defaultValue={0}
                                                                                            />
                                                                                            <Button className="le-plus" onClick={(e) => this.HandleGrowListChange(item.key, "greens", "add")}>+</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}


                                                                            </FormControl>
                                                                        </form>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button color="primary" onClick={() => this.CloseDialog("green")}>
                                                                            OK
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                {/* <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlHerbList" id="ddlHerbList"
                                                                    onChange={this.props.HandleUserInputs}

                                                                    pnkdisplayerrormsg='Select How big is your family'
                                                                    pnkvalue={this.props.editDataObj.ddlHerbList}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Herb List	</option>
                                                                    {herbList.map(item =>
                                                                        <option value={item.name}>{item.name}</option>

                                                                    )}

                                                                </Form.Control> */}
                                                                <Form.Control className="le-form-controls-select"
                                                                    name="ddlHerbList" id="ddlHerbList"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    onClick={() => this.OpenDialog("herb")}
                                                                    value={this.props.mystate.selectedHerbList}
                                                                    pnkvalue={this.props.editDataObj.ddlHerbList}
                                                                    placeholder="Herb List"
                                                                />
                                                                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.herbOpen} >
                                                                    <DialogTitle>Herb List</DialogTitle>
                                                                    <DialogContent>
                                                                        <form >

                                                                            <FormControl >

                                                                                {herbList.map(item =>
                                                                                    <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>

                                                                                        <div className="quantity-label" style={{ display: 'flex', width: '40%' }}>
                                                                                            <label >{item.name}</label>
                                                                                        </div>
                                                                                        <div className="quantity-div" style={{ display: 'flex', width: '60%' }}>
                                                                                            <Button className="le-minus" onClick={(e) => this.HandleGrowListChange(item.key, "herbs", "minus")}>-</Button>
                                                                                            <Form.Control
                                                                                                className="list-quantity-ctrl"
                                                                                                readOnly
                                                                                                id={item.key}
                                                                                                defaultValue={0} />
                                                                                            <Button className="le-plus" onClick={(e) => this.HandleGrowListChange(item.key, "herbs", "add")}>+</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}


                                                                            </FormControl>
                                                                        </form>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button color="primary" onClick={() => this.CloseDialog("herb")}>
                                                                            OK
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                {/* <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlFruitList" id="ddlFruitList"
                                                                    onChange={this.props.HandleUserInputs}

                                                                    pnkdisplayerrormsg='Select How big is your family'
                                                                    pnkvalue={this.props.editDataObj.ddlFruitList}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Fruit List	</option>
                                                                    {fruitList.map(item =>
                                                                        <option value={item.name}>{item.name}</option>

                                                                    )}

                                                                </Form.Control> */}
                                                                <Form.Control className="le-form-controls-select"
                                                                    name="ddlFruitList" id="ddlFruitList"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    onClick={() => this.OpenDialog("fruit")}
                                                                    value={this.props.mystate.selectedFruitList}
                                                                    pnkvalue={this.props.editDataObj.ddlFruitList}
                                                                    placeholder="Fruit List"
                                                                />
                                                                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.fruitOpen} >
                                                                    <DialogTitle>Fruit List</DialogTitle>
                                                                    <DialogContent>
                                                                        <form >

                                                                            <FormControl >

                                                                                {fruitList.map(item =>
                                                                                    <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>

                                                                                        <div className="quantity-label" style={{ display: 'flex', width: '40%' }}>
                                                                                            <label >{item.name}</label>
                                                                                        </div>
                                                                                        <div className="quantity-div" style={{ display: 'flex', width: '60%' }}>
                                                                                            <Button className="le-minus" onClick={(e) => this.HandleGrowListChange(item.key, "fruits", "minus")}>-</Button>
                                                                                            <Form.Control
                                                                                                className="list-quantity-ctrl"
                                                                                                readOnly
                                                                                                id={item.key}
                                                                                                defaultValue={0} />
                                                                                            <Button className="le-plus" onClick={(e) => this.HandleGrowListChange(item.key, "fruits", "add")}>+</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}


                                                                            </FormControl>
                                                                        </form>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button color="primary" onClick={() => this.CloseDialog("fruit")}>
                                                                            OK
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <Form.Control type="text" className="le-form-controls"
                                                                    name="txtVegeGardenQuantity" id="txtVegeGardenQuantity" pnkvalidate="forceentry"
                                                                    pnkvalue={this.props.editDataObj.txtVegeGardenQuantity}
                                                                    onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Name" />
                                                                <label class="floating-label" >Quantity</label>
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVegGradenWatering" id="ddlVegGradenWatering"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.vegGardenValidation}
                                                                    pnkdisplayerrormsg='Select Watering'
                                                                    pnkvalue={this.props.editDataObj.ddlVegGradenWatering}
                                                                    value={this.props.editDataObj.ddlVegGradenWatering}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>What about watering?</option>
                                                                    <option value="Automatic">Automatic</option>
                                                                    <option value="Manual">Manual</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVegGradenMaintenance" id="ddlVegGradenMaintenance"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.vegGardenValidation}
                                                                    pnkdisplayerrormsg='Select maintenance package'
                                                                    pnkvalue={this.props.editDataObj.ddlVegGradenMaintenance}
                                                                    value={this.props.editDataObj.ddlVegGradenMaintenance}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Would you be interested in our maintenance package?	</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtVegGradenAdditionalComments" id="txtVegGradenAdditionalComments"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtVegGradenAdditionalComments}
                                                                    pnkvalue={this.props.editDataObj.txtVegGradenAdditionalComments}

                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Add your comment</span>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>

                                                            <div className="padding-left-0 padding-right-0">
                                                                <div className="lead-entry-img-file" id="lead-entry-img-file">
                                                                    <div className="padding-img-class1">

                                                                        <div className="booking-browse-label text-center" style={this.props.image_editmode}>

                                                                            <PnkImage
                                                                                onChange={this.props.HandleImage}
                                                                                destinationFolder="application/public/images/uploads/crm/bookings"
                                                                                allowedTypeStr="png,jpg,jpeg,pdf"
                                                                                btnPreviewText="Upload Site Pictures"
                                                                                multiple="true"
                                                                                limitFiles="5"
                                                                                id="vegGardenFileDetails"
                                                                                name="vegGardenFileDetails"
                                                                                buttonClass="btn-bs-file btn btn-sm lead-img-browse-label"
                                                                                pnkvalue={this.props.editDataObj.vegGardenFileDetails}
                                                                                mode={this.props.mystate.mode}
                                                                                renderChild={this.props.mystate.renderChild}
                                                                                previewImageSrc={this.props.editDataObj.vegGardenFileDetails}
                                                                                itemStyle="padding:10px;height:150px;text-align:center"

                                                                            />
                                                                        </div>
                                                                        <div className="lead-img-text-head">
                                                                            (pdf and jpeg)
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section" style={{ display: this.props.mystate.showVerticalGardenDescDiv }}>
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Vertical Garden Description</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("reqDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="reqDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlWhereToSetupVerticalGarden" id="ddlWhereToSetupVerticalGarden"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.verticalGardenValidation}
                                                                    pnkdisplayerrormsg='Select Where would you like to set up your vertical garden'
                                                                    pnkvalue={this.props.editDataObj.ddlWhereToSetupVerticalGarden}
                                                                    value={this.props.editDataObj.ddlWhereToSetupVerticalGarden}
                                                                    style={{ padding: '4px', width: '100%' }} >
                                                                    <option value="" disabled selected>Where would you like to set up your vertical garden?</option>
                                                                    <option value="Balcony">Balcony/Patio</option>
                                                                    <option value="Terrace">Terrace</option>
                                                                    <option value="Garden">Garden</option>
                                                                    <option value="Garden">Exterior Wall</option>
                                                                    <option value="Others">Others</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>


                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVerticalGardenApproxSize" id="ddlVerticalGardenApproxSize"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.verticalGardenValidation}
                                                                    pnkdisplayerrormsg='Select Approx Size'
                                                                    pnkvalue={this.props.editDataObj.ddlVerticalGardenApproxSize}
                                                                    value={this.props.editDataObj.ddlVerticalGardenApproxSize}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Approx Size	</option>
                                                                    <option value="0 to 50">0 to 50(sqft)</option>
                                                                    <option value="50 to 100">50 to 100(sqft)</option>
                                                                    <option value="100 to 150">100 to 150(sqft)</option>
                                                                    <option value="150 to 300">150 to 300(sqft)</option>
                                                                    <option value="300 to 500">300 to 500(sqft)</option>
                                                                    <option value="500 to 700">500 to 700(sqft)</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVerticalGardenSunlight" id="ddlVerticalGardenSunlight"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.verticalGardenValidation}
                                                                    pnkdisplayerrormsg='Select Sunlight'
                                                                    pnkvalue={this.props.editDataObj.ddlVerticalGardenSunlight}
                                                                    value={this.props.editDataObj.ddlVerticalGardenSunlight}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Sunlight	</option>
                                                                    <option value="Complete Indoor">Complete Indoor</option>
                                                                    <option value="No direct sunlight">No direct sunlight</option>
                                                                    <option value="1-2 hours">1-2 hours</option>
                                                                    <option value="2-4 hours">2-4 hours</option>
                                                                    <option value=">6 hours"> &gt;6 hours</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVerticalGradenWatering" id="ddlVerticalGradenWatering"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.verticalGardenValidation}
                                                                    pnkdisplayerrormsg='Select Watering'
                                                                    pnkvalue={this.props.editDataObj.ddlVerticalGradenWatering}
                                                                    value={this.props.editDataObj.ddlVerticalGradenWatering}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>What about watering?</option>
                                                                    <option value="Automatic">Automatic</option>
                                                                    <option value="Manual">Manual</option>

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVerticalSupportStructure" id="ddlVerticalSupportStructure"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.verticalGardenValidation}
                                                                    pnkdisplayerrormsg='Select support'
                                                                    pnkvalue={this.props.editDataObj.ddlVerticalSupportStructure}
                                                                    value={this.props.editDataObj.ddlVerticalSupportStructure}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Do you have a wall or a structure at the back to support the Vertical Garden installation?	</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlVerticalGradenMaintenance" id="ddlVerticalGradenMaintenance"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate={this.props.mystate.verticalGardenValidation}
                                                                    pnkdisplayerrormsg='Select maintenance package'
                                                                    pnkvalue={this.props.editDataObj.ddlVerticalGradenMaintenance}
                                                                    value={this.props.editDataObj.ddlVerticalGradenMaintenance}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Would you be interested in our maintenance package?	</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>



                                                    </Row>
                                                    <Row>
                                                        {/* <Col lg={4} className="lead-col">
                                                            <div className="file-up-div le-up-pics">
                                                                <label for="file-upload" class="custom-file-upload">
                                                                    <div className="le-up-pics-icon">
                                                                        <FontAwesomeIcon icon={faPlus} className="le-up-icon" />
                                                                    </div>
                                                                    <div className="le-up-pic-title">
                                                                        <h3 className="le-up-pic-tit-text">Upload Site Pictures</h3>
                                                                    </div>
                                                                    <div className="le-up-pic-title">
                                                                        <h4 className="le-up-pic-sub-text">(pdf and jpeg)</h4>
                                                                    </div>
                                                                </label>
                                                                <input id="file-upload" type="file" multiple
                                                                    onInput={(event) => {
                                                                        this.props.UploadImage(event)
                                                                    }}
                                                                    onClick={(event) => {
                                                                        event.target.value = null
                                                                    }}
                                                                />
                                                            </div>

                                                        </Col> */}
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtVerticalGradenAdditionalComments" id="txtVerticalGradenAdditionalComments"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtVerticalGradenAdditionalComments}
                                                                    pnkvalue={this.props.editDataObj.txtVerticalGradenAdditionalComments}
                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Add your comment</span>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>

                                                            <div className="padding-left-0 padding-right-0">
                                                                <div className="lead-entry-img-file" id="lead-entry-img-file">
                                                                    <div className="padding-img-class1">

                                                                        <div className="booking-browse-label text-center" style={this.props.image_editmode}>

                                                                            <PnkImage
                                                                                onChange={this.props.HandleImage}
                                                                                destinationFolder="application/public/images/uploads/crm/bookings"
                                                                                allowedTypeStr="png,jpg,jpeg,pdf"
                                                                                btnPreviewText="Upload Site Pictures"
                                                                                multiple="true"
                                                                                limitFiles="5"
                                                                                id="verticalFileAttachment"
                                                                                name="verticalFileAttachment"
                                                                                buttonClass="btn-bs-file btn btn-sm lead-img-browse-label"
                                                                                pnkvalue={this.props.editDataObj.verticalFileAttachment}
                                                                                mode={this.props.mystate.mode}
                                                                                renderChild={this.props.mystate.renderChild}
                                                                                previewImageSrc={this.props.editDataObj.verticalFileAttachment}
                                                                                itemStyle="padding:10px;height:150px;text-align:center"

                                                                            />
                                                                        </div>
                                                                        <div className="lead-img-text-head">
                                                                            (pdf and jpeg)
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Additional Requirement</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("additionalDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="additionalDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >

                                                                <FormControl variant="outlined" className="width_100" >
                                                                    <InputLabel htmlFor="ddlColorPalette" className="le-label-multi">Color Palette</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlColorPalette}
                                                                        pnkvalue={this.props.editDataObj.ddlColorPalette}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlColorPalette',
                                                                            id: 'ddlColorPalette',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Color Palette"
                                                                    >
                                                                        {this.props.colorType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                {/* <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlHandscrapping" id="ddlHandscrapping"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Hardscaping '
                                                                    pnkvalue={this.props.editDataObj.ddlHandscrapping}
                                                                    value={this.props.editDataObj.ddlHandscrapping}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Hardscaping</option>
                                                                    <option value="Flooring">Flooring</option>
                                                                    <option value="Pergola">Pergola</option>
                                                                    <option value="Woodwork">Woodwork</option>
                                                                    <option value="Artificial Grass">Artificial Grass</option>
                                                                    <option value="Bar">Bar</option>
                                                                    <option value="Water Body">Water Body</option>
                                                                    <option value="Lighting">Lighting</option>
                                                                    <option value="Watering System">Watering System</option>
                                                                    <option value="Play Area">Play Area</option> 

                                                                        </Form.Control>*/}
                                                                <FormControl variant="outlined" className="width_100" >
                                                                    <InputLabel htmlFor="ddlHandscrapping" className="le-label-multi">Hardscaping</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlHandscrapping}
                                                                        pnkvalue={this.props.editDataObj.ddlHandscrapping}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlHandscrapping',
                                                                            id: 'ddlHandscrapping',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Hardscaping"
                                                                    >
                                                                        {this.props.hardScapingType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>

                                                                </FormControl>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <FormControl variant="outlined" className="width_100">
                                                                    <InputLabel htmlFor="ddlAccessories" className="le-label-multi">Any other accessories</InputLabel>
                                                                    <Select
                                                                        value={this.props.editDataObj.ddlAccessories}
                                                                        pnkvalue={this.props.editDataObj.ddlAccessories}
                                                                        onChange={this.props.HandleUserInputs}
                                                                        inputProps={{
                                                                            name: 'ddlAccessories',
                                                                            id: 'ddlAccessories',
                                                                            // pnkvalidate: 'forceselect',
                                                                            pnkdisplayerrormsg: 'Select one'
                                                                        }}
                                                                        className="le-form-controls-select"
                                                                        multiple={true}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                        placeholder="Any other accessories"
                                                                    >
                                                                        {this.props.accessoryType.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <ListItemText primary={name} />

                                                                            </MenuItem>
                                                                        ))}


                                                                    </Select>
                                                                </FormControl>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showtxtAccessoryOther }}>
                                                            <Form.Group  >
                                                                <Form.Control type="text" className="le-form-controls" name="txtAccessoryOther" id="txtAccessoryOther"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtAccessoryOther}
                                                                    pnkvalue={this.props.editDataObj.txtAccessoryOther}
                                                                    pnkdisplayerrormsg="Invalid Feature" />
                                                                <span className="floating-label">Specify Other</span>
                                                            </Form.Group>

                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlFloorPlanReceived" id="ddlFloorPlanReceived"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Floor Plan Received '
                                                                    pnkvalue={this.props.editDataObj.ddlFloorPlanReceived}
                                                                    value={this.props.editDataObj.ddlFloorPlanReceived}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Floor Plan Received</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>


                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlHomeConsultationFeeCollected" id="ddlHomeConsultationFeeCollected"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    // pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Home consultation amount collected'
                                                                    pnkvalue={this.props.editDataObj.ddlHomeConsultationFeeCollected}
                                                                    value={this.props.editDataObj.ddlHomeConsultationFeeCollected}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Home consultation amount collected?	</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtVideoUrl" name="txtVideoUrl"
                                                                    pnkvalue={this.props.editDataObj.txtVideoUrl}
                                                                    value={this.props.editDataObj.txtVideoUrl}
                                                                    onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid City" />
                                                                <span className="floating-label" for="txtVideoUrl">Add Video Url </span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtGetQuoteUrl" name="txtGetQuoteUrl"
                                                                    pnkvalue={this.props.editDataObj.txtGetQuoteUrl}
                                                                    value={this.props.editDataObj.txtGetQuoteUrl}
                                                                    onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid City" />
                                                                <span className="floating-label" for="txtGetQuoteUrl">Upload Quote URL </span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtDesignUrl" name="txtDesignUrl"
                                                                    pnkvalue={this.props.editDataObj.txtDesignUrl}
                                                                    value={this.props.editDataObj.txtDesignUrl}
                                                                    onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid City" />
                                                                <span className="floating-label" for="txtDesignUrl">Upload Design URL </span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group  >
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtCustomerNote" id="txtCustomerNote"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtCustomerNote}
                                                                    pnkvalue={this.props.editDataObj.txtCustomerNote}
                                                                    pnkdisplayerrormsg="Invalid Feature" />
                                                                <span className="floating-label">Customer Notes</span>
                                                            </Form.Group>

                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="7" className="le-form-controls-ta" name="txtAdditionalReqComment" id="txtAdditionalReqComment"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalue={this.props.editDataObj.txtAdditionalReqComment}
                                                                    value={this.props.editDataObj.txtAdditionalReqComment}
                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Add your comment</span>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>

                                                            <div className="padding-left-0 padding-right-0">
                                                                <div className="lead-entry-img-file" id="lead-entry-img-file">
                                                                    <div className="padding-img-class1">

                                                                        <div className="booking-browse-label text-center" style={this.props.image_editmode}>

                                                                            <PnkImage
                                                                                onChange={this.props.HandleImage}
                                                                                destinationFolder="application/public/images/uploads/crm/bookings"
                                                                                allowedTypeStr="png,jpg,jpeg,pdf"
                                                                                btnPreviewText="Upload Site Pictures"
                                                                                multiple="true"
                                                                                limitFiles="5"
                                                                                id="fileAdditionalAttachment"
                                                                                name="fileAdditionalAttachment"
                                                                                buttonClass="btn-bs-file btn btn-sm lead-img-browse-label"
                                                                                pnkvalue={this.props.editDataObj.fileAdditionalAttachment}
                                                                                mode={this.props.mystate.mode}
                                                                                renderChild={this.props.mystate.renderChild}
                                                                                previewImageSrc={this.props.editDataObj.fileAdditionalAttachment}

                                                                                itemStyle="padding:10px;height:150px;text-align:center"

                                                                            />
                                                                        </div>
                                                                        <div className="lead-img-text-head">
                                                                            (pdf and jpeg)
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </Col>
                                                    </Row>

                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading">Lead Details</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("leadDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="leadDiv">
                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlSource" id="ddlSource"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Source '
                                                                    pnkvalue={this.props.editDataObj.ddlSource}
                                                                    value={this.props.editDataObj.ddlSource}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Source</option>
                                                                    {(leadSourceData && leadSourceData.length > 0) ? leadSourceData.map(item =>
                                                                        <option value={item.lead_source_name}>{item.lead_source_name}</option>
                                                                    ) : ""}

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">

                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlHandledBy" id="ddlHandledBy"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Handled By '
                                                                    pnkvalue={this.props.editDataObj.ddlHandledBy}
                                                                    value={this.props.editDataObj.ddlHandledBy}

                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" key="" disabled selected>Handled By</option>
                                                                    {(filteredData && filteredData.length > 0) ? filteredData.map(item =>
                                                                        <React.Fragment>
                                                                            {sessionStorage.getItem('current_user_profile') === "Sales" ?
                                                                                item.profile_name !== 'Telecalling' ?
                                                                                    <option value={item.profile_name} id={item._id}>{item.profile_name}</option> : ''
                                                                                :
                                                                                this.props.mystate.mode === 'add' ?
                                                                                    sessionStorage.getItem('current_user_profile') === "Telecalling" ?
                                                                                        item.profile_name === 'Telecalling' ?
                                                                                            <option value={item.profile_name} id={item._id}>{item.profile_name}</option> : ''
                                                                                        : <option value={item.profile_name} id={item._id}>{item.profile_name}</option>
                                                                                    : <option value={item.profile_name} id={item._id}>{item.profile_name}</option>
                                                                            }
                                                                        </React.Fragment>

                                                                    ) : ""}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlLeadOwner" id="ddlLeadOwner"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Lead Owner '
                                                                    pnkvalue={this.props.editDataObj.ddlLeadOwner}
                                                                    value={this.props.editDataObj.ddlLeadOwner}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Lead Owner</option>
                                                                    {(this.props.mystate.userListByProfile && this.props.mystate.userListByProfile.length > 0) ? this.props.mystate.userListByProfile.map(item =>
                                                                        this.props.mystate.mode === 'add' ?
                                                                            sessionStorage.getItem('current_user_profile') === "Telecalling" ?
                                                                                sessionStorage.getItem('current_user_name') === item.user_name ?
                                                                                    <option value={item.user_name} selected>{item.user_name}</option> : ''
                                                                                : <option value={item.user_name}>{item.user_name}</option>
                                                                            : <option value={item.user_name}>{item.user_name}</option>


                                                                    ) : ""}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlStage" id="ddlStage"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Stage '
                                                                    pnkvalue={this.props.editDataObj.ddlStage}
                                                                    value={this.props.editDataObj.ddlStage}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Stage</option>

                                                                    {(this.props.mystate.profileStageData && this.props.mystate.profileStageData.length > 0) ? this.props.mystate.profileStageData.map(item =>
                                                                        <option value={item} id="">{item}</option>

                                                                    ) : ""}

                                                                </Form.Control>
                                                                {this.props.editDataObj.ddlStage !== "Pending" && sessionStorage.getItem("current_user_profile") !== "Telecalling" && this.props.mystate.mode === 'edit' ?
                                                                    (this.props.editDataObj.txtName !== '' && this.props.editDataObj.txtName !== null) && (this.props.editDataObj.txtPhone !== '' && this.props.editDataObj.txtPhone !== null)
                                                                        && (this.props.editDataObj.txtAddress !== '' && this.props.editDataObj.txtAddress !== null) && (this.props.editDataObj.ddlPreferredCity !== '' && this.props.editDataObj.ddlPreferredCity !== null)
                                                                        && (this.props.editDataObj.ddlLocality !== '' && this.props.editDataObj.ddlLocality !== null) ?

                                                                        <Button style={{ marginTop: 10 }} onClick={() => this.props.GoToHCForm()}>HC FORM</Button>
                                                                        :
                                                                        <Button disabled={true} title="Contact , City , Locality are required fields" style={{ marginTop: 10 }} onClick={() => this.props.GoToHCForm()}>HC FORM</Button>
                                                                    :
                                                                    ''}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showDeadControls }}>
                                                            <Form.Group >

                                                                <TextField
                                                                    id="txtDeadDate"
                                                                    name="txtDeadDate"
                                                                    label="From"
                                                                    type="date"
                                                                    // pnkvalidate={this.props.mystate.deadStageValidation}
                                                                    pnkvalue={this.props.editDataObj.txtDeadDate}
                                                                    value={this.props.mystate.txtDeadDate}
                                                                    onChange={this.props.HandleUserInputs}
                                                                    style={{ width: '100%' }}
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                    pnkdisplayerrormsg='Invalid Date'
                                                                />

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showDeadControls }}>
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlDeadReason" id="ddlDeadReason"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate={this.props.mystate.deadStageSelectValidation}
                                                                    pnkdisplayerrormsg='Select One'
                                                                    pnkvalue={this.props.editDataObj.ddlDeadReason}
                                                                    value={this.props.editDataObj.ddlDeadReason}
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Dead Reason	</option>
                                                                    <option value="call not answered">Call not answered</option>
                                                                    <option value="not interested">Not interested</option>
                                                                    <option value="no budget">No budget</option>
                                                                    <option value="other city">Other city</option>
                                                                    <option value="window shopping">Window shopping</option>
                                                                    <option value="other">Other</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showDesignerControl }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtHandoverToDesignerDateTime"
                                                                    name="txtHandoverToDesignerDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtHandoverToDesignerDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtHandoverToDesignerDateTime}
                                                                    value={this.props.mystate.txtHandoverToDesignerDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showDesignerControl }}>
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlDesigner" id="ddlDesigner"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate={this.props.mystate.ddlDesignerValidation}
                                                                    pnkdisplayerrormsg='Select One'
                                                                    pnkvalue={this.props.editDataObj.ddlDesigner}
                                                                    value={this.props.mystate.ddlDesigner}
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Select Designer	</option>

                                                                    {(this.props.designerList && this.props.designerList.rows && this.props.designerList.rows.length > 0) ? this.props.designerList.rows.map(item =>
                                                                        item.user_name === this.props.mystate.ddlDesigner ?
                                                                            <option selected={true} value={item.user_name} id={item._id}>{item.user_name}</option> :
                                                                            <option value={item.user_name} id={item._id}>{item.user_name}</option>
                                                                    ) : ""
                                                                    }

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showtxtDeadReason }}>
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtDeadReason" name="txtDeadReason"
                                                                    pnkvalue={this.props.editDataObj.txtDeadReason}
                                                                    value={this.props.editDataObj.txtDeadReason}
                                                                    pnkvalidate={this.props.mystate.deadStageOtherValidation}
                                                                    validate={this.props.mystate.deadStageOtherValidation}
                                                                    onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Reason" />
                                                                <span className="floating-label" for="txtDeadReason">Specify Other Reason </span>

                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showAdvanceControls }}>
                                                            <Form.Group >

                                                                <TextField
                                                                    id="txtAdvancePayment"
                                                                    name="txtAdvancePayment"
                                                                    label="From"
                                                                    type="date"
                                                                    pnkvalue={this.props.editDataObj.txtAdvancePayment}
                                                                    value={this.props.mystate.txtAdvancePayment}
                                                                    onChange={this.props.HandleUserInputs}
                                                                    style={{ width: '100%' }}
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                />


                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showCallBackDate }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtLeadCallBackDate"
                                                                    name="txtLeadCallBackDate"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtLeadCallBackDate}
                                                                    pnkvalue={this.props.editDataObj.txtLeadCallBackDate}
                                                                    value={this.props.mystate.txtLeadCallBackDate}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showHCBooked }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtHcBookedDateTime"
                                                                    name="txtHcBookedDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtHcBookedDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtHcBookedDateTime}
                                                                    value={this.props.mystate.txtHcBookedDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showHcCompleted }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtHcCompletedDateTime"
                                                                    name="txtHcCompletedDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtHcCompletedDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtHcCompletedDateTime}
                                                                    value={this.props.mystate.txtHcCompletedDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}

                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showProposalUploaded }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtProposalUploadedDateTime"
                                                                    name="txtProposalUploadedDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtProposalUploadedDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtProposalUploadedDateTime}
                                                                    value={this.props.mystate.txtProposalUploadedDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showProposalSent }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtProposalSentDateTime"
                                                                    name="txtProposalSentDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtProposalSentDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtProposalSentDateTime}
                                                                    value={this.props.mystate.txtProposalSentDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showBookingAmountReceived }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtBookingAmountReceivedDateTime"
                                                                    name="txtBookingAmountReceivedDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtBookingAmountReceivedDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtBookingAmountReceivedDateTime}
                                                                    value={this.props.mystate.txtBookingAmountReceivedDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showFollowUp }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtFollowUpDateTime"
                                                                    name="txtFollowUpDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtFollowUpDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtFollowUpDateTime}
                                                                    value={this.props.mystate.txtFollowUpDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showProjectClose }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtProjectCloseDateTime"
                                                                    name="txtProjectCloseDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtProjectCloseDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtProjectCloseDateTime}
                                                                    value={this.props.mystate.txtProjectCloseDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showVCBookedDate }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtVCBookedDateTime"
                                                                    name="txtVCBookedDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtVCBookedDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtVCBookedDateTime}
                                                                    value={this.props.mystate.txtVCBookedDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showVCCompletedDate }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtVCCompletedDateTime"
                                                                    name="txtVCCompletedDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtVCCompletedDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtVCCompletedDateTime}
                                                                    value={this.props.mystate.txtVCCompletedDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showProjectMeetingDate }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtProjectMeetingDateTime"
                                                                    name="txtProjectMeetingDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtProjectMeetingDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtProjectMeetingDateTime}
                                                                    value={this.props.mystate.txtProjectMeetingDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showProjectMeetingDate }}>
                                                            <Form.Group >


                                                                <Form.Control type="text" className="le-form-controls"
                                                                    name="txtPMAssist" id="txtPMAssist"
                                                                    pnkvalidate={this.props.mystate.validationAssist}
                                                                    value={this.props.editDataObj.txtPMAssist}
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                    pnkvalue={this.props.editDataObj.txtPMAssist}
                                                                    pnkdisplayerrormsg='Enter Assist '
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <label class="floating-label" >Assist</label>
                                                            </Form.Group>

                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showProjectComplete }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtProjectCompleteDateTime"
                                                                    name="txtProjectCompleteDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtProjectCompleteDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtProjectCompleteDateTime}
                                                                    value={this.props.mystate.txtProjectCompleteDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showPPFollowup }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtPPFollowUp"
                                                                    name="txtPPFollowUp"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtPPFollowUp}
                                                                    pnkvalue={this.props.editDataObj.txtPPFollowUp}
                                                                    value={this.props.mystate.txtPPFollowUp}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}
                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showUnderConstructionDateTime }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtUnderConstructionDateTime"
                                                                    name="txtUnderConstructionDateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtUnderConstructionDateTime}
                                                                    pnkvalue={this.props.editDataObj.txtUnderConstructionDateTime}
                                                                    value={this.props.mystate.txtUnderConstructionDateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}

                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showHCNADateTime }}>
                                                            <Form.Group >
                                                                <input type="text"
                                                                    id="txtHCNADateTime"
                                                                    name="txtHCNADateTime"
                                                                    onClick={this.props.HandleUserInputs}
                                                                    placeholder={this.props.mystate.txtHCNADateTime}
                                                                    pnkvalue={this.props.editDataObj.txtHCNADateTime}
                                                                    value={this.props.mystate.txtHCNADateTime}
                                                                    style={{ width: '100%' }}
                                                                    autoComplete="off"
                                                                    className="le-form-controls-date"
                                                                    disabled={this.props.mystate.formModeActivity}

                                                                // disabled='disabled'
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col">
                                                            <Form.Group >
                                                                <Form.Control as="select" className="le-form-controls-select"
                                                                    name="ddlProjectEstimate" id="ddlProjectEstimate"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    pnkvalidate='forceselect'
                                                                    pnkdisplayerrormsg='Select Project Estimate '
                                                                    pnkvalue={this.props.editDataObj.ddlProjectEstimate}
                                                                    value={this.props.editDataObj.ddlProjectEstimate}
                                                                    style={{ padding: '4px', width: '100%' }}>
                                                                    <option value="" disabled selected>Project Estimate</option>

                                                                    {(this.props.projectEstimate && this.props.projectEstimate.length > 0) ?
                                                                        this.props.projectEstimate.map(item =>
                                                                            <option value={item} id="">{item}</option>

                                                                        ) : ""}

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="lead-col" style={{ display: this.props.mystate.showOtherProjectEstimate }}>
                                                            <Form.Group >
                                                                <Form.Control type="text" className="le-form-controls" id="txtOtherProjectEstimate" name="txtOtherProjectEstimate"
                                                                    pnkvalue={this.props.editDataObj.txtOtherProjectEstimate}
                                                                    value={this.props.editDataObj.txtOtherProjectEstimate}
                                                                    onChange={this.props.HandleUserInputs} />
                                                                <span class="floating-label" for="txtEmail">Other Project Estimate</span>
                                                            </Form.Group>
                                                        </Col>


                                                    </Row>

                                                    <Row>
                                                        <Col lg={4} className="lead-col">
                                                            <div className="le-teaxt-area">
                                                                <Form.Control as="textarea" rows="6" className="le-form-controls-ta" name="txtLeadComment" id="txtLeadComment"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtLeadComment}
                                                                    pnkvalue={this.props.editDataObj.txtLeadComment}

                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Pre Sales Comment</span>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {sessionStorage.getItem('current_user_profile') !== 'Telecalling' ?
                                        <div className="le-section">
                                            <div className="mob-container">
                                                <Row className="le-nex-div">
                                                    <Col lg={12}>
                                                        <Row>
                                                            <Col lg={7}>
                                                                <div className="personal-div">
                                                                    <h3 className="form-heading"> Description</h3>
                                                                    <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("descDiv")} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={12} id="descDiv">
                                                        <Row>
                                                            <Col lg={12} className="lead-col">
                                                                <Form.Group >

                                                                    <Form.Control as="textarea" rows="6" className="le-form-controls-ta" name="txtDescriptionComment" id="txtDescriptionComment"
                                                                        onChange={this.props.HandleUserInputs}
                                                                        value={this.props.editDataObj.txtDescriptionComment}
                                                                        pnkvalue={this.props.editDataObj.txtDescriptionComment}

                                                                        pnkdisplayerrormsg="Invalid Comment" />
                                                                    <span className="floating-label">Sales Comment</span>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        :
                                        this.props.mystate.mode === 'edit' ?
                                            <div className="le-section">
                                                <div className="mob-container">
                                                    <Row className="le-nex-div">
                                                        <Col lg={12}>
                                                            <Row>
                                                                <Col lg={7}>
                                                                    <div className="personal-div">
                                                                        <h3 className="form-heading"> Description</h3>
                                                                        <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("descDiv")} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={12} id="descDiv">
                                                            <Row>
                                                                <Col lg={12} className="lead-col">
                                                                    <Form.Group >

                                                                        <Form.Control as="textarea" rows="6" className="le-form-controls-ta" name="txtDescriptionComment" id="txtDescriptionComment"
                                                                            onChange={this.props.HandleUserInputs}
                                                                            value={this.props.editDataObj.txtDescriptionComment}
                                                                            pnkvalue={this.props.editDataObj.txtDescriptionComment}
                                                                            readOnly

                                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                                        <span className="floating-label">Sales Comments</span>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    {sessionStorage.getItem('current_user_profile').includes('Sales') || (this.props.mystate.mode === 'edit' && (sessionStorage.getItem('current_user_profile') === 'Administrator' || sessionStorage.getItem('current_user_profile') === 'Telecalling')) ?
                                        <div className="le-section">
                                            <div className="mob-container">
                                                <Row className="le-nex-div">
                                                    <Col lg={12}>
                                                        <Row>
                                                            <Col lg={7}>
                                                                <div className="personal-div">
                                                                    <h3 className="form-heading">Remarks of Sales TL</h3>
                                                                    <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("remarkDiv")} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={12} id="remarkDiv">
                                                        <Row>
                                                            <Col lg={12} className="lead-col">
                                                                {sessionStorage.getItem('current_user_profile') !== 'Telecalling' ?
                                                                    <Form.Group>
                                                                        <Form.Control as="textarea" rows="6" className="le-form-controls-ta" name="txtSalesTLRemark" id="txtSalesTLRemark"
                                                                            onChange={this.props.HandleUserInputs}
                                                                            value={this.props.editDataObj.txtSalesTLRemark}
                                                                            pnkvalue={this.props.editDataObj.txtSalesTLRemark}
                                                                            disabled={this.props.mystate.formModeActivity}
                                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                                        <span className="floating-label">Sales TL Remarks</span>
                                                                    </Form.Group>
                                                                    :
                                                                    <Form.Group>
                                                                        <Form.Control as="textarea" rows="6" className="le-form-controls-ta" name="txtSalesTLRemark" id="txtSalesTLRemark"
                                                                            onChange={this.props.HandleUserInputs}
                                                                            value={this.props.editDataObj.txtSalesTLRemark}
                                                                            pnkvalue={this.props.editDataObj.txtSalesTLRemark}
                                                                            readOnly
                                                                            pnkdisplayerrormsg="Invalid Comment" />
                                                                        <span className="floating-label">Sales TL Remarks</span>
                                                                    </Form.Group>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        : ''}
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <div className="personal-div">
                                                                <h3 className="form-heading"> Lead Automation Email Content</h3>
                                                                <FontAwesomeIcon icon={faChevronDown} className="show-mob le-mob-dow-arrow" onClick={() => this.ToggleView("emailDiv")} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={12} id="emailDiv">
                                                    <Row>
                                                        <Col lg={12} className="lead-col">

                                                            <Form.Group >
                                                                <Form.Control as="textarea" rows="6" className="le-form-controls-ta" name="txtLeadAutomationComment" id="txtLeadAutomationComment"
                                                                    onChange={this.props.HandleUserInputs}
                                                                    value={this.props.editDataObj.txtLeadAutomationComment}
                                                                    pnkvalue={this.props.editDataObj.txtLeadAutomationComment}
                                                                    pnkdisplayerrormsg="Invalid Comment" />
                                                                <span className="floating-label">Add your comment</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="le-section">
                                        <div className="mob-container">
                                            <Row className="le-nex-div">
                                                <Col lg={12}>
                                                    <button className="le-save-submit mr-3 pnkvalidatesubmit" name="Save" id="btnLeadEntry" onClick={this.props.SaveLeadData}>{this.props.mystate.showButtonType}</button>

                                                    <Button variant="light" className="le-cancel-submit " onClick={() => this.props.ShowCancel()}>CANCEL</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </PnkHtmlForm>
                    </div>
                </div>
            </div >
        )
    }
}
