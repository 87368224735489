import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import EntryForm from './form'
import MobForm from './mob-form'
import { Container } from 'react-bootstrap'
import AppUtils from '../../apputils'
import { GetStateList, GetCountries, AddStates, AddAreas, GetCityList, AddCities, GetCityById, EditCity, GetAreaById, GetStateById, EditState, EditArea } from '../../models/users'
import $ from 'jquery';
class entryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentChoice: 'state',
            txtChoice: '',
            ddlChoice: '',
            invalidTxtChoice: '',
            invalidDDlChoice: '',
            chkEntryStatus: 'off',
            getState: false,
            getCountry: true,
            getCity: false,
            currentChoiceMobile: '',
            countryList: {},
            stateList: {},
            addState: false,
            addCity: false,
            addArea: false,
            showEditBtn: 'none',
            showButtonType: 'Add ',
            getCityById: false,
            mode: 'add',
            editCity: false,
            actionHeading: ' Entry',
            getAreaById: false,
            getStateById: false,
            editState: false,
            editArea: false,
            hideCity: false,
            hideArea: false,
            hideState: false

        }
        this.data = {};
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Entry');
        this.props.componentprops.ShowAdvanceSearch(true);
    }
    componentDidMount = () => {

        if (this.props.componentprops.match.params.id !== undefined && this.props.componentprops.match.params.id !== "") {
            this.setState({ currentSeletedId: this.props.componentprops.match.params.id })
            this.ChangePageMode()
        }
        if (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "") {
            if (this.props.componentprops.match.params.type === "city" && (this.props.componentprops.match.params.id !== undefined && this.props.componentprops.match.params.id !== "")) {
                this.setState({
                    getState: true,
                    getCityById: true,
                    hideArea: true,
                    hideState: true,
                    hideCity: false


                })
            }
            if (this.props.componentprops.match.params.type === "area" && (this.props.componentprops.match.params.id !== undefined && this.props.componentprops.match.params.id !== "")) {
                this.setState({
                    getCity: true,
                    getAreaById: true,
                    hideCity: true,
                    hideState: true,
                    hideArea: false
                })
            }
            if (this.props.componentprops.match.params.type === "state" && (this.props.componentprops.match.params.id !== undefined && this.props.componentprops.match.params.id !== "")) {
                this.setState({
                    getCountry: true,
                    getStateById: true,
                    hideCity: true,
                    hideArea: true,
                    hideState: false,
                })
            }
            if (this.props.componentprops.match.params.type === "city") {
                this.setState({
                    getState: true,
                })
            }
            if (this.props.componentprops.match.params.type === "area") {
                this.setState({
                    getCity: true,
                })
            }
            if (this.props.componentprops.match.params.type === "state") {
                this.setState({
                    getCountry: true,
                })
            }
            this.setState({ currentChoice: this.props.componentprops.match.params.type, currentChoiceMobile: this.props.componentprops.match.params.type })
        }


    }
    ChangePageMode = () => {

        let obj;

        obj = {
            actionHeading: ' Details',
            editDeleteDiv: true,
            getUserProfileEditData: true,
            // currentSeletedProfileId: this.props.componentprops.currentAction.action.id,
            showButtonType: 'Update ',
            formModeActivity: true,
            btnUserProfileOuterDiv: false,


        };

        this.props.componentprops.HandleTitle('Entry Details');

        this.setState(obj);

    }


    ShowEditMode = () => {
       
        let form = document.getElementById("frmEntry");
       
        let elements = form.elements;
        for (let i = 0, len = elements.length; i < len; ++i) {
            if (elements[i].id !== '')
                elements[i].disabled = false;
        }
        this.setState({ formModeActivity: false, profileActionHeading: 'Edit Profile', btnUserProfileOuterDiv: true, showEditBtn: 'none', mode: 'edit' })

    }
    ChangeOption = (choice) => {

        this.setState({
            currentChoice: choice,
            txtChoice: '',
            ddlChoice: '',
            invalidTxtChoice: '',
            invalidDDlChoice: '',
        })
        if (choice === "city") {
            this.setState({
                getState: true
            })
        }
        if (choice === "area") {
            this.setState({
                getCity: true
            })
        }
    }
    ChangeOptionMobile = (choice) => {
        this.setState({
            currentChoiceMobile: choice,
            txtChoice: '',
            ddlChoice: '',
            invalidTxtChoice: '',
            invalidDDlChoice: '',
        })
        if (choice === "city") {
            this.setState({
                getState: true
            })
        }
        if (choice === "area") {
            this.setState({
                getCity: true
            })
        }
        if (choice === "state") {
            this.setState({
                getCountry: true
            })
        }
    }
    HandleUserInput = (e) => {
        var name = e.target.name
        var val = e.target.value

        if (this.state.currentChoice === "city" || this.state.currentChoiceMobile === "city") {
            if (name === "txtCityName") {
                if (val !== "") {
                    this.setState({
                        txtChoice: val,
                        invalidTxtChoice: false
                    })
                }
                else {
                    this.setState({
                        txtChoice: '',
                        invalidTxtChoice: true
                    })
                }
            }
            if (name === "ddlState") {
                if (val !== "") {
                    this.setState({
                        ddlChoice: val,
                        invalidDDlChoice: false
                    })
                }
                else {
                    this.setState({
                        invalidDDlChoice: true
                    })
                }
            }
            if (name === "chkCityEntryStatus") {
                if (e.target.checked === true) {
                    this.setState({
                        chkEntryStatus: 'on'
                    })
                }
                else {
                    this.setState({
                        chkEntryStatus: 'off'
                    })
                }
            
            }
        }
        if (this.state.currentChoice === "area" || this.state.currentChoiceMobile === "area") {
            if (name === "txtAreaName") {
                if (val !== "") {
                    this.setState({
                        txtChoice: val,
                        invalidTxtChoice: false
                    })
                }
                else {
                    this.setState({
                        txtChoice: '',
                        invalidTxtChoice: true
                    })
                }
            }
            if (name === "ddlCity") {
                if (val !== "") {
                    this.setState({
                        ddlChoice: val,
                        invalidDDlChoice: false
                    })
                }
                else {
                    this.setState({
                        invalidDDlChoice: true
                    })
                }
            }
            if (name === "chkAreaEntryStatus") {
                if (e.target.checked === true) {
                    this.setState({
                        chkEntryStatus: 'on'
                    })
                }
                else {
                    this.setState({
                        chkEntryStatus: 'off'
                    })
                }
               
            }
        }
        if (this.state.currentChoice === "state") {
            if (name === "txtStateName") {
                if (val !== "") {
                    this.setState({
                        txtChoice: val,
                        invalidTxtChoice: false
                    })
                }
                else {
                    this.setState({
                        txtChoice: '',
                        invalidTxtChoice: true
                    })
                }
            }
            if (name === "ddlCountry") {
                if (val !== "") {
                    this.setState({
                        ddlChoice: val,
                        invalidDDlChoice: false
                    })
                }
                else {
                    this.setState({
                        invalidDDlChoice: true,
                        ddlChoice: '',
                    })
                }
            }
            if (name === "chkStateEntryStatus") {
                if (e.target.checked === true) {
                    this.setState({
                        chkEntryStatus: 'on'
                    })
                }
                else {
                    this.setState({
                        chkEntryStatus: 'off'
                    })
                }
               
            }
        }


    }
    SaveEntryData = () => {
      
        if (this.state.txtChoice === '') {
            this.setState({
                invalidTxtChoice: true
            })
        }
        else {
            this.setState({
                invalidTxtChoice: false
            })
        }
        if (this.state.ddlChoice === '') {
            this.setState({
                invalidDDlChoice: true
            })
        }
        else {
            this.setState({
                invalidDDlChoice: false
            })
        }
        if (this.state.txtChoice !== '' && this.state.ddlChoice !== '') {
            if (this.state.currentChoice === 'state') {
                // alert(1)
                this.data = {
                    txtStateName: this.state.txtChoice,
                    ddlCountryName: this.state.ddlChoice,
                    chkStateEntryStatus: this.state.chkEntryStatus

                }
                if (this.state.mode === "add") {
                    this.setState({
                        addState: true
                    })
                }
                else {
                    // alert(1)
                    this.setState({
                        editState: true
                    })
                }
            }
            if (this.state.currentChoice === 'city') {
                this.data = {
                    txtCityName: this.state.txtChoice,
                    ddlStateName: this.state.ddlChoice,
                    chkCityEntryStatus: this.state.chkEntryStatus

                }
                if (this.state.mode === "add") {
                    this.setState({
                        addCity: true
                    })
                }
                else {
                    this.setState({
                        editCity: true
                    })
                }
            }
            if (this.state.currentChoice === 'area') {
                this.data = {
                    txtAreaName: this.state.txtChoice,
                    ddlCityName: this.state.ddlChoice,
                    chkAreaEntryStatus: this.state.chkEntryStatus

                }
                if (this.state.mode === "add") {
                    this.setState({
                        addArea: true
                    })
                }
                else {
                    this.setState({
                        editArea: true
                    })
                }
            }


        }
    }
    SaveEntryDataMobile = () => {
        if (this.state.txtChoice === '') {
            this.setState({
                invalidTxtChoice: true
            })
        }
        else {
            this.setState({
                invalidTxtChoice: false
            })
        }
        if (this.state.ddlChoice === '') {
            this.setState({
                invalidDDlChoice: true
            })
        }
        else {
            this.setState({
                invalidDDlChoice: false
            })
        }
        if (this.state.txtChoice !== '' && this.state.ddlChoice !== '') {
            if (this.state.currentChoiceMobile === 'state') {
                // alert(1)
                this.data = {
                    txtStateName: this.state.txtChoice,
                    ddlCountryName: this.state.ddlChoice,
                    chkStateEntryStatus: this.state.chkEntryStatus

                }
                if (this.state.mode === "add") {
                    this.setState({
                        addState: true
                    })
                }
                else {
                    // alert(1)
                    this.setState({
                        editState: true
                    })
                }
            }
            if (this.state.currentChoiceMobile === 'city') {
                this.data = {
                    txtCityName: this.state.txtChoice,
                    ddlStateName: this.state.ddlChoice,
                    chkCityEntryStatus: this.state.chkEntryStatus

                }
                if (this.state.mode === "add") {
                    this.setState({
                        addCity: true
                    })
                }
                else {
                    this.setState({
                        editCity: true
                    })
                }
            }
            if (this.state.currentChoiceMobile === 'area') {
                this.data = {
                    txtAreaName: this.state.txtChoice,
                    ddlCityName: this.state.ddlChoice,
                    chkAreaEntryStatus: this.state.chkEntryStatus

                }
                if (this.state.mode === "add") {
                    this.setState({
                        addArea: true
                    })
                }
                else {
                    this.setState({
                        editArea: true
                    })
                }
            }


        }
    }

    HandleStateListResponse = (res) => {
     
        this.setState({
            stateList: res.data.statelist
        })
    }

    HandleCountryListResponse = (res) => {
        this.setState({
            countryList: res.data.countrylist
        })
    }
    HandleStateSubmitResponse = (res) => {
       
        var that = this
        if (res !== false) {
            document.getElementById('spnSuccessMessageTitle').innerText = "State Message - ";
            document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
            // $('#crmSuccessAlert').slideDown().slideUp(5000)
            $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
                if (res.data && res.data.stateentryresponse && res.data.stateentryresponse.value) {
                    AppUtils.HandlePageClick(that.props.componentprops, 'entries');
                }
            }.bind(this));

            this.setState({
                txtChoice: '',
                ddlChoice: '',
                chkEntryStatus: 'off'
            })
        }

        this.setState({
            addState: false
        })
    }
    HandleCitySubmitResponse = (res) => {
       
        var that = this;
        if (res !== false) {
            document.getElementById('spnSuccessMessageTitle').innerText = "City Message - ";
            document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
            $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
                if (res.data && res.data.cityentryresponse && res.data.cityentryresponse.value) {
                    AppUtils.HandlePageClick(that.props.componentprops, 'entries');
                }
            }.bind(this));

            this.setState({
                txtChoice: '',
                ddlChoice: '',
                chkEntryStatus: 'off'
            })
        }

        this.setState({
            addCity: false
        })

    }
    HandleGetCityListSuccessResponse = (res) => {
      
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    HandleAreaSubmitResponse = (res) => {
      
        var that = this
        if (res !== false) {
            document.getElementById('spnSuccessMessageTitle').innerText = "Area Message - ";
            document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
            // $('#crmSuccessAlert').slideDown().slideUp(5000)
            $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
                if (res.data && res.data.areaentryresponse && res.data.areaentryresponse.value) {
                    AppUtils.HandlePageClick(that.props.componentprops, 'entries');
                }
            }.bind(this));

            this.setState({
                txtChoice: '',
                ddlChoice: '',
                chkEntryStatus: 'off'
            })
        }

        this.setState({
            addArea: false
        })
    }
    HandleGetCityByIdResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.citydetails && res.data.citydetails.rows) {
                let citydetails = res.data.citydetails.rows[0];
                let status = ''
                if (citydetails.city_status === 1) {
                    status = 'on'
                }
                else
                    status = 'off'

                this.setState({
                    txtChoice: citydetails.city_name,
                    ddlChoice: citydetails.state_name,
                    chkEntryStatus: status
                })
            }
        }
        this.setState({
            getCityById: false,
            showEditBtn: 'block'
        })
    }
    HandleEditCityByIdResponse = (res) => {
      
        var that = this
        if (res !== false) {
            document.getElementById('spnSuccessMessageTitle').innerText = "City Message - ";
            document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
            //window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
            this.setState({
                editCity: false
            })
            $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
                if (res.data && res.data.cityeditresponse && res.data.cityeditresponse.value) {
                    AppUtils.HandlePageClick(that.props.componentprops, 'entries');
                }
            }.bind(this));
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "City Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {

            })

            this.setState({
                editCity: false
            })
        }

    }
    HandleGetAreaByIdResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.areadetails && res.data.areadetails.rows) {
                let areadetails = res.data.areadetails.rows[0];
                let status = ''
                if (areadetails.area_status === 1) {
                    status = 'on'
                }
                else
                    status = 'off'

                this.setState({
                    txtChoice: areadetails.area_name,
                    ddlChoice: areadetails.city_name,
                    chkEntryStatus: status
                })
            }
        }
        this.setState({
            getAreaById: false,
            showEditBtn: 'block',
            mode: 'update'
        })
    }
    HandleGetStateByIdResponse = (res) => {
        
        if (res !== false) {
            if (res.data && res.data.statedetails && res.data.statedetails.rows) {
                let statedetails = res.data.statedetails.rows[0];
                let status = ''
                if (statedetails.state_status === 1) {
                    status = 'on'
                }
                else
                    status = 'off'

                this.setState({
                    txtChoice: statedetails.state_name,
                    ddlChoice: statedetails.country_name,
                    chkEntryStatus: status
                })
            }
        }
        this.setState({
            getStateById: false,
            showEditBtn: 'block',
            mode: 'update'
        })
    }
    HandleEditStateResponse = (res) => {
       
        var that = this
        if (res !== false) {
            document.getElementById('spnSuccessMessageTitle').innerText = "State Message - ";
            document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
            //window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
            this.setState({
                editState: false
            })
            $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
                if (res.data && res.data.stateeditresponse && res.data.stateeditresponse.value) {
                    AppUtils.HandlePageClick(that.props.componentprops, 'entries');
                }
            }.bind(this));
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "City Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {

            })

            this.setState({
                editState: false
            })
        }
    }
    HandleEditAreaResponse = (res) => {
       
        var that = this
        if (res !== false) {
            document.getElementById('spnSuccessMessageTitle').innerText = "Area Message - ";
            document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
            //window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
            this.setState({
                editArea: false
            })
            $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
                if (res.data && res.data.areaeditresponse && res.data.areaeditresponse.value) {
                    AppUtils.HandlePageClick(that.props.componentprops, 'entries');
                }
            }.bind(this));
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "City Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {

            })

            this.setState({
                editArea: false
            })
        }
    }
    render() {
        return (
            <Container fluid className="data-table" style={{ paddingLeft: 0 }}>
                <EntryForm {...this.props}
                    ChangeOption={this.ChangeOption} mystate={this.state}
                    SaveEntryData={this.SaveEntryData}
                    HandleUserInput={this.HandleUserInput}
                    ShowEditMode={this.ShowEditMode}
                    showEditBtn={this.state.showEditBtn} />
                <MobForm {...this.props}
                    ChangeOptionMobile={this.ChangeOptionMobile} mystate={this.state}
                    SaveEntryDataMobile={this.SaveEntryDataMobile}
                    HandleUserInput={this.HandleUserInput}
                    ShowEditMode={this.ShowEditMode}
                    showEditBtn={this.state.showEditBtn} />
                {((this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {((this.state.getCountry === true) ? GetCountries(this.props.PnkStore, this.HandleCountryListResponse) : "")}
                {((this.state.getState === true) ? GetStateList(this.props.PnkStore, this.HandleStateListResponse) : "")}
                {((this.state.addState === true) ? AddStates(this.props.PnkStore, this.data, this.HandleStateSubmitResponse) : "")}
                {((this.state.addCity === true) ? AddCities(this.props.PnkStore, this.data, this.HandleCitySubmitResponse) : "")}
                {((this.state.addArea === true) ? AddAreas(this.props.PnkStore, this.data, this.HandleAreaSubmitResponse) : "")}
                {((this.state.getCityById === true) ? GetCityById(this.props.PnkStore, this.state.currentSeletedId, this.HandleGetCityByIdResponse) : "")}
                {((this.state.editCity === true) ? EditCity(this.props.PnkStore, this.state.currentSeletedId, this.data, this.HandleEditCityByIdResponse) : "")}
                {((this.state.getAreaById === true) ? GetAreaById(this.props.PnkStore, this.state.currentSeletedId, this.HandleGetAreaByIdResponse) : "")}
                {((this.state.getStateById === true) ? GetStateById(this.props.PnkStore, this.state.currentSeletedId, this.HandleGetStateByIdResponse) : "")}
                {((this.state.editState === true) ? EditState(this.props.PnkStore, this.state.currentSeletedId, this.data, this.HandleEditStateResponse) : "")}
                {((this.state.editArea === true) ? EditArea(this.props.PnkStore, this.state.currentSeletedId, this.data, this.HandleEditAreaResponse) : "")}
            </Container>

        )
    }
}
export const EntryPage = PnkConnect(entryPage, "leadlist");