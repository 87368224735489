import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import { Container } from 'react-bootstrap'
import ExotelCallList from './exotel_calls'
import Options from './options'
import { IncomingCallList } from '../../models/services'
// import { SourceEntryPage } from '../LeadSourceEntry'
// import Footer from './mob-option'
import AppUtils from '../../apputils';
import $ from 'jquery';
class exotelCall extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           getExotelCalls:true,
           setNo: 1,
           ddlCallSort: 'All Call',
           setLength: 50,
            callData:[],
            totalRecordsInDb: 0,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,
        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Exotel Calls');
        this.props.componentprops.ShowAdvanceSearch(false);
        this.props.PnkStore.SetData('setNoExotel', 1)
       this.callData=[]
    }
    GoToLeadEditPage=(lead_id)=>{
        AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/' + lead_id)
    }
 
 
    HandleSuccessCallListResponse=(res)=>{
       
       
        this.setState({
            getExotelCalls:false
        })
       let calllist=[]
        if(res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows[0].incoming && res.data.calllist.rows[0].incoming.length>0){
           
           res.data.calllist.rows[0].incoming.map(incoming=>{
            calllist.push({
                'from':incoming.from,
                'date_created':incoming.date_created,
                'to':incoming.to,
                'recording_url':incoming.recording_url,
                'status':incoming.status,
                'id':incoming._id,
                'user_name':'',
                'lead_id':''
                
            })
            if( res.data.calllist.rows[0].leads && res.data.calllist.rows[0].leads.length>0){
                res.data.calllist.rows[0].leads.map(lead=>{
                   
                    if(((incoming.from).substr((incoming.from).length - 10))===((lead.mobile).substr((lead.mobile).length - 10))){
                        let typeIndex = calllist.findIndex(mobile => ((lead.mobile).substr((lead.mobile).length - 10)) ===((mobile.from).substr((mobile.from).length - 10)));
                       
                        if(typeIndex!==-1){
                        calllist[typeIndex].user_name=lead.name
                        calllist[typeIndex].lead_id=lead.lead_id
                        
                       
                       
                        }
                    }
                   
                    
                })
            }
            
        })
        
       
            this.setState({
                callData:calllist
            })
            let total = 0;
            let totalPages = 0;
            total = res.data.calllist.metarow.totalRecordsInDb
           
            totalPages = Math.ceil(total / this.state.setLength);

            let number =  this.props.PnkStore.GetData('setNoExotel')
           

            if (number - 1 === 0) {
                // this.setState({ getLeadList: true, currentPageNumber: number, startValue: 0, endValue: (number * this.state.setLength), selectAll: false });
                this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.setLength), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            else {
                // this.setState({ getLeadList: true, currentPageNumber: number, startValue: ((number - 1) * this.state.setLength), endValue: (number * this.state.setLength), selectAll: false });
                this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.setLength), endValue: (number * this.state.setLength), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
        }
    }
    ChangePage = (number) => {
       
       
        this.props.PnkStore.SetData('setNoExotel', number)
            this.setState({ getExotelCalls: true ,currentPageNumber: number})
            
        
    }
    ReloadPage=()=>{
       this.setState({
        getExotelCalls:true
       }) 
    }
    GotoLeadEntryPage=(mobile)=>{
        sessionStorage.setItem("fromExotelToLead",mobile)
        AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/')
    }

    render() {
      
        return (
            <Container fluid className="data-table-lead">
             <Options mystate={this.state} {...this.props} ReloadPage={this.ReloadPage}/>
                <ExotelCallList mystate={this.state} {...this.props}
                    LeadSourceData={this.state.leadSourceList}
                   callData={this.callData}
                    HandleUserAction={this.HandleUserAction}
                    ChangePage={this.ChangePage}
                    GoToLeadEditPage={this.GoToLeadEditPage}
                    GotoLeadEntryPage={this.GotoLeadEntryPage}
                />
             
                 {((this.state.getExotelCalls === true) ?  IncomingCallList(this.props.PnkStore, this.state.currentPageNumber, this.state.setLength, this.state.ddlCallSort, '', this.HandleSuccessCallListResponse) : '')}

            </Container>

        )
    }
}
export const ExotelCallPage = PnkConnect(exotelCall, "leadlist");