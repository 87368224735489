import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import ProfileEntry from './profile-entry'
import { Container } from 'react-bootstrap'
import { AddUserProfile, GetUserProfileDetailsById, EditUserProfile, GetUserProfileList } from '../../models/users'
import $ from 'jquery';
// import BulkUpload from './bulk-upload'
class profileEntryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            showProfileModal: false,
            ddlAllowedRoutes: [],
            ddlAllowedLeadStages: [],
            ddlAllowedBookingStages: [],
            ddlUserProfileStatus: '',
            sendProfileData: false,
            currentSeletedProfileId: '',
            getUserProfileEditData: false,
            showButtonType: 'Save',
            showEditBtn: 'none',
            editUserProfile: false,
            ddlAllowedRoutesStatus: false,
            ddlAllowedLeadStagesStatus: false,
            ddlAllowedBookingStagesStatus: false,
            ddlUserProfileStatusStatus: false,
            getProfileData: false,
            showToast: true,


        }
        // alert(JSON.stringify(this.props.componentprops.currentAction));
        // this.props.componentprops.HandleBackButton(false);
        // this.props.componentprops.HandleTitle('Profile');
        // this.props.componentprops.ShowAdvanceSearch(true);
        this.editDataObj = {}
        this.data = {}
    }
    componentDidMount = () => {
        this.ChangePageMode()
    }
    HandleUserInput = (e) => {
        
        let val = e.target.value;
        let name = e.target.name;
        this.editDataObj[name] = val;
        this.setState({ [name]: val })
    }
    HideModal = () => {
        this.props.componentprops.currentAction.close();
    }
    HandleSubmit = (event) => {
    }
    SaveProfileData = (e) => {
       
        this.data = {
            txtUserProfileName: e.txtUserProfileName,
            txtUserProfileDescription: e.txtUserProfileDescription
        }

        if (this.state.ddlAllowedRoutes == '')
            this.data['ddlAllowedRoutes'] = [];
        else
            this.data['ddlAllowedRoutes'] = e.ddlAllowedRoutes.split(',')
        if (this.state.ddlAllowedLeadStages == '')
            this.data['ddlAllowedLeadStages'] = [];
        else
            this.data['ddlAllowedLeadStages'] = e.ddlAllowedLeadStages.split(',')
        if (this.state.ddlAllowedBookingStages == '')
            this.data['ddlAllowedBookingStages'] = [];
        else
            this.data['ddlAllowedBookingStages'] = e.ddlAllowedBookingStages.split(',')

        if (this.state.ddlUserProfileStatus === "0") {
            this.data['ddlUserProfileStatus'] = 0;
        }
        else {
            this.data['ddlUserProfileStatus'] = 1;
        }
       
        if (this.state.showButtonType === "Save") {
            this.setState({
                sendProfileData: true
            })
        }
        else {
            this.setState({
                editUserProfile: true
            })
        }

    }
    HandleProfileSubmiteResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.addprofileresponse && res.data.addprofileresponse.value === "SUCCESS") {
                this.setState({
                    sendProfileData: false,

                })
                this.HideModal();

            }
        }
        else {
            this.HideModal();
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Profile Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({
                sendProfileData: false,

            })
        }

    }
    ChangePageMode = () => {
        // alert(JSON.stringify(this.props.componentprops.currentAction));
        let obj;
      
        if (this.props.componentprops.currentAction.action.action === 'Detail') {
            obj = {
                profileActionHeading: 'Profile Detail',
                editDeleteDiv: true,
                getUserProfileEditData: true,
                currentSeletedProfileId: this.props.componentprops.currentAction.action.id,
                showButtonType: 'Update',
                formModeActivity: true,
                btnUserProfileOuterDiv: false,

                ddlUserProfileStatus: "",
                ddlAllowedRoutes: [],
                ddlAllowedLeadStages: [],
                ddlAllowedBookingStages: [],
            };
            let form = document.getElementById("frmProfileEntry");
            let elements = form.elements;

            for (let i = 0, len = elements.length; i < len; ++i) {
                if (elements[i].id.includes('ddl')) {
                    let item = elements[i].id + "Status";
                    this.setState({
                        [item]: true
                    })
                }
                else
                    elements[i].readOnly = true;
            }
           
        }
        else {
            obj = { formModeActivity: false, btnUserProfileOuterDiv: true, editDeleteDiv: false };
            let form = document.getElementById("frmProfileEntry");
            let elements = form.elements;
            for (let i = 0, len = elements.length; i < len; ++i) {
                if (elements[i].id.includes('ddl')) {
                    let item = elements[i].id + "Status";
                    this.setState({
                        [item]: false
                    })
                }
                else
                    elements[i].readOnly = false;
            }
        }

        this.setState(obj);
    }
    ShowEditMode = () => {
       
        let form = document.getElementById("frmProfileEntry");
        let elements = form.elements;
        for (let i = 0, len = elements.length; i < len; ++i) {
            if (elements[i].id.includes('ddl')) {
                let item = elements[i].id + "Status";
                this.setState({
                    [item]: false
                })
            }
            else
                elements[i].readOnly = false;
        }
        this.setState({ formModeActivity: false, profileActionHeading: 'Edit Profile', btnUserProfileOuterDiv: true, showEditBtn: 'none' })

    }
    HandleGetProfileByIdResponse = (res) => {
       

        if (res.data && res.data.profiledetails && res.data.profiledetails.rows) {

            let profiledata = res.data.profiledetails.rows[0];

            let status = '';
            if (profiledata.profile_status === 0)
                status = "0";
            else
                status = "1";
            this.editDataObj = {
                txtUserProfileName: profiledata.profile_name,
                txtUserProfileDescription: profiledata.profile_desc,
                ddlUserProfileStatus: status,
                ddlAllowedRoutes: profiledata.allowed_routes,
                ddlAllowedLeadStages: profiledata.allowed_lead_stages,
                ddlAllowedBookingStages: profiledata.allowed_booking_stages
            }
            this.setState({
                ddlUserProfileStatus: status,
                ddlAllowedRoutes: profiledata.allowed_routes,
                ddlAllowedLeadStages: profiledata.allowed_lead_stages,
                ddlAllowedBookingStages: profiledata.allowed_booking_stages,


            })
            // if (profiledata.profile_name == 'Administrator' || profiledata.profile_name == 'Sales' || profiledata.profile_name == 'Telecalling')
            document.getElementById('txtUserProfileName').disabled = true;
            this.setState({ getUserProfileEditData: false, showEditBtn: 'block' });
        }

    }
    HandleEditUserProfileDataSuccessResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.profileeditresp && res.data.profileeditresp.value === "SUCCESS") {
                this.setState({
                    editUserProfile: false,
                    getProfileData: true
                })
                this.HideModal();
            }
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Profile Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({
                editUserProfile: false,
                getProfileData: true
            })
            this.HideModal();
        }

    }
    HandleProfileGetResponse = (res) => {
       
        if (res.data && res.data.profilelist && res.data.profilelist.rows) {
           
            this.props.PnkStore.SetData('profilelist', res.data.profilelist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({
            getProfileData: false
        })

    }

    render() {
      
        return (
            <Container fluid className="data-table">

                <ProfileEntry HideProfileModal={this.HideModal} visible={this.state.showProfileModal} mystate={this.state} {...this.props} HandleUserInputs={this.HandleUserInput}
                    SaveProfileData={this.SaveProfileData} editDataObj={this.editDataObj} HandleUserInputsMultiple={this.HandleUserInputsMultiple}
                    showEditBtn={this.state.showEditBtn} ShowEditMode={this.ShowEditMode} />

                {(this.state.sendProfileData === true) ? AddUserProfile(this.props.PnkStore, this.data, this.HandleProfileSubmiteResponse) : ''}
                {(this.state.getUserProfileEditData === true) ? GetUserProfileDetailsById(this.props.PnkStore, this.state.currentSeletedProfileId, this.HandleGetProfileByIdResponse) : ''}
                {((this.state.editUserProfile === true) ? EditUserProfile(this.props.PnkStore, this.state.currentSeletedProfileId, this.editDataObj, this.HandleEditUserProfileDataSuccessResponse) : "")}
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
            </Container >
        )
    }
}
export const ProfileEntryPage = PnkConnect(profileEntryPage, "");
