import React from 'react'
import { Row, Col, Button, ButtonGroup, Accordion, Card } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
export default class SalesReport extends React.Component {
    constructor(props) {
        super(props)
    }
    ShowPatchDetails = () => {
        return <div>hai</div>
    }
    render() {

        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        {this.props.mystate.patchView === 'default' ?
                            <Row className="mob-row-align hide-mob">
                                <div className="report-btn-div">
                                    <ButtonGroup className="report-btn-group">
                                        {this.props.salesReportType.map(item =>
                                            this.props.mystate.currentSalesReportType === item ?
                                                <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                                <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                        )}
                                    </ButtonGroup>
                                    {this.props.mystate.currentSalesReportType === "Custom" ?
                                        <div className="custom-date-div">

                                            <DatePicker
                                                name="txtCustomStartDate" id="txtCustomStartDate"
                                                selected={this.props.mystate.txtCustomStartDate}
                                                onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                                className="custom-date-picker"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select date"
                                            />
                                            <span className="ml-2 mr-3">To</span>
                                            <DatePicker
                                                name="txtCustomEndDate" id="txtCustomEndDate"
                                                selected={this.props.mystate.txtCustomEndDate}
                                                onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                                className="custom-date-picker mr-3"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select date"
                                            />
                                            <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                        </div> : null}
                                </div>
                            </Row> : null}
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    {this.props.mystate.patchView === 'default' ?
                                        <Row className="mob-row-align mob-row-color">

                                            <Col lg={3} xs={3}>
                                                <h3 className="table-head-lead ">Telicalling User</h3>
                                            </Col>

                                            <Col lg={2} xs={3} className="">
                                                <h3 className="table-head-lead">Lead Patched To</h3>
                                            </Col>
                                            <Col xs={3} className="">
                                                <h3 className="table-head-lead">Count</h3>
                                            </Col>
                                            <Col xs={3} className="">
                                                <h3 className="table-head-lead">Details</h3>
                                            </Col>


                                        </Row> : null}
                                    {this.props.mystate.patchView === 'details' ?
                                        <Row className="mob-row-align mob-row-color">


                                            <Col lg={2} xs={3}>
                                                <h3 className="table-head-lead ">Acquisition Date</h3>
                                            </Col>
                                            <Col lg={2} className="">
                                                <h3 className="table-head-lead">Lead Patched To</h3>
                                            </Col>
                                            <Col xs={2} className="">
                                                <h3 className="table-head-lead">User Name</h3>
                                            </Col>

                                            <Col xs={2} className="hide-mob">
                                                <h3 className="table-head-lead">User Contact</h3>
                                            </Col>
                                            <Col xs={2} className="hide-mob">
                                                <h3 className="table-head-lead">Patch Date Time</h3>
                                            </Col>
                                            <Col xs={2} className="hide-mob">
                                                <h3 className="table-head-lead">Current Stage</h3>
                                            </Col>


                                        </Row> : null}
                                    <div>
                                        {
                                            this.props.mystate.patchView === 'default' ?

                                                this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                                    (tele_assignee, j) => (
                                                        tele_assignee.list.map((sales_details, i) =>
                                                            <div className="lead-table-data-user">

                                                                {(((j + i) + 1) % 2 === 0) ?
                                                                    (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>



                                                                        <Col lg={3} className="" xs={3}>
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{tele_assignee.tele_assignee}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>

                                                                        <Col lg={2} className="" xs={3}>

                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{sales_details.lead_owner}</div>
                                                                                </div>
                                                                            </div>

                                                                        </Col>

                                                                        <Col lg={2} xs={3} className="">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{sales_details.patch_count}</div>
                                                                                </div>

                                                                            </div>
                                                                        </Col>

                                                                        <Col xs={2} className="show-mob">
                                                                            <FontAwesomeIcon icon={faEye} className="dashbord-view-more-icon" onClick={() => this.props.ShowSalesInsideModal(tele_assignee)} />
                                                                        </Col>

                                                                        <Col lg={2} xs={2} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>
                                                                                        <button className='btn  btn-success' onClick={() => this.props.PatchDetailedReport(sales_details)}>Details</button>


                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </Col>




                                                                    </Row>) : (
                                                                        <Row className="table-items-user" style={{ background: '#ffffff' }}>


                                                                            <Col lg={3} className="" xs={3}>
                                                                                <div className="table-data-user">
                                                                                    <div className="lead-data">
                                                                                        <div className="text-overflow">{tele_assignee.tele_assignee}</div>

                                                                                    </div>
                                                                                </div>

                                                                            </Col>

                                                                            <Col lg={2} className="" xs={3}>

                                                                                <div className="table-data-user">
                                                                                    <div className="lead-data">
                                                                                        <div>{sales_details.lead_owner}</div>
                                                                                    </div>
                                                                                </div>

                                                                            </Col>

                                                                            <Col lg={2} xs={3} className="">
                                                                                <div className="table-data-user">
                                                                                    <div className="lead-data">
                                                                                        <div>{sales_details.patch_count}</div>
                                                                                    </div>

                                                                                </div>
                                                                            </Col>
                                                                            {/* <Col xs={3} className="show-mob">
                                                                    <FontAwesomeIcon icon={faEye} className="dashbord-view-more-icon" onClick={() => this.props.ShowSalesInsideModal(tele_assignee)} />
                                                                </Col> */}
                                                                            <Col lg={2} xs={3} className="">
                                                                                <div className="table-data-user">
                                                                                    <div className="lead-data">
                                                                                        <div>
                                                                                            <button className='btn  btn-success' onClick={() => this.props.PatchDetailedReport(sales_details)}>Details</button>


                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </Col>

                                                                        </Row>


                                                                    )}


                                                            </div>


                                                        ))) : null : null}
                                        {this.props.mystate.patchView === 'details' ?

                                            this.props.mystate.patchDetailReport !== [] && this.props.mystate.patchDetailReport.lead_owner !== '' && this.props.mystate.patchDetailReport.user_details.length > 0 ? this.props.mystate.patchDetailReport.user_details.map(
                                                (userdetails, j) => (

                                                    <div className="lead-table-data-user">
                                                        {this.props.mystate.ddlCurrentLeadStage !== '' ?
                                                            this.props.mystate.ddlCurrentLeadStage === userdetails.lead_current_stage ?

                                                                <Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                                    <Col lg={2} className="" xs={3}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{userdetails.date_time}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="" xs={3}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{this.props.mystate.patchDetailReport.lead_owner}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} className="hide-mob" xs={3}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{userdetails.client_name}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={2} xs={2} className="">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{userdetails.client_contact}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} xs={2} className="">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{userdetails.patch_date_time}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} xs={2} className="">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{userdetails.lead_current_stage}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>



                                                                </Row> : null :
                                                            <Row className="table-items-user" style={{ background: '#fbfbfb' }}>
                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{userdetails.date_time}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>


                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{this.props.mystate.patchDetailReport.lead_owner}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="hide-mob" xs={3}>

                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{userdetails.client_name}</div>
                                                                        </div>
                                                                    </div>

                                                                </Col>


                                                                <Col lg={2} xs={2} className="">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{userdetails.client_contact}</div>
                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                                <Col lg={2} xs={3} className="">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{userdetails.patch_date_time}</div>
                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                                <Col lg={2} xs={3} className="">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{userdetails.lead_current_stage}</div>
                                                                        </div>

                                                                    </div>
                                                                </Col>



                                                            </Row>}


                                                    </div>


                                                )) : null : null}
                                        {this.props.mystate.patchView === 'default' ?
                                            <Row className="table-items-user mt-3" style={{ background: 'rgb(204 201 201)' }}>

                                                <Col lg={3} className="" xs={6}>
                                                    <div className="table-data-user">
                                                        <div className="lead-data">
                                                            <div className="text-overflow" style={{ fontWeight: 600, fontSize: '15px' }}>Total</div>

                                                        </div>
                                                    </div>

                                                </Col>
                                                <Col lg={2} className=""></Col>
                                                <Col lg={3} className="" xs={3}>
                                                    <div className="table-data-user">
                                                        <div className="lead-data">
                                                            <div className="text-overflow" style={{ fontWeight: 600, fontSize: '15px' }}>{this.props.mystate.total_patch_count}</div>

                                                        </div>
                                                    </div>

                                                </Col>
                                            </Row> : null}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}