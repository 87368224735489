import React from 'react'
import {Row,Col} from 'react-bootstrap'
import Pagination from '../Pagination'
export default class ExotelCallList extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
       
        return(
            <Row>
            <Col lg={12} className="sidebar-col">
                <div className="crm-body">
                    <div className="lead-table-div">
                        <div className="home-container">
                            <div className="mob-container">
                            <Row className="mob-row-align">


                                <Col lg={1} xs={3}>
                                    <h3 className="table-head-lead ">Call From</h3>
                                </Col>

                                <Col lg={2} xs={3}>
                                    <h3 className="table-head-lead">Date Time</h3>
                                </Col>

                                <Col lg={1}  className="hide-mob">
                                    <h3 className="table-head-lead">Call To</h3>
                                </Col>
                                <Col lg={2} xs={3} className="">
                                    <h3 className="table-head-lead">User Name</h3>
                                </Col>
                                <Col lg={3}  className="hide-mob">
                                    <h3 className="table-head-lead">Recording </h3>
                                </Col>

                                <Col lg={1} className="hide-mob" >
                                    <h3 className="table-head-lead">Status</h3>
                                </Col>
                                <Col lg={2} xs={3} className="">
                                    <h3 className="table-head-lead">Action</h3>
                                </Col>

                                </Row>
                                <div>
                                        {this.props.mystate.callData && this.props.mystate.callData.length > 0
                                            ? this.props.mystate.callData.map(
                                                (item, i) => (
                                                    <div className="lead-table-data-user">
                                                  

                                                            <Row className="table-items-user" style={{ background: '#ffffff' }}>

                                                            {/* <Col lg={1} className="hide-mob">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div> {i + 1}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col> */}
                                                                <Col lg={1} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.from}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.date_created}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} className="hide-mob" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.to}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.user_name}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={3} className="hide-mob" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                        {(item.recording_url != null && item.recording_url != '')  ?
                                                <audio controls style={{height:'35px',width:'100%'}}>
                                                    <source src={item.recording_url} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                                :''}

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} className="hide-mob" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.status}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            {/* <div className="text-overflow">{item.user_name}</div> */}
                                                                           { item.user_name!==''?
                                                                            <button className='btn btn-primary fontAction' onClick={()=>this.props.GoToLeadEditPage(item.lead_id)}>Details</button>:
                                                                             <button className='btn btn-success fontAction' onClick={()=>this.props.GotoLeadEntryPage(item.from)}>Add Lead</button> }

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                </Row>
                                                                </div>
                                                )):null}
                                                 {this.props.mystate.currentPageNumber <= this.props.mystate.totalRecordsInDb ?
                                        <div className="pagination-div-profile">
                                            <Pagination totalNumber={this.props.mystate.totalPages} currentPageNumber={this.props.mystate.currentPageNumber} ChangePage={this.props.ChangePage} />
                                               
                                        </div> : null}
                                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        
    )
    }
}