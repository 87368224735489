import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Form, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {
      
        this.props.SortData(e, e.target.value)
    }
    render() {
      

        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentViewType'
                                    id='currentViewType'
                                    value={this.props.mystate.currentViewType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeViewReport(e)}>
                                           <option value="daily-report" >Daily Report</option>
                                    <option value="meeting-tracker">Meeting Tracker</option>
                                    <option value="sales-meeting-count" >Sales Meeting Count</option>
                                 

                                </Form.Control>
                            </Col>
 
                            
                           {this.props.mystate.currentViewType!=='meeting-tracker'?
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0">


                                <Form.Control as="select" className="dashboard-option-select"
                                    name='ddlPreferredCity'
                                    id='ddlPreferredCity'
                                    onChange={this.props.HandleUserInput}
                                    pnkdisplayerrormsg='Select City'
                                    value={this.props.mystate.ddlPreferredCity}
                                    style={{ padding: '4px', width: '100%' }}>
                                    <option value="-1" disabled selected>Select City</option>
                                    <option value=''>No City</option>
                                    {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                        this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                            <option value={item.city_name}>{item.city_name}</option>) : null
                                    }

                                </Form.Control>

                            </Col>:null}
                            {this.props.mystate.currentViewType!=='meeting-tracker'?
                            <Col lg={2} md={3} xs={3} className="flex-left col-padding-0">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlSalesUser'
                                        id='ddlSalesUser'
                                        onChange={this.props.HandleUserInput}
                                        value={this.props.mystate.ddlSalesUser}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="-1" disabled selected>Sales</option>
                                        <option value=""  >All</option>
                                        {this.props.mystate.all_users_under_head !==[] && 
                                           this.props.mystate.all_users_under_head.length > 0
                                            ?this.props.mystate.all_users_under_head.map(
                                                (item, i) => (
                                                  
                                                        <option value={item.user_name} >{item.user_name}</option> 
                                                )) : ""}
                                    </Form.Control>

                                </Col>
                                :null}
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0 show-mob">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentSalesReportType'
                                    id='currentSalesReportType'
                                    value={this.props.mystate.currentSalesReportType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeSalesReportTypeMobile(e)}>
                                    <option value="" disabled selected>Select</option>
                                    {this.props.salesReportType.map(item =>
                                        <option value={item} >{item}</option>)}
                                </Form.Control>

                            </Col>
                            {this.props.mystate.currentViewType ==='Patch-Report' && this.props.mystate.patchView==='details'?
                             <Col lg={2} md={3} xs={3} className="flex-left col-padding-0">
                        <button className='btn btn-secondary' onClick={()=>this.props.PatchReportDefaultView()}>Go Back</button></Col>    :null
                        }
                          
   
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}