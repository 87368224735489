import React from 'react'
import HCForm from './form'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import { Container, Row } from 'react-bootstrap'
import { SaveHCForm, SendEmail } from '../../models/services'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import $ from 'jquery';
import Album from '../../assets/images/dummy.png';
import Img from '../../assets/images/album.png';
// import pdfMake from 'pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// import htmlToPdfmake from 'html-to-pdfmake';
// import Logo from '../../assets/images/Final_logo_NEO_ROOTS.png'
class HCEntry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            txtOtherPropertyType: '',
            // txtBalconyLength: 0,
            // txtBalconyBreadth: 0,
            // txtTerraceLength: 0,
            // txtTerraceBreadth: 0,
            // txtIndoorsBreadth: 0,
            // txtIndoorsLength: 0,
            // txtLandscapingLength: 0,
            // txtLandscapingBreadth: 0,
            // txtFullHouseLength: 0,
            // txtFullHouseBreadth: 0,
            txtBalconyTotal: 0,
            ddlBalconySpaceFacing: [],
            ddlBalconyUtilisationSpace: [],
            txtBalconyOtherUtilSpace: '',
            ddlBalconyGardenNeeds: [],
            txtBalconyOtherGardenNeeds: '',
            ddlBalconyLookPreferred: [],
            ddlBalconyPlantsPreferred: [],
            txtBalconyOtherPlantPreferred: '',
            ddlBalconyAllergicPlant: '',
            txtBalconyAllergicPlant: '',
            ddlBalconyColorPalette: [],
            ddlBalconyPlanterMaterial: [],

            txtTerraceTotal: 0,
            ddlTerraceSpaceFacing: [],
            ddlTerraceUtilisationSpace: [],
            txtTerraceOtherUtilSpace: '',
            ddlTerraceGardenNeeds: [],
            txtTerraceOtherGardenNeeds: '',
            ddlTerraceLookPreferred: [],
            ddlTerracePlantsPreferred: [],
            txtTerraceOtherPlantPreferred: '',
            ddlTerraceAllergicPlant: '',
            txtTerraceAllergicPlant: '',
            ddlTerraceColorPalette: [],
            ddlTerracePlanterMaterial: [],

            txtIndoorsTotal: 0,
            ddlIndoorsSpaceFacing: [],
            ddlIndoorsUtilisationSpace: [],
            txtIndoorsOtherUtilSpace: '',
            ddlIndoorsGardenNeeds: [],
            txtIndoorsOtherGardenNeeds: '',
            ddlIndoorsLookPreferred: [],
            ddlIndoorsPlantsPreferred: [],
            txtIndoorsOtherPlantPreferred: '',
            ddlIndoorsAllergicPlant: '',
            txtIndoorsAllergicPlant: '',
            ddlIndoorsColorPalette: [],
            ddlIndoorsPlanterMaterial: [],

            txtLandscapingTotal: 0,
            ddlLandscapingSpaceFacing: [],
            ddlLandscapingUtilisationSpace: [],
            txtLandscapingOtherUtilSpace: '',
            ddlLandscapingGardenNeeds: [],
            txtLandscapingOtherGardenNeeds: '',
            ddlLandscapingLookPreferred: [],
            ddlLandscapingPlantsPreferred: [],
            txtLandscapingOtherPlantPreferred: '',
            ddlLandscapingAllergicPlant: '',
            txtLandscapingAllergicPlant: '',
            ddlLandscapingColorPalette: [],
            ddlLandscapingPlanterMaterial: [],

            txtFullHouseTotal: 0,
            ddlFullHouseSpaceFacing: [],
            ddlFullHouseUtilisationSpace: [],
            txtFullHouseOtherUtilSpace: '',
            ddlFullHouseGardenNeeds: [],
            txtFullHouseOtherGardenNeeds: '',
            ddlFullHouseLookPreferred: [],
            ddlFullHousePlantsPreferred: [],
            txtFullHouseOtherPlantPreferred: '',
            ddlFullHouseAllergicPlant: '',
            txtFullHouseAllergicPlant: '',
            ddlFullHouseColorPalette: [],
            ddlFullHousePlanterMaterial: [],

            ddlIndoorWaterType: [],
            ddlIndoorAutoDroppingSys: '',
            ddlIndoorSupportStructure: '',
            ddlIndoorSunlightSpec: '',
            txtIndoorWaterTypeVerticalGardenLength: '',
            txtIndoorVerticalGardenBreadth: '',
            ddlOutdoorWaterType: [],
            ddlOutdoorSunlightSpec: '',
            ddlOutdoorSupportStructure: '',
            ddlOutdoorAutoDroppingSys: '',
            // txtOutdoorWaterTypeVerticalGardenLength: '',
            // txtIndoorWaterTypeVerticalGardenLength: '',
            txtVerticalGardenIndoorTotal: 0,
            txtVerticalGardenOutdoorTotal: 0,
            ddlPropertType: '',
            ddlOwnershipType: '',
            ddlSpaceType: [],
            ddlVerticalGarden: [],
            txtVerticalGardenIndoorTotal: 0,
            txtVerticalGardenOutdoorTotal: 0,
            // ddlWaterType:'',
            // ddlAutoDroppingSys:'',
            // ddlSupportStructure:'',
            // ddlSunlightSpec:'',
            ddlSpaceFacing: [],
            ddlUtilisationSpace: [],
            txtOtherUtilSpace: '',
            txtOtherGardenNeeds: '',
            ddlGardenNeeds: [],
            dllLookPreferred: [],
            ddlPlantsPreferred: [],
            txtOtherPlantPreferred: '',
            ddlColorPalette: [],
            ddlAllergicPlant: '',
            txtAllergicPlant: '',
            ddlPlanterMaterial: [],
            ddlShadeSolutions: [],
            ddlOpen: [],
            ddlPartial: [],
            ddlFull: [],
            txtShadeSolutions: '',
            txtAwningShadeLength: 0,
            txtAwningShadeBreadth: 0,
            txtUmbrellaColor: '',
            txtCombinationShade: '',
            txtOtherShades: '',
            ddlInnerRoofCladding: '',
            txtInnerRoofCladding: '',
            ddlFlooringSolutions: [],
            txtFlooringSolutions: '',
            txtCombinationFlooring: '',
            ddlFloorPreparation: [],
            txtOtherFloorPrep: '',
            ddlWallSolutions: [],
            txtWallSolutions: '',
            ddlPartitions: [],
            txtPartitions: '',
            ddlTrellis: [],
            ddlCladding: [],
            ddlPartitionWithPlanters: '',
            // ddlCombination:'',
            ddlMetalStructureSupport: '',
            ddlOutdoorLighting: [],
            ddlOutdoorFurniture: [],
            ddlCoffeeSet: '',
            txtCoffeeSeater: '',
            ddlSofaSet: '',
            txtSofaSeater: '',
            ddlBenchSet: '',
            txtBenchSize: '',
            ddlBarCounterType: '',
            txtBarSeater: '',
            ddlDiningSet: '',
            txtDiningSeater: '',
            ddlSwing: '',
            txtSwingSeater: '',
            ddlDecorOptions: [],
            txtDecorOptions: '',
            ddlWallDecor: '',
            ddlWallPainting: '',
            ddlStatues: [],
            ddlWaterFeatures: '',
            ddlOtherOptions: [],
            txtFeatureMaterial: '',
            txtIssues: '',
            txtClientSuggestions: '',
            ddlFloorPlanReceived: '',
            txtElectricalRequirement: '',
            txtPlumbingRequirement: '',
            ddlClientBudget: '',
            // txtRMProjectEstimate:'',
            // txtRMPhotoVideos:'',
            // ddlRMEstimatedBudget:''
            txtRMChecklist: '',
            ddlSpaceDetails: [],
            sendData: false,
            operation: '',
            sendEmail: false,

            showDownload: false


        }
        this.editDataObj = {
            txtBalconyTotal: 0,
            ddlBalconySpaceFacing: [],
            ddlBalconyUtilisationSpace: [],
            txtBalconyOtherUtilSpace: '',
            ddlBalconyGardenNeeds: [],
            txtBalconyOtherGardenNeeds: '',
            ddlBalconyLookPreferred: [],
            ddlBalconyPlantsPreferred: [],
            txtBalconyOtherPlantPreferred: '',
            ddlBalconyAllergicPlant: '',
            txtBalconyAllergicPlant: '',
            ddlBalconyColorPalette: [],
            ddlBalconyPlanterMaterial: [],

            txtTerraceTotal: 0,
            ddlTerraceSpaceFacing: [],
            ddlTerraceUtilisationSpace: [],
            txtTerraceOtherUtilSpace: '',
            ddlTerraceGardenNeeds: [],
            txtTerraceOtherGardenNeeds: '',
            ddlTerraceLookPreferred: [],
            ddlTerracePlantsPreferred: [],
            txtTerraceOtherPlantPreferred: '',
            ddlTerraceAllergicPlant: '',
            txtTerraceAllergicPlant: '',
            ddlTerraceColorPalette: [],
            ddlTerracePlanterMaterial: [],

            txtIndoorsTotal: 0,
            ddlIndoorsSpaceFacing: [],
            ddlIndoorsUtilisationSpace: [],
            txtIndoorsOtherUtilSpace: '',
            ddlIndoorsGardenNeeds: [],
            txtIndoorsOtherGardenNeeds: '',
            ddlIndoorsLookPreferred: [],
            ddlIndoorsPlantsPreferred: [],
            txtIndoorsOtherPlantPreferred: '',
            ddlIndoorsAllergicPlant: '',
            txtIndoorsAllergicPlant: '',
            ddlIndoorsColorPalette: [],
            ddlIndoorsPlanterMaterial: [],

            txtLandscapingTotal: 0,
            ddlLandscapingSpaceFacing: [],
            ddlLandscapingUtilisationSpace: [],
            txtLandscapingOtherUtilSpace: '',
            ddlLandscapingGardenNeeds: [],
            txtLandscapingOtherGardenNeeds: '',
            ddlLandscapingLookPreferred: [],
            ddlLandscapingPlantsPreferred: [],
            txtLandscapingOtherPlantPreferred: '',
            ddlLandscapingAllergicPlant: '',
            txtLandscapingAllergicPlant: '',
            ddlLandscapingColorPalette: [],
            ddlLandscapingPlanterMaterial: [],

            txtFullHouseTotal: 0,
            ddlFullHouseSpaceFacing: [],
            ddlFullHouseUtilisationSpace: [],
            txtFullHouseOtherUtilSpace: '',
            ddlFullHouseGardenNeeds: [],
            txtFullHouseOtherGardenNeeds: '',
            ddlFullHouseLookPreferred: [],
            ddlFullHousePlantsPreferred: [],
            txtFullHouseOtherPlantPreferred: '',
            ddlFullHouseAllergicPlant: '',
            txtFullHouseAllergicPlant: '',
            ddlFullHouseColorPalette: [],
            ddlFullHousePlanterMaterial: [],


            ddlPropertType: '',
            ddlOwnershipType: '',
            ddlSpaceType: [],
            txtVerticalGardenIndoorTotal: 0,
            txtVerticalGardenOutdoorTotal: 0,
            ddlVerticalGarden: [],
            ddlIndoorWaterType: [],
            ddlIndoorAutoDroppingSys: '',
            ddlIndoorSupportStructure: '',
            ddlIndoorSunlightSpec: '',
            ddlOutdoorWaterType: [],
            ddlOutdoorSunlightSpec: '',
            ddlOutdoorSupportStructure: '',
            ddlOutdoorAutoDroppingSys: '',

            ddlSpaceFacing: [],
            ddlUtilisationSpace: [],
            ddlGardenNeeds: [],
            dllLookPreferred: [],
            ddlPlantsPreferred: [],
            ddlColorPalette: [],
            ddlAllergicPlant: '',
            ddlPlanterMaterial: [],
            ddlShadeSolutions: [],
            ddlOpen: [],
            ddlPartial: [],
            ddlFull: [],
            txtShadeSolutions: '',
            ddlInnerRoofCladding: '',
            txtInnerRoofCladding: '',
            ddlFlooringSolutions: [],
            txtFlooringSolutions: '',
            ddlFloorPreparation: [],
            ddlWallSolutions: [],
            txtWallSolutions: '',
            ddlPartitions: [],
            txtPartitions: '',
            ddlTrellis: [],
            ddlCladding: [],
            ddlOutdoorLighting: [],
            ddlOutdoorFurniture: [],
            ddlCoffeeSet: '',
            ddlSofaSet: '',
            ddlBenchSet: '',
            ddlBarCounterType: '',
            ddlDiningSet: '',
            ddlSwing: '',
            ddlDecorOptions: [],
            txtDecorOptions: '',
            ddlWallDecor: '',
            ddlWallPainting: '',
            ddlStatues: [],
            ddlWaterFeatures: '',
            ddlOtherOptions: [],
            ddlFloorPlanReceived: '',
            ddlClientBudget: '',



            // ddlRMEstimatedBudget:'',



        }
        this.verticalList = [
            'Indoor',
            'Outdoor',
        ];
        this.shadeSolutions = ["Pergola", "Awning", "Umbrella", "Combination of Full/Partial/Open", "Others"]
        this.fullOptions = ["Mangalore tiles", "Mangalore glass tiles", "Puff Panel", "Toughened glass", "Fibre Shingles", "Polycarbonate Sheet"]
        this.partialOptions = ["CNC sheets (metal)", "Acrylic sheet", "Tensile fabric", "Retractable Roofing", "PP Lattice"]
        this.openOptions = ["Wooden rafters/louvres", "Metal rafters", "CNC sheets (metal)"]
        this.flooringSolutions = ["Artificial Turf", "WPC Tiles", "WPC Deck", "IPE Tiles", "IPE Deck", "Stone Flooring", "Combination Flooring", "Natural Grass", "Floor Paint", "Ceramic Plain Tiles", "Ceramic Wood finish tiles", "Ceramic Printed Tiles", "Red Oxide", "Stamped Concrete", "Granite Flooring"]
        this.floorPrep = ["PCC Bed", "Waterproofing", "Sloping", "Drainage", "Stepping Stones", "Soil Filling for Pit", "Pebbles /Jelly", "Kerbing /Skirting", "Others", "Not required"]
        this.wallSolutions = ["Paint", "Tall Plants", "Planters on Wall", "Artificial Green Wall"]
        this.trellis = ["Wood", "Metal", "PP lattice", "Wires/Strings", "FRP Jhali"]
        this.cladding = ["Brick", "Stone", "Metal", "Wood", "Tiles", "Bamboo", "WPC", "IPE"]
        this.partitions = ["Metal (railings", "Wood (cladding)", "Metal CNC sheets", "PP Lattice", "Tall Screening plants", "Trellis  Creepers", "Artificial Vertical Garden", "Natural Vertical Garden", "Bamboo and Natural Fibre", "Blinds", "Terracotta Jhali"]
        this.outdoorlighting = ["Surface Light", "Spike Light", "In Ground Burial Light", "String Light", "Hanging Light", "Wall Light", "Tree Light", "Strip Light", "String Light"]
        this.outdoorFurnture = ["Coffee Set", "Sofa Set", "Bench", "Bar Counters", "Dining Set", "Swing", "Cabana", "Lounge Chairs"]
        this.decorOptions = ["Wall Decor", "Wall Painting and Mosaics", "Statues", "Tea Light Holder / Lanterns", "Pebbles and Boulders", "Water Features"]
        this.otherOptions = ["Barbecue", "Children’s Play Area", "Fire Pit", "Swimming / Lap pool", "Jacuzzi"]
        this.clientBudget = ["1-2 lacs", "2-4 lacs", "4-6 lacs", "6-8 lacs", "8 lacs and above", "Not shared"]
        this.spaceList = ["Balcony/Patio", "Terrace", "Indoors", "Landscaping (Garden)", "Full House"]
        this.spaceFaceList = ["North", "South", "East", "West"]
        this.waterAvailable = ["Inlet", "Outlet"]
        this.utilisationSpace = ["Relaxation", "Entertainment", "Home Office", "Exercise Area/Yoga Area", "Play Area", "Others"]
        this.gardenNeeds = ["Start Garden from scratch", "Redo Existing Place", "Plants Replaced", "Hardscaping Work", "Others"]
        this.lookPreferred = ["Contemporary", "Tropical", "Indian", "Mediterranean", "Zen"]
        this.plantsPreferred = ["Climbers", "Evergreens", "Flowers", "Lawn", "Trees", "Succulents", "Others"]
        this.colorPalette = ["White", "Black", "Pastels", "Brown", "Clay", "Bright"]
        this.planterMaterial = ["FRP", "Terracotta", "Ceramic", "Plastic", "Metal", "LED Planters"]
        this.statueList = ["FRP Statue", "Stone Statue", "Cement Statue"]
        this.data = {}
        this.email_data = {}
        // console.log("id", this.props.componentprops.match.params.id)
    }
    HandleUserInputs = (e) => {
        let val = e.target.value;
        let name = e.target.name;

        this.editDataObj[name] = val;
        this.setState({ [name]: val })

        if (this.state.showDownload === true)
            this.setState({ showDownload: false })
    }


    SaveHCForm = (operation) => {
        let spaceDetails = []
        if (this.editDataObj.ddlSpaceType.includes("Balcony/Patio")) {

            if (this.editDataObj.txtBalconyTotal <= 0) {
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
                document.getElementById('spnWarningMessage').innerText = "Balcony area should be greater than 0";
                $('#crmWarningAlert').slideDown().slideUp(3000);
                return false
            }
            // spaceDetails.push({ 'type': 'Balcony/Patio', 'details': { 'length': this.editDataObj.txtBalconyLength, 'breadth': this.editDataObj.txtBalconyBreadth } })
            spaceDetails.push({
                'type': 'Balcony/Patio', 'details': {
                    'total': this.editDataObj.txtBalconyTotal, 'Spacing_Facing': this.editDataObj.ddlBalconySpaceFacing,
                    'Utiliztion_Space': this.editDataObj.ddlBalconyUtilisationSpace, 'Other_Util_Space': this.editDataObj.txtBalconyOtherUtilSpace,
                    'Garden_Needs': this.editDataObj.ddlBalconyGardenNeeds, 'Other_Garden_Needs': this.editDataObj.txtBalconyOtherGardenNeeds,
                    'Look_Preferred': this.editDataObj.ddlBalconyLookPreferred, 'Plants_Preferred': this.editDataObj.ddlBalconyPlantsPreferred,
                    'Other_Plants_Preferred': this.editDataObj.txtBalconyOtherPlantPreferred, 'Alergeic_Plants': this.editDataObj.ddlBalconyAllergicPlant,
                    'Other_Allergic_Plants': this.editDataObj.txtBalconyAllergicPlant, 'Color_Palette': this.editDataObj.ddlBalconyColorPalette, 'Planter_Material': this.editDataObj.ddlBalconyPlanterMaterial
                }
            })
        }
        if (this.editDataObj.ddlSpaceType.includes("Terrace")) {

            if (this.editDataObj.txtTerraceTotal <= 0) {
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
                document.getElementById('spnWarningMessage').innerText = "Terrace area should be greater than 0";
                $('#crmWarningAlert').slideDown().slideUp(3000);
                return false
            }
            //spaceDetails.push({ 'type': 'Terrace', 'details': { 'length': this.editDataObj.txtTerraceLength, 'breadth': this.editDataObj.txtTerraceBreadth } })
            spaceDetails.push({
                'type': 'Terrace', 'details': {
                    'total': this.editDataObj.txtTerraceTotal, 'Spacing_Facing': this.editDataObj.ddlTerraceSpaceFacing,
                    'Utiliztion_Space': this.editDataObj.ddlTerraceUtilisationSpace, 'Other_Util_Space': this.editDataObj.txtTerraceOtherUtilSpace,
                    'Garden_Needs': this.editDataObj.ddlTerraceGardenNeeds, 'Other_Garden_Needs': this.editDataObj.txtTerraceOtherGardenNeeds,
                    'Look_Preferred': this.editDataObj.ddlTerraceLookPreferred, 'Plants_Preferred': this.editDataObj.ddlTerracePlantsPreferred,
                    'Other_Plants_Preferred': this.editDataObj.txtTerraceOtherPlantPreferred, 'Alergeic_Plants': this.editDataObj.ddlTerraceAllergicPlant,
                    'Other_Allergic_Plants': this.editDataObj.txtTerraceAllergicPlant, 'Color_Palette': this.editDataObj.ddlTerraceColorPalette, 'Planter_Material': this.editDataObj.ddlTerracePlanterMaterial
                }
            })
        }
        if (this.editDataObj.ddlSpaceType.includes("Indoors")) {

            if (this.editDataObj.txtIndoorsTotal <= 0) {
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
                document.getElementById('spnWarningMessage').innerText = "Indoor area should be greater than 0";
                $('#crmWarningAlert').slideDown().slideUp(3000);
                return false
            }
            //spaceDetails.push({ 'type': 'Indoors', 'details': { 'length': this.editDataObj.txtIndoorsLength, 'breadth': this.editDataObj.txtIndoorsBreadth } })
            spaceDetails.push({
                'type': 'Indoors', 'details': {
                    'total': this.editDataObj.txtIndoorsTotal, 'Spacing_Facing': this.editDataObj.ddlIndoorsSpaceFacing,
                    'Utiliztion_Space': this.editDataObj.ddlIndoorsUtilisationSpace, 'Other_Util_Space': this.editDataObj.txtIndoorsOtherUtilSpace,
                    'Garden_Needs': this.editDataObj.ddlIndoorsGardenNeeds, 'Other_Garden_Needs': this.editDataObj.txtIndoorsOtherGardenNeeds,
                    'Look_Preferred': this.editDataObj.ddlIndoorsLookPreferred, 'Plants_Preferred': this.editDataObj.ddlIndoorsPlantsPreferred,
                    'Other_Plants_Preferred': this.editDataObj.txtIndoorsOtherPlantPreferred, 'Alergeic_Plants': this.editDataObj.ddlIndoorsAllergicPlant,
                    'Other_Allergic_Plants': this.editDataObj.txtIndoorsAllergicPlant, 'Color_Palette': this.editDataObj.ddlIndoorsColorPalette, 'Planter_Material': this.editDataObj.ddlIndoorsPlanterMaterial
                }
            })
        }
        if (this.editDataObj.ddlSpaceType.includes("Landscaping (Garden)")) {

            if (this.editDataObj.txtLandscapingTotal <= 0) {
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
                document.getElementById('spnWarningMessage').innerText = "Landscaping area should be greater than 0";
                $('#crmWarningAlert').slideDown().slideUp(3000);
                return false
            }
            //spaceDetails.push({ 'type': 'Landscaping (Garden)', 'details': { 'length': this.editDataObj.txtLandscapingLength, 'breadth': this.editDataObj.txtLandscapingBreadth } })
            spaceDetails.push({
                'type': 'Landscaping (Garden)', 'details': {
                    'total': this.editDataObj.txtLandscapingTotal, 'Spacing_Facing': this.editDataObj.ddlLandscapingSpaceFacing,
                    'Utiliztion_Space': this.editDataObj.ddlLandscapingUtilisationSpace, 'Other_Util_Space': this.editDataObj.txtLandscapingOtherUtilSpace,
                    'Garden_Needs': this.editDataObj.ddlLandscapingGardenNeeds, 'Other_Garden_Needs': this.editDataObj.txtLandscapingOtherGardenNeeds,
                    'Look_Preferred': this.editDataObj.ddlLandscapingLookPreferred, 'Plants_Preferred': this.editDataObj.ddlLandscapingPlantsPreferred,
                    'Other_Plants_Preferred': this.editDataObj.txtLandscapingOtherPlantPreferred, 'Alergeic_Plants': this.editDataObj.ddlLandscapingAllergicPlant,
                    'Other_Allergic_Plants': this.editDataObj.txtLandscapingAllergicPlant, 'Color_Palette': this.editDataObj.ddlLandscapingColorPalette, 'Planter_Material': this.editDataObj.ddlLandscapingPlanterMaterial
                }
            })
        }
        if (this.editDataObj.ddlSpaceType.includes("Full House")) {

            if (this.editDataObj.txtFullHouseTotal <= 0) {
                document.getElementById('spnWarningMessageTitle').innerText = "Lead Message - ";
                document.getElementById('spnWarningMessage').innerText = "FullHouse area should be greater than 0";
                $('#crmWarningAlert').slideDown().slideUp(3000);
                return false
            }
            //spaceDetails.push({ 'type': 'Full House', 'details': { 'length': this.editDataObj.txtFullHouseLength, 'breadth': this.editDataObj.txtFullHouseBreadth } })
            spaceDetails.push({
                'type': 'Full House', 'details': {
                    'total': this.editDataObj.txtFullHouseTotal, 'Spacing_Facing': this.editDataObj.ddlFullHouseSpaceFacing,
                    'Utiliztion_Space': this.editDataObj.ddlFullHouseUtilisationSpace, 'Other_Util_Space': this.editDataObj.txtFullHouseOtherUtilSpace,
                    'Garden_Needs': this.editDataObj.ddlFullHouseGardenNeeds, 'Other_Garden_Needs': this.editDataObj.txtFullHouseOtherGardenNeeds,
                    'Look_Preferred': this.editDataObj.ddlFullHouseLookPreferred, 'Plants_Preferred': this.editDataObj.ddlFullHousePlantsPreferred,
                    'Other_Plants_Preferred': this.editDataObj.txtFullHouseOtherPlantPreferred, 'Alergeic_Plants': this.editDataObj.ddlFullHouseAllergicPlant,
                    'Other_Allergic_Plants': this.editDataObj.txtFullHouseAllergicPlant, 'Color_Palette': this.editDataObj.ddlFullHouseColorPalette, 'Planter_Material': this.editDataObj.ddlBalconyPlanterMaterial
                }
            })
        }
        let verticalGaredenDetails = []
        if (this.editDataObj.ddlVerticalGarden.includes("Indoor")) {
            verticalGaredenDetails.push({ 'type': 'Indoor', 'details': { 'water_available': this.editDataObj.ddlIndoorWaterType, 'total': this.editDataObj.txtVerticalGardenIndoorTotal, 'auto_dripping_sys': this.editDataObj.ddlIndoorAutoDroppingSys, 'support_structure': this.editDataObj.ddlIndoorSupportStructure, 'sunlight': this.editDataObj.ddlIndoorSunlightSpec } })
        }
        if (this.editDataObj.ddlVerticalGarden.includes("Outdoor")) {
            verticalGaredenDetails.push({ 'type': 'Outdoor', 'details': { 'water_available': this.editDataObj.ddlOutdoorWaterType, 'total': this.editDataObj.txtVerticalGardenOutdoorTotal, 'auto_dripping_sys': this.editDataObj.ddlOutdoorAutoDroppingSys, 'support_structure': this.editDataObj.ddlOutdoorSupportStructure, 'sunlight': this.editDataObj.ddlOutdoorSunlightSpec } })
        }
        let shadeSolutionDetails = []
        if (this.editDataObj.ddlShadeSolutions.includes("Pergola")) {
            shadeSolutionDetails.push({ 'type': 'Pergola', 'specifyArea': this.editDataObj.txtShadeSolutions, 'details': { 'full': this.editDataObj.ddlFull, 'partial': this.editDataObj.ddlPartial, 'open': this.editDataObj.ddlOpen } })
        }
        if (this.editDataObj.ddlShadeSolutions.includes("Umbrella")) {
            shadeSolutionDetails.push({ 'type': 'Umbrella', 'specifyArea': this.editDataObj.txtShadeSolutions, 'details': { 'color': this.editDataObj.txtUmbrellaColor } })
        }
        if (this.editDataObj.ddlShadeSolutions.includes("Awning")) {
            shadeSolutionDetails.push({ 'type': 'Awning', 'specifyArea': this.editDataObj.txtShadeSolutions, 'details': { 'length': this.editDataObj.txtAwningShadeLength, 'breadth': this.editDataObj.txtAwningShadeBreadth } })
        }
        if (this.editDataObj.ddlShadeSolutions.includes("Combination of Full/Partial/Open")) {
            shadeSolutionDetails.push({ 'type': 'Combination of Full/Partial/Open', 'specifyArea': this.editDataObj.txtShadeSolutions, 'details': { 'combination': this.editDataObj.txtCombinationShade } })
        }
        if (this.editDataObj.ddlShadeSolutions.includes("Others")) {
            shadeSolutionDetails.push({ 'type': 'Others', 'specifyArea': this.editDataObj.txtShadeSolutions, 'details': { 'other_shades': this.editDataObj.txtOtherShades } })
        }
        let outdoorFurnitureDetails = []
        if (this.editDataObj.ddlOutdoorFurniture.includes("Coffee Set")) {
            outdoorFurnitureDetails.push({ 'type': 'Coffee Set', 'details': { 'set_type': this.editDataObj.ddlCoffeeSet, 'seater': this.editDataObj.txtCoffeeSeater } })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Sofa Set")) {
            outdoorFurnitureDetails.push({ 'type': 'Sofa Set', 'details': { 'set_type': this.editDataObj.ddlSofaSet, 'seater': this.editDataObj.txtSofaSeater } })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Bench")) {
            outdoorFurnitureDetails.push({ 'type': 'Bench', 'details': { 'set_type': this.editDataObj.ddlBenchSet, 'seater': this.editDataObj.txtBenchSize } })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Bar Counters")) {
            outdoorFurnitureDetails.push({ 'type': 'Bar Counters', 'details': { 'set_type': this.editDataObj.ddlBarCounterType, 'seater': this.editDataObj.txtBarSeater } })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Dining Set")) {
            outdoorFurnitureDetails.push({ 'type': 'Dining Set', 'details': { 'set_type': this.editDataObj.ddlDiningSet, 'seater': this.editDataObj.txtDiningSeater } })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Swing")) {
            outdoorFurnitureDetails.push({ 'type': 'Swing', 'details': { 'set_type': this.editDataObj.ddlSwing, 'seater': this.editDataObj.txtSwingSeater } })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Cabana")) {
            outdoorFurnitureDetails.push({ 'type': 'Cabana', 'details': {} })
        }
        if (this.editDataObj.ddlOutdoorFurniture.includes("Lounge Chairs")) {
            outdoorFurnitureDetails.push({ 'type': 'Lounge Chairs', 'details': {} })
        }

        let decorDetails = []
        if (this.editDataObj.ddlDecorOptions.includes("Wall Decor")) {
            decorDetails.push({ 'type': 'Wall Decor', 'specifyArea': this.editDataObj.txtDecorOptions, 'details': { 'set_type': this.editDataObj.ddlWallDecor } })
        }
        if (this.editDataObj.ddlDecorOptions.includes("Wall Painting and Mosaics")) {
            decorDetails.push({ 'type': 'Wall Painting and Mosaics', 'specifyArea': this.editDataObj.txtDecorOptions, 'details': { 'set_type': this.editDataObj.ddlWallPainting } })
        }
        if (this.editDataObj.ddlDecorOptions.includes("Statues")) {
            decorDetails.push({ 'type': 'Statues', 'specifyArea': this.editDataObj.txtDecorOptions, 'details': { 'set_type': this.editDataObj.ddlStatues } })
        }
        if (this.editDataObj.ddlDecorOptions.includes("Water Features")) {
            decorDetails.push({ 'type': 'Water Features', 'specifyArea': this.editDataObj.txtDecorOptions, 'details': { 'set_type': this.editDataObj.ddlWaterFeatures } })
        }
        if (this.editDataObj.ddlDecorOptions.includes("Tea Light Holder / Lanterns")) {
            decorDetails.push({ 'type': 'Tea Light Holder / Lanterns', 'specifyArea': this.editDataObj.txtDecorOptions, 'details': {} })
        }

        if (this.editDataObj.ddlDecorOptions.includes("Pebbles and Boulders")) {
            decorDetails.push({ 'type': 'Pebbles and Boulders', 'specifyArea': this.editDataObj.txtDecorOptions, 'details': {} })
        }
        this.data = {
            ddlPropertType: this.editDataObj.ddlPropertType,
            txtOtherPropertyType: this.editDataObj.txtOtherPropertyType,
            ddlOwnershipType: this.editDataObj.ddlOwnershipType,
            ddlSpaceDetails: spaceDetails,
            ddlVerticalGardenDetails: verticalGaredenDetails,
            ddlSpaceFacing: this.editDataObj.ddlSpaceFacing,
            ddlUtilisationSpace: this.editDataObj.ddlUtilisationSpace,
            txtOtherUtilSpace: this.editDataObj.txtOtherUtilSpace,
            ddlGardenNeeds: this.editDataObj.ddlGardenNeeds,
            txtOtherGardenNeeds: this.editDataObj.txtOtherGardenNeeds,
            ddlLookPreferred: this.editDataObj.dllLookPreferred,
            ddlPlantsPreferred: this.editDataObj.ddlPlantsPreferred,
            txtOtherPlantPreferred: this.editDataObj.txtOtherPlantPreferred,
            ddlAllergicPlant: this.editDataObj.ddlAllergicPlant,
            txtAllergicPlant: this.editDataObj.txtAllergicPlant,
            ddlColorPalette: this.editDataObj.ddlColorPalette,
            ddlPlanterMaterial: this.editDataObj.ddlPlanterMaterial,
            ddlShadeSolutions: shadeSolutionDetails,
            ddlInnerRoofCladding: this.editDataObj.ddlInnerRoofCladding,
            txtInnerRoofCladding: this.editDataObj.txtInnerRoofCladding,
            ddlFlooringSolutions: this.editDataObj.ddlFlooringSolutions,
            txtFlooringSolutions: this.editDataObj.txtFlooringSolutions,
            txtCombinationFlooring: this.editDataObj.txtCombinationFlooring,
            ddlFloorPreparation: this.editDataObj.ddlFloorPreparation,
            txtOtherFloorPrep: this.editDataObj.txtOtherFloorPrep,
            ddlWallSolutions: this.editDataObj.ddlWallSolutions,
            txtWallSolutions: this.editDataObj.txtWallSolutions,
            ddlTrellis: this.editDataObj.ddlTrellis,
            ddlCladding: this.editDataObj.ddlCladding,
            ddlPartitions: this.editDataObj.ddlPartitions,
            txtPartitions: this.editDataObj.txtPartitions,
            ddlPartitionWithPlanters: this.editDataObj.ddlPartitionWithPlanters,
            ddlMetalStructureSupport: this.editDataObj.ddlMetalStructureSupport,
            ddlOutdoorLighting: this.editDataObj.ddlOutdoorLighting,
            ddlOutdoorFurniture: outdoorFurnitureDetails,
            ddlDecorOptions: decorDetails,
            ddlOtherOptions: this.editDataObj.ddlOtherOptions,
            txtFeatureMaterial: this.editDataObj.txtFeatureMaterial,
            txtIssues: this.editDataObj.txtIssues,
            ddlFloorPlanReceived: this.editDataObj.ddlFloorPlanReceived,
            txtClientSuggestions: this.editDataObj.txtClientSuggestions,
            txtElectricalRequirement: this.editDataObj.txtElectricalRequirement,
            txtPlumbingRequirement: this.editDataObj.txtPlumbingRequirement,
            ddlClientBudget: this.editDataObj.ddlClientBudget,
            txtRMChecklist: this.editDataObj.txtRMChecklist,
            user_mobile_no: sessionStorage.getItem("current_hc_user_contact_no")
        }

        console.log(this.data);




        this.setState({ operation: operation, sendData: true })
    }
    HandleHCFormSubmiteResponse = (res) => {
        // console.log("HandleHCFormSubmiteResponse", res)
        document.getElementById('spnSuccessMessageTitle').innerText = "HC Message - ";
        document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
        // document.getElementById('spnSuccessMessage').innerText = "Successfully Downloaded";

        // if (this.state.operation === "download") {
        //     let htmlString = `<div id="content">
        //     <table id="example1" class="display table table-bordered table-striped row- 
        //   border order-column" cellspacing="0" width="auto">
        //       <thead style="background-color: #b6b37e">
        //         <tr>
        //           <th>No</th>
        //           <th>PO Number</th>
        //           <th>Article Code</th>
        //           <th>Description</th>
        //           <th>Qty</th>
        //           <th>Price</th>
        //           <th>Discount</th>
        //         </tr>
        //       </thead>
        //       <tbody>
        //         <tr ng-repeat="x in listData">
        //           <td>{'hai'}</td>
        //           <td>{'hai'}</td>
        //           <td>{'hai'}</td>
        //           <td>{'hai'}</td>
        //           <td>{'hai'}</td>
        //           <td>{'hai'}</td>
        //           <td>{'hai'}</td>
        //         </tr>
        //       </tbody>
        //     </table>
        //   </div>`
        //         ;
        //     const doc = new jsPDF();

        //     doc.text(htmlString, 10, 10);
        //     doc.save("data.pdf");


        // Save the PDF
        // doc.save('sample-document.pdf');         


        // }
        // else {
        //     document.getElementById('spnSuccessMessage').innerText = "Successfully Submitted";
        // }

        $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
            // if (res.data && res.data.hcentryrresponse && res.data.hcentryrresponse.value) {
            //     // AppUtils.HandlePageClick(this.props.componentprops, 'leads/'+this.state.currentId);
            //     // AppUtils.HandlePageClick(this.props.componentprops, 'leads/');

            // }
        }.bind(this));
        this.setState({
            sendData: false,
            showDownload: true
        })

    }

    HandleHCFormEmailRes = (res) => {
        // console.log("HandleHCFormEmailRes", res)
        document.getElementById('spnSuccessMessageTitle').innerText = "HC Message - ";
        document.getElementById('spnSuccessMessage').innerText = "Email Sent";

        $('#crmSuccessAlert').slideDown().slideUp(2000, function () {
            // if (res.data && res.data.hcentryrresponse && res.data.hcentryrresponse.value) {
            //     // AppUtils.HandlePageClick(this.props.componentprops, 'leads/'+this.state.currentId);
            //     // AppUtils.HandlePageClick(this.props.componentprops, 'leads/');

            // }
        }.bind(this));
        this.setState({
            sendEmail: false
        })
    }

    DownloadPdf = (type) => {

        //  alert(type)
        let arr = [
            'Property Type',
            'Ownership Type',
            'Space Details',
            'Vertical Garden Details',
            'Space Facing',
            'Utilisation Space',
            'Other Util Space',
            'Garden Needs',
            'Other Garden Needs',
            'Look Preferred',
            'Plants Preferred',
            'Other Plant Preferred',
            'Allergic Plant',
            'Allergic Plant',
            'Color Palette',
            'Planter Material',
            'Shade Solutions',
            'Inner Roof Cladding',
            'Flooring Solutions',
            'Combination Flooring',
            'Floor Preparation',
            'Other Floor Prep',
            'Wall Solutions',
            'Trellis',
            'Cladding',
            'Partitions',
            'Partition With Planters',
            'Metal Structure Support',
            'Outdoor Lighting',
            'Outdoor Furniture',
            'Decor Options',
            'Other Options',
            'Feature Material',
            'Issues',
            'Floor Plan Received',
            'Client Suggestions',
            'Electrical Requirement',
            'Plumbing Requirement',
            'Client Budget',
            'RM Checklist',
            'user_mobile_no'
        ]


        var doc = new jsPDF();

        var data = this.data;



        var allinOneArrayMain = [];

        var verticalGarden = [];

        var hardScapingDetails = [];

        var outDoorFurnituresArr = [];

        var decorOptionsArr = [];

        var space = {};



        if (data.ddlSpaceDetails.length > 0) {
            for (var i = 0; i < data.ddlSpaceDetails.length; i++) {


                var allinOneArray = [];


                if (data.ddlSpaceDetails[i].type !== '' &&  data.ddlSpaceDetails[i].type !== undefined) {
                    var type = {}
                    type.Type = data.ddlSpaceDetails[i].type
                    allinOneArray.push(type);
                }

                if (data.ddlSpaceDetails[i].details.total !== '' &&  data.ddlSpaceDetails[i].details.total !== undefined ) {
                    var total = {}
                    total.Total = data.ddlSpaceDetails[i].details.total+' '+'Sqft'
                    allinOneArray.push(total)

                }

                if (data.ddlSpaceDetails[i].details.Spacing_Facing.length > 0) {

                    var space_facing = {}
                    space_facing.Space_Facing = data.ddlSpaceDetails[i].details.Spacing_Facing
                    allinOneArray.push(space_facing);
                }

                if (data.ddlSpaceDetails[i].details.Utiliztion_Space.length > 0) {

                    var utilization_space = {}
                    utilization_space.Utilization_Space = data.ddlSpaceDetails[i].details.Utiliztion_Space
                    allinOneArray.push(utilization_space);
                    if (data.ddlSpaceDetails[i].details.Utiliztion_Space.includes('Others')) {
                        var other_utilization_space = {}
                        other_utilization_space.Other_Utilisation_Space = data.ddlSpaceDetails[i].details.Other_Util_Space
                        allinOneArray.push(other_utilization_space);
                    }
                }


                if (data.ddlSpaceDetails[i].details.Garden_Needs.length > 0) {

                    var garden_needs = {}
                    garden_needs.Garden_Needs = data.ddlSpaceDetails[i].details.Garden_Needs
                    allinOneArray.push(garden_needs);
                    if (data.ddlSpaceDetails[i].details.Garden_Needs.includes('Others')) {
                        var other_garden_needs = {}
                        other_garden_needs.Other_Garden_Needs = data.ddlSpaceDetails[i].details.Other_Garden_Needs
                        allinOneArray.push(other_garden_needs);
                    }
                }
                if (data.ddlSpaceDetails[i].details.Look_Preferred.length > 0) {
                    var look_preferred = {}
                    look_preferred.Look_Preferred = data.ddlSpaceDetails[i].details.Look_Preferred
                    allinOneArray.push(look_preferred);
                }
                if (data.ddlSpaceDetails[i].details.Plants_Preferred.length > 0) {

                    var plants_preferred = {}
                    plants_preferred.Plants_Preferred = data.ddlSpaceDetails[i].details.Plants_Preferred
                    allinOneArray.push(plants_preferred);
                    if (data.ddlSpaceDetails[i].details.Plants_Preferred.includes('Others')) {
                        var other_plants_preferred = {}
                        other_plants_preferred.Other_Plants_Preferred = data.ddlSpaceDetails[i].details.Other_Plants_Preferred
                        allinOneArray.push(other_plants_preferred);
                    }
                }

                if (data.ddlSpaceDetails[i].details.Color_Palette.length > 0) {
                    var colour_palette = {}
                    colour_palette.Color_Palette = data.ddlSpaceDetails[i].details.Color_Palette
                    allinOneArray.push(colour_palette);
                }


                if (data.ddlSpaceDetails[i].details.Planter_Material.length > 0) {
                    var planter_material = {}
                    planter_material.Planter_Material = data.ddlSpaceDetails[i].details.Planter_Material
                    allinOneArray.push(planter_material);
                }

                if (data.ddlSpaceDetails[i].details.Alergeic_Plants === 'Yes') {
                    if (data.ddlSpaceDetails[i].details.Other_Allergic_Plants !== '') {
                        var allergic_plants = {}
                        allergic_plants.Other_Allergic_Plants = data.ddlSpaceDetails[i].details.Other_Allergic_Plants
                        allinOneArray.push(allergic_plants);
                    }
                }


                allinOneArrayMain.push(allinOneArray);



            }
        }

        if (data.ddlVerticalGardenDetails.length > 0) {

            for (var i = 0; i < data.ddlVerticalGardenDetails.length; i++) {

                var vertical_garder_details = [];

                if (data.ddlVerticalGardenDetails[i].type !== '' && data.ddlVerticalGardenDetails[i].type !== undefined) {
                    var type = {}
                    type.Type = data.ddlVerticalGardenDetails[i].type
                    vertical_garder_details.push(type);
                }
                if (data.ddlVerticalGardenDetails[i].details.water_available.length > 0) {
                    var water_available = {}
                    water_available.Water_Available = data.ddlVerticalGardenDetails[i].details.water_available
                    vertical_garder_details.push(water_available);
                }
                if (data.ddlVerticalGardenDetails[i].details.total.length>0) {
                    var total = {}
                    total.Total = data.ddlVerticalGardenDetails[i].details.total+' '+'Sqft'
                    vertical_garder_details.push(total);
                }
                if (data.ddlVerticalGardenDetails[i].details.auto_dripping_sys !== '' && data.ddlVerticalGardenDetails[i].details.auto_dripping_sys !== null ) {
                    var auto_dripping_sys = {}
                    auto_dripping_sys.Auto_Dripping_System = data.ddlVerticalGardenDetails[i].details.auto_dripping_sys
                    vertical_garder_details.push(auto_dripping_sys);
                }
                if (data.ddlVerticalGardenDetails[i].details.support_structure !== '' &&  data.ddlVerticalGardenDetails[i].details.support_structure !== null) {
                    var support_structure = {}
                    support_structure.Support_Structure = data.ddlVerticalGardenDetails[i].details.support_structure
                    vertical_garder_details.push(support_structure);
                }
                if (data.ddlVerticalGardenDetails[i].details.sunlight !== '' && data.ddlVerticalGardenDetails[i].details.sunlight !== null) {
                    var sunlight = {}
                    sunlight.Sunlight = data.ddlVerticalGardenDetails[i].details.sunlight
                    vertical_garder_details.push(sunlight);
                }

                verticalGarden.push(vertical_garder_details);
            }
        }

        if (data.ddlShadeSolutions.length > 0) {
            for (var i = 0; i < data.ddlShadeSolutions.length; i++) {
                var hardscaping_details = []

                if (data.ddlShadeSolutions[i].type.length > 0) {
                    var type = {}
                    type.Type = data.ddlShadeSolutions[i].type
                    hardscaping_details.push(type);
                }
                if (data.ddlShadeSolutions[i].specifyArea.length > 0) {

                    var specifyarea = []
                    specifyarea = data.ddlShadeSolutions[i].specifyArea.split('#')
                    hardscaping_details.push(specifyarea);
                }

                if (data.ddlShadeSolutions[i].type === 'Pergola') {

                    if (data.ddlShadeSolutions[i].details.full.length > 0) {
                        var full = {}
                        full.Full = data.ddlShadeSolutions[i].details.full
                        hardscaping_details.push(full)

                    }
                    if (data.ddlShadeSolutions[i].details.partial.length > 0) {
                        var partial = {}
                        partial.Partial = data.ddlShadeSolutions[i].details.partial
                        hardscaping_details.push(partial)
                    }
                    if (data.ddlShadeSolutions[i].details.open.length > 0) {
                        var open = {}
                        open.Open = data.ddlShadeSolutions[i].details.open
                        hardscaping_details.push(open)
                    }
                }

                if (data.ddlShadeSolutions[i].type === 'Awning') {

                    if (data.ddlShadeSolutions[i].details.length !=='' && data.ddlShadeSolutions[i].details.length !== undefined) {
                        var length = {}
                        length.Length = data.ddlShadeSolutions[i].details.length
                        hardscaping_details.push(length)

                    }
                    if (data.ddlShadeSolutions[i].details.breadth !=='' && data.ddlShadeSolutions[i].details.breadth !== undefined) {
                        var breadth = {}
                        breadth.Breadth = data.ddlShadeSolutions[i].details.breadth
                        hardscaping_details.push(breadth)
                    }

                }

                if (data.ddlShadeSolutions[i].type === 'Umbrella') {

                    if (data.ddlShadeSolutions[i].details.color !=='' && data.ddlShadeSolutions[i].details.color !== undefined) {
                        var color = {}
                        color.Color = data.ddlShadeSolutions[i].details.color
                        hardscaping_details.push(color)

                    }

                }
                if (data.ddlShadeSolutions[i].type === 'Combination of Full/Partial/Open') {

                    if (data.ddlShadeSolutions[i].details.combination !== '' && data.ddlShadeSolutions[i].details.combination !== undefined ) {
                        var combination = {}
                        combination.Combination = data.ddlShadeSolutions[i].details.combination
                        hardscaping_details.push(combination)

                    }


                }
                if (data.ddlShadeSolutions[i].type === 'Others') {

                    if (data.ddlShadeSolutions[i].details.other_shades !== '' && data.ddlShadeSolutions[i].details.other_shades !== undefined) {
                        var other_shades = {}
                        other_shades.Other_Shades = data.ddlShadeSolutions[i].details.other_shades
                        hardscaping_details.push(other_shades)

                    }


                }

                hardScapingDetails.push(hardscaping_details);




            }

        }


        if (data.ddlOutdoorFurniture.length > 0) {
            for (var i = 0; i < data.ddlOutdoorFurniture.length; i++) {
                var outDoorFurnitures = [];

                if (data.ddlOutdoorFurniture[i].type.length > 0) {
                    var type = {}
                    type.Type = data.ddlOutdoorFurniture[i].type
                    outDoorFurnitures.push(type);
                }
                if (data.ddlOutdoorFurniture[i].details !== '') {
                    if (data.ddlOutdoorFurniture[i].details.set_type !== '' && data.ddlOutdoorFurniture[i].details.set_type !== undefined) {
                        var set_type = {}
                        set_type.Set_Type = data.ddlOutdoorFurniture[i].details.set_type
                        outDoorFurnitures.push(set_type);
                    }
                    if (data.ddlOutdoorFurniture[i].details.seater !== '' && data.ddlOutdoorFurniture[i].details.seater !== undefined) {
                        var seater = {}
                        seater.Seater = data.ddlOutdoorFurniture[i].details.seater
                        outDoorFurnitures.push(seater)
                    }
                }

                outDoorFurnituresArr.push(outDoorFurnitures);


            }


        }

        if (data.ddlDecorOptions.length > 0) {
            for (var i = 0; i < data.ddlDecorOptions.length; i++) {
                var decorOptions = [];

                if (data.ddlDecorOptions[i].type.length > 0) {
                    var type = {}
                    type.Type = data.ddlDecorOptions[i].type
                    decorOptions.push(type);
                }

                if (data.ddlDecorOptions[i].specifyArea.length > 0) {
                    var specifyarea = []
                    specifyarea = data.ddlDecorOptions[i].specifyArea.split('#')
                    decorOptions.push(specifyarea);

                    // var specify_Area = {}
                    // specifyarea= data.ddlDecorOptions[i].specifyArea
                    // decorOptions.push(specifyarea);
                }

                if (data.ddlDecorOptions[i].details !== '') {
                    if (data.ddlDecorOptions[i].details.set_type !== '' && data.ddlDecorOptions[i].details.set_type !== undefined) {
                        var set_type = {}
                        set_type.Set_Type = data.ddlDecorOptions[i].details.set_type
                        decorOptions.push(set_type);
                    }
                }

                decorOptionsArr.push(decorOptions);
            }
        }



     //     console.log(hardScapingDetails)
     //   console.log(outDoorFurnituresArr)
        // console.log(spaceDetailsArray);
        // console.log(spaceKeyDetailsArray);

     //   console.log(space)




       
        // doc.autoTable({
        //     body: [
        //         [{ content: 'Ausaf', colSpan: 2, styles: { halign: 'left' } }, { content: 'Ahmad', colSpan: 2, rowSpan: 2, styles: { halign: 'left' } }]
        //     ]
        // })






        if (data.ddlPropertType !== '') {
            doc.autoTable({

                body: [
                    [{ content: arr[0], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }, { content: data.ddlPropertType, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })

        }

        if (data.ddlOwnershipType !== '') {

            doc.autoTable({
                body: [
                    [{ content: arr[1], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: data.ddlOwnershipType, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })
        }

        if (data.ddlSpaceDetails.length > 0) {

            for (var i = 0; i < data.ddlSpaceDetails.length; i++) {

                doc.autoTable({
                  
                    body: [
                        [{ content: arr[2], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                        { content: data.ddlSpaceDetails[i].type, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                    ],

                })

                for (var k = i; k <= i; k++) {

                    for (var j = 0; j < allinOneArrayMain[k].length; j++) {

                        if(allinOneArrayMain[k][j].length>1){
                            alert('kkk')
                            // doc.autoTable({

                            //     body: [
                            //         [{ content: JSON.stringify(allinOneArrayMain[k][j]).slice(1, JSON.stringify(allinOneArrayMain[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'), colSpan: 2, styles: { halign: 'left' } },
    
                            //         { content: JSON.stringify(allinOneArrayMain[k][j]).slice(JSON.stringify(allinOneArrayMain[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', ''), colSpan: 2, styles: { halign: 'left' } }]
                            //     ],                          
    
                            // })
                        }

                        doc.autoTable({

                            body: [
                                [{ content: JSON.stringify(allinOneArrayMain[k][j]).slice(1, JSON.stringify(allinOneArrayMain[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'), colSpan: 2, styles: { halign: 'left' } },

                                { content: JSON.stringify(allinOneArrayMain[k][j]).slice(JSON.stringify(allinOneArrayMain[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', ''), colSpan: 2, styles: { halign: 'left' } }]
                            ],                          

                        })

                    }

                }

            }

        }

        if (data.ddlVerticalGardenDetails.length > 0) {
            for (var i = 0; i < data.ddlVerticalGardenDetails.length; i++) {

                doc.autoTable({
                   
                    // columns: [
                    //     { header: arr[3], dataKey: 'europe' },
                    //     { header: data.ddlVerticalGardenDetails[i].type, dataKey: 'asia' }
                    // ]

                    body: [
                        [{ content: arr[3], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                        { content: data.ddlVerticalGardenDetails[i].type, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                    ],


                })

                for (var k = i; k <= i; k++) {

                    for (var j = 0; j < verticalGarden[k].length; j++) {

                        doc.autoTable({

                            // columnStyles: { asia: { halign: 'center' } },
                            // style: { cellWidth: 'wrap' },
                            // body: [

                            //     {
                            //         europe: JSON.stringify(verticalGarden[k][j]).slice(1, JSON.stringify(verticalGarden[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'),

                            //         asia: JSON.stringify(verticalGarden[k][j]).slice(JSON.stringify(verticalGarden[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', '')
                            //     },

                            // ],
                            
                            body: [
                                [{ content: JSON.stringify(verticalGarden[k][j]).slice(1, JSON.stringify(verticalGarden[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'), colSpan: 2, styles: { halign: 'left' } },

                                { content: JSON.stringify(verticalGarden[k][j]).slice(JSON.stringify(verticalGarden[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', ''), colSpan: 2, styles: { halign: 'left' } }]
                            ], 

                        })

                    }

                }


            }
        }
        if (data.ddlSpaceFacing.length > 0) {

            doc.autoTable({
                // columns: [
                //     { header: arr[4] },
                //     { header: JSON.stringify(data.ddlSpaceFacing).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[4], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlSpaceFacing).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })

        }
        if (data.ddlUtilisationSpace.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[5] },
                //     { header: JSON.stringify(data.ddlUtilisationSpace).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[5], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlUtilisationSpace).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })
            if (data.ddlUtilisationSpace.includes('Others')) {
                if(data.txtOtherUtilSpace !== '' && data.txtOtherUtilSpace !== undefined){
                doc.autoTable({
                   
                    body: [
                        [{ content: arr[6], colSpan: 2, styles: { halign: 'left' } },

                        { content: data.txtOtherUtilSpace, colSpan: 2, styles: { halign: 'left' } }]
                    ], 
                })
            }
            }
        }
        if (data.ddlGardenNeeds.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[7] },
                //     { header: JSON.stringify(data.ddlGardenNeeds).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[7], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlGardenNeeds).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
            if (data.ddlGardenNeeds.includes('Others')) {
                if(data.txtOtherGardenNeeds !== '' && data.txtOtherGardenNeeds !== undefined){
                doc.autoTable({
                    // columnStyles: { europe: { halign: 'center' } }, // European countries centered
                    // body: [
                    //     { europe: arr[8], asia: data.txtOtherGardenNeeds },

                    // ],
                    body: [
                        [{ content: arr[8], colSpan: 2, styles: { halign: 'left' } },

                        { content: data.txtOtherGardenNeeds, colSpan: 2, styles: { halign: 'left' } }]
                    ], 
                })
            }
            }

        }

        if (data.ddlLookPreferred.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[9] },
                //     { header: JSON.stringify(data.ddlLookPreferred).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[9], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlLookPreferred).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })


        }

        if (data.ddlPlantsPreferred.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[10] },
                //     { header: JSON.stringify(data.ddlPlantsPreferred).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[10], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlPlantsPreferred).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })
            if (data.ddlPlantsPreferred.includes('Others')) {
                if(data.txtOtherPlantPreferred !=='' && data.txtOtherPlantPreferred !== undefined){
                doc.autoTable({
                    // columnStyles: { europe: { halign: 'center' } }, // European countries centered
                    // body: [
                    //     { europe: arr[11], asia: data.txtOtherPlantPreferred },

                    // ],
                    body: [
                        [{ content: arr[11], colSpan: 2, styles: { halign: 'left' } },

                        { content: data.txtOtherPlantPreferred, colSpan: 2, styles: { halign: 'left' } }]
                    ], 
                })
            }
        }

        }

        if (data.ddlAllergicPlant === 'Yes') {
            doc.autoTable({
                // columns: [
                //     { header: arr[12] },
                //     { header: JSON.stringify(data.ddlAllergicPlant).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],

                body: [
                    [{ content: arr[12], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlAllergicPlant).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

                // columnStyles: { europe: { halign: 'center' } }, // European countries centered
                // body: [
                //     { europe: arr[13], asia: data.txtAllergicPlant },

                // ]
                // body: [
                //     [{ content: arr[13], colSpan: 2, styles: { halign: 'left' } },

                //     { content: data.txtAllergicPlant, colSpan: 2, styles: { halign: 'left' } }]
                // ], 

            })

        }
        if(data.txtAllergicPlant !=='' && data.txtAllergicPlant !== undefined){
            doc.autoTable({
                body: [
                    [{ content: 'Allergic Plant', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtAllergicPlant, colSpan: 2, styles: { halign: 'left' } }]
                ], 

            })
        }

        if (data.ddlColorPalette.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[14] },
                //     { header: JSON.stringify(data.ddlColorPalette).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[14], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlColorPalette).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })


        }
        if (data.ddlPlanterMaterial.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[15] },
                //     { header: JSON.stringify(data.ddlPlanterMaterial).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[15], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlPlanterMaterial).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })


        }

        if (data.ddlShadeSolutions.length > 0) {
            for (var i = 0; i < data.ddlShadeSolutions.length; i++) {

                doc.autoTable({
                  

                    body: [
                        [{ content: arr[16], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                        { content: data.ddlShadeSolutions[i].type, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                    ],

                })

                for (var k = i; k <= i; k++) {

                    for (var j = 0; j < hardScapingDetails[k].length; j++) {

                        if(Array.isArray(hardScapingDetails[k][j])){
                          //  console.log(hardScapingDetails[k][j])

                            for(var m = 0; m<hardScapingDetails[k][j].length; m++){
                                if(m == 0){
                                doc.autoTable({
                                    body: [
                                        [{ content: 'Specify/Area/Space', colSpan: 2, styles: { halign: 'left' } },
        
                                        { content: hardScapingDetails[k][j][m], colSpan: 2, styles: { halign: 'left' } }]
                                    ], 
                                })
                            }

                            else{

                                doc.autoTable({
                                    body: [
                                        [{ content: '.', colSpan: 2, styles: { halign: 'left' } },
        
                                        { content: hardScapingDetails[k][j][m], colSpan: 2, styles: { halign: 'left' } }]
                                    ], 
                                })
                            }
                            }
                        }

                        else{

                        

                        doc.autoTable({
                        

                            body: [
                                [{ content: JSON.stringify(hardScapingDetails[k][j]).slice(1, JSON.stringify(hardScapingDetails[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-').replace(/3/g,'/'), colSpan: 2, styles: { halign: 'left' } },

                                { content: JSON.stringify(hardScapingDetails[k][j]).slice(JSON.stringify(hardScapingDetails[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', ''), colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                          

                        })
                    }

                    }

                }


            }
        }

        if (data.ddlInnerRoofCladding !== '' && data.ddlInnerRoofCladding !== null) {
            doc.autoTable({
               
                body: [
                    [{ content: arr[17], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlInnerRoofCladding).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })
            if(data.ddlInnerRoofCladding === 'Yes' && data.txtInnerRoofCladding !== ''){
            doc.autoTable({
                // columns: [
                //     { header: arr[18] },
                //     { header: JSON.stringify(data.txtInnerRoofCladding).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[17], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.txtInnerRoofCladding).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })

        }
        }

        if (data.ddlFlooringSolutions.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[19], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlFlooringSolutions).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content: arr[18], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlFlooringSolutions).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            
            })
            if(data.txtFlooringSolutions !== ''){

                let txtFlooringSolutionsArr = data.txtFlooringSolutions.split('#')

                for(var i = 0; i<txtFlooringSolutionsArr.length; i++){
                    if(i == 0){
                        doc.autoTable({
                            body: [
                                [{ content: 'Specify/Area/Space', colSpan: 2, styles: { halign: 'left' } },
            
                                { content: txtFlooringSolutionsArr[i], colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                        })
                    }
                    else{
                        doc.autoTable({
                            body: [
                                [{ content: ' ', colSpan: 2, styles: { halign: 'left' } },
            
                                { content: txtFlooringSolutionsArr[i], colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                        })
                    }
                }
        
        }
        }
       
        if (data.ddlFloorPreparation.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[20] },
                //     { header: JSON.stringify(data.ddlFloorPreparation).replace('[', '').replace(']', '').replace(/"/g, '') },
                // ],
                body: [
                    [{ content: arr[19], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlFloorPreparation).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

              
            })
            if (data.ddlFloorPreparation.includes('Others')) {
                doc.autoTable({
                    // columnStyles: { europe: { halign: 'center' } }, // European countries centered
                    // body: [
                    //     { europe: arr[21], asia: data.txtOtherFloorPrep },

                    // ],
                    body: [
                        [{ content: arr[20], colSpan: 2, styles: { halign: 'left' } },
    
                        { content: data.txtOtherFloorPrep, colSpan: 2, styles: { halign: 'left' } }]
                    ], 
                })
            }
        }
        if (data.ddlWallSolutions.length > 0) {
            doc.autoTable({
               

                body: [
                    [{ content: arr[22], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlWallSolutions).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
                
            })
            if(data.txtWallSolutions !== ''){

                let txtWallSolutionsArr = data.txtWallSolutions.split('#')

                for(var i = 0; i<txtWallSolutionsArr.length; i++){
                    if(i == 0){
                        doc.autoTable({
                            body: [
                                [{ content: 'Specify/Area/Space', colSpan: 2, styles: { halign: 'left' } },
            
                                { content: txtWallSolutionsArr[i], colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                        })
                    }
                    else{
                        doc.autoTable({
                            body: [
                                [{ content: ' ', colSpan: 2, styles: { halign: 'left' } },
            
                                { content: txtWallSolutionsArr[i], colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                        })
                    }
                }
        
        }
           
        }
        if (data.ddlTrellis.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[24], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlTrellis).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],
                body: [
                    [{ content: arr[23], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlTrellis).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
            })
            
        }

        if (data.ddlCladding.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[25], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlCladding).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],
                body: [
                    [{ content: arr[24], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlCladding).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],
              

            })
        }
        if (data.ddlPartitions.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[26], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlPartitions).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],
                body: [
                    [{ content: arr[25], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlPartitions).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
            if(data.txtPartitions !== ''){

                let txtPartitionsArr = data.txtPartitions.split('#')

                for(var i = 0; i<txtPartitionsArr.length; i++){
                    if(i == 0){
                        doc.autoTable({
                            body: [
                                [{ content: 'Specify/Area/Space', colSpan: 2, styles: { halign: 'left' } },
            
                                { content: txtPartitionsArr[i], colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                        })
                    }
                    else{
                        doc.autoTable({
                            body: [
                                [{ content: ' ', colSpan: 2, styles: { halign: 'left' } },
            
                                { content: txtPartitionsArr[i], colSpan: 2, styles: { halign: 'left' } }]
                            ], 
                        })
                    }
                }
        
        }

          
        }
        if (data.ddlPartitionWithPlanters !== '' && data.ddlPartitionWithPlanters !== undefined) {
            //   alert(data.ddlPartitionWithPlanters)
            doc.autoTable({
                // columns: [
                //     { header: arr[27], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlPartitionWithPlanters).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],
                body: [
                    [{ content: arr[26], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlPartitionWithPlanters).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],


            })
        }
        if (data.ddlMetalStructureSupport !== '' && data.ddlMetalStructureSupport !== undefined) {
            doc.autoTable({
                // columns: [
                //     { header: arr[28], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlMetalStructureSupport).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],
                body: [
                    [{ content: arr[27], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlMetalStructureSupport).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],


            })
        }


        if (data.ddlOutdoorLighting.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[29], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlOutdoorLighting).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],
                body: [
                    [{ content: arr[28], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlOutdoorLighting).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],


            })
        }


        if (data.ddlOutdoorFurniture.length > 0) {
            for (var i = 0; i < data.ddlOutdoorFurniture.length; i++) {

                doc.autoTable({
                    // array:['Type','Balcony-Spacing'],
                    // columns: [
                    //     { header: arr[30], dataKey: 'europe' },
                    //     { header: data.ddlOutdoorFurniture[i].type, dataKey: 'asia' }
                    // ]
                    body: [
                        [{ content: arr[29], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                        { content: data.ddlOutdoorFurniture[i].type, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                    ],

                })

                for (var k = i; k <= i; k++) {

                    for (var j = 0; j < outDoorFurnituresArr[k].length; j++) {

                        doc.autoTable({
                            // array:['Type','Balcony-Spacing'],
                            // columnStyles: { asia: { halign: 'left' } },
                            // margin: { asia: { left: 0 } },

                            // body: [

                            //     {
                            //         europe: JSON.stringify(outDoorFurnituresArr[k][j]).slice(1, JSON.stringify(outDoorFurnituresArr[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'),

                            //         asia: JSON.stringify(outDoorFurnituresArr[k][j]).slice(JSON.stringify(outDoorFurnituresArr[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', '')
                            //     },

                            // ],
                            body: [
                                [{ content: JSON.stringify(outDoorFurnituresArr[k][j]).slice(1, JSON.stringify(outDoorFurnituresArr[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'), colSpan: 2, styles: { halign: 'left' } },
            
                                { content: JSON.stringify(outDoorFurnituresArr[k][j]).slice(JSON.stringify(outDoorFurnituresArr[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', ''), colSpan: 2, styles: { halign: 'left' } }]
                            ], 

                        })

                    }

                }


            }
        }

        if (data.ddlDecorOptions.length > 0) {
            for (var i = 0; i < data.ddlDecorOptions.length; i++) {

                doc.autoTable({
                    // array:['Type','Balcony-Spacing'],
                    // columns: [
                    //     { header: arr[31], dataKey: 'europe' },
                    //     { header: data.ddlDecorOptions[i].type, dataKey: 'asia' }
                    // ]
                    body: [
                        [{ content: arr[30], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                        { content: data.ddlDecorOptions[i].type, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                    ],

                })

                for (var k = i; k <= i; k++) {

                    for (var j = 0; j < decorOptionsArr[k].length; j++) {

                        if(Array.isArray(decorOptionsArr[k][j])){
                            //  console.log(hardScapingDetails[k][j])
  
                              for(var m = 0; m<decorOptionsArr[k][j].length; m++){
                                  if(m == 0){
                                  doc.autoTable({
                                      body: [
                                          [{ content: 'Specify/Area/Space', colSpan: 2, styles: { halign: 'left' } },
          
                                          { content: decorOptionsArr[k][j][m], colSpan: 2, styles: { halign: 'left' } }]
                                      ], 
                                  })
                              }
  
                              else{
  
                                  doc.autoTable({
                                      body: [
                                          [{ content: '.', colSpan: 2, styles: { halign: 'left' } },
          
                                          { content: decorOptionsArr[k][j][m], colSpan: 2, styles: { halign: 'left' } }]
                                      ], 
                                  })
                              }
                              }
                          }
                       

                          
                        else  {

                            doc.autoTable({
                               
                                body: [
                                    [{ content:JSON.stringify(decorOptionsArr[k][j]).slice(1, JSON.stringify(decorOptionsArr[k][j]).indexOf(':')).replace(/"/g, '').replace(/_/g, '-'), colSpan: 2, styles: { halign: 'left' } },
                
                                    { content: JSON.stringify(decorOptionsArr[k][j]).slice(JSON.stringify(decorOptionsArr[k][j]).indexOf(':') + 1).replace(/"/g, '').replace(/_/g, '-').replace(/}/g, '').replace('[', '').replace(']', ''), colSpan: 2, styles: { halign: 'left' } }]
                                ], 

                            })
                        }

                    }

                }


            }
        }


        if (data.ddlOtherOptions.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: arr[32], dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlOtherOptions).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content: arr[31], colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlOtherOptions).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],


            })
        }
        if (data.txtElectricalRequirement !== '' && data.txtElectricalRequirement !== undefined) {
            doc.autoTable({

                // body: [
                //     { europe: 'Electrical Requirement', asia: data.txtElectricalRequirement },

                // ],
                body: [
                    [{ content: 'Electrical Requirement', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtElectricalRequirement, colSpan: 2, styles: { halign: 'left' } }]
                ], 
            })
        }

        if (data.txtPlumbingRequirement !== '' && data.txtPlumbingRequirement !== undefined) {
            doc.autoTable({

                // body: [
                //     { europe: 'Plumbing Requirement', asia: data.txtPlumbingRequirement },

                // ],
                body: [
                    [{ content: 'Plumbing Requirement', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtPlumbingRequirement, colSpan: 2, styles: { halign: 'left' } }]
                ], 
            })
        }

        if (data.txtFeatureMaterial !== '' && data.txtFeatureMaterial !== undefined) {
            doc.autoTable({

                // body: [
                //     { europe: 'Feature Material', asia: data.txtFeatureMaterial },

                // ],
                body: [
                    [{ content: 'Feature Material', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtFeatureMaterial, colSpan: 2, styles: { halign: 'left' } }]
                ], 
            })
        }
        if (data.txtIssues !== '' && data.txtIssues !== undefined) {
            doc.autoTable({

                // body: [
                //     { europe: 'Client Issues', asia: data.txtIssues },

                // ],
                body: [
                    [{ content: 'Client Issues', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtIssues, colSpan: 2, styles: { halign: 'left' } }]
                ], 
            })
        }

        if (data.ddlFloorPlanReceived.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: 'FloorPlan Recieved', dataKey: 'europe' },
                //     { header: data.ddlFloorPlanReceived },
                // ],
                body: [
                    [{ content:'FloorPlan Recieved' , colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: data.ddlFloorPlanReceived, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],


            })
        }

        if (data.txtClientSuggestions !== '' && data.txtClientSuggestions !== undefined) {
            doc.autoTable({

                // body: [
                //     { europe: 'Client Suggestions', asia: data.txtClientSuggestions },

                // ],
                body: [
                    [{ content: 'Client Suggestions', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtClientSuggestions, colSpan: 2, styles: { halign: 'left' } }]
                ], 
            })
        }

        if (data.ddlClientBudget.length > 0) {
            doc.autoTable({
                // columns: [
                //     { header: 'Client Budget', dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlClientBudget).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content:'Client Budget' , colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(data.ddlClientBudget).replace('[', '').replace(']', '').replace(/"/g, ''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
        }
        let client_name = sessionStorage.getItem('current_hc_user_name')
        let city_name = sessionStorage.getItem('current_hc_user_city')
        let locality_name = sessionStorage.getItem('current_hc_user_locality')
        let client_address = sessionStorage.getItem('current_hc_user_address')
      
        if(client_name !== '' && client_name !== undefined){
            doc.autoTable({
                // columns: [
                //     { header: 'Client Budget', dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlClientBudget).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content:'Client Name' , colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: client_name, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
        }
        if(city_name !== '' && city_name !== undefined){
            doc.autoTable({
                // columns: [
                //     { header: 'Client Budget', dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlClientBudget).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content:'Client City' , colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: city_name, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
        }
        if(locality_name !== '' && locality_name !== undefined && locality_name !== null){
            doc.autoTable({
                // columns: [
                //     { header: 'Client Budget', dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlClientBudget).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content:'Client Location' , colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: JSON.stringify(locality_name).replace(/"/g,''), colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
        }
        if(client_address !== '' && client_address !== undefined){
            doc.autoTable({
                // columns: [
                //     { header: 'Client Budget', dataKey: 'europe' },
                //     { header: JSON.stringify(data.ddlClientBudget).replace('[', '').replace(']', '').replace(/"/g, ''), dataKey: 'asia' },
                // ],

                body: [
                    [{ content:'Client Address' , colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } },
                    { content: client_address, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: 'white', fillColor: '#4682b4' } }]
                ],

            })
        }

      
        if (data.txtRMChecklist !== '' && data.txtRMChecklist !== undefined) {
            doc.autoTable({

                // body: [
                //     { europe: 'RM Checklist', asia: data.txtRMChecklist },

                // ],
                body: [
                    [{ content: 'RM Checklist', colSpan: 2, styles: { halign: 'left' } },

                    { content: data.txtRMChecklist, colSpan: 2, styles: { halign: 'left' } }]
                ], 
            })
        }

        // doc.autoTable({
        //   //  html: '#mytable',
        //     bodyStyles: {minCellHeight: 15},
        //     didDrawCell: function(data) {
        //       if (data.column.index === 5 && data.cell.section === 'body') {
        //          var td = data.cell.raw;
        //          var img = td.getElementsByTagName('img')[0];
        //          var dim = data.cell.height - data.cell.padding('vertical');
        //          var textPos = data.cell.textPos;
        //          doc.addImage(Album, textPos.x,  textPos.y, dim, dim);
        //       }
        //     }
        //   });
        // doc.addPage();
        // var img = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.istockphoto.com%2Fphotos%2Fbuilding&psig=AOvVaw0J7xokWjJBinO3IWlzdON0&ust=1644057642414000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCLCjhZDu5fUCFQAAAAAdAAAAABAD';

        // //  doc.extractImageFromDataUrl('https://drive.google.com/file/d/1fU9kZWJWIBiYDuSGBxlWh7fhOneJnWwp/view?usp=sharing')
        // doc.addImage(img, 'JPEG', 10, 20, 80, 60);
        // doc.addImage(Img, 'PNG', 10, 90, 80, 60);









        doc.save('table.pdf')




        // var col = ["Particulars", "Description"];
        // var rows = [];
        // let arr = [
        //     'Property Type',
        //     'OtherPropertyType',
        //     'OwnershipType',
        //     'SpaceDetails',
        //     'VerticalGardenDetails',
        //     'SpaceFacing',
        //     'UtilisationSpace',
        //     'OtherUtilSpace',
        //     'GardenNeeds',
        //     'OtherGardenNeeds',
        //     'LookPreferred',
        //     'PlantsPreferred',
        //     'OtherPlantPreferred',
        //     'AllergicPlant', ,
        //     'AllergicPlant',
        //     'ColorPalette',
        //     'PlanterMaterial',
        //     'ShadeSolutions',
        //     'InnerRoofCladding',
        //     'FlooringSolutions',
        //     'CombinationFlooring',
        //     'FloorPreparation',
        //     'OtherFloorPrep',
        //     'WallSolutions',
        //     'Trellis',
        //     'Cladding',
        //     'Partitions',
        //     'PartitionWithPlanters',
        //     'MetalStructureSupport',
        //     'OutdoorLighting',
        //     'OutdoorFurniture',
        //     'DecorOptions',
        //     'OtherOptions',
        //     'FeatureMaterial',
        //     'Issues',
        //     'FloorPlanReceived',
        //     'ClientSuggestions',
        //     'ElectricalRequirement',
        //     'PlumbingRequirement',
        //     'ClientBudget',
        //     'RMChecklist',
        //     'user_mobile_no'
        // ]
        // // alert(type);

        // // alert('download');
        // // alert(this.data);
        // //  this.data.for((item, i) => {
        // //         var temp = [arr[i], item[i]];
        // //         rows.push(temp);
        // //         doc.autoTable(col, rows);
        // //         doc.save(`${this.props.componentprops.match.params.id}`);
        // //     })
        // var i = 0;
        // for (var key in this.data) {
        //     let dat = this.data[key];
        //     var temp;
        //     if (typeof (dat) === "object") {
        //         var str = '';

        //         for (let j = 0; j < dat.length; j++) {
        //             if (typeof (dat[j]) !== "object") {
        //                 if (type === 'email')
        //                     str += '<p>' + dat + '</p>'
        //                 else
        //                     str += dat + '\n'
        //                 j = dat.length;
        //             }
        //             else {
        //                 for (var keys in dat[j]) {
        //                     //  alert(JSON.stringify(dat[j]));
        //                     if (typeof (dat[j][keys]) !== "object") {

        //                         if (j > 0) {
        //                             if (type === 'email')
        //                                 str += '<p>---------------------</p>'
        //                             else
        //                                 str += '---------------------' + '\n'
        //                         }
        //                         // alert(dat[j][keys]);
        //                         if (type === 'email')
        //                             str += '<p>' + keys + '-' + JSON.stringify(dat[j][keys]) + '</p>'
        //                         else
        //                             str += keys + '-' + JSON.stringify(dat[j][keys]) + '\n'
        //                     }
        //                     // temp = [arr[i],dat[key]]
        //                     //  alert(dat + '' + typeof(dat[j][keys]));
        //                     else {
        //                         for (var k in dat[j][keys]) {
        //                             // if (dat[j][keys][k] !== '') {
        //                             if (type === 'email')
        //                                 str += '<p>' + k + '-' + JSON.stringify(dat[j][keys][k]) + '</p>'
        //                             else
        //                                 str += k + '-' + JSON.stringify(dat[j][keys][k]) + '\n'
        //                             // }
        //                         }
        //                     }
        //                     // alert(keys + ' ' + JSON.stringify(dat[0][keys]));
        //                     //   //  i++;
        //                 }
        //             }
        //         }
        //         // let arr_data = JSON.stringify(dat);
        //         // if (dat.length > 0)
        //         //     alert(arr_data);
        //         // if (str !== '' && str !== '') {
        //         temp = [arr[i], str]
        //         rows.push(temp);
        //         // }
        //     }
        //     // let dat = Object.keys(data[key])
        //     //alert(key);
        //     //alert(arr[i]);
        //     else {
        //         if (this.data[key] !== '' && this.data[key] !== null) {
        //             temp = [arr[i], this.data[key]];
        //             rows.push(temp);
        //         }
        //     }
        //     i++;
        // }
        // if (type !== 'email') {
        //     doc.autoTable(col, rows);
        //     doc.save(`${this.props.componentprops.match.params.id}`);
        // }
        // var datu = rows;
        // // console.log('Aus' + typeof (datu));

        // if (type === 'email') {
        //     var strn = '';
        //     for (var m in datu) {
        //         var ob = JSON.stringify(datu[m]).replace(',', ':').replace('[', '').replace(']', '').replace(/"/g, '');
        //         strn += '<p>' + m + '-' + (ob) + '</p>';
        //     }
        //     // console.log('Ausi' + strn);

        //     this.email_data = {
        //         mail_body: strn,
        //         user_name: sessionStorage.getItem("current_hc_user_name"),
        //         // user_email: 'prabhashr@pinclick.in',
        //         user_email: 'hello@neoroots.design',
        //         // user_email:sessionStorage.getItem("current_hc_user_email"),
        //         owner_email: sessionStorage.getItem("current_user_email"),
        //     }
        //     this.setState({
        //         sendEmail: true
        //     })
        // }
    }

    render() {
        // console.log("state", this.state.ddlVerticalGarden)
        return (
            <Container fluid style={{ position: 'relative' }}>

                <Row>
                    <HCForm mystate={this.state} {...this.props} HandleUserInputs={this.HandleUserInputs}
                        editDataObj={this.editDataObj} verticalList={this.verticalList} shadeSolutions={this.shadeSolutions}
                        fullOptions={this.fullOptions} partialOptions={this.partialOptions} openOptions={this.openOptions}
                        flooringSolutions={this.flooringSolutions} floorPrep={this.floorPrep} wallSolutions={this.wallSolutions}
                        trellis={this.trellis} cladding={this.cladding} partitions={this.partitions} outdoorlighting={this.outdoorlighting}
                        outdoorFurnture={this.outdoorFurnture} decorOptions={this.decorOptions} otherOptions={this.otherOptions} clientBudget={this.clientBudget}
                        spaceList={this.spaceList} spaceFaceList={this.spaceFaceList} waterAvailable={this.waterAvailable} utilisationSpace={this.utilisationSpace}
                        gardenNeeds={this.gardenNeeds} lookPreferred={this.lookPreferred} plantsPreferred={this.plantsPreferred} colorPalette={this.colorPalette}
                        planterMaterial={this.planterMaterial} statueList={this.statueList} SaveHCForm={this.SaveHCForm} DownloadPdf={this.DownloadPdf} SendEmail={this.SendEmail}
                    />
                    <div id="divToPrint" style={{ display: 'none', width: 800 }}>

                        <table border="0">
                            <tr>
                                <td>
                                    <table width="800px" class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td width="200px">First Name:</td>
                                                <td width="200px">ccc</td>
                                                <td width="200px">Last Name:</td>
                                                <td width="200px">hggg</td>
                                            </tr>
                                            <tr>
                                                <td width="200px">Email</td>
                                                <td width="200px">ccc</td>
                                                <td width="200px">Contact No</td>
                                                <td width="200px">hggg</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </table>


                    </div>
                </Row>
                {(this.state.sendData === true) ? SaveHCForm(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.props.componentprops.match.params.id, this.data, this.HandleHCFormSubmiteResponse) : ''}
                {(this.state.sendEmail === true) ? SendEmail(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.email_data, this.HandleHCFormEmailRes) : ''}

            </Container>
        )
    }
}
export const HCEntryPage = PnkConnect(HCEntry, "");