
import React from 'react'
import {ReportPage} from './Admin'
import {TelecallingReportPage} from './Telecalling'
import {SalesReportPage} from './Sales'
import { SalesTLReportPage } from './SalesTL'
import {MarketingReportPage} from './Marketing'
import { LeadPage } from "../Lead"
import {SalesHeadDashboard} from './Sales Head'
class AdminRoute extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        if(sessionStorage.getItem('current_user_profile')==="Administrator"){
            return <ReportPage {...this.props}/>
        }
        else if(sessionStorage.getItem('current_user_profile')==="Sales"){
            return <SalesReportPage  {...this.props}/>
        }
        else if(sessionStorage.getItem('current_user_profile')==="Telecalling"){
            return <TelecallingReportPage  {...this.props}/>
        }
        else if(sessionStorage.getItem('current_user_profile')==="Sales TL"){
            return <SalesTLReportPage  {...this.props}/>
        }
        else if(sessionStorage.getItem('current_user_profile')==="Marketing"){
            return <MarketingReportPage  {...this.props}/>
        }
        else if(sessionStorage.getItem('current_user_profile')==="Sales Head"){
            return <SalesHeadDashboard  {...this.props}/>
        }

        else{
            return <LeadPage  {...this.props} showHeader={false} />
        }
    }
}

export default AdminRoute;

