import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { PnkConnect } from "../../../pnk-react/pnk-miniredux/";
import { GetLeadSource, GetUserList } from '../../../models/users';
import { AdminSalesReport } from '../../../models/admin-dashboard'
import LeadSourcereport from './lead-source'
import Option from './oprions'
import Marketing from './marketing'
import LeadTrackingReport from '../Admin/lead-tracking-report';
import LeadCampaign from '../Admin/lead-campaign'
import PatchReport from '../Admin/patch-report'
import InsideSalesReport from '../Admin/inside-sales-report'
import { FirstCallConnectionByDate } from '../../../models/calls'
import { AllCallList } from '../../../models/services'
class reportPage extends React.Component {
    constructor(props) {
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('');
        this.props.componentprops.ShowAdvanceSearch(false);
        this.state = {
            currentSalesReportType: 'Today',
            txtCustomStartDate: new Date().setDate(new Date().getDate() - 29),
            txtCustomEndDate: new Date(),
            currentViewType: 'Default',
            startDate: '',
            endDate: '',
            currentDisplayType: '',
            todayDate: '',
            yesterdayDate: '',
            weekDate: '',
            monthDate: '',
            getUser: false,
            getReportData: false,
            reportData: [],
            showCustomDateModal: false,
            getLeadSource: true,
            LeadSourceList: [],

            getFirstCall: false,
            setNo: 1,
            setLength: 50,
            numItemsPerPage: 50,
            totalRecordsInDb: 0,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,
            lead_campaign_view: 'default',
            patchView: 'default',
            patchDetailReport: [],
            ddlCurrentLeadStage: '',

            getAllCalls: false,
            getCustomCallList: false,
        }
        this.salesReportType = [
            'Today',
            'Yesterday',
            'Last 7 Days',
            // 'Last 30 Days',
            'Custom'
        ];
        this.TodayLeadGenerated = []
        this.YesterdayLeadGenerated = []
        this.WeekLeadGenerated = []
        this.customData = []

        this.TodayPatchData = []
        this.YesterdayPatchData = []
        this.WeekPatchData = []
        this.leadSourceData = []
        // Booking AMount Received
        this.todayBookingAmountReceived = []
        this.yesterdayBookingAmountReceived = []
        this.weekBookingAmountReceived = []
        // Hc Booked
        this.todayHCBookedReport = []
        this.yesterdayHCBookedReport = []
        this.weekHCBookedReport = []
        this.monthHCBookedReport = []
        // HC NA
        this.todayHCNAReport = []
        this.yesterdayHCNAReport = []
        this.weekHCNAReport = []
        // Exotel called
        this.todayExotelCalled = []
        this.yesterdayExotelCalled = []
        this.weekExotelCalled = []
        this.monthExotelCalled = []
        //  Lead Generated
        this.todayLeadGeneratedData = [];
        this.yesterdayLeadGeneratedData = [];
        this.weekLeadGeneratedData = [];
        this.monthLeadGeneratedData = [];
        // Lead Called
        this.todayLeadCalledData = []
        this.yesterdayLeadCalledData = []
        this.weekLeadCalledData = []
        this.monthLeadCalledData = []

        this.currentCity = '';

        this.FirstCallData = []
        this.calldetails_data = {};

        //  Lead Generated
        this.monthLeadGeneratedData = [];

        this.currentSalesUser = ''
        this.currentDesigner = ''
        this.currentTeleUser = '';

        this.customCallList = []

    }
    HandleUserInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ddlPreferredCity") {
            this.currentCity = e.target.value;
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }
    }
    componentDidMount = () => {
        var today = new Date();

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let endDate = yyyy + '-' + mm + '-' + dd;

        let beforeThirtyDays = new Date(new Date().setDate(new Date().getDate() - 6))
        var before_dd = String(beforeThirtyDays.getDate()).padStart(2, '0');
        var before_mm = String(beforeThirtyDays.getMonth() + 1).padStart(2, '0'); //January is 0!
        var before_yyyy = beforeThirtyDays.getFullYear();
        let startDate = before_yyyy + '-' + before_mm + '-' + before_dd;

        let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
        var yester_dd = String(yesterdayDate.getDate()).padStart(2, '0');
        var yester_mm = String(yesterdayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yester_yyyy = yesterdayDate.getFullYear();
        let yesterday = yester_yyyy + '-' + yester_mm + '-' + yester_dd;

        let weekDate = new Date(new Date().setDate(new Date().getDate() - 6))
        var week_dd = String(weekDate.getDate()).padStart(2, '0');
        var week_mm = String(weekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var week_yyyy = weekDate.getFullYear();
        let weekStartDate = week_yyyy + '-' + week_mm + '-' + week_dd;

        let monthDate = new Date(new Date().setDate(new Date().getDate() - 29))
        var month_dd = String(monthDate.getDate()).padStart(2, '0');
        var month_mm = String(monthDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var month_yyyy = monthDate.getFullYear();
        let monthStartDate = month_yyyy + '-' + month_mm + '-' + month_dd;


        this.setState({
            startDate: startDate, endDate: endDate, getReportData: true, currentDisplayType: endDate,
            todayDate: endDate, yesterdayDate: yesterday, weekDate: weekStartDate, monthDate: monthStartDate,
            getAllCalls: true
        })

        if (JSON.stringify(this.props.PnkStore.GetData('userlist')) === '{}') {
            this.setState({
                getUser: true
            })
        }

        if (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "") {
            if (this.props.componentprops.match.params.type === 'meeting-tracker') {
                this.setState({
                    getMeetingData: true
                })
            }
            else if (this.props.componentprops.match.params.type === 'callback') {
                this.setState({
                    getCallbackData: true
                })
            }
            else if (this.props.componentprops.match.params.type === "lead-tracking-report") {
                this.calldetails_data = {
                    'txtCallListStartDate': endDate,
                    'txtCallListEndDate': endDate
                }
                this.setState({
                    getFirstCall: true
                })
            }
            else {
                this.setState({
                    getReportData: true,
                    getAllCalls: true
                })
            }
        }
        else {
            this.setState({
                getReportData: true, getAllCalls: true
            })
        }
    }
    ChangeViewReport = (event) => {
        this.setState({ currentViewType: event.target.value, currentSalesReportType: 'Today' })
        if (event.target.value === "Marketing") {

            if (JSON.stringify(this.props.PnkStore.GetData('leadsource')) === '{}') {

                if (JSON.stringify(this.props.PnkStore.GetData('leaddata')) === '{}') {

                    this.setState({
                        getLeadSource: true
                    })
                }
                else {

                    if (this.props.PnkStore.GetData("leaddata") && this.props.PnkStore.GetData("leaddata").length > 0) {
                        this.leadSourceData = this.props.PnkStore.GetData("leaddata")[2].leadsourcelist;
                    }
                }
            }
            else {
                this.leadSourceData = this.props.PnkStore.GetData("leadsource");
            }
        }
        else if (event.target.value === "lead-tracking-report") {
            this.calldetails_data = {
                'txtCallListStartDate': this.state.todayDate,
                'txtCallListEndDate': this.state.todayDate
            }
            this.setState({
                getFirstCall: true
            })
        }
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport(event.target.value, this.state.currentSalesReportType)
        }


    }
    HandleChangeDate = (type, date) => {

        this.setState({
            [type]: date
        });

    };

    ChangeSalesReportType = (type) => {
        // alert(type);
        // alert(this.state.currentViewType+'==='+type);
        this.setState({
            currentSalesReportType: type
        })
        if (this.state.currentViewType === "lead-tracking-report") {
            if (type === "Today") {
                this.calldetails_data = {
                    'txtCallListStartDate': this.state.todayDate,
                    'txtCallListEndDate': this.state.todayDate
                }
                this.setState({
                    setNo: 1, currentSalesReportType: 'Today', getFirstCall: true, reportData: [],
                    currentPageNumber: 1,
                    startValue: 0,
                    endValue: 1,
                    totalPages: 0,
                    totalRecordsInDb: 0,
                })
            }
            else if (type === "Yesterday") {
                this.calldetails_data = {
                    'txtCallListStartDate': this.state.yesterdayDate,
                    'txtCallListEndDate': this.state.yesterdayDate
                }
                this.setState({
                    setNo: 1, getFirstCall: true, reportData: [],
                    currentPageNumber: 1,
                    startValue: 0,
                    endValue: 1,
                    totalPages: 0,
                    totalRecordsInDb: 0,
                })
            }
            else if (type === "Last 7 Days") {
                this.calldetails_data = {
                    'txtCallListStartDate': this.state.weekDate,
                    'txtCallListEndDate': this.state.todayDate
                }
                this.setState({
                    setNo: 1, getFirstCall: true, reportData: [],
                    currentPageNumber: 1,
                    startValue: 0,
                    endValue: 1,
                    totalPages: 0,
                    totalRecordsInDb: 0,
                })
            }
            // this.RenderFirstCallDetails(this.FirstCallData,type)
        }
        else if (this.state.currentViewType === 'campaign-report') {
            let camapign_data = [];
            let campaign_name_list = []
            let total_campaign_count = 0;

            // alert(JSON.stringify(this.TodayLeadGenerated));

            // let unique_campaign_names = [...new Set(campaign_name_list)];
            let unique_campaign_names = [];
            let unique_source_names = [];

            let data_arr = '';

            if (type === "Today") {
                data_arr = this.TodayLeadGenerated;
            }
            else if (type === "Yesterday") {
                data_arr = this.YesterdayLeadGenerated;
            }
            else if (type === "Last 7 Days") {
                data_arr = this.WeekLeadGenerated;
            }
            else if (type === "Last 30 Days" || type === 'Custom') {
                data_arr = this.monthLeadGeneratedData;
                // alert(JSON.stringify(this.monthLeadGeneratedData));
            }

            data_arr.forEach(report => {
                if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                    if (!unique_campaign_names.includes(report.lead_campaign)) {
                        unique_campaign_names.push(report.lead_campaign);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: report.lead_campaign })
                    }
                }
                else {
                    // alert(report.lead_source);
                    if (!unique_source_names.includes(report.lead_source)) {
                        unique_source_names.push(report.lead_source);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: '' })
                    }
                }
                // if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                //     campaign_name_list.push(report.lead_campaign)
                // }
            });
            // let unique_campaign_names = [...new Set(campaign_name_list)];

            campaign_name_list.map(element => {
                data_arr.forEach(report => {
                    let campaign_name = element.lead_campaign
                    let lead_source = element.lead_source
                    if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                        if (report.lead_campaign == campaign_name) {

                            let typeIndex = camapign_data.findIndex(type => report.lead_campaign === type.campaign_name);
                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                    else {
                        if (report.lead_source == lead_source) {
                            let typeIndex = camapign_data.findIndex(type => report.lead_source === type.lead_source);
                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': '', 'campaign_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                })
            })

            this.setState({
                reportData: camapign_data, total_campaign_count: total_campaign_count
            })

        }
        this.RenderReport(this.state.currentViewType, type)
    }
    HandleGetLeadSourceSuccessResponse = (res) => {
        if (res.data) {
            this.leadSourceData = res.data

            this.props.PnkStore.SetData('leadsource', res.data);
            this.props.PnkStore.BroadCast();
            this.RenderReport(this.state.currentViewType, this.state.currentSalesReportType)
        }
        this.setState({
            getLeadSource: false
        })
    }
    HandleGetReportResponse = (res) => {
        let lead_source = []
        this.setState({ getReportData: false, isOptionDisabled: false })
        if (res !== false) {

            if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.adminReportAll = res.data.adminfirstmonthdata.rows
                // console.log("res.data.adminfirstmonthdata.rows",res.data.adminfirstmonthdata.rows)
                res.data.adminfirstmonthdata.rows.forEach((element, i) => {

                    if (element.date_time !== undefined && element.date_time.includes(this.state.todayDate)) {
                        this.todayLeadGeneratedData.push(element)
                        if (element.lead_current_stage !== "Pending") {
                            this.todayLeadCalledData.push(element)
                        }

                    }
                    else if (element.date_time !== undefined && element.date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayLeadGeneratedData.push(element);
                        if (element.lead_current_stage !== "Pending") {
                            this.yesterdayLeadCalledData.push(element)
                        }
                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.weekDate) {
                        this.weekLeadGeneratedData.push(element)
                        if (element.lead_current_stage !== "Pending") {
                            this.weekLeadCalledData.push(element)
                        }
                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.monthDate) {
                        this.monthLeadGeneratedData.push(element)
                        if (element.lead_current_stage !== "Pending") {
                            this.monthLeadCalledData.push(element)
                        }
                    }
                    // Patch Data for other reports
                    if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate)) {
                        this.TodayPatchData.push(element)
                    }
                    else if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate)) {
                        this.YesterdayPatchData.push(element);
                    }
                    if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.weekDate) {
                        this.WeekPatchData.push(element)
                    }

                    if (element.lead_source !== undefined && element.lead_source !== 'undefined' && element.lead_source !== null && element.lead_source !== '')
                        lead_source.push(element.lead_source)

                    if (element.date_time !== undefined && element.date_time.includes(this.state.todayDate)) {
                        this.TodayLeadGenerated.push(element)
                    }
                    else if (element.date_time !== undefined && element.date_time.includes(this.state.yesterdayDate)) {
                        this.YesterdayLeadGenerated.push(element);
                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.weekDate) {
                        this.WeekLeadGenerated.push(element)
                    }
                    if (element.lead_department[0].profile_name.includes("Sales")) {
                        if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.todayDate)) {
                            this.todayBookingAmountReceived.push(element)
                        }
                        else if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayBookingAmountReceived.push(element);
                        }
                        if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.weekDate) {
                            this.weekBookingAmountReceived.push(element)
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                            this.todayHCBookedReport.push(element)
                        }

                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCBookedReport.push(element);
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                            this.weekHCBookedReport.push(element)
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.monthDate) {
                            this.monthHCBookedReport.push(element)
                        }
                        // HC NA
                        if (element.hc_na_date_time !== undefined && element.hc_na_date_time.includes(this.state.todayDate)) {
                            this.todayHCNAReport.push(element)
                        }

                        if (element.hc_na_date_time !== undefined && element.hc_na_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCNAReport.push(element);
                        }
                        if (element.hc_na_date_time !== undefined && element.hc_na_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_na_date_time >= this.state.weekDate) {
                            this.weekHCNAReport.push(element)
                        }
                    }




                    if (res.data.adminfirstmonthdata.rows.length - 1 === i) {
                        if (lead_source.length > 0) {
                            let unique = lead_source.filter((item, i, ar) => ar.indexOf(item) === i);
                            this.setState({
                                LeadSourceList: unique
                            })
                        }
                        this.RenderReport(this.state.currentViewType, this.state.currentSalesReportType);
                    }

                });
            }

        }
    }
    ChangePage = (number) => {

        if (number - 1 === 0) {
            // this.setState({ getLeadList: true, currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
        else {
            // this.setState({ getLeadList: true, currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
        this.setState({ setNo: number, getFirstCall: true })
    }
    RenderReport = (viewType, type) => {
        // alert(viewType+'-----'+type);
        console.log("LeadSourceList", this.state.LeadSourceList)
        if (viewType === 'Default') {
            let lead_source_report = []
            if (this.state.LeadSourceList.length > 0) {
                this.state.LeadSourceList.forEach(leadsource => {
                    // alert(type)
                    let count = 0
                    if (type === "Today") {


                        this.TodayLeadGenerated.forEach(report => {
                            if (report.lead_source !== undefined && report.lead_source !== null && report.lead_source !== '') {
                                if (report.lead_source === leadsource) {
                                    count++;
                                }
                            }

                        })
                    }
                    else if (type === "Yesterday") {
                        this.YesterdayLeadGenerated.forEach(report => {
                            if (report.lead_source !== undefined && report.lead_source !== null && report.lead_source !== '') {
                                if (report.lead_source === leadsource) {
                                    count++;
                                }
                            }

                        })
                    }
                    else if (type === 'Last 7 Days' || type === 'Custom') {
                        this.WeekLeadGenerated.forEach(report => {
                            if (report.lead_source !== undefined && report.lead_source !== null && report.lead_source !== '') {
                                if (report.lead_source === leadsource) {
                                    count++;
                                }
                            }

                        })
                    }
                    if (count > 0) {
                        lead_source_report.push({
                            lead_source: leadsource, total_count: count
                        })
                    }

                })


                console.log("lead_source_report", lead_source_report)
                this.setState({
                    reportData: lead_source_report
                })
            }
        }
        else if (viewType === "Marketing") {

            let marketingData = []
            if (this.leadSourceData && this.leadSourceData.leadsourcelist && this.leadSourceData.leadsourcelist.rows !== undefined && this.leadSourceData.leadsourcelist.rows.length > 0) {
                this.leadSourceData.leadsourcelist.rows.forEach((leadData, i) => {
                    // alert(1)
                    let lead_patched = 0, lead_generated = 0, total_spend = 0, total_closure = 0, dead_after_patch = 0, total_booking_amount_received = 0;
                    if (type === "Today") {

                        this.TodayPatchData.forEach(todayPatchData => {

                            if (this.currentCity !== '' && (this.currentCity === todayPatchData.lead_city)) {

                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(todayPatchData.lead_source.toLowerCase())) {

                                        lead_patched++;
                                    }
                                }
                                else if (todayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (todayPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            if (this.currentCity === '') {

                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(todayPatchData.lead_source.toLowerCase())) {
                                        console.log("todayPatchData", todayPatchData.lead_source)
                                        lead_patched++;
                                    }
                                }
                                else if (todayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (todayPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }

                        });

                        this.TodayLeadGenerated.forEach(todayData => {
                            if (todayData.lead_source !== undefined) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && todayData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(todayData.lead_source.toLowerCase())) {
                                            lead_generated++
                                        }
                                    }
                                    else if (todayData.lead_source === leadData.lead_source_name) {
                                        lead_generated++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && todayData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(todayData.lead_source.toLowerCase())) {
                                            lead_generated++
                                        }
                                    }
                                    else if (todayData.lead_source === leadData.lead_source_name) {
                                        lead_generated++
                                    }
                                }
                            }
                        });
                        this.todayBookingAmountReceived.forEach(report => {
                            if (report.lead_source !== undefined) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && report.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(report.lead_source.toLowerCase())) {
                                            total_booking_amount_received++
                                        }
                                    }
                                    else if (report.lead_source === leadData.lead_source_name) {
                                        total_booking_amount_received++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && report.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(report.lead_source.toLowerCase())) {
                                            total_booking_amount_received++
                                        }
                                    }
                                    else if (report.lead_source === leadData.lead_source_name) {
                                        total_booking_amount_received++
                                    }
                                }
                            }

                        })

                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch, total_booking_amount_received: total_booking_amount_received

                            })
                        }
                    }
                    else if (type === "Yesterday") {
                        this.YesterdayPatchData.forEach(yesterdayPatchData => {
                            if (this.currentCity !== '' && (this.currentCity === yesterdayPatchData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (yesterdayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (yesterdayPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (yesterdayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        });

                        this.YesterdayLeadGenerated.forEach(yesterdayLGData => {
                            if (this.currentCity !== '' && (this.currentCity === yesterdayLGData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (yesterdayLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            if (this.currentCity === '') {

                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (yesterdayLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        });
                        this.yesterdayBookingAmountReceived.forEach(report => {
                            if (report.lead_source !== undefined) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && report.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(report.lead_source.toLowerCase())) {
                                            total_booking_amount_received++
                                        }
                                    }
                                    else if (report.lead_source === leadData.lead_source_name) {
                                        total_booking_amount_received++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && report.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(report.lead_source.toLowerCase())) {
                                            total_booking_amount_received++
                                        }
                                    }
                                    else if (report.lead_source === leadData.lead_source_name) {
                                        total_booking_amount_received++
                                    }
                                }
                            }

                        })

                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch, total_booking_amount_received: total_booking_amount_received
                            })
                        }
                    }
                    if (type === "Last 7 Days" || type === "Custom") {
                        this.WeekPatchData.forEach(weekData => {
                            if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (weekData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (weekData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (weekData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        });

                        this.WeekLeadGenerated.forEach(weekLGData => {
                            if (this.currentCity !== '' && (this.currentCity === weekLGData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (weekLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (weekLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        });
                        this.weekBookingAmountReceived.forEach(report => {
                            if (report.lead_source !== undefined) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && report.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(report.lead_source.toLowerCase())) {
                                            total_booking_amount_received++
                                        }
                                    }
                                    else if (report.lead_source === leadData.lead_source_name) {
                                        total_booking_amount_received++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && report.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(report.lead_source.toLowerCase())) {
                                            total_booking_amount_received++
                                        }
                                    }
                                    else if (report.lead_source === leadData.lead_source_name) {
                                        total_booking_amount_received++
                                    }
                                }
                            }

                        })
                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch, total_booking_amount_received: total_booking_amount_received
                            })
                        }
                    }


                })



            }
            // console.log("marketingData,", marketingData)
            this.setState({ reportData: marketingData })
        }
        else if (viewType === 'campaign-report') {
         //   alert('kuuu')
            let camapign_data = [];
            let campaign_name_list = []
            let total_campaign_count = 0;

            // let unique_campaign_names = [...new Set(campaign_name_list)];
            let unique_campaign_names = [];
            let unique_source_names = [];

            let data_arr = '';

            if (type === "Today") {
                data_arr = this.TodayLeadGenerated;
            }
            else if (type === "Yesterday") {
                data_arr = this.YesterdayLeadGenerated;
            }
            else if (type === "Last 7 Days") {
                data_arr = this.WeekLeadGenerated;
            }
            else if (type === "Last 30 Days" || type === 'Custom') {
                data_arr = this.monthLeadGeneratedData;
                // alert(JSON.stringify(this.monthLeadGeneratedData));
            }

            // alert(JSON.stringify(data_arr));

            data_arr.forEach(report => {
                if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                    if (!unique_campaign_names.includes(report.lead_campaign)) {
                        unique_campaign_names.push(report.lead_campaign);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: report.lead_campaign })
                    }
                }
                else {
                    // alert(report.lead_source);
                    if (!unique_source_names.includes(report.lead_source)) {
                        unique_source_names.push(report.lead_source);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: '' })
                    }
                }
                // if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                //     campaign_name_list.push(report.lead_campaign)
                // }
            });
            // let unique_campaign_names = [...new Set(campaign_name_list)];

            campaign_name_list.map(element => {
                let patch_count = 0;
                let dead_count = 0;
                let callback_count =0;
                let adset_arr = [];
                let ads_arr = [];
                let source_patch_count = 0;
                let source_dead_count = 0;
                let source_callback_count =0;

                data_arr.forEach(report => {

                    let campaign_name = element.lead_campaign
                    let lead_source = element.lead_source

                    if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                        if (report.lead_campaign == campaign_name) {

                            
                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }

                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }

                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                callback_count++;
                            }

                            let typeIndex = camapign_data.findIndex(type => report.lead_campaign === type.campaign_name);
                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = patch_count
                                camapign_data[typeIndex].dead_count = dead_count
                                camapign_data[typeIndex].callback_count = callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': patch_count,'dead_count': dead_count,'callback_count': callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                    else {
                        if (report.lead_source == lead_source) {

                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }
                      
                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }


                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                source_patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                source_dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                source_callback_count++;
                            }

                            let typeIndex = camapign_data.findIndex(type => report.lead_source === type.lead_source);
                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = source_patch_count
                                camapign_data[typeIndex].dead_count = source_dead_count
                                camapign_data[typeIndex].callback_count = source_callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': source_patch_count,'dead_count': source_dead_count,'callback_count': source_callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                })
            })

            this.setState({
                reportData: camapign_data, total_campaign_count: total_campaign_count
            })

        }
        else if (viewType === "patch-report") {
            let users = this.props.PnkStore.GetData('userlist').rows;

            let total_patch_count = 0;
            let total_patch_count_yesterday = 0;
            let total_patch_count_week = 0;
            let total_patch_count_month = 0;
            let patch_report = [];
            let sales_person_list = []
            let tele_assignee = []
            if (type === "Today") {
                total_patch_count = 0
                this.TodayPatchData.forEach(report => {
                    tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.TodayPatchData.forEach(report => {
                        if (report.tele_assignee === tele_assignee) {

                            if (this.currentTeleUser !== '' && this.currentTeleUser === report.tele_assignee) {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {
                                        total_patch_count++
                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                            }
                            else if (this.currentTeleUser === '') {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }

                            }
                        }

                    })

                })


            }
            else if (type === "Yesterday") {
                total_patch_count = 0
                this.YesterdayPatchData.forEach(report => {
                    tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.YesterdayPatchData.forEach(report => {

                        if (report.tele_assignee === tele_assignee) {
                            if (this.currentTeleUser !== '' && this.currentTeleUser === report.tele_assignee) {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                            }
                            else if (this.currentTeleUser === '') {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }

                            }
                        }

                    })

                })


            }
            else if (type === "Last 7 Days") {
                total_patch_count = 0
                this.WeekPatchData.forEach(report => {
                    tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.WeekPatchData.forEach(report => {
                        if (report.tele_assignee === tele_assignee) {
                            if (this.currentTeleUser !== '' && this.currentTeleUser === report.tele_assignee) {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                            }
                            else if (this.currentTeleUser === '') {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }

                            }
                        }
                    })

                })


            }


            this.setState({ reportData: patch_report, total_patch_count: total_patch_count })
        }
        else if (viewType === "inside-sales-report") {
            // alert(1)

            let insideSalesData = []

            let users = this.props.PnkStore.GetData('userlist').rows;
            let sales_reports = [];
            users.forEach((user, i) => {

                let lead_patched = 0, lead_generated = 0, lead_called = 0, no_budget = 0, not_answered = 0, not_interested = 0, other_city = 0, window_shopping = 0, other_reason = 0, total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                let total_hc_booked = 0, total_hcna = 0;
                if (user.user_profile[0].profile_name !== undefined && (user.user_profile[0].profile_name.includes('Telecalling') || user.user_profile[0].profile_name.includes('Administrator'))) {
                    if (type === "Today") {
                        this.todayHCBookedReport.forEach(todayData => {

                            if (todayData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                            }
                        })
                        this.todayHCNAReport.forEach(todayData => {

                            if (todayData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_hcna++;
                                }
                                if (this.currentCity === '') {
                                    total_hcna++;
                                }
                            }
                        })
                        this.TodayPatchData.forEach(todayPatchedData => {
                            if (todayPatchedData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayPatchedData.lead_city)) {
                                    lead_patched++
                                }
                                if (this.currentCity === '') {
                                    lead_patched++
                                }
                            }



                        });

                        this.todayExotelCalled.forEach(todayCalleddData => {

                            if (todayCalleddData.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++
                                }
                                if (this.currentCity === '') {
                                    lead_called++
                                }
                            }
                        });
                        this.todayLeadGeneratedData.forEach(todayLGData => {
                            if (todayLGData.tele_assignee === user.user_name || todayLGData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayLGData.lead_city)) {
                                    lead_generated++

                                }
                                if (this.currentCity === '') {
                                    lead_generated++
                                }
                            }
                        });
                        if (this.currentInsideSalesType === "dead") {
                            // alert(1)
                            this.todayDeadData.forEach(todayDeadData => {
                                if (todayDeadData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayDeadData.lead_city)) {
                                        total_dead++
                                        if (todayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (todayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (todayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (todayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (todayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (todayDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }


                                    if (this.currentCity === '') {

                                        total_dead++
                                        if (todayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (todayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (todayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (todayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (todayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (todayDeadData.lead_dead_reason === "other")
                                            other_reason++

                                    }
                                }
                            });
                        }
                        if (this.currentInsideSalesType === "pending") {
                            // alert(1)
                            this.todayPendingData.forEach(todayPendingdData => {
                                if (todayPendingdData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayPendingdData.lead_city)) {

                                        total_pending++

                                    }
                                    if (this.currentCity === '') {
                                        // console.loge.log("todayPendingdData",todayPendingdData)
                                        total_pending++
                                    }
                                }
                            })
                            this.todayUpdateTimeData.forEach(todayUpdatedData => {
                                if (todayUpdatedData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayUpdatedData.lead_city)) {
                                        if (todayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (todayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++
                                    }


                                    if (this.currentCity === '') {
                                        if (todayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (todayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++

                                    }
                                }

                            })
                            this.todayCallbackData.forEach(todayCallbackData => {
                                if (todayCallbackData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayCallbackData.lead_city)) {
                                        total_callback++

                                    }
                                    if (this.currentCity === '') {
                                        total_callback++
                                    }
                                }
                            })

                        }
                        if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                            insideSalesData.push({
                                lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                                not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                                total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                                total_hc_booked: total_hc_booked, total_hcna: total_hcna

                            })
                        }
                    }
                    if (type === "Yesterday") {
                        this.yesterdayHCBookedReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                            }
                        })
                        this.yesterdayHCNAReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hcna++;
                                }
                                if (this.currentCity === '') {
                                    total_hcna++;
                                }
                            }
                        })
                        this.YesterdayPatchData.forEach(yesterdayPatchedData => {
                            if (yesterdayPatchedData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayPatchedData.lead_city)) {
                                    lead_patched++
                                }
                                if (this.currentCity === '') {
                                    lead_patched++
                                }
                            }

                        });
                        this.yesterdayExotelCalled.forEach(yesterdayCalleddData => {

                            if (yesterdayCalleddData.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++
                                }
                                if (this.currentCity === '') {
                                    lead_called++
                                }
                            }
                        });
                        // this.yesterdayLeadCalledData.forEach(yesterdayCalleddData => {
                        //     if (yesterdayCalleddData.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === yesterdayCalleddData.lead_city)) {
                        //             lead_called++
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++
                        //         }
                        //     }
                        // });
                        this.yesterdayLeadGeneratedData.forEach(yesterdayLGData => {
                            if (yesterdayLGData.tele_assignee === user.user_name || yesterdayLGData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayLGData.lead_city)) {
                                    lead_generated++
                                }
                                if (this.currentCity === '') {
                                    lead_generated++
                                }
                            }
                        });
                        if (this.currentInsideSalesType === "dead") {
                            this.yesterdayDeadData.forEach(yesterdayDeadData => {
                                if (yesterdayDeadData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayDeadData.lead_city)) {
                                        total_dead++
                                        if (yesterdayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (yesterdayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (yesterdayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (yesterdayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (yesterdayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (yesterdayDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                    if (this.currentCity === '') {
                                        total_dead++
                                        if (yesterdayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (yesterdayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (yesterdayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (yesterdayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (yesterdayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (yesterdayDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                }

                            });
                        }
                        if (this.currentInsideSalesType === "pending") {
                            this.yesterdayPendingData.forEach(yesterdayPendingdData => {
                                if (yesterdayPendingdData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayPendingdData.lead_city)) {
                                        total_pending++

                                    }
                                    if (this.currentCity === '') {
                                        total_pending++
                                    }
                                }
                            })
                            this.yesterdayUpdateTimeData.forEach(yesterdayUpdatedData => {
                                if (yesterdayUpdatedData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayUpdatedData.lead_city)) {
                                        if (yesterdayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (yesterdayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++

                                    }
                                    if (this.currentCity === '') {
                                        if (yesterdayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (yesterdayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++
                                    }
                                }
                            })
                            this.yesterdayCallbackData.forEach(yesterdayCallbackData => {
                                if (yesterdayCallbackData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayCallbackData.lead_city)) {
                                        total_callback++

                                    }
                                    if (this.currentCity === '') {
                                        total_callback++
                                    }
                                }
                            })

                        }
                        if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                            insideSalesData.push({
                                lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.yesterdayDate,
                                not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                                total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                                total_hc_booked: total_hc_booked, total_hcna: total_hcna
                            })
                        }
                    }
                    if (type === "Last 7 Days" || type === "Custom") {
                        this.weekHCBookedReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                            }
                        })
                        this.weekHCNAReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hcna++;
                                }
                                if (this.currentCity === '') {
                                    total_hcna++;
                                }
                            }
                        })
                        this.WeekPatchData.forEach(weekPatchedData => {
                            if (weekPatchedData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekPatchedData.lead_city)) {
                                    lead_patched++
                                }
                                if (this.currentCity === '') {
                                    lead_patched++
                                }
                            }

                        });
                        this.weekExotelCalled.forEach(weekCalleddData => {

                            if (weekCalleddData.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++
                                }
                                if (this.currentCity === '') {
                                    lead_called++
                                }
                            }
                        });
                        // this.weekLeadCalledData.forEach(weekCalleddData => {
                        //     if (weekCalleddData.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === weekCalleddData.lead_city)) {
                        //             lead_called++
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++
                        //         }
                        //     }
                        // });
                        this.weekLeadGeneratedData.forEach(weekLGData => {
                            if (weekLGData.tele_assignee === user.user_name || weekLGData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekLGData.lead_city)) {
                                    lead_generated++
                                }
                                if (this.currentCity === '') {
                                    lead_generated++
                                }
                            }
                        });
                        if (this.currentInsideSalesType === "dead") {
                            this.weekDeadData.forEach(weekDeadData => {
                                if (weekDeadData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekDeadData.lead_city)) {
                                        total_dead++
                                        if (weekDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (weekDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (weekDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (weekDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (weekDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (weekDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                    if (this.currentCity === '') {
                                        total_dead++
                                        if (weekDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (weekDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (weekDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (weekDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (weekDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (weekDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                }

                            });
                        }
                        if (this.currentInsideSalesType === "pending") {
                            this.weekPendingData.forEach(weekPendingdData => {
                                if (weekPendingdData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekPendingdData.lead_city)) {
                                        total_pending++

                                    }
                                    if (this.currentCity === '') {
                                        total_pending++
                                    }
                                }
                            })
                            this.weekUpdateTimeData.forEach(weekUpdatedData => {
                                if (weekUpdatedData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekUpdatedData.lead_city)) {
                                        if (weekUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (weekUpdatedData.lead_current_stage === "Hold")
                                            total_hold++

                                    }
                                    if (this.currentCity === '') {
                                        if (weekUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (weekUpdatedData.lead_current_stage === "Hold")
                                            total_hold++
                                    }
                                }
                            })
                            this.weekCallbackData.forEach(weekCallbackData => {
                                if (weekCallbackData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekCallbackData.lead_city)) {
                                        total_callback++

                                    }
                                    if (this.currentCity === '') {
                                        total_callback++
                                    }
                                }
                            })

                        }
                        if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                            insideSalesData.push({
                                lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.weekDate + ' To ' + this.state.todayDate,
                                not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                                total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                                total_hc_booked: total_hc_booked, total_hcna: total_hcna
                            })
                        }
                    }
                }
                if (users.length - 1 == i) {
                    this.setState({ reportData: insideSalesData })
                }

            })
        }

    }
    GetCustomData = () => {
        // alert('kkkkkkkk');
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;

        this.setState({
            customStartDate: startDate,
            customEndDate: endDate, getCustomData: true,

        })


    }
    HandleGetCustomDatatResponse = (res) => {
        console.log("HandleGetCustomDatatResponse", res)
        if (res !== false) {

            if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                if (this.state.currentViewType === "Marketing") {

                    if (JSON.stringify(this.props.PnkStore.GetData('leadsource')) === '{}') {

                        if (JSON.stringify(this.props.PnkStore.GetData('leaddata')) === '{}') {

                            this.setState({
                                getLeadSource: true
                            })
                        }
                        else {

                            if (this.props.PnkStore.GetData("leaddata") && this.props.PnkStore.GetData("leaddata").length > 0) {
                                this.leadSourceData = this.props.PnkStore.GetData("leaddata")[2].leadsourcelist;
                            }
                        }
                    }
                    else {
                        this.leadSourceData = this.props.PnkStore.GetData("leadsource");
                    }
                }
                this.RenderCustomReport(res.data.adminfirstmonthdata.rows, this.state.currentViewType)
            }
        }
        this.setState({
            getCustomData: false,
            showCustomDateModal: false
        })
    }
    HandleGetCustomAllCallsListSuccessResponse = (res) => {

        if (res && res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows.length > 0) {
            this.customCallList = res.data.calllist.rows

        }
        this.setState({
            getCustomCallList: false,
            getCustomData: true
        })
    }
    RenderCustomReport = (customData, viewType) => {
        var users = this.props.PnkStore.GetData('userlist').rows;
        if (viewType === 'Default') {
            let lead_source_data = []
            let custom_lead_list = []
            let lead_source_report = []
            customData.forEach(element => {
                if (element.lead_source !== undefined && element.lead_source !== null && element.lead_source !== '') {
                    lead_source_data.push(element.lead_source)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    custom_lead_list.push(element)
                }

            })

            let unique_lead_source = lead_source_data.filter((item, i, ar) => ar.indexOf(item) === i);
            console.log("unique_lead_source", unique_lead_source)
            if (unique_lead_source.length > 0) {
                unique_lead_source.forEach(leadSource => {
                    let count = 0
                    custom_lead_list.forEach(report => {
                        if (report.lead_source !== undefined && report.lead_source !== null && report.lead_source !== '') {
                            if (report.lead_source === leadSource) {
                                count++;
                            }
                        }
                    })
                    if (count > 0) {
                        lead_source_report.push({
                            lead_source: leadSource, total_count: count
                        })
                    }
                })
                this.setState({
                    reportData: lead_source_report
                })
            }
        }
        else if (viewType === 'Marketing') {
            // alert(1)
            let marketingData = []
            let marketing_patch_data = []
            let marketing_lg_data = []
            customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    marketing_patch_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    marketing_lg_data.push(element)
                }
            })
            if (this.leadSourceData && this.leadSourceData.leadsourcelist.rows && this.leadSourceData.leadsourcelist.rows.length > 0) {

                this.leadSourceData.leadsourcelist.rows.forEach((leadData, i) => {
                    let lead_patched = 0, lead_generated = 0, total_spend = 0, total_closure = 0, dead_after_patch = 0;

                    marketing_patch_data.forEach(marketPatchData => {
                        if (marketPatchData.lead_source !== undefined) {
                            if (this.currentCity !== '' && (this.currentCity === marketPatchData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {

                                    if ((marketPatchData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(marketPatchData.lead_source.toLowerCase())) {

                                        lead_patched++;
                                    }
                                }
                                else if (marketPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {

                                    if ((marketPatchData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(marketPatchData.lead_source.toLowerCase())) {

                                        lead_patched++;
                                    }
                                }
                                else if (marketPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        }
                    });
                    marketing_lg_data.forEach(lgData => {
                        if (lgData.lead_source !== undefined) {
                            if (this.currentCity !== '' && (this.currentCity === lgData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name !== undefined && lgData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(lgData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (lgData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name !== undefined && lgData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(lgData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (lgData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        }

                    });

                    if (lead_patched > 0 || lead_generated > 0) {
                        marketingData.push({
                            source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                            dead_after_patch: dead_after_patch

                        })
                    }

                })
            }
            console.log("marketingData", marketingData)
            this.setState({
                reportData: marketingData
            })

        }
        else if (viewType === 'campaign-report') {
         //   alert('kkk');
            let camapign_data = [];
            let campaign_name_list = []
            let total_campaign_count = 0;

            // let unique_campaign_names = [...new Set(campaign_name_list)];
            let unique_campaign_names = [];
            let unique_source_names = [];

            let data_arr = '';

            // if (type === "Today") {
            //     data_arr = this.TodayLeadGenerated;
            // }
            // else if (type === "Yesterday") {
            //     data_arr = this.YesterdayLeadGenerated;
            // }
            // else if (type === "Last 7 Days") {
            //     data_arr = this.WeekLeadGenerated;
            // }
            // else if (type === "Last 30 Days" || type === 'Custom') {
            // data_arr = this.monthLeadGeneratedData;
            // data_arr = customData;
            // data_arr = customData;
            // alert(JSON.stringify(this.monthLeadGeneratedData));
            // }

            // alert(JSON.stringify(data_arr));


            let campaign_lg_data = []
            customData.forEach((element, i) => {
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    campaign_lg_data.push(element)
                    if (element.lead_campaign !== '' && element.lead_campaign !== undefined) {
                        // alert(element.lead_campaign);
                        if (!unique_campaign_names.includes(element.lead_campaign)) {
                            unique_campaign_names.push(element.lead_campaign);
                            campaign_name_list.push({ lead_source: element.lead_source, lead_campaign: element.lead_campaign })
                        }
                    }
                    else {
                        // alert(report.lead_source);
                        if (!unique_source_names.includes(element.lead_source)) {
                            unique_source_names.push(element.lead_source);
                            campaign_name_list.push({ lead_source: element.lead_source, lead_campaign: '' })
                        }
                    }
                }
            })

            campaign_name_list.map(element => {

                let patch_count = 0;
                let dead_count = 0;
                let callback_count =0;
                let adset_arr = [];
                let ads_arr = [];
                let source_patch_count = 0;
                let source_dead_count = 0;
                let source_callback_count =0;

                campaign_lg_data.forEach(report => {
                    // console.log(report.lead_campaign);
                    // alert(JSON.stringify(report));
                    let campaign_name = element.lead_campaign
                    let lead_source = element.lead_source
                    // alert(campaign_name+"------"+lead_source);
                    if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {

                        if (report.lead_campaign == campaign_name) {

                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }

                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }

                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                callback_count++;
                            }

                            let typeIndex = camapign_data.findIndex(type => report.lead_campaign === type.campaign_name);
                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = patch_count
                                camapign_data[typeIndex].dead_count = dead_count
                                camapign_data[typeIndex].callback_count = callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': source_patch_count,'dead_count': source_dead_count,'callback_count': source_callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                    else {
                        if (report.lead_source == lead_source) {

                            
                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }
                      
                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }


                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                source_patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                source_dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                source_callback_count++;
                            }

                            let typeIndex = camapign_data.findIndex(type => report.lead_source === type.lead_source);
                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = source_patch_count
                                camapign_data[typeIndex].dead_count = source_dead_count
                                camapign_data[typeIndex].callback_count = source_callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': source_patch_count,'dead_count': source_dead_count,'callback_count': source_callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                })
            })

            this.setState({
                reportData: camapign_data, total_campaign_count: total_campaign_count
            })

        }
        else if (viewType === "patch-report") {
            let total_patch_count = 0;
            let patch_data_report = []
            let tele_assignee = []
            let patch_report = []
            customData.forEach((element, i) => {
                if (element.date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    patch_data_report.push(element)
                }

            })
            patch_data_report.forEach(report => {
                tele_assignee.push(report.tele_assignee)
            })
            let unique_tele_assignee = [...new Set(tele_assignee)];
            unique_tele_assignee.map(tele_assignee => {
                patch_data_report.forEach(report => {
                    if (report.tele_assignee === tele_assignee) {
                        let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);

                        if (typeIndex !== -1) {

                            let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                            if (valIndex === -1) {
                                total_patch_count++
                                patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, date_time: report.date_time, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                            }
                            else {
                                total_patch_count++
                                let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                            }

                        }
                        else {
                            total_patch_count++
                            patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, date_time: report.date_time, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                        }
                    }

                })

            })
            this.setState({ reportData: patch_report, total_patch_count: total_patch_count })

        }
        else if (viewType === "inside-sales-report") {
            //    this.GetCustomCallList('Inside Sales Report')

            let insideSalesData = []
            let insidesales_patch_data = []
            let insidesales_lg_data = []
            let insidesales_called_data = []
            let insidesales_dead_data = []
            let insidesales_pending_data = []
            let insidesales_updated_data = []
            let insidesales_callback_data = []
            let insidesales_hc_booked_data = []
            let insidesales_hc_na_data = []
            customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    insidesales_patch_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_lg_data.push(element)

                }
                if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.customEndDate + ' 23:59:59' && element.lead_dead_date >= this.state.customStartDate) {
                    insidesales_dead_data.push(element)
                }
                if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_pending_data.push(element)
                }
                if (element.updated_on !== undefined && element.updated_on <= this.state.customEndDate + ' 23:59:59' && element.updated_on >= this.state.customStartDate) {
                    insidesales_updated_data.push(element)
                }
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate + ' 23:59:59' && element.callback_date_time >= this.state.customStartDate) {
                    insidesales_callback_data.push(element)
                }
                if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                    insidesales_hc_booked_data.push(element)
                }
                if (element.hc_na_date_time !== undefined && element.hc_na_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_na_date_time >= this.state.customStartDate) {
                    insidesales_hc_na_data.push(element)
                }


            })
            // // console.loge.log("insidesales_patch_data",insidesales_patch_data)
            this.customCallList.forEach((element, i) => {
                if (element.date_created !== undefined && element.date_created <= this.state.customEndDate + ' 23:59:59' && element.date_created >= this.state.customStartDate) {
                    insidesales_called_data.push(element)
                }
            })
            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && (user.user_profile[0].profile_name.includes('Telecalling') || user.user_profile[0].profile_name.includes('Administrator'))) {
                    let lead_patched = 0, lead_generated = 0, lead_called = 0, no_budget = 0, not_answered = 0, not_interested = 0, other_city = 0, window_shopping = 0, other_reason = 0, total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                    let total_hc_booked = 0, total_hc_na = 0;
                    insidesales_hc_booked_data.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_hc_booked++
                            }
                            if (this.currentCity === '') {
                                total_hc_booked++
                            }
                        }

                    });
                    insidesales_hc_na_data.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_hc_na++
                            }
                            if (this.currentCity === '') {
                                total_hc_na++
                            }
                        }

                    });
                    insidesales_patch_data.forEach(customPatchedData => {
                        if (customPatchedData.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customPatchedData.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }

                    });
                    insidesales_called_data.forEach(customCalleddData => {

                        if (customCalleddData.caller_name === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                lead_called++
                            }
                            if (this.currentCity === '') {
                                lead_called++
                            }
                        }
                    });

                    insidesales_lg_data.forEach(customLGData => {
                        if (customLGData.tele_assignee === user.user_name || customLGData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customLGData.lead_city)) {
                                lead_generated++

                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    if (this.currentInsideSalesType === "dead") {
                        insidesales_dead_data.forEach(customDeadData => {
                            if (customDeadData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customDeadData.lead_city)) {
                                    total_dead++
                                    if (customDeadData.lead_dead_reason === "call not answered")
                                        not_answered++
                                    else if (customDeadData.lead_dead_reason === "not interested")
                                        not_interested++
                                    else if (customDeadData.lead_dead_reason === "no budget")
                                        no_budget++
                                    else if (customDeadData.lead_dead_reason === "other city")
                                        other_city++
                                    else if (customDeadData.lead_dead_reason === "window shopping")
                                        window_shopping++
                                    else if (customDeadData.lead_dead_reason === "other")
                                        other_reason++

                                }
                                if (this.currentCity === '') {
                                    total_dead++
                                    if (customDeadData.lead_dead_reason === "call not answered")
                                        not_answered++
                                    else if (customDeadData.lead_dead_reason === "not interested")
                                        not_interested++
                                    else if (customDeadData.lead_dead_reason === "no budget")
                                        no_budget++
                                    else if (customDeadData.lead_dead_reason === "other city")
                                        other_city++
                                    else if (customDeadData.lead_dead_reason === "window shopping")
                                        window_shopping++
                                    else if (customDeadData.lead_dead_reason === "other")
                                        other_reason++
                                }
                            }
                        });
                    }
                    if (this.currentInsideSalesType === "pending") {
                        insidesales_pending_data.forEach(customPendingdData => {
                            if (customPendingdData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customPendingdData.lead_city)) {
                                    total_pending++

                                }
                                if (this.currentCity === '') {
                                    total_pending++
                                }
                            }
                        })
                        insidesales_updated_data.forEach(customUpdatedData => {
                            if (customUpdatedData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customUpdatedData.lead_city)) {
                                    if (customUpdatedData.lead_current_stage === "RNR")
                                        total_rnr++
                                    if (customUpdatedData.lead_current_stage === "Hold")
                                        total_hold++

                                }
                                if (this.currentCity === '') {

                                    if (customUpdatedData.lead_current_stage === "RNR")
                                        total_rnr++
                                    if (customUpdatedData.lead_current_stage === "Hold")
                                        total_hold++
                                }
                            }
                        })
                        insidesales_callback_data.forEach(customCallbackData => {
                            if (customCallbackData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customCallbackData.lead_city)) {
                                    total_callback++

                                }
                                if (this.currentCity === '') {
                                    total_callback++
                                }
                            }
                        })

                    }
                    // if (lead_generated > 0 || lead_patched > 0 || lead_called > 0 || not_answered>0 || not_interested>0 || no_budget>0 || other_city>0 || window_shopping>0 || other_reason>0 || total_dead>0 || total_pending>0 ||
                    //     total_rnr>0 || total_hold>0 || total_callback>0
                    //     ) {
                    insideSalesData.push({
                        lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.customStartDate + ' TO ' + this.state.customEndDate,
                        not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                        total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                        total_hcna: total_hc_na, total_hc_booked: total_hc_booked

                    })
                    // }

                }
            })
            // console.loge.log("insideSalesData",insideSalesData)
            this.setState({ reportData: insideSalesData })
        }
    }

    ChangeCampaignReportView = (details) => {
        this.setState({
            lead_campaign_view: 'details',
            campaignDetails: details
        })
    }

    HandleGetFirstCallResponse = (res) => {
        let tracking_arr = []
        if (res.data && res.data.leadlist && res.data.leadlist.rows && res.data.leadlist.rows.length > 0) {
            this.FirstCallData = res.data.leadlist.rows
            let total = 0;
            let totalPages = 0;
            total = res.data.leadlist.metarow.totalRecordsInDb

            totalPages = Math.ceil(total / this.state.numItemsPerPage);

            let number = this.state.setNo;
            if (number - 1 === 0) {
                this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            else {
                this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            this.RenderFirstCallDetails(res.data.leadlist.rows, this.state.currentSalesReportType)

        }
        else {
            this.setState({
                reportData: []
            })
        }
        this.setState({ getFirstCall: false, isOptionDisabled: false })
    }
    RenderFirstCallDetails(firstCallData, type) {
        let tracking_arr = []
        this.setState({ reportData: [] })
        firstCallData.map(element => {
            if (type === "Today") {
                //   alert(2)
                if (element.lead.date_time !== undefined && element.lead.date_time.includes(this.state.todayDate)) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
            else if (type === "Yesterday") {
                if (element.lead.date_time !== undefined && element.lead.date_time.includes(this.state.yesterdayDate)) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
            else if (type === "Last 7 Days") {
                if (element.lead.date_time !== undefined && element.lead.date_time <= this.state.todayDate + ' 23:59:59' && element.lead.date_time >= this.state.weekDate) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
            else if (type === "Custom") {

                if (element.lead.date_time !== undefined && element.lead.date_time <= this.state.customEndDate + ' 23:59:59' && element.lead.date_time >= this.state.customStartDate) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
        })
        this.setState({ reportData: tracking_arr, })
    }
    GetCustomDataCallDetails = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        this.calldetails_data = {
            'txtCallListStartDate': startDate,
            'txtCallListEndDate': endDate
        }
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate,
            getFirstCall: true,
            totalRecordsInDb: 0,
            reportData: [],
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,

        })

    }
    PatchDetailedReport = (details) => {
        // alert(JSON.stringify(details));
        let allowedLeadStages = [];
        if (sessionStorage.getItem('current_user_allowed_lead_stages')) {
            allowedLeadStages = sessionStorage.getItem('current_user_allowed_lead_stages');
            allowedLeadStages = allowedLeadStages.split(',');
        }
        // alert(allowedLeadStages);

        this.setState({
            patchView: 'details',
            patchDetailReport: details,
            profileStageData: allowedLeadStages
        })

    }
    PatchReportDefaultView = () => {
        this.setState({
            patchView: 'default',
            patchDetailReport: []
        })
    }
    HandleGetUserListResponse = (res) => {

        if (res.data && res.data.userlist && res.data.userlist) {
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({ getUser: false })
    }
    HandleGetAllCallsListSuccessResponse = (res) => {
        this.setState({
            getAllCalls: false
        })
        if (res && res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows.length > 0) {
            res.data.calllist.rows.forEach((element, i) => {

                // Lead Called
                if (element.date_created !== undefined && element.date_created.includes(this.state.todayDate)) {
                    this.todayExotelCalled.push(element)


                }
                else if (element.date_created !== undefined && element.date_created.includes(this.state.yesterdayDate)) {
                    this.yesterdayExotelCalled.push(element);

                }
                if (element.date_created !== undefined && element.date_created <= this.state.todayDate + ' 23:59:59' && element.date_created >= this.state.weekDate) {
                    this.weekExotelCalled.push(element)

                }
                if (element.date_created !== undefined && element.date_created <= this.state.todayDate + ' 23:59:59' && element.date_created >= this.state.monthDate) {
                    this.monthExotelCalled.push(element)

                }
            })
        }
    }
    render() {
        // console.log(" this.LeadSourceList",  this.TodayLeadGenerated)
        // console.log("current_user_profile",sessionStorage.getItem('current_user_profile'))
        return (
            <Container fluid>
                <div className="tele-caller-db-head"> Dashboard</div>
                <Option PatchReportDefaultView={this.PatchReportDefaultView} HandleUserInput={this.HandleUserInput}  {...this.props} mystate={this.state} ChangeViewReport={this.ChangeViewReport} />
                {this.state.currentViewType === "Default" ?
                    <LeadSourcereport {...this.props} mystate={this.state} salesReportType={this.salesReportType} ChangeSalesReportType={this.ChangeSalesReportType} GetCustomData={this.GetCustomData} HandleChangeDate={this.HandleChangeDate} />
                    : null}
                {this.state.currentViewType === "Marketing" ?
                    <Marketing {...this.props} mystate={this.state} salesReportType={this.salesReportType} ChangeSalesReportType={this.ChangeSalesReportType} GetCustomData={this.GetCustomData} HandleChangeDate={this.HandleChangeDate} />
                    : null}
                {this.state.currentViewType === "lead-tracking-report" ?
                    <LeadTrackingReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        // FirstCallData={this.FirstCallData}
                        ChangePage={this.ChangePage}
                        GetCustomDataCallDetails={this.GetCustomDataCallDetails}
                    />
                    : null
                }
                {this.state.currentViewType === "campaign-report" ?
                    <LeadCampaign
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        // ShowPatchDetails={this.ShowPatchDetails}
                        // ShowCallModal={this.ShowCallModal}
                        // GotoLeadEntry={this.GotoLeadEntry}
                        ChangeCampaignReportView={this.ChangeCampaignReportView}
                    />
                    : null
                }
                {this.state.currentViewType === "patch-report" ?
                    <PatchReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        PatchDetailedReport={this.PatchDetailedReport}
                    />
                    : null
                }
                {this.state.currentViewType === "inside-sales-report" ?
                    <InsideSalesReport mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                    /> : null}

                {this.state.getUser === true ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : ''}
                {((this.state.getLeadSource === true) ? GetLeadSource(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")}
                {(this.state.getReportData === true) ? AdminSalesReport(this.props.PnkStore, 'Administrator', 'prabhash', this.state.startDate, this.state.endDate, this.HandleGetReportResponse) : ''}
                {(this.state.getCustomData === true) ? AdminSalesReport(this.props.PnkStore, 'Administrator', 'prabhash', this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomDatatResponse) : ''}

                {((this.state.getCustomCallList === true) ? AllCallList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomAllCallsListSuccessResponse) : "")}
                {((this.state.getAllCalls === true) ? AllCallList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetAllCallsListSuccessResponse) : "")}
                {(this.state.getFirstCall === true) ? FirstCallConnectionByDate(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.setNo, this.state.setLength, this.calldetails_data, this.HandleGetFirstCallResponse) : ''}
            </Container>
        )
    }
}
export const MarketingReportPage = PnkConnect(reportPage, "");