import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSearch, faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Form, InputGroup, FormControl, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import Search from './search'
import User from '../assets/images/profile.png'
import { FilterPage } from './filter'
import AppUtils from '../apputils'
export default class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddlLead: '',
            showFilterWindow: false,
            showLogout: false,
        }
    }
    ShowFilter = () => {
        // var divsToHide = document.getElementsByClassName("data-table"); //divsToHide is an array

        // for (var i = 0; i < divsToHide.length; i++) {
        //     divsToHide[i].style.visibility = "hidden"; // or
        //     divsToHide[i].style.display = "none"; // depending on what you're doing
        // }
        this.setState({ showFilterWindow: true })
    }
    HideFilter = () => {
        this.setState({ showFilterWindow: false })
    }
    HandleRoute = (route) => {
        AppUtils.HandlePageClick(this.props, route);
    }
    ToggleLogout = () => {
        this.setState({
            showLogout: !this.state.showLogout
        })
    }
    HideLogout=()=>{
        this.setState({
            showLogout: false
        })
    }

    CallMethod = () => {
        this.props.GetAdvanceSearchData()
    }

    render() {
        
        return (
            <div className="header-main-div" onMouseLeave={()=>this.HideLogout()}>
                <Row>
                    <div className="alert alert-success" role="alert" id="crmSuccessAlert"
                        style={{ display: 'none', position: 'fixed', top: 0, width: '100%', zIndex: 1400 }}>
                        <strong id="spnSuccessMessageTitle"></strong> <span id="spnSuccessMessage"></span>
                    </div>
                    <div className="alert alert-danger" role="alert" id="crmWarningAlert"
                        style={{ display: 'none', position: 'fixed', top: 0, width: '100%', zIndex: 1400 }}>
                        <strong id="spnWarningMessageTitle"></strong> <span id="spnWarningMessage"></span>
                    </div>
                    <div className="navbar-header">
                        {this.state.showFilterWindow === false ? (
                            <Row style={{ height: '100%' }}>
                                <Col lg={4} xs={8} style={{ height: '100%' }}>

                                    <div className="nav-header-data">
                                        <div className="page-title">
                                            {this.props.showBackButton == true ?
                                                <div className="page-title-icon">
                                                    <FontAwesomeIcon icon={faArrowLeft} className="header-back-icon" onClick={() => this.HandleRoute(this.props.backButtonRoute)} />
                                                </div> : null}

                                            <h3 className="header-page-title">{this.props.title}</h3>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6} className="search-col hide-mob">
                                    {this.props.showSearch ?
                                        <Search HandleRoute={this.HandleRoute} CallMethod={this.CallMethod} /> : null}
                                </Col>
                                <Col lg={2} xs={2} className="search-col flex-end show-mob align-center" >
                                    <FontAwesomeIcon icon={faSearch} className="header-search-icon" onClick={() => this.ShowFilter()} />

                                </Col>
                                <Col lg={2} xs={2} className="search-col-img flex-end" style={{ height: '100%' }}>

                                    <img src={User} className="user-profile" id="dropdown-menu-align-right" id="user-image" onClick={() => this.ToggleLogout()} />

                                </Col>
                            </Row>) :
                            <Row style={{ height: '100%' }} >
                                <Col xs={10} style={{ height: '100%', display: 'flex', alignItems: 'center' }} className="mob-search-row">


                                    <InputGroup className="header-search-input-group">

                                        <FormControl id="inlineFormInputGroup" className="header-search-bar" />
                                        <InputGroup.Append >
                                            <InputGroup.Text className="header-search-append-text">
                                                <FontAwesomeIcon icon={faSearch} className="header-search-icon" />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>

                                </Col>
                                <Col xs={2} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>

                                    <Button variant="link" className="header-search-cancel" onClick={() => this.HideFilter()}>Cancel</Button>


                                </Col>


                            </Row>}
                    </div>
                </Row>
                {this.state.showLogout === true ?
                    <Row style={{ justifyContent: 'flex-end' }} onMouseLeave={()=>this.HideLogout()}>
                        <Col lg={2}>
                            <div className="user-options">
                                <div className="user-name-div">
                                    <FontAwesomeIcon icon={faUser} className="header-back-icon user-icon" />{sessionStorage.getItem('current_user_name')}</div>
                                <div className="user-action-div" onClick={() => this.props.LogOut()}>
                                    <FontAwesomeIcon icon={faPowerOff} className="header-back-icon user-icon" />Logout
                            </div>
                            </div>
                        </Col>
                    </Row> : null}
                {this.state.showFilterWindow === true ? (
                    <FilterPage HideFilter={this.HideFilter} HandleChange={this.HandleChange} mystate={this.state} {...this.props} HandleRoute={this.HandleRoute} />
                ) : null
                }

            </div>
        )
    }
}