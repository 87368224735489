import React from 'react'
import Pagination from 'react-bootstrap-4-pagination'
export default class PaginationPage extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {
        let pageArray=[]
        for(let i=1;i<=this.props.totalNumber;i++){
            pageArray.push(i)
        }

        return (
            <nav aria-label="Page navigation example">
  <ul class="pagination">
      {this.props.currentPageNumber>1?
       <li class="page-item" style={{cursor:'pointer'}}><a style={{cursor:'pointer'}} class="page-link" onClick={()=>this.props.ChangePage(this.props.currentPageNumber-1)}>Previous</a></li>:null}
      {pageArray.map(i=>
      i===this.props.currentPageNumber || i===this.props.currentPageNumber-1 || i===this.props.currentPageNumber-2 || i===this.props.currentPageNumber+1 || i===this.props.currentPageNumber+2?
      <li class="page-item" style={{cursor:'pointer',backgroundColor: i===this.props.currentPageNumber? '#d4515c':'#fff',color: i===this.props.currentPageNumber? '#fff':'#d4515c'}}><a style={{cursor:'pointer',backgroundColor: i===this.props.currentPageNumber? '#d4515c':'#fff',color: i===this.props.currentPageNumber? '#fff':'#d4515c'}} class="page-link" onClick={()=>this.props.ChangePage(i)}>{i}</a></li>:null
        )}
   
   {this.props.currentPageNumber>1 && this.props.currentPageNumber<this.props.totalNumber?
    <li class="page-item" style={{cursor:'pointer'}}><a class="page-link" style={{cursor:'pointer'}} onClick={()=>this.props.ChangePage(this.props.currentPageNumber+1)}>Next</a></li>:null}
  </ul>
</nav>
            // <Pagination
            //     totalPages={this.props.totalNumber}
            //     currentPage={this.props.currentPageNumber}
            //     showMax={5}
            //     // prevNext
            //     activeBgColor="#d4515c"
            //     activeBorderColor="#d4515c"
            //     color="#d4515c"
            //     onClick={(page) => this.props.ChangePage(page)

            //     }
            // />
        )
    }
}
