import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import Options from './options'
import Table from './profile'
import Footer from './footer'
import { ProfileEntryPage } from '../Profile-Entry'
import { Container } from 'react-bootstrap'
import { GetUserProfileList, DeleteUserProfile, SearchProfileList } from '../../models/users'
import $ from 'jquery';
class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            getProfileData: true,
            getProfilDataById: false,
            currentSeletedProfileId: '',
            deleteData: false,
            getProfileById: false,
            txtUserSearch: '',
            getSearch: false

        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Profile');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.editDataObj = {}
        this.data = {}
    }
    ShowProfileModal = () => {
        this.props.componentprops.OpenModal(ProfileEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Add User Profile' }, { action: 'profile', name: 'Profile', close: this.CloseModal })
    }
    HandleProfileGetResponse = (res) => {
        if (res.data && res.data.profilelist && res.data.profilelist.rows) {
           
            this.props.PnkStore.SetData('profilelist', res.data.profilelist);
            this.props.PnkStore.BroadCast();
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Profile List Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
        this.setState({
            getProfileData: false
        })

    }
    HandleUserAction = (e, id, status) => {
        const val = e.target.value;
        const name = e.target.name;
        if (val === 'Detail') {

            let action = { id: id, action: val, status: status };
            this.props.componentprops.OpenModal(ProfileEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Profile Detail' }, { action: action, name: 'Profile', close: this.CloseModal })
        }
        else if (val === 'Delete') {
            if (window.confirm("Are you sure?"))
                this.setState({ deleteData: true, currentSeletedProfileId: id, getProfileData: false });
        }

        this.setState({ [name]: '' });
    }
    CloseModal = () => {
        this.props.componentprops.HandleCloseModal();
    }
    HandleProfileDeleteResponse = (res) => {
       
        this.setState({
            deleteData: false,
            getProfileData: true
        })
    }
    HandleSuccessSearchProfileListResponse = (res) => {
        if (res !== false) {
            if (res.data && res.data.profilelist && res.data.profilelist.rows) {
                this.props.PnkStore.SetData('profilelist', res.data.profilelist);
                this.props.PnkStore.BroadCast();
            }
            this.setState({
                getSearch: false
            })
        }


    }
    HandleUserSearch = (e) => {
        let val = e.target.value;
        let name = e.target.name;
       
        this.setState({
            [name]: val,

        })
        if (val.length >= 3) {
            this.setState({

                getSearch: true
            })
        }
        else {
            this.setState({

                getSearch: false,
            })
        }
        if (val === '') {
            this.setState({

                getSearch: false,
                getProfileData: true
            })
        }

    }

    render() {
      
        return (
            <Container fluid className="data-table">
                <Options ShowProfileModal={this.ShowProfileModal} HandleUserSearch={this.HandleUserSearch} mystate={this.state} />
                <Table mystate={this.state} ShowCallModal={this.ShowCallModal} {...this.props} HandleUserAction={this.HandleUserAction} />
                <Footer ShowProfileModal={this.ShowProfileModal} />
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
                {(this.state.deleteData === true) ? DeleteUserProfile(this.props.PnkStore, this.state.currentSeletedProfileId, this.HandleProfileDeleteResponse) : ''}
                {this.state.getSearch === true ? SearchProfileList(this.props.PnkStore, 'profile_name', this.state.txtUserSearch, this.HandleSuccessSearchProfileListResponse) : ''}
            </Container >
        )
    }
}
export const ProfilePage = PnkConnect(Profile, "searchFullData");
