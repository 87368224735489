import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Form, Image } from 'react-bootstrap'


export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {

        this.props.SortData(e, e.target.value)
    }
    render() {


        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                            <Col lg={2} sm={4} xs={4} className="flex-left col-padding-0">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentViewType'
                                    id='currentViewType'
                                    value={this.props.mystate.currentViewType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeViewReport(e)}>
                                    <option value="Default">Default</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="lead-tracking-report">Lead Tracking Report</option>
                                    <option value="campaign-report">Lead Campaign Report</option>
                                    <option value="patch-report">Patch Report</option>
                                    <option value="inside-sales-report">Inside Sales Report</option>
                                </Form.Control>
                            </Col>
                            {/* {this.props.mystate.currentViewType === "Marketing" ?
                            <Col lg={2} md={3} xs={3} className="flex-left col-padding-0">


                                <Form.Control as="select" className="dashboard-option-select"
                                    name='ddlPreferredCity'
                                    id='ddlPreferredCity'
                                    onChange={this.props.HandleUserInput}
                                    pnkdisplayerrormsg='Select City'
                                    value={this.props.mystate.ddlPreferredCity}
                                    style={{ padding: '4px', width: '100%' }}>
                                    <option value="-1" disabled selected>Select City</option>
                                    <option value=''>No City</option>
                                    {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                        this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                            <option value={item.city_name}>{item.city_name}</option>) : null
                                    }

                                </Form.Control>

                            </Col>:null} */}
                        </Row>
                    </div>
                    <div>
                        <Col lg={12}>
                            {this.props.mystate.currentViewType === 'patch-report' && this.props.mystate.patchView === 'details' ?
                                <Col lg={2} md={3} xs={3} className="flex-left col-padding-0  padding-form">
                                    <button className='btn btn-secondary' onClick={() => this.props.PatchReportDefaultView()}>Go Back</button></Col> : null
                            }
                        </Col>
                    </div>
                </Col>
            </Row>
        )
    }
}