export const vegList = [
    {
        id: '1',
        name: 'Tomato',
        key: 'Tomato'
    },
    {
        id: '2',
        name: 'Brinjal',
        key: 'Brinjal'
    },
    {
        id: '3',
        name: 'Ladies Finger',
        key: 'Ladies_Finger'
    },
    {
        id: '4',
        name: 'Beetroot',
        key: 'Beetroot'
    },
    {
        id: '5',
        name: 'Beans',
        key: 'Beans'
    },
    {
        id: '6',
        name: 'String beans',
        key: 'String_beans'
    },
    {
        id: '7',
        name: 'Capsicum',
        key: 'Capsicum'
    },
    {
        id: '8',
        name: 'Knol khol',
        key: 'Knol_khol'
    },
    {
        id: '9',
        name: 'Cabbage',
        key: 'Cabbage'
    },
    {
        id: '10',
        name: 'Cucumber',
        key: 'Cucumber'
    },
    {
        id: '11',
        name: 'Carrot',
        key: 'Carrot'
    },
    {
        id: '12',
        name: 'Radish',
        key: 'Radish'
    },

]
export const greenList = [
    {
        id: '1',
        name: 'Mint',
        key: 'Mint'
    },
    {
        id: '2',
        name: 'Palak',
        key: 'Palak'
    },
    {
        id: '3',
        name: 'Curly kale',
        key: 'Curly_kale'
    },
    {
        id: '4',
        name: 'Black kale ',
        key: 'Black_kale '
    },
    {
        id: '5',
        name: 'Curry leaf',
        key: 'Curry_leaf'
    },
    {
        id: '6',
        name: 'Cherry tomatoes',
        key: 'Cherry_tomatoes'
    },
    {
        id: '7',
        name: 'Chillies / jalapenos',
        key: 'Chillies_jalapenos'
    },
]
export const herbList = [
    {
        id: '1',
        name: 'Parsely',
        key: 'Parsely'
    },
    {
        id: '2',
        name: 'Dill',
        key: 'Dill'
    },
    {
        id: '3',
        name: 'Tulsi',
        key: 'Tulsi'
    },
    {
        id: '4',
        name: 'Italian Basil',
        key: 'Italian_Basil'
    },
    {
        id: '5',
        name: 'Rosemary',
        key: 'Rosemary'
    },
    {
        id: '6',
        name: 'Oregano',
        key: 'Oregano'
    },

]
export const fruitList = [
    {
        id: '1',
        name: 'Lemon',
        key: 'Lemon'
    },
    {
        id: '2',
        name: 'Pomegranate',
        key: 'Pomegranate'
    },
    {
        id: '3',
        name: 'Papaya ',
        key: 'Papaya '
    },
    {
        id: '4',
        name: 'Guava',
        key: 'Guava'
    },
    {
        id: '5',
        name: 'Sweet Lime',
        key: 'Sweet Lime'
    },


]