import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import Options from './options'
import Table from './bookings'
import Footer from './footer'
import { ProfileEntryPage } from '../Profile-Entry'
import { Container } from 'react-bootstrap'
import { GetUserProfileList, GetUserProfileDetailsById } from '../../models/users'
import PhotoAlbum from './photolist'
class Booking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            getProfileData: true,
            getProfilDataById: false,
        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Booking');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.editDataObj = {}
        this.data = {}
    }
    ShowAlbum = () => {
        this.props.componentprops.OpenModal(PhotoAlbum, 'lg', { modalClass: 'profile-entry-modal', headerClass: 'booking-album-header', titleClass: 'profile-entry-title', title: 'Photos' }, { action: 'Album', name: 'Album', close: this.CloseModal })


    }
    HandleProfileGetResponse = (res) => {
        if (res.data && res.data.profilelist && res.data.profilelist.rows) {
            this.props.PnkStore.SetData('profilelist', res.data.profilelist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({
            getProfileData: false
        })

    }
    HandleUserAction = (e, id, status) => {
        const val = e.target.value;
        const name = e.target.name;
        if (val === 'Detail') {
            let action = { id: id, action: val, status: status };
            this.props.componentprops.OpenModal(ProfileEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Profile Detail' }, { action: action, name: 'Profile' })
        }
        else if (val === 'Delete') {
            if (window.confirm("Are you sure?"))
                this.setState({ deleteData: true, currentSeletedProfileId: id, getProfile: false });
        }
        this.setState({ [name]: '' });
    }
    CloseModal = () => {
        this.props.componentprops.HandleCloseModal();
    }
    HideModal = () => {
        this.props.componentprops.currentAction.close();
    }
    render() {
        return (
            <Container fluid className="data-table">
                <Options ShowAlbum={this.ShowAlbum} />
                <Table mystate={this.state} ShowAlbum={this.ShowAlbum} {...this.props} HandleUserAction={this.HandleUserAction} HideModal={this.HideModal} />
                <Footer ShowAlbum={this.ShowAlbum} />
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
            </Container >
        )
    }
}
export const BookingPage = PnkConnect(Booking, "searchFullData");
