import React, { Component } from 'react';
export default class AppUtils extends Component {
    constructor(props) {
        super(props);
        //alert("hh"+JSON.stringify(props.history));
    }

    // static HandlePageClick(e, target) {
    //     history.push('/'+target);
    // }
    static HandlePageClick(e, target) {
        // alert(JSON.stringify((e)))
        // e.history.push(target);
        // alert("hh" + JSON.stringify(e));
        // if(e.componentprops == undefined)
        //  e = this.props

        // if (target.includes('meeting')) {
        //     document.getElementById('zmmtg-root').style.display = 'block'
        // }
        // else {
        //     document.getElementById('zmmtg-root').style.display = 'none'
        // }

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if (target !== "") {
            // if (e.componentprops != undefined) {
            //     if (target == "/")
            //         e.componentprops.history.push(target);
            //     else
            //         e.componentprops.history.push("/" + target);
            // }
            // else {
            e.history.push("/" + target);
            // }
            //this.props.history.push(target);
            //this.props.history.push(e);
        }
    }
    //const serverPath = 'http://192.168.43.72:3000/images/uploads/property/';

}