import React from 'react'
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { bookingstages, leadstages, routes } from './data'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
export default class ProfileEntry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: [{ name: 'Srigar', id: 1 }, { name: 'Sam', id: 2 }]
        }
    }

    render() {
       
        return (

            <PnkHtmlForm id="frmProfileEntry" onsubmit={this.props.SaveProfileData} submitbtn="btnProfileEntry">

                <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                    <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                </div>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Profile Name</Form.Label>
                    <Form.Control type="text" placeholder="Profile Name" className="profile-entry-controls"
                        name="txtUserProfileName" id="txtUserProfileName" pnkvalidate="forceentry"
                        pnkvalue={this.props.editDataObj.txtUserProfileName}
                        value={this.props.editDataObj.txtUserProfileName}
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid Profile Name" />
                </Form.Group>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Profile Description</Form.Label>
                    {/* <Form.Control placeholder="Profile Description" className="profile-entry-text-area" as="textarea" rows="5" */}
                    <Form.Control placeholder="Profile Description" className="profile-entry-text-area" as="textarea" rows="5"
                        name="txtUserProfileDescription" id="txtUserProfileDescription"
                        pnkvalue={this.props.editDataObj.txtUserProfileDescription}
                        value={this.props.editDataObj.txtUserProfileDescription}
                        onChange={this.props.HandleUserInputs} />

                </Form.Group>
                <Form.Group >
                    <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedRoutesStatus}>
                        <InputLabel htmlFor="ddlAllowedRoutes" className="profile-entry-label">Assign Route Access</InputLabel>
                        <Select
                            value={this.props.mystate.ddlAllowedRoutes}
                            pnkvalue={this.props.editDataObj.ddlAllowedRoutes}
                            onChange={this.props.HandleUserInputs}
                            inputProps={{
                                name: 'ddlAllowedRoutes',
                                id: 'ddlAllowedRoutes',
                                pnkvalidate: 'forceselect',
                                pnkdisplayerrormsg: 'Select one'
                            }}
                            className="profile-entry-controls"
                            multiple={true}
                            placeholder="Assign Route Access"
                        >
                            <MenuItem value="-1" disabled>
                                <b>Select</b>
                            </MenuItem>
                            {routes.map((item) => (
                                <MenuItem key={item.name} value={item.name}>

                                    {item.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Form.Group>
                <Row>
                    <Col xs={12}>
                        <Form.Group >
                            <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedLeadStagesStatus}>
                                <InputLabel htmlFor="ddlAllowedLeadStages" className="profile-entry-label">Assign Lead Stages</InputLabel>
                                <Select
                                    value={this.props.mystate.ddlAllowedLeadStages}
                                    pnkvalue={this.props.editDataObj.ddlAllowedLeadStages}
                                    onChange={this.props.HandleUserInputs}
                                    inputProps={{
                                        name: 'ddlAllowedLeadStages',
                                        id: 'ddlAllowedLeadStages',
                                        pnkvalidate: 'forceselect',
                                        pnkdisplayerrormsg: 'Select one'
                                    }}
                                    className="profile-entry-controls"
                                    multiple={true}
                                    placeholder="Assign Route Access"
                                >
                                    <MenuItem value="-1" disabled>
                                        <b>Select</b>
                                    </MenuItem>
                                    {leadstages.map((item) => (
                                        <MenuItem key={item.name} value={item.name}>

                                            {item.name}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group >
                            <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlAllowedBookingStagesStatus}>
                                <InputLabel htmlFor="ddlAllowedBookingStages" className="profile-entry-label">Assign Booking Stages</InputLabel>
                                <Select
                                    value={this.props.mystate.ddlAllowedBookingStages}
                                    pnkvalue={this.props.editDataObj.ddlAllowedBookingStages}
                                    onChange={this.props.HandleUserInputs}
                                    inputProps={{
                                        name: 'ddlAllowedBookingStages',
                                        id: 'ddlAllowedBookingStages',
                                        pnkvalidate: 'forceselect',
                                        pnkdisplayerrormsg: 'Select one'
                                    }}
                                    className="profile-entry-controls"
                                    multiple={true}
                                    placeholder="Assign Route Access"
                                >
                                    <MenuItem value="-1" disabled>
                                        <b>Select</b>
                                    </MenuItem>
                                    {bookingstages.map((item) => (
                                        <MenuItem key={item.name} value={item.name}>

                                            {item.name}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group >
                    <FormControl variant="outlined" className="width_100" disabled={this.props.mystate.ddlUserProfileStatusStatus}>

                        <InputLabel htmlFor="ddlAllowedBookingStages" className="profile-entry-label">User Status</InputLabel>
                        <Select
                            value={this.props.mystate.ddlUserProfileStatus}
                            pnkvalue={this.props.editDataObj.ddlUserProfileStatus}
                            onChange={this.props.HandleUserInputs}
                            inputProps={{
                                name: 'ddlUserProfileStatus',
                                id: 'ddlUserProfileStatus',
                                pnkvalidate: 'forceselect',
                                pnkdisplayerrormsg: 'Select one',
                                placeholder: "Age"
                            }}
                            className="profile-entry-controls"
                        >
                            <MenuItem value="-1" disabled>
                                <b>Select</b>
                            </MenuItem>
                            <MenuItem value='1'
                            >Active</MenuItem>
                            <MenuItem value='0'
                            >Inactive</MenuItem>
                        </Select>

                    </FormControl>
                </Form.Group>

                <div className="profile-btn-div">
                    <Row className="flex-end">
                        <Col lg={3} xs={5}>
                            <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.HideProfileModal}>Cancel</Button>
                        </Col>
                        <Col lg={2} xs={5}>
                            <button className="profile-entry-save-btn pnkvalidatesubmit" name="Save" id="btnProfileEntry" onClick={() => this.props.SaveProfileData}>{this.props.mystate.showButtonType}</button>
                            {/* <Button variant="light" type="button" className="profile-entry-save-btn pnkvalidatesubmit" id="btnProfileEntry" onClick={this.props.SaveProfileData}  > Save</Button> */}
                        </Col>
                    </Row>
                </div>
            </PnkHtmlForm>
        )
    }
}