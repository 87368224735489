import React, {useContext} from 'react'
import { Row, Col, Button, ButtonGroup, Form, Table,Card } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
function ContextAwareToggle({ children, eventKey, callback }) {

    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    if (isCurrentEventKey) {
        return <FontAwesomeIcon icon={faMinus} className="control-icons" onClick={decoratedOnClick} />
    }
    else {
        return (

            <FontAwesomeIcon icon={faPlus} className="control-icons" onClick={decoratedOnClick} />

        );
    }
}
export default class HCTracker extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        console.log("report",this.props.mystate.reportData)
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {/* {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === 'Custom' ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )} */}
                                </ButtonGroup>
                                {/* {this.props.mystate.currentSalesReportType === "Custom" ? */}
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomMeetingStartDate" id="txtCustomMeetingStartDate"
                                            selected={this.props.mystate.txtCustomMeetingStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomMeetingStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomMeetingEndDate" id="txtCustomMeetingEndDate"
                                            selected={this.props.mystate.txtCustomMeetingEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomMeetingEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetMeetingCustomData()}>Apply</Button>



                                    </div> 
                                    {/* : null} */}
                            </div>
                        </Row>
                        <div className="lead-table-div">
                            <div className="home-container">
                           
                                <div className="mob-container">
                                <Accordion >
                                {console.log(this.props.mystate.reportData)}
                           {this.props.mystate.reportData && this.props.mystate.reportData.length>0?
                                    this.props.mystate.reportData.map((item,i)=>
                                        <Card className="faq-service-card">
                                        <Accordion.Toggle as={Card.Header} eventKey={i+1} className="faq-service-card-head">
                                            {item.date_time}
                                         
                                            <ContextAwareToggle eventKey={i+1}>  <FontAwesomeIcon icon={faPlus} className="control-icons" /></ContextAwareToggle>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={i+1}>
                                            <Card.Body className="faq-card-body">
                                                <div className='table-main'>
                                                   
                                                {item.list && item.list.length>0? item.list.map(owner=>
                                                <React.Fragment>
                                                 <div className='table-head-main'>
                                                
                                                <div >
                                                   <div className="meeting-leadowner"> {owner.lead_owner}</div>
                                                 
                                                    {owner.user_details && owner.user_details.length>0?owner.user_details.map(user=> 
                                                      
                                                    
                                                 <div onClick={()=>this.props.GotoLeadEntry(user.id)} className="table-body"  style={{background: user.type ==="HC"?'#89e6b7':'#f7e0c2',cursor:'pointer'}}>
                                                 
                                                    {user.client_name} ( {user.type} ) <br/>
                                                    {user.area!==null && user.area!==undefined && user.area!==''? user.area :null}{user.area!==null && user.area!==undefined && user.area!==''?<br/>:null}
                                                    {user.city!==null && user.city!==undefined && user.city!==''? user.city:null}{user.city!==null && user.city!==undefined && user.city!==''? <br/>:null}
                                                    {user.time}
                                                
                                                </div>
                                                ):''}
                                                </div>
                                                </div>
                                              
                                               
                                               
                                                </React.Fragment>
                                                ):''}
                                                </div>
                                                {/* </div> */}
                                            {/* <Table striped bordered hover variant="dark">
  <thead>
    <tr>
      {item.list && item.list.length>0? item.list.map(owner=>
       <th>{owner.lead_owner}</th>


      
      ):''}
     
    </tr>
  </thead>
  
    <tr>
    {item.list && item.list.length>0? item.list.map(owner=>
        owner.user_details && owner.user_details.length>0?owner.user_details.map(user=>
         <td>{user.client_name}</td>
        ):''
    ):''}
    </tr>
 
</Table> */}
                                                
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                        ):''}
                
 
</Accordion>
</div>
</div>
</div>
                      </div>
                </Col>
            </Row>

        )
    }
}