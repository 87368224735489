import Home from '../assets/images/Home.svg'
import Activities from '../assets/images/Activities.svg'
import Lead from '../assets/images/Leads.svg'
import Booking from '../assets/images/Booking.svg'
import Audit from '../assets/images/Audit.svg'
import Call_Plan from '../assets/images/Call_Plan.svg'
import Property_Master from '../assets/images/Property_Master.svg'
import Reports from '../assets/images/Reports.svg'
import Incentives from '../assets/images/Incentives.svg'
import IncomingCalls from '../assets/images/IncomingCalls.svg'
import Setup from '../assets/images/Setup.svg'
import Notifications from '../assets/images/notifications.svg'
import Archive from '../assets/images/archive.svg'
export const routeData = [
    {
        id: 1,
        routeName: 'Dashboard',
        routePath: 'dashboard',
        iconName: Home,
        isSubMenu: 'no',
    },
   
    // {
    //     id: 2,
    //     routeName: 'Activities',
    //     routePath: '',
    //     iconName: Activities
    // },
    {
        id: 3,
        routePath: 'lead-entry',
        routeName: 'Lead List',
        iconName: Lead,
        isSubMenu: 'yes',
        subMenuList: [
            {
                subMenuName: "Lead Entry",
                subMenuRoutePath: 'lead-entry',
                routeName: 'Lead Entry',
            },
            {
                subMenuName: 'Leads List',
                subMenuRoutePath: 'leads',
                routeName: 'Lead List',
            }]
    },
    {
        id: 4,
        routeName: 'Exotel Calls',
        routePath: 'exotel-calls',
        iconName: Call_Plan,
        isSubMenu: 'no',
    },
    {
        id: 4,
        routeName: 'Reports',
        routePath: 'reports',
        iconName: Reports,
        isSubMenu: 'no',
    },
    // {
    //     id: 4,
    //     routeName: 'Booking',
    //     routePath: '/lead-entry',
    //     iconName: Booking
    // },
    // {
    //     id: 5,
    //     routeName: 'Audit',
    //     routePath: '',
    //     iconName: Audit
    // },
    // {
    //     id: 6,
    //     routeName: 'Pre-day Call Automation',
    //     routePath: '',
    //     iconName: Call_Plan
    // },
    // {
    //     id: 7,
    //     routeName: 'Call Plan Automation',
    //     routePath: '',
    //     iconName: Call_Plan
    // },
    // {
    //     id: 8,
    //     routeName: 'Property Master',
    //     routePath: '',
    //     iconName: Property_Master
    // },
    // {
    //     id: 9,
    //     routeName: 'Reports',
    //     routePath: '/',
    //     iconName: Reports
    // },
    // {
    //     id: 10,
    //     routeName: 'Incentives',
    //     routePath: '/',
    //     iconName: Incentives
    // },
    // {
    //     id: 11,
    //     routeName: 'Exotel Calls',
    //     routePath: '/',
    //     iconName: IncomingCalls
    // },
    // {
    //     id: 12,
    //     routeName: 'Incoming Calls',
    //     routePath: '/',
    //     iconName: IncomingCalls
    // },
    {
        id: 13,
        routePath: 'hc-list',
        routeName: 'HC List',
        iconName: Lead,
        isSubMenu: 'no'
    },
    {
        id: 14,
        routeName: 'Settings',
        routePath: '/',
        iconName: Setup,
        isSubMenu: 'yes',
        subMenuList: [
            // {
            //     subMenuName: "Lead Sources",
            //     subMenuRoutePath: 'lead-source-entry',
            //     routeName: 'Lead Sources List',
            // },
            {
                subMenuName: 'Users',
                subMenuRoutePath: 'users',
                routeName: 'User List',
            },
            {
                subMenuName: 'Profiles',
                subMenuRoutePath: 'profiles',
                routeName: 'Profiles',
            },
            {
                subMenuName: 'Entry',
                subMenuRoutePath: 'entries',
                routeName: 'New Entry',
            },
            {
                subMenuName: 'Lead Source',
                subMenuRoutePath: 'lead-source',
                routeName: 'Lead Sources List',
            },
            // {
            //     subMenuName: 'Reports',
            //     subMenuRoutePath: 'dashboard',
            //     routeName: 'Reports',
            // },
            // {
            //     subMenuName: 'My Account',
            //     subMenuRoutePath: '',
            //     routeName: 'Activities',
            // },
            // {
            //     subMenuName: 'Change Password',
            //     subMenuRoutePath: '',
            //     routeName: 'Activities',
            // },

        ]
        // ["Lead Sources", 'Users', 'Profiles', 'My Account', 'Change Password']
    },
    // {
    //     id: 14,
    //     routeName: 'Notifications',
    //     routePath: '/',
    //     iconName: Notifications
    // },
    // {
    //     id: 15,
    //     routeName: 'Archive',
    //     routePath: '/',
    //     iconName: Archive
    // },

]