import React from 'react'
import { Row, Col, Button, ButtonGroup, Form, Image } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../../Pagination'
export default class SalesReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
  
   
    render() {

     
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                     
                        <div className="lead-table-div">
                      
                            <div className="home-container">
                                <div className="mob-container">
                                    <Row className="mob-row-align mob-row-color">
                                        <Col lg={1} className="hide-mob">
                                      
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                          
                                        </Col>

                                        <Col lg={1} xs={3}>
                                            <h3 className="table-head-lead ">Date</h3>
                                        </Col>

                                        <Col lg={1} xs={3}>
                                            <h3 className="table-head-lead">Lead Owner</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead ">Team Lead</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <div style={{ display: 'flex' }} >
                                                <h3 className="table-head-lead dashboard-margin-5">LP</h3>

                                                <h3 className="table-head-lead dashboard-margin-5 text-end">HB</h3>
                                            </div>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <div style={{ display: 'flex' }} >
                                                <h3 className="table-head-lead dashboard-margin-5">HC Completed</h3>

                                                <h3 className="table-head-lead dashboard-margin-5 text-end">Follow Up</h3>
                                            </div>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Pending Design Handover </h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead ">Handover to Design</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Estimate Pending</h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob"><h3 className="table-head-lead ">Proposal Sent </h3></Col>
                                        <Col lg={1} xs={4} className="">
                                            <h3 className="table-head-lead">Booking Amount Received </h3>
                                        </Col>
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead ">Project Closed  </h3>
                                        </Col>




                                    </Row>
                                    <div>
                                        {this.props.mystate.reportData.length > 0 ?
                                            this.props.mystate.reportData.map(
                                                (item, i) => (
                                                    <div className="lead-table-data-user">
    
                                                        { ((i + 1) % 2 === 0) ?
                                                                (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div> {i + 1}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={1} className="" xs={3}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="">{item.date_time}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="" xs={3}>

                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.lead_owner}</div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div>{item.reporting_to}</div>
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data flex-display">
                                                                                <div className="text-overflow dashboard-margin-5">{item.total_lead_patched}</div>
                                                                                <div className="text-overflow dashboard-margin-5 text-end">{item.total_hc_booked}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data flex-display">
                                                                                <div className="text-overflow dashboard-margin-5">{item.total_hc_completed}</div>
                                                                                <div className="text-overflow dashboard-margin-5 text-end">{item.total_follow}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.total_pending_design}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.total_handover_to_design}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob">
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.total_estimate_pending}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" >
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.total_proposal_sent}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="" xs={4}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.total_booking_amount_received}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col lg={1} className="hide-mob" xs={3}>
                                                                        <div className="table-data-user">
                                                                            <div className="lead-data">
                                                                                <div className="text-overflow">{item.total_project_closed}</div>

                                                                            </div>
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs={2} className="show-mob">
                                                                        <FontAwesomeIcon icon={faEye} className="dashbord-view-more-icon" onClick={() => this.props.ShowSalesInsideModal(item)} />
                                                                    </Col>

                                                                </Row>) : (
                                                                    <Row className="table-items-user" style={{ background: '#ffffff' }}>

                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div> {i + 1}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>

                                                                        <Col lg={1} className="" xs={3}>
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="">{item.date_time}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="" xs={3}>

                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{item.lead_owner}</div>
                                                                                </div>
                                                                            </div>

                                                                        </Col>

                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div>{item.reporting_to}</div>
                                                                                </div>

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob" >
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data flex-display">
                                                                                    <div className="text-overflow dashboard-margin-5">{item.total_lead_patched}</div>
                                                                                    <div className="text-overflow dashboard-margin-5  text-end" >{item.total_hc_booked}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob" >
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data flex-display">
                                                                                    <div className="text-overflow dashboard-margin-5">{item.total_hc_completed}</div>
                                                                                    <div className="text-overflow dashboard-margin-5  text-end">{item.total_follow}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob" >
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.total_pending_design}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob" >
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.total_handover_to_design}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob">
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.total_estimate_pending}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob" >
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.total_proposal_sent}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="" xs={4}>
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.total_booking_amount_received}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col lg={1} className="hide-mob" xs={3}>
                                                                            <div className="table-data-user">
                                                                                <div className="lead-data">
                                                                                    <div className="text-overflow">{item.total_project_closed}</div>

                                                                                </div>
                                                                            </div>

                                                                        </Col>
                                                                        <Col xs={2} className="show-mob">
                                                                            <FontAwesomeIcon icon={faEye} className="dashbord-view-more-icon" onClick={() => this.props.ShowSalesInsideModal(item)} />
                                                                        </Col>

                                                                    </Row>)}

                                                    </div>




                                                )) : null}
                                      

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}