import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
export default class BulkUpload extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            // <Modal

            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            //     show={this.props.visible}
            //     className="bulk-modal"
            // >

            //     <Modal.Body>
            <React.Fragment>

                <h3 className="bulk-modal-text">Upload Bulk User</h3>
                <div className="bulk-modal-file-up-div">
                    <Form>
                        <Form.File
                            id="bulk-modal-file"
                            className="bulk-modal-file"
                            label="Custom file input"
                            lang="en"
                            custom
                        />
                    </Form>
                </div>
                <div className="bulk-modal-btn-div">

                    <Button variant="light" className="bulk-modal-cancel-btn" >Cancel</Button>

                </div>
            </React.Fragment>
            //     </Modal.Body>
            // </Modal>


        )
    }
} 