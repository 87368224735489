import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
export default class ViewData extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <Modal show={true}
                size="sm"
                centered
                id="custom-date-picker"
                onHide={this.props.HideCustomDateModal}
            >
                <Modal.Header closeButton style={{ background: '#F2F2F2' }}>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={5}>

                            <DatePicker
                                name="txtCustomStartDate" id="txtCustomStartDate"
                                selected={this.props.mystate.txtCustomStartDate}
                                onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                className="custom-date-picker"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select date"
                            />
                        </Col>
                        <Col xs={2} className="custom-center">
                            <span>To</span>
                        </Col>
                        <Col xs={5}>
                            <DatePicker
                                name="txtCustomEndDate" id="txtCustomEndDate"
                                selected={this.props.mystate.txtCustomEndDate}
                                onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                className="custom-date-picker"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select date"
                            />
                        </Col>

                    </Row>
                    <Row style={{ paddingTop: 24 }}>
                        <Col xs={12}>
                            <Button variant="light" className="custom-apply-btn-modal" style={{ width: '100%' }} onClick={() => this.props.GetCustomData()}>Apply</Button>
                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>
            // <div>
            //     <FontAwesomeIcon icon={faTimes} className="modal-header-close" onClick={() => this.props.currentAction.close()} />



            // </div>
        )
    }
} 