
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { routeData } from './route-data'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarFooter, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { faHeart, faGem, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import Logo from '../assets/images/Final_logo_NEO_ROOTS.png'
import { Link } from 'react-router-dom'
import AppUtils from "../apputils";
import { PnkConnect } from "../pnk-react/pnk-miniredux/";
import { ReminderPage } from '../Components/Reminder'
import axios from 'axios'
export default class sideBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
            collapsed: true,
            AlloweRoteList: [],
            lead_id: '',
            mode: 'web'
        };
        this.allowedRoutes = [];
    }

    updateModal(isVisible) {
        this.state.isVisible = isVisible;
        this.forceUpdate();
    }
    ToggleSideBar = (source) => {
        this.setState({ collapsed: !this.state.collapsed, mode: source })
        if (source === "mob") {
            document.getElementById("side-content").style.display = "block"
            document.getElementById('sidebar').style.backgroundColor = "#eac8bd";
            if (this.state.collapsed === false) {
                document.getElementById("side-content").style.display = "none"
                document.getElementById('sidebar').style.backgroundColor = "transparent";


            }
        }

    }

    HandleRoute = (route) => {
        sessionStorage.removeItem("origin");
        sessionStorage.removeItem("current_op");
        sessionStorage.removeItem("current_type");
        sessionStorage.removeItem("meeting_start");
        sessionStorage.removeItem("meeting_end");
        localStorage.removeItem("currentLeadId")
        localStorage.removeItem("rowOffset")
        
        AppUtils.HandlePageClick(this.props.componentprops, route);
        this.setState({ collapsed: true })
        if (this.state.mode === "mob") {
            document.getElementById("submenu-link").style.display = 'none'
            for (let el of document.querySelectorAll('.sb-icon-items')) el.style.visibility = 'hidden';
            document.getElementById('sidebar').style.backgroundColor = "transparent";
        }
    }
    componentWillMount = () => {
        // componentDidMount1 = () => {
        if (sessionStorage.getItem('current_user_allowed_routes')) {

            this.allowedRoutes = sessionStorage.getItem('current_user_allowed_routes');
            this.allowedRoutes = this.allowedRoutes.split(',');
            this.props.PnkStore.SetData('allowed_routes', this.allowedRoutes);
        }
        if (sessionStorage.getItem('current_user_profile') == 'Telecalling' || sessionStorage.getItem('current_user_profile') == 'Administrator' || sessionStorage.getItem('current_user_profile') == 'Sales' ||sessionStorage.getItem('current_user_profile') == 'Sales TL' )
            this.GetCallBackData();
    }
    CloseReminderModal = () => {
        sessionStorage.setItem('callbackdata', [])
        this.props.componentprops.HandleCloseModal();
    }
    async componentDidMount() {
        if (sessionStorage.getItem('current_user_profile') == 'Telecalling' || sessionStorage.getItem('current_user_profile') == 'Administrator' || sessionStorage.getItem('current_user_profile') == 'Sales' ||sessionStorage.getItem('current_user_profile') == 'Sales TL') {
            try {
                setInterval(async () => {
                    this.GetCallBackData();
                }, 300000);
                setInterval(async () => {
                    let lead_data = JSON.parse(sessionStorage.getItem('callbackdata'));
                    if (lead_data != null && lead_data.length > 0) {
                        var now = new Date();

                        now.setMinutes(now.getMinutes() + 1)
                        console.log("now",now)
                        console.log("new Date()",new Date())
                        lead_data.forEach(element => {
                            console.log("callback_date_time",element.callback_date_time)
                          
                            if (new Date() <= new Date(element.callback_date_time) && new Date(element.callback_date_time) <= now) {
                                this.props.componentprops.OpenModal(ReminderPage, 'md', { modalClass: 'remainder-modal', headerClass: '', titleClass: '', title: '' }, { action: this.CallBackOk, name: 'Profile', close: this.CloseReminderModal })
                                this.setState({
                                    lead_id: element._id
                                })
                            }
                            // else if (new Date() <= new Date(element.hc_booked_date_time) && new Date(element.hc_booked_date_time) <= now) {
                            //     this.props.componentprops.OpenModal(ReminderPage, 'md', { modalClass: 'remainder-modal', headerClass: '', titleClass: '', title: '' }, { action: this.CallBackOk, name: 'Profile', message:'You Have One Call Back Reminder',close: this.CloseReminderModal })
                            //     this.setState({
                            //         lead_id: element._id
                            //     })
                            // }
                        });
                    }
                }, 60000);
            } catch (e) {
                console.log(e);
            }
        }
    }
    // async componentDidMount() {
    //     var that = this;
    //     if (sessionStorage.getItem('current_user_profile') == 'Telecalling' || sessionStorage.getItem('current_user_profile') == 'Administrator') {
    //         try {
    //             setInterval(async () => {
    //                 this.GetCallBackData();
    //             }, 30000);
    //             setInterval(async () => {
    //                 // alert('hello')
    //                 let modal_data = this.props.componentprops.OpenModal(ReminderPage, 'md', { modalClass: 'remainder-modal', headerClass: '', titleClass: '', title: '' }, { action: this.CallBackOk, name: 'Profile', close: this.CloseReminderModal })

    //                 let lead_data = JSON.parse(sessionStorage.getItem('callbackdata'));
    //                 console.log("lead_data", lead_data)
    //                 if (lead_data != null && lead_data.length > 0) {
    //                     var now = new Date();
    //                     now.setMinutes(now.getMinutes() + 1)
    //                     lead_data.forEach(element => {
    //                         alert("element")
    //                         console.log("callback_date_time", new Date(element.callback_date_time))
    //                         console.log("now", new Date())
    //                         if (new Date() <= new Date(element.callback_date_time) && new Date(element.callback_date_time) <= now) {
    //                             alert(element)
    //                             that.setState({
    //                                 showModal: true,
    //                                 modalData: modal_data,
    //                                 lead_id: element._id
    //                             })

    //                             //alert(new Date() + "---" + now + "---" + new Date(element.callback_date_time))
    //                         }
    //                     });
    //                 }
    //             }, 60000);
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     }
    // }
    CallBackOk = (modalId) => {
        sessionStorage.setItem('callbackdata', [])
        AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/' + this.state.lead_id)

    }
    GetCallBackData = () => {
        const headers = {
            'x-access-token': sessionStorage.getItem('current_user_login_token')
        };
        let base_url = 'https://api.neoroots.design';
        axios.get(base_url + '/crm/callback/json/' + sessionStorage.getItem('current_user_profile') + "/" + sessionStorage.getItem('current_user_name'), {
            headers
        })
            .then(function (response) {
                console.log("response",response)
                sessionStorage.setItem('callbackdata', JSON.stringify(response.data.callbackleads.rows))
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    render() {
        // alert(this.props.PnkStore.GetData('allowed_routes'));
      
        return (

            <ProSidebar id="sidebar" collapsed={this.state.collapsed} className="sidebar">

                <SidebarHeader className="flex-center">
                    <div class="btn-toggle" onClick={() => this.ToggleSideBar('mob')}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg></div>
                    {this.state.collapsed === false ?
                        <div className="toggle-btn-div " id="toggle-btn-div">

                            <img src={Logo} id="brand-logo" style={{width:'40%'}} className="hide-mob" />
                            <a className="sb-toggle-icon" onClick={() => this.ToggleSideBar('web')}> <FontAwesomeIcon icon={faBars} className="sb-toggle-icon" /></a>

                        </div>
                        : null}
                    {this.state.collapsed === true ?
                        <div className="toggle-btn-div1" id="toggle-btn-div">


                            <a className="sb-toggle-icon" onClick={() => this.ToggleSideBar('web')}> <FontAwesomeIcon icon={faBars} className="sb-toggle-icon" /></a>

                        </div> : null}

                </SidebarHeader>
              
                <SidebarContent id="side-content">
                    <Menu iconShape="square">
                        {routeData.map(item => (
                            // this.allowedRoutes.includes(item.routeName) === true ?

                            <React.Fragment>

                                {item.isSubMenu === 'yes' ?
                                    this.allowedRoutes.includes(item.routeName) ?
                                        <SubMenu title={item.routeName} icon={<img src={item.iconName} className="sb-icon-items side-bar-submenu" />} defaultOpen>
                                            {item.subMenuList.map(list => (
                                                this.allowedRoutes.includes(list.routeName) ?
                                                    <MenuItem id="submenu-link" onClick={() => this.HandleRoute(list.subMenuRoutePath)}>{list.subMenuName}</MenuItem>
                                                    : ''

                                            ))}

                                        </SubMenu>
                                        : ''
                                    :
                                    this.allowedRoutes.includes(item.routeName) ?
                                        <MenuItem onClick={() => this.HandleRoute(item.routePath)} icon={<img src={item.iconName} className="sb-icon-items" />}>{item.routeName}</MenuItem>
                                        : ''}

                            </React.Fragment>
                            // :
                            //  null

                        ))}
                    </Menu>
                </SidebarContent>
                {/* <SidebarFooter>
                    {/**
     *  You can add a footer for the sidebar ex: copyright
  
                </SidebarFooter> */}
            </ProSidebar>


        )
    }
}
export const SideBar = PnkConnect(sideBar, "");