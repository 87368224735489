import React from 'react'
import { Modal, Form, Button, Row, Col } from 'react-bootstrap'

import InputLabel from '@material-ui/core/InputLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
// import { bookingstages, leadstages, routes } from './data'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
export default class UserEntry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: [{ name: 'Srigar', id: 1 }, { name: 'Sam', id: 2 }]
        }
    }

    render() {
       
        return (
            <PnkHtmlForm id="frmUserEntry" onsubmit={this.props.SaveUserData} submitbtn="btnUserEntry">
                <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                    <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                </div>
                <Form.Group>
                    <Form.Label className="profile-entry-label">Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter User Full Name" className="profile-entry-controls"
                        name="txtUserFullName" id="txtUserFullName"
                        pnkvalue={this.props.editDataObj.txtUserFullName}
                        value={this.props.editDataObj.txtUserFullName}
                        pnkvalidate="forceentry"
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid User Full Name" />

                </Form.Group>
                <Form.Group >
                    <Form.Label className="profile-entry-label">User Name</Form.Label>
                    <Form.Control type="text" placeholder="User Name" className="profile-entry-controls"
                        name="txtUserName" id="txtUserName"
                        pnkvalidate="forceentry"
                        pnkvalue={this.props.editDataObj.txtUserName}
                        value={this.props.editDataObj.txtUserName}
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid User Name" />

                </Form.Group>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Email</Form.Label>
                    <Form.Control type="text" placeholder="Enter Employee Email" className="profile-entry-controls"
                        name="txtUserEmail" id="txtUserEmail"
                        pnkvalidate="forceemail"
                        pnkvalue={this.props.editDataObj.txtUserEmail}
                        value={this.props.editDataObj.txtUserEmail}
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid User Email" />

                </Form.Group>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Mobile</Form.Label>
                    <Form.Control type="text" placeholder="Enter Empolyee Mobile" className="profile-entry-controls"
                        name="txtUserPhone" id="txtUserPhone"
                        pnkvalidate="forceRmobile"
                        pnkvalue={this.props.editDataObj.txtUserPhone}
                        value={this.props.editDataObj.txtUserPhone}
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid User Phone" />

                </Form.Group>
                <Form.Group >
                    <Form.Label className="profile-entry-label">Salary</Form.Label>
                    <Form.Control type="text" placeholder="Enter Empolyee Salary" className="profile-entry-controls"
                        name="txtUserSalary" id="txtUserSalary"
                        pnkvalidate="forceRnumber"
                        pnkvalue={this.props.editDataObj.txtUserSalary}
                        value={this.props.editDataObj.txtUserSalary}
                        onChange={this.props.HandleUserInputs} pnkdisplayerrormsg="Invalid User Salary" />
                </Form.Group>
                <Row>
                    <Col xs={6} lg={4}>
                        <Form.Group >
                            <Form.Label className="profile-entry-label">Reporting To</Form.Label>
                            <Form.Control as="select" className="profile-entry-controls"
                                name="ddlUserReportingTo"
                                id='ddlUserReportingTo'
                                onChange={this.props.HandleUserInputs}
                                pnkvalidate='forceselect'
                                pnkdisplayerrormsg='Select Reporting To'
                                pnkvalue={this.props.editDataObj.ddlUserReportingTo}
                                value={this.props.mystate.ddlUserReportingTo}>
                                <option value="">Reporting To</option>
                                {(this.props.UserData && this.props.UserData && this.props.UserData.length > 0) ? this.props.UserData.map(item =>
                                    item.user_profile[0] && item.user_profile[0].profile_name !== "" ?
                                        item.user_name === this.props.mystate.ddlUserReportingTo ?
                                            <option selected value={item.user_name}>{item.user_full_name} ({item.user_profile[0].profile_name})</option> :
                                            <option value={item.user_name}>{item.user_full_name} ({item.user_profile[0].profile_name})</option>
                                        : ""
                                )
                                    : ""}

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} lg={4}>
                        <Form.Group >
                            <Form.Label className="profile-entry-label">User Profile</Form.Label>
                            <Form.Control as="select" className="profile-entry-controls"
                                name="ddlUserProfile"
                                id='ddlUserProfile'
                                onChange={this.props.HandleUserInputs}
                                pnkvalidate='forceselect'
                                pnkdisplayerrormsg='Select User Profile '
                                pnkvalue={this.props.editDataObj.ddlUserProfile}
                            >
                                <option value="">User Profile</option>
                                {(this.props.ProfileData && this.props.ProfileData.rows && this.props.ProfileData.rows.length > 0) ? this.props.ProfileData.rows.map(item =>
                                    item.profile_name === this.props.mystate.ddlUserProfile ?
                                        <option selected={true} value={item._id}>{item.profile_name}</option > : <option value={item._id}>{item.profile_name}</option>
                                )
                                    : ""}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} lg={4}>
                        <Form.Group >
                            <Form.Label className="profile-entry-label">User Status</Form.Label>
                            <Form.Control as="select" className="profile-entry-controls"
                                name="ddlUserStatus"
                                id='ddlUserStatus'
                                onChange={this.props.HandleUserInputs}
                                pnkvalidate='forceselect'
                                pnkdisplayerrormsg='Select User Status '
                                pnkvalue={this.props.editDataObj.ddlUserStatus}
                                value={this.props.mystate.ddlUserStatus}>
                                <option value="">User Status</option>
                                {this.props.mystate.ddlUserStatus === "1" ?
                                    <option selected value="1">Active</option> : <option value="1">Active</option>}
                                {this.props.mystate.ddlUserStatus === "0" ?
                                    <option selected value="2">Inactive</option> : <option value="2">Inactive</option>}

                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={6} lg={4} style={{ display: this.props.mystate.showCityddl }}>
                        <Form.Group >
                            <Form.Label className="profile-entry-label">City</Form.Label>

                            <Form.Control as="select" className="profile-entry-controls"
                                name="ddlUserCity"
                                id='ddlUserCity'
                                onChange={this.props.HandleUserInputs}
                                pnkvalidate={this.props.mystate.pnkValidateCity}
                                pnkdisplayerrormsg='Select User City '
                                pnkvalue={this.props.editDataObj.ddlUserCity}
                                value={this.props.mystate.ddlUserCity}>
                                <option value="">Select City</option>
                                {(this.props.CityData && this.props.CityData.rows && this.props.CityData.rows.length > 0) ? this.props.CityData.rows.map(item =>
                                    <option value={item.city_name}>{item.city_name}</option>
                                ) : ""}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <div className="profile-btn-div">
                    <Row className="flex-end">
                        <Col lg={3} xs={5}>
                            <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.HideUserModal}>Cancel</Button>
                        </Col>
                        <Col lg={2} xs={5}>
                            <button className="profile-entry-save-btn pnkvalidatesubmit" name="Save" id="btnUserEntry" onClick={this.props.SaveUserData}>{this.props.mystate.showButtonType}</button>
                            {/* <Button variant="light" className="profile-entry-save-btn" id="btnUserEntry" onClick={this.props.SaveUserData}> Save</Button> */}
                        </Col>
                    </Row>
                </div>
            </PnkHtmlForm>

        )
    }
}