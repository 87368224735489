import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap'
import AppUtils from '../../apputils';
export default class Header extends React.Component {
    constructor(props) {
        super(props)
    }
    GoToEntry = () => {
        AppUtils.HandlePageClick(this.props.componentprops, 'entry/' + this.props.mystate.currentChoice)
    }
    render() {
        return (
            <div className="footer show-mob">
                <Row className="footer-row">
                    {this.props.mystate.currentChoice === "area" ?
                        <div style={{ width: '90%', border: 'solid 1px #d6d6d6' }}>

                            <InputGroup className="profile-search-box">
                                <FormControl
                                    placeholder="Search by Area Name"
                                    className="profile-search-box"
                                    name="txtSearchAreaKey" id="txtSearchAreaKey"
                                    value={this.props.mystate.txtSearchAreaKey}
                                    onChange={this.props.HandleUserSearch}

                                />
                                <InputGroup.Append >
                                    <InputGroup.Text id="basic-addon2" className="profile-search-text search-icon-lead">
                                        {/* <Button variant="light"> */}
                                        <FontAwesomeIcon icon={faSearch} className="profile-search-icon" />
                                        {/* </Button> */}
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div> :
                        <div style={{ width: '90%' }}></div>}
                    <div style={{ width: '10%', border: 'solid 1px #d6d6d6', paddingLeft: 5 }}>
                        <Button className="add-footer" onClick={() => this.GoToEntry()}><FontAwesomeIcon icon={faPlus} /></Button>
                    </div>
                </Row>
            </div>
        )
    }
}