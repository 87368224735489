import React from 'react'
import { Row, Col, Button, Form, Tooltip, OverlayTrigger, Overlay } from 'react-bootstrap'
import Activities from '../../assets/images/Activities.svg'
import Exotel_Calls from '../../assets/images/Exotel_Calls.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import WhatsappIcon from '../../assets/images/whatsapp-icon.svg'
import Pagination from '../Pagination'
import AppUtils from "../../apputils";

export default class HcList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            selectAll: false,
            showEditBox: false,
            currentRef: '',
            rowOffset: '',

        }
        this.target = React.createRef()
        this.currentRef = ''
        this.refsArray = [];
        JSON.stringify(this.props.hcData) !== '{}' && this.props.hcData && this.props.hcData.length > 0 && this.props.hcData.map((item, i) => {
            this["hcRow" + item._id] = React.createRef();

        }
        )
    }

    HandleRoute = (id) => {
        // localStorage.setItem("rowOffset", this.state.rowOffset)
        // localStorage.setItem("currentLeadId", id)
        // AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/' + id);

    }
    //  ScrollDemo = () => {

    //     const myRef = React.createRef()
    //     const executeScroll = () => scrollToRef(myRef)
    // }
    RenderSpace = (list) => {
        let str = ""
        if (list !== undefined)
            if (list.length > 0) {
                list.map((item, i) => (
                    str = item
                    // <div>{i + 1}.{item.replace(/aaaa,?/g, "\n")}</div>
                ))
                let tempArr = str.split(',')
                let arr = tempArr.filter(item => item);
                // console.log(arr);
                if (arr !== null || arr !== undefined) {
                    return (arr.length > 0 ?
                        arr.map((space, i) =>
                            space !== "" ?
                                <div>{i + 1}.{space}</div> : null
                        ) : null

                    )
                    //     space !== "" ?
                    //         <div>{i + 1}.{space}</div> : null

                    // ) : null
                }

            }

    }
    componentDidMount = () => {
        window.addEventListener("scroll", this.handleScroll);
        if (localStorage.getItem("currentLeadId") !== null) {
            document.body.scrollTop = localStorage.getItem("rowOffset");
            document.documentElement.scrollTop = localStorage.getItem("rowOffset");
            localStorage.removeItem("currentLeadId")
            localStorage.removeItem("rowOffset")

        }
        console.log("currentPageNumber",)


    }
    handleScroll = () => {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

        this.setState({
            rowOffset: scrollTop
        })
    }
    // if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {



    render() {
        // Counts in Footer
        let pageStart = 0, endPage = 0
        if (this.props.PnkStore.GetData('lastSearchBy') === 'advance' && this.props.mystate.totalRecordsInDb < this.props.mystate.numItemsPerPage) {
            pageStart = 1
            endPage = this.props.mystate.totalRecordsInDb

        }
        else {
            if (this.props.currentPageNumber > 0) {
                pageStart = ((this.props.currentPageNumber - 1) * this.props.mystate.numItemsPerPage) + 1
                if (this.props.mystate.totalRecordsInDb < (this.props.mystate.numItemsPerPage * this.props.currentPageNumber))
                    endPage = this.props.mystate.totalRecordsInDb
                else
                    endPage = this.props.currentPageNumber * this.props.mystate.numItemsPerPage
            }
        }


        // console.log("lead list", this.props)
        let userMobileNumber = sessionStorage.getItem("current_user_mobile")
        // console.log("this.props.mystate.numItemsPerPage", this.props.mystate.numItemsPerPage)
        console.log("state", sessionStorage.getItem("current_user_profile"))
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    <Row>
                                        <Col>
                                            <div style={{ display: 'flex', alignItems: "baseline", paddingBottom: 10 }}>
                                                <label className="mr-2 lead-show-entry">Show</label>
                                                <Form.Group className="mr-2">
                                                    <Form.Control as="select" size="md" custom id="numItemsPerPage" name="numItemsPerPage" onChange={this.props.HandleUserInput}>
                                                        {this.props.pageentries.map(item => (
                                                            this.props.mystate.numItemsPerPage === item ?
                                                                <option value={item} selected> {item}</option> :
                                                                <option value={item}> {item}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                                <label className="lead-show-entry">entries per page</label>

                                                {this.props.mystate.showEditBox === true && (sessionStorage.getItem('current_user_profile') == 'Administrator' || sessionStorage.getItem('current_user_profile') == 'CRM Admin') ?
                                                    <div id="divEditBox" style={{ paddingRight: '12px' }}>
                                                        {this.props.allowed_route ?
                                                            <div className="pull-left" onClick={() => this.props.EditAndDeleteLead('', 'edit_multi')} style={{
                                                                padding: '4px 26px',
                                                                height: '36px',
                                                                background: 'red',
                                                                color: '#FFFFFF',
                                                                fontWeight: 600,
                                                                borderRadius: '3px',
                                                                cursor: 'pointer',
                                                                marginLeft: 20
                                                            }}>
                                                                Multi Edit
                                                            </div>
                                                            : <span></span>}
                                                    </div>
                                                    : ''}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mob-row-align">

                                        {/* {this.props.allowed_route ?
                                            <Col lg={1} className="order-col hide-mob">
                                                <label class="chk-container">
                                                    <input type="checkbox" id="chkSelectAllLeads" name="chkLeads"
                                                        onClick={this.props.HandleCheckBoxes}
                                                        checked={this.props.mystate.selectAll} />
                                                    <span class="checkmark-form"></span>
                                                </label>

                                            </Col>
                                            : <span></span>} */}

                                        <React.Fragment>

                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Acquisition Date</h3>
                                            </Col>
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Updated On</h3>
                                            </Col>
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">HC Booked Date</h3>
                                            </Col>
                                            <Col lg={2} xs={8} className="">
                                                <h3 className="table-head-lead ">Customer Details</h3>
                                            </Col>
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">City</h3>
                                            </Col>
                                            {/* {sessionStorage.getItem("current_user_profile").includes("Sales") === false ? */}
                                            <Col lg={1} className="order-col hide-mob" >
                                                <h3 className="table-head-lead margin-5">Lead Owner</h3>

                                            </Col>
                                            {/* :
                                                <Col lg={1} className="order-col hide-mob" >
                                                    <h3 className="table-head-lead margin-5">Patch Date</h3>

                                                </Col>
                                            } */}

                                            <Col lg={2} className="order-col hide-mob">
                                                <h3 className="table-head-lead">
                                                    {/* {sessionStorage.getItem("current_user_profile") !== "Telecalling" ? */}
                                                    <h3 className="table-head-lead">HC Details Added By</h3>
                                                    {/* : null} */}
                                                    {/* {sessionStorage.getItem("current_user_profile") !== "Telecalling" ?
                                                        <h3 className="table-head-lead">/Campaign</h3> : <h3 className="table-head-lead">Campaign</h3>} */}

                                                </h3>
                                            </Col>
                                            {/* <Col lg={1} className="order-col hide-mob">
                                                <h3 className="table-head-lead margin-5">Handled By</h3>
                                            </Col> */}
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Space Facing</h3>
                                            </Col>
                                            
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Status</h3>
                                            </Col>
                                            {/* {this.props.allowed_route ?
                                                <Col lg={1} className="" xs={4}>
                                                    <h3 className="table-head-lead" style={{ textAlign: 'center' }}>Action</h3>
                                                </Col>
                                                :
                                                <Col lg={2} className="" xs={4}>
                                                    <h3 className="table-head-lead" style={{ textAlign: 'center' }}>Action</h3>
                                                </Col>

                                            } */}
                                        </React.Fragment>
                                    </Row>

                                    {JSON.stringify(this.props.hcData) !== '{}' && this.props.hcData && this.props.hcData.length > 0 ? this.props.hcData.map((item, i) => (
                                        <div className="lead-table-data" ref={this["hcRow" + item._id]} id={"hcRow_" + item._id}>
                                            <Row className="table-items" >
                                                {/* {console.log("item",item)} */}
                                                {/* {this.props.allowed_route ?
                                                    <Col lg={1} className="hide-mob">
                                                        <div className="table-data">
                                                            <label class="chk-container">
                                                                <input type="checkbox" id={"chkLead_" + item._id} name="chkLeads"
                                                                    onChange={this.props.HandleCheckBoxes} />
                                                                <span class="checkmark-form"></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    : <span></span>} */}
                                                <React.Fragment>
                                                    <Col lg={1} className="hide-mob">

                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                {item.lead_details[0] != undefined && item.lead_details[0].date_time != undefined && item.lead_details[0].date_time !== '' && item.lead_details[0].date_time !== null && item.lead_details[0].date_time !== undefined ?
                                                                    <React.Fragment>
                                                                        <div >{item.lead_details[0].date_time.substring(0, 10)}</div>
                                                                        <div >{item.lead_details[0].date_time.substring(10, 19)}</div>
                                                                    </React.Fragment>
                                                                    : ''}
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    <Col lg={1} className="hide-mob">

                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                {item.lead_details[0] !== undefined && item.lead_details[0].updated_on !== '' && item.lead_details[0].updated_on !== null && item.lead_details[0].updated_on !== undefined ?
                                                                    <React.Fragment>
                                                                        <div >{item.lead_details[0].updated_on.substring(0, 10)}</div>
                                                                        <div >{item.lead_details[0].updated_on.substring(10, 19)}</div>
                                                                    </React.Fragment> : ''}

                                                            </div>
                                                        </div>

                                                    </Col>

                                                    <Col lg={1} className="hide-mob">

                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                {item.lead_details[0] !== undefined && item.lead_details[0].hc_booked_date_time !== '' && item.lead_details[0].hc_booked_date_time !== null && item.lead_details[0].hc_booked_date_time !== undefined ?
                                                                    <React.Fragment>
                                                                        <div >{item.lead_details[0].hc_booked_date_time.substring(0, 10)}</div>
                                                                        <div >{item.lead_details[0].hc_booked_date_time.substring(10, 19)}</div>
                                                                    </React.Fragment> : ''}

                                                            </div>
                                                        </div>

                                                    </Col>

                                                    <Col lg={2} xs={8} className="">
                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data" >
                                                                {item.lead_details[0] !== undefined && item.lead_details[0].user_name != undefined ?
                                                                    <div>{item.lead_details[0].user_name}</div>
                                                                    : ''}
                                                                <div>{item.user_mobile_no}</div>
                                                                {item.lead_details[0] !== undefined && item.lead_details[0].user_email != undefined ?
                                                                    <div> {item.lead_details[0].user_email}</div>
                                                                    : ''}
                                                            </div>

                                                        </div>

                                                    </Col>

                                                    {/* <Col lg={1} className="hide-mob">

                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                {this.RenderSpace(item.spaceType)}

                                                            </div>
                                                            <div className="lead-data" style={{ background: '#58c2da94' }}>
                                                                {item.project_estimate}

                                                            </div>

                                                        </div>

                                                    </Col> */}
                                                    <Col lg={1} className="hide-mob">

                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                {item.lead_details[0] !== undefined && item.lead_details[0].lead_city ?
                                                                    <div>{item.lead_details[0].lead_city}</div>
                                                                    : ''}
                                                            </div>
                                                        </div>

                                                    </Col>

                                                    <Col lg={1} className="order-col hide-mob">
                                                        <div className="table-data margin-5" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data" >
                                                                {item.lead_details[0] !== undefined && item.lead_details[0].lead_owner != undefined ?
                                                                    sessionStorage.getItem("current_user_profile").includes("Sales") === false ?
                                                                        <div className="text-overflow">{item.lead_details[0].lead_owner}</div>
                                                                        :
                                                                        ''
                                                                    // <div className="" style={{ fontSize: '11px' }}>{item.patch_date_time}</div>

                                                                    : ''}
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    <Col lg={2} className="order-col hide-mob">
                                                        <div className="table-data margin-5" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data" >
                                                                {/* {sessionStorage.getItem("current_user_profile") !== "Telecalling" ? */}
                                                                <div className="text-overflow">{item.created_by}</div>
                                                                <div className="text-overflow">{item.created_on}</div>
                                                                {/* : null}

                                                                <div className="" style={{ background: 'chartreuse', fontSize: '9px', fontWeight: 600 }} title={item.lead_campaign}>{item.lead_campaign}</div> */}
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col lg={1} className="hide-mob">

                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                {this.RenderSpace(item.ddlSpaceFacing)}

                                                            </div>
                                                            {/* <div className="lead-data" style={{ background: '#58c2da94' }}>
                                                                {item.project_estimate}

                                                            </div> */}

                                                        </div>

                                                    </Col>

                                                    {/* <Col lg={1} className="order-col hide-mob">
                                                            <div className="table-data margin-5" onClick={() => this.HandleRoute(item._id)}>
                                                                <div className="lead-data">
                                                                    {item.lead_department[0] != undefined ? item.lead_department[0].profile_name : item.lead_department != undefined ? item.lead_department.profile_name : <span></span>}

                                                                </div>
                                                            </div>

                                                        </Col> */}

                                                    <Col lg={1} className="hide-mob">
                                                        <div className="table-data" onClick={() => this.HandleRoute(item._id)}>
                                                            <div className="lead-data">
                                                                <div id="statusDiv">
                                                                    <span id={"spnStatus_" + item.lead_details[0]._id} className="">{item.lead_details[0].lead_current_stage}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* {this.props.allowed_route ?
                                                        <Col lg={1} xs={4} className="">
                                                            <div className="action-icon-list">
                                                                <div className="table-data mob-padding">
                                                                    <a className="icon-padding"><img src={Activities} /></a>
                                                                    <OverlayTrigger className="video-tool-tip" placement="bottom"
                                                                        overlay={<Tooltip id="tooltip-top"> {item.date_time} </Tooltip>} >
                                                                        <a className="icon-padding" ref={this.target} onClick={() => this.setState({ show: !this.state.show })} > <FontAwesomeIcon icon={faVideo} className="lead-action-video" /> </a>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div className="table-data mob-padding">
                                                                    <a className="icon-padding"><img src={WhatsappIcon} style={{ height: 30 }} /></a>
                                                                    <a onClick={() => this.props.ShowCallModal(item.user_mobile_no, userMobileNumber)} className="icon-padding"><img src={Exotel_Calls} style={{ height: 15 }} /></a>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        :
                                                        <Col lg={2} xs={4} className="">
                                                            <div className="action-icon-list">
                                                                <div className="table-data mob-padding">
                                                                    <a className="icon-padding" ><img src={Activities} /></a>
                                                                    <OverlayTrigger className="video-tool-tip" placement="bottom"
                                                                        overlay={<Tooltip id="tooltip-top"> {item.date_time} </Tooltip>} >
                                                                        <a className="icon-padding" ref={this.target} onClick={() => this.setState({ show: !this.state.show })} > <FontAwesomeIcon icon={faVideo} className="lead-action-video" /> </a>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div className="table-data mob-padding">
                                                                    <a className="icon-padding"><img src={WhatsappIcon} style={{ height: 30 }} /></a>
                                                                    <a onClick={() => this.props.ShowCallModal(item.user_mobile_no, userMobileNumber)} className="icon-padding"><img src={Exotel_Calls} style={{ height: 15 }} /></a>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                    } */}
                                                </React.Fragment>
                                            </Row>
                                            {/* {this.props.ChangeStatusColor(item.lead_current_stage, item._id)} */}
                                        </div>
                                        // : null
                                    )) : null}
                                    {/* {console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"+this.props.mystate.numItemsPerPage + "-----" + this.props.mystate.totalRecordsInDb)} */}
                                    <div className="pagination-div-profile">
                                        {this.props.PnkStore.GetData('lastSearchBy') === 'advance' && this.props.mystate.totalRecordsInDb < this.props.mystate.numItemsPerPage ?
                                            <Col lg={4}>
                                                <div className='lead-list-footer-count'>Showing {pageStart} to {endPage} data</div>
                                            </Col>

                                            :
                                            // this.props.mystate.numItemsPerPage <= this.props.mystate.totalRecordsInDb ?

                                            <Row>
                                                <Col lg={4}>
                                                    <div className='lead-list-footer-count'>Showing {pageStart} to {endPage} data</div>
                                                </Col>
                                                <Col lg={4}>
                                                    <Pagination totalNumber={this.props.mystate.totalPages} currentPageNumber={this.props.currentPageNumber} ChangePage={this.props.ChangePage} />
                                                </Col>
                                                {this.props.mystate.numItemsPerPage <= this.props.mystate.totalRecordsInDb ?
                                                    <Col lg={4}>
                                                        Page No.
                                                        <input type="text" className="form-controls" style={{ height: 35, width: 50, textAlign: 'center' }} name="currentPageNumber" id="currentPageNumber" value={this.props.currentPageNumber} onChange={this.props.HandleUserInput} />
                                                        <button type="button" className="primary" style={{ height: 35, width: 50, backgroundColor: 'green', color: '#FFFFFF' }} onClick={() => this.props.ChangePage(this.props.currentPageNumber)}>OK</button>
                                                    </Col>
                                                    : null}
                                            </Row>
                                            // : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
        )
    }
}
