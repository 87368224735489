import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import SourceEntry from './entry'
import { Container } from 'react-bootstrap'
import $ from 'jquery';
import { AddLeadSource, GetLeadSourceDetailsById, EditLeadSource } from '../../models/users';
class sourceEntryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddlSourceStatus: '',
            showButtonType: 'Save',
            sendData: false,
            showEditBtn: 'none',
            getLeadSourceData: false,
            currentSeletedLeadSourceId: '',
            actionType: 'save',
            editData: false,
            fileAdditionalAttachment: '',
            renderChild: false,
            mode: 'add'
        }
        this.editDataObj = {}
        this.data = {}
    }
    HandleUserInput = (e) => {
       
        let val = e.target.value;
        let name = e.target.name;
        this.editDataObj[name] = val;
        this.setState({ [name]: val })
    }
    HideModal = () => {
        this.props.componentprops.currentAction.close();
    }

    SaveLeadSourceData = (e) => {
        this.data = {
            txtLeadSourceName: e.txtSourceName,
            txtMailForwardTo: 'hello@neoroots.design',
            txtDefaultAssignee: 'hello@neoroots.design',
            ddlLeadSourceStatus: e.ddlSourceStatus,
            imgLeadSourceImage: e.fileAdditionalAttachment,
        }
        if (this.state.actionType === 'save') {
            this.setState({
                sendData: true
            })
        }
        else {
            this.setState({
                editData: true, sendData: false
            })
        }

       
    }
    HandleLeadSubmiteResponse = (res) => {
       
        if (res !== false) {
            this.setState({
                sendData: false,
                getLeadSourceData: true
            })
            this.HideModal();

        }
        else {
            this.HideModal();
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Source Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({
                sendData: false,

            })
        }

    }
    componentDidMount = () => {
        this.ChangePageMode()
    }
    ChangePageMode = () => {
        let obj;
       
        if (this.props.componentprops.currentAction.action.action === 'Detail') {
            obj = {
                profileActionHeading: 'Source Entry Detail',
                editDeleteDiv: true,
                getLeadSourceData: true,
                currentSeletedLeadSourceId: this.props.componentprops.currentAction.action.id,
                showButtonType: 'Update',
                formModeActivity: true,
                btnUserProfileOuterDiv: false,
                ddlLeadSourceStatus: "",

            };
            let form = document.getElementById("frmLeadSourceEntry");
            let elements = form.elements;

            for (let i = 0, len = elements.length; i < len; ++i) {
                if (elements[i].id !== '')
                    elements[i].disabled = true;
            }
           
        }
        else {
            obj = { formModeActivity: false, btnUserProfileOuterDiv: true, editDeleteDiv: false };
            let form = document.getElementById("frmLeadSourceEntry");
            let elements = form.elements;
            for (let i = 0, len = elements.length; i < len; ++i) {
                elements[i].disabled = false;
            }
        }

        this.setState(obj);
    }
    ShowEditMode = () => {
      
        let form = document.getElementById("frmLeadSourceEntry");
        let elements = form.elements;
        for (let i = 0, len = elements.length; i < len; ++i) {
            elements[i].disabled = false;
        }

        this.setState({ formModeActivity: false, profileActionHeading: 'Edit User', btnUserProfileOuterDiv: true, showEditBtn: 'none', actionType: 'update' })

    }
    HandleGetLeadSourceByIdResponse = (res) => {
       
        let status = 0
        if (res.data && res.data.leadsrcdetails && res.data.leadsrcdetails.rows) {
            let leadsrcdetails = res.data.leadsrcdetails.rows[0];
            if (leadsrcdetails.lead_source_status === 0)
                status = "0";
            else
                status = "1";
            this.editDataObj = {
                txtSourceName: leadsrcdetails.lead_source_name,
                ddlSourceStatus: status,
                fileAdditionalAttachment: leadsrcdetails.lead_source_image
            }
            this.setState({
                txtSourceName: leadsrcdetails.lead_source_name,
                ddlSourceStatus: status,
                fileAdditionalAttachment: leadsrcdetails.lead_source_image
            })
            this.setState({ getLeadSourceData: false, showEditBtn: 'block', renderChild: true, mode: 'edit' })
        }
    }
    HandleEditSourceDataSuccessResponse = (res) => {
       
        if (res !== false) {
            this.setState({
                editData: false,

            })
            this.HideModal();

        }
        else {
            this.HideModal();
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Source Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({
                editData: false,

            })
        }
    }
    HandleImage = (e) => {
       

        this.editDataObj[e.target.name] = e.target.value;

    }
    render() {
       
        return (
            <Container fluid className="data-table">

                <SourceEntry HideModal={this.HideModal} mystate={this.state} {...this.props} HandleUserInputs={this.HandleUserInput}
                    editDataObj={this.editDataObj} SaveLeadSourceData={this.SaveLeadSourceData}
                    showEditBtn={this.state.showEditBtn} ShowEditMode={this.ShowEditMode}
                    HandleImage={this.HandleImage}
                // SaveProfileData={this.SaveProfileData}  HandleUserInputsMultiple={this.HandleUserInputsMultiple}


                />
                {(this.state.sendData === true) ? AddLeadSource(this.props.PnkStore, this.data, this.HandleLeadSubmiteResponse) : ''}
                {(this.state.getLeadSourceData === true) ? GetLeadSourceDetailsById(this.props.PnkStore, this.state.currentSeletedLeadSourceId, this.HandleGetLeadSourceByIdResponse) : ''}
                {((this.state.editData === true) ? EditLeadSource(this.props.PnkStore, this.state.currentSeletedLeadSourceId, this.data, this.HandleEditSourceDataSuccessResponse) : "")}
            </Container>

        )
    }
}
export const SourceEntryPage = PnkConnect(sourceEntryPage, "");