import React from 'react'
import { Modal, Button, Image, Row, Col } from 'react-bootstrap'
import DummyImg from '../../assets/images/dummy.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
export default class ProfileEntry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: [{ name: 'Srigar', id: 1 }, { name: 'Sam', id: 2 }]
        }
    }

    render() {

        return (

            <div className="booking-photolits">
                <Row >
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} className="photo-col">
                        <div style={{ width: '100%' }}>
                            <Image src={DummyImg} className="photolist-img" />
                            <div className="download-btn-div">
                                <FontAwesomeIcon icon={faDownload} className="photo-album-download-icon" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="profile-btn-div">
                    <Row className="flex-end">
                        <Col lg={4} xs={3} className="flex-end">
                            <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.HideModal}>Back</Button>
                        </Col>
                    </Row>
                </div>


            </div>

            // <div className="profile-btn-div">
            //     <Row className="flex-end">
            //         <Col lg={4} xs={3}>
            //             <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.HideProfileModal}>Cancel</Button>
            //         </Col>
            //         <Col lg={2} xs={2}>
            //             <button className="profile-entry-save-btn pnkvalidatesubmit" name="Save" id="btnProfileEntry" onClick={this.props.SaveProfileData}>SAVE</button>
            //             {/* <Button variant="light" type="button" className="profile-entry-save-btn pnkvalidatesubmit" id="btnProfileEntry" onClick={this.props.SaveProfileData}  > Save</Button> */}
            //         </Col>
            //     </Row>
            // </div>

        )
    }
}