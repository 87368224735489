import React from 'react'
import { Row, Col, Button, InputGroup, FormControl, Form, Image } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    HandleChange = (e) => {
       
        this.props.SortData(e, e.target.value)
    }
    render() {
        return (
            <Row className="">
                <Col lg={12} className="sidebar-col">
                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                            <Col lg={2} sm={4} xs={6} className="flex-left col-padding-0">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentViewType'
                                    id='currentViewType'
                                    value={this.props.mystate.currentViewType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeViewReport(e)}>
                                    <option value="default">Default</option>
                                    <option value="details">Details</option>
                                    <option value="callback">CallBack</option>
                                    <option value="salesreport">Sales Report</option>
                                    {/* <option value="HCTracker">HC Tracker</option> */}
                                    {/* <option value="DesignTeamReport">Design Team Report</option> */}
                                    {/* <option value="HC-PM-Schedule">HC & PM Schedule</option> */}
                                    <option value="followupreport">Followup Report</option>
                                    <option value="meeting-tracker" >Meeting Tracker</option>
                                    <option value="sales-rm-daily-report">Sales RM daily report </option>
                                    <option value="under-construction">Under Construction Report</option>

                                </Form.Control>
                            </Col>
                            {this.props.mystate.currentViewType!=="callback" && this.props.mystate.currentViewType!=="meeting-tracker"?
                            <Col lg={2} sm={4} xs={6} className="flex-left col-padding-0">


                                <Form.Control as="select" className="dashboard-option-select"
                                    name='ddlPreferredCity'
                                    id='ddlPreferredCity'
                                    onChange={this.props.HandleUserInput}
                                    pnkdisplayerrormsg='Select City'
                                    value={this.props.mystate.ddlPreferredCity}
                                    style={{ padding: '4px', width: '100%' }}>
                                    <option value="-1" disabled selected>Select City</option>
                                    <option value=''>No City</option>
                                    {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                        this.props.PnkStore.GetData("citylist").rows.map((item, i) =>
                                            <option value={item.city_name}>{item.city_name}</option>) : null
                                    }

                                </Form.Control>

                            </Col>:null}
                            {this.props.mystate.currentViewType !== "callback" ?
                            <Col lg={2} md={3} xs={6} className="flex-left col-padding-0 show-mob">
                                <Form.Control as="select" className="dashboard-option-select"
                                    name='currentSalesReportType'
                                    id='currentSalesReportType'
                                    value={this.props.mystate.currentSalesReportType}
                                    style={{ padding: '4px', width: '100%' }}
                                    onChange={(e) => this.props.ChangeSalesReportTypeMobile(e)}>
                                    <option value="" disabled selected>Select</option>
                                    {this.props.salesReportType.map(item =>
                                        <option value={item} >{item}</option>)}
                                </Form.Control>

                            </Col>:
                                    <Col lg={2} md={3} xs={6} className="flex-left col-padding-0 show-mob">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='currentSalesReportType'
                                        id='currentSalesReportType'
                                        value={this.props.mystate.currentSalesReportType}
                                        style={{ padding: '4px', width: '100%' }}
                                        onChange={(e) => this.props.ChangeSalesReportTypeMobile(e)}>
                                        <option value="" disabled selected>Select</option>
                                        {this.props.callBackReportType.map(item =>
                                            <option value={item} >{item}</option>)}
                                    </Form.Control>
    
                                </Col>
    }
                            {this.props.mystate.currentViewType !== "HC-PM-Schedule" && this.props.mystate.currentViewType!=="Meeting-Tracker"?
                            <Col lg={2} md={3} xs={6} className="flex-left col-padding-0">
                                    <Form.Control as="select" className="dashboard-option-select"
                                        name='ddlCurrentSalesUser'
                                        id='ddlCurrentSalesUser'
                                        onChange={this.props.HandleUserInput}
                                        pnkdisplayerrormsg='Select City'
                                        value={this.props.mystate.ddlCurrentSalesUser}
                                        style={{ padding: '4px', width: '100%' }}>
                                        <option value="-1" disabled selected>Sales</option>
                                        <option value='' >No User</option>
                                        {this.props.PnkStore.GetData('userlist') && this.props.PnkStore.GetData("userlist") && this.props.PnkStore.GetData("userlist").rows &&
                                            this.props.PnkStore.GetData("userlist").rows.length > 0
                                            ? this.props.PnkStore.GetData("userlist").rows.map(
                                                (item, i) => (
                                                    item.user_profile[0].profile_name.includes('Sales') === true && (item.reporting_to===sessionStorage.getItem('current_user_name') || item.user_name===sessionStorage.getItem('current_user_name'))?
                                                        <option value={item.user_name} >{item.user_name}</option> : ""
                                                )) : ""}
                                    </Form.Control>

                                </Col>:null}
                                {this.props.mystate.currentViewType === "HC-PM-Schedule" ?

<Col lg={1} xs={2} className="flex-left col-padding-0">
    {this.props.mystate.hcPmScheduleType === "hc" ?
        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('hc')}>HC</Button> :
        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('hc')}>HC</Button>}


</Col> : null}
{this.props.mystate.currentViewType === "HC-PM-Schedule" ?
<Col lg={2} xs={4} className="flex-left col-padding-0">
    {this.props.mystate.hcPmScheduleType === "project-meeting" ?
        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('project-meeting')}>Project Meeting</Button> :
        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('project-meeting')}>Project Meeting</Button>
    }

</Col> : null}
{this.props.mystate.currentViewType === "HC-PM-Schedule" ?
<Col lg={2} xs={4} className="flex-left col-padding-0">
    {this.props.mystate.hcPmScheduleType === "vc" ?
        <Button active={true} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('vc')}>VC</Button> :
        <Button active={false} className="inside-sales-btn" onClick={() => this.props.HandleHCSchedule('vc')}>VC</Button>
    }

</Col> : null}

                          
               
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}