import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import CancelModal from './cancel'
import { Container } from 'react-bootstrap'
import AppUtils from "../../apputils";
class cancelPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showCancel: false
        }
    }
    ShowCancel = () => {
        this.setState({
            showCancel: true
        })
    }
    HideCancel = () => {
        this.props.componentprops.currentAction.close();
    }
    HandlePressYes = (route) => {
        AppUtils.HandlePageClick(this.props.componentprops, route);
        this.props.componentprops.currentAction.close();
    }
    render() {

        return (
            <Container fluid className="data-table">
                <CancelModal HideCancel={this.HideCancel} HandlePressYes={this.HandlePressYes} visible={this.state.showCancel} mystate={this.state} {...this.props}
                />

            </Container >
        )
    }
}
export const CancelPage = PnkConnect(cancelPage, "");
