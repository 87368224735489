
import React from 'react'
import { Row, Col, Button, ButtonGroup, Form, Image } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Pagination from '../../Pagination'

export default class UnderConstructionComp extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        console.log("reportData", this.props.mystate.reportData)
        let pageStart = 0, endPage = 0
        if (this.props.mystate.currentPageNumber > 0) {
            pageStart = ((this.props.mystate.currentPageNumber - 1) * this.props.mystate.numItemsPerPage) + 1
            endPage = this.props.mystate.currentPageNumber * this.props.mystate.numItemsPerPage
        }
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomDataCallDetails()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                        <div className="lead-table-div">
                            <div className="home-container">
                                <div className="mob-container">
                                    <Row className="mob-row-align mob-row-color">
                                        <Col lg={1} className="hide-mob">
                                            <h3 className="table-head-lead">Sr. No.</h3>
                                        </Col>

                                        {/* <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead ">LEAD ID </h3>
                                        </Col> */}
                                        <Col lg={2} xs={3} className="">
                                            <h3 className="table-head-lead ">ACQUISITION DATE TIME</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead ">User Name</h3>
                                        </Col>
                                        <Col lg={1} xs={3}>
                                            <h3 className="table-head-lead">PLATFORM</h3>
                                        </Col>
                                        <Col lg={1} xs={3}>
                                            <h3 className="table-head-lead ">CAMPAIGN</h3>
                                        </Col>

                                        <Col lg={2} xs={3} className="">
                                            <h3 className="table-head-lead ">FIRST CALL DATE TIME</h3>
                                        </Col>
                                        <Col lg={2} xs={3} className="">
                                            <h3 className="table-head-lead ">PRE-SALES COMMENT</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead ">LEAD STAGE</h3>
                                        </Col>
                                        <Col lg={1} xs={3} className="">
                                            <h3 className="table-head-lead ">LAST UPDATED DATE TIME</h3>
                                        </Col>


                                    </Row>
                                    <div>
                                        {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                            (item, i) => (
                                                <div className="lead-table-data-user">

                                                    {((i + 1) % 2 === 0) ?
                                                        (<Row className="table-items-user" style={{ background: '#fbfbfb' }}>

                                                            <Col lg={1} className="hide-mob">
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div> {i + 1}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col lg={2} xs={3} className="" >
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.date_time}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={1} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.user_name}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={1} xs={3} className="" >
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.lead_source}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>

                                                            <Col lg={1} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="">{item.lead_campaign}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={2} className="" xs={3}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.first_call}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={2} className="" xs={3}>
                                                                <div className="table-data-user" title={item.crmComments}>
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.crmComments}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={1} className="" xs={3}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.lead_current_stage}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={1} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="text-overflow">{item.updated_on}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            {/* <Col lg={1} className="" xs={3}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.client_name}</div>
                                                                    </div>
                                                                </div>

                                                            </Col> */}


                                                        </Row>) : (
                                                            <Row className="table-items-user" style={{ background: '#ffffff' }}>
                                                                <Col lg={1} className="hide-mob">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div> {i + 1}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>

                                                                <Col lg={2} xs={3} className="" >
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.date_time}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.user_name}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} xs={3} className="" >
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.lead_source}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>

                                                                <Col lg={1} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="">{item.lead_campaign}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>

                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{item.first_call}</div>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user" title={item.crmComments}>
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.crmComments}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} className="" xs={3}>

                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{item.lead_current_stage}</div>
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={1} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.updated_on}</div>

                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                            </Row>)}

                                                </div>



                                            )) : null}
                                        {this.props.mystate.numItemsPerPage <= this.props.mystate.totalRecordsInDb ?

                                            <Row>
                                                <Col lg={4}>
                                                    <div className='lead-list-footer-count'>Showing {pageStart} to {endPage} data</div>
                                                </Col>
                                                <Col lg={4}>
                                                    <Pagination totalNumber={this.props.mystate.totalPages} currentPageNumber={this.props.mystate.currentPageNumber} ChangePage={this.props.ChangePage} />
                                                </Col>

                                            </Row> : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}