import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { PnkConnect } from "../../../pnk-react/pnk-miniredux/";
import SalesReport from './sales-report'
import Marketing from './marketing'
import InsideSalesReport from './inside-sales-report'
import ViewData from './view-data-modal'
import HCTracker from './hc-tracker'
import DesignTeamReport from './design-team-report'
import DesignTracker from './design-tracker'
import HCPMSchedule from './hc-pm-schedule'
import InsideSales from './inside-sales'
import Options from './options';
import LeadPatched from './lead-patched'
import { GetUserProfileList, GetCityList, GetUserList, GetLeadSource, GetUserByProfile } from '../../../models/users';
import { AllCallList } from '../../../models/services'
import { AdminSalesReport, CallBackList } from '../../../models/admin-dashboard'
import { FirstCall, FirstCallConnectionByDate } from '../../../models/calls'
import MobCustomDatePicker from './mob-custom-picker'
import CallbackReport from './callback'
import PatchReport from './patch-report'
import FollowupReport from './followup'
import AppUtils from '../../../apputils'
import DialyReport from './dialy-report'
import LeadCampaign from './lead-campaign'
import SalesMeetingCount from './sales-meeting-count'
import RMDailyReport from './RM-daily-report'
import PartialAssignmentReport from './partial-assgn-report'
import UnderConstructionComp from './under-construction'
import { element } from 'prop-types';
import LeadTrackingReport from './lead-tracking-report';
class reportPage extends React.Component {
    constructor(props) {
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.state = {
            ddlReportType: '',
            ddlPreferredCity: '-1',
            getCity: true,
            currentChoice: (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "") ? this.props.componentprops.match.params.type : 'sales-report',
            currentSalesReportType: 'Today',
            txtCustomStartDate: new Date().setDate(new Date().getDate() - 29),
            txtCustomEndDate: new Date(),
            txtCustomMeetingStartDate: new Date(),
            txtCustomMeetingEndDate: new Date().setDate(new Date().getDate() + 7),
            currentInsideSalesType: '',
            ddlHcSalesUser: '-1',
            getProfileData: true,
            hcPmScheduleType: 'hc',
            getReportData: false,
            startDate: '',
            endDate: '',
            salesData: [],
            currentDisplayType: '',
            todaysData: [],
            yestrdayData: [],
            weekData: [],
            monthData: [],
            todayDate: '',
            yesterdayDate: '',
            weekDate: '',
            monthDate: '',
            getUser: false,
            getLeadData: false,
            marketingData: [],
            getMarketingData: false,
            getCustomData: false,
            customStartDate: '',
            customEndDate: '',
            getInsideSalesData: false,
            insideSalesData: [],
            reportData: [],
            isOptionDisabled: true,
            timeArray: [],
            ddlCurrentDesigner: '',
            showCustomDateModal: false,
            getCallbackData: false,
            userListByProfile: [],
            getUsersListByProfile: false,
            ddlHandledById: '',
            ddlcurrentCallBackUser: '',
            ddlCurrentCallBackBprofile: '',
            callbackReportData: [],
            showPatchUserDetails: 'block',
            ddlTelecallingUser: '-1',
            patchView: 'default',
            patchDetailReport: [],
            profileStageData: [],
            ddlCurrentLeadStage: '',
            total_patch_count: 0,
            total_patch_count_yesterday: 0,
            total_patch_count_week: 0,
            total_patch_count_month: 0,
            lead_campaign_view: 'default',
            total_campaign_count: 0,
            campaignDetails: [],
            getAllCalls: false,
            getCustomCallList: false,
            getCustomCallbackData: false,
            dayBeforeYestrday: '',
            meetingWeekStartDate: '',
            meetingWeekEndDate: '',
            getMeetingData: false,
            setNo: 1,
            setLength: 50,
            getFirstCall: false,
            numItemsPerPage: 50,
            totalRecordsInDb: 0,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,


        }
        this.FirstCallData = []
        this.calldetails_data = {}
        this.salesReportType = [
            'Today',
            'Yesterday',
            'Last 7 Days',
            // 'Last 30 Days',
            'Custom',
            // 'Coming Days'
        ];
        this.callBackReportType = [
            'Today',
            'Coming Days',
            'Custom'
        ]

        this.currentCity = '';
        this.currentInsideSalesType = '';
        this.adminReportAll = []
        // Patch
        this.todayPatchData = [];
        this.yesterdayPatchData = [];
        this.weekPatchData = [];
        this.monthPatchData = [];
        //  Lead Generated
        this.todayLeadGeneratedData = [];
        this.yesterdayLeadGeneratedData = [];
        this.weekLeadGeneratedData = [];
        this.monthLeadGeneratedData = [];
        // Lead Called
        this.todayLeadCalledData = []
        this.yesterdayLeadCalledData = []
        this.weekLeadCalledData = []
        this.monthLeadCalledData = []
        // Dead
        this.todayDeadData = []
        this.yesterdayDeadData = []
        this.weekDeadData = []
        this.monthDeadData = []
        // Pending
        this.todayPendingData = []
        this.yesterdayPendingData = []
        this.weekPendingData = []
        this.monthPendingData = []
        // Updated Time
        this.todayUpdateTimeData = []
        this.yesterdayUpdateTimeData = []
        this.weekUpdateTimeData = []
        this.monthUpdateTimeData = []
        // Callback
        this.todayCallbackData = []
        this.yesterdayCallbackData = []
        this.weekCallbackData = []
        this.monthCallbackData = []
        // For sales Only
        this.todayPatchSalesReport = []
        this.yesterdayPatchSalesReport = []
        this.weekPatchSalesReport = []
        this.monthPatchSalesReport = []
        // Hc Booked
        this.todayHCBookedReport = []
        this.yesterdayHCBookedReport = []
        this.weekHCBookedReport = []
        this.monthHCBookedReport = []
        // Hc Completed
        this.todayHCCompleted = []
        this.yesterdayHCCompleted = []
        this.weekHCCompleted = []
        this.monthHCCompleted = []
        // Follow Up
        this.todayFollowUp = []
        this.yesterdayFollowUp = []
        this.weekFollowUp = []
        this.monthFollowUp = []
        // Pending Design Handover
        this.todayPendingDesignHandover = []
        this.yesterdayPendingDesignHandover = []
        this.weekPendingDesignHandover = []
        this.monthPendingDesignHandover = []

        // Handover To design
        this.todayHandoverDesign = []
        this.yesterdayHandoverDesign = []
        this.weekHandoverDesign = []
        this.monthHandoverDesign = []
        // Estimate Pending
        this.todayEstimatePending = []
        this.yesterdayEstimatePending = []
        this.weekEstimatePending = []
        this.monthEstimatePending = []
        //Proposal Sent
        this.todayProposalSent = []
        this.yesterdayProposalSent = []
        this.weekProposalSent = []
        this.monthProposalSent = []

        // Booking Amount Received
        this.todayBookingAmountReceived = []
        this.yesterdayBookingAmountReceived = []
        this.weekBookingAmountReceived = []
        this.monthBookingAmountReceived = []
        // Projcet Closed
        this.todayProjectClosed = []
        this.yesterdayProjectClosed = []
        this.weekProjectClosed = []
        this.monthProjectClosed = []
        // Store Customdata

        // Designer Hndovers

        this.todayDesignerHandover = []
        this.yesterdayDesignerHandover = []
        this.weekDesignerHandover = []
        this.monthDesignerHandover = []
        // Designer Proposal sent
        this.todayDesignerProposalSent = []
        this.yesterdayDesignerProposalSent = []
        this.weekDesignerProposalSent = []
        this.monthDesignerProposalSent = []
        // Designer Ptoject Close
        this.todayDesignerProjectClosed = []
        this.yesterdayDesignerProjectClosed = []
        this.weekDesignerProjectClosed = []
        this.monthDesignerProjectClosed = []
        // Design  in progress
        this.todayDesignProgress = []
        this.yesterdayDesignProgress = []
        this.weekDesignProgress = []
        this.monthDesignProgress = []
        // Design Proposal Uploaded

        this.todayDesignProposalUploaded = []
        this.yesterdayDesignProposalUploaded = []
        this.weekDesignProposalUploaded = []
        this.monthDesignProposalUploaded = []

        // Design Change
        this.todayDesignChange = []
        this.yesterdayDesignChange = []
        this.weekDesignChange = []
        this.monthDesignChange = []

        // Callback 
        this.callbackReportData = []
        // PM Booked
        this.todayPMBookedReport = []
        this.yesterdayPMBookedReport = []
        this.weekPMBookedReport = []
        this.monthPMBookedReport = []

        // PM Completed
        this.todayPMCompleted = []
        this.yesterdayPMCompleted = []
        this.weekPMCompleted = []
        // VC
        this.todayVCCompletedReport = []
        this.yesterdayVCCompletedReport = []
        this.weekVCCompletedReport = []
        this.monthVCCompletedReport = []

        this.customData = []
        this.leadSourceData = []
        this.currentSalesUser = ''
        this.currentDesigner = ''
        this.hcPmScheduleType = 'hc'
        this.currentTeleUser = ''
        // this.todayPatchData
        // Follow Up
        this.todayFollowupReport = []
        this.yesterdayFollowupReport = []
        this.weekFollowupReport = []
        this.monthFollowupReport = []

        // Exotel called
        this.todayExotelCalled = []
        this.yesterdayExotelCalled = []
        this.weekExotelCalled = []
        this.monthExotelCalled = []

        // HC NA
        this.todayHCNAReport = []
        this.yesterdayHCNAReport = []
        this.weekHCNAReport = []
        // Only for RM daily Report
        this.todayDailyReport = [];
        this.yesterdayDailyReport = []
        this.weekDailyReport = []

        // Partial Assignment
        this.todayPartialAssignment = []
        this.yesterdayPartialAssignment = []
        this.weekPartialAssignment = []

        // Under Construction
        this.todayUnderConstruction = []
        this.yesterdayUnderConstruction = []
        this.weekUnderConstruction = []

        // New Meeting Tracker
        this.weekMeetingList = []


        this.customCallList = []
    }

    componentDidMount = () => {
       


        var today = new Date();

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let endDate = yyyy + '-' + mm + '-' + dd;


        let beforeThirtyDays = new Date(new Date().setDate(new Date().getDate() - 6))
        var before_dd = String(beforeThirtyDays.getDate()).padStart(2, '0');
        var before_mm = String(beforeThirtyDays.getMonth() + 1).padStart(2, '0'); //January is 0!
        var before_yyyy = beforeThirtyDays.getFullYear();
        let startDate = before_yyyy + '-' + before_mm + '-' + before_dd;

        let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
        var yester_dd = String(yesterdayDate.getDate()).padStart(2, '0');
        var yester_mm = String(yesterdayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yester_yyyy = yesterdayDate.getFullYear();
        let yesterday = yester_yyyy + '-' + yester_mm + '-' + yester_dd;

        let weekDate = new Date(new Date().setDate(new Date().getDate() - 6))
        var week_dd = String(weekDate.getDate()).padStart(2, '0');
        var week_mm = String(weekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var week_yyyy = weekDate.getFullYear();
        let weekStartDate = week_yyyy + '-' + week_mm + '-' + week_dd;

        let monthDate = new Date(new Date().setDate(new Date().getDate() - 29))
        var month_dd = String(monthDate.getDate()).padStart(2, '0');
        var month_mm = String(monthDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var month_yyyy = monthDate.getFullYear();
        let monthStartDate = month_yyyy + '-' + month_mm + '-' + month_dd;

        let dayBeforeYestrday = new Date(new Date().setDate(new Date().getDate() - 2))
        var dy_bf_yst = String(dayBeforeYestrday.getDate()).padStart(2, '0');
        var month_dy_bg_yst = String(dayBeforeYestrday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var year_dy_bf_yst = dayBeforeYestrday.getFullYear();
        let dayBfYest = year_dy_bf_yst + '-' + month_dy_bg_yst + '-' + dy_bf_yst;

        let comingWeekDate = new Date(new Date().setDate(new Date().getDate() + 7))
        var meeting_week_dd = String(comingWeekDate.getDate()).padStart(2, '0');
        var meeting_week_mm = String(comingWeekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var meeting_week_yyyy = comingWeekDate.getFullYear();
        let meetingWeekEndDate = meeting_week_yyyy + '-' + meeting_week_mm + '-' + meeting_week_dd;
        this.setState({
            startDate: startDate, endDate: endDate, currentDisplayType: endDate,
            todayDate: endDate, yesterdayDate: yesterday, weekDate: weekStartDate, monthDate: monthStartDate,
            dayBeforeYestrday: dayBfYest, meetingWeekStartDate: endDate, meetingWeekEndDate: meetingWeekEndDate
        })
        if (JSON.stringify(this.props.PnkStore.GetData('userlist')) === '{}') {
            this.setState({
                getUser: true
            })
        }
        if (this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "") {
            if (this.props.componentprops.match.params.type === 'meeting-tracker') {
               
                this.setState({
                    getMeetingData: true
                })
            }
            else if (this.props.componentprops.match.params.type === 'callback') {
                this.setState({
                    getCallbackData: true
                })
            }
            else if (this.props.componentprops.match.params.type === "lead-tracking-report") {
                this.calldetails_data = {
                    'txtCallListStartDate': endDate,
                    'txtCallListEndDate': endDate
                }
                this.setState({
                    getFirstCall: true
                })
            }
            else {
                this.setState({
                    getReportData: true,
                    getAllCalls: true
                })
            }
        }
        else {
            this.setState({
                getReportData: true, getAllCalls: true
            })
        }







    }

    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {

            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    ChangeOption = (e) => {

        // alert(1)
        this.setState({
            currentChoice: e.target.value,
            currentSalesReportType: 'Today',
            ddlPreferredCity: '-1',
            currentInsideSalesType: '',
            reportData: []
        })

        this.currentCity = ''
        if (e.target.value === "meeting-tracker") {
            if (this.props.PnkStore.GetData('userlist') === {}) {
                this.setState({ getUser: true })
            }
            this.setState({
                getMeetingData: true
            })
        }
        else if (e.target.value === "marketing") {
            if (JSON.stringify(this.props.PnkStore.GetData('leadsource')) === '{}') {

                this.setState({
                    getLeadData: true,
                    getReportData: true
                })
            }
            else {
                this.leadSourceData = this.props.PnkStore.GetData("leadsource");
            }
        }

        else if (e.target.value === "callback") {
            this.setState({
                getCallbackData: true
            })
        }
        else if (e.target.value === "lead-tracking-report") {
            this.calldetails_data = {
                'txtCallListStartDate': this.state.todayDate,
                'txtCallListEndDate': this.state.todayDate
            }
            this.setState({
                getFirstCall: true
            })
        }


        this.customData = []
        if (e.target.value !== "callback" && this.state.currentSalesReportType !== "Custom") {
            this.RenderReport('Today', e.target.value)
        }
        if (window.location.href.indexOf("/dashboard/") > -1)
            this.props.componentprops.history.push(e.target.value);
        else
            this.props.componentprops.history.push('dashboard/' + e.target.value);
    }

    HandleChangeDate = (type, date) => {
        this.setState({
            [type]: date
        });

    };
    HandleUserInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ddlPreferredCity") {
            this.currentCity = e.target.value;
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }
        if (e.target.name === "ddlHcSalesUser") {
            this.currentSalesUser = e.target.value
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }
        if (e.target.name === "ddlTelecallingUser") {
            this.currentTeleUser = e.target.value
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }
        if (e.target.name === "ddlCurrentDesigner" && e.target.value !== '') {
            this.currentDesigner = e.target.value
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }
        if (e.target.name === "ddlCurrentCallBackBprofile") {
            if (e.target.value !== 'Administrator') {
                var sel = document.getElementById('ddlCurrentCallBackBprofile');
                var opt = sel.options[sel.selectedIndex];

                this.setState({
                    ddlHandledById: opt.id,
                    getUsersListByProfile: true,
                    ddlcurrentCallBackUser: ''

                })
                this.RenderCallbackReport(this.callbackReportData, '', e.target.value, this.state.currentSalesReportType)
            }
            else {
                this.RenderCallbackReport(this.callbackReportData, '', '', this.state.currentSalesReportType)
            }
        }

        if (e.target.name === "ddlcurrentCallBackUser" && e.target.value !== '') {
            this.RenderCallbackReport(this.callbackReportData, e.target.value, this.state.ddlCurrentCallBackBprofile, this.state.currentSalesReportType)
        }
        if (e.target.name === 'txtFromNumber') {
            var numbers = /^[0-9]+$/;
            if (!e.target.value.match(numbers) || e.target.value === '') {
                this.setState({ invalidtxtFromNumber: true, [e.target.name]: '' })
            }
            else {
                this.setState({ invalidtxtFromNumber: false, [e.target.name]: e.target.value })
            }
        }
        if (e.target.name === 'txtFromNumber' && e.target.value.length === 10) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(e.target.value)) {
                this.setState({ invalidtxtFromNumber: true })


            } else {
                this.setState({ invalidtxtFromNumber: false })
            }
        }
        if (e.target.name === 'txtCustomerNumber') {
            var numbers = /^[0-9]+$/;
            if (!e.target.value.match(numbers) || e.target.value === '') {
                this.setState({ invalidtxtCustomerNumber: true, [e.target.name]: '' })
            }
            else {
                this.setState({ invalidtxtCustomerNumber: false, [e.target.name]: e.target.value })
            }
        }
        if (e.target.name === 'txtCustomerNumber' && e.target.value.length === 10) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(e.target.value)) {
                this.setState({ invalidtxtCustomerNumber: true })


            } else {
                this.setState({ invalidtxtCustomerNumber: false })
            }
        }
        if (e.target.name === 'ddlCurrentLeadStage') {
            this.setState({
                ddlCurrentLeadStage: e.target.value
            })
        }

    }
    HandleInsideSalesOptions = (type) => {
        this.currentInsideSalesType = type
        this.setState({ currentInsideSalesType: type })
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport(this.state.currentSalesReportType, this.state.currentChoice)
        }
        else {
            this.RenderCustomReport()
        }
    }
    HandleHCSchedule = (type) => {

        this.setState({
            hcPmScheduleType: type,
            currentSalesReportType: 'Today'

        })
        this.hcPmScheduleType = type;
        this.RenderReport('Today', "HC-PM Schedule")
    }
    HandleMeetingType = (type) => {
        this.setState({
            hcPmScheduleType: type,
            currentSalesReportType: 'Today'

        })
        this.hcPmScheduleType = type;
        this.RenderReport('Today', "meeting-tracker")
    }

    ShowSalesInsideModal = (data) => {
        this.props.componentprops.OpenModal(ViewData, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: '', icon: 'close' }, { action: this.state, name: 'Profile', close: this.HideModal, data: data })
    }
    HideModal = () => {
        this.props.componentprops.HandleCloseModal();
    }

    HandleProfileGetResponse = (res) => {

        if (res.data && res.data.profilelist && res.data.profilelist.rows) {
            // // console.loge.log("res.data.profilelist.rows",res.data.profilelist.rows)
            this.props.PnkStore.SetData('profilelist', res.data.profilelist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getProfileData: false
        })

    }
    HandleGetReportResponse = (res) => {
        this.setState({ getReportData: false, isOptionDisabled: false })
        if (res !== false) {
            if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.adminReportAll = res.data.adminfirstmonthdata.rows
                res.data.adminfirstmonthdata.rows.forEach((element, i) => {

                    // Lead Generate & Lead Called
                    if (element.date_time !== undefined && element.date_time.includes(this.state.todayDate)) {
                        this.todayLeadGeneratedData.push(element)
                        if (element.lead_current_stage !== "Pending") {
                            this.todayLeadCalledData.push(element)
                        }

                    }
                    else if (element.date_time !== undefined && element.date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayLeadGeneratedData.push(element);
                        if (element.lead_current_stage !== "Pending") {
                            this.yesterdayLeadCalledData.push(element)
                        }
                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.weekDate) {
                        this.weekLeadGeneratedData.push(element)
                        if (element.lead_current_stage !== "Pending") {
                            this.weekLeadCalledData.push(element)
                        }
                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.monthDate) {
                        this.monthLeadGeneratedData.push(element)
                        if (element.lead_current_stage !== "Pending") {
                            this.monthLeadCalledData.push(element)
                        }
                    }
                    if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.todayDate)) {
                        this.todayDesignerHandover.push(element)
                    }
                    else if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayDesignerHandover.push(element);
                    }
                    if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.weekDate) {
                        this.weekDesignerHandover.push(element)
                    }
                    if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.monthDate) {
                        this.monthDesignerHandover.push(element)
                    }
                    if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.todayDate)) {
                        this.todayDesignerProposalSent.push(element)
                    }
                    else if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayDesignerProposalSent.push(element);
                    }
                    if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.weekDate) {
                        this.weekDesignerProposalSent.push(element)
                    }
                    if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.monthDate) {
                        this.monthDesignerProposalSent.push(element)
                    }
                    if (element.project_close_date_time !== undefined && element.project_close_date_time.includes(this.state.todayDate)) {
                        this.todayDesignerProjectClosed.push(element)
                    }
                    else if (element.project_close_date_time !== undefined && element.project_close_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayDesignerProjectClosed.push(element);
                    }
                    if (element.project_close_date_time !== undefined && element.project_close_date_time <= this.state.todayDate + ' 23:59:59' && element.project_close_date_time >= this.state.weekDate) {
                        this.weekDesignerProjectClosed.push(element)
                    }
                    if (element.project_close_date_time !== undefined && element.project_close_date_time <= this.state.todayDate + ' 23:59:59' && element.project_close_date_time >= this.state.monthDate) {
                        this.monthDesignerProjectClosed.push(element)
                    }

                    if (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time.includes(this.state.todayDate)) {
                        this.todayDesignerProjectClosed.push(element)
                    }
                    else if (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayDesignerProjectClosed.push(element);
                    }
                    if (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_uploaded_date_time >= this.state.weekDate) {
                        this.weekDesignerProjectClosed.push(element)
                    }
                    if (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_uploaded_date_time >= this.state.monthDate) {
                        this.monthDesignerProjectClosed.push(element)
                    }

                    // }
                    // Follow Up
                    if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.todayDate)) {
                        this.todayFollowupReport.push(element)
                    }
                    else if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayFollowupReport.push(element);
                    }
                    if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate + ' 23:59:59' && element.follow_up_date_time >= this.state.weekDate) {
                        this.weekFollowupReport.push(element)
                    }
                    if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate + ' 23:59:59' && element.follow_up_date_time >= this.state.monthDate) {
                        this.monthFollowupReport.push(element)
                    }
                    // Partial Assignment
                    if (element.partial_date_time !== undefined && element.partial_date_time.includes(this.state.todayDate)) {
                        this.todayPartialAssignment.push(element)
                    }
                    else if (element.partial_date_time !== undefined && element.partial_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayPartialAssignment.push(element);
                    }
                    if (element.partial_date_time !== undefined && element.partial_date_time <= this.state.todayDate + ' 23:59:59' && element.partial_date_time >= this.state.weekDate) {
                        this.weekPartialAssignment.push(element)
                    }
                    // Under Construction

                    if (element.lead_current_stage === 'Under Construction' && element.under_const_date_time !== '' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.todayDate)) {
                        this.todayUnderConstruction.push(element)
                    }
                    else if (element.lead_current_stage === 'Under Construction' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayUnderConstruction.push(element);
                    }
                    if (element.lead_current_stage === 'Under Construction' && element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.todayDate + ' 23:59:59' && element.under_const_date_time >= this.state.weekDate) {
                        this.weekUnderConstruction.push(element)
                    }

                    if (element.lead_department[0].profile_name.includes("Sales")) {

                        // weekMeetingList
                        // For Sales Only
                        if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate)) {
                            this.todayPatchSalesReport.push(element)
                        }
                        else if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPatchSalesReport.push(element);
                        }
                        if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.weekDate) {
                            this.weekPatchSalesReport.push(element)
                        }
                        if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.monthDate) {
                            this.monthPatchSalesReport.push(element)
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                            this.todayHCBookedReport.push(element)
                        }

                        else if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCBookedReport.push(element);
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                            this.weekHCBookedReport.push(element)
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.monthDate) {
                            this.monthHCBookedReport.push(element)
                        }
                        // HC NA
                        if (element.hc_na_date_time !== undefined && element.hc_na_date_time.includes(this.state.todayDate)) {
                            this.todayHCNAReport.push(element)
                        }

                        if (element.hc_na_date_time !== undefined && element.hc_na_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCNAReport.push(element);
                        }
                        if (element.hc_na_date_time !== undefined && element.hc_na_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_na_date_time >= this.state.weekDate) {
                            this.weekHCNAReport.push(element)
                        }

                        // PM Complated


                        if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.todayDate)) {
                            this.todayPMCompleted.push(element)
                        }
                        else if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPMCompleted.push(element);
                        }
                        if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.project_meeting_completed_date_time >= this.state.weekDate) {
                            this.weekPMCompleted.push(element)
                        }
                        // PM todayPMBookedReport
                        if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate)) {
                            this.todayPMBookedReport.push(element)
                        }
                        else if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPMBookedReport.push(element);
                        }
                        if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate) {
                            this.weekPMBookedReport.push(element)
                        }
                        if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.monthDate) {
                            this.monthPMBookedReport.push(element)
                        }
                        // VC Completed

                        if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate)) {
                            this.todayVCCompletedReport.push(element)
                        }
                        else if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayVCCompletedReport.push(element);
                        }
                        if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate) {
                            this.weekVCCompletedReport.push(element)
                        }
                        if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.monthDate) {
                            this.monthVCCompletedReport.push(element)
                        }
                        if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.todayDate)) {
                            this.todayHCCompleted.push(element)
                        }
                        else if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCCompleted.push(element);
                        }
                        if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate) {
                            this.weekHCCompleted.push(element)
                        }
                        if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.monthDate) {
                            this.monthHCCompleted.push(element)
                        }
                        if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.todayDate)) {
                            this.todayFollowUp.push(element)
                        }
                        else if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayFollowUp.push(element);
                        }
                        if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate + ' 23:59:59' && element.follow_up_date_time >= this.state.weekDate) {
                            this.weekFollowUp.push(element)
                        }
                        if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate + ' 23:59:59' && element.follow_up_date_time >= this.state.monthDate) {
                            this.monthFollowUp.push(element)
                        }

                        if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.todayDate)) {
                            this.todayHandoverDesign.push(element)
                        }
                        else if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHandoverDesign.push(element);
                        }
                        if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.weekDate) {
                            this.weekHandoverDesign.push(element)
                        }
                        if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.todayDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.monthDate) {
                            this.monthHandoverDesign.push(element)
                        }

                        if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.todayDate)) {
                            this.todayProposalSent.push(element)
                        }
                        else if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayProposalSent.push(element);
                        }
                        if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.weekDate) {
                            this.weekProposalSent.push(element)
                        }
                        if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.todayDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.monthDate) {
                            this.monthProposalSent.push(element)
                        }

                        if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.todayDate)) {
                            this.todayBookingAmountReceived.push(element)
                        }
                        else if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayBookingAmountReceived.push(element);
                        }
                        if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.weekDate) {
                            this.weekBookingAmountReceived.push(element)
                        }
                        if (element.booking_amount_received_date_time !== undefined && element.booking_amount_received_date_time <= this.state.todayDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.monthDate) {
                            this.monthBookingAmountReceived.push(element)
                        }

                        if (element.project_close_date_time !== undefined && element.project_close_date_time.includes(this.state.todayDate)) {
                            this.todayProjectClosed.push(element)
                        }
                        else if (element.project_close_date_time !== undefined && element.project_close_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayProjectClosed.push(element);
                        }
                        if (element.project_close_date_time !== undefined && element.project_close_date_time <= this.state.todayDate + ' 23:59:59' && element.project_close_date_time >= this.state.weekDate) {
                            this.weekProjectClosed.push(element)
                        }
                        if (element.project_close_date_time !== undefined && element.project_close_date_time <= this.state.todayDate + ' 23:59:59' && element.project_close_date_time >= this.state.monthDate) {
                            this.monthProjectClosed.push(element)
                        }

                    }
                    // Patch Data for other reports
                    if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate)) {
                        this.todayPatchData.push(element)
                    }
                    else if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayPatchData.push(element);
                    }
                    if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.weekDate) {
                        this.weekPatchData.push(element)
                    }
                    if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate + ' 23:59:59' && element.patch_date_time >= this.state.monthDate) {
                        this.monthPatchData.push(element)
                    }


                    //    Dead
                    if (element.lead_dead_date !== undefined && element.lead_dead_date.includes(this.state.todayDate)) {
                        this.todayDeadData.push(element)
                    }
                    else if (element.lead_dead_date !== undefined && element.lead_dead_date.includes(this.state.yesterdayDate)) {
                        this.yesterdayDeadData.push(element);
                    }
                    if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.todayDate + ' 23:59:59' && element.lead_dead_date >= this.state.weekDate) {
                        this.weekDeadData.push(element)
                    }
                    if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.todayDate + ' 23:59:59' && element.lead_dead_date >= this.state.monthDate) {
                        this.monthDeadData.push(element)
                    }
                    // Pending
                    if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time.includes(this.state.todayDate)) {
                        this.todayPendingData.push(element)
                    }
                    else if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayPendingData.push(element);
                    }
                    if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.weekDate) {
                        this.weekPendingData.push(element)
                    }
                    if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.todayDate + ' 23:59:59' && element.date_time >= this.state.monthDate) {
                        this.monthPendingData.push(element)
                    }
                    // Update Time
                    if (element.updated_on !== undefined && element.updated_on.includes(this.state.todayDate)) {

                        this.todayUpdateTimeData.push(element)
                    }
                    else if (element.updated_on !== undefined && element.updated_on.includes(this.state.yesterdayDate)) {
                        this.yesterdayUpdateTimeData.push(element);
                    }
                    if (element.updated_on !== undefined && element.updated_on <= this.state.todayDate + ' 23:59:59' && element.updated_on >= this.state.weekDate) {
                        this.weekUpdateTimeData.push(element)
                    }
                    if (element.updated_on !== undefined && element.updated_on <= this.state.todayDate + ' 23:59:59' && element.updated_on >= this.state.monthDate) {
                        this.monthUpdateTimeData.push(element)
                    }

                    // Callback
                    if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.todayDate)) {

                        this.todayCallbackData.push(element)
                    }
                    else if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayCallbackData.push(element);
                    }
                    if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.todayDate + ' 23:59:59' && element.callback_date_time >= this.state.weekDate) {
                        this.weekCallbackData.push(element)
                    }
                    if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.todayDate + ' 23:59:59' && element.callback_date_time >= this.state.monthDate) {
                        this.monthCallbackData.push(element)
                    }
                    // Daily Report
                    if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.yesterdayDate
                        || element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate)
                        || element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)
                    )) {
                        this.todayDailyReport.push(element)
                    }
                    else if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.dayBeforeYestrday)
                        || element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.dayBeforeYestrday)
                        || element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.dayBeforeYestrday)
                    ) {
                        this.yesterdayDailyReport.push(element);
                    }
                    if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.yesterdayDate + ' 23:59:59' && element.project_meeting_completed_date_time >= this.state.weekDate
                        || element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.yesterdayDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate
                        || element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.yesterdayDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate

                    ) {
                        this.weekDailyReport.push(element)
                    }


                    if (res.data.adminfirstmonthdata.rows.length - 1 === i)
                        this.RenderReport(this.state.currentSalesReportType, this.state.currentChoice);
                });
            }
        }
    }

    RenderReport = (type, choice) => {
        
        //  alert(type,choice)
        if (choice === "sales-report") {
            // alert(1)
            let users = this.props.PnkStore.GetData('userlist').rows;

            let sales_reports = [];
            users !== undefined && users.forEach((user, i) => {
                let date_time = '', total_patch = 0, total_hc_booked = 0, total_hc_completed = 0, total_follow = 0, total_pending_design = 0,
                    total_handover_to_design = 0, total_estimate_pending = 0, total_proposal_sent = 0, total_booking_amount_received = 0, total_project_closed = 0;

                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {

                    if (type === "Today") {
                        this.todayPatchSalesReport.forEach(todayData => {

                            if (todayData.lead_owner === user.user_name) {

                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_patch++;
                                }
                                if (this.currentCity === '') {
                                    total_patch++;
                                }
                                date_time = todayData.date_time
                            }

                        });
                        this.todayHCBookedReport.forEach(todayData => {

                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        this.todayHCCompleted.forEach(todayData => {
                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_hc_completed++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_completed++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        this.todayFollowUp.forEach(todayData => {
                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_follow++;
                                }
                                if (this.currentCity === '') {
                                    total_follow++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        this.todayHandoverDesign.forEach(todayData => {
                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_handover_to_design++;
                                }
                                if (this.currentCity === '') {
                                    total_handover_to_design++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        this.todayProposalSent.forEach(todayData => {
                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_proposal_sent++;
                                }
                                if (this.currentCity === '') {
                                    total_proposal_sent++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        this.todayBookingAmountReceived.forEach(todayData => {
                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_booking_amount_received++;
                                }
                                if (this.currentCity === '') {
                                    total_booking_amount_received++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        this.todayProjectClosed.forEach(todayData => {
                            if (todayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_project_closed++;
                                }
                                if (this.currentCity === '') {
                                    total_project_closed++;
                                }
                                date_time = todayData.date_time
                            }
                        })
                        // console.loge.log("update",update_time)
                        if (total_patch > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_follow > 0 || total_pending_design > 0 || total_handover_to_design > 0 || total_estimate_pending > 0 || total_proposal_sent > 0 || total_booking_amount_received > 0 || total_project_closed > 0) {
                            sales_reports.push({
                                lead_owner: user.user_full_name, reporting_to: user.reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
                                total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
                                total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
                                total_project_closed: total_project_closed, date_time: date_time, total_lead_patched: total_patch
                            });
                        }
                    }
                    else if (type === "Yesterday") {
                        this.yesterdayPatchSalesReport.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_patch++;
                                }
                                if (this.currentCity === '') {
                                    total_patch++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        });
                        this.yesterdayHCBookedReport.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        this.yesterdayHCCompleted.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_hc_completed++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_completed++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        this.yesterdayFollowUp.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_follow++;
                                }
                                if (this.currentCity === '') {
                                    total_follow++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        this.yesterdayHandoverDesign.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_handover_to_design++;
                                }
                                if (this.currentCity === '') {
                                    total_handover_to_design++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        this.yesterdayProposalSent.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_proposal_sent++;
                                }
                                if (this.currentCity === '') {
                                    total_proposal_sent++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        this.yesterdayBookingAmountReceived.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_booking_amount_received++;
                                }
                                if (this.currentCity === '') {
                                    total_booking_amount_received++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        this.yesterdayProjectClosed.forEach(yesterdayData => {
                            if (yesterdayData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_project_closed++;
                                }
                                if (this.currentCity === '') {
                                    total_project_closed++;
                                }
                                date_time = yesterdayData.date_time
                            }
                        })
                        if (total_patch > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_follow > 0 || total_pending_design > 0 || total_handover_to_design > 0 || total_estimate_pending > 0 || total_proposal_sent > 0 || total_booking_amount_received > 0 || total_project_closed > 0) {
                            sales_reports.push({
                                lead_owner: user.user_full_name, reporting_to: user.reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
                                total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
                                total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
                                total_project_closed: total_project_closed, date_time: date_time, total_lead_patched: total_patch
                            });
                        }

                    }
                    else if (type === "Last 7 Days") {
                        this.weekPatchSalesReport.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_patch++;
                                }
                                if (this.currentCity === '') {
                                    total_patch++;
                                }
                                date_time = weekData.date_time
                            }

                        });
                        this.weekHCBookedReport.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        this.weekHCCompleted.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_hc_completed++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_completed++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        this.weekFollowUp.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_follow++;
                                }
                                if (this.currentCity === '') {
                                    total_follow++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        this.weekHandoverDesign.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_handover_to_design++;
                                }
                                if (this.currentCity === '') {
                                    total_handover_to_design++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        this.weekProposalSent.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_proposal_sent++;
                                }
                                if (this.currentCity === '') {
                                    total_proposal_sent++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        this.weekBookingAmountReceived.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_booking_amount_received++;
                                }
                                if (this.currentCity === '') {
                                    total_booking_amount_received++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        this.weekProjectClosed.forEach(weekData => {
                            if (weekData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_project_closed++;
                                }
                                if (this.currentCity === '') {
                                    total_project_closed++;
                                }
                                date_time = weekData.date_time
                            }
                        })
                        if (total_patch > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_follow > 0 || total_pending_design > 0 || total_handover_to_design > 0 || total_estimate_pending > 0 || total_proposal_sent > 0 || total_booking_amount_received > 0 || total_project_closed > 0) {
                            sales_reports.push({
                                lead_owner: user.user_full_name, reporting_to: user.reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
                                total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
                                total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
                                total_project_closed: total_project_closed, date_time: date_time, total_lead_patched: total_patch
                            });
                        }
                    }

                }
                if (users.length - 1 == i) {
                    this.setState({
                        reportData: sales_reports
                    })
                }
            });
        }
        if (choice === "patch-report") {
            let users = this.props.PnkStore.GetData('userlist').rows;

            let total_patch_count = 0;
            let total_patch_count_yesterday = 0;
            let total_patch_count_week = 0;
            let total_patch_count_month = 0;
            let patch_report = [];
            let sales_person_list = []
            let tele_assignee = []
            if (type === "Today") {
                total_patch_count = 0
                this.todayPatchData.forEach(report => {
                    tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.todayPatchData.forEach(report => {
                        if (report.tele_assignee === tele_assignee) {

                            if (this.currentTeleUser !== '' && this.currentTeleUser === report.tele_assignee) {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {
                                        total_patch_count++
                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                            }
                            else if (this.currentTeleUser === '') {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }

                            }
                        }

                    })

                })


            }
            else if (type === "Yesterday") {
                total_patch_count = 0
                this.yesterdayPatchData.forEach(report => {
                    tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.yesterdayPatchData.forEach(report => {

                        if (report.tele_assignee === tele_assignee) {
                            if (this.currentTeleUser !== '' && this.currentTeleUser === report.tele_assignee) {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                            }
                            else if (this.currentTeleUser === '') {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }

                            }
                        }

                    })

                })


            }
            else if (type === "Last 7 Days") {
                total_patch_count = 0
                this.weekPatchData.forEach(report => {
                    tele_assignee.push(report.tele_assignee)
                })
                let unique_tele_assignee = [...new Set(tele_assignee)];
                unique_tele_assignee.map(tele_assignee => {
                    this.weekPatchData.forEach(report => {
                        if (report.tele_assignee === tele_assignee) {
                            if (this.currentTeleUser !== '' && this.currentTeleUser === report.tele_assignee) {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                            }
                            else if (this.currentTeleUser === '') {
                                let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);
                                if (this.currentSalesUser !== '' && this.currentSalesUser === report.lead_owner) {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (typeIndex !== -1) {

                                        let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                                        if (valIndex === -1) {
                                            total_patch_count++
                                            patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                                        }
                                        else {
                                            total_patch_count++
                                            let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                            patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                            patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                                        }

                                    }
                                    else {
                                        total_patch_count++
                                        patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                                    }
                                }

                            }
                        }
                    })

                })


            }


            this.setState({ reportData: patch_report, total_patch_count: total_patch_count })
        }
        else if (choice === 'under-construction') {
            let under_const_arr = []
            if (type === "Today") {
                this.setState({ reportData: [] })
                this.todayUnderConstruction.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on


                        });
                    }
                    else if (this.currentCity === '') {

                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });
                    }
                });
            }
            if (type === "Yesterday") {
                this.setState({ reportData: [] })
                this.yesterdayUnderConstruction.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });
                    }
                    else if (this.currentCity === '') {

                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });
                    }
                });
            }
            if (type === "Last 7 Days") {
                this.setState({ reportData: [] })
                this.weekUnderConstruction.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });
                    }
                    else if (this.currentCity === '') {

                        under_const_arr.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            under_const_date_time: report.under_const_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id,
                            date_time: report.date_time,
                            update_on: report.updated_on

                        });
                    }
                });
            }
            this.setState({
                reportData: under_const_arr
            })
        }
        else if (choice === 'followup-report') {
            let follow_up_report = []
            if (type === "Today") {
                this.todayFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                    else if (this.currentCity === '') {

                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                });
            }
            else if (type === "Yesterday") {
                follow_up_report = []
                this.yesterdayFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                    else if (this.currentCity === '') {

                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                });
            }
            if (type === "Last 7 Days") {
                follow_up_report = []
                this.weekFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                    else if (this.currentCity === '') {

                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                });
            }
            if (type === "Last 30 Days" || type === "Custom") {
                follow_up_report = []
                this.monthFollowupReport.forEach(report => {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                    else if (this.currentCity === '') {

                        follow_up_report.push({
                            lead_owner: report.lead_owner,
                            client_name: report.user_name,
                            follow_up_date_time: report.follow_up_date_time,
                            client_mobile: report.user_mobile_no,
                            id: report._id

                        });
                    }
                });
            }
            follow_up_report.sort((a, b) => new Date(b.follow_up_date_time).getTime() - new Date(a.follow_up_date_time).getTime());


            this.setState({
                reportData: follow_up_report
            })
        }
        else if (choice === "marketing") {

            let marketingData = []
            if (this.leadSourceData && this.leadSourceData.leadsourcelist && this.leadSourceData.leadsourcelist.rows !== undefined && this.leadSourceData.leadsourcelist.rows.length > 0) {
                this.leadSourceData.leadsourcelist.rows.forEach((leadData, i) => {
                    let lead_patched = 0, lead_generated = 0, total_spend = 0, total_closure = 0, dead_after_patch = 0;
                    if (type === "Today") {

                        this.todayPatchData.forEach(todayPatchData => {
                            if (this.currentCity !== '' && (this.currentCity === todayPatchData.lead_city)) {

                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(todayPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (todayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (todayPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(todayPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (todayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (todayPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }

                        });

                        this.todayLeadGeneratedData.forEach(todayData => {
                            if (todayData.lead_source !== undefined) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && todayData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(todayData.lead_source.toLowerCase())) {
                                            lead_generated++
                                        }
                                    }
                                    else if (todayData.lead_source === leadData.lead_source_name) {
                                        lead_generated++
                                    }
                                }
                                else if (this.currentCity === '') {
                                    if (leadData.lead_source_name.includes('Google')) {
                                        if ((leadData.lead_source_name !== undefined && todayData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(todayData.lead_source.toLowerCase())) {
                                            lead_generated++
                                        }
                                    }
                                    else if (todayData.lead_source === leadData.lead_source_name) {
                                        lead_generated++
                                    }
                                }
                            }
                        });

                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch

                            })
                        }
                    }
                    else if (type === "Yesterday") {
                        this.yesterdayPatchData.forEach(yesterdayPatchData => {
                            if (this.currentCity !== '' && (this.currentCity === yesterdayPatchData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (yesterdayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (yesterdayPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (yesterdayPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        });

                        this.yesterdayLeadGeneratedData.forEach(yesterdayLGData => {
                            if (this.currentCity !== '' && (this.currentCity === yesterdayLGData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (yesterdayLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            if (this.currentCity === '') {

                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(yesterdayLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (yesterdayLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        });

                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch
                            })
                        }
                    }
                    if (type === "Last 7 Days") {
                        this.weekPatchData.forEach(weekData => {
                            if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (weekData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (weekData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (weekData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        });

                        this.weekLeadGeneratedData.forEach(weekLGData => {
                            if (this.currentCity !== '' && (this.currentCity === weekLGData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (weekLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(weekLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (weekLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        });

                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch
                            })
                        }
                    }
                    if (type === "Last 30 Days" || type === "Custom") {
                        this.monthPatchData.forEach(monthPatchData => {
                            if (this.currentCity !== '' && (this.currentCity === monthPatchData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(monthPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (monthPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                                if (monthPatchData.lead_current_stage === "Dead") {
                                    dead_after_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(monthPatchData.lead_source.toLowerCase())) {
                                        lead_patched++;
                                    }
                                }
                                else if (monthPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        });

                        this.monthLeadGeneratedData.forEach(monthLGData => {
                            if (this.currentCity !== '' && (this.currentCity === monthLGData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(monthLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (monthLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name.toLowerCase()).includes(monthLGData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (monthLGData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        });

                        if (lead_patched > 0 || lead_generated > 0) {
                            marketingData.push({
                                source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                                dead_after_patch: dead_after_patch
                            })
                        }
                    }

                })



            }
            this.setState({ reportData: marketingData })
        }
        else if (choice === 'sales-rm-daily-report') {
            let rmDailyReport = [];
            let users = this.props.PnkStore.GetData('userlist').rows;
            users.forEach((user, i) => {
                if (type === "Today") {
                    this.todayDailyReport.forEach(reportData => {
                        if (reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time.includes(this.state.yesterdayDate)
                            || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time.includes(this.state.yesterdayDate)
                            || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                            if (user.user_name === reportData.lead_owner) {

                                rmDailyReport.push({
                                    'date': reportData.updated_on,
                                    'client_name': reportData.user_name,
                                    'client_contact': reportData.user_mobile_no,
                                    'rm_name': reportData.lead_owner,
                                    'location': reportData.lead_city,
                                    'hc_completed_date_time': reportData.hc_completed_date_time,
                                    'project_completed_date_time': reportData.project_completed_date_time,
                                    'vc_completed_date_time': reportData.vc_completed_date_time,
                                    'id': reportData._id

                                })
                            }
                        }

                    })

                }
                if (type === 'Yesterday') {

                    this.yesterdayDailyReport.forEach(reportData => {
                        console.log("reportData", reportData)
                        if (reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time.includes(this.state.dayBeforeYestrday)
                            || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time.includes(this.state.dayBeforeYestrday)
                            || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time.includes(this.state.dayBeforeYestrday)) {

                            if (user.user_name === reportData.lead_owner) {
                                // console.loge.log("reportData",reportData)
                                rmDailyReport.push({
                                    'date': reportData.updated_on,
                                    'rm_name': reportData.lead_owner,
                                    'client_name': reportData.user_name,
                                    'client_contact': reportData.user_mobile_no,
                                    'location': reportData.lead_city,
                                    'hc_completed_date_time': reportData.hc_completed_date_time,
                                    'project_completed_date_time': reportData.project_completed_date_time,
                                    'vc_completed_date_time': reportData.vc_completed_date_time,
                                    'id': reportData._id

                                })
                            }
                        }

                    })

                }
                if (type === 'Last 7 Days') {
                    // alert(1)
                    this.weekDailyReport.forEach(reportData => {
                        if (reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time <= this.state.yesterdayDate + ' 23:59:59' && reportData.project_meeting_completed_date_time >= this.state.weekDate
                            || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time <= this.state.yesterdayDate + ' 23:59:59' && reportData.hc_completed_date_time >= this.state.weekDate
                            || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time <= this.state.yesterdayDate + ' 23:59:59' && reportData.vc_completed_date_time >= this.state.weekDate) {

                            if (user.user_name === reportData.lead_owner) {
                                // console.loge.log("reportData",reportData)
                                rmDailyReport.push({
                                    'date': reportData.updated_on,
                                    'rm_name': reportData.lead_owner,
                                    'client_name': reportData.user_name,
                                    'client_contact': reportData.user_mobile_no,
                                    'location': reportData.lead_city,
                                    'hc_completed_date_time': reportData.hc_completed_date_time,
                                    'project_completed_date_time': reportData.project_completed_date_time,
                                    'vc_completed_date_time': reportData.vc_completed_date_time,
                                    'id': reportData._id

                                })
                            }
                        }

                    })

                }

            })
            this.setState({ reportData: rmDailyReport })
        }
        else if (choice === 'campaign-report') {
         
            let camapign_data = [];
            let campaign_name_list = []
            let total_campaign_count = 0;
           

            // let unique_campaign_names = [...new Set(campaign_name_list)];
            let unique_campaign_names = [];
            let unique_source_names = [];

            let data_arr = '';

            if (type === "Today") {
                data_arr = this.todayLeadGeneratedData;
            }
            else if (type === "Yesterday") {
                data_arr = this.yesterdayLeadGeneratedData;
            }
            else if (type === "Last 7 Days") {
                data_arr = this.weekLeadGeneratedData;
            }
            else if (type === "Last 30 Days" || type === 'Custom') {
                data_arr = this.monthLeadGeneratedData;
                // alert(JSON.stringify(this.monthLeadGeneratedData));
            }

       //     console.log(data_arr);

            data_arr.forEach(report => {
                if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                    if (!unique_campaign_names.includes(report.lead_campaign)) {
                        unique_campaign_names.push(report.lead_campaign);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: report.lead_campaign })
                    }
                }
                else {
                    // alert(report.lead_source);
                    if (!unique_source_names.includes(report.lead_source)) {
                        unique_source_names.push(report.lead_source);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: '' })
                    }
                }
                // if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                //     campaign_name_list.push(report.lead_campaign)
                // }
         //       console.log(campaign_name_list);
            });
            // let unique_campaign_names = [...new Set(campaign_name_list)];

            campaign_name_list.map(element => {
                let patch_count = 0;
                let dead_count = 0;
                let callback_count =0;
                let adset_arr = [];
                let ads_arr = [];
                let source_patch_count = 0;
                let source_dead_count = 0;
                let source_callback_count =0;

                data_arr.forEach(report => {
                    let campaign_name = element.lead_campaign
                    let lead_source = element.lead_source
                  //  alert(lead_source)
                    if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                             
                    

                        if (report.lead_campaign == campaign_name) {

                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }

                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }

                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                callback_count++;
                            }

                           
                            let typeIndex = camapign_data.findIndex(type => report.lead_campaign === type.campaign_name);

                           
                            if (typeIndex !== -1) {
                               
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = patch_count
                                camapign_data[typeIndex].dead_count = dead_count
                                camapign_data[typeIndex].callback_count = callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': patch_count,'dead_count': dead_count,'callback_count': callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                    else {
                        if (report.lead_source == lead_source) {

                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }
                      
                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }


                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                source_patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                source_dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                source_callback_count++;
                            }
                            let typeIndex = camapign_data.findIndex(type => report.lead_source === type.lead_source);
                            if (typeIndex !== -1) {
                              
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = source_patch_count
                                camapign_data[typeIndex].dead_count = source_dead_count
                                camapign_data[typeIndex].callback_count = source_callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': source_patch_count,'dead_count': source_dead_count,'callback_count': source_callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                })
            })

            this.setState({
                reportData: camapign_data, total_campaign_count: total_campaign_count
            })

        }
        else if (choice === 'daily-report') {
            let insideSalesData = []
            let users = this.props.PnkStore.GetData('userlist').rows;
            users.forEach((user, i) => {
                let lead_patched = 0, lead_generated = 0, lead_called = 0, total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                if (type === "Today") {

                    this.todayPatchData.forEach(todayPatchedData => {
                        if (todayPatchedData.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayPatchedData.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }



                    });

                    this.todayLeadGeneratedData.forEach(todayLGData => {
                        if (todayLGData.tele_assignee === user.user_name || todayLGData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayLGData.lead_city)) {
                                lead_generated++

                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.todayUpdateTimeData.forEach(todayUpdatedData => {
                        if (todayUpdatedData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayUpdatedData.lead_city)) {
                                if (todayUpdatedData.lead_current_stage === "RNR")
                                    total_rnr++
                                if (todayUpdatedData.lead_current_stage === "Hold")
                                    total_hold++
                            }


                            if (this.currentCity === '') {
                                if (todayUpdatedData.lead_current_stage === "RNR")
                                    total_rnr++
                                if (todayUpdatedData.lead_current_stage === "Hold")
                                    total_hold++

                            }
                        }

                    })
                    this.todayPendingData.forEach(todayPendingdData => {
                        if (todayPendingdData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayPendingdData.lead_city)) {
                                total_pending++

                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.todayCallbackData.forEach(todayCallbackData => {
                        if (todayCallbackData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayCallbackData.lead_city)) {
                                total_callback++

                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.todayDeadData.forEach(todayDeadData => {
                        if (todayDeadData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayDeadData.lead_city)) {
                                total_dead++

                            }


                            if (this.currentCity === '') {

                                total_dead++


                            }
                        }
                    });

                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name

                        })
                    }
                }
                else if (type === "Yesterday") {
                    this.yesterdayPatchData.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }
                    });

                    this.yesterdayLeadGeneratedData.forEach(report => {
                        if (report.tele_assignee === user.user_name || report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_generated++

                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.yesterdayUpdateTimeData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
                            }


                            if (this.currentCity === '') {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++

                            }
                        }

                    })
                    this.yesterdayPendingData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_pending++

                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.yesterdayCallbackData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_callback++

                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.yesterdayDeadData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_dead++

                            }


                            if (this.currentCity === '') {

                                total_dead++


                            }
                        }
                    });

                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name

                        })
                    }
                }
                else if (type === "Last 7 Days") {
                    this.weekPatchData.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }
                    });

                    this.weekLeadGeneratedData.forEach(report => {
                        if (report.tele_assignee === user.user_name || report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_generated++

                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.weekUpdateTimeData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
                            }


                            if (this.currentCity === '') {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++

                            }
                        }

                    })
                    this.weekPendingData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_pending++

                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.weekCallbackData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_callback++

                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.weekDeadData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_dead++

                            }


                            if (this.currentCity === '') {

                                total_dead++


                            }
                        }
                    });

                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name

                        })
                    }
                }

                else if (type === "Last 30 Days" || type === "Custom") {
                    this.monthPatchData.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }
                    });

                    this.monthLeadGeneratedData.forEach(report => {
                        if (report.lead_owner === user.user_name || report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_generated++

                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.monthUpdateTimeData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
                            }


                            if (this.currentCity === '') {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++

                            }
                        }

                    })
                    this.monthPendingData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_pending++

                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.monthCallbackData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_callback++

                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.monthDeadData.forEach(report => {
                        if (report.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_dead++

                            }


                            if (this.currentCity === '') {

                                total_dead++


                            }
                        }
                    });

                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name

                        })
                    }

                }




            })
            this.setState({
                reportData: insideSalesData
            })
        }
        else if (choice === "inside-sales-report") {
            // alert(1)

            let insideSalesData = []

            let users = this.props.PnkStore.GetData('userlist').rows;
            let sales_reports = [];
            users.forEach((user, i) => {

                let lead_patched = 0, lead_generated = 0, lead_called = 0, no_budget = 0, not_answered = 0, not_interested = 0, other_city = 0, window_shopping = 0, other_reason = 0, total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                let total_hc_booked = 0, total_hcna = 0;
                if (user.user_profile[0].profile_name !== undefined && (user.user_profile[0].profile_name.includes('Telecalling') || user.user_profile[0].profile_name.includes('Administrator'))) {
                    if (type === "Today") {
                        this.todayHCBookedReport.forEach(todayData => {

                            if (todayData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                            }
                        })
                        this.todayHCNAReport.forEach(todayData => {

                            if (todayData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_hcna++;
                                }
                                if (this.currentCity === '') {
                                    total_hcna++;
                                }
                            }
                        })
                        this.todayPatchData.forEach(todayPatchedData => {
                            if (todayPatchedData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayPatchedData.lead_city)) {
                                    lead_patched++
                                }
                                if (this.currentCity === '') {
                                    lead_patched++
                                }
                            }



                        });

                        this.todayExotelCalled.forEach(todayCalleddData => {

                            if (todayCalleddData.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++
                                }
                                if (this.currentCity === '') {
                                    lead_called++
                                }
                            }
                        });
                        this.todayLeadGeneratedData.forEach(todayLGData => {
                            if (todayLGData.tele_assignee === user.user_name || todayLGData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayLGData.lead_city)) {
                                    lead_generated++

                                }
                                if (this.currentCity === '') {
                                    lead_generated++
                                }
                            }
                        });
                        if (this.currentInsideSalesType === "dead") {
                            // alert(1)
                            this.todayDeadData.forEach(todayDeadData => {
                                if (todayDeadData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayDeadData.lead_city)) {
                                        total_dead++
                                        if (todayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (todayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (todayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (todayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (todayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (todayDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }


                                    if (this.currentCity === '') {

                                        total_dead++
                                        if (todayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (todayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (todayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (todayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (todayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (todayDeadData.lead_dead_reason === "other")
                                            other_reason++

                                    }
                                }
                            });
                        }
                        if (this.currentInsideSalesType === "pending") {
                            // alert(1)
                            this.todayPendingData.forEach(todayPendingdData => {
                                if (todayPendingdData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayPendingdData.lead_city)) {

                                        total_pending++

                                    }
                                    if (this.currentCity === '') {
                                        // console.loge.log("todayPendingdData",todayPendingdData)
                                        total_pending++
                                    }
                                }
                            })
                            this.todayUpdateTimeData.forEach(todayUpdatedData => {
                                if (todayUpdatedData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayUpdatedData.lead_city)) {
                                        if (todayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (todayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++
                                    }


                                    if (this.currentCity === '') {
                                        if (todayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (todayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++

                                    }
                                }

                            })
                            this.todayCallbackData.forEach(todayCallbackData => {
                                if (todayCallbackData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayCallbackData.lead_city)) {
                                        total_callback++

                                    }
                                    if (this.currentCity === '') {
                                        total_callback++
                                    }
                                }
                            })

                        }
                        if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                            insideSalesData.push({
                                lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                                not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                                total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                                total_hc_booked: total_hc_booked, total_hcna: total_hcna

                            })
                        }
                    }
                    if (type === "Yesterday") {
                        this.yesterdayHCBookedReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                            }
                        })
                        this.yesterdayHCNAReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hcna++;
                                }
                                if (this.currentCity === '') {
                                    total_hcna++;
                                }
                            }
                        })
                        this.yesterdayPatchData.forEach(yesterdayPatchedData => {
                            if (yesterdayPatchedData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayPatchedData.lead_city)) {
                                    lead_patched++
                                }
                                if (this.currentCity === '') {
                                    lead_patched++
                                }
                            }

                        });
                        this.yesterdayExotelCalled.forEach(yesterdayCalleddData => {

                            if (yesterdayCalleddData.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++
                                }
                                if (this.currentCity === '') {
                                    lead_called++
                                }
                            }
                        });
                        // this.yesterdayLeadCalledData.forEach(yesterdayCalleddData => {
                        //     if (yesterdayCalleddData.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === yesterdayCalleddData.lead_city)) {
                        //             lead_called++
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++
                        //         }
                        //     }
                        // });
                        this.yesterdayLeadGeneratedData.forEach(yesterdayLGData => {
                            if (yesterdayLGData.tele_assignee === user.user_name || yesterdayLGData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayLGData.lead_city)) {
                                    lead_generated++
                                }
                                if (this.currentCity === '') {
                                    lead_generated++
                                }
                            }
                        });
                        if (this.currentInsideSalesType === "dead") {
                            this.yesterdayDeadData.forEach(yesterdayDeadData => {
                                if (yesterdayDeadData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayDeadData.lead_city)) {
                                        total_dead++
                                        if (yesterdayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (yesterdayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (yesterdayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (yesterdayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (yesterdayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (yesterdayDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                    if (this.currentCity === '') {
                                        total_dead++
                                        if (yesterdayDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (yesterdayDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (yesterdayDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (yesterdayDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (yesterdayDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (yesterdayDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                }

                            });
                        }
                        if (this.currentInsideSalesType === "pending") {
                            this.yesterdayPendingData.forEach(yesterdayPendingdData => {
                                if (yesterdayPendingdData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayPendingdData.lead_city)) {
                                        total_pending++

                                    }
                                    if (this.currentCity === '') {
                                        total_pending++
                                    }
                                }
                            })
                            this.yesterdayUpdateTimeData.forEach(yesterdayUpdatedData => {
                                if (yesterdayUpdatedData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayUpdatedData.lead_city)) {
                                        if (yesterdayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (yesterdayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++

                                    }
                                    if (this.currentCity === '') {
                                        if (yesterdayUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (yesterdayUpdatedData.lead_current_stage === "Hold")
                                            total_hold++
                                    }
                                }
                            })
                            this.yesterdayCallbackData.forEach(yesterdayCallbackData => {
                                if (yesterdayCallbackData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayCallbackData.lead_city)) {
                                        total_callback++

                                    }
                                    if (this.currentCity === '') {
                                        total_callback++
                                    }
                                }
                            })

                        }
                        if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                            insideSalesData.push({
                                lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.yesterdayDate,
                                not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                                total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                                total_hc_booked: total_hc_booked, total_hcna: total_hcna
                            })
                        }
                    }
                    if (type === "Last 7 Days" || type === "Custom") {
                        this.weekHCBookedReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hc_booked++;
                                }
                                if (this.currentCity === '') {
                                    total_hc_booked++;
                                }
                            }
                        })
                        this.weekHCNAReport.forEach(report => {

                            if (report.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    total_hcna++;
                                }
                                if (this.currentCity === '') {
                                    total_hcna++;
                                }
                            }
                        })
                        this.weekPatchData.forEach(weekPatchedData => {
                            if (weekPatchedData.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekPatchedData.lead_city)) {
                                    lead_patched++
                                }
                                if (this.currentCity === '') {
                                    lead_patched++
                                }
                            }

                        });
                        this.weekExotelCalled.forEach(weekCalleddData => {

                            if (weekCalleddData.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++
                                }
                                if (this.currentCity === '') {
                                    lead_called++
                                }
                            }
                        });
                        // this.weekLeadCalledData.forEach(weekCalleddData => {
                        //     if (weekCalleddData.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === weekCalleddData.lead_city)) {
                        //             lead_called++
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++
                        //         }
                        //     }
                        // });
                        this.weekLeadGeneratedData.forEach(weekLGData => {
                            if (weekLGData.tele_assignee === user.user_name || weekLGData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekLGData.lead_city)) {
                                    lead_generated++
                                }
                                if (this.currentCity === '') {
                                    lead_generated++
                                }
                            }
                        });
                        if (this.currentInsideSalesType === "dead") {
                            this.weekDeadData.forEach(weekDeadData => {
                                if (weekDeadData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekDeadData.lead_city)) {
                                        total_dead++
                                        if (weekDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (weekDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (weekDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (weekDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (weekDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (weekDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                    if (this.currentCity === '') {
                                        total_dead++
                                        if (weekDeadData.lead_dead_reason === "call not answered")
                                            not_answered++
                                        else if (weekDeadData.lead_dead_reason === "not interested")
                                            not_interested++
                                        else if (weekDeadData.lead_dead_reason === "no budget")
                                            no_budget++
                                        else if (weekDeadData.lead_dead_reason === "other city")
                                            other_city++
                                        else if (weekDeadData.lead_dead_reason === "window shopping")
                                            window_shopping++
                                        else if (weekDeadData.lead_dead_reason === "other")
                                            other_reason++
                                    }
                                }

                            });
                        }
                        if (this.currentInsideSalesType === "pending") {
                            this.weekPendingData.forEach(weekPendingdData => {
                                if (weekPendingdData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekPendingdData.lead_city)) {
                                        total_pending++

                                    }
                                    if (this.currentCity === '') {
                                        total_pending++
                                    }
                                }
                            })
                            this.weekUpdateTimeData.forEach(weekUpdatedData => {
                                if (weekUpdatedData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekUpdatedData.lead_city)) {
                                        if (weekUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (weekUpdatedData.lead_current_stage === "Hold")
                                            total_hold++

                                    }
                                    if (this.currentCity === '') {
                                        if (weekUpdatedData.lead_current_stage === "RNR")
                                            total_rnr++
                                        if (weekUpdatedData.lead_current_stage === "Hold")
                                            total_hold++
                                    }
                                }
                            })
                            this.weekCallbackData.forEach(weekCallbackData => {
                                if (weekCallbackData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekCallbackData.lead_city)) {
                                        total_callback++

                                    }
                                    if (this.currentCity === '') {
                                        total_callback++
                                    }
                                }
                            })

                        }
                        if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                            insideSalesData.push({
                                lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.weekDate + ' To ' + this.state.todayDate,
                                not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                                total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                                total_hc_booked: total_hc_booked, total_hcna: total_hcna
                            })
                        }

                    }
                    // if (type === "Last 30 Days" || type === "Custom") {
                    //     this.monthPatchData.forEach(monthPatchedData => {
                    //         if (monthPatchedData.tele_assignee === user.user_name) {
                    //             if (this.currentCity !== '' && (this.currentCity === monthPatchedData.lead_city)) {
                    //                 lead_patched++
                    //             }
                    //             if (this.currentCity === '') {
                    //                 lead_patched++
                    //             }
                    //         }

                    //     });
                    //     this.monthExotelCalled.forEach(monthCalleddData => {

                    //         if (monthCalleddData.caller_name === user.user_name) {
                    //             if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                    //                 lead_called++
                    //             }
                    //             if (this.currentCity === '') {
                    //                 lead_called++
                    //             }
                    //         }
                    //     });
                    //     // this.monthLeadCalledData.forEach(monthCalleddData => {
                    //     //     if (monthCalleddData.tele_assignee === user.user_name) {
                    //     //         if (this.currentCity !== '' && (this.currentCity === monthCalleddData.lead_city)) {
                    //     //             lead_called++
                    //     //         }
                    //     //         if (this.currentCity === '') {
                    //     //             lead_called++
                    //     //         }
                    //     //     }
                    //     // });
                    //     this.monthLeadGeneratedData.forEach(monthLGData => {
                    //         if (monthLGData.tele_assignee === user.user_name || monthLGData.lead_owner === user.user_name) {
                    //             if (this.currentCity !== '' && (this.currentCity === monthLGData.lead_city)) {
                    //                 lead_generated++
                    //             }
                    //             if (this.currentCity === '') {
                    //                 lead_generated++
                    //             }
                    //         }
                    //     });
                    //     if (this.currentInsideSalesType === "dead") {
                    //         this.monthDeadData.forEach(monthDeadData => {
                    //             if (monthDeadData.lead_owner === user.user_name) {
                    //                 if (this.currentCity !== '' && (this.currentCity === monthDeadData.lead_city)) {
                    //                     total_dead++
                    //                     if (monthDeadData.lead_dead_reason === "call not answered")
                    //                         not_answered++
                    //                     else if (monthDeadData.lead_dead_reason === "not interested")
                    //                         not_interested++
                    //                     else if (monthDeadData.lead_dead_reason === "no budget")
                    //                         no_budget++
                    //                     else if (monthDeadData.lead_dead_reason === "other city")
                    //                         other_city++
                    //                     else if (monthDeadData.lead_dead_reason === "window shopping")
                    //                         window_shopping++
                    //                     else if (monthDeadData.lead_dead_reason === "other")
                    //                         other_reason++
                    //                 }
                    //                 if (this.currentCity === '') {
                    //                     total_dead++
                    //                     if (monthDeadData.lead_dead_reason === "call not answered")
                    //                         not_answered++
                    //                     else if (monthDeadData.lead_dead_reason === "not interested")
                    //                         not_interested++
                    //                     else if (monthDeadData.lead_dead_reason === "no budget")
                    //                         no_budget++
                    //                     else if (monthDeadData.lead_dead_reason === "other city")
                    //                         other_city++
                    //                     else if (monthDeadData.lead_dead_reason === "window shopping")
                    //                         window_shopping++
                    //                     else if (monthDeadData.lead_dead_reason === "other")
                    //                         other_reason++
                    //                 }
                    //             }

                    //         });
                    //     }
                    //     if (this.currentInsideSalesType === "pending") {
                    //         this.monthPendingData.forEach(monthPendingdData => {
                    //             if (monthPendingdData.lead_owner === user.user_name) {
                    //                 if (this.currentCity !== '' && (this.currentCity === monthPendingdData.lead_city)) {
                    //                     total_pending++

                    //                 }
                    //                 if (this.currentCity === '') {
                    //                     total_pending++
                    //                 }
                    //             }
                    //         })
                    //         this.monthUpdateTimeData.forEach(monthUpdatedData => {
                    //             if (monthUpdatedData.lead_owner === user.user_name) {
                    //                 if (this.currentCity !== '' && (this.currentCity === monthUpdatedData.lead_city)) {
                    //                     if (monthUpdatedData.lead_current_stage === "RNR")
                    //                         total_rnr++
                    //                     if (monthUpdatedData.lead_current_stage === "Hold")
                    //                         total_hold++

                    //                 }
                    //                 if (this.currentCity === '') {
                    //                     if (monthUpdatedData.lead_current_stage === "RNR")
                    //                         total_rnr++
                    //                     if (monthUpdatedData.lead_current_stage === "Hold")
                    //                         total_hold++
                    //                 }
                    //             }
                    //         })
                    //         this.monthCallbackData.forEach(monthCallbackData => {
                    //             if (monthCallbackData.lead_owner === user.user_name) {
                    //                 if (this.currentCity !== '' && (this.currentCity === monthCallbackData.lead_city)) {
                    //                     total_callback++

                    //                 }
                    //                 if (this.currentCity === '') {
                    //                     total_callback++
                    //                 }
                    //             }
                    //         })

                    //     }
                    //     if (lead_generated > 0 || lead_patched > 0 || lead_called > 0) {
                    //         insideSalesData.push({
                    //             lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.monthDate + ' To ' + this.state.todayDate,
                    //             not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                    //             total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name

                    //         })
                    //     }
                    // }
                }
                if (users.length - 1 == i) {
                    this.setState({ reportData: insideSalesData })
                }

            })
        }
        else if (choice === 'sales-meeting-count') {
            let users = this.props.PnkStore.GetData('userlist').rows;
            let sales_count_arr = []
            users.forEach((user, i) => {
                let hc_count = 0, vc_count = 0, pm_count = 0, total_count = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if (type === "Today") {
                        this.todayHCCompleted.forEach(todayHCData => {
                            if (user.user_name === todayHCData.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === todayHCData.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {

                                        hc_count++;
                                        total_count++



                                    }
                                    else if (this.currentCity === '') {

                                        hc_count++;
                                        total_count++

                                    }

                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                        hc_count++;
                                        total_count++

                                    }
                                    else if (this.currentCity === '') {
                                        hc_count++;
                                        total_count++

                                    }
                                }
                            }


                        });
                        this.todayPMCompleted.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                        pm_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        pm_count++
                                        total_count++
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        pm_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        pm_count++
                                        total_count++
                                    }
                                }
                            }
                        })
                        this.todayVCCompletedReport.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        vc_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        vc_count++
                                        total_count++
                                    }
                                }

                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        vc_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        vc_count++
                                        total_count++
                                    }
                                }
                            }

                        })
                    }
                    else if (type === "Yesterday") {
                        this.yesterdayHCCompleted.forEach(todayHCData => {
                            if (user.user_name === todayHCData.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === todayHCData.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {

                                        hc_count++;
                                        total_count++



                                    }
                                    else if (this.currentCity === '') {

                                        hc_count++;
                                        total_count++

                                    }

                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                        hc_count++;
                                        total_count++

                                    }
                                    else if (this.currentCity === '') {
                                        hc_count++;
                                        total_count++

                                    }
                                }
                            }


                        });
                        this.yesterdayPMCompleted.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                        pm_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        pm_count++
                                        total_count++
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        pm_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        pm_count++
                                        total_count++
                                    }
                                }
                            }
                        })
                        this.yesterdayVCCompletedReport.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        vc_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        vc_count++
                                        total_count++
                                    }
                                }

                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        vc_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        vc_count++
                                        total_count++
                                    }
                                }
                            }

                        })

                    }
                    else if (type === "Last 7 Days") {
                        this.weekHCCompleted.forEach(todayHCData => {
                            if (user.user_name === todayHCData.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === todayHCData.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {

                                        hc_count++;
                                        total_count++



                                    }
                                    else if (this.currentCity === '') {

                                        hc_count++;
                                        total_count++

                                    }

                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                        hc_count++;
                                        total_count++

                                    }
                                    else if (this.currentCity === '') {
                                        hc_count++;
                                        total_count++

                                    }
                                }
                            }


                        });
                        this.weekPMCompleted.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                        pm_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        pm_count++
                                        total_count++
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        pm_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        pm_count++
                                        total_count++
                                    }
                                }
                            }
                        })
                        this.weekVCCompletedReport.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        vc_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        vc_count++
                                        total_count++
                                    }
                                }

                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        vc_count++
                                        total_count++
                                    }
                                    else if (this.currentCity === '') {
                                        vc_count++
                                        total_count++
                                    }
                                }
                            }

                        })

                    }
                }
                if (total_count > 0) {
                    sales_count_arr.push({
                        lead_owner: user.user_name,
                        sales_tl: user.reporting_to,
                        hc_count: hc_count,
                        pm_count: pm_count,
                        vc_count: vc_count,
                        total_count: total_count
                    })
                }
            })
            this.setState
                ({
                    reportData: sales_count_arr
                })
        }
        else if (choice === "meeting-tracker") {
           // alert('kkkk')
            if (type === "Today") {

            }
            let users = this.props.PnkStore.GetData('userlist').rows;
            // console.loge.log(" this.todayHCBookedReport", this.todayHCBookedReport)
            var hc_report = [];
            let meeting_tracker_data = []

            let total_closure = 0, lead_owner = '', client_name = '', project_value = '', hc_date_time = '', stage = '', name_of_designer = '';
            // if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if (type === "Today") {

                        //         if(this.hcPmScheduleType==='hc')
                        //         {
                        //             this.setState({
                        //                 reportData:[]
                        //             })
                        //             // alert(1)
                        //         this.todayHCBookedReport.forEach(todayHCData => {

                        //             if(user.user_name===todayHCData.lead_owner){
                        //                 if (this.currentSalesUser !== '' && (this.currentSalesUser === todayHCData.lead_owner)) {
                        //                     if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {

                        //                         // if (todayHCData.designer_name !== '' && todayHCData.designer_name !== null && todayHCData.designer_name !== undefined)
                        //                         // name_of_designer = todayHCData.designer_name

                        //                         hc_report.push({
                        //                             lead_owner: todayHCData.lead_owner, 
                        //                             client_name: todayHCData.user_name, 
                        //                             hc_date_time: todayHCData.hc_booked_date_time, 
                        //                             sales_tl:user.reporting_to,
                        //                             location:todayHCData.preferred_area,
                        //                             hc_completed_date_time:todayHCData.hc_completed_date_time

                        //                         });
                        //                     }
                        //                     else if (this.currentCity === '') {

                        //                         hc_report.push({
                        //                             lead_owner: todayHCData.lead_owner, 
                        //                             client_name: todayHCData.user_name, 
                        //                             hc_date_time: todayHCData.hc_booked_date_time, 
                        //                             sales_tl:user.reporting_to,
                        //                             location:todayHCData.preferred_area,
                        //                             hc_completed_date_time:todayHCData.hc_completed_date_time

                        //                         });
                        //                     }

                        //                 }
                        //                 else if (this.currentSalesUser === '') {
                        //                     if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                        //                         hc_report.push({
                        //                             lead_owner: todayHCData.lead_owner, 
                        //                             client_name: todayHCData.user_name, 
                        //                             hc_date_time: todayHCData.hc_booked_date_time, 
                        //                             sales_tl:user.reporting_to,
                        //                             location:todayHCData.preferred_area,
                        //                             hc_completed_date_time:todayHCData.hc_completed_date_time

                        //                         });
                        //                     }
                        //                     else if (this.currentCity === '') {
                        //                         hc_report.push({
                        //                             lead_owner: todayHCData.lead_owner, 
                        //                             client_name: todayHCData.user_name, 
                        //                             hc_date_time: todayHCData.hc_booked_date_time, 
                        //                             sales_tl:user.reporting_to,
                        //                             location:todayHCData.preferred_area,
                        //                             hc_completed_date_time:todayHCData.hc_completed_date_time

                        //                         });
                        //                     }
                        //                 }
                        //             }


                        //         });
                        //     }
                        //     else if(this.hcPmScheduleType==="project-meeting"){
                        //         this.setState({
                        //             reportData:[]
                        //         })
                        //         this.todayPMBookedReport.forEach(report => {
                        //             // console.loge.log("assist",this.todayPMBookedReport)
                        //             if(user.user_name===report.lead_owner){
                        //             if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                        //                 if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         project_estimate:report.project_estimate,
                        //                         hc_completed_date_time:report.hc_completed_date_time,
                        //                         project_meeting_date_time:report.project_meeting_date_time,
                        //                         designer_name:report.designer_name,
                        //                         assist_name:report.assist_name

                        //                     });
                        //                 }
                        //                 else if (this.currentCity === '') {
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         project_estimate:report.project_estimate,
                        //                         hc_completed_date_time:report.hc_completed_date_time,
                        //                         project_meeting_date_time:report.project_meeting_date_time,
                        //                         designer_name:report.designer_name,
                        //                         assist_name:report.assist_name

                        //                     });
                        //                 }
                        //             }
                        //             else if (this.currentSalesUser === '') {
                        //                 if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         project_estimate:report.project_estimate,
                        //                         hc_completed_date_time:report.hc_completed_date_time,
                        //                         project_meeting_date_time:report.project_meeting_date_time,
                        //                         designer_name:report.designer_name,
                        //                         assist_name:report.assist_name

                        //                     });
                        //                 }
                        //                 else if (this.currentCity === '') {
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         project_estimate:report.project_estimate,
                        //                         hc_completed_date_time:report.hc_completed_date_time,
                        //                         project_meeting_date_time:report.project_meeting_date_time,
                        //                         designer_name:report.designer_name,
                        //                         assist_name:report.assist_name

                        //                     });
                        //                 }
                        //             }
                        //         }
                        //         })

                        //     }
                        //     else if(this.hcPmScheduleType==="vc"){
                        //         this.setState({
                        //             reportData:[]
                        //         })
                        //         this.todayVCCompletedReport.forEach(report => {
                        //             if(user.user_name===report.lead_owner){
                        //             if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                        //                 if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         location:report.preferred_area,
                        //                         vc_completed_date_time:report.vc_completed_date_time


                        //                     });
                        //                 }
                        //                 else if (this.currentCity === '') {
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         location:report.preferred_area,
                        //                         vc_completed_date_time:report.vc_completed_date_time


                        //                     });
                        //                 }
                        //             }

                        //             else if (this.currentSalesUser === '') {
                        //                 if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         location:report.preferred_area,
                        //                         vc_completed_date_time:report.vc_completed_date_time

                        //                     });
                        //                 }
                        //                 else if (this.currentCity === '') {
                        //                     // console.loge.log("report",report)
                        //                     hc_report.push({
                        //                         lead_owner: report.lead_owner, 
                        //                         client_name: report.user_name, 
                        //                         hc_date_time: report.hc_booked_date_time, 
                        //                         sales_tl:user.reporting_to,
                        //                         location:report.preferred_area,
                        //                         vc_completed_date_time:report.vc_completed_date_time

                        //                     });
                        //                 }
                        //             }
                        //     }

                        //     })
                        // }
                    }

                    else if (type === "Yesterday") {
                        if (this.hcPmScheduleType === 'hc') {
                            this.setState({
                                reportData: []
                            })
                            this.yesterdayHCBookedReport.forEach(yesterdayHCData => {
                                if (user.user_name === yesterdayHCData.lead_owner) {
                                    if (this.currentSalesUser !== '' && (this.currentSalesUser === yesterdayHCData.lead_owner)) {
                                        if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner,
                                                client_name: yesterdayHCData.user_name,
                                                hc_date_time: yesterdayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: yesterdayHCData.preferred_area,
                                                hc_completed_date_time: yesterdayHCData.hc_completed_date_time


                                            });
                                        }
                                        else if (this.currentCity === '') {

                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner,
                                                client_name: yesterdayHCData.user_name,
                                                hc_date_time: yesterdayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: yesterdayHCData.preferred_area,
                                                hc_completed_date_time: yesterdayHCData.hc_completed_date_time

                                            });
                                        }

                                    }
                                    else if (this.currentSalesUser === '') {
                                        if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner,
                                                client_name: yesterdayHCData.user_name,
                                                hc_date_time: yesterdayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: yesterdayHCData.preferred_area,
                                                hc_completed_date_time: yesterdayHCData.hc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner,
                                                client_name: yesterdayHCData.user_name,
                                                hc_date_time: yesterdayHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: yesterdayHCData.preferred_area,
                                                hc_completed_date_time: yesterdayHCData.hc_completed_date_time

                                            });
                                        }
                                    }

                                }
                            });
                        }
                        else if (this.hcPmScheduleType === "project-meeting") {
                            this.setState({
                                reportData: []
                            })
                            this.yesterdayPMBookedReport.forEach(report => {
                                if (user.user_name === report.lead_owner) {
                                    if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {


                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name



                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                    }
                                    else if (this.currentSalesUser === '') {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            // console.loge.log("report",report)
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                    }
                                }
                            })

                        }
                        else if (this.hcPmScheduleType === "vc") {
                            this.setState({
                                reportData: []
                            })
                            this.yesterdayVCCompletedReport.forEach(report => {
                                if (user.user_name === report.lead_owner) {
                                    if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time


                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time


                                            });
                                        }
                                    }

                                    else if (this.currentSalesUser === '') {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            // console.loge.log("report",report)
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time
                                            });
                                        }
                                    }
                                }

                            })
                        }

                    }
                    else if (type === "Last 7 Days") {
                        this.setState({
                            reportData: []
                        })
                        if (this.hcPmScheduleType === 'hc') {
                            this.weekHCBookedReport.forEach(weekyHCData => {
                                if (user.user_name === weekyHCData.lead_owner) {
                                    if (this.currentSalesUser !== '' && (this.currentSalesUser === weekyHCData.lead_owner)) {
                                        if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: weekyHCData.lead_owner,
                                                client_name: weekyHCData.user_name,
                                                hc_date_time: weekyHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: weekyHCData.preferred_area,
                                                hc_completed_date_time: weekyHCData.hc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: weekyHCData.lead_owner,
                                                client_name: weekyHCData.user_name,
                                                hc_date_time: weekyHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: weekyHCData.preferred_area,
                                                hc_completed_date_time: weekyHCData.hc_completed_date_time

                                            });
                                        }

                                    }
                                    else if (this.currentSalesUser === '') {
                                        if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: weekyHCData.lead_owner,
                                                client_name: weekyHCData.user_name,
                                                hc_date_time: weekyHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: weekyHCData.preferred_area,
                                                hc_completed_date_time: weekyHCData.hc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: weekyHCData.lead_owner,
                                                client_name: weekyHCData.user_name,

                                                hc_date_time: weekyHCData.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: weekyHCData.preferred_area,
                                                hc_completed_date_time: weekyHCData.hc_completed_date_time

                                            });
                                        }
                                    }
                                }

                            });
                        }
                        else if (this.hcPmScheduleType === "project-meeting") {
                            this.setState({
                                reportData: []
                            })
                            this.weekPMBookedReport.forEach(report => {
                                if (user.user_name === report.lead_owner) {
                                    if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                    }
                                    else if (this.currentSalesUser === '') {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                project_estimate: report.project_estimate,
                                                hc_completed_date_time: report.hc_completed_date_time,
                                                project_meeting_date_time: report.project_meeting_date_time,
                                                designer_name: report.designer_name,
                                                assist_name: report.assist_name

                                            });
                                        }
                                    }
                                }
                            })

                        }
                        else if (this.hcPmScheduleType === "vc") {
                            this.setState({
                                reportData: []
                            })
                            this.weekVCCompletedReport.forEach(report => {
                                if (user.user_name === report.lead_owner) {
                                    if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }
                                    }

                                    else if (this.currentSalesUser === '') {
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            // console.loge.log("report",report)
                                            hc_report.push({
                                                lead_owner: report.lead_owner,
                                                client_name: report.user_name,
                                                hc_date_time: report.hc_booked_date_time,
                                                sales_tl: user.reporting_to,
                                                location: report.preferred_area,
                                                vc_completed_date_time: report.vc_completed_date_time

                                            });
                                        }
                                    }
                                }

                            })
                        }
                    }

                }

            })

            this.setState({ reportData: hc_report })
        }
        else if (choice === "Inside Sales") {
            let users = this.props.PnkStore.GetData('userlist').rows;
            let inside_sales_report = [];

            users.forEach((user, i) => {
                let lead_called = 0, lead_patched = 0, dead_leads = 0, pending_calls = 0, tele_user = 0;

                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Telecalling')) {
                    if (type === "Today") {
                        this.todayExotelCalled.forEach(todayCalled => {

                            if (todayCalled.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++;
                                }
                                if (this.currentCity === '') {
                                    lead_called++;
                                }
                            }
                        })
                        this.todayPatchData.forEach(todayPatch => {
                            if (todayPatch.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayPatch.lead_city)) {
                                    lead_patched++;
                                }
                                if (this.currentCity === '') {
                                    lead_patched++;
                                }
                            }
                        })
                        this.todayDeadData.forEach(todayDead => {
                            if (todayDead.lead_current_stage === "Dead") {
                                if (todayDead.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayDead.lead_city)) {
                                        dead_leads++;
                                    }
                                    if (this.currentCity === '') {
                                        dead_leads++;
                                    }
                                }
                            }
                        })
                        this.todayPendingData.forEach(todayPending => {
                            if (todayPending.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayPending.lead_city)) {
                                    pending_calls++;
                                }
                                if (this.currentCity === '') {
                                    pending_calls++;
                                }
                            }
                        })
                        if (lead_patched > 0 || lead_called > 0 || dead_leads > 0 || pending_calls > 0) {
                            inside_sales_report.push({
                                tele_user: user.user_name, lead_called: lead_called, lead_patched: lead_patched, dead_leads: dead_leads, pending_calls: pending_calls
                            });
                        }
                    }
                    else if (type === "Yesterday") {
                        // this.yesterdayLeadCalledData.forEach(yesterdayCalled => {
                        //     if (yesterdayCalled.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === yesterdayCalled.lead_city)) {
                        //             lead_called++;
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++;
                        //         }
                        //     }
                        // })
                        this.yesterdayExotelCalled.forEach(yesterdayCalled => {
                            if (yesterdayCalled.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++;
                                }
                                if (this.currentCity === '') {
                                    lead_called++;
                                }
                            }
                        })
                        this.yesterdayPatchData.forEach(yesterdayPatch => {
                            if (yesterdayPatch.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayPatch.lead_city)) {
                                    lead_patched++;
                                }
                                if (this.currentCity === '') {
                                    lead_patched++;
                                }
                            }
                        })
                        this.yesterdayDeadData.forEach(yesterdayDead => {
                            if (yesterdayDead.lead_current_stage === "Dead") {
                                if (yesterdayDead.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === yesterdayDead.lead_city)) {
                                        dead_leads++;
                                    }
                                    if (this.currentCity === '') {
                                        dead_leads++;
                                    }
                                }
                            }
                        })
                        this.yesterdayPendingData.forEach(yesterdayPending => {
                            if (yesterdayPending.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayPending.lead_city)) {
                                    pending_calls++;
                                }
                                if (this.currentCity === '') {
                                    pending_calls++;
                                }
                            }
                        })
                        if (lead_patched > 0 || lead_called > 0 || dead_leads > 0 || pending_calls > 0) {
                            inside_sales_report.push({
                                tele_user: user.user_name, lead_called: lead_called, lead_patched: lead_patched, dead_leads: dead_leads, pending_calls: pending_calls
                            });
                        }
                    }
                    else if (type === "Last 7 Days") {
                        // this.weekLeadCalledData.forEach(weekCalled => {
                        //     if (weekCalled.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === weekCalled.lead_city)) {
                        //             lead_called++;
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++;
                        //         }
                        //     }
                        // })
                        this.weekExotelCalled.forEach(weekCalled => {
                            if (weekCalled.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++;
                                }
                                if (this.currentCity === '') {
                                    lead_called++;
                                }
                            }
                        })
                        this.weekPatchData.forEach(weekPatch => {
                            if (weekPatch.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekPatch.lead_city)) {
                                    lead_patched++;
                                }
                                if (this.currentCity === '') {
                                    lead_patched++;
                                }
                            }
                        })
                        this.weekDeadData.forEach(weekDead => {
                            if (weekDead.lead_current_stage === "Dead") {
                                if (weekDead.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === weekDead.lead_city)) {
                                        dead_leads++;
                                    }
                                    if (this.currentCity === '') {
                                        dead_leads++;
                                    }
                                }
                            }
                        })
                        this.weekPendingData.forEach(weekPending => {
                            if (weekPending.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekPending.lead_city)) {
                                    pending_calls++;
                                }
                                if (this.currentCity === '') {
                                    pending_calls++;
                                }
                            }
                        })
                        if (lead_patched > 0 || lead_called > 0 || dead_leads > 0 || pending_calls > 0) {
                            inside_sales_report.push({
                                tele_user: user.user_name, lead_called: lead_called, lead_patched: lead_patched, dead_leads: dead_leads, pending_calls: pending_calls
                            });
                        }
                    }
                    else if (type === "Last 30 Days" || type === "Custom") {
                        // this.monthLeadCalledData.forEach(monthCalled => {
                        //     if (monthCalled.tele_assignee === user.user_name) {
                        //         if (this.currentCity !== '' && (this.currentCity === monthCalled.lead_city)) {
                        //             lead_called++;
                        //         }
                        //         if (this.currentCity === '') {
                        //             lead_called++;
                        //         }
                        //     }
                        // })
                        this.monthExotelCalled.forEach(monthCalled => {
                            if (monthCalled.caller_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                    lead_called++;
                                }
                                if (this.currentCity === '') {
                                    lead_called++;
                                }
                            }
                        })
                        this.monthPatchData.forEach(monthPatch => {
                            if (monthPatch.tele_assignee === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === monthPatch.lead_city)) {
                                    lead_patched++;
                                }
                                if (this.currentCity === '') {
                                    lead_patched++;
                                }
                            }
                        })
                        this.monthDeadData.forEach(monthDead => {
                            if (monthDead.lead_current_stage === "Dead") {
                                if (monthDead.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === monthDead.lead_city)) {
                                        dead_leads++;
                                    }
                                    if (this.currentCity === '') {
                                        dead_leads++;
                                    }
                                }
                            }
                        })
                        this.monthPendingData.forEach(monthPending => {
                            if (monthPending.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === monthPending.lead_city)) {
                                    pending_calls++;
                                }
                                if (this.currentCity === '') {
                                    pending_calls++;
                                }
                            }
                        })
                        if (lead_patched > 0 || lead_called > 0 || dead_leads > 0 || pending_calls > 0) {
                            inside_sales_report.push({
                                tele_user: user.user_name, lead_called: lead_called, lead_patched: lead_patched, dead_leads: dead_leads, pending_calls: pending_calls
                            });
                        }
                    }

                }
                this.setState({
                    reportData: inside_sales_report
                })
            })
        }
        else if (choice === "HC-PM Schedule") {
            //    alert(this.hcPmScheduleType)
            let hc_pm_report = []
            let hc_user_list = []
            let hc_time_list = []
            let final_hc_array = []
            let current_array = []
            let hc_user_list_temp = []
            let final_date_array = []
            if (type === "Today") {
                if (this.hcPmScheduleType === 'hc') {
                    this.todayHCBookedReport.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner !== undefined && todayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(todayData.lead_owner)) {
                                    hc_user_list_temp.push(todayData.lead_owner);
                                }
                            }
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                hc_time_list.push(todayData.hc_booked_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (todayData.lead_owner !== undefined && todayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(todayData.lead_owner)) {
                                    hc_user_list_temp.push(todayData.lead_owner);
                                }
                            }
                            if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
                                hc_time_list.push(todayData.hc_booked_date_time);
                            }
                        }

                    })
                    current_array = this.todayHCBookedReport
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.todayPMBookedReport.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner !== undefined && todayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(todayData.lead_owner)) {
                                    hc_user_list_temp.push(todayData.lead_owner);
                                }
                            }
                            if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                hc_time_list.push(todayData.project_meeting_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (todayData.lead_owner !== undefined && todayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(todayData.lead_owner)) {
                                    hc_user_list_temp.push(todayData.lead_owner);
                                }
                            }
                            if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
                                hc_time_list.push(todayData.project_meeting_date_time);
                            }
                        }

                    })
                    current_array = this.todayPMBookedReport
                }
                else if (this.hcPmScheduleType === "vc") {
                    this.todayVCCompletedReport.forEach(todayData => {
                        if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                            if (todayData.lead_owner !== undefined && todayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(todayData.lead_owner)) {
                                    hc_user_list_temp.push(todayData.lead_owner);
                                }
                            }
                            if (todayData.vc_completed_date_time !== undefined && todayData.vc_completed_date_time !== '') {
                                hc_time_list.push(todayData.vc_completed_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (todayData.lead_owner !== undefined && todayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(todayData.lead_owner)) {
                                    hc_user_list_temp.push(todayData.lead_owner);
                                }
                            }
                            if (todayData.vc_completed_date_time !== undefined && todayData.vc_completed_date_time !== '') {
                                hc_time_list.push(todayData.vc_completed_date_time);
                            }
                        }

                    })
                    current_array = this.todayVCCompletedReport
                }


            }
            else if (type === "Yesterday") {
                if (this.hcPmScheduleType === 'hc') {
                    this.yesterdayHCBookedReport.forEach(yesterdayData => {
                        if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                            if (yesterdayData.lead_owner !== undefined && yesterdayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(yesterdayData.lead_owner)) {
                                    hc_user_list_temp.push(yesterdayData.lead_owner);
                                }
                            }
                            if (yesterdayData.hc_booked_date_time !== undefined && yesterdayData.hc_booked_date_time !== '') {
                                hc_time_list.push(yesterdayData.hc_booked_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (yesterdayData.lead_owner !== undefined && yesterdayData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(yesterdayData.lead_owner)) {
                                    hc_user_list_temp.push(yesterdayData.lead_owner);
                                }
                            }
                            if (yesterdayData.hc_booked_date_time !== undefined && yesterdayData.hc_booked_date_time !== '') {
                                hc_time_list.push(yesterdayData.hc_booked_date_time);
                            }
                        }

                    })
                    current_array = this.yesterdayHCBookedReport
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.yesterdayPMBookedReport.forEach(pmData => {

                        if (this.currentCity !== '' && (this.currentCity === pmData.lead_city)) {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.project_meeting_date_time !== undefined && pmData.project_meeting_date_time !== '') {
                                hc_time_list.push(pmData.project_meeting_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.project_meeting_date_time !== undefined && pmData.project_meeting_date_time !== '') {
                                hc_time_list.push(pmData.project_meeting_date_time);
                            }
                        }

                    })
                    current_array = this.yesterdayPMBookedReport
                }
                else if (this.hcPmScheduleType === "vc") {
                    this.yesterdayVCCompletedReport.forEach(pmData => {

                        if (this.currentCity !== '' && (this.currentCity === pmData.lead_city)) {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.vc_completed_date_time !== undefined && pmData.vc_completed_date_time !== '') {
                                hc_time_list.push(pmData.vc_completed_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.vc_completed_date_time !== undefined && pmData.vc_completed_date_time !== '') {
                                hc_time_list.push(pmData.vc_completed_date_time);
                            }
                        }

                    })
                    current_array = this.yesterdayVCCompletedReport
                }
            }
            else if (type === "Last 7 Days") {
                if (this.hcPmScheduleType === 'hc') {
                    this.weekHCBookedReport.forEach(weekData => {
                        if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                            if (weekData.lead_owner !== undefined && weekData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(weekData.lead_owner)) {
                                    hc_user_list_temp.push(weekData.lead_owner);
                                }
                            }
                            if (weekData.hc_booked_date_time !== undefined && weekData.hc_booked_date_time !== '') {
                                hc_time_list.push(weekData.hc_booked_date_time);
                            }
                        }
                        if (this.currentCity === '') {

                            if (weekData.lead_owner !== undefined && weekData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(weekData.lead_owner)) {
                                    hc_user_list_temp.push(weekData.lead_owner);
                                }
                            }
                            if (weekData.hc_booked_date_time !== undefined && weekData.hc_booked_date_time !== '') {
                                hc_time_list.push(weekData.hc_booked_date_time);
                            }
                        }


                    })
                    current_array = this.weekHCBookedReport
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.weekPMBookedReport.forEach(pmData => {
                        if (this.currentCity !== '' && (this.currentCity === pmData.lead_city)) {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.project_meeting_date_time !== undefined && pmData.project_meeting_date_time !== '') {
                                hc_time_list.push(pmData.project_meeting_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.project_meeting_date_time !== undefined && pmData.project_meeting_date_time !== '') {
                                hc_time_list.push(pmData.project_meeting_date_time);
                            }
                        }

                    })
                    current_array = this.weekPMBookedReport
                }
                else if (this.hcPmScheduleType === "vc") {
                    this.weekVCCompletedReport.forEach(pmData => {

                        if (this.currentCity !== '' && (this.currentCity === pmData.lead_city)) {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.vc_completed_date_time !== undefined && pmData.vc_completed_date_time !== '') {
                                hc_time_list.push(pmData.vc_completed_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.vc_completed_date_time !== undefined && pmData.vc_completed_date_time !== '') {
                                hc_time_list.push(pmData.vc_completed_date_time);
                            }
                        }

                    })
                    current_array = this.weekVCCompletedReport
                }
            }
            else if (type === "Last 30 Days" || type === "Custom") {
                if (this.hcPmScheduleType === 'hc') {
                    this.monthHCBookedReport.forEach(monthData => {
                        if (this.currentCity !== '' && (this.currentCity === monthData.lead_city)) {
                            if (monthData.lead_owner !== undefined && monthData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(monthData.lead_owner)) {
                                    hc_user_list_temp.push(monthData.lead_owner);
                                }
                            }
                            if (monthData.hc_booked_date_time !== undefined && monthData.hc_booked_date_time !== '') {
                                hc_time_list.push(monthData.hc_booked_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (monthData.lead_owner !== undefined && monthData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(monthData.lead_owner)) {
                                    hc_user_list_temp.push(monthData.lead_owner);
                                }
                            }
                            if (monthData.hc_booked_date_time !== undefined && monthData.hc_booked_date_time !== '') {
                                hc_time_list.push(monthData.hc_booked_date_time);
                            }
                        }


                    })
                    current_array = this.monthHCBookedReport
                }
                else if (this.hcPmScheduleType === "project-meeting") {
                    this.monthPMBookedReport.forEach(pmData => {
                        if (this.currentCity !== '' && (this.currentCity === pmData.lead_city)) {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.project_meeting_date_time !== undefined && pmData.project_meeting_date_time !== '') {
                                hc_time_list.push(pmData.project_meeting_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.project_meeting_date_time !== undefined && pmData.project_meeting_date_time !== '') {
                                hc_time_list.push(pmData.project_meeting_date_time);
                            }
                        }

                    })
                    current_array = this.monthPMBookedReport
                }
                else if (this.hcPmScheduleType === "vc") {
                    this.monthVCCompletedReport.forEach(pmData => {

                        if (this.currentCity !== '' && (this.currentCity === pmData.lead_city)) {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.vc_completed_date_time !== undefined && pmData.vc_completed_date_time !== '') {
                                hc_time_list.push(pmData.vc_completed_date_time);
                            }
                        }
                        if (this.currentCity === '') {
                            if (pmData.lead_owner !== undefined && pmData.lead_owner !== '') {
                                if (!hc_user_list_temp.includes(pmData.lead_owner)) {
                                    hc_user_list_temp.push(pmData.lead_owner);
                                }
                            }
                            if (pmData.vc_completed_date_time !== undefined && pmData.vc_completed_date_time !== '') {
                                hc_time_list.push(pmData.vc_completed_date_time);
                            }
                        }

                    })
                    current_array = this.monthVCCompletedReport
                }
            }
            hc_user_list_temp.forEach(element => {
                hc_user_list.push({ 'val': element });
            })
            final_hc_array.push({ 'Time': 'Time', list: hc_user_list })

            var final_time_array = hc_time_list.filter((v, i, a) => a.indexOf(v) === i);
            final_time_array.sort((a, b) => a.localeCompare(b))
            final_time_array.forEach(date_time => {
                let typeIndex = final_date_array.findIndex(item => date_time.substr(0, 10) === item);
                if (typeIndex === -1) {
                    final_date_array.push(date_time.substr(0, 10))
                }
            })

            let arr_date = [];
            final_date_array.forEach(date => {
                let arr_list = [];
                final_time_array.forEach(time => {
                    if (time.includes(date)) {

                        let user_list_arr = [];

                        hc_user_list.forEach(user => {
                            let count = 0;
                            let details = [];
                            current_array.forEach((item, i) => {

                                if (this.hcPmScheduleType === 'hc') {
                                    if (item.hc_booked_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }

                                else if (this.hcPmScheduleType === 'project-meeting') {
                                    if (item.project_meeting_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }
                                else if (this.hcPmScheduleType === 'vc') {
                                    if (item.vc_completed_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }
                            });

                            user_list_arr.push({ lead_owner: user.val, lead_details: details })

                        });


                        let typeIndex = arr_list.findIndex(item => time.substr(0, 10) === item.Time);
                        // alert(typeIndex);
                        if (typeIndex === -1) {
                            // arr_date.push({ list: arr_list })
                            arr_list.push({ 'Time': time, list: user_list_arr })
                        }
                        else {
                            arr_list[typeIndex].list = user_list_arr
                        }

                    }
                });

                final_hc_array.push({ 'date': date, 'timearr': arr_list });

            });



            this.setState({ reportData: final_hc_array, timeArray: [] })
        }
        else if (choice === 'design-tracker') {
            let users = this.props.PnkStore.GetData('userlist').rows;
            let design_tracker_report = [];
            users.forEach((user, i) => {

                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Designer')) {

                    let total_handover = 0, lead_owner = '', total_project_closed = 0, design_in_progress = 0, proposal_uploaded = 0, design_change = 0;
                    if (type === "Today") {
                        this.todayDesignerHandover.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.todayDesignProposalUploaded.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.todayDesignerProjectClosed.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        if (total_project_closed > 0 || proposal_uploaded > 0) {
                            design_tracker_report.push({
                                handover_to_designer: user.user_name, project_closed: total_project_closed, total_handover: total_handover,
                                handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                                design_change: design_change
                            });
                        }

                    }
                    else if (type === "Yesterday") {
                        this.yesterdayDesignerHandover.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.yesterdayDesignProposalUploaded.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }

                        })
                        this.yesterdayDesignerProjectClosed.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        if (total_project_closed > 0 || proposal_uploaded > 0) {
                            design_tracker_report.push({
                                handover_to_designer: user.user_name, project_closed: total_project_closed, total_handover: total_handover,
                                handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded, design_change: design_change
                            });
                        }
                    }
                    else if (type === 'Last 7 Days') {
                        this.weekDesignerHandover.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.weekDesignProposalUploaded.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.weekDesignerProjectClosed.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        if (total_project_closed > 0 || proposal_uploaded > 0) {
                            design_tracker_report.push({
                                handover_to_designer: user.user_name, project_closed: total_project_closed, total_handover: total_handover,
                                handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded, design_change: design_change
                            });
                        }
                    }
                    else if (type === 'Last 30 Days' || type === 'Custom') {
                        this.monthPendingDesignHandover.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.monthDesignProposalUploaded.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        proposal_uploaded++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                        })
                        this.monthDesignerProjectClosed.forEach(todayData => {
                            if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }
                            else if (this.currentDesigner === '') {
                                if (todayData.designer_name === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                    else if (this.currentCity === '') {
                                        total_project_closed++
                                        lead_owner = todayData.lead_owner
                                    }
                                }
                            }

                        })
                        if (total_project_closed > 0 || proposal_uploaded > 0) {
                            design_tracker_report.push({
                                handover_to_designer: user.user_name, project_closed: total_project_closed, total_handover: total_handover,
                                handover_date: this.state.todayDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded, design_change: design_change
                            });
                        }
                    }
                }
            })
            this.setState({ reportData: design_tracker_report })
        }
        else if (choice === "design-team-report") {
            let users = this.props.PnkStore.GetData('userlist').rows;
            let design_team_report = [];
            users.forEach((user, i) => {
                let total_handover = 0, total_proposal_sent = 0, total_project_closed = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Designer')) {
                    if (type === "Today") {
                        this.todayDesignerHandover.forEach(todayData => {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_handover++
                                }
                                else if (this.currentCity === '') {
                                    total_handover++
                                }
                            }
                        })
                        this.todayDesignerProposalSent.forEach(todayData => {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_proposal_sent++
                                }
                                else if (this.currentCity === '') {
                                    total_proposal_sent++
                                }
                            }
                        })
                        this.todayDesignerProjectClosed.forEach(todayData => {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_project_closed++
                                }
                                else if (this.currentCity === '') {
                                    total_project_closed++
                                }
                            }
                        })
                        if (total_project_closed > 0 || total_proposal_sent > 0 || total_handover > 0) {
                            design_team_report.push({
                                designer_user: user.user_name, project_closed: total_project_closed, proposal_sent: total_proposal_sent, total_handover: total_handover
                            });
                        }
                    }
                    else if (type === "Yesterday") {
                        this.yesterdayDesignerHandover.forEach(yesterdayData => {
                            if (yesterdayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_handover++
                                }
                                else if (this.currentCity === '') {
                                    total_handover++
                                }
                            }
                        })
                        this.yesterdayDesignerProposalSent.forEach(yesterdayData => {
                            if (yesterdayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_proposal_sent++
                                }
                                else if (this.currentCity === '') {
                                    total_proposal_sent++
                                }
                            }
                        })
                        this.yesterdayDesignerProjectClosed.forEach(yesterdayData => {
                            if (yesterdayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayData.lead_city)) {
                                    total_project_closed++
                                }
                                else if (this.currentCity === '') {
                                    total_project_closed++
                                }
                            }
                        })
                        if (total_project_closed > 0 || total_proposal_sent > 0 || total_handover > 0) {
                            design_team_report.push({
                                designer_user: user.user_name, project_closed: total_project_closed, proposal_sent: total_proposal_sent, total_handover: total_handover
                            });
                        }
                    }
                    else if (type === 'Last 7 Days') {
                        this.weekDesignerHandover.forEach(weekData => {
                            if (weekData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_handover++
                                }
                                else if (this.currentCity === '') {
                                    total_handover++
                                }
                            }
                        })
                        this.weekDesignerProposalSent.forEach(weekData => {
                            if (weekData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_proposal_sent++
                                }
                                else if (this.currentCity === '') {
                                    total_proposal_sent++
                                }
                            }
                        })
                        this.weekDesignerProjectClosed.forEach(weekData => {
                            if (weekData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === weekData.lead_city)) {
                                    total_project_closed++
                                }
                                else if (this.currentCity === '') {
                                    total_project_closed++
                                }
                            }
                        })
                        if (total_project_closed > 0 || total_proposal_sent > 0 || total_handover > 0) {
                            design_team_report.push({
                                designer_user: user.user_name, project_closed: total_project_closed, proposal_sent: total_proposal_sent, total_handover: total_handover
                            });
                        }
                    }
                    else if (type === 'Last 30 Days' || type === 'Custom') {
                        this.monthDesignerHandover.forEach(monthData => {
                            if (monthData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === monthData.lead_city)) {
                                    total_handover++
                                }
                                else if (this.currentCity === '') {
                                    total_handover++
                                }
                            }
                        })
                        this.monthDesignerProposalSent.forEach(monthData => {
                            if (monthData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === monthData.lead_city)) {
                                    total_proposal_sent++
                                }
                                else if (this.currentCity === '') {
                                    total_proposal_sent++
                                }
                            }
                        })
                        this.monthDesignerProjectClosed.forEach(monthData => {
                            if (monthData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === monthData.lead_city)) {
                                    total_project_closed++
                                }
                                else if (this.currentCity === '') {
                                    total_project_closed++
                                }
                            }
                        })
                        if (total_project_closed > 0 || total_proposal_sent > 0 || total_handover > 0) {
                            design_team_report.push({
                                designer_user: user.user_name, project_closed: total_project_closed, proposal_sent: total_proposal_sent, total_handover: total_handover
                            });
                        }
                    }
                }
            })
            this.setState({ reportData: design_team_report })
        }
        else if (choice === 'partial-report') {
            let partial_array = []
            if (type === "Today") {
                this.todayPartialAssignment.forEach(report => {
                    if (this.currentCity !== '' && this.currentCity === report.lead_city) {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }
                    else if (this.currentCity === '') {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }


                })
            }
            else if (type === "Yesterday") {
                this.yesterdayPartialAssignment.forEach(report => {
                    if (this.currentCity !== '' && this.currentCity === report.lead_city) {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }
                    else if (this.currentCity === '') {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }


                })
            }
            else if (type === 'Last 7 Days') {
                this.weekPartialAssignment.forEach(report => {
                    if (this.currentCity !== '' && this.currentCity === report.lead_city) {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }
                    else if (this.currentCity === '') {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }


                })
            }

            this.setState({
                reportData: partial_array
            })
        }

    }

    CampaignDataFormatting = () => {

    }


    RenderCustomReport = () => {
        // alert(this.state.currentChoice+"---")
        //    alert(JSON.stringify(this.customData))
        this.setState({
            getCustomData: false
        })
        var users = this.props.PnkStore.GetData('userlist').rows;
        if (this.state.currentChoice === "design-team-report") {
            let design_team_report = [];
            let design_handover = []
            let design_proposal_sent = []
            let design_project_close = []
            this.customData.forEach((element, i) => {
                if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.customEndDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.customStartDate) {
                    design_handover.push(element)
                }
                if (element.proposal_sent_date_time !== undefined && element.proposal_sent_date_time <= this.state.customEndDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.customStartDate) {
                    design_proposal_sent.push(element)
                }
                if (element.project_close_date_time !== undefined && element.project_close_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_close_date_time >= this.state.customStartDate) {
                    design_project_close.push(element)
                }
            })
            users.forEach((user, i) => {
                let total_handover = 0, total_proposal_sent = 0, total_project_closed = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Designer')) {
                    design_handover.forEach(todayData => {
                        if (todayData.designer_name === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                total_handover++
                            }
                            else if (this.currentCity === '') {
                                total_handover++
                            }
                        }
                    })
                    design_proposal_sent.forEach(todayData => {
                        if (todayData.designer_name === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                total_proposal_sent++
                            }
                            else if (this.currentCity === '') {
                                total_proposal_sent++
                            }
                        }
                    })
                    design_project_close.forEach(todayData => {
                        if (todayData.designer_name === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                total_project_closed++
                            }
                            else if (this.currentCity === '') {
                                total_project_closed++
                            }
                        }
                    })
                    if (total_project_closed > 0 || total_proposal_sent > 0 || total_handover > 0) {
                        design_team_report.push({
                            designer_user: user.user_name, project_closed: total_project_closed, proposal_sent: total_proposal_sent, total_handover: total_handover
                        });
                    }

                }
            })
            this.setState({
                reportData: design_team_report
            })
        }
        else if (this.state.currentChoice === 'partial-report') {
            let partial_data = []
            let partial_array = []
            this.customData.forEach((element, i) => {

                if (element.partial_date_time !== undefined && element.partial_date_time <= this.state.customEndDate + ' 23:59:59' && element.partial_date_time >= this.state.customStartDate) {
                    partial_data.push(element)
                }

            })
            partial_data.forEach(report => {
                {
                    // console.loge.log("report",this.currentCity)
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }
                    else if (this.currentCity === '') {
                        let patch_date_time = ''
                        if (report.lead_current_stage !== 'Partial Assignment')
                            patch_date_time = report.patch_date_time
                        partial_array.push({
                            'lead_owner': report.lead_owner,
                            'client_name': report.user_name,
                            'partial_date_time': report.partial_date_time,
                            'patch_date_time': patch_date_time,
                            'lead_current_stage': report.lead_current_stage,
                            'client_mob': report.user_mobile_no,
                            'id': report._id

                        })
                    }


                }
            })
            this.setState({
                reportData: partial_array
            })
        }
        else if (this.state.currentChoice === 'followup-report') {
            let follow_up_report = [];
            let follow_up_date_time_report = []
            this.customData.forEach((element, i) => {

                if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.customEndDate + ' 23:59:59' && element.follow_up_date_time >= this.state.customStartDate) {
                    follow_up_date_time_report.push(element)
                }

            })


            follow_up_date_time_report.forEach(report => {

                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    follow_up_report.push({
                        lead_owner: report.lead_owner,
                        client_name: report.user_name,
                        follow_up_date_time: report.follow_up_date_time,
                        client_mobile: report.user_mobile_no,
                        id: report._id

                    });
                }
                else if (this.currentCity === '') {

                    follow_up_report.push({
                        lead_owner: report.lead_owner,
                        client_name: report.user_name,
                        follow_up_date_time: report.follow_up_date_time,
                        client_mobile: report.user_mobile_no,
                        id: report._id

                    });
                }



            })
            follow_up_report.sort((a, b) => new Date(b.follow_up_date_time).getTime() - new Date(a.follow_up_date_time).getTime());
            this.setState({
                reportData: follow_up_report
            })
        }
        else if (this.state.currentChoice === 'design-tracker') {
            let design_tracker_report = [];
            let design_handover = []
            let design_proposal_uploaded = []
            let design_project_close = []
            this.customData.forEach((element, i) => {
                if (element.handover_to_designer_date_time !== undefined && element.handover_to_designer_date_time <= this.state.customEndDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.customStartDate) {
                    design_handover.push(element)
                }
                if (element.proposal_uploaded_date_time !== undefined && element.proposal_uploaded_date_time <= this.state.customEndDate + ' 23:59:59' && element.proposal_uploaded_date_time >= this.state.customStartDate) {
                    design_proposal_uploaded.push(element)
                }
                if (element.project_close_date_time !== undefined && element.project_close_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_close_date_time >= this.state.customStartDate) {
                    design_project_close.push(element)
                }
            })
            users.forEach((user, i) => {
                let total_handover = 0, lead_owner = '', total_project_closed = 0, design_in_progress = 0, proposal_uploaded = 0, design_change = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Designer')) {
                    design_handover.forEach(todayData => {
                        if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    lead_owner = todayData.lead_owner
                                }
                                else if (this.currentCity === '') {
                                    lead_owner = todayData.lead_owner
                                }
                            }
                        }
                        else if (this.currentDesigner === '') {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    lead_owner = todayData.lead_owner
                                }
                                else if (this.currentCity === '') {
                                    lead_owner = todayData.lead_owner
                                }
                            }
                        }
                    })
                    design_proposal_uploaded.forEach(todayData => {
                        if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    proposal_uploaded++
                                }
                                else if (this.currentCity === '') {
                                    proposal_uploaded++
                                }
                            }
                        }
                        else if (this.currentDesigner === '') {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    proposal_uploaded++
                                }
                                else if (this.currentCity === '') {
                                    proposal_uploaded++
                                }
                            }
                        }
                    })
                    design_project_close.forEach(todayData => {
                        if (this.currentDesigner !== '' && (this.currentDesigner === todayData.designer_name)) {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_project_closed++
                                }
                                else if (this.currentCity === '') {
                                    total_project_closed++
                                }
                            }
                        }
                        else if (this.currentDesigner === '') {
                            if (todayData.designer_name === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                    total_project_closed++
                                }
                                else if (this.currentCity === '') {
                                    total_project_closed++
                                }
                            }
                        }
                    })
                    if (total_project_closed > 0 || proposal_uploaded > 0) {
                        design_tracker_report.push({
                            handover_to_designer: user.user_name, project_closed: total_project_closed,
                            handover_date: this.state.customStartDate + ' To ' + this.state.customEndDate, lead_owner: lead_owner, design_in_progress: design_in_progress, proposal_uploaded: proposal_uploaded,
                            design_change: design_change
                        });
                    }

                }
            })
            this.setState({
                reportData: design_tracker_report
            })
        }
        else if (this.state.currentChoice === "sales-report") {
            let sales_reports = [];
            let sales_patch_data = [], sales_hc_booked_data = [], sales_hc_completed_data = [], sales_follow_up_data = [], sales_handover_data = [];
            let sales_proposal_sent_data = [], sales_booking_amt_data = [], sales_project_closed_data = [];
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {

                        sales_patch_data.push(element)
                    }

                    // if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                    if (element.date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                        sales_hc_booked_data.push(element)
                    }
                    if (element.date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate) {
                        sales_hc_completed_data.push(element)
                    }
                    if (element.date_time !== undefined && element.follow_up_date_time <= this.state.customEndDate + ' 23:59:59' && element.follow_up_date_time >= this.state.customStartDate) {
                        sales_follow_up_data.push(element)
                    }
                    if (element.date_time !== undefined && element.handover_to_designer_date_time <= this.state.customEndDate + ' 23:59:59' && element.handover_to_designer_date_time >= this.state.customStartDate) {
                        sales_handover_data.push(element)
                    }
                    if (element.date_time !== undefined && element.proposal_sent_date_time <= this.state.customEndDate + ' 23:59:59' && element.proposal_sent_date_time >= this.state.customStartDate) {
                        sales_proposal_sent_data.push(element)
                    }
                    if (element.date_time !== undefined && element.booking_amount_received_date_time <= this.state.customEndDate + ' 23:59:59' && element.booking_amount_received_date_time >= this.state.customStartDate) {
                        sales_booking_amt_data.push(element)
                    }
                    if (element.date_time !== undefined && element.project_close_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_close_date_time >= this.state.customStartDate) {
                        sales_project_closed_data.push(element)
                    }
                }
            })

            users.forEach((user, i) => {
                let date_time = '', total_patch = 0, total_hc_booked = 0, total_hc_completed = 0, total_follow = 0, total_pending_design = 0,
                    total_handover_to_design = 0, total_estimate_pending = 0, total_proposal_sent = 0, total_booking_amount_received = 0, total_project_closed = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    sales_patch_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_patch++;
                            }
                            if (this.currentCity === '') {
                                total_patch++;
                            }
                            date_time = customData.date_time
                        }
                    });

                    //02-10 - PR

                    sales_hc_booked_data.forEach(customData => {

                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_hc_booked++;
                            }
                            if (this.currentCity === '') {
                                total_hc_booked++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    sales_hc_completed_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_hc_completed++;
                            }
                            if (this.currentCity === '') {
                                total_hc_completed++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    sales_follow_up_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_follow++;
                            }
                            if (this.currentCity === '') {
                                total_follow++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    sales_handover_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_handover_to_design++;
                            }
                            if (this.currentCity === '') {
                                total_handover_to_design++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    sales_proposal_sent_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_proposal_sent++;
                            }
                            if (this.currentCity === '') {
                                total_proposal_sent++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    sales_booking_amt_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_booking_amount_received++;
                            }
                            if (this.currentCity === '') {
                                total_booking_amount_received++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    sales_project_closed_data.forEach(customData => {
                        if (customData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                                total_project_closed++;
                            }
                            if (this.currentCity === '') {
                                total_project_closed++;
                            }
                            date_time = customData.date_time
                        }
                    })
                    // // console.loge.log("update",update_time)
                    // if (total_patch > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_follow > 0 || total_pending_design > 0 || total_handover_to_design > 0 || total_estimate_pending > 0 || total_proposal_sent > 0 || total_booking_amount_received > 0 || total_project_closed > 0) {
                    //     sales_reports.push({
                    //         lead_owner: user.user_full_name, reporting_to: user.reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
                    //         total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
                    //         total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
                    //         total_project_closed: total_project_closed, date_time: date_time, total_lead_patched: total_patch
                    //     });
                    // }



                    if (total_patch > 0 || total_hc_booked > 0 || total_hc_completed > 0 || total_follow > 0 || total_pending_design > 0 || total_handover_to_design > 0 || total_estimate_pending > 0 || total_proposal_sent > 0 || total_booking_amount_received > 0 || total_project_closed > 0) {
                        sales_reports.push({
                            lead_owner: user.user_full_name, reporting_to: user.reporting_to, patch: total_patch, total_hc_booked: total_hc_booked,
                            total_hc_completed: total_hc_completed, total_follow: total_follow, total_pending_design: total_pending_design, total_handover_to_design,
                            total_estimate_pending: total_estimate_pending, total_proposal_sent: total_proposal_sent, total_booking_amount_received: total_booking_amount_received,
                            total_project_closed: total_project_closed, date_time: date_time, total_lead_patched: total_patch
                        });
                    }
                }

            })
            this.setState({
                reportData: sales_reports
            })
        }
        else if (this.state.currentChoice === "patch-report") {
            let total_patch_count = 0;
            let patch_data_report = []
            let tele_assignee = []
            let patch_report = []
            this.customData.forEach((element, i) => {
                if (element.date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    patch_data_report.push(element)
                }

            })
            patch_data_report.forEach(report => {
                tele_assignee.push(report.tele_assignee)
            })
            let unique_tele_assignee = [...new Set(tele_assignee)];
            unique_tele_assignee.map(tele_assignee => {
                patch_data_report.forEach(report => {
                    if (report.tele_assignee === tele_assignee) {
                        let typeIndex = patch_report.findIndex(type => report.tele_assignee === type.tele_assignee);

                        if (typeIndex !== -1) {

                            let valIndex = patch_report[typeIndex].list.findIndex(item => item.lead_owner === report.lead_owner)
                            if (valIndex === -1) {
                                total_patch_count++
                                patch_report[typeIndex].list.push({ 'lead_owner': report.lead_owner, date_time: report.date_time, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] });
                            }
                            else {
                                total_patch_count++
                                let patch_count = patch_report[typeIndex].list[valIndex].patch_count + 1
                                patch_report[typeIndex].list[valIndex].patch_count = patch_count
                                patch_report[typeIndex].list[valIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage })
                            }

                        }
                        else {
                            total_patch_count++
                            patch_report.push({ 'tele_assignee': report.tele_assignee, list: [{ 'lead_owner': report.lead_owner, date_time: report.date_time, 'patch_count': 1, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, patch_date_time: report.patch_date_time, lead_current_stage: report.lead_current_stage }] }] });
                        }
                    }

                })

            })
            this.setState({ reportData: patch_report, total_patch_count: total_patch_count })

        }
        else if (this.state.currentChoice === "sales-meeting-count") {
            let sales_count_arr = [];
            let hc_datetime_report = []
            let pm_date_time_report = []
            let vc_date_time_report = []
            let users = this.props.PnkStore.GetData('userlist').rows;
            // // console.loge.log("this.customData",this.customData)
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate) {
                        hc_datetime_report.push(element)
                    }
                    if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_meeting_completed_date_time >= this.state.customStartDate) {
                        pm_date_time_report.push(element)
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                        vc_date_time_report.push(element)
                    }
                }


            })
            // // console.loge.log("hc_datetime_report",hc_datetime_report)
            users.forEach((user, i) => {
                let hc_count = 0, vc_count = 0, pm_count = 0, total_count = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {

                    hc_datetime_report.forEach(todayHCData => {
                        if (user.user_name === todayHCData.lead_owner) {
                            if (this.currentSalesUser !== '' && (this.currentSalesUser === todayHCData.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {

                                    hc_count++;
                                    total_count++



                                }
                                else if (this.currentCity === '') {

                                    hc_count++;
                                    total_count++

                                }

                            }
                            else if (this.currentSalesUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                    hc_count++;
                                    total_count++

                                }
                                else if (this.currentCity === '') {
                                    hc_count++;
                                    total_count++

                                }
                            }
                        }


                    });
                    pm_date_time_report.forEach(report => {
                        if (user.user_name === report.lead_owner) {
                            if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                    pm_count++
                                    total_count++
                                }
                                else if (this.currentCity === '') {
                                    pm_count++
                                    total_count++
                                }
                            }
                            else if (this.currentSalesUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    pm_count++
                                    total_count++
                                }
                                else if (this.currentCity === '') {
                                    pm_count++
                                    total_count++
                                }
                            }
                        }
                    })
                    vc_date_time_report.forEach(report => {
                        if (user.user_name === report.lead_owner) {
                            if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    vc_count++
                                    total_count++
                                }
                                else if (this.currentCity === '') {
                                    vc_count++
                                    total_count++
                                }
                            }

                            else if (this.currentSalesUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    vc_count++
                                    total_count++
                                }
                                else if (this.currentCity === '') {
                                    vc_count++
                                    total_count++
                                }
                            }
                        }

                    })

                }
                if (total_count > 0) {
                    sales_count_arr.push({
                        lead_owner: user.user_name,
                        sales_tl: user.reporting_to,
                        hc_count: hc_count,
                        pm_count: pm_count,
                        vc_count: vc_count,
                        total_count: total_count
                    })
                }
            })
            this.setState({
                reportData: sales_count_arr
            })


        }
        else if (this.state.currentChoice === "meeting-tracker") {
           
            let hc_reports = [];
            let hc_datetime_report = []
            let pm_date_time_report = []
            let vc_date_time_report = []
            let users = this.props.PnkStore.GetData('userlist').rows;
            // // console.loge.log("this.customData",this.customData)
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                        hc_datetime_report.push(element)
                    }
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate) {
                        pm_date_time_report.push(element)
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                        vc_date_time_report.push(element)
                    }
                }


            })
            // // console.loge.log("hc_datetime_report",hc_datetime_report)
            users.forEach((user, i) => {
                // let lead_owner = '', client_name = '', project_value = '', hc_date_time = '', stage = '', name_of_designer = '';
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                    if (this.hcPmScheduleType === 'hc') {

                        hc_datetime_report.forEach(customHcData => {
                            if (this.currentSalesUser !== '' && (this.currentSalesUser === customHcData.lead_owner)) {
                                if (customHcData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === customHcData.lead_city)) {

                                        hc_reports.push({
                                            client_name: customHcData.user_name,
                                            hc_date_time: customHcData.hc_booked_date_time,
                                            lead_owner: customHcData.lead_owner,
                                            sales_tl: user.reporting_to,
                                            location: customHcData.preferred_area,
                                            hc_completed_date_time: customHcData.hc_completed_date_time
                                        })


                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            client_name: customHcData.user_name,
                                            hc_date_time: customHcData.hc_booked_date_time,
                                            lead_owner: customHcData.lead_owner,
                                            sales_tl: user.reporting_to,
                                            location: customHcData.preferred_area,
                                            hc_completed_date_time: customHcData.hc_completed_date_time

                                        })
                                    }
                                }
                            }
                            else if (this.currentSalesUser === '') {
                                if (customHcData.lead_owner === user.user_name) {
                                    if (this.currentCity !== '' && (this.currentCity === customHcData.lead_city)) {
                                        hc_reports.push({
                                            client_name: customHcData.user_name,
                                            hc_date_time: customHcData.hc_booked_date_time,
                                            lead_owner: customHcData.lead_owner,
                                            sales_tl: user.reporting_to,
                                            location: customHcData.preferred_area,
                                            hc_completed_date_time: customHcData.hc_completed_date_time

                                        })
                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            client_name: customHcData.user_name,
                                            hc_date_time: customHcData.hc_booked_date_time,
                                            lead_owner: customHcData.lead_owner,
                                            sales_tl: user.reporting_to,
                                            location: customHcData.preferred_area,
                                            hc_completed_date_time: customHcData.hc_completed_date_time

                                        })
                                    }
                                }
                            }

                        });
                    }
                    if (this.hcPmScheduleType === 'project-meeting') {
                        pm_date_time_report.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            project_estimate: report.project_estimate,
                                            hc_completed_date_time: report.hc_completed_date_time,
                                            project_meeting_date_time: report.project_meeting_date_time,
                                            designer_name: report.designer_name,
                                            assist_name: report.assist_name
                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            project_estimate: report.project_estimate,
                                            hc_completed_date_time: report.hc_completed_date_time,
                                            project_meeting_date_time: report.project_meeting_date_time,
                                            designer_name: report.designer_name,
                                            assist_name: report.assist_name

                                        });
                                    }
                                }
                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            project_estimate: report.project_estimate,
                                            hc_completed_date_time: report.hc_completed_date_time,
                                            project_meeting_date_time: report.project_meeting_date_time,
                                            designer_name: report.designer_name,
                                            assist_name: report.assist_name

                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            project_estimate: report.project_estimate,
                                            hc_completed_date_time: report.hc_completed_date_time,
                                            project_meeting_date_time: report.project_meeting_date_time,
                                            designer_name: report.designer_name,
                                            assist_name: report.assist_name

                                        });
                                    }
                                }
                            }
                        })
                    }
                    if (this.hcPmScheduleType === "vc") {

                        vc_date_time_report.forEach(report => {
                            if (user.user_name === report.lead_owner) {
                                if (this.currentSalesUser !== '' && (this.currentSalesUser === report.lead_owner)) {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            location: report.preferred_area,
                                            vc_completed_date_time: report.vc_completed_date_time


                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            location: report.preferred_area,
                                            vc_completed_date_time: report.vc_completed_date_time


                                        });
                                    }
                                }

                                else if (this.currentSalesUser === '') {
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            location: report.preferred_area,
                                            vc_completed_date_time: report.vc_completed_date_time

                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        // // console.loge.log("report",report)
                                        hc_reports.push({
                                            lead_owner: report.lead_owner,
                                            client_name: report.user_name,
                                            hc_date_time: report.hc_booked_date_time,
                                            sales_tl: user.reporting_to,
                                            location: report.preferred_area,
                                            vc_completed_date_time: report.vc_completed_date_time

                                        });
                                    }
                                }
                            }

                        })
                    }

                }

            })
            this.setState({
                reportData: hc_reports
            })
        }
        else if (this.state.currentChoice === "marketing") {
            let marketingData = []
            let marketing_patch_data = []
            let marketing_lg_data = []
            this.customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    marketing_patch_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    marketing_lg_data.push(element)
                }
            })
            if (this.leadSourceData && this.leadSourceData.leadsourcelist.rows && this.leadSourceData.leadsourcelist.rows.length > 0) {

                this.leadSourceData.leadsourcelist.rows.forEach((leadData, i) => {
                    let lead_patched = 0, lead_generated = 0, total_spend = 0, total_closure = 0, dead_after_patch = 0;

                    marketing_patch_data.forEach(marketPatchData => {
                        if (marketPatchData.lead_source !== undefined) {
                            if (this.currentCity !== '' && (this.currentCity === marketPatchData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {

                                    if ((marketPatchData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(marketPatchData.lead_source.toLowerCase())) {

                                        lead_patched++;
                                    }
                                }
                                else if (marketPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {

                                    if ((marketPatchData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(marketPatchData.lead_source.toLowerCase())) {

                                        lead_patched++;
                                    }
                                }
                                else if (marketPatchData.lead_source === leadData.lead_source_name) {
                                    lead_patched++;
                                }
                            }
                        }
                    });
                    marketing_lg_data.forEach(lgData => {
                        if (lgData.lead_source !== undefined) {
                            if (this.currentCity !== '' && (this.currentCity === lgData.lead_city)) {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name !== undefined && lgData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(lgData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (lgData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (leadData.lead_source_name.includes('Google')) {
                                    if ((leadData.lead_source_name !== undefined && lgData.lead_source !== undefined && leadData.lead_source_name.toLowerCase()).includes(lgData.lead_source.toLowerCase())) {
                                        lead_generated++
                                    }
                                }
                                else if (lgData.lead_source === leadData.lead_source_name) {
                                    lead_generated++
                                }
                            }
                        }

                    });

                    if (lead_patched > 0 || lead_generated > 0) {
                        marketingData.push({
                            source_name: leadData.lead_source_name, lead_generated: lead_generated, lead_patched: lead_patched, total_spend: total_spend, total_closure: total_closure,
                            dead_after_patch: dead_after_patch

                        })
                    }

                })
            }
            this.setState({
                reportData: marketingData
            })
        }
        else if (this.state.currentChoice === 'campaign-report') {
        //    alert('kkk')
            let lg_data = []
            let total_campaign_count = 0
            let camapign_data = []
            let campaign_name_list = [];

            let unique_campaign_names = [];
            let unique_source_names = [];

            this.customData.forEach((element, i) => {

                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    lg_data.push(element)
                }
            })



            lg_data.forEach(report => {
                if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                    if (!unique_campaign_names.includes(report.lead_campaign)) {
                        unique_campaign_names.push(report.lead_campaign);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: report.lead_campaign })
                    }
                }
                else {
                    // alert(report.lead_source);
                    if (!unique_source_names.includes(report.lead_source)) {
                        unique_source_names.push(report.lead_source);
                        campaign_name_list.push({ lead_source: report.lead_source, lead_campaign: '' })
                    }
                }
                // if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                //     campaign_name_list.push(report.lead_campaign)
                // }
            });
            campaign_name_list.map(element => {

                let patch_count = 0;
                let dead_count = 0;
                let callback_count =0;
                let adset_arr = [];
                let ads_arr = [];
                let source_patch_count = 0;
                let source_dead_count = 0;
                let source_callback_count =0;

                lg_data.forEach(report => {
                    let campaign_name = element.lead_campaign
                    let lead_source = element.lead_source
                    if (report.lead_campaign !== '' && report.lead_campaign !== undefined) {
                        if (report.lead_campaign == campaign_name) {
        
                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }

                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }

                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                callback_count++;
                            }

                            let typeIndex = camapign_data.findIndex(type => report.lead_campaign === type.campaign_name);

                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = patch_count
                                camapign_data[typeIndex].dead_count = dead_count
                                camapign_data[typeIndex].callback_count = callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': source_patch_count,'dead_count': source_dead_count,'callback_count': source_callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                    else {
                        if (report.lead_source == lead_source) {
                            let typeIndex = camapign_data.findIndex(type => report.lead_source === type.lead_source);

                            if(report.lead_adset != '' && report.lead_adset != null && report.lead_adset != undefined){
                                if(adset_arr.length>0){
                                    let count_num = 0;
                                    adset_arr.forEach((item,i)=>{
                                        if(report.lead_adset == item.adset_name){
                                            let count = item.adset_count;
                                            count = count + 1;
                                            item.adset_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == adset_arr.length){
                                            if(count_num == 0){
                                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                            }
                                        }
                                        // else{

                                        //     adset_arr.push({'adset_name':report.lead_adset,'adset_count':1})
                                        // }
                                    });
                                }
                                else{
                                adset_arr.push({'adset_name':report.lead_adset,'adset_count':1});
                                }
                            }
                      
                            if(report.lead_ad != '' && report.lead_ad != null && report.lead_ad != undefined){
                                if(ads_arr.length>0){
                                    let count_num = 0;
                                    ads_arr.forEach((item,i)=>{

                                        if(report.lead_ad.trim() == item.ads_name.trim()){
                                            let count = item.ads_count;
                                            count = count + 1;
                                            item.ads_count = count;
                                            count_num++;
                                        }
                                        if(i+1 == ads_arr.length){
                                            if(count_num == 0){
                                            ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                            }
                                        }
                                      
                                    });
                                }
                                else{
                                ads_arr.push({'ads_name':report.lead_ad.trim(),'ads_count':1});
                                }
                            }


                            if(report.patch_date_time !='' && report.patch_date_time != null && report.patch_date_time != undefined){
                                source_patch_count++;
                            }
                            if(report.lead_current_stage == 'Dead'){
                             //   alert('kkk')
                                source_dead_count++;
                            }
                            if(report.lead_current_stage == 'CallBack'){
                                source_callback_count++;
                            }

                            if (typeIndex !== -1) {
                                total_campaign_count++
                                let campaign_count = camapign_data[typeIndex].campaign_count + 1
                                camapign_data[typeIndex].campaign_count = campaign_count
                                camapign_data[typeIndex].patch_count = source_patch_count
                                camapign_data[typeIndex].dead_count = source_dead_count
                                camapign_data[typeIndex].callback_count = source_callback_count
                                camapign_data[typeIndex].user_details.push({ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee })
                            }
                            else {
                                total_campaign_count++
                                camapign_data.push({ 'lead_source': lead_source, 'campaign_name': report.lead_campaign, 'campaign_count': 1, 'patch_count': source_patch_count,'dead_count': source_dead_count,'callback_count': source_callback_count,'adset':adset_arr,'ads':ads_arr, user_details: [{ client_name: report.user_name, client_contact: report.user_mobile_no, date_time: report.date_time, update_time: report.updated_on, patch_date_time: report.patch_date_time, callback_date_time: report.callback_date_time, lead_dead_date: report.lead_dead_date, follow_up_date_time: report.follow_up_date_time, under_const_date_time: report.under_const_date_time, lead_current_stage: report.lead_current_stage, lead_owner: report.lead_owner, tele_assignee: report.tele_assignee }] });
                            }
                        }
                    }
                })
            })

            this.setState({
                reportData: camapign_data, total_campaign_count: total_campaign_count
            })
        }
        else if (this.state.currentChoice === 'sales-rm-daily-report') {
            let customDailyReport = [];
            let daily_report_array = []
            let users = this.props.PnkStore.GetData('userlist').rows;
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {

                    if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_meeting_completed_date_time >= this.state.customStartDate
                        || element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate
                        || element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate

                    ) {
                        customDailyReport.push(element)
                    }

                }
            });
            users.forEach((user, i) => {
                customDailyReport.forEach(reportData => {
                    if (reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time <= this.state.customEndDate + ' 23:59:59' && reportData.project_meeting_completed_date_time >= this.state.customStartDate
                        || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && reportData.hc_completed_date_time >= this.state.customStartDate
                        || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && reportData.vc_completed_date_time >= this.state.customStartDate) {

                        if (user.user_name === reportData.lead_owner) {
                            // console.loge.log("reportData",reportData)
                            daily_report_array.push({
                                'date': reportData.updated_on,
                                'rm_name': reportData.lead_owner,
                                'client_name': reportData.user_name,
                                'client_contact': reportData.user_mobile_no,
                                'location': reportData.lead_city,
                                'hc_completed_date_time': reportData.hc_completed_date_time,
                                'project_completed_date_time': reportData.project_completed_date_time,
                                'vc_completed_date_time': reportData.vc_completed_date_time,
                                'id': reportData._id

                            })
                        }
                    }
                })
            })
            this.setState({
                reportData: daily_report_array
            })



        }
        else if (this.state.currentChoice === "under-construction") {
            let under_const_data = [], under_const_arr = [];
            this.customData.forEach((element, i) => {
                if (element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.customEndDate + ' 23:59:59' && element.under_const_date_time >= this.state.customStartDate) {
                    under_const_data.push(element)
                }
            })
            under_const_data.forEach(report => {
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    under_const_arr.push({
                        lead_owner: report.lead_owner,
                        client_name: report.user_name,
                        under_const_date_time: report.under_const_date_time,
                        client_mobile: report.user_mobile_no,
                        id: report._id,
                        date_time: report.date_time,
                        update_on: report.updated_on


                    });
                }
                else if (this.currentCity === '') {

                    under_const_arr.push({
                        lead_owner: report.lead_owner,
                        client_name: report.user_name,
                        under_const_date_time: report.under_const_date_time,
                        client_mobile: report.user_mobile_no,
                        id: report._id,
                        date_time: report.date_time,
                        update_on: report.updated_on

                    });
                }
            });
            this.setState({
                reportData: under_const_arr
            })
        }
        else if (this.state.currentChoice === 'daily-report') {
            let insideSalesData = []
            let insidesales_patch_data = []
            let insidesales_lg_data = []
            let insidesales_called_data = []
            let insidesales_dead_data = []
            let insidesales_pending_data = []
            let insidesales_updated_data = []
            let insidesales_callback_data = []
            this.customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    insidesales_patch_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_lg_data.push(element)

                }
                if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.customEndDate + ' 23:59:59' && element.lead_dead_date >= this.state.customStartDate) {
                    insidesales_dead_data.push(element)
                }
                if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_pending_data.push(element)
                }
                if (element.updated_on !== undefined && element.updated_on <= this.state.customEndDate + ' 23:59:59' && element.updated_on >= this.state.customStartDate) {
                    insidesales_updated_data.push(element)
                }
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate + ' 23:59:59' && element.callback_date_time >= this.state.customStartDate) {
                    insidesales_callback_data.push(element)
                }


            })
            users.forEach((user, i) => {
                let lead_patched = 0, lead_generated = 0, lead_called = 0, total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                insidesales_patch_data.forEach(customPatchedData => {
                    if (customPatchedData.tele_assignee === user.user_name) {
                        if (this.currentCity !== '' && (this.currentCity === customPatchedData.lead_city)) {
                            lead_patched++
                        }
                        if (this.currentCity === '') {
                            lead_patched++
                        }
                    }

                });

                insidesales_lg_data.forEach(customLGData => {
                    if (customLGData.tele_assignee === user.user_name || customLGData.lead_owner === user.user_name) {
                        if (this.currentCity !== '' && (this.currentCity === customLGData.lead_city)) {
                            lead_generated++

                        }
                        if (this.currentCity === '') {
                            lead_generated++
                        }
                    }
                });

                insidesales_dead_data.forEach(customDeadData => {
                    if (customDeadData.lead_owner === user.user_name) {
                        if (this.currentCity !== '' && (this.currentCity === customDeadData.lead_city)) {
                            total_dead++

                        }
                        if (this.currentCity === '') {
                            total_dead++

                        }
                    }
                });


                insidesales_pending_data.forEach(customPendingdData => {
                    if (customPendingdData.lead_owner === user.user_name) {
                        if (this.currentCity !== '' && (this.currentCity === customPendingdData.lead_city)) {
                            total_pending++

                        }
                        if (this.currentCity === '') {
                            total_pending++
                        }
                    }
                })
                insidesales_updated_data.forEach(customUpdatedData => {
                    if (customUpdatedData.lead_owner === user.user_name) {
                        if (this.currentCity !== '' && (this.currentCity === customUpdatedData.lead_city)) {
                            if (customUpdatedData.lead_current_stage === "RNR")
                                total_rnr++
                            if (customUpdatedData.lead_current_stage === "Hold")
                                total_hold++

                        }
                        if (this.currentCity === '') {

                            if (customUpdatedData.lead_current_stage === "RNR")
                                total_rnr++
                            if (customUpdatedData.lead_current_stage === "Hold")
                                total_hold++
                        }
                    }
                })
                insidesales_callback_data.forEach(customCallbackData => {
                    if (customCallbackData.lead_owner === user.user_name) {
                        if (this.currentCity !== '' && (this.currentCity === customCallbackData.lead_city)) {
                            total_callback++

                        }
                        if (this.currentCity === '') {
                            total_callback++
                        }
                    }
                })


                if (lead_generated > 0 || lead_patched > 0) {
                    insideSalesData.push({
                        lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                        total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name

                    })
                }
                this.setState({ reportData: insideSalesData })
            })


        }
        else if (this.state.currentChoice === "inside-sales-report") {
            //    this.GetCustomCallList('Inside Sales Report')

            let insideSalesData = []
            let insidesales_patch_data = []
            let insidesales_lg_data = []
            let insidesales_called_data = []
            let insidesales_dead_data = []
            let insidesales_pending_data = []
            let insidesales_updated_data = []
            let insidesales_callback_data = []
            let insidesales_hc_booked_data = []
            let insidesales_hc_na_data = []
            this.customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    insidesales_patch_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_lg_data.push(element)

                }
                if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.customEndDate + ' 23:59:59' && element.lead_dead_date >= this.state.customStartDate) {
                    insidesales_dead_data.push(element)
                }
                if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_pending_data.push(element)
                }
                if (element.updated_on !== undefined && element.updated_on <= this.state.customEndDate + ' 23:59:59' && element.updated_on >= this.state.customStartDate) {
                    insidesales_updated_data.push(element)
                }
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate + ' 23:59:59' && element.callback_date_time >= this.state.customStartDate) {
                    insidesales_callback_data.push(element)
                }
                if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                    insidesales_hc_booked_data.push(element)
                }
                if (element.hc_na_date_time !== undefined && element.hc_na_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_na_date_time >= this.state.customStartDate) {
                    insidesales_hc_na_data.push(element)
                }


            })
            // // console.loge.log("insidesales_patch_data",insidesales_patch_data)
            this.customCallList.forEach((element, i) => {
                if (element.date_created !== undefined && element.date_created <= this.state.customEndDate + ' 23:59:59' && element.date_created >= this.state.customStartDate) {
                    insidesales_called_data.push(element)
                }
            })
            users.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && (user.user_profile[0].profile_name.includes('Telecalling') || user.user_profile[0].profile_name.includes('Administrator'))) {
                    let lead_patched = 0, lead_generated = 0, lead_called = 0, no_budget = 0, not_answered = 0, not_interested = 0, other_city = 0, window_shopping = 0, other_reason = 0, total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                    let total_hc_booked = 0, total_hc_na = 0;
                    insidesales_hc_booked_data.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_hc_booked++
                            }
                            if (this.currentCity === '') {
                                total_hc_booked++
                            }
                        }

                    });
                    insidesales_hc_na_data.forEach(report => {
                        if (report.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_hc_na++
                            }
                            if (this.currentCity === '') {
                                total_hc_na++
                            }
                        }

                    });
                    insidesales_patch_data.forEach(customPatchedData => {
                        if (customPatchedData.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customPatchedData.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }

                    });
                    insidesales_called_data.forEach(customCalleddData => {

                        if (customCalleddData.caller_name === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                lead_called++
                            }
                            if (this.currentCity === '') {
                                lead_called++
                            }
                        }
                    });

                    insidesales_lg_data.forEach(customLGData => {
                        if (customLGData.tele_assignee === user.user_name || customLGData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customLGData.lead_city)) {
                                lead_generated++

                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    if (this.currentInsideSalesType === "dead") {
                        insidesales_dead_data.forEach(customDeadData => {
                            if (customDeadData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customDeadData.lead_city)) {
                                    total_dead++
                                    if (customDeadData.lead_dead_reason === "call not answered")
                                        not_answered++
                                    else if (customDeadData.lead_dead_reason === "not interested")
                                        not_interested++
                                    else if (customDeadData.lead_dead_reason === "no budget")
                                        no_budget++
                                    else if (customDeadData.lead_dead_reason === "other city")
                                        other_city++
                                    else if (customDeadData.lead_dead_reason === "window shopping")
                                        window_shopping++
                                    else if (customDeadData.lead_dead_reason === "other")
                                        other_reason++

                                }
                                if (this.currentCity === '') {
                                    total_dead++
                                    if (customDeadData.lead_dead_reason === "call not answered")
                                        not_answered++
                                    else if (customDeadData.lead_dead_reason === "not interested")
                                        not_interested++
                                    else if (customDeadData.lead_dead_reason === "no budget")
                                        no_budget++
                                    else if (customDeadData.lead_dead_reason === "other city")
                                        other_city++
                                    else if (customDeadData.lead_dead_reason === "window shopping")
                                        window_shopping++
                                    else if (customDeadData.lead_dead_reason === "other")
                                        other_reason++
                                }
                            }
                        });
                    }
                    if (this.currentInsideSalesType === "pending") {
                        insidesales_pending_data.forEach(customPendingdData => {
                            if (customPendingdData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customPendingdData.lead_city)) {
                                    total_pending++

                                }
                                if (this.currentCity === '') {
                                    total_pending++
                                }
                            }
                        })
                        insidesales_updated_data.forEach(customUpdatedData => {
                            if (customUpdatedData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customUpdatedData.lead_city)) {
                                    if (customUpdatedData.lead_current_stage === "RNR")
                                        total_rnr++
                                    if (customUpdatedData.lead_current_stage === "Hold")
                                        total_hold++

                                }
                                if (this.currentCity === '') {

                                    if (customUpdatedData.lead_current_stage === "RNR")
                                        total_rnr++
                                    if (customUpdatedData.lead_current_stage === "Hold")
                                        total_hold++
                                }
                            }
                        })
                        insidesales_callback_data.forEach(customCallbackData => {
                            if (customCallbackData.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customCallbackData.lead_city)) {
                                    total_callback++

                                }
                                if (this.currentCity === '') {
                                    total_callback++
                                }
                            }
                        })

                    }
                    // if (lead_generated > 0 || lead_patched > 0 || lead_called > 0 || not_answered>0 || not_interested>0 || no_budget>0 || other_city>0 || window_shopping>0 || other_reason>0 || total_dead>0 || total_pending>0 ||
                    //     total_rnr>0 || total_hold>0 || total_callback>0
                    //     ) {
                    insideSalesData.push({
                        lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.customStartDate + ' TO ' + this.state.customEndDate,
                        not_answered: not_answered, not_interested: not_interested, no_budget: no_budget, other_city: other_city, window_shopping: window_shopping, other_reason: other_reason,
                        total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: user.user_name,
                        total_hcna: total_hc_na, total_hc_booked: total_hc_booked

                    })
                    // }

                }
            })
            // console.loge.log("insideSalesData",insideSalesData)
            this.setState({ reportData: insideSalesData })
        }
        else if (this.state.currentChoice === "Inside Sales") {

            let insidesales_patch_data = []
            let insidesales_called_data = []
            let insidesales_dead_data = []
            let insidesales_pending_data = []
            this.customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate + ' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    insidesales_patch_data.push(element)
                }
                // if (element.date_time !== undefined && element.date_time <= this.state.customEndDate+' 23:59:59' && element.date_time >= this.state.customStartDate) {
                //     if (element.lead_current_stage !== "Pending")
                //         insidesales_called_data.push(element)
                // }
                if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.customEndDate + ' 23:59:59' && element.lead_dead_date >= this.state.customStartDate) {
                    insidesales_dead_data.push(element)
                }
                if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate + ' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_pending_data.push(element)
                }
            })
            this.customCallList.forEach((element, i) => {
                if (element.date_created !== undefined && element.date_created <= this.state.customEndDate + ' 23:59:59' && element.date_created >= this.state.customStartDate) {
                    insidesales_called_data.push(element)
                }
            })
            let users = this.props.PnkStore.GetData('userlist').rows;
            let inside_sales_report = [];
            users.forEach((user, i) => {
                let lead_called = 0, lead_patched = 0, dead_leads = 0, pending_calls = 0, tele_user = 0;
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Telecalling')) {

                    insidesales_called_data.forEach(customCalleddData => {

                        if (customCalleddData.caller_name === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                lead_called++
                            }
                            if (this.currentCity === '') {
                                lead_called++
                            }
                        }
                    });
                    insidesales_patch_data.forEach(calledPatch => {
                        if (calledPatch.tele_assignee === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === calledPatch.lead_city)) {
                                lead_patched++;
                            }
                            if (this.currentCity === '') {
                                lead_patched++;
                            }
                        }
                    })
                    insidesales_dead_data.forEach(customDead => {
                        if (customDead.lead_current_stage === "Dead") {
                            if (customDead.lead_owner === user.user_name) {
                                if (this.currentCity !== '' && (this.currentCity === customDead.lead_city)) {
                                    dead_leads++;
                                }
                                if (this.currentCity === '') {
                                    dead_leads++;
                                }
                            }
                        }
                    })
                    insidesales_pending_data.forEach(customPending => {
                        if (customPending.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customPending.lead_city)) {
                                pending_calls++;
                            }
                            if (this.currentCity === '') {
                                pending_calls++;
                            }
                        }
                    })
                }
                if (lead_patched > 0 || lead_called > 0 || dead_leads > 0 || pending_calls > 0) {
                    inside_sales_report.push({
                        tele_user: user.user_name, lead_called: lead_called, lead_patched: lead_patched, dead_leads: dead_leads, pending_calls: pending_calls
                    });
                }
            })


            this.setState({ reportData: inside_sales_report })
        }

        if (this.state.currentChoice === "HC-PM Schedule") {
            let hc_pm_report = []
            let hc_user_list = []
            let hc_time_list = []
            let final_hc_array = []
            let current_array = []
            let hc_user_list_temp = []
            let pm_data = []
            let final_date_array = []
            let vc_data = []
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Sales")) {
                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                        hc_pm_report.push(element)
                    }
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate) {
                        pm_data.push(element)
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate + ' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                        vc_data.push(element)
                    }
                }
            })
            if (this.hcPmScheduleType === 'hc') {
                hc_pm_report.forEach(customData => {
                    if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                        if (customData.lead_owner !== undefined && customData.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(customData.lead_owner)) {
                                hc_user_list_temp.push(customData.lead_owner);
                            }
                        }
                        if (customData.hc_booked_date_time !== undefined && customData.hc_booked_date_time !== '') {
                            hc_time_list.push(customData.hc_booked_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (customData.lead_owner !== undefined && customData.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(customData.lead_owner)) {
                                hc_user_list_temp.push(customData.lead_owner);
                            }
                        }
                        if (customData.hc_booked_date_time !== undefined && customData.hc_booked_date_time !== '') {
                            hc_time_list.push(customData.hc_booked_date_time);
                        }
                    }

                })
                current_array = hc_pm_report
            }
            else if (this.hcPmScheduleType === 'project-meeting') {
                pm_data.forEach(customData => {
                    if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                        if (customData.lead_owner !== undefined && customData.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(customData.lead_owner)) {
                                hc_user_list_temp.push(customData.lead_owner);
                            }
                        }
                        if (customData.project_meeting_date_time !== undefined && customData.project_meeting_date_time !== '') {
                            hc_time_list.push(customData.project_meeting_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (customData.lead_owner !== undefined && customData.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(customData.lead_owner)) {
                                hc_user_list_temp.push(customData.lead_owner);
                            }
                        }
                        if (customData.project_meeting_date_time !== undefined && customData.project_meeting_date_time !== '') {
                            hc_time_list.push(customData.project_meeting_date_time);
                        }
                    }

                })
                current_array = pm_data
            }
            else if (this.hcPmScheduleType === 'vc') {
                vc_data.forEach(customData => {
                    if (this.currentCity !== '' && (this.currentCity === customData.lead_city)) {
                        if (customData.lead_owner !== undefined && customData.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(customData.lead_owner)) {
                                hc_user_list_temp.push(customData.lead_owner);
                            }
                        }
                        if (customData.vc_completed_date_time !== undefined && customData.vc_completed_date_time !== '') {
                            hc_time_list.push(customData.vc_completed_date_time);
                        }
                    }
                    if (this.currentCity === '') {
                        if (customData.lead_owner !== undefined && customData.lead_owner !== '') {
                            if (!hc_user_list_temp.includes(customData.lead_owner)) {
                                hc_user_list_temp.push(customData.lead_owner);
                            }
                        }
                        if (customData.vc_completed_date_time !== undefined && customData.vc_completed_date_time !== '') {
                            hc_time_list.push(customData.vc_completed_date_time);
                        }
                    }

                })
                current_array = vc_data
            }

            hc_user_list_temp.forEach(element => {
                hc_user_list.push({ 'val': element });
            })
            hc_user_list.sort();
            final_hc_array.push({ 'Time': 'Time', list: hc_user_list })

            var final_time_array = hc_time_list.filter((v, i, a) => a.indexOf(v) === i);
            final_time_array.sort((a, b) => a.localeCompare(b))
            final_time_array.forEach(date_time => {
                let typeIndex = final_date_array.findIndex(item => date_time.substr(0, 10) === item);
                if (typeIndex === -1) {
                    final_date_array.push(date_time.substr(0, 10))
                }
            })

            let arr_date = [];
            final_date_array.forEach(date => {
                let arr_list = [];
                final_time_array.forEach(time => {
                    if (time.includes(date)) {

                        let user_list_arr = [];

                        hc_user_list.forEach(user => {
                            let count = 0;
                            let details = [];
                            current_array.forEach((item, i) => {

                                if (this.hcPmScheduleType === 'hc') {
                                    if (item.hc_booked_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }

                                else if (this.hcPmScheduleType === 'project-meeting') {
                                    if (item.project_meeting_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }
                                else if (this.hcPmScheduleType === 'vc') {
                                    if (item.vc_completed_date_time === time && user.val === item.lead_owner) {
                                        details.push({ val: item.user_name, id: item._id, time: time.substr(time.length - 5), lead_owner: item.lead_owner });
                                    }
                                }
                            });

                            user_list_arr.push({ lead_owner: user.val, lead_details: details })

                        });


                        let typeIndex = arr_list.findIndex(item => time.substr(0, 10) === item.Time);
                        // alert(typeIndex);
                        if (typeIndex === -1) {
                            // arr_date.push({ list: arr_list })
                            arr_list.push({ 'Time': time, list: user_list_arr })
                        }
                        else {
                            arr_list[typeIndex].list = user_list_arr
                        }

                    }
                });

                final_hc_array.push({ 'date': date, 'timearr': arr_list });

            });



            this.setState({ reportData: final_hc_array, timeArray: [] })
        }

    }
    ChangeSalesReportType = (type) => {

        // alert(type)
        this.setState({
            lead_campaign_view: 'default',
            patchView: 'default',
            currentSalesReportType: type,

            // hcPmScheduleType:'hc'
        })
        if (this.state.currentChoice === "callback") {
            this.RenderCallbackReport(this.callbackReportData, this.state.ddlcurrentCallBackUser, this.state.ddlCurrentCallBackBprofile, type)
        }
        else if (this.state.currentChoice === "lead-tracking-report") {
            if (type === "Today") {
                this.calldetails_data = {
                    'txtCallListStartDate': this.state.todayDate,
                    'txtCallListEndDate': this.state.todayDate
                }
                this.setState({
                    setNo: 1, currentSalesReportType: 'Today', getFirstCall: true, reportData: [],
                    currentPageNumber: 1,
                    startValue: 0,
                    endValue: 1,
                    totalPages: 0,
                    totalRecordsInDb: 0,
                })
            }
            else if (type === "Yesterday") {
                this.calldetails_data = {
                    'txtCallListStartDate': this.state.yesterdayDate,
                    'txtCallListEndDate': this.state.yesterdayDate
                }
                this.setState({
                    setNo: 1, getFirstCall: true, reportData: [],
                    currentPageNumber: 1,
                    startValue: 0,
                    endValue: 1,
                    totalPages: 0,
                    totalRecordsInDb: 0,
                })
            }
            else if (type === "Last 7 Days") {
                this.calldetails_data = {
                    'txtCallListStartDate': this.state.weekDate,
                    'txtCallListEndDate': this.state.todayDate
                }
                this.setState({
                    setNo: 1, getFirstCall: true, reportData: [],
                    currentPageNumber: 1,
                    startValue: 0,
                    endValue: 1,
                    totalPages: 0,
                    totalRecordsInDb: 0,
                })
            }

            // this.RenderFirstCallDetails(this.FirstCallData,type)
        }
        else if (type !== 'Custom') {
            this.RenderReport(type, this.state.currentChoice)
        }
        else if (type === 'Custom') {
            this.RenderCustomReport()
        }
    }
    ChangeSalesReportTypeMobile = (event) => {
        this.setState({
            currentSalesReportType: event.target.value
        })
        if (this.state.currentChoice === "callback") {
            this.setState({
                getCallbackData: true
            })
        }
        else {
            if (event.target.value === "Custom") {
                this.setState({ showCustomDateModal: true })
            }
            else {
                this.setState({ showCustomDateModal: false })
                this.RenderReport(event.target.value, this.state.currentChoice)

            }
        }
    }
    HideCustomDateModal = () => {
        this.setState({ showCustomDateModal: false })
    }
    HandleGetUserListResponse = (res) => {

        if (res.data && res.data.userlist && res.data.userlist) {
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({ getUser: false })
    }

    HandleGetLeadSourceSuccessResponse = (res) => {
        if (res.data) {
            this.leadSourceData = res.data
            this.RenderReport(this.state.currentSalesReportType, this.state.currentChoice)
            this.props.PnkStore.SetData('leadsource', res.data);
            this.props.PnkStore.BroadCast();
        }
        this.setState({
            getLeadData: false
        })
    }
    GetCustomDataCallDetails = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        this.calldetails_data = {
            'txtCallListStartDate': startDate,
            'txtCallListEndDate': endDate
        }
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate,
            getFirstCall: true,
            totalRecordsInDb: 0,
            reportData: [],
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,

        })

    }
    GetCustomMeetingData = () => {
        var dd = String(new Date(this.state.txtCustomMeetingStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomMeetingStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomMeetingStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomMeetingEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomMeetingEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomMeetingEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        this.setState({
            meetingWeekStartDate: startDate,
            meetingWeekEndDate: endDate,
            getMeetingData: true
        })

    }
    GetCustomData = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        if (this.state.currentChoice === 'inside-sales-report' || this.state.currentChoice === 'Inside Sales') {
            this.setState({
                getCustomCallList: true,
                customStartDate: startDate,
                customEndDate: endDate,
            })
        }
        else if (this.state.currentChoice === 'CallBack') {
            this.setState({
                customStartDate: startDate,
                customEndDate: endDate,
                getCustomCallbackData: true,
                getCustomData: false
            })

        }
        else {
            this.setState({

                customStartDate: startDate,
                customEndDate: endDate,

                getCustomData: true,
                ddlPreferredCity: '-1',

            })
        }
        this.currentCity = ''
    }
    HandleGetCustomDatatResponse = (res) => {
        if (res !== false) {
            if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.customData = res.data.adminfirstmonthdata.rows

                this.RenderCustomReport()


            }
        }
        this.setState({
            getCustomData: false,
            showCustomDateModal: false
        })
    }
    HandleGetCallbackReportResponse = (res) => {
        // // console.loge.log("HandleGetCallbackReportResponse",res)

        var callbackdata = []
        if (res !== false) {
            if (res.data && res.data.callbackleads && res.data.callbackleads.rows && res.data.callbackleads.rows.length > 0) {
                this.callbackReportData = res.data.callbackleads;
                this.setState({
                    ddlcurrentCallBackUser: '', ddlCurrentCallBackBprofile: ''
                })
                this.RenderCallbackReport(res.data.callbackleads, '', '', this.state.currentSalesReportType)

            }
        }
        this.setState({ getCallbackData: false, getCustomCallbackData: false, isOptionDisabled: false, })
    }
    RenderCallbackReport = (data, user, profile, currentSalesReportType) => {
        // console.loge.log("data",data)
        var callbackReportData = []
        if (currentSalesReportType === 'Today') {
            data.rows.forEach((element, i) => {

                if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.todayDate)) {
                    if (profile === '') {


                        callbackReportData.push({
                            acquisitionDate: element.date_time,
                            updatedOn: element.updated_on,
                            customerName: element.user_name,
                            customerContact: element.user_mobile_no,
                            customerEmail: element.user_email,
                            callbackDateTime: element.callback_date_time,
                            leadOwner: element.lead_owner,
                            id: element._id
                        })
                    }
                    else if (profile !== '') {
                        if (element.lead_department[0].profile_name === profile) {
                            if (user == '') {
                                callbackReportData.push({
                                    acquisitionDate: element.date_time,
                                    updatedOn: element.updated_on,
                                    customerName: element.user_name,
                                    customerContact: element.user_mobile_no,
                                    customerEmail: element.user_email,
                                    callbackDateTime: element.callback_date_time,
                                    leadOwner: element.lead_owner,
                                    id: element._id
                                })
                            }
                            else if (user !== '') {
                                if (element.lead_owner === user) {
                                    callbackReportData.push({
                                        acquisitionDate: element.date_time,
                                        updatedOn: element.updated_on,
                                        customerName: element.user_name,
                                        customerContact: element.user_mobile_no,
                                        customerEmail: element.user_email,
                                        callbackDateTime: element.callback_date_time,
                                        leadOwner: element.lead_owner,
                                        id: element._id
                                    })
                                }

                            }
                        }

                    }

                }

            })
        }
        if (currentSalesReportType === 'Coming Days') {

            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time > this.state.todayDate) {

                    // console.loge.log("element",element)
                    if (profile === '') {
                        callbackReportData.push({
                            acquisitionDate: element.date_time,
                            updatedOn: element.updated_on,
                            customerName: element.user_name,
                            customerContact: element.user_mobile_no,
                            customerEmail: element.user_email,
                            callbackDateTime: element.callback_date_time,
                            leadOwner: element.lead_owner,
                            id: element._id
                        })
                    }
                    else if (profile !== '') {
                        if (profile === "Sales TL") {
                            if (element.lead_department[0].profile_name.includes("Sales") === true) {
                                if (user == '') {
                                    callbackReportData.push({
                                        acquisitionDate: element.date_time,
                                        updatedOn: element.updated_on,
                                        customerName: element.user_name,
                                        customerContact: element.user_mobile_no,
                                        customerEmail: element.user_email,
                                        callbackDateTime: element.callback_date_time,
                                        leadOwner: element.lead_owner,
                                        id: element._id
                                    })
                                }
                                else if (user !== '') {
                                    if (element.lead_owner === user) {
                                        callbackReportData.push({
                                            acquisitionDate: element.date_time,
                                            updatedOn: element.updated_on,
                                            customerName: element.user_name,
                                            customerContact: element.user_mobile_no,
                                            customerEmail: element.user_email,
                                            callbackDateTime: element.callback_date_time,
                                            leadOwner: element.lead_owner,
                                            id: element._id
                                        })
                                    }

                                }
                            }

                        }
                        else if (element.lead_department[0].profile_name.includes(profile) === true) {
                            if (user == '') {
                                callbackReportData.push({
                                    acquisitionDate: element.date_time,
                                    updatedOn: element.updated_on,
                                    customerName: element.user_name,
                                    customerContact: element.user_mobile_no,
                                    customerEmail: element.user_email,
                                    callbackDateTime: element.callback_date_time,
                                    leadOwner: element.lead_owner,
                                    id: element._id
                                })
                            }
                            else if (user !== '') {
                                if (element.lead_owner === user) {
                                    callbackReportData.push({
                                        acquisitionDate: element.date_time,
                                        updatedOn: element.updated_on,
                                        customerName: element.user_name,
                                        customerContact: element.user_mobile_no,
                                        customerEmail: element.user_email,
                                        callbackDateTime: element.callback_date_time,
                                        leadOwner: element.lead_owner,
                                        id: element._id
                                    })
                                }

                            }
                        }

                    }
                }

            })
        }
        if (currentSalesReportType === 'Custom') {

            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate + ' 23:59:59' && element.callback_date_time >= this.state.customStartDate) {

                    // console.loge.log("element",element)
                    if (profile === '') {
                        callbackReportData.push({
                            acquisitionDate: element.date_time,
                            updatedOn: element.updated_on,
                            customerName: element.user_name,
                            customerContact: element.user_mobile_no,
                            customerEmail: element.user_email,
                            callbackDateTime: element.callback_date_time,
                            leadOwner: element.lead_owner,
                            id: element._id
                        })
                    }
                    else if (profile !== '') {
                        if (profile === "Sales TL") {
                            if (element.lead_department[0].profile_name.includes("Sales") === true) {
                                if (user == '') {
                                    callbackReportData.push({
                                        acquisitionDate: element.date_time,
                                        updatedOn: element.updated_on,
                                        customerName: element.user_name,
                                        customerContact: element.user_mobile_no,
                                        customerEmail: element.user_email,
                                        callbackDateTime: element.callback_date_time,
                                        leadOwner: element.lead_owner,
                                        id: element._id
                                    })
                                }
                                else if (user !== '') {
                                    if (element.lead_owner === user) {
                                        callbackReportData.push({
                                            acquisitionDate: element.date_time,
                                            updatedOn: element.updated_on,
                                            customerName: element.user_name,
                                            customerContact: element.user_mobile_no,
                                            customerEmail: element.user_email,
                                            callbackDateTime: element.callback_date_time,
                                            leadOwner: element.lead_owner,
                                            id: element._id
                                        })
                                    }

                                }
                            }

                        }
                        else if (element.lead_department[0].profile_name.includes(profile) === true) {
                            if (user == '') {
                                callbackReportData.push({
                                    acquisitionDate: element.date_time,
                                    updatedOn: element.updated_on,
                                    customerName: element.user_name,
                                    customerContact: element.user_mobile_no,
                                    customerEmail: element.user_email,
                                    callbackDateTime: element.callback_date_time,
                                    leadOwner: element.lead_owner,
                                    id: element._id
                                })
                            }
                            else if (user !== '') {
                                if (element.lead_owner === user) {
                                    callbackReportData.push({
                                        acquisitionDate: element.date_time,
                                        updatedOn: element.updated_on,
                                        customerName: element.user_name,
                                        customerContact: element.user_mobile_no,
                                        customerEmail: element.user_email,
                                        callbackDateTime: element.callback_date_time,
                                        leadOwner: element.lead_owner,
                                        id: element._id
                                    })
                                }

                            }
                        }

                    }
                }

            })
        }


        callbackReportData.sort((a, b) => new Date(a.callbackDateTime).getTime() - new Date(b.callbackDateTime).getTime());

        this.setState({
            callbackReportData: callbackReportData
        })
    }


    HandleGetUserByProfileSuccessResponse = (res) => {

        if (res.data && res.data.userlistbyprofile) {
            this.setState({
                userListByProfile: res.data.userlistbyprofile,
                getUsersListByProfile: false,

            })
        }
    }
    ShowPatchDetails = (list) => {

    }
    GotoLeadEntry = (lead_id) => {
        console.log("dash", this.state.currentChoice.toLowerCase())

        AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/' + lead_id + '/' + this.state.currentChoice);
    }
    PatchDetailedReport = (details) => {
        let allowedLeadStages = [];
        if (sessionStorage.getItem('current_user_allowed_lead_stages')) {
            allowedLeadStages = sessionStorage.getItem('current_user_allowed_lead_stages');
            allowedLeadStages = allowedLeadStages.split(',');
        }


        this.setState({
            patchView: 'details',
            patchDetailReport: details,
            profileStageData: allowedLeadStages
        })

    }
    PatchReportDefaultView = () => {
        this.setState({
            patchView: 'default',
            patchDetailReport: []
        })
    }
    ChangeCampaignReportView = (details) => {
        this.setState({
            lead_campaign_view: 'details',
            campaignDetails: details
        })
    }
    CampaignReportDefaultView = () => {
        this.setState({
            lead_campaign_view: 'default',
            campaignDetails: []
        })
    }

    HandleGetCustomAllCallsListSuccessResponse = (res) => {

        if (res && res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows.length > 0) {
            this.customCallList = res.data.calllist.rows

        }
        this.setState({
            getCustomCallList: false,
            getCustomData: true
        })
    }
    HandleGetAllCallsListSuccessResponse = (res) => {
        this.setState({
            getAllCalls: false
        })
        if (res && res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows.length > 0) {
            res.data.calllist.rows.forEach((element, i) => {

                // Lead Called
                if (element.date_created !== undefined && element.date_created.includes(this.state.todayDate)) {
                    this.todayExotelCalled.push(element)


                }
                else if (element.date_created !== undefined && element.date_created.includes(this.state.yesterdayDate)) {
                    this.yesterdayExotelCalled.push(element);

                }
                if (element.date_created !== undefined && element.date_created <= this.state.todayDate + ' 23:59:59' && element.date_created >= this.state.weekDate) {
                    this.weekExotelCalled.push(element)

                }
                if (element.date_created !== undefined && element.date_created <= this.state.todayDate + ' 23:59:59' && element.date_created >= this.state.monthDate) {
                    this.monthExotelCalled.push(element)

                }
            })
        }
    }
    HandleGetMeetingDatatResponse = (res) => {

        let meetingArray = []
        if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
            res.data.adminfirstmonthdata.rows.forEach(element => {
                if (element.lead_current_stage === "HC Booked" && element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate
                    ||
                    element.lead_current_stage === "Project Meeting" && element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate
                ) {
                    // console.log("", element.hc_booked_date_time, element.project_meeting_date_time)
                    if (element.lead_current_stage === "HC Booked" && element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate) {
                        let typeIndex = meetingArray.findIndex(type => element.hc_booked_date_time.substr(0, 10) === type.date_time);
                        if (typeIndex !== -1) {
                            let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                            if (valIndex === -1) {
                                meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                            }
                            else {
                                meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                            }
                        }
                        else {
                            meetingArray.push({ 'date_time': element.hc_booked_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'HC', user_details: [{ client_name: element.user_name, type: 'HC', 'time': element.hc_booked_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                        }
                    }
                    else if (element.lead_current_stage === "Project Meeting" && element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.meetingWeekEndDate + ' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate) {
                        let typeIndex = meetingArray.findIndex(type => element.project_meeting_date_time.substr(0, 10) === type.date_time);
                        if (typeIndex !== -1) {
                            let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                            if (valIndex === -1) {
                                meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] });
                            }
                            else {
                                meetingArray[typeIndex].list[valIndex].user_details.push({ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id })
                            }
                        }
                        else {
                            meetingArray.push({ 'date_time': element.project_meeting_date_time.substr(0, 10), list: [{ 'lead_owner': element.lead_owner, type: 'PM', user_details: [{ client_name: element.user_name, type: 'PM', 'time': element.project_meeting_date_time.substr(11, 16), 'area': element.preferred_area, 'city': element.lead_city, id: element._id }] }] });
                        }
                    }

                }
            })
        }
        // console.log("meetingArray", meetingArray)
        meetingArray.sort((a, b) => new Date(b.date_time).getTime() - new Date(a.date_time).getTime());

        this.setState({
            getMeetingData: false,
            isOptionDisabled: false,
            reportData: meetingArray
        })
      //  alert(JSON.stringify(this.state.reportData))
    }
    ChangePage = (number) => {

        if (number - 1 === 0) {
            // this.setState({ getLeadList: true, currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
        else {
            // this.setState({ getLeadList: true, currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false });
        }
        this.setState({ setNo: number, getFirstCall: true })
    }
    HandleGetFirstCallResponse = (res) => {
        let tracking_arr = []
        if (res.data && res.data.leadlist && res.data.leadlist.rows && res.data.leadlist.rows.length > 0) {
            this.FirstCallData = res.data.leadlist.rows
            let total = 0;
            let totalPages = 0;
            total = res.data.leadlist.metarow.totalRecordsInDb

            totalPages = Math.ceil(total / this.state.numItemsPerPage);

            let number = this.state.setNo;
            if (number - 1 === 0) {
                this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            else {
                this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage), selectAll: false, totalRecordsInDb: total, totalPages: totalPages, selectAll: false });
            }
            this.RenderFirstCallDetails(res.data.leadlist.rows, this.state.currentSalesReportType)

        }
        else {
            this.setState({
                reportData: []
            })
        }
        this.setState({ getFirstCall: false, isOptionDisabled: false })
    }
    RenderFirstCallDetails(firstCallData, type) {
        let tracking_arr = []
        this.setState({ reportData: [] })
        firstCallData.map(element => {
            if (type === "Today") {
                //   alert(2)
                if (element.lead.date_time !== undefined && element.lead.date_time.includes(this.state.todayDate)) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
            else if (type === "Yesterday") {
                if (element.lead.date_time !== undefined && element.lead.date_time.includes(this.state.yesterdayDate)) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
            else if (type === "Last 7 Days") {
                if (element.lead.date_time !== undefined && element.lead.date_time <= this.state.todayDate + ' 23:59:59' && element.lead.date_time >= this.state.weekDate) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
            else if (type === "Custom") {

                if (element.lead.date_time !== undefined && element.lead.date_time <= this.state.customEndDate + ' 23:59:59' && element.lead.date_time >= this.state.customStartDate) {
                    let first_call = ''
                    if (element.calls && element.calls[0] && element.calls[0].calls.date_created !== undefined) {
                        first_call = element.calls[0].calls.date_created
                    }
                    tracking_arr.push({
                        'lead_id': element.lead._id,
                        'user_name': element.lead.user_name,
                        'date_time': element.lead.date_time,
                        'lead_source': element.lead.lead_source,
                        'lead_current_stage': element.lead.lead_current_stage,
                        'updated_on': element.lead.updated_on,
                        'first_call': first_call,
                        'lead_campaign': element.lead.lead_campaign,
                        'crmComments': element.lead.crmComments
                    })
                }
            }
        })
        this.setState({ reportData: tracking_arr, })
    }
    render() {
        //    console.log("currentSalesReportType",this.state.currentSalesReportType)
        return (
            <Container fluid>
                {/* <Row>
                    <Col lg={2} md={2} className="flex-left  col-padding-0"> */}


                <Form.Control as="select" className="header-select" name='ddlReportType' id='ddlReportType' onChange={this.ChangeOption} pnkdisplayerrormsg='Select City' value={this.state.currentChoice}
                    disabled={this.state.isOptionDisabled}>
                    <option value="sales-report" selected>Sales Report</option>
                    <option value="marketing" >Marketing</option>
                    <option value="inside-sales-report" >Inside Sales Report</option>
                    {/* <option value="meeting-tracker" >meeting-tracker</option> */}
                    <option value="meeting-tracker" >Meeting Tracker</option>
                    <option value="design-team-report" >Design Team Report</option>
                    <option value="design-tracker" >Design Tracker</option>
                    {/* <option value="HC-PM Schedule" >HC & PM Schedule</option> */}
                    {/* <option value="Inside Sales" >Inside Sales </option> */}
                    <option value="callback" >CallBack </option>
                    <option value="patch-report" >Patch Report </option>
                    <option value="followup-report" >Followup Report </option>
                    <option value="daily-report">Daily Report </option>
                    <option value="campaign-report">Lead Campaign Report </option>
                    <option value="sales-meeting-count">Sales Meeting Count </option>
                    <option value="sales-rm-daily-report">Sales RM daily report </option>
                    <option value="partial-report">Partial Assignment Report</option>
                    <option value="under-construction">Under Construction Report</option>
                    <option value="lead-tracking-report">Lead Tracking Report</option>

                </Form.Control>

                {/* </Col>
                </Row> */}
                <Options mystate={this.state} {...this.props} HandleUserInput={this.HandleUserInput}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    ChangeOption={this.ChangeOption}
                    HandleInsideSalesOptions={this.HandleInsideSalesOptions}
                    HandleHCSchedule={this.HandleHCSchedule}
                    HandleMeetingType={this.HandleMeetingType}
                    GetCustomData={this.GetCustomData}
                    ChangeSalesReportTypeMobile={this.ChangeSalesReportTypeMobile}
                    callBackReportType={this.callBackReportType}
                    PatchReportDefaultView={this.PatchReportDefaultView}
                    leadSourceData={this.leadSourceData}
                    CampaignReportDefaultView={this.CampaignReportDefaultView}
                // profileData={profileData}

                />
                {this.state.currentChoice === 'sales-meeting-count' ?
                    <SalesMeetingCount
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "sales-report" ?
                    <SalesReport mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "marketing" ?
                    <Marketing mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "inside-sales-report" ?
                    <InsideSalesReport mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                    /> : null}
                {this.state.currentChoice === "meeting-tracker" ?
                    <HCTracker mystate={this.state} {...this.props}
                        GetCustomMeetingData={this.GetCustomMeetingData}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GotoLeadEntry={this.GotoLeadEntry}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "design-team-report" ?
                    <DesignTeamReport mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "design-tracker" ?
                    <DesignTracker mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "HC-PM Schedule" ?
                    <HCPMSchedule mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "Inside Sales" ?
                    <InsideSales mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.currentChoice === "Lead Patched" ?
                    <LeadPatched mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData} /> : null}
                {this.state.showCustomDateModal === true ?
                    <MobCustomDatePicker
                        visible={this.state.showCustomDateModal}
                        mystate={this.state}
                        {...this.props}
                        HandleUserInput={this.HandleUserInput}
                        HideCustomDateModal={this.HideCustomDateModal}
                        GetCustomData={this.GetCustomData}

                    /> : null
                }
                {
                    this.state.currentChoice === "callback" ?
                        <CallbackReport
                            mystate={this.state} {...this.props}
                            callBackReportType={this.callBackReportType}
                            ChangeSalesReportType={this.ChangeSalesReportType}
                            HandleChangeDate={this.HandleChangeDate}
                            ShowSalesInsideModal={this.ShowSalesInsideModal}
                            GetCustomData={this.GetCustomData}
                            callbackReportData={this.callbackReportData}
                            GotoLeadEntry={this.GotoLeadEntry}
                        />
                        : null
                }
                {this.state.currentChoice === "patch-report" ?
                    <PatchReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        PatchDetailedReport={this.PatchDetailedReport}
                    />
                    : null
                }
                {this.state.currentChoice === "followup-report" ?
                    <FollowupReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                    />
                    : null
                }
                {this.state.currentChoice === "daily-report" ?
                    <DialyReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}

                    />
                    : null
                }
                {this.state.currentChoice === "campaign-report" ?
                    <LeadCampaign
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                        ChangeCampaignReportView={this.ChangeCampaignReportView}
                    />
                    : null
                }
                {this.state.currentChoice === "sales-rm-daily-report" ?
                    <RMDailyReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                        ChangeCampaignReportView={this.ChangeCampaignReportView}
                    />
                    : null
                }
                {this.state.currentChoice === "partial-report" ?
                    <PartialAssignmentReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                    />
                    : null
                }
                {this.state.currentChoice === "under-construction" ?
                    <UnderConstructionComp
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                    />
                    : null
                }
                {this.state.currentChoice === "lead-tracking-report" ?
                    <LeadTrackingReport
                        mystate={this.state} {...this.props}
                        salesReportType={this.salesReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        GetCustomData={this.GetCustomData}
                        ShowPatchDetails={this.ShowPatchDetails}
                        ShowCallModal={this.ShowCallModal}
                        GotoLeadEntry={this.GotoLeadEntry}
                        FirstCallData={this.FirstCallData}
                        ChangePage={this.ChangePage}
                        GetCustomDataCallDetails={this.GetCustomDataCallDetails}
                    />
                    : null
                }


                {/* <ViewData mystate={this.state} {...this.props} HideModal={this.HideModal} /> */}

                {this.state.getUser === true ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : ''}
                {((this.props.PnkStore.GetData("citylist") === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
                {(this.state.getReportData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetReportResponse) : ''}
                {/* {(this.state.getMarketingData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetMarketingReportResponse) : ''}*/}
                {(this.state.getCustomData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomDatatResponse) : ''}
                {/* {((this.state.getLeadData === true) ? GetLeadData(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")} */}
                {((this.state.getLeadData === true) ? GetLeadSource(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")}
                {(this.state.getCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetCallbackReportResponse) : ''}
                {((this.state.getUsersListByProfile === true) ? GetUserByProfile(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.ddlHandledById, '', this.HandleGetUserByProfileSuccessResponse) : "")}
                {((this.state.getAllCalls === true) ? AllCallList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetAllCallsListSuccessResponse) : "")}
                {((this.state.getCustomCallList === true) ? AllCallList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomAllCallsListSuccessResponse) : "")}
                {(this.state.getCustomCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCallbackReportResponse) : ''}
                {(this.state.getMeetingData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.meetingWeekStartDate, this.state.meetingWeekEndDate, this.HandleGetMeetingDatatResponse) : ''}
                {(this.state.getFirstCall === true) ? FirstCallConnectionByDate(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.setNo, this.state.setLength, this.calldetails_data, this.HandleGetFirstCallResponse) : ''}



            </Container>
        )
    }
}
export const ReportPage = PnkConnect(reportPage, "");