import { PnkAjax } from "../pnk-react/ajax/pnk-ajax";
// Profiles

export function AddUserProfile(store, userObj, successFunc) {
   
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    // return PnkAjax(store).Post('crm/profiles/json').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
    return PnkAjax(store).Post('crm/profiles/json').ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetUserProfileList(store, setNo = 0, status = '', successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    // alert(sessionStorage.getItem('current_user_login_token'))
    return PnkAjax(store).Get('crm/profiles/json/' + setNo + '/' + status).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    // return PnkAjax(store).Get('crm/profiles/json/' + setNo + '/' + status).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function GetUserProfileDetailsById(store, profile_id, successFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    // if (!(store.GetData('userdetails').length == 0)) {
    return PnkAjax(store).Get('crm/profile/json/detail/' + profile_id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    // return PnkAjax(store).Get('crm/profile/json/detail/' + profile_id).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
    // }
}
export function SearchProfileList(store, search_by, search_value, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/search/profiles/json/' + search_by + '/' + search_value).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function DeleteUserProfile(store, id, successFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Delete('crm/profile/json/delete/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
// export function GetUserDetailsById(store, user_id, successFunc) {
//     let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
//     return PnkAjax(store).Get('crm/user/json/' + user_id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
// }
export function EditUserProfile(store, profile_id, profileObj, successFunc) {
    //code for further validations
  
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/profile/json/update/' + profile_id).ExtraData(profileObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

// Users
export function AddEmployee(store, empObj, user_name, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/users/json/0/0/' + user_name).ExtraData(empObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetUserList(store, setNo = 0, set_length = 0, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };

    return PnkAjax(store).Get('crm/users/json/' + setNo + '/' + set_length).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    // return PnkAjax(store).Get('crm/users/json/' + setNo + '/' + set_length).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();

}
export function GetCityList(store, successFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PnkAjax(store).Get('admin/property/cities/json').AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();

}
export function GetUserDetailsById(store, user_id, successFunc) {
    
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/user/json/' + user_id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function EditUser(store, user_id, userObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/user/json/' + user_id).ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
/******Search User ********/
export function SearchUserList(store, search_by, search_value, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/search/users/json/' + search_by + '/' + search_value).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function ChangeUserStatus(store, userObj, status, id, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/userstatus/json/' + status + "/" + id).ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function DeleteUser(store, id, successFunc) {

    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Delete('crm/user/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
// Login
export function LoginUser(store, userObj, successFunc) {

    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/login/json').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
}
export function ForgotPasswordUser(store, userObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/forgetpasswordlink/json').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function CheckResetLink(store, link, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/verifyforgeturl/json/' + link).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function ResetPassword(store, actiontype, userObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/resetpassword/json/' + actiontype).ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
}

// Lead
export function GetAreaListByCity(store, cityname, searchkey, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    if (searchkey !== '') {
        return PnkAjax(store).Get('admin/property/areasbycity/json/' + searchkey + '/search').AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    }
    else {
        // if (!(store.GetData('arealist').length > 0)) {
        return PnkAjax(store).Get('admin/property/areasbycity/json/' + cityname).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
    }
    // }
    // else return HandleSuccessGetAreaListByCity(store.GetData('arealist'), store);
}
export function GetLeadData(store, successFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/leadentrydata/json').AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetUserByProfile(store, user_profile, user_name, profile_name, city_name = '', successFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/usersbyprofile/json/' + user_profile + '/' + user_name + '/' + profile_name + '/' + city_name).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}



// Lead
export function AddLead(store, user_profile, user_name, leadObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/leads/json/' + user_profile + '/' + user_name).ExtraData(leadObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function EditLead(store, user_profile, user_name, leadObj, lead_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/lead/json/' + user_profile + '/' + user_name + '/update/' + lead_id).ExtraData(leadObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

export function MultiEdit(store, user_profile, user_name, lead_obj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/multieditlead/json/' + user_profile + '/' + user_name).ExtraData(lead_obj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

export function GetLeadList(store, user_profile, user_name, setNo = 0, set_length = 0, sort_by = '', sort_order = '', successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/leads/json/' + user_profile + '/' + user_name + '/' + setNo + '/' + set_length + '/' + sort_by + '/' + sort_order).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function SearchLeadList(store, user_profile, user_name, setNo = 0, set_length = 0, search_by, search_value, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/search/leads/json/' + user_profile + '/' + user_name + '/' + setNo + '/' + set_length + '/' + search_by + '/' + search_value).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetLeadById(store, user_profile, user_name, lead_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/lead/json/' + user_profile + '/' + user_name + '/details/' + lead_id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}


//Advance Search
export function AdvancedSearch(store, user_profile, user_name, search_type, userObj, setNo = 0, set_length = 0, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/advancesearch/json/' + user_profile + '/' + user_name + '/' + search_type + '/' + setNo + '/' + set_length).ExtraData(userObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}


// Entry
// State list
export function GetStateList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('admin/property/states/json').AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function GetCountries(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('admin/property/countries/json/0').AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function GetAreas(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('admin/property/areas/json/0').AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function AddStates(store, stateObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('admin/property/states/json').ExtraData(stateObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function AddCities(store, cityObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('admin/property/cities/json').ExtraData(cityObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function AddAreas(store, areaObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('admin/property/areas/json').ExtraData(areaObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function DeleteCity(store, id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Delete('admin/property/city/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function DeleteArea(store, id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Delete('admin/property/area/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function DeleteState(store, id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Delete('admin/property/state/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetCityById(store, id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('admin/property/city/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function EditCity(store, id, cityObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('admin/property/city/json/' + id).ExtraData(cityObj).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetAreaById(store, id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('admin/property/area/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetStateById(store, id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('admin/property/state/json/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function EditState(store, id, cityObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('admin/property/state/json/' + id).ExtraData(cityObj).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function EditArea(store, id, cityObj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('admin/property/area/json/' + id).ExtraData(cityObj).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
// Lead Source
export function GetLeadSource(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/sourceleads/json/0/0').AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
//code for further validations
export function AddLeadSource(store, leadSourceObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/sourceleads/json').ExtraData(leadSourceObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function ChangeLeadSourceStatus(store, leadSourceObj, status, id, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/sourcelead/json/' + status + "/" + id).ExtraData(leadSourceObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function DeleteLeadSource(store, id, successFunc) {
    let loader = { isLoader: false, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Delete('crm/sourcelead/json/delete/' + id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function GetLeadSourceDetailsById(store, lead_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/sourcelead/json/detail/' + lead_id).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function EditLeadSource(store, id, leadsrcObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Put('crm/sourcelead/json/update/' + id).ExtraData(leadsrcObj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function SearchLeadSourceList(store, search_by, search_value, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/search/sourceleads/json/' + search_by + '/' + search_value).AttachLoader(loader).WetherPinakaResponse(false).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}