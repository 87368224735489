import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faSearch } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Button ,InputGroup,FormControl} from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
export default class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }
    GoToEntry = () => {
        this.setState({
            gotoEntry: true
        })

    }
    HandleChange = (e) => {
       
        this.props.SortData(e, e.target.value)
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.HandleUserSearch();
            // alert('enter press here! ')
        }
    }
    render() {
        if (this.state.gotoEntry === true) {
            return <Redirect to='/lead-entry' push={true} />
        }
        else {
            return (
                <div className="footer show-mob">
                    <Row className="footer-row">
                        <div style={{ width: '50%', border: 'solid 1px #d6d6d6' }}>
                            <div className="btn-div">
                            <InputGroup className="profile-search-box">
                                    <FormControl
                                        placeholder="Search by Name, Contact, Email"
                                        className="profile-search-box"
                                        name="txtUserSearch" id="txtUserSearch"
                                        value={this.props.mystate.txtUserSearch}
                                        onChange={this.props.HandleUserInput}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <InputGroup.Append onClick={this.props.HandleUserSearch}>
                                        <InputGroup.Text id="basic-addon2" className="profile-search-text search-icon-lead">
                                            <FontAwesomeIcon icon={faSearch} className="profile-search-icon" />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                {/* <Button className="filter-button">
                                    Filter<span style={{ paddingRight: '10%' }}> <i class="fa fa-bars"></i></span></Button> */}
                            </div>
                        </div>
                        {/* </Col>
                    <Col xs={7}> */}
                        <div style={{ width: '40%', border: 'solid 1px #d6d6d6' }}>
                            <div class="searchDropdownDiv ">
                                <div class="input-group" style={{ float: "right", height: 38 }} >
                                    <div className="input-group-prepend">
                                        <button className="SortByButton" type="button">
                                            Sort By:
        </button>
                                    </div>
                                    {/* <select name="sortBy" className="selectSearch" id="inputGroupSelect03" aria-label="Example select with button addon" >
                                        <option value="">Sort By</option>
                                        <option selected value="Name">All Lead</option>
                                        <option value="Location">Location</option>
                                    </select> */}
                                    <select name="sortBy" className="selectSearch" id="inputGroupSelect03" aria-label="Example select with button addon"
                                        onChange={this.HandleChange}
                                    >
                                        <option value="">Sort By</option>
                                        <option selected value="All Lead">All Lead</option>
                                        <option value="date_time" column="date_time" >Date</option>
                                        <option value="lead_city" column="lead_city" >Location</option>
                                        <option value="lead_current_stage" column="lead_current_stage">Status</option>
                                        <option value="lead_source" column="lead_source" >Source</option>
                                        <option value="lead_owner" column="lead_owner" >Owner</option>
                                        <option value="user_name" column="user_name" >Customer</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '10%', border: 'solid 1px #d6d6d6', display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {/* </Col>
                    <Col xs={2}> */}
                            <Button className="add-footer"><FontAwesomeIcon icon={faPlus} onClick={() => this.GoToEntry()} /></Button>
                            {/* </Col> */}
                        </div>
                    </Row>
                </div>
            )
        }
    }
}