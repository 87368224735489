import React, { Component } from "react";
// import Loader from '../../common/loader'
export const PnkLoader = (props) => {
  return (
    <div
      style={{
        /*boxShadow: '0 0 11px 2px',*/
        opacity: "0.9",
        padding: "0",
        borderRadius: "6px",
        position: "fixed",
        textAlign: "center",
        zIndex: "99999",
        left: "42%",
        top: "50%",
      }}
    >
      {props.size === "small" ? (
        // <Loader />
        <img
          height="30px"
          width="70%"
          src={require("../images/small.gif")}
          style={{ opacity: 1 }}
          alt=""
        />
      ) : props.size === "large" ? (
        <img
          height="100"
          width="100"
          src={require("../images/large.gif")}
          alt=""
        />
      ) : (
            <img
              height="100"
              width="100"
              src={require("../images/default.gif")}
              alt=""
            />
          )}
    </div>
  );
};
