import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import UploadModal from './bulk-upload'
import { Container } from 'react-bootstrap'

class bulkUploadPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showBulkUpload: false
        }
    }


    HideBulkUpload = () => {
        this.setState({
            showBulkUpload: false
        })
    }
    render() {

        return (
            <Container fluid className="data-table">
                <UploadModal HideCancel={this.HideCancel} visible={this.state.showBulkUpload} mystate={this.state} {...this.props}
                />

            </Container >
        )
    }
}
export const BulkUploadPage = PnkConnect(bulkUploadPage, "");
