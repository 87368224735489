import React from 'react'
import { PnkConnect } from "../../../pnk-react/pnk-miniredux/";
import {Container} from 'react-bootstrap'
import Options from './options'
import MeetingTracker from './meeting-tracker'
import SalesMeetingCount from './sales-meeting-count';
import { GetUserProfileList, GetCityList, GetUserList, GetLeadData, GetLeadSource } from '../../../models/users';
import { AdminSalesReport ,CallBackList} from '../../../models/admin-dashboard'
import DailyReport from './daily-report';
import AppUtils from '../../../apputils'
class salesHeadDashboard extends React.Component{
    constructor(props){
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Sales Head Dashbord');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.state={
            currentViewType:(this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "")?this.props.componentprops.match.params.type :'daily-report',
            currentSalesReportType: 'Today',
            txtCustomStartDate: new Date().setDate(new Date().getDate() - 29),
            txtCustomEndDate: new Date(),
            startDate: '',
            endDate: '',
            todayDate: '',
            yesterdayDate: '',
            weekDate: '',
            hcPmScheduleType:'hc',
            getUser:false,
            reportData:[],
            getReportData:false,
            ddlSalesUser:'',
            ddlPreferredCity:'',
            all_users_under_head:[],
            getCity: true,
            dayBeforeYestrday:'',
            meetingWeekStartDate:'',
            meetingWeekEndDate:'',
            getMeetingData:false,
            txtCustomMeetingStartDate:new Date(),
            txtCustomMeetingEndDate: new Date().setDate(new Date().getDate() + 7),
        }
        this.salesReportType = [
            'Today',
            'Yesterday',
            'Last 7 Days',
            // 'Last 30 Days',
            'Custom'
        ];
        this.currentCity = ''
        this.currentUser=''
        this.hcPmScheduleType='hc'
        this.all_users_under_head=[]



          // Hc Booked
          this.todayHCBookedReport = []
          this.yesterdayHCBookedReport = []
          this.weekHCBookedReport = []
              // PM Booked
        this.todayPMBookedReport = []
        this.yesterdayPMBookedReport = []
        this.weekPMBookedReport = []

          // VC
          this.todayVCCompletedReport = []
          this.yesterdayVCCompletedReport = []
          this.weekVCCompletedReport = []
           // Hc Completed
        this.todayHCCompleted = []
        this.yesterdayHCCompleted = []
        this.weekHCCompleted = []
        // PM Completed
this.todayPMCompleted=[]
this.yesterdayPMCompleted=[]
this.weekPMCompleted=[]

// Only for daily Report
this.todayDailyReport=[];
this.yesterdayDailyReport=[]
this.weekDailyReport=[]

this.adminReportAll=[]
    }
    componentDidMount = () => {
        var today = new Date();

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let endDate = yyyy + '-' + mm + '-' + dd;

        let beforeThirtyDays = new Date(new Date().setDate(new Date().getDate() - 6))
        var before_dd = String(beforeThirtyDays.getDate()).padStart(2, '0');
        var before_mm = String(beforeThirtyDays.getMonth() + 1).padStart(2, '0'); //January is 0!
        var before_yyyy = beforeThirtyDays.getFullYear();
        let startDate = before_yyyy + '-' + before_mm + '-' + before_dd;

        let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
        var yester_dd = String(yesterdayDate.getDate()).padStart(2, '0');
        var yester_mm = String(yesterdayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yester_yyyy = yesterdayDate.getFullYear();
        let yesterday = yester_yyyy + '-' + yester_mm + '-' + yester_dd;

        // Daily Report
        let dayBeforeYestrday = new Date(new Date().setDate(new Date().getDate() - 2))
        var dy_bf_yst = String(dayBeforeYestrday.getDate()).padStart(2, '0');
        var month_dy_bg_yst = String(dayBeforeYestrday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var year_dy_bf_yst = dayBeforeYestrday.getFullYear();
        let dayBfYest = year_dy_bf_yst + '-' + month_dy_bg_yst + '-' + dy_bf_yst;

        let weekDate = new Date(new Date().setDate(new Date().getDate() - 6))
        var week_dd = String(weekDate.getDate()).padStart(2, '0');
        var week_mm = String(weekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var week_yyyy = weekDate.getFullYear();
        let weekStartDate = week_yyyy + '-' + week_mm + '-' + week_dd;

        let monthDate = new Date(new Date().setDate(new Date().getDate() - 29))
        var month_dd = String(monthDate.getDate()).padStart(2, '0');
        var month_mm = String(monthDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var month_yyyy = monthDate.getFullYear();
        let monthStartDate = month_yyyy + '-' + month_mm + '-' + month_dd;

        let comingWeekDate = new Date(new Date().setDate(new Date().getDate() +7))
        var meeting_week_dd = String(comingWeekDate.getDate()).padStart(2, '0');
        var meeting_week_mm = String(comingWeekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var meeting_week_yyyy = comingWeekDate.getFullYear();
        let meetingWeekEndDate = meeting_week_yyyy + '-' + meeting_week_mm + '-' + meeting_week_dd;
        this.setState({
            startDate: startDate, endDate: endDate, getReportData: false, currentDisplayType: endDate,
            todayDate: endDate, yesterdayDate: yesterday, weekDate: weekStartDate, monthDate: monthStartDate,
            dayBeforeYestrday:dayBfYest,meetingWeekStartDate:endDate,meetingWeekEndDate:meetingWeekEndDate
        })

        if (JSON.stringify(this.props.PnkStore.GetData('userlist')) === '{}') {
            this.setState({
                getUser: true
            })
        }
        else{
            let all_users=this.props.PnkStore.GetData('userlist').rows
            // console.log("all_users",all_users);

            let head_tl_users=[]
            let all_users_under_head=[]
            if ( all_users &&  all_users.length>0) {
              
               
                all_users.forEach((user, i) => {
                    if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')
                   ) {
                        if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                            head_tl_users.push(user.user_name)
                        }
                    }
                })
                head_tl_users.forEach(head_user=>{
                    all_users.forEach((user,i)=>{
                        if(user.reporting_to===head_user || user.user_name===head_user){
                            let typeIndex = all_users_under_head.findIndex(type => user.user_name ===type.user_name);
                            if(typeIndex===-1)
                            all_users_under_head.push(user)
                        }
                    })
                })
            }
console.log("all_users_under_head comp",all_users_under_head)
            this.setState({
                all_users_under_head:all_users_under_head 
            })



        }
        if(this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== ""){
            if(this.props.componentprops.match.params.type==='meeting-tracker'){
                this.setState({
                    getMeetingData:true
                })
            }
            else if(this.props.componentprops.match.params.type==='callback'){
                this.setState({
                    getCallbackData:true
                })    
            }
            else{
                this.setState({
                    getReportData:true,
                   
                })
            }
        }
        else{
        this.setState({
            getReportData:true
        })
    }
    }

    HandleUserInput = (e) => {
      
        if(e.target.name==='ddlSalesUser'){
            this.currentUser=e.target.value
        }
        if(e.target.name==='ddlPreferredCity'){
            this.currentCity=e.target.value
        }
        this.setState({
            [e.target.name]: e.target.value
        })
        this.ChangeSalesReportType(this.state.currentSalesReportType);
    }
    ChangeSalesReportType = (type) => {
        this.setState({
            currentSalesReportType: type,
            hcPmScheduleType:'hc'
        })
       
        this.RenderReport(type,this.state.currentViewType)
    }
    ChangeSalesReportTypeMobile = (event) => {
        this.setState({
            currentSalesReportType: event.target.value
        })

        if (event.target.value !== "Custom") {
            this.setState({ showCustomDateModal: false })
            this.RenderReport( event.target.value,this.state.currentViewType)
        }
        else if (event.target.value === "Custom") {
            this.setState({ showCustomDateModal: true })
            // this.RenderCustomReport(this.state.currentViewType)
        }
    
    }
    ChangeViewReport = (event) => {
        this.setState({ currentViewType: event.target.value ,currentSalesReportType:'Today'})
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport('Today',event.target.value)
        }
      
    if(event.target.name==='currentViewType')
    {
        if(event.target.value==='CallBack'){
            this.setState({getCallbackData:true})
        }
       else if(event.target.value==='meeting-tracker'){
            this.setState({getCallbackData:false,
                getMeetingData:true,reportData:[]
            })
        }
        else if(this.adminReportAll.length<0){
            this.setState({
                getReportData:true
                });
        }

    }
    if (window.location.href.indexOf("/dashboard/") > -1)
    this.props.componentprops.history.push(event.target.value);
else
    this.props.componentprops.history.push('dashboard/' + event.target.value); 
    }
    HandleMeetingType=(type)=>{
        this.setState({
            hcPmScheduleType: type,
            // currentSalesReportType:'Today',
            reportData:[]

        })
        this.hcPmScheduleType=type;
        this.RenderReport('Today',"meeting-tracker")
    }
    ShowSalesInsideModal = (data) => {
        // this.props.componentprops.OpenModal(ViewData, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: '', icon: 'close' }, { action: this.state, name: 'Profile', close: this.HideModal, data: data })
    }
    HideModal = () => {
        this.props.componentprops.HandleCloseModal();
    }
    GetCustomData = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;

        // if(this.state.currentViewType==='CallBack')
        // {
        //     this.setState({
        //         customStartDate: startDate,
        //         customEndDate: endDate, getCustomData: false,
        //         ddlPreferredCity: '-1',
        //         ddlcurrentUser:'-1',
        //         getCustomCallbackData:true
        //     })
        // }
        // else{
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate, getCustomData: true,
            ddlPreferredCity: '-1',
            ddlcurrentUser:'-1',
            getCustomCallbackData:false
        })
    // }
        this.currentCity = ''
        this.currentUser=''
    }
    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    HandleGetUserListResponse = (res) => {
        let head_tl_users=[]
        let all_users_under_head=[]
        if (res.data && res.data.userlist && res.data.userlist &&  res.data.userlist.rows &&  res.data.userlist.rows.length>0) {
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
           
            res.data.userlist.rows.forEach((user, i) => {
                if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')
               ) {
                    if(user.reporting_to===sessionStorage.getItem('current_user_name') || user.user_name===sessionStorage.getItem('current_user_name')){
                        head_tl_users.push(user.user_name)
                    }
                }
            })
            head_tl_users.forEach(head_user=>{
                res.data.userlist.rows.forEach((user,i)=>{
                    if(user.reporting_to===head_user || user.user_name===head_user){
                        let typeIndex = all_users_under_head.findIndex(type => user.user_name ===type.user_name);
                        if(typeIndex===-1)
                        all_users_under_head.push(user)
                    }
                })
            })
        }

        this.all_users_under_head=all_users_under_head
        this.setState({ getUser: false,all_users_under_head:all_users_under_head })
    }
    
    HandleGetReportResponse = (res) => {
       
        this.setState({ getReportData: false, isOptionDisabled: false })
        if (res !== false) {
            if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
               
                this.adminReportAll=res.data.adminfirstmonthdata.rows;
                        res.data.adminfirstmonthdata.rows.forEach((element, i) => {
                            if(element.lead_department[0].profile_name.includes("Sales")){
                                if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                                    this.todayHCBookedReport.push(element)
                                }
                                else if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                                    this.yesterdayHCBookedReport.push(element);
                                }
                                if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                                    this.weekHCBookedReport.push(element)
                                }

                                 // PM todayPMBookedReport
                         if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate)) {
                            this.todayPMBookedReport.push(element)
                        }
                        else if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPMBookedReport.push(element);
                        }
                        if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate) {
                            this.weekPMBookedReport.push(element)
                        }
                          // VC Completed

                          if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate)) {
                            this.todayVCCompletedReport.push(element)
                        }
                        else if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayVCCompletedReport.push(element);
                        }
                        if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate) {
                            this.weekVCCompletedReport.push(element)
                        }
                    // HC Completed
                       
                        if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.todayDate)) {
                            this.todayHCCompleted.push(element)
                        }
                        else if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCCompleted.push(element);
                        }
                        if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate) {
                            this.weekHCCompleted.push(element)
                        }
 // PM Completed
                        
                       

 if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.todayDate)) {
    this.todayPMCompleted.push(element)
}
else if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.yesterdayDate)) {
    this.yesterdayPMCompleted.push(element);
}
if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.weekDate) {
    this.weekPMCompleted.push(element)
}
// Daily Report
if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.yesterdayDate
    || element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.yesterdayDate)
    || element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)
    )) {
        this.todayDailyReport.push(element)
}
else if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time.includes(this.state.dayBeforeYestrday)
|| element.hc_completed_date_time !== undefined && element.hc_completed_date_time.includes(this.state.dayBeforeYestrday)
    || element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.dayBeforeYestrday)
) {
    this.yesterdayDailyReport.push(element);
}
if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.weekDate
|| element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && element.hc_completed_date_time >= this.state.weekDate
    || element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate

) {
    this.weekDailyReport.push(element)
}



                               
                            }
                            if (res.data.adminfirstmonthdata.rows.length - 1 === i)
                            this.RenderReport(this.state.currentSalesReportType, this.state.currentViewType);
                         })



                    }

                }     
}
GotoLeadEntry=(lead_id)=>{

    AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/'+lead_id+ '/' + this.state.currentViewType);

}
RenderReport=(type,viewType)=>{
    console.log("all_users_under_head",this.state.all_users_under_head)
    if(viewType==='meeting-tracker'){
     
        let hc_report=[]
      
        this.state.all_users_under_head.forEach((user,i)=>{
            if(type==='Today'){
                if(this.hcPmScheduleType==='hc')
                {
                    this.setState({
                        reportData:[]
                    })
                    // alert(1)
                this.todayHCBookedReport.forEach(todayHCData => {
                    if(user.user_name===todayHCData.lead_owner){
                        if ( this.currentUser !== '' && ( this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                               
                            
                               
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
    
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                               
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                    
                                });
                                
                            }
    
                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                console.log("user",user)
                                console.log("todayHCData",todayHCData)
                                hc_report.push({
                                    lead_owner: todayHCData.lead_owner, 
                                    client_name: todayHCData.user_name, 
                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:todayHCData.preferred_area,
                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                    
                                });
                            }
                        }
                    }
                    
                
                   
                });
            }
            else if(this.hcPmScheduleType==="project-meeting"){
                this.setState({
                    reportData:[]
                })
                this.todayPMBookedReport.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                    }
                    }
                })
               
            }
            else if(this.hcPmScheduleType==="vc"){
                this.setState({
                    reportData:[]
                })
                this.todayVCCompletedReport.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                    }

                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            console.log("report",report)
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                    }
            
                }
            })
        }
    
            }
            else if (type === "Yesterday") {
                if(this.hcPmScheduleType==='hc'){
                    this.setState({
                        reportData:[]
                    })
                    this.yesterdayHCBookedReport.forEach(yesterdayHCData => {
                    if(yesterdayHCData.lead_owner===user.user_name){
                            if ( this.currentUser !== '' && ( this.currentUser === yesterdayHCData.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                    hc_report.push({
                                        lead_owner: yesterdayHCData.lead_owner, 
                                        client_name: yesterdayHCData.user_name, 
                                        hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:yesterdayHCData.preferred_area,
                                        hc_completed_date_time:yesterdayHCData.hc_completed_date_time
    
                                        
                                    });
                                }
                                else if (this.currentCity === '') {
                                  
                                    hc_report.push({
                                        lead_owner: yesterdayHCData.lead_owner, 
                                        client_name: yesterdayHCData.user_name, 
                                        hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:yesterdayHCData.preferred_area,
                                        hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                        
                                    });
                                }
    
                            }
                            else if ( this.currentUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                    hc_report.push({
                                        lead_owner: yesterdayHCData.lead_owner, 
                                        client_name: yesterdayHCData.user_name, 
                                        hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:yesterdayHCData.preferred_area,
                                        hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                        
                                    });
                                }
                                else if (this.currentCity === '') {
                                    hc_report.push({
                                        lead_owner: yesterdayHCData.lead_owner, 
                                        client_name: yesterdayHCData.user_name, 
                                        hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:yesterdayHCData.preferred_area,
                                        hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                        
                                    });
                                }
                            }
                        
                    }
                    });
                }
                else if(this.hcPmScheduleType==="project-meeting"){
                    this.setState({
                        reportData:[]
                    })
                    this.yesterdayPMBookedReport.forEach(report => {
                      if(report.lead_owner===user.user_name){
                        if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                               
    
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
    
    
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                console.log("report",report)
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                        }
                      }
                    })
                   
                }
                else if(this.hcPmScheduleType==="vc"){
                    this.setState({
                        reportData:[]
                    })
                    this.yesterdayVCCompletedReport.forEach(report => {
                        if(report.lead_owner===user.user_name){
                        if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                   
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                   
                                    
                                });
                            }
                        }
    
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                console.log("report",report)
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                });
                            }
                        }
                
                    }
                })
            }
    
                }
                else if (type === "Last 7 Days") {
                    this.setState({
                        reportData:[]
                    })
                    if(this.hcPmScheduleType==='hc'){
                    this.weekHCBookedReport.forEach(weekyHCData => {
                        if(weekyHCData.lead_owner===user.user_name){
                            if ( this.currentUser !== '' && ( this.currentUser === weekyHCData.lead_owner)) {
                                if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                    hc_report.push({
                                        lead_owner: weekyHCData.lead_owner, 
                                        client_name: weekyHCData.user_name, 
                                        hc_date_time: weekyHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:weekyHCData.preferred_area,
                                        hc_completed_date_time:weekyHCData.hc_completed_date_time
                                        
                                    });
                                }
                                else if (this.currentCity === '') {
                                    hc_report.push({
                                        lead_owner: weekyHCData.lead_owner, 
                                        client_name: weekyHCData.user_name, 
                                        hc_date_time: weekyHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:weekyHCData.preferred_area,
                                        hc_completed_date_time:weekyHCData.hc_completed_date_time
                                        
                                    });
                                }
    
                            }
                            else if ( this.currentUser === '') {
                                if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                    hc_report.push({
                                        lead_owner: weekyHCData.lead_owner, 
                                        client_name: weekyHCData.user_name, 
                                        hc_date_time: weekyHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:weekyHCData.preferred_area,
                                        hc_completed_date_time:weekyHCData.hc_completed_date_time
                                        
                                    });
                                }
                                else if (this.currentCity === '') {
                                    hc_report.push({
                                        lead_owner: weekyHCData.lead_owner, 
                                        client_name: weekyHCData.user_name, 
                                       
                                        hc_date_time: weekyHCData.hc_booked_date_time, 
                                        sales_tl:user.reporting_to,
                                        location:weekyHCData.preferred_area,
                                        hc_completed_date_time:weekyHCData.hc_completed_date_time
                                        
                                    });
                                }
                            }
                        
                        }
                    });
                }
                else if(this.hcPmScheduleType==="project-meeting"){
                    this.setState({
                        reportData:[]
                    })
                    this.weekPMBookedReport.forEach(report => {
                        if(report.lead_owner===user.user_name){
                        if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
    
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                        }
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    project_estimate:report.project_estimate,
                                    hc_completed_date_time:report.hc_completed_date_time,
                                    project_meeting_date_time:report.project_meeting_date_time,
                                    designer_name:report.designer_name
                                    
                                });
                            }
                        }
                        }
                    })
                   
                }
                else if(this.hcPmScheduleType==="vc"){
                    this.setState({
                        reportData:[]
                    })
                    this.weekVCCompletedReport.forEach(report => {
                        if(report.lead_owner===user.user_name){
                        if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                        }
    
                        else if ( this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                            else if (this.currentCity === '') {
                                console.log("report",report)
                                hc_report.push({
                                    lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                    
                                });
                            }
                        }
                
                    }
                })
            }
                }
               
            
        })
      
        this.setState({
            reportData:hc_report
        })
   



        
    }
    else if(viewType==='daily-report'){
        let daily_report_array=[];
        this.state.all_users_under_head.forEach((user,i)=>{
        if(type==='Today'){
            this.todayDailyReport.forEach(reportData => {
                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time.includes(this.state.yesterdayDate)
                    || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time.includes(this.state.yesterdayDate)
                    || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time.includes(this.state.yesterdayDate)){
                 if(user.user_name===reportData.lead_owner){
                    console.log("reportData",reportData)
                    daily_report_array.push({
                        'date':reportData.date_time,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'rm_name':reportData.lead_owner,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
            }

            })
           
        }
        if(type==='Yesterday'){
            this.yesterdayDailyReport.forEach(reportData => {
                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time.includes(this.state.dayBeforeYestrday)
                || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time.includes(this.state.dayBeforeYestrday)
                || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time.includes(this.state.dayBeforeYestrday)){

                if(user.user_name===reportData.lead_owner){
                    console.log("reportData",reportData)
                    daily_report_array.push({
                        'date':reportData.date_time,
                        'rm_name':reportData.lead_owner,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
            }

            })
           
        }
        if(type==='Last 7 Days'){
            this.weekDailyReport.forEach(reportData => {
                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && reportData.project_meeting_completed_date_time >= this.state.weekDate
                || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && reportData.hc_completed_date_time >= this.state.weekDate
                    || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time <= this.state.yesterdayDate+' 23:59:59' && reportData.vc_completed_date_time >= this.state.weekDate){

                if(user.user_name===reportData.lead_owner){
                    console.log("reportData",reportData)
                    daily_report_array.push({
                        'date':reportData.date_time,
                        'rm_name':reportData.lead_owner,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
            }

            })
           
        }
        })
        this.setState({
            reportData:daily_report_array
        })
        

    }
    else if(viewType==='sales-meeting-count'){
        let sales_count_arr=[] 
        this.state.all_users_under_head.forEach((user,i)=>{
            let hc_count=0,vc_count=0,pm_count=0,total_count=0;
            if(type==='Today'){
                this.todayHCCompleted.forEach(todayHCData => {
                    if(user.user_name===todayHCData.lead_owner){
                        if (this.currentUser !== '' && (this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                               
                                    hc_count++;
                                    total_count++
                                   
                                    
                                
                            }
                            else if (this.currentCity === '') {
                               
                                hc_count++;
                                total_count++
                               
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                hc_count++;
                                total_count++
                                
                            }
                            else if (this.currentCity === '') {
                                hc_count++;
                                    total_count++
                                   
                            }
                        }
                    }
                
                   
                });
                this.todayPMCompleted.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                          pm_count++
                          total_count++
                        }
                        else if (this.currentCity === '') {
                            pm_count++
                          total_count++
                        }
                    }
                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            pm_count++
                            total_count++
                        }
                        else if (this.currentCity === '') {
                            pm_count++
                          total_count++
                        }
                    }
                }
                })
                this.todayVCCompletedReport.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           vc_count++
                           total_count++
                        }
                        else if (this.currentCity === '') {
                            vc_count++
                            total_count++
                        }
                    }

                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            vc_count++
                           total_count++
                        }
                        else if (this.currentCity === '') {
                            vc_count++
                            total_count++
                        }
                    }
            }
               
            })
            }
            else if (type === "Yesterday") {
                this.yesterdayHCCompleted.forEach(todayHCData => {
                    if(user.user_name===todayHCData.lead_owner){
                        if (this.currentUser !== '' && (this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                               
                                    hc_count++;
                                    total_count++
                                   
                                    
                                
                            }
                            else if (this.currentCity === '') {
                               
                                hc_count++;
                                total_count++
                               
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                hc_count++;
                                total_count++
                                
                            }
                            else if (this.currentCity === '') {
                                hc_count++;
                                    total_count++
                                   
                            }
                        }
                    }
                
                   
                });
                this.yesterdayPMCompleted.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                          pm_count++
                          total_count++
                        }
                        else if (this.currentCity === '') {
                            pm_count++
                          total_count++
                        }
                    }
                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            pm_count++
                            total_count++
                        }
                        else if (this.currentCity === '') {
                            pm_count++
                          total_count++
                        }
                    }
                }
                })
                this.yesterdayVCCompletedReport.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           vc_count++
                           total_count++
                        }
                        else if (this.currentCity === '') {
                            vc_count++
                            total_count++
                        }
                    }

                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            vc_count++
                           total_count++
                        }
                        else if (this.currentCity === '') {
                            vc_count++
                            total_count++
                        }
                    }
            }
               
            })

            }
            else if (type === "Last 7 Days") {
                this.weekHCCompleted.forEach(todayHCData => {
                    if(user.user_name===todayHCData.lead_owner){
                        if (this.currentUser !== '' && (this.currentUser === todayHCData.lead_owner)) {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                               
                                    hc_count++;
                                    total_count++
                                   
                                    
                                
                            }
                            else if (this.currentCity === '') {
                               
                                hc_count++;
                                total_count++
                               
                            }

                        }
                        else if (this.currentUser === '') {
                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                hc_count++;
                                total_count++
                                
                            }
                            else if (this.currentCity === '') {
                                hc_count++;
                                    total_count++
                                   
                            }
                        }
                    }
                
                   
                });
                this.weekPMCompleted.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                          pm_count++
                          total_count++
                        }
                        else if (this.currentCity === '') {
                            pm_count++
                          total_count++
                        }
                    }
                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            pm_count++
                            total_count++
                        }
                        else if (this.currentCity === '') {
                            pm_count++
                          total_count++
                        }
                    }
                }
                })
                this.weekVCCompletedReport.forEach(report => {
                    if(user.user_name===report.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           vc_count++
                           total_count++
                        }
                        else if (this.currentCity === '') {
                            vc_count++
                            total_count++
                        }
                    }

                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            vc_count++
                           total_count++
                        }
                        else if (this.currentCity === '') {
                            vc_count++
                            total_count++
                        }
                    }
            }
               
            })

            }
            if(total_count>0){
                sales_count_arr.push({
                    lead_owner:user.user_name,
                    sales_tl:user.reporting_to,
                    hc_count:hc_count,
                    pm_count:pm_count,
                    vc_count:vc_count,
                    total_count:total_count
                })
            }
        })

        this.setState
        ({
            reportData:sales_count_arr
        }) 

    }

}
HandleGetCustomDatatResponse = (res) => {
      
    if (res !== false) {
        this.setState({
            getCustomData: false,
            showCustomDateModal: false
        })
       
        if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
            this.customData = res.data.adminfirstmonthdata.rows
            this.RenderCustomReport( res.data.adminfirstmonthdata.rows,this.state.currentViewType)
        }
    }
    this.setState({
        getCustomData: false,
        showCustomDateModal: false
    })
}
RenderCustomReport=(customData,viewType)=>{
    
    if(viewType==="meeting-tracker"){
      
        let hc_report=[]
        let custom_hc_booked_data=[],custom_pm_booked_data=[],cutom_vc_data=[]
        
      
        customData.forEach((element, i) => {
            if(element.lead_department[0].profile_name.includes("Sales")){
         
            if(element.hc_booked_date_time!==undefined && element.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate){
                custom_hc_booked_data.push(element)
            }
            if(element.project_meeting_date_time!==undefined && element.project_meeting_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate){
                custom_pm_booked_data.push(element)
            }
            if(element.vc_completed_date_time!==undefined && element.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate){
                cutom_vc_data.push(element)
            }
         
        }
    })
        this.state.all_users_under_head.forEach(user=>{
            if(this.hcPmScheduleType==='hc')
            {
            custom_hc_booked_data.forEach(report=>{
                if(report.lead_owner===user.user_name){
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           
                            
                           
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                 sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                hc_completed_date_time:report.hc_completed_date_time

                                
                            });
                        }
                        else if (this.currentCity === '') {
                           
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                 sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                hc_completed_date_time:report.hc_completed_date_time
                                
                            });
                            console.log("hc_report",hc_report)
                        }

                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                 sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                hc_completed_date_time:report.hc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                 sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                hc_completed_date_time:report.hc_completed_date_time
                                
                            });
                        }
                    }
                

                }
            })
        }
        if(this.hcPmScheduleType==='project-meeting')
        {
            custom_pm_booked_data.forEach(report=>{
                if(report.lead_owner===user.user_name){
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name

                                
                            });
                        }
                        else if (this.currentCity === '') {
                           
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                            console.log("hc_report",hc_report)
                        }

                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                    }
                

                }
            })
        }
        if(this.hcPmScheduleType==='vc')
        {
            cutom_vc_data.forEach(report=>{
                if(report.lead_owner===user.user_name){
                    if ( this.currentUser !== '' && ( this.currentUser === report.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                           
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time

                                
                            });
                        }
                        else if (this.currentCity === '') {
                           
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                            console.log("hc_report",hc_report)
                        }

                    }
                    else if ( this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                    client_name: report.user_name, 
                                    hc_date_time: report.hc_booked_date_time, 
                                    sales_tl:user.reporting_to,
                                    location:report.preferred_area,
                                    vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                                
                            });
                        }
                    }
                

                }
            })
        }
        })
        this.setState({
            reportData:hc_report
        })

    }
    if(viewType==="daily-report"){
        let customDailyReport=[];
        let daily_report_array=[]
        customData.forEach((element, i) => {
            if (element.lead_department[0].profile_name.includes("Sales")) {

                if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.customStartDate
                    || element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate
                        || element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate

                    ) {
                        customDailyReport.push(element)
                    }
               
            }
            
 
        })
        this.state.all_users_under_head.forEach(user=>{
            customDailyReport.forEach(reportData=>{
                if(reportData.project_meeting_completed_date_time !== undefined && reportData.project_meeting_completed_date_time <= this.state.customEndDate+' 23:59:59' && reportData.project_meeting_completed_date_time >= this.state.customStartDate
                || reportData.hc_completed_date_time !== undefined && reportData.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && reportData.hc_completed_date_time >= this.state.customStartDate
                    || reportData.vc_completed_date_time !== undefined && reportData.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && reportData.vc_completed_date_time >= this.state.customStartDate){

                if(user.user_name===reportData.lead_owner){
                    console.log("reportData",reportData)
                    daily_report_array.push({
                        'date':reportData.date_time,
                        'rm_name':reportData.lead_owner,
                        'client_name':reportData.user_name,
                        'client_contact':reportData.user_mobile_no,
                        'location':reportData.lead_city,
                        'hc_completed_date_time':reportData.hc_completed_date_time,
                        'project_completed_date_time':reportData.project_completed_date_time,
                        'vc_completed_date_time':reportData.vc_completed_date_time,
                        'id':reportData._id

                    })
                }
            }
            })
        })
        this.setState({
            reportData:daily_report_array
        })

    }
     if(viewType==="sales-meeting-count"){
        let sales_count_arr = [];
        let hc_datetime_report = []
        let pm_date_time_report=[]
        let vc_date_time_report=[]
        // console.log("this.customData",this.customData)
        customData.forEach((element, i) => {
            if (element.lead_department[0].profile_name.includes("Sales")) {
                if (element.hc_completed_date_time !== undefined && element.hc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_completed_date_time >= this.state.customStartDate) {
                    hc_datetime_report.push(element)
                }
                if (element.project_meeting_completed_date_time !== undefined && element.project_meeting_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_completed_date_time >= this.state.customStartDate) {
                    pm_date_time_report.push(element)
                }
                if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                    vc_date_time_report.push(element)
                }
            }
            
           
        })
        this.state.all_users_under_head.forEach(user=>{
            let hc_count=0,vc_count=0,pm_count=0,total_count=0;
            
            hc_datetime_report.forEach(todayHCData => {
                if(user.user_name===todayHCData.lead_owner){
                    if (this.currentUser !== '' && (this.currentUser === todayHCData.lead_owner)) {
                        if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                           
                                hc_count++;
                                total_count++
                               
                                
                            
                        }
                        else if (this.currentCity === '') {
                           
                            hc_count++;
                            total_count++
                           
                        }

                    }
                    else if (this.currentUser === '') {
                        if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                            hc_count++;
                            total_count++
                            
                        }
                        else if (this.currentCity === '') {
                            hc_count++;
                                total_count++
                               
                        }
                    }
                }
            
               
            });
            pm_date_time_report.forEach(report => {
                if(user.user_name===report.lead_owner){
                if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                      pm_count++
                      total_count++
                    }
                    else if (this.currentCity === '') {
                        pm_count++
                      total_count++
                    }
                }
                else if (this.currentUser === '') {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        pm_count++
                        total_count++
                    }
                    else if (this.currentCity === '') {
                        pm_count++
                      total_count++
                    }
                }
            }
            })
            vc_date_time_report.forEach(report => {
                if(user.user_name===report.lead_owner){
                if (this.currentUser !== '' && (this.currentUser === report.lead_owner)) {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                       vc_count++
                       total_count++
                    }
                    else if (this.currentCity === '') {
                        vc_count++
                        total_count++
                    }
                }

                else if (this.currentUser === '') {
                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                        vc_count++
                       total_count++
                    }
                    else if (this.currentCity === '') {
                        vc_count++
                        total_count++
                    }
                }
        }
           
        })
        if(total_count>0){
            sales_count_arr.push({
                lead_owner:user.user_name,
                sales_tl:user.reporting_to,
                hc_count:hc_count,
                pm_count:pm_count,
                vc_count:vc_count,
                total_count:total_count
            })
        }
        })
        this.setState({
            reportData:sales_count_arr
        })
    }

}
HandleChangeDate = (type, date) => {
       
    this.setState({
        [type]: date
    });

};
GetMeetingCustomData=()=>{
    var dd = String(new Date(this.state.txtCustomMeetingStartDate).getDate()).padStart(2, '0');
    var mm = String(new Date(this.state.txtCustomMeetingStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = new Date(this.state.txtCustomMeetingStartDate).getFullYear();
    let startDate = yyyy + '-' + mm + '-' + dd;
    var end_dd = String(new Date(this.state.txtCustomMeetingEndDate).getDate()).padStart(2, '0');
    var end_mm = String(new Date(this.state.txtCustomMeetingEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
    var end_yyyy = new Date(this.state.txtCustomMeetingEndDate).getFullYear();
    let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
    this.setState({
        meetingWeekStartDate:startDate,
        meetingWeekEndDate:endDate,
        getMeetingData:true
    })
   
}
HandleGetMeetingDatatResponse=(res)=>{
    console.log("HandleGetMeetingDatatResponse",res.data)
    let meetingArray=[]
    if(res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length>0){
        res.data.adminfirstmonthdata.rows.forEach(element=>{
            if(element.lead_current_stage==="HC Booked" && element.hc_booked_date_time!==undefined && element.hc_booked_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate
            ||
            element.lead_current_stage==="Project Meeting" && element.project_meeting_date_time!==undefined && element.project_meeting_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate
            ){
                console.log("",element.hc_booked_date_time,element.project_meeting_date_time)
                if(element.lead_current_stage==="HC Booked" && element.hc_booked_date_time !==undefined && element.hc_booked_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate){
                    let typeIndex = meetingArray.findIndex(type => element.hc_booked_date_time.substr(0,10) ===type.date_time);
                    if (typeIndex !== -1) {
                        let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                        if (valIndex === -1) {
                            meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type:'HC',user_details:[{client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}] });
                        }
                        else{
                            meetingArray[typeIndex].list[valIndex].user_details.push({client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id})
                        }
                    }
                    else{
                        meetingArray.push({'date_time': element.hc_booked_date_time.substr(0,10),list:[{'lead_owner': element.lead_owner, type:'HC',user_details:[{client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}]}]});
                    }
                }
                if( element.lead_current_stage==="Project Meeting" && element.project_meeting_date_time !==undefined && element.project_meeting_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate){
                    let typeIndex = meetingArray.findIndex(type => element.project_meeting_date_time.substr(0,10) ===type.date_time);
                    if (typeIndex !== -1) {
                        let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                        if (valIndex === -1) {
                            meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type:'PM',user_details:[{client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}] });
                        }
                        else{
                            meetingArray[typeIndex].list[valIndex].user_details.push({client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id})
                        }
                    }
                    else{
                        meetingArray.push({'date_time': element.project_meeting_date_time.substr(0,10),list:[{'lead_owner': element.lead_owner, type:'PM',user_details:[{client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city,id:element._id}]}]});
                    }
                }
           
            }
        })
    }
    console.log("meetingArray",meetingArray)
    meetingArray.sort((a,b)=> new Date(b.date_time).getTime()-new Date(a.date_time).getTime());
    
    this.setState({
        getMeetingData:false,
        reportData:meetingArray
    })
}
    render(){
        console.log("dayBeforeYestrday",this.state.dayBeforeYestrday)
        return(
            <Container fluid>
                 <Options mystate={this.state} {...this.props} HandleUserInput={this.HandleUserInput}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    ChangeViewReport={this.ChangeViewReport}
                    ChangeSalesReportTypeMobile={this.ChangeSalesReportTypeMobile}
                    HandleHCSchedule={this.HandleHCSchedule}
                    callBackReportType={this.callBackReportType}
                    HandleMeetingType={this.HandleMeetingType}
                />
                  {this.state.currentViewType === "meeting-tracker" ?
                <MeetingTracker
                 mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GotoLeadEntry={this.GotoLeadEntry}
                 GetMeetingCustomData={this.GetMeetingCustomData}/>:null}

                 {this.state.currentViewType==='sales-meeting-count'?
                    <SalesMeetingCount
                    mystate={this.state} {...this.props}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    HandleChangeDate={this.HandleChangeDate}
                    ShowSalesInsideModal={this.ShowSalesInsideModal}
                    GetCustomData={this.GetCustomData}/>
                 :null}
                 
                 {this.state.currentViewType==='daily-report'?
                    <DailyReport
                    mystate={this.state} {...this.props}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    HandleChangeDate={this.HandleChangeDate}
                    ShowSalesInsideModal={this.ShowSalesInsideModal}
                    GotoLeadEntry={this.GotoLeadEntry}
                    GetCustomData={this.GetCustomData}/>
                 :null}

{this.state.getUser === true ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : ''}
{(this.state.getReportData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetReportResponse) : ''}
{(this.state.getCustomData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomDatatResponse) : ''}
{((this.props.PnkStore.GetData("citylist") === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
{(this.state.getMeetingData === true) ? AdminSalesReport(this.props.PnkStore,'Administrator', sessionStorage.getItem('current_user_name'), this.state.meetingWeekStartDate, this.state.meetingWeekEndDate, this.HandleGetMeetingDatatResponse) : ''}
               
                </Container>
        )
    }
}
export const SalesHeadDashboard = PnkConnect(salesHeadDashboard, "");