export const pnkstore = {
    api: { baseurl: 'https://api.neoroots.design' },
    //api: {baseurl:'http://localhost:3000'},
    user: { name: '', password: '', displayName: '', email: '', role: '' },
    app: { mode: 'prod', token: '', startTime: '', lastLoggedTime: '' },
    error: { errorCode: '', errorType: '', errorMessage: '', prettyMessage: '' },
    ajax: { isFetching: false },
    wetherAuthenticated: false,

    // citylist: {},
    // featuredprops: {},
    // topdevelopers: {},
    // localities: {},
    // searchFullData: {},
    // propertyListBySearch: [],
    // propertySetListBySearch: [],
    // buildersList: {},




    //for testing
    // name: 'Shiva', age: 1000000,
    // wife: { name: 'Mata Sati', age: 900000 },
    // daas: [{ name: 'prabhash' }]

    profilelist: {},
    userlist: {},
    citylist: {},
    arealist: {},
    leaddata: {},
    leadlist: {},
    hclist: {},
    leadsource: {},
    advancesearchlist: {},
    allowed_routes: [],
    lastSearchBy: '',
    searchData: '',
    setNo: '',
    advanceSearch: {},
    advanceSearchtype: '',
    currentEntryChoice: '',
    setNoExotel:''

};

