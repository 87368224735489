import React from 'react'
import { Toast, Row, Col } from 'react-bootstrap'
export default class ToastMsg extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
       
        return (

            <Row>
                <Col xs={6} style={{ width: '100%' }}>
                    <Toast onClose={() => this.props.CloseToast()} show={this.props.visible}
                        delay={3000} autohide
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            right: '100%',
                        }}
                    >
                        <Toast.Header className={this.props.headerType}>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded mr-2"
                                alt=""
                            />
                            <strong className="mr-auto">Message</strong>
                            {/* <small>11 mins ago</small> */}
                        </Toast.Header>
                        <Toast.Body>{this.props.message}</Toast.Body>
                    </Toast>
                </Col>

            </Row>
        );
    }

}
