import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import Exotel_Calls from '../../assets/images/Exotel_Calls.svg'
export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    render() {
        let userMobileNumber = sessionStorage.getItem("current_user_mobile")

        return (
            <div className="show-mob le-mob-header">
                <div className="crm-container">
                    <div className="mob-container">
                        <Row >
                            <Col xs={3} className=" flex-left">
                                <Button variant="light" className="le-mob-header-plus-btn" onClick={() => alert("hai")}>+</Button>
                            </Col>
                            <Col xs={3} className=" flex-left">
                            {this.props.mystate.mode==='edit'?
                           
                                                      
                                                      <a onClick={() => this.props.ShowCallModal(this.props.editDataObj.txtPhone, userMobileNumber)} className="lead-entry-exotel-call-btn icon-padding "><img src={Exotel_Calls} style={{ height: 15 }} /></a>
                                                     
                                                    
                                                      :''}
                                                       </Col>
                            <Col xs={3} className="">
                                <Button variant="light" className="le-cancel mr-3" onClick={() => this.props.ShowCancel()}>CANCEL</Button>

                            </Col>
                            <Col xs={3} className="">
                                <Button variant="light" className="le-save">{this.props.mystate.showButtonType}</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}