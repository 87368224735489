import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
export default class ViewData extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
       
        return (
            <div>
                <FontAwesomeIcon icon={faTimes} className="modal-header-close" onClick={() => this.props.currentAction.close()} />
                {Object.keys(this.props.currentAction.data).length > 0 ?
                    <Row>

                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Customer Name</h3>
                                <span className="view-data-data">{this.props.currentAction.data.client_name}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Customer Contact</h3>
                                <span className="view-data-data">{this.props.currentAction.data.client_number}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Patch Date time</h3>
                                <span className="view-data-data">{this.props.currentAction.data.lead_patched_date}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Last Updated</h3>
                                <span className="view-data-data">{this.props.currentAction.data.last_edited}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Stage</h3>
                                <span className="view-data-data">{this.props.currentAction.data.lead_stage}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Lead Owner</h3>
                                <span className="view-data-data">{this.props.currentAction.data.lead_owner}</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="view-data-div">
                                <h3 className="view-data-label">Description </h3>
                                <span className="view-data-data">{this.props.currentAction.data.comment}</span>
                            </div>
                        </Col>
                 
                    </Row>
                    : null}
            </div>
        )
    }
} 