import React from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
export default class EntryForm extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
       
        return (
            <div className="entry-foom-main-div-mob">
                <div className="entry-container">
                    <Row>
                        {this.props.mystate.hideCity === false ?
                            <Col xs={12}>
                                <div className="entry-btn-pills">
                                    {this.props.mystate.currentChoiceMobile === "city" ?
                                        <div active={true} className="entry-btn-mobile" style={{ background: '#ECEDF3' }} onClick={() => this.props.ChangeOptionMobile('')}>
                                            <h3 className="entry-mob-title">  City {this.props.mystate.actionHeading}</h3>
                                            <FontAwesomeIcon icon={faAngleUp} className="entry-mob-icon" />
                                        </div> :
                                        <div active={true} className="entry-btn-mobile" style={{ background: '#FFF' }} onClick={() => this.props.ChangeOptionMobile('city')}>
                                            <h3 className="entry-mob-title">  City {this.props.mystate.actionHeading}</h3>
                                            <FontAwesomeIcon icon={faAngleDown} className="entry-mob-icon" />
                                        </div>}

                                </div>
                            </Col> : null}
                        {this.props.mystate.currentChoiceMobile === "city" ?

                            <Col xs={12} className="form-col-entry">
                                <div className="entry-form-div">
                                    <div className="entry-form">
                                        <PnkHtmlForm id="frmEntry" onsubmit={this.props.SaveEntryDataMobile} submitbtn="btnCityEntry">
                                            <fieldset>
                                                <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                                    <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                                                </div>
                                                <Form.Group >
                                                    <Form.Label className="entry-form-label">City Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter City" className="entry-form-cntrl"
                                                        name="txtCityName" id="txtCityName"
                                                        value={this.props.mystate.txtChoice}
                                                        onChange={this.props.HandleUserInput}
                                                        pnkvalue={this.props.mystate.txtChoice}
                                                        disabled={this.props.mystate.formModeActivity}
                                                    />
                                                    {this.props.mystate.invalidTxtChoice === true ?
                                                        <div className="errorMessage">Invalid Name</div> :
                                                        ''}
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label className="entry-form-label">State Name </Form.Label>
                                                    <Form.Control as="select" className="entry-form-cntrl" id="ddlState" name="ddlState"
                                                        onChange={this.props.HandleUserInput}
                                                        disabled={this.props.mystate.formModeActivity}
                                                        value={this.props.mystate.ddlChoice}
                                                        pnkvalue={this.props.mystate.ddlChoice}>
                                                        <option value="" selected disabled>Select State</option>
                                                        {JSON.stringify(this.props.mystate.stateList) !== '{}' && this.props.mystate.stateList.rows.length > 0 ? this.props.mystate.stateList.rows.map(state =>
                                                            state.state_status == 1 ?
                                                                <option value={state.state_name}>{state.state_name}</option> :
                                                                ''
                                                        ) : ''}
                                                    </Form.Control>
                                                    {this.props.mystate.invalidDDlChoice === true ?
                                                        <div className="errorMessage">Select One</div> :
                                                        ''}
                                                </Form.Group>
                                                <Form.Group >
                                                    {this.props.mystate.chkEntryStatus === 'on' ?
                                                        <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} checked={true} label="Status" id="chkCityEntryStatus" name="chkCityEntryStatus" className="entry-chk" onChange={this.props.HandleUserInput} /> :
                                                        <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} checked={false} label="Status" id="chkCityEntryStatus" name="chkCityEntryStatus" className="entry-chk" onChange={this.props.HandleUserInput} />
                                                    }
                                                </Form.Group>
                                                <Form.Group >
                                                    <Button variant="light" className="entry-add-btn" id="btnCityEntry" onClick={() => this.props.SaveEntryDataMobile()}>{this.props.mystate.showButtonType} City</Button>
                                                </Form.Group>
                                            </fieldset>
                                        </PnkHtmlForm>
                                    </div>
                                </div>
                            </Col> : ''}
                        {this.props.mystate.hideArea === false ?
                            <Col xs={12}>
                                <div className="entry-btn-pills">
                                    {this.props.mystate.currentChoiceMobile === "area" ?

                                        <div active={true} className="entry-btn-mobile" style={{ background: '#ECEDF3' }} onClick={() => this.props.ChangeOptionMobile('')}>
                                            <h3 className="entry-mob-title">  Area {this.props.mystate.actionHeading}</h3>
                                            <FontAwesomeIcon icon={faAngleUp} className="entry-mob-icon" />
                                        </div> :
                                        <div active={true} className="entry-btn-mobile" style={{ background: '#FFF' }} onClick={() => this.props.ChangeOptionMobile('area')}>
                                            <h3 className="entry-mob-title">  Area {this.props.mystate.actionHeading}</h3>
                                            <FontAwesomeIcon icon={faAngleDown} className="entry-mob-icon" />
                                        </div>}

                                </div>
                            </Col> : null}
                        {this.props.mystate.currentChoiceMobile === "area" ?
                            <Col xs={12} className="form-col-entry">
                                <div className="entry-form-div">
                                    <div className="entry-form">
                                        <PnkHtmlForm id="frmEntry" onsubmit={this.props.SaveEntryDataMobile} submitbtn="btnAreaEntry">
                                            <fieldset>
                                                <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                                    <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                                                </div>
                                                <Form.Group >
                                                    <Form.Label className="entry-form-label">Area Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Area" className="entry-form-cntrl"
                                                        name="txtAreaName" id="txtAreaName"
                                                        value={this.props.mystate.txtChoice}
                                                        onChange={this.props.HandleUserInput}
                                                        pnkvalue={this.props.mystate.txtChoice}
                                                        disabled={this.props.mystate.formModeActivity} />
                                                    {this.props.mystate.invalidTxtChoice === true ?
                                                        <div className="errorMessage">Invalid Name</div> :
                                                        ''}
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label className="entry-form-label">City Name </Form.Label>
                                                    <Form.Control as="select" className="entry-form-cntrl" id="ddlCity" name="ddlCity"
                                                        onChange={this.props.HandleUserInput}
                                                        value={this.props.mystate.ddlChoice}
                                                        pnkvalue={this.props.mystate.ddlChoice}
                                                        disabled={this.props.mystate.formModeActivity}>
                                                        <option value="" selected disabled>Select City</option>
                                                        {this.props.PnkStore.GetData("citylist") !== '{}' && this.props.PnkStore.GetData("citylist").rows && this.props.PnkStore.GetData("citylist").rows.length > 0 ?
                                                            this.props.PnkStore.GetData("citylist").rows.map(city =>
                                                                city.city_status === 1 ?
                                                                    <option value={city.city_name}>{city.city_name}</option> : ''
                                                            ) : ""}
                                                    </Form.Control>
                                                    {this.props.mystate.invalidDDlChoice === true ?
                                                        <div className="errorMessage">Select One</div> :
                                                        ''}
                                                </Form.Group>
                                                <Form.Group >
                                                    {this.props.mystate.chkEntryStatus === 'on' ?
                                                        <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} checked={true} label="Status" id="chkAreaEntryStatus" name="chkAreaEntryStatus" className="entry-chk" onChange={this.props.HandleUserInput} /> :
                                                        <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} checked={false} label="Status" id="chkAreaEntryStatus" name="chkAreaEntryStatus" className="entry-chk" onChange={this.props.HandleUserInput} />
                                                    }

                                                </Form.Group>
                                                <Form.Group >
                                                    <Button variant="light" id="btnAreaEntry" className="entry-add-btn" onClick={() => this.props.SaveEntryDataMobile()}>{this.props.mystate.showButtonType} Area</Button>
                                                </Form.Group>
                                            </fieldset>
                                        </PnkHtmlForm>
                                    </div>
                                </div>
                            </Col> : ''}
                        {this.props.mystate.hideState === false ?
                            <Col xs={12}>
                                <div className="entry-btn-pills">
                                    {this.props.mystate.currentChoiceMobile === "state" ?
                                        <div active={true} className="entry-btn-mobile" style={{ background: '#ECEDF3' }} onClick={() => this.props.ChangeOptionMobile('')}>
                                            <h3 className="entry-mob-title">  State {this.props.mystate.actionHeading}</h3>
                                            <FontAwesomeIcon icon={faAngleUp} className="entry-mob-icon" />
                                        </div> :
                                        <div active={true} className="entry-btn-mobile" style={{ background: '#FFF' }} onClick={() => this.props.ChangeOptionMobile('state')}>
                                            <h3 className="entry-mob-title">  State {this.props.mystate.actionHeading}</h3>
                                            <FontAwesomeIcon icon={faAngleDown} className="entry-mob-icon" />
                                        </div>}

                                </div>
                            </Col> : null}
                        {this.props.mystate.currentChoiceMobile === "state" && this.props.mystate.hideState === false ?
                            <Col xs={12} className="form-col-entry">
                                <div className="entry-form-div">
                                    <div className="entry-form">
                                        <PnkHtmlForm id="frmEntry" onsubmit={this.props.SaveEntryDataMobile} submitbtn="btnStateEntry">
                                            <fieldset>
                                                <div className="edit-btn-div" style={{ display: this.props.showEditBtn }}>
                                                    <Button variant="light" className="profile-entry-cancel-btn" onClick={this.props.ShowEditMode}>Edit</Button>
                                                </div>
                                                <Form.Group >
                                                    <Form.Label className="entry-form-label">State Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter State" className="entry-form-cntrl"
                                                        name="txtStateName" id="txtStateName"
                                                        onChange={this.props.HandleUserInput}
                                                        value={this.props.mystate.txtChoice}
                                                        pnkvalue={this.props.mystate.txtChoice}
                                                        disabled={this.props.mystate.formModeActivity}
                                                    />
                                                    {this.props.mystate.invalidTxtChoice === true ?
                                                        <div className="errorMessage">Invalid Name</div> :
                                                        ''}
                                                </Form.Group>
                                                <Form.Group >
                                                    <Form.Label className="entry-form-label">Country Name </Form.Label>
                                                    <Form.Control as="select" className="entry-form-cntrl" id="ddlCountry" name="ddlCountry"
                                                        onChange={this.props.HandleUserInput}
                                                        value={this.props.mystate.ddlChoice}
                                                        pnkvalue={this.props.mystate.txtChoice}
                                                        disabled={this.props.mystate.formModeActivity}
                                                    >
                                                        <option value="" selected disabled>Select Country</option>
                                                        {JSON.stringify(this.props.mystate.countryList) !== '{}' && this.props.mystate.countryList.rows.length > 0 ? this.props.mystate.countryList.rows.map(country =>
                                                            <option value={country.country_name}>{country.country_name}</option>
                                                        ) : ''}
                                                    </Form.Control>
                                                    {this.props.mystate.invalidDDlChoice === true ?
                                                        <div className="errorMessage">Select One</div> :
                                                        ''}
                                                </Form.Group>
                                                <Form.Group >
                                                    {this.props.mystate.chkEntryStatus === 'on' ?
                                                        <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} checked={true} label="Status" className="entry-chk" id="chkStateEntryStatus" name="chkStateEntryStatus" onChange={this.props.HandleUserInput} /> :
                                                        <Form.Check type="checkbox" disabled={this.props.mystate.formModeActivity} label="Status" className="entry-chk" id="chkStateEntryStatus" name="chkStateEntryStatus" onChange={this.props.HandleUserInput} />
                                                    }
                                                </Form.Group>
                                                <Form.Group >
                                                    <Button variant="light" className="entry-add-btn" id="btnStateEntry" onClick={() => this.props.SaveEntryDataMobile()}>{this.props.mystate.showButtonType} State</Button>
                                                </Form.Group>
                                            </fieldset>
                                        </PnkHtmlForm>
                                    </div>
                                </div>
                            </Col> : ''}
                    </Row>
                </div >
            </div >
        )
    }
}