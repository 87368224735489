import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import UserEntry from './user-entry'
import { Container } from 'react-bootstrap'
import { AddEmployee, GetCityList, GetUserProfileList, GetUserList, GetUserDetailsById, EditUser } from '../../models/users'
import $ from 'jquery';
class userEntryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showUserModal: false,
            sendUserData: false,
            ddlUserReportingTo: '',
            ddlUserProfile: '',
            ddlUserStatus: '',
            ddlUserCity: '',
            getCity: true,
            profilelist: true,
            getUserList: true,
            showCityddl: 'none',
            pnkValidateCity: '',
            currentSeletedProfileId: '',
            getUserProfileEditData: false,
            showButtonType: 'Save',
            showEditBtn: 'none',
            editUserProfile: false,
            ddlAllowedRoutesStatus: false,
            ddlAllowedLeadStagesStatus: false,
            ddlAllowedBookingStagesStatus: false,
            ddlUserProfileStatusStatus: false,
            getProfileData: false
        }
        this.editDataObj = {}
        this.data = {}
    }
    componentDidMount = () => {
        this.ChangePageMode()
    }
    HandleUserInput = (e) => {
        let val = e.target.value;
        let name = e.target.name;
       
        if (name === "ddlUserProfile") {
            let result = this.props.PnkStore.GetData('profilelist').rows.filter(obj => {
                return obj.profile_name === 'Telecalling' || obj.profile_name === 'Sales TL'
            })
            if (val === result[0]._id || val === result[1]._id) {
                // alert("hhh")
                this.setState({
                    showCityddl: 'block',
                    pnkValidateCity: 'forceselect'
                })
            }
            else {
                this.setState({
                    showCityddl: 'none',
                    pnkValidateCity: ''
                })
            }
        }
        this.editDataObj[name] = val;
        this.setState({ [name]: val })
    }
    HideModal = () => {
        this.props.componentprops.currentAction.close();
    }

    SaveUserData = (e) => {

        this.data = {
            ddlEmpCity: e.ddlUserCity,
            ddlEmpProfile: e.ddlUserProfile,
            ddlEmpReporting: e.ddlUserReportingTo,
            ddlEmpStatus: e.ddlUserStatus,
            txtEmpEmail: e.txtUserEmail,
            txtEmpName: e.txtUserFullName,
            txtEmpUserName: e.txtUserName,
            txtEmpMobile: e.txtUserPhone,
            txtEmpSalary: e.txtUserSalary
        }
        if (this.state.showButtonType === "Save") {
            this.setState({
                sendUserData: true
            })
        }
        else {
            this.setState({
                editUserProfile: true
            })
        }

    }
    HandleUserSubmiteResponse = (res) => {
      
        if (res !== false) {
            if (res.data && res.data.userregisterresponse && res.data.userregisterresponse.value === "SUCCESS") {
                this.setState({
                    sendUserData: false
                })
                this.HideModal();
            }
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Profile Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({
                sendUserData: false
            })
            this.HideModal();
        }

    }
    HandleUserProfileListResponse = (res) => {
        this.props.PnkStore.SetData('profilelist', res.data.profilelist);
        this.props.PnkStore.BroadCast();
        this.setState({
            profilelist: false
        })
    }
    ChangePageMode = () => {
        let obj;
       
        if (this.props.componentprops.currentAction.action.action === 'Detail') {
            obj = {
                profileActionHeading: 'Profile Detail',
                editDeleteDiv: true,
                getUserProfileEditData: true,
                currentSeletedProfileId: this.props.componentprops.currentAction.action.id,
                showButtonType: 'Update',
                formModeActivity: true,
                btnUserProfileOuterDiv: false,

                ddlUserProfileStatus: "",
                ddlAllowedRoutes: [],
                ddlAllowedLeadStages: [],
                ddlAllowedBookingStages: [],
            };
            let form = document.getElementById("frmUserEntry");
            let elements = form.elements;

            for (let i = 0, len = elements.length; i < len; ++i) {
                if (elements[i].id !== '')
                    elements[i].disabled = true;
            }
            
        }
        else {
            obj = { formModeActivity: false, btnUserProfileOuterDiv: true, editDeleteDiv: false };
            let form = document.getElementById("frmUserEntry");
            let elements = form.elements;
            for (let i = 0, len = elements.length; i < len; ++i) {
                elements[i].disabled = false;
            }
        }

        this.setState(obj);
    }
    ShowEditMode = () => {
       
        let form = document.getElementById("frmUserEntry");
        let elements = form.elements;
        for (let i = 0, len = elements.length; i < len; ++i) {
            elements[i].disabled = false;
        }

        let selectElement = document.getElementById('ddlUserReportingTo');
        selectElement.querySelector('option[value="' + this.editDataObj.txtUserName + '"]').disabled = true;

        document.getElementById('txtUserName').disabled = true;
        this.setState({ formModeActivity: false, profileActionHeading: 'Edit User', btnUserProfileOuterDiv: true, showEditBtn: 'none' })

    }
    HandleGetUserByIdResponse = (res) => {
     

        if (res.data && res.data.userdetails && res.data.userdetails.rows) {

            let userdata = res.data.userdetails.rows[0];

            let status = '';
            if (userdata.user_status === 0)
                status = "0";
            else
                status = "1";
            this.editDataObj = {
                txtUserFullName: userdata.user_full_name,
                txtUserName: userdata.user_name,
                txtUserEmail: userdata.user_email,
                txtUserPhone: userdata.user_mobile,
                txtUserSalary: userdata.user_salary,
                ddlUserReportingTo: userdata.reporting_to,
                ddlUserProfile: userdata.user_profile[0].profile_name,
                ddlUserStatus: status,


            }
            this.setState({
                ddlUserReportingTo: userdata.reporting_to,
                ddlUserProfile: userdata.user_profile[0].profile_name,
                ddlUserStatus: status
            })
            if (userdata.user_profile[0].profile_name === "Telecalling" || userdata.user_profile[0].profile_name === "Sales TL") {
                this.setState({
                    showCityddl: 'block',
                    pnkValidateCity: 'forceselect',
                    ddlUserCity: userdata.city_name
                })
            }
            else {
                this.setState({
                    showCityddl: 'none',
                    pnkValidateCity: ''
                })
            }
            document.getElementById('txtUserName').disabled = true;
            this.setState({ getUserProfileEditData: false, showEditBtn: 'block' });
        }

    }
    HandleEditUserDataSuccessResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.usereditresponse && res.data.usereditresponse.value === "SUCCESS") {
                this.setState({
                    editUserProfile: false,
                    getProfileData: true
                })
                this.HideModal();
            }
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "User Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
            this.setState({
                editUserProfile: false,
                getProfileData: true
            })
            this.HideModal();
        }

    }

    render() {
        let userlist = this.props.PnkStore.GetData('userlist');
        let profilelist = this.props.PnkStore.GetData("profilelist")
        let citylist = this.props.PnkStore.GetData('citylist');
        let arealist = this.props.PnkStore.GetData('arealist');
        var newarr = [];
        if (this.props.componentprops.currentAction && this.props.componentprops.currentAction.id && this.props.componentprops.currentAction.action === 'Detail') {
            var params = { key: '_id', value: this.props.componentprops.currentAction.id };
            var userArr = userlist.rows;
            userArr.some(function (item, index) {
                return (userArr[index][params.key] === params.value) ? !!(userArr.splice(index, 1)) : false;
            });
            newarr = userArr;
        }
        else {
            if (userlist !== undefined)
                newarr = userlist.rows;
        }
        return (
            <Container fluid className="data-table">
                <UserEntry HideUserModal={this.HideModal} visible={this.state.showUserModal} mystate={this.state}
                    {...this.props} HandleUserInputs={this.HandleUserInput} SaveUserData={this.SaveUserData}
                    UserData={newarr}
                    ProfileData={profilelist}
                    CityData={citylist}
                    AreaData={arealist}
                    editDataObj={this.editDataObj}
                    ShowEditMode={this.ShowEditMode}
                    showEditBtn={this.state.showEditBtn} />
                {(JSON.stringify(profilelist) === '{}' && this.state.profilelist === true) ? GetUserProfileList(this.props.PnkStore, 0, 'active', this.HandleUserProfileListResponse) : ''}
                {/* {this.getCity === true ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : ''} */}
                {((this.state.sendUserData === true) ? AddEmployee(this.props.PnkStore, this.data, sessionStorage.getItem('current_user_name'), this.HandleUserSubmiteResponse) : "")}

                {(this.state.getUserProfileEditData === true) ? GetUserDetailsById(this.props.PnkStore, this.state.currentSeletedProfileId, this.HandleGetUserByIdResponse) : ''}
                {((this.state.editUserProfile === true) ? EditUser(this.props.PnkStore, this.state.currentSeletedProfileId, this.data, this.HandleEditUserDataSuccessResponse) : "")}
                {/* {((JSON.stringify(userlist) !== {} || this.state.getUserList === true) ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : '')} */}

                {/* {((this.state.getUserEditData === true) ? GetUserDetailsById(this.props.PnkStore, this.props.componentprops.currentAction.id, this.HandleGetUserDetailsByIdSuccessResponse) : "")}
                {((this.state.editUser === true) ? EditUser(this.props.PnkStore, this.props.componentprops.currentAction.id, this.editDataObj, this.HandleEditUserDataSuccessResponse) : "")} */}
            </Container >
        )
    }
}
export const UserEntryPage = PnkConnect(userEntryPage, 'userlist', 'profilelist', 'citylist', 'arealist');