import React from "react";
import PropTypes from "prop-types";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { PnkProvider } from "../pnk-react/pnk-miniredux/";
import { pnkstore } from "../stores/pnk-store";

import SinglePageLayout from "../layouts/singlepagelayout";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { LeadPage } from "../Components/Lead"
import { LeadEntryPage } from '../Components/LeadEntry'
import { LoginPage } from '../Components/Login'
import { ResetPage } from '../Components/ResetPassword'
import { UserPage } from '../Components/User-List'
import UserChangePasswordPage from '../Components/User-List/user_changepassword'
import { UserEntryPage } from '../Components/UserEntry'
import { ProfilePage } from '../Components/Profile'

import { BookingPage } from '../Components/Booking-List'
import { EntryPage } from '../Components/Entries'
import { EntryListPage } from '../Components/Entry-List'
import { LeadSourcePage } from '../Components/LeadSource'
import AdminRoute from '../Components/Dashboard/dashboard-route'
import { HCEntryPage } from '../Components/HC-Form'
import {HCListPage} from '../Components/HC-List/'
import { SalesTLReportPage } from '../Components/Dashboard/SalesTL'

import { ExotelCallPage } from '../Components/ExotelCalls'
import  {SearchFormPage}  from '../Components/Reports'
// import OurWorkPage from '../components/OurWork'

// import TermsConditionPage from '../components/TermsConditions'
// import PrivacyPolicyPage from '../components/PrivacyPolicy'
// import ReferFriendPage from '../components/ReferFriend'
// import HowWeWorkPage from '../components/HowWeWork'

// import VegGardenPage from '../components/Vegetable-Garden'
// import VertGardenPage from '../components/Vertical-Garden'
// import VegQustns from '../components/Veg-Questions'
// import VertQustns from '../components/Vertical-Questions'
// Get Quote Questions

import NotFound from "../Components/notfound";

const Root = () => (
  // <MuiThemeProvider>
  <PnkProvider store={pnkstore}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {/* <BrowserRouter basename="/pinclick"> */}
      <div>
        {/* <Pnk_Error_Ctrl/> */}
        <Switch>
          {/* <Route exact path="/" component={HomePage} /> */}
          <Route exact path="/" render={(props) => (< LoginPage {...props} />)} />
          <Route exact path="/login" render={(props) => (< LoginPage {...props} />)} />
          {/* <Route exact path="/dashbord" render={(props) => (<SinglePageLayout children={DashboardPage} {...props} />)} /> */}
          <Route exact path="/leads" render={(props) => (<SinglePageLayout children={LeadPage} {...props} />)} />
          <Route exact path="/leads/:id" render={(props) => (<SinglePageLayout children={LeadPage} {...props} />)} />
          <Route exact path="/lead-entry" render={(props) => (<SinglePageLayout children={LeadEntryPage} {...props} />)} />
          <Route exact path="/lead-entry/:id" render={(props) => (<SinglePageLayout children={LeadEntryPage} {...props} />)} />
          <Route exact path="/lead-entry/:id/:type?" render={(props) => (<SinglePageLayout children={LeadEntryPage} {...props} />)} />
         
          <Route exact path="/users" render={(props) => (<SinglePageLayout children={UserPage} {...props} />)} />
          {/* <Route exact path="/user_changepassword"
                        render={(props) => (<SinglePageLayout children={UserChangePasswordPage} {...props} />)} /> */}
          {/* <Route exact path="/user-entry" render={(props) => (<SinglePageLayout children={UserEntryPage} {...props} />)} /> */}
          <Route exact path="/profiles" render={(props) => (<SinglePageLayout children={ProfilePage} {...props} />)} />
          <Route exact path="/resetpassword/:link" render={(props) => (<ResetPage {...props} />)} />
          <Route exact path="/bookings" render={(props) => (<SinglePageLayout children={BookingPage} {...props} />)} />
          <Route exact path="/entries" render={(props) => (<SinglePageLayout children={EntryListPage} {...props} />)} />
          <Route exact path="/entry" render={(props) => (<SinglePageLayout children={EntryPage} {...props} />)} />
          <Route exact path="/entry/:type/:id?" render={(props) => (<SinglePageLayout children={EntryPage} {...props} />)} />
          <Route exact path="/lead-source" render={(props) => (<SinglePageLayout children={LeadSourcePage} {...props} />)} />
         <Route exact path="/dashboard/" render={(props) => (<SinglePageLayout children={AdminRoute} {...props} />)} />
         <Route exact path="/dashboard/:type" render={(props) => (<SinglePageLayout children={AdminRoute} {...props} />)} />

         <Route exact path="/exotel-calls" render={(props) => (<SinglePageLayout children={ExotelCallPage} {...props} />)} />
         <Route exact path="/reports" render={(props) => (<SinglePageLayout children={SearchFormPage} {...props} />)} />
         <Route exact path="/hc-form/:id" render={(props) => (<SinglePageLayout children={HCEntryPage} {...props} />)} />
         <Route exact path="/hc-list" render={(props) => (<SinglePageLayout children={HCListPage} {...props} />)} />

         

          {/* <Route
              exact
              path="/"
              render={(props) => (
                <SinglePageLayout children={HomePage} {...props} />
              )}
            />
            <Route
              exact
              path="/about"
              render={(props) => (
                <SinglePageLayout children={AboutPage} {...props} />
              )}
            />
            <Route
              exact
              path="/what-we-do"
              render={(props) => (
                <SinglePageLayout children={WhatWeDoPage} {...props} />
              )}
            />
            <Route
              exact
              path="/why-neoroots-design"
              render={(props) => (
                <SinglePageLayout children={DesignPage} {...props} />
              )}
            />
            <Route
              exact
              path="/gift-a-garden"
              render={(props) => (
                <SinglePageLayout children={GardenPage} {...props} />
              )}
            />
            <Route
              exact
              path="/our-work"
              render={(props) => (
                <SinglePageLayout children={OurWorkPage} {...props} />
              )}
            />
            <Route
              exact
              path="/say-hello"
              render={(props) => (
                <SinglePageLayout children={ContactPage} {...props} />
              )}
            />
            <Route
              exact
              path="/terms-conditions"
              render={(props) => (
                <SinglePageLayout children={TermsConditionPage} {...props} />
              )}
            />
            <Route
              exact
              path="/privacy-policy"
              render={(props) => (
                <SinglePageLayout children={PrivacyPolicyPage} {...props} />
              )}
            />
            <Route
              exact
              path="/how-we-work"
              render={(props) => (
                <SinglePageLayout children={HowWeWorkPage} {...props} />
              )}
            />
            <Route
              exact
              path="/refer-a-friend"
              render={(props) => (
                <SinglePageLayout children={ReferFriendPage} {...props} />
              )}
            />
            <Route
              exact
              path="/get-quote"
              render={(props) => (
                <SinglePageLayout children={Quote} {...props} />
              )}
            />
            <Route
              exact
              path="/career"
              render={(props) => (
                <SinglePageLayout children={CareerPage} {...props} />
              )}
            />
            <Route
              exact
              path="/vegetable-garden"
              render={(props) => (
                <SinglePageLayout children={VegGardenPage} {...props} />
              )}
            />
            <Route
              exact
              path="/vertical-garden"
              render={(props) => (
                <SinglePageLayout children={VertGardenPage} {...props} />
              )}
            />
            <Route
              exact
              path="/vegetable-garden-get-quote"
              render={(props) => (
                <SinglePageLayout children={VegQustns} {...props} />
              )}
            />
            <Route
              exact
              path="/vertical-garden-get-quote"
              render={(props) => (
                <SinglePageLayout children={VertQustns} {...props} />
              )}
            /> */}


          {/******************** 404 Page Route *********************/}
          {/* <Route exact path="*" component={NotFound} /> */}
        </Switch>
      </div>
    </BrowserRouter>
  </PnkProvider>
  // </MuiThemeProvider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
