import React from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import Alert from './alert'
export default class CallModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            shoAdvanceOption: false
        }
    }
    render() {

      
        return (
            <Modal
                closeButton
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.visible}
                className="call-modal"
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter" className="make-a-call-header">
                        <h3 className="make-call-head">  Make a call</h3>
                        <FontAwesomeIcon icon={faTimes} className="cm-close" onClick={this.props.HideCallModal} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form >
                        <Form.Group>
                            <Form.Label className="cm-label-name">Your Number</Form.Label>
                            <Form.Control type="text"
                                placeholder="Your Number"
                                className="cm-form-controls"
                                value={this.props.mystate.txtFromNumber}
                                id="txtFromNumber"
                                name="txtFromNumber"
                                maxLength={10}
                                disabled={this.props.mystate.userNumberDisable}
                                onChange={this.props.HandleUserInput} />



                            {this.props.mystate.invalidtxtFromNumber === true ? <Alert message="Invalid Mobile Number" /> : null}
                        </Form.Group>

                        <Form.Group >
                            <Form.Label className="cm-label-name">Dial</Form.Label>

                            <Form.Control
                                type="text"
                                className="cm-form-controls"
                                placeholder="Dial Number"
                                value={this.props.mystate.txtCustomerNumber}
                                id="txtCustomerNumber"
                                name="txtCustomerNumber"
                                maxLength={10}
                                disabled={this.props.mystate.custNumberDisable}
                                onChange={this.props.HandleUserInput} />
                            {this.props.mystate.invalidtxtCustomerNumber === true ? <Alert message="Invalid Mobile Number" /> : null}

                        </Form.Group>
                        {/* <Row >
                            <Col lg={12} className="flex-left">
                                <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ shoAdvanceOption: !this.state.shoAdvanceOption })} className="cm-advance">Advance<FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} /> </a>
                            </Col>
                        </Row > */}
                        {this.state.shoAdvanceOption === true ?
                            <Row className="mt-3">
                                <Col lg={12} ><Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label className="cm-label-name">From User Profile</Form.Label>
                                    <Form.Control as="select" className="cm-form-controls"
                                        onChange={this.props.HandleUserInput}
                                        id="ddlCallProfile" name="ddlCallProfile"
                                        value={this.props.mystate.ddlCallProfile}
                                    >
                                        <option disabled selected>Profiles</option>
                                        {(this.props.profileData && this.props.profileData.rows && this.props.profileData.rows.length > 0) ? this.props.profileData.rows.map(item =>
                                            <option value={item.profile_name} id={item._id}>{item.profile_name}</option>

                                        ) : ""}
                                    </Form.Control>
                                </Form.Group></Col>
                                <Col lg={12} ><Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label className="cm-label-name">From User </Form.Label>
                                    <Form.Control as="select" className="cm-form-controls"
                                        onChange={this.props.HandleUserInput}
                                        id="ddlCallUser" name="ddlCallUser"
                                        value={this.props.mystate.ddlCallUser}>
                                        <option value="" disabled selected>From User</option>
                                        {(this.props.userData && this.props.userData.rows && this.props.userData.rows.length > 0) ? this.props.userData.rows.map(item =>
                                            item.user_name === this.props.mystate.ddlCallUser ?
                                                <option value={item.user_name} selected>{item.user_name}</option> :
                                                <option value={item.user_name}>{item.user_name}</option>

                                        ) : ""}
                                    </Form.Control>
                                </Form.Group></Col>
                            </Row> : null}
                        <Row >
                            <Col lg={12} className="flex-center">
                                <div className="cm-btn-div">
                                    <Button type="button" onClick={this.props.CallNow} className="cm-btn">Call Now</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={this.props.HideCallModal}>Cancel</Button>
                </Modal.Footer> */}
            </Modal>
        )
    }
}