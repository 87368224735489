import React from 'react'
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
export default class Options extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoEntry: false
        }
    }

    render() {


        return (
            <Row className="hide-mob">
                <Col lg={12} className="sidebar-col">

                    <div className="action-div">
                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                            <Col lg={2} md={4} className="col-padding-0">
                                <div className="btn-div">
                                    <Button type="button" className="add-button" onClick={() => this.props.ShowProfileModal()}>+ Add New Booking</Button>
                                </div>
                            </Col>
                            <Col lg={3} md={4} className="col-padding-0">
                                <div>
                                    <div class="searchDropdownDiv ">
                                        <div class="input-group" style={{ float: "right", height: 38 }} >
                                            <div className="input-group-prepend">
                                                <button className="SortByButton" type="button">
                                                    Sort By:
        </button>
                                            </div>
                                            <select name="sortBy" className="selectSearch" id="inputGroupSelect03" aria-label="Example select with button addon" >
                                                <option value="">Sort By</option>
                                                <option selected value="Name">All Lead</option>
                                                <option value="Location">Location</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={4} className="col-padding-0">
                                <InputGroup className="profile-search-box">
                                    <FormControl
                                        placeholder="Search by Name, Contact, Email"
                                        className="profile-search-box"
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className="profile-search-text">
                                            <FontAwesomeIcon icon={faSearch} className="profile-search-icon" />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>

                        </Row>

                        {/* <div className="btn-div">
                            <Button className="filter-button">Filter</Button>
                        </div> */}

                    </div>
                </Col>
            </Row>
        )
    }
}