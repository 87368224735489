import { PnkAjax } from '../pnk-react/ajax/pnk-ajax';

// export function MakeCall(store,user_profile,user_name, Obj, successFunc) {
//     let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
//     return PnkAjax(store).Post('crm/bookings/json/'+user_profile+'/'+user_name).ExtraData(Obj).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
export function MakeCall(store, client, customer_no, answered_by, called_by, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('exotel/call/' + client + '/' + customer_no + '/' + answered_by + '/' + called_by).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function MakeWhite(store, client, customer_no, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('exotel/whitelist/' + client + '/' + customer_no).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}
export function CallList(store, setNo = 0, set_length = 0, sort_by = '', sort_order = '', successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('exotel/calls/json/' + setNo + '/' + set_length + '/' + sort_by + '/' + sort_order).AttachLoader(loader).OnSuccess(successFunc).Do();
}
export function SearchCallList(store, setNo = 0, set_length = 0, search_by, search_value, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('exotel/search/calls/json/' + setNo + '/' + set_length + '/' + search_by + '/' + search_value).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}

export function SearchCallListByCity(store, city_name, user_profile,successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('exotel/searchbycity/calls/json/' + city_name+"/"+user_profile).AttachLoader(loader).WetherPinakaResponse(false).OnSuccess(successFunc).Do();
}

export function SendNotificationsToSpecificUsers(store, obj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('admin/app/specificnotification/json').ExtraData(obj).AttachLoader(loader).OnSuccess(successFunc).Do();
    // return PnkAjax(store).Post('admin/app/notification/json').ExtraData(obj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

export function SendNotificationsToAll(store, obj, successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('admin/app/notification/json').ExtraData(obj).AttachLoader(loader).OnSuccess(successFunc).Do();
    // return PnkAjax(store).Post('admin/app/notification/json').ExtraData(obj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}

export function IncomingCallList(store, setNo = 0, set_length = 0, sort_by = '', sort_order = '', successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('exotel/incomingcalls/json/' + setNo + '/' + set_length + '/' + sort_by + '/' + sort_order).AttachLoader(loader).OnSuccess(successFunc).Do();
}
export function FirstCall(store,user_profile,user_name, setNo = 0, set_length = 0,successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Get('crm/firstcallconnection/json/' + user_profile + '/' + user_name +  '/'+ setNo + '/' + set_length ).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
export function FirstCallConnectionByDate(store,user_profile,user_name,setNo,set_length,search_obj,successFunc) {
    let loader = { isLoader: true, containerId: 'testpnk', size: 'small' };
    return PnkAjax(store).Post('crm/firstcallconnection/json/search/' + user_profile+'/'+user_name+'/'+ setNo + '/' + set_length ).ExtraData(search_obj).AttachLoader(loader).AddHeader({ 'x-access-token': sessionStorage.getItem('current_user_login_token') }).OnSuccess(successFunc).Do();
}
