import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import { Container } from 'react-bootstrap'
import Option from './options'
import { Row, Col, Button, InputGroup, FormControl, Form } from 'react-bootstrap'
import Table from './entry-list'
import AppUtils from '../../apputils';
import Footer from './footer'
import { GetStateList, GetAreas, GetCityList, GetAreaListByCity, DeleteCity, DeleteArea, DeleteState, GetCityById } from '../../models/users'
class entryListPage extends React.Component {
    constructor(props) {
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Miscellaneous');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.state = {
            currentChoice: 'city',
            getCity: true,
            getState: false,
            getArea: false,
            stateList: {},
            numItemsPerPage: 10,
            totalRecordsInDb: 0,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 1,
            totalPages: 0,
            txtManualPageNumber: '',
            getAreaByCity: false,
            ddlPreferredCity: '',
            currentSeletedId: '',
            deleteCity: false,
            deleteArea: false,
            deleteState: false,
            getCityById: false,
            txtSearchAreaKey: '',
        }
    }

    componentDidMount = () => {

        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })


    }

    HandleUserSearch = (e) => {
        let val = e.target.value;
        let name = e.target.name;
       
        this.setState({
            [name]: val,

        })
        if (val.length >= 3) {
            this.setState({

                getAreaByCity: true
            })
        }
        else {
            this.setState({

                getAreaByCity: true
            })
        }
        if (val === '') {
            this.setState({

                getAreaByCity: false,
                getArea: true
            })
        }

    }
    HandleUserInputs = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ddlPreferredCity") {
            this.setState({
                getAreaByCity: true
            })
        }

    }
    Reload = () => {

        this.setState({
            getCity: true,
            currentChoice: 'city',

        })
    }
    HandleGetCityListSuccessResponse = (res) => {
       
        if (res.data && res.data.citylist && res.data.citylist.rows) {

            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    ChangeOption = (e) => {
        let choice = e.target.value
        this.setState({
            currentChoice: choice,
            currentPageNumber: 0
        })
        if (choice === "city") {
            this.setState({
                getCity: true
            })
        }
        if (choice === "state") {
            this.setState({
                getState: true
            })
        }
        if (choice === "area") {
            this.setState({
                getArea: true
            })
        }
    }
    HandleStateListResponse = (res) => {
        
        this.setState({
            stateList: res.data.statelist
        })
    }
    HandleAreaListResponse = (res) => {
       
        if (res.data && res.data.arealist && res.data.arealist.rows) {
            this.props.PnkStore.SetData('arealist', res.data.arealist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getArea: false
        })
    }
    HandleGetAreaSuccessResponse = (res) => {
        if (res.data && res.data.arealist && res.data.arealist.rows) {
            this.props.PnkStore.SetData('arealist', res.data.arealist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getAreaByCity: false,
            currentChoice: "area"
        })
    }
    ChangePage = (number) => {
       
        if (number - 1 === 0) {
            this.setState({ currentPageNumber: number, startValue: 0, endValue: (number * this.state.numItemsPerPage) });
        }
        else {
            this.setState({ currentPageNumber: number, startValue: ((number - 1) * this.state.numItemsPerPage), endValue: (number * this.state.numItemsPerPage) });
        }
    }
    HandleUserAction = (e, id, status) => {
        const val = e.target.value;
        const name = e.target.name;
        if (val === 'Detail') {
            AppUtils.HandlePageClick(this.props.componentprops, 'entry/' + this.state.currentChoice + '/' + id)
            // this.props.PnkStore.SetData("currentEntryChoice", this.state.currentChoice)
            // this.props.PnkStore.BroadCast();
            // let action = { id: id, action: val, status: status };
            // this.props.componentprops.OpenModal(ProfileEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Profile Detail' }, { action: action, name: 'Profile', close: this.CloseModal })
        }
        else if (val === 'Delete') {
            if (window.confirm("Are you sure?"))
                if (this.state.currentChoice === "city") {
                    this.setState({
                        deleteCity: true
                    })
                }
            if (this.state.currentChoice === "area") {
                this.setState({
                    deleteArea: true
                })
            }
            if (this.state.currentChoice === "state") {
                this.setState({
                    deleteState: true
                })
            }
            this.setState({ currentSeletedId: id, getCity: false, getArea: false, getState: false });
        }

        this.setState({ [name]: '' });
    }
    HandleDeleteCityResponse = (res) => {
        this.setState({
            deleteCity: false,
            getCity: true
        })
    }
    HandleDeleteAreaResponse = (res) => {
        this.setState({
            deleteArea: false,
            getArea: true
        })
    }
    HandleDeleteStateResponse = (res) => {
        this.setState({
            deleteState: false,
            getState: true
        })
    }
    render() {
      
        return (
            <Container fluid className="data-table">
                {/* <Row>
                    <Col lg={2} md={2} className="flex-left  col-padding-0"> */}
                <Form.Control as="select" className="header-ddl"
                    name='ddlPreferredCity'
                    id='ddlPreferredCity'
                    onChange={this.ChangeOption}
                    pnkdisplayerrormsg='Select City'
                    value={this.state.currentChoice}

                    style={{ padding: '4px', width: '100%' }}>
                    <option value="city" selected>City List</option>
                    <option value="area" >Area List</option>
                    <option value="state" >State List</option>


                </Form.Control>

                {/* </Col>
                </Row> */}
                <Option HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInputs}
                    Reload={this.Reload}
                    {...this.props} />
                <Table mystate={this.state} {...this.props} ChangePage={this.ChangePage}
                    HandleUserAction={this.HandleUserAction} />
                <Footer HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInputs}
                    Reload={this.Reload}
                    {...this.props} />
                {((this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {((this.state.getState === true) ? GetStateList(this.props.PnkStore, this.HandleStateListResponse) : "")}
                {((this.state.getArea === true) ? GetAreas(this.props.PnkStore, this.HandleAreaListResponse) : "")}
                {((this.state.getAreaByCity === true) ? GetAreaListByCity(this.props.PnkStore, this.state.ddlPreferredCity, this.state.txtSearchAreaKey, this.HandleGetAreaSuccessResponse) : "")}
                {((this.state.deleteCity === true) ? DeleteCity(this.props.PnkStore, this.state.currentSeletedId, this.HandleDeleteCityResponse) : "")}
                {((this.state.deleteArea === true) ? DeleteArea(this.props.PnkStore, this.state.currentSeletedId, this.HandleDeleteAreaResponse) : "")}
                {((this.state.deleteState === true) ? DeleteState(this.props.PnkStore, this.state.currentSeletedId, this.HandleDeleteStateResponse) : "")}
            </Container>
        )
    }
}

export const EntryListPage = PnkConnect(entryListPage, "leadlist");