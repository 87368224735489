import React from 'react'
import { Row, Col, Button, ButtonGroup, Form, Image } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../../Pagination'
export default class PreSalesReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numItemsPerPage: 10,
            currentPageNumber: 1,
            startValue: 0,
            endValue: 0
        }
    }
    componentDidMount = () => {
        this.setState({
            endValue: (this.state.startValue + this.state.numItemsPerPage)
        })


    }

    render() {
        return (
            <Row>
                <Col lg={12} className="sidebar-col">
                    <div className="crm-body">
                        <Row className="mob-row-align hide-mob">
                            <div className="report-btn-div">
                                <ButtonGroup className="report-btn-group">
                                    {this.props.salesReportType.map(item =>
                                        this.props.mystate.currentSalesReportType === item ?
                                            <Button variant="light" className="report-btn" active={true} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button> :
                                            <Button variant="light" className="report-btn" active={false} onClick={() => this.props.ChangeSalesReportType(item)}>{item}</Button>
                                    )}
                                </ButtonGroup>
                                {this.props.mystate.currentSalesReportType === "Custom" ?
                                    <div className="custom-date-div">

                                        <DatePicker
                                            name="txtCustomStartDate" id="txtCustomStartDate"
                                            selected={this.props.mystate.txtCustomStartDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomStartDate", e)}
                                            className="custom-date-picker"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <span className="ml-2 mr-3">To</span>
                                        <DatePicker
                                            name="txtCustomEndDate" id="txtCustomEndDate"
                                            selected={this.props.mystate.txtCustomEndDate}
                                            onChange={(e) => this.props.HandleChangeDate("txtCustomEndDate", e)}
                                            className="custom-date-picker mr-3"
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select date"
                                        />
                                        <Button variant="light" className="custom-apply-btn" onClick={() => this.props.GetCustomData()}>Apply</Button>



                                    </div> : null}
                            </div>
                        </Row>
                        {this.props.mystate.currentViewType === "default" ?
                           <div className="lead-table-div">
                           <div className="home-container">
                               <div className="mob-container">
                                   {this.props.mystate.reportData.length > 0 ?
                                       this.props.mystate.reportData.map(item =>
                                           <Row className="mob-row-align db-row">

                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Total In Progress</div>
                                                           <div className="tele-calling-db-count">{item.total_in_progress}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Total HC-Booked</div>
                                                           <div className="tele-calling-db-count">{item.total_hc_booked}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Total HC Completed</div>
                                                           <div className="tele-calling-db-count">{item.total_hc_completed}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Total Design Handovered</div>
                                                           <div className="tele-calling-db-count">{item.total_design_handovered}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Total Proposal Sent</div>
                                                           <div className="tele-calling-db-count">{item.total_proposal_sent}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Total Design Uploaded</div>
                                                           <div className="tele-calling-db-count">{item.total_proposal_uploaded}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col lg={2} sm={6} xs={6} className="db-col">
                                                   <div className='telecalling-stats'>
                                                       <div>
                                                           <div className="tele-calling-db-head">Booking Amout Received</div>
                                                           <div className="tele-calling-db-count">{item.total_booking_amount_received}</div>
                                                       </div>
                                                   </div>
                                               </Col>
                                           </Row>) : null}
                               </div>
                           </div>
                       </div>

                            : null}
                        {this.props.mystate.currentViewType === "details" ?
                            <div className="lead-table-div">
                                <div className="home-container">
                                    <div className="mob-container">
                                        <Row className="mob-row-align mob-row-color">
                                            <Col lg={2} xs={3}>
                                                <h3 className="table-head-lead ">Customer Name</h3>
                                            </Col>

                                            <Col lg={2} xs={3}>
                                                <h3 className="table-head-lead">Phone Number</h3>
                                            </Col>
                                            <Col lg={2} className="hide-mob">
                                                <h3 className="table-head-lead ">Patch Date time</h3>
                                            </Col>

                                            <Col lg={2} className="hide-mob">
                                                <h3 className="table-head-lead">Last Updated </h3>
                                            </Col>
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Stage</h3>
                                            </Col>
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead ">Lead Owner</h3>
                                            </Col>
                                            <Col lg={2} xs={4} className="">
                                                <h3 className="table-head-lead ">Comment</h3>
                                            </Col>
                                          
                                            {/* <Col lg={1} xs={4} className="hide-mob">
                                                <h3 className="table-head-lead">Stage</h3>
                                            </Col> */}


                                        </Row>
                                        <div>
                                            {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                                (item, i) =>
                                                    <div className="lead-table-data-user">
                                                        <Row className="table-items-user" style={{ background: '#fff' }}>

                                                            <Col lg={2} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="">{item.client_name}</div>

                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={2} className="" xs={3}>

                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.client_number}</div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={2} className="hide-mob" xs={2}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.lead_patched_date}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={2} className="hide-mob" xs={2}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.last_edited}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={1} className="hide-mob" xs={2}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.lead_stage}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={1} className="hide-mob" xs={2}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div>{item.lead_owner}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={2} className="" xs={4}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                        <div className="desc-overflow">{item.comment}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            
                                                        
                                                            <Col xs={2} className="show-mob">
                                                                <FontAwesomeIcon icon={faEye} className="dashbord-view-more-icon" onClick={() => this.props.ShowSalesInsideModal(item)} />
                                                            </Col>

                                                        </Row>
                                                    </div>
                                            ) : null}

                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            {this.props.mystate.currentViewType === "followupreport" ?
                                
                                <div className="lead-table-div">
                                    <div className="home-container">
                                        <div className="mob-container">
                                            <Row className="mob-row-align mob-row-color">
                                            <Col lg={1} className="hide-mob">
                                                <h3 className="table-head-lead">Sr. No.</h3>
                                            </Col>
                                            <Col lg={2} xs={3}>
                                                <h3 className="table-head-lead">Lead Owner</h3>
                                            </Col>
                                            <Col lg={2} xs={3}>
                                                <h3 className="table-head-lead ">Client Name</h3>
                                            </Col>
                                            
                                            <Col lg={2} xs={3}className="">
                                                <h3 className="table-head-lead ">Follw Up Date Time</h3>
                                            </Col>
                                                
                                              
    
    
                                            </Row>
                                            <div>
                                                {this.props.mystate.reportData.length > 0 ? this.props.mystate.reportData.map(
                                                    (item, i) =>
                                                        <div className="lead-table-data-user">
                                                            <Row className="table-items-user" style={{ background: '#fff' }}>
    
                                                            <Col lg={1} className="hide-mob">
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div> {i + 1}</div>
    
                                                                        </div>
                                                                    </div>
    
                                                                </Col>
    
                                                                <Col lg={2} className="" xs={3}>
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.lead_owner}</div>
    
                                                                        </div>
                                                                    </div>
    
                                                                </Col>
                                                                <Col lg={2} className="" xs={3}>
    
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div>{item.client_name}</div>
                                                                        </div>
                                                                    </div>
    
                                                                </Col>
    
    
                                                                <Col lg={2} className="" xs={3} >
                                                                    <div className="table-data-user">
                                                                        <div className="lead-data">
                                                                            <div className="text-overflow">{item.follow_up_date_time}</div>
    
                                                                        </div>
                                                                    </div>
    
                                                                </Col>
                                                                <Col lg={1} className="" xs={3}>
                                                                <div className="table-data-user">
                                                                    <div className="lead-data">
                                                                       
                                                                    <a onClick={() => this.props.GotoLeadEntry(item.id)} className="icon-padding btn btn-primary lead-details-btn">Details</a>

                                                                      
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                              
                                                                {/* <Col xs={2} className="show-mob">
                                                                    <FontAwesomeIcon icon={faEye} className="dashbord-view-more-icon" onClick={() => this.props.ShowSalesInsideModal(item)} />
                                                                </Col> */}
    
                                                            </Row>
                                                        </div>
                                                ) : null}
    
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                    </div>
                </Col>
            </Row>

        )
    }
}