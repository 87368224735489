import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import Form from './form'
import { Container, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { LoginUser, ForgotPasswordUser } from '../../models/users'
import AppUtils from "../../apputils";
import LoginAlert from '../LoginAlert'
import SinglePageLayout from "../../layouts/singlepagelayout";


class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            txtUserEmail: '',
            txtUserPassword: '',
            invalidEmail: '',
            invalidPass: '',
            gotoHome: false,
            txtforgotEmail: '',
            invalidFgEmail: '',
            loginUser: false,
            forgotPassword: false,
            showLoginAlert: false,
            currentProgess: 10,
            type: '',
            messageToDisplay: '',
            screenType: '',
            showForgotPassword: false,
            currentProfile:'',
            successLogin:false
        }
        this.logindata = {}
        this.forgotdata = {}
        this.currentProfile=''
    }
    componentDidMount = () => {
        // this.ManageProgress()
    }
    HandleUserInput = (e) => {
        let val = e.target.value;
        let name = e.target.name;
       
        if (name === "txtUserEmail") {
            if (val !== '') {

                let apos = val.indexOf("@");
                let dotpos = val.lastIndexOf(".");
                let lastpos = val.length - 1;
                if (val.includes('@')) {
                    if (apos < 1 || dotpos - apos < 2 || lastpos - dotpos > 7 || lastpos - dotpos < 2) {
                        this.setState({
                            invalidEmail: true,
                            [name]: val
                        })
                        return false;
                    }
                    else {
                        this.setState({
                            invalidEmail: false,
                            [name]: val
                        })
                    }
                }
                else {
                    this.setState({
                        invalidEmail: false,
                        [name]: val
                    })
                }
            }
            else {
                this.setState({
                    invalidEmail: true,
                    [name]: ''
                })
            }
        }
        if (name === "txtforgotEmail") {
            if (val !== '') {

                let apos = val.indexOf("@");
                let dotpos = val.lastIndexOf(".");
                let lastpos = val.length - 1;
                if (apos < 1 || dotpos - apos < 2 || lastpos - dotpos > 7 || lastpos - dotpos < 2) {
                    this.setState({
                        invalidFgEmail: true,
                        [name]: val
                    })
                    return false;
                } else {
                    this.setState({
                        invalidFgEmail: false,
                        [name]: val
                    })
                }
            }
            else {
                this.setState({
                    invalidFgEmail: true,
                    [name]: ''
                })
            }
        }
        if (name === "txtUserPassword") {
            this.setState({

                [name]: val,
                invalidPass: false
            })
        }
    }
    HandleSubmit = (e, type) => {
        e.preventDefault()
        if (type === "login") {
            if (this.state.txtUserEmail === '') {
                this.setState({
                    invalidEmail: true
                })
            }
            if (this.state.txtUserPassword === '') {
                this.setState({
                    invalidPass: true
                })
            }

            if (this.state.txtUserEmail !== '' && this.state.txtUserPassword !== '' && this.state.invalidEmail === false && this.state.invalidPass === false) {


                this.logindata['txtLoginEmail'] = this.state.txtUserEmail;
                this.logindata['txtLoginPassword'] = this.state.txtUserPassword;
                this.setState({ loginUser: true });
            }
            else {
                alert("Check your credentials")
            }
        }
        if (type === "forgot-pass") {
            if (this.state.txtforgotEmail === '') {
                this.setState({
                    invalidFgEmail: true
                })
            }
            if (this.state.txtforgotEmail !== '' && this.state.invalidFgEmail === false) {

                this.forgotdata['txtUserForgetEmail'] = this.state.txtforgotEmail;
                this.setState({ forgotPassword: true, loginUser: false });
            }
            else {
                // alert("error")
            }
        }
    }
    HandleLoginUserSuccessResponse = (res) => {
        // alert(JSON.stringify(res.data))
        if (res !== false) {

            let d = new Date();
            d.setMinutes(d.getMinutes() + 240);
            if (res.data.userloginresponse.rows.length > 0) {
                // alert(sessionStorage.setItem('logintoken', response.loginresp.token);)
                if (this.state.chkRemember) {
                    if (res.data.userloginresponse.rows[0].user_email == 'finelogics.prabhash@gmail.com')
                        d.setMinutes(d.getMinutes() + 24000);
                        this.currentProfile= res.data.userloginresponse.rows[0].user_profile[0].profile_name
                        this.setState({currentProfile:res.data.userloginresponse.rows[0].user_profile[0].profile_name})
                    localStorage.setItem('current_user_id', res.data.userloginresponse.rows[0]._id);
                    localStorage.setItem('current_user_name', res.data.userloginresponse.rows[0].user_name);
                    localStorage.setItem('current_user_email', res.data.userloginresponse.rows[0].user_email);
                    localStorage.setItem('current_user_mobile', res.data.userloginresponse.rows[0].user_mobile);
                    localStorage.setItem('current_user_profile', res.data.userloginresponse.rows[0].user_profile[0].profile_name);
                    localStorage.setItem('current_user_allowed_routes', res.data.userloginresponse.rows[0].user_profile[0].allowed_routes);
                    localStorage.setItem('current_user_allowed_lead_stages', res.data.userloginresponse.rows[0].user_profile[0].allowed_lead_stages);
                    localStorage.setItem('current_user_allowed_booking_stages', res.data.userloginresponse.rows[0].user_profile[0].allowed_booking_stages);
                    localStorage.setItem('current_user_full_name', res.data.userloginresponse.rows[0].user_full_name);
                    localStorage.setItem('current_user_login_token', res.data.userloginresponse.token);
                    localStorage.setItem('login_token_expire_time', d);
                    sessionStorage.setItem('current_user_id', res.data.userloginresponse.rows[0]._id);
                    sessionStorage.setItem('current_user_name', res.data.userloginresponse.rows[0].user_name);
                    sessionStorage.setItem('current_user_email', res.data.userloginresponse.rows[0].user_email);
                    sessionStorage.setItem('current_user_mobile', res.data.userloginresponse.rows[0].user_mobile);
                    sessionStorage.setItem('current_user_profile', res.data.userloginresponse.rows[0].user_profile[0].profile_name);
                    sessionStorage.setItem('current_user_allowed_routes', res.data.userloginresponse.rows[0].user_profile[0].allowed_routes);
                    sessionStorage.setItem('current_user_allowed_lead_stages', res.data.userloginresponse.rows[0].user_profile[0].allowed_lead_stages);
                    sessionStorage.setItem('current_user_allowed_booking_stages', res.data.userloginresponse.rows[0].user_profile[0].allowed_booking_stages);
                    sessionStorage.setItem('current_user_full_name', res.data.userloginresponse.rows[0].user_full_name);
                    sessionStorage.setItem('current_user_login_token', res.data.userloginresponse.token);
                    sessionStorage.setItem('login_token_expire_time', d);
                }
                else {
                    if (res.data.userloginresponse.rows[0].user_email == 'finelogics.prabhash@gmail.com')
                        d.setMinutes(d.getMinutes() + 24000);
                        this.currentProfile= res.data.userloginresponse.rows[0].user_profile[0].profile_name
                        this.setState({currentProfile:res.data.userloginresponse.rows[0].user_profile[0].profile_name})
                    sessionStorage.setItem('current_user_id', res.data.userloginresponse.rows[0]._id);
                    sessionStorage.setItem('current_user_name', res.data.userloginresponse.rows[0].user_name);
                    sessionStorage.setItem('current_user_email', res.data.userloginresponse.rows[0].user_email);
                    sessionStorage.setItem('current_user_mobile', res.data.userloginresponse.rows[0].user_mobile);
                    sessionStorage.setItem('current_user_profile', res.data.userloginresponse.rows[0].user_profile[0].profile_name);
                    sessionStorage.setItem('current_user_allowed_routes', res.data.userloginresponse.rows[0].user_profile[0].allowed_routes);
                    sessionStorage.setItem('current_user_allowed_lead_stages', res.data.userloginresponse.rows[0].user_profile[0].allowed_lead_stages);
                    sessionStorage.setItem('current_user_allowed_booking_stages', res.data.userloginresponse.rows[0].user_profile[0].allowed_booking_stages);
                    sessionStorage.setItem('current_user_full_name', res.data.userloginresponse.rows[0].user_full_name);
                    sessionStorage.setItem('current_user_login_token', res.data.userloginresponse.token);
                    sessionStorage.setItem('login_token_expire_time', d);
                }
                this.props.PnkStore.SetData('allowed_routes', res.data.userloginresponse.rows[0].user_profile[0].allowed_routes);
            }

            document.getElementById("frmLogin").reset();

            // document.getElementById('spnSuccessMessageTitle').innerText = "Login Response - ";
            // document.getElementById('spnSuccessMessage').innerText = "Successfully Logged In";
            let that = this;
            that.setState({
                showLoginAlert: true,
                type: 'success',
                messageToDisplay: 'Login Succesful..Redirecting to the CRM',
                screenType: "login"
            })
            that.ManageProgress()

        }
        else {
            this.setState({
                showLoginAlert: true,
                type: 'danger',
                messageToDisplay: 'Error In Login',
                screenType: "login"
            })
            this.ManageProgress()
        }
        this.props.PnkStore.SetData('leadlist', '');
        this.props.PnkStore.SetData('bookinglist', '');
        this.setState({ loginUser: false });
    }
    HandleForgotUserSuccessResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.sendforgetmail) {
                if (res.data.sendforgetmail.value === "SUCCESS") {
                    this.setState({
                        showLoginAlert: true,
                        type: 'success',
                        messageToDisplay: 'Forget Password Mail Sent Successfully',
                        forgotPassword: false,
                        screenType: "forgot"
                    })
                    this.ManageProgress()
                }
            }
        }
        else {
            this.setState({
                showLoginAlert: true,
                type: 'danger',
                messageToDisplay: 'Error in Forgot Password',
                forgotPassword: false,
                screenType: "forgot"
            })
            this.ManageProgress()
        }
    }
    IncreaseProgress = () => {
        this.setState({ currentProgess: this.state.currentProgess + 1 });
        this.ManageProgress()
    }
    ManageProgress = (type, screenType) => {
       
        if (this.state.currentProgess < 100) {
            setTimeout(
                function () {
                    this.IncreaseProgress()
                }
                    .bind(this),
                20
            );

        }
        if (this.state.currentProgess === 100 && this.state.type === "success" && this.state.screenType === "login") {

            AppUtils.HandlePageClick(this.props.componentprops, 'dashboard');
        }
        if (this.state.currentProgess === 100 && this.state.type === "success" && this.state.screenType === "forgot") {
            this.setState({
                txtforgotEmail: '',
                showForgotPassword: false,
                showLoginAlert: false

            },
                console.log("Show Login"))

        }
        // this.ManageProgress()
    }
    ShowFgPass = () => {
        this.setState({ showForgotPassword: true })
    }
    HideFgPass = () => {
        this.setState({ showForgotPassword: false })
    }
    render() {
        
      
            return (
                <Container fluid>
                    <Row>
                        {this.state.showLoginAlert === true ?
                            <LoginAlert currentProgess={this.state.currentProgess} mystate={this.state} /> : null}
                    </Row>
                    <Row>

                        <Form mystate={this.state} {...this.props} HandleUserInput={this.HandleUserInput}
                            SaveLeadData={this.SaveLeadData} editDataObj={this.editDataObj} HandleSubmit={this.HandleSubmit}
                            ShowFgPass={this.ShowFgPass} HideFgPass={this.HideFgPass} />

                    </Row>
                    {this.state.loginUser === true ? LoginUser(this.props.PnkStore, this.logindata, this.HandleLoginUserSuccessResponse) : ''}
                    {this.state.forgotPassword === true ? ForgotPasswordUser(this.props.PnkStore, this.forgotdata, this.HandleForgotUserSuccessResponse) : ''}
                </Container >
            )
        
    }
}
export const LoginPage = PnkConnect(Login, "");