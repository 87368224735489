import React, { Component } from 'react';
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import $ from 'jquery';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Require Editor JS files.
// import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// Require Font Awesome.
// import 'font-awesome/css/font-awesome.css';

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 106,
        width: '100%',
    },
    menuItem: {
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: '14px',
        color: '#999'
    },
    select: {
        // fontSize: '0.9em'
        fontSize: '14px'
    }

});

class MultiEditComponent extends Component {
    constructor(props) {
        super(props);
    }


    compopenentDidMount = () => {
        document.getElementsByClassName[0]('fr-wrapper').removeChild(
            document.getElementsByTagName('div').childNodes[0]
        );
        document.getElementsByClassName[0]('fr-element').style.padding = '26px';
    }
    render() {
        const { classes } = this.props;
        let allowedLeadStages = [];
        if (sessionStorage.getItem('current_user_allowed_lead_stages')) {
            allowedLeadStages = sessionStorage.getItem('current_user_allowed_lead_stages');
            allowedLeadStages = allowedLeadStages.split(',');
        }
        let ddlStageData=[]
        if(allowedLeadStages.length>0)
        {
        allowedLeadStages.map(item=>{
            if(item === 'Pending' || item === 'In Progress'){
                ddlStageData.push(item)
            }
            if(this.props.mystate.ddlProfileName!=='' && this.props.mystate.ddlProfileName==="Telecalling"){
                if(item === 'RNR' || item === 'Dead'){
                    ddlStageData.push(item)
                }  
            }

        })
    }
        return (
            <div className="main-page">
                <div className="row">
                    <div className="col-xs-12 setPosition_LeadItems" id='divLeadPanelFormFixed'>
                        <PnkHtmlForm id="frmLeadEntry" onsubmit={this.props.UpdateLeadData} submitbtn="btnLeadEntry">
                            <fieldset id="frmLeadEntryActivity">
                                <div className="panel panel-default lead_entry_panel" id="divLeadAssociation">
                                    {/* <div className="panel-heading custom-header-panel">
                                        <h3 className="panel-title mob_heading roboto">Lead Allocation Detail</h3>
                                    </div> */}
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="form-group col-sm-6 col-xs-12">
                                                <FormControl className={classes.formControl}>
                                                    {/* disabled={this.props.mystate.formModeActivity}> */}
                                                    <InputLabel htmlFor="ddlLeadHandledByProfile"
                                                        className="entry_label ddl_label"
                                                        style={{ fontSize: '15px' }}>Handled By *</InputLabel>
                                                    <Select
                                                        pnkvalue={this.props.mystate.ddlLeadHandledByProfile}
                                                        value={this.props.mystate.ddlLeadHandledByProfile}
                                                        onChange={this.props.HandleUserSelectInput}
                                                        inputProps={{
                                                            name: 'ddlLeadHandledByProfile',
                                                            id: 'ddlLeadHandledByProfile'
                                                        }}
                                                        className={classes.select}>
                                                        <MenuItem value="-1" className={classes.menuItem} disabled>
                                                            Select Lead Handled By
                                                        </MenuItem>
                                                        {(JSON.stringify(this.props.profileList) !== '{}' && this.props.profileList) ? this.props.profileList.map(item =>
                                                            <MenuItem value={item._id} key={item.profile_name}
                                                                className={classes.menuItem}>{item.profile_name}</MenuItem>
                                                        ) : ""}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="form-group col-sm-6 col-xs-12">
                                                <FormControl className={classes.formControl}
                                                    disabled={this.props.mystate.formModeActivity}>
                                                    <InputLabel htmlFor="ddlLeadOwner"
                                                        className="entry_label ddl_label"
                                                        style={{ fontSize: '15px' }}>Lead Owner *</InputLabel>
                                                    <Select
                                                        pnkvalue={this.props.mystate.ddlLeadOwner}
                                                        value={this.props.mystate.ddlLeadOwner}
                                                        onChange={this.props.HandleUserInputs}
                                                        inputProps={{
                                                            name: 'ddlLeadOwner',
                                                            id: 'ddlLeadOwner'
                                                        }}
                                                        className={classes.select}>
                                                        <MenuItem value="-1" className={classes.menuItem} disabled>
                                                            Select Lead Owner
                                                        </MenuItem>
                                                        {(this.props.userlistbyprofile.length > 0) ? this.props.userlistbyprofile.map(item =>
                                                            <MenuItem value={item.user_name}
                                                                className={classes.menuItem}>{item.user_name}</MenuItem>
                                                        ) : ""}
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="form-group col-sm-6 col-xs-12">
                                                <FormControl className={classes.formControl}
                                                    disabled={this.props.mystate.formModeActivity}>
                                                    <InputLabel htmlFor="ddlLeadStage"
                                                        className="entry_label ddl_label"
                                                        style={{ fontSize: '15px' }}>Stage *</InputLabel>
                                                    <Select
                                                        pnkvalue={this.props.mystate.ddlLeadStage}
                                                        value={this.props.mystate.ddlLeadStage}
                                                        onChange={this.props.HandleUserInputs}
                                                        inputProps={{
                                                            name: 'ddlLeadStage',
                                                            id: 'ddlLeadStage'
                                                        }}
                                                        className={classes.select}>
                                                        <MenuItem value="-1" className={classes.menuItem} disabled>
                                                            Select Lead Stage </MenuItem>
                                                          
                                                        {ddlStageData !== [] ? ddlStageData.map(item =>

                                                                <MenuItem value={item}
                                                                    className={classes.menuItem}>{item}</MenuItem>
                                                                
                                                       
                                                        )
                                                        
    
                                                         : ""}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button className="btn pnkvalidatesubmit btn-danger crmTextWhite"
                                                    style={{ backgroundColor: '#FF4923' }}
                                                    name="Save" id="btnLeadEntry"
                                                    onClick={this.props.UpdateLeadData}>
                                                    Update Leads
                                                </button>
                                                <button type="button" variant="light" className="le-cancel mr-3 ml-3" onClick={() => this.props.HideModal()}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </PnkHtmlForm>
                    </div>
                </div>
            </div>
        )
    }
}

MultiEditComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles())
export default withStyles(styles)(MultiEditComponent);