import React, { Component } from 'react';
import Header from '../common/header';
// import { Footer } from '../common/footer';
import { SideBar } from '../common/sidebar';
import AppUtils from '../apputils';
import { PnkConnect } from '../pnk-react/pnk-miniredux';

import { Row, Col, Container, Button, Modal } from 'react-bootstrap'

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class SinglePageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            currentModalAction: {},
            backButton: false,
            showAdvanceSearch: false,
            pageTitle: 'Home',
            backButtonRoute: '',
        };
        this.comp = '';
        this.modalClass = "profile-entry-modal";
        this.title = "";
        this.headerClass = "";
        this.comp_size = 'md';
        this.routeArray = ['/user/new', '/users', '/leads'];
        this.prev_url = 'init'
        this.titleClass = "";
        this.icon = "";
        this.data = []
    }

    componentWillReceiveProps = () => {
        if ((this.prev_url.indexOf('/lead/edit') > -1) && (window.location.href.indexOf('/lead/new') > -1) || (this.prev_url.indexOf('/booking/edit') > -1) && (window.location.href.indexOf('/booking/new') > -1)) {
            //AppUtils.HandlePageClick(this.props, 'lead/new')
            window.location.reload();
        }
        this.prev_url = window.location.pathname;
    }

    handleClickOpen = (comp_name, size = 'sm', items = {}, action = {}) => {
        this.comp_size = size;
        this.comp = comp_name;
        this.modalClass = items.modalClass;
        this.headerClass = items.headerClass;
        this.title = items.title;
        this.titleClass = items.titleClass;
        this.icon = items.icon
        this.data = items.data
        this.setState({ open: true, currentModalAction: action });
    };

    handleClose = () => {

        this.setState({ open: false });
    };

    // componentWillReceiveProps = (nextProps) => {
    //     if (nextProps.match.path !== this.props.match.path && document.getElementById('advanceSearchDiv')) {
    //         document.getElementById('advanceSearchDiv').style.display = 'none';
    //     }
    // }

    // shouldComponentUpdate = (nextProps) => {
    //     if(nextProps.match.params.mode && nextProps.match.params.mode !== '') {
    //        alert('lead')
    //     }
    //
    // }

    // componentDidUpdate = () => {

    // }

    LogOut = () => {
       
        sessionStorage.clear();
        localStorage.clear();
        this.setState({ loginStatus: 'logout' })
        AppUtils.HandlePageClick(this.props, '')

    }

    HandleBackButton = (data, route) => {
       
        
        this.setState({
            backButton: data, backButtonRoute: route
        })
    
    }
    HandleTitle = (data) => {
        this.setState({
            pageTitle: data
        })
    }
    ShowAdvanceSearch = (data) => {
        this.setState({
            showAdvanceSearch: data
        })
    }

    render() {
        // alert('in render')
        let access_route = false;
        for (let i = 0; i < this.routeArray.length; i++) {
            if (this.props.match.path === this.routeArray[i]) {
                access_route = true;
            }
        }
        // alert(''+access_route)
        // let isLoggedIn = '';
        let isLoggedIn = '';
        // alert(sessionStorage.getItem('current_user_login_token'))
        if (sessionStorage.getItem('current_user_name') == null && sessionStorage.getItem('current_user_login_token') == null) {
            if (localStorage.getItem('current_user_name') == null && localStorage.getItem('current_user_login_token') == null) {
                isLoggedIn = false;
                // isLoggedIn = true;
            }
            else {
                if (localStorage.getItem('current_user_name') !== null && localStorage.getItem('current_user_login_token') !== null && localStorage.getItem('current_user_profile') !== null && localStorage.getItem('current_user_allowed_routes') !== null) {
                    isLoggedIn = true;
                    sessionStorage.setItem('current_user_id', localStorage.getItem('current_user_id'));
                    sessionStorage.setItem('current_user_name', localStorage.getItem('current_user_name'));
                    sessionStorage.setItem('current_user_email', localStorage.getItem('current_user_email'));
                    sessionStorage.setItem('current_user_mobile', localStorage.getItem('current_user_mobile'));

                    sessionStorage.setItem('current_user_profile', localStorage.getItem('current_user_profile'));
                    sessionStorage.setItem('current_user_allowed_routes', localStorage.getItem('current_user_allowed_routes'));
                    sessionStorage.setItem('current_user_allowed_lead_stages', localStorage.getItem('current_user_allowed_lead_stages'));
                    sessionStorage.setItem('current_user_allowed_booking_stages', localStorage.getItem('current_user_allowed_booking_stages'));

                    sessionStorage.setItem('current_user_full_name', localStorage.getItem('current_user_full_name'));
                    sessionStorage.setItem('current_user_login_token', localStorage.getItem('current_user_login_token'));
                    sessionStorage.setItem('login_token_expire_time', 300000);
                }
                else {
                    isLoggedIn = false;
                }
            }
        }
        else {
            if (sessionStorage.getItem('current_user_name') !== null && sessionStorage.getItem('current_user_login_token') !== null && sessionStorage.getItem('current_user_profile') !== null && sessionStorage.getItem('current_user_allowed_routes') !== null)
                isLoggedIn = true;
            else
                isLoggedIn = false;
        }


        // // var Component_name = this.props.componentprops.data;
        //  var Component_name = components[this.props.componentprops.data];
        //  alert('###########'+Component_name);
        //  Component_name = Component_name.replace('"','');
        //  alert('@@@'+(this.props.componentprops.children));

        return (
            <Container fluid className="cbp-spmenu-push">
                {isLoggedIn ?
                    <Row className="header-row">
                        <Col className="sidebar-col">
                            <SideBar {...this.props}
                                OpenModal={this.handleClickOpen}
                                HandleCloseModal={this.handleClose} />
                        </Col>


                        <Col lg={12} xs={12} className="crm-body-col">
                            {!window.location.href.includes('leads') ?
                                <Header showBackButton={this.state.backButton} backButtonRoute={this.state.backButtonRoute} showSearch={this.state.showAdvanceSearch} title={this.state.pageTitle} {...this.props} LogOut={this.LogOut} />
                                : ''}
                            <this.props.children HandleBackButton={this.HandleBackButton} HandleTitle={this.HandleTitle}
                                ShowAdvanceSearch={this.ShowAdvanceSearch} HandleCloseModal={this.handleClose} OpenModal={this.handleClickOpen} {...this.props} />

                            {/*{access_route ?*/}
                            {/*<this.props.children OpenModal={this.handleClickOpen} {...this.props} />*/}
                            {/*: 'You are Not Authorized to access this route.'*/}
                            {/*}*/}
                        </Col>
                        {/* <Footer /> */}
                        <div>
                            {/* <Dialog fullWidth
                                maxWidth={this.comp_size}
                                open={this.state.open}
                                onClose={this.handleClose}
                                aria-labelledby="form-dialog-title"
                            >
                            
                                <DialogContent>
                                    <this.comp currentAction={this.state.currentModalAction} {...this.props} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary">
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog> */}
                            <Modal
                                size={this.comp_size}
                                show={this.state.open}
                                onClose={this.handleClose}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className={this.modalClass}
                                backdrop="static"
                            >
                                {this.headerClass !== "" ?
                                    <Modal.Header className={this.headerClass}>
                                        {/* {this.icon !== "" ?
                                            <FontAwesomeIcon icon={faTimes} className="modal-header-close" />
                                            :
                                            null} */}
                                        {this.title !== "" ?
                                            <Modal.Title id="contained-modal-title-vcenter">

                                                <h3 className={this.titleClass}>{this.title}</h3>

                                            </Modal.Title> : null}
                                    </Modal.Header> : null}
                                <Modal.Body>
                                    <this.comp currentAction={this.state.currentModalAction} {...this.props} />
                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button onClick={this.handleClose}>Close</Button>
                                </Modal.Footer> */}
                            </Modal>
                        </div>
                    </Row>
                    :
                    AppUtils.HandlePageClick(this.props, 'login')
                }
            </Container>
        );
    }
}
const dialogPaper = {
    // minHeight: '80vh',
    // maxHeight: '80vh',
    maxWidth: 'lg'
}
