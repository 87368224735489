import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import { Container } from 'react-bootstrap'
import Table from './leadsource'
import Options from './options'
import { GetLeadSource, ChangeLeadSourceStatus, DeleteLeadSource, SearchLeadSourceList } from '../../models/users'
import { SourceEntryPage } from '../LeadSourceEntry'
import Footer from './mob-option'
import $ from 'jquery';
class leadSource extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            getLeadSource: true,
            leadSourceList: [],
            changeStatus: false,
            requiredLeadSourceStatus: '',
            currentSeletedLeadSourceId: '',
            deleteData: false,
            txtUserSearch: ''

        }
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('Lead Source');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.editDataObj = {}
        this.data = {}
    }
    HandleGetLeadSourceSuccessResponse = (res) => {
        let that = this
       
        if (res !== false) {
            if (res.data && res.data.leadsourcelist && res.data.leadsourcelist.rows && res.data.leadsourcelist.rows.length > 0) {
                that.setState({
                    leadSourceList: res.data.leadsourcelist
                })
            }
        }
        this.setState({
            getLeadSource: false
        })
    }
    HandleUserSearch = (e) => {
        let val = e.target.value;
        let name = e.target.name;
       
        this.setState({
            [name]: val,

        })
        if (val.length >= 3) {
            this.setState({

                getSearch: true
            })
        }
        else {
            this.setState({

                getSearch: false,
            })
        }
        if (val === '') {
            this.setState({

                getSearch: false,
                getLeadSource: true
            })
        }

    }
    HandleUserInputs = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    SortData = (event, key) => {
       
        const { sorting } = this.state;
        var column = event.target.getAttribute('column');

        if (sorting.column === column) {
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            sorting.order = 'asc';
        }

        sorting.column = column;
        this.setState({ sorting: sorting });
        // this.state.table_data.sort(compareValues(sorting.column, sorting.order));
        // this.state.TableDataList.sort(function (index, order) {
        this.props.PnkStore.GetData('leadlist').rows.sort(function (index, order) {
            return function (a, b) {
                var valueA, valueB;
                // valueA = a[key.item]; // Where 1 is your index, from your example
                valueA = a[key]; // Where 1 is your index, from your example
                valueB = b[key];
                // valueB = b[key.item];
                // valueA = a.data[index]['fieldValue']; // Where 1 is your index, from your example
                // valueB = b.data[index]['fieldValue'];
                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));
    }
    ShowProfileModal = () => {
        this.props.componentprops.OpenModal(SourceEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Add Lead Source' }, { action: 'profile', name: 'Profile', close: this.CloseModal })
    }
    CloseModal = () => {
        this.props.componentprops.HandleCloseModal();
    }
    HandleUserAction = (e, id, status) => {
        let name = e.target.name
        let val = e.target.value
        if (name === 'ChangeLeadSourceStatus') {
            status === 0 ? status = 1 : status = 0
            this.setState({
                changeStatus: true, requiredLeadSourceStatus: status, currentSeletedLeadSourceId: id
            })
        }
        else if (val === 'Delete') {
            if (window.confirm("Are you sure?"))
                this.setState({ deleteData: true, currentSeletedLeadSourceId: id, getLeadSource: false });
        }
        else if (val === 'Detail') {
            // this.setState({
            //     getUserList: true
            // })
            let action = { id: id, action: val, status: status };
            // this.props.componentprops.OpenModal(UserEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'User Details' }, { action: action, name: 'User Details', close: this.CloseModal })
            this.props.componentprops.OpenModal(SourceEntryPage, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: 'Lead Source Details' }, { action: action, name: 'Lead Source', close: this.CloseModal })
        }

    }
    HandleSuccessChangeLeadSourceStatusResponse = (res) => {
       
        if (res !== false) {
            if (res.data && res.data.leadsourceeditresponse.value === "SUCCESS") {
              
                this.setState({

                    changeStatus: false, requiredLeadSourceStatus: '', currentSeletedLeadSourceId: '', getLeadSource: true

                })
            }

        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Source Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
    }
    HandleSuccessDeleteLeadEntryResponse = (res) => {
       
        if (res !== false) {
            this.setState({
                deleteData: false,
                getLeadSource: true
            })
        }
        else {
            let error = 'Something went wrong';
            let errorData = JSON.stringify(this.props.PnkStore.GetData('error'));

            if (errorData.prettyMessage !== '')
                error = errorData.prettyMessage
            else if (errorData.errorMessage !== '')
                error = errorData.errorMessage
            document.getElementById('spnWarningMessageTitle').innerText = "Lead Source Entry Message - ";
            document.getElementById('spnWarningMessage').innerText = error;
            $('#crmWarningAlert').slideDown().slideUp(5000, function () {
            })
        }
    }
    HandleSuccessSearchSourceListResponse = (res) => {
      
        var that = this
        if (res !== false) {
            this.setState({
                getSearch: false
            })
            if (res.data && res.data.leadsourcelist && res.data.leadsourcelist.rows && res.data.leadsourcelist.rows.length > 0) {
                that.setState({
                    leadSourceList: res.data.leadsourcelist
                })
            }
        }
        else {
            this.setState({
                getSearch: false,
                getLeadSource: true

            })
        }

    }

    render() {
    
        let serverPath = this.props.PnkStore.GetData('api').baseurl + "/images/uploads/crm/lead_source/"
        return (
            <Container fluid className="data-table-lead">
                <Options HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInputs} SortData={this.SortData}
                    {...this.props}
                    ShowProfileModal={this.ShowProfileModal} />
                <Table mystate={this.state} {...this.props}
                    LeadSourceData={this.state.leadSourceList}
                    serverPath={serverPath}
                    HandleUserAction={this.HandleUserAction}
                />
                <Footer HandleUserSearch={this.HandleUserSearch} mystate={this.state}
                    HandleUserInput={this.HandleUserInputs} SortData={this.SortData}
                    {...this.props}
                    ShowProfileModal={this.ShowProfileModal} />
                {((this.state.getLeadSource === true) ? GetLeadSource(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")}
                {((this.state.changeStatus === true) ? ChangeLeadSourceStatus(this.props.PnkStore, {}, this.state.requiredLeadSourceStatus, this.state.currentSeletedLeadSourceId, this.HandleSuccessChangeLeadSourceStatusResponse) : '')}
                {this.state.deleteData === true ? DeleteLeadSource(this.props.PnkStore, this.state.currentSeletedLeadSourceId, this.HandleSuccessDeleteLeadEntryResponse) : ''}
                {this.state.getSearch === true ? SearchLeadSourceList(this.props.PnkStore, 'profile_name', this.state.txtUserSearch, this.HandleSuccessSearchSourceListResponse) : ''}
            </Container>

        )
    }
}
export const LeadSourcePage = PnkConnect(leadSource, "leadlist");