import React from 'react'
import { PnkConnect } from "../../pnk-react/pnk-miniredux/";
import Form from './reset-form'
import { Container, Row } from 'react-bootstrap'
import { ResetPassword, CheckResetLink } from '../../models/users'
import AppUtils from "../../apputils";
class Reset extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            txtResetPass: '',
            txtConfirmPass: '',
            invalidPass: '',
            invalidCPass: '',
            passwordMismatch: '',
            resetPassword: false,
            checkLink: false,
            showResetForm: 'none'

        }
        this.resetdata = {}
    }
    HandleUserInput = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        if (name === "txtResetPass") {
            this.setState({
                [name]: val,
                invalidPass: false
            })
        }
        if (name === "txtConfirmPass") {
            this.setState({
                [name]: val,
                invalidCPass: false
            })
            if (this.state.txtResetPass === val) {
                this.setState({
                    passwordMismatch: false
                }, () => { console.log("hai") })
            }
        }
    }
    HandleSubmit = (e) => {
        e.preventDefault()

        if (this.state.txtResetPass === '') {
            this.setState({
                invalidPass: true
            })
        }
        if (this.state.txtConfirmPass === '') {
            this.setState({
                invalidCPass: true
            })
        }
        if (this.state.txtConfirmPass !== this.state.txtResetPass) {
            this.setState({
                passwordMismatch: true
            })
        }
        if (this.state.txtConfirmPass === this.state.txtResetPass) {
            this.setState({
                passwordMismatch: false
            }, () => { console.log("hai") })
        }

        if (this.state.txtResetPass !== '' && this.state.txtConfirmPass !== '' && this.state.invalidPass === false && this.state.invalidCPass === false, this.state.passwordMismatch === false) {
            this.resetdata['txtResetPassword'] = this.state.txtResetPass;
            this.resetdata['txtResetLink'] = this.state.txtResetLink;

            this.setState({ resetPassword: true });
        }
        else {
            alert("Check your credentials")
        }

    }
    componentDidMount = () => {
        let link = (window.location.href).substring((window.location.href).lastIndexOf('/') + 1);
        this.setState({ txtResetLink: link, checkLink: true });
    }
    HandleCheckResetLinkSuccessResponse = (res) => {
        // alert(JSON.stringify(res))
        if (res.data.verifylinkresponse.FailureReason) {
            alert(res.data.verifylinkresponse.FailureReason.value)

        }
        else if (res !== false) {
            this.setState({ showResetForm: 'block' });
        }
    }
    HandleResetPasswordSuccessResponse = (res) => {
        alert(JSON.stringify(res.data))
        this.setState({
            resetPassword: false

        })
        AppUtils.HandlePageClick(this.props.componentprops, '/');
    }
    render() {
        return (
            <Container fluid>
                <Row>
                    {/* <MobHeader mystate={this.state} {...this.props} /> */}
                </Row>
                <Row>
                    <Form mystate={this.state} {...this.props} HandleUserInput={this.HandleUserInput}
                        SaveLeadData={this.SaveLeadData} editDataObj={this.editDataObj} HandleSubmit={this.HandleSubmit} />
                    {((this.state.resetPassword === true) ? ResetPassword(this.props.PnkStore, 'mail', this.resetdata, this.HandleResetPasswordSuccessResponse) : "")}
                    {((this.state.checkLink === true) ? CheckResetLink(this.props.PnkStore, this.state.txtResetLink, this.HandleCheckResetLinkSuccessResponse) : "")}
                </Row>
            </Container >
        )
    }
}
export const ResetPage = PnkConnect(Reset, "");