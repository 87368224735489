import React from 'react'
import { Row, Col, Button, Form, Tooltip, Image, Overlay } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/images/Final_logo_NEO_ROOTS.png'
import { PnkHtmlForm } from '../../pnk-react/pnk-controls/pnk-html-form';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom'

export default class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showForgotPassword: false,
            showLogout: false,
        }
    }

    render() {
        return (
            <div className="login-main-div">
                <Row style={{ height: '100%' }}>
                    <Col lg={6} style={{ height: '100%' }} xs={12}>
                        <div className="log-left-img"></div>
                    </Col>

                    <Col lg={6} className="log-form-col">
                        <div style={{ height: '100%' }} className="log-padding-adj">
                            <div className="login-container">

                                {this.props.mystate.showForgotPassword === true ?
                                    <div className="div-logo-main">
                                        <FontAwesomeIcon icon={faArrowLeft} className="logo-login-action" onClick={() => this.props.HideFgPass()} />

                                        <Image src={Logo} style={{ width: '22%' }} className="hide-mob" />
                                    </div> : null}
                                {this.props.mystate.showForgotPassword === false ?
                                    <div className="div-logo-main1">
                                        <Image src={Logo} style={{ width: '22%' }} />
                                    </div> : null}


                            </div>

                            <div className="form-div">
                                {this.props.mystate.showForgotPassword === false ?
                                    <div className="login-container">
                                        <h3 className="login-heading">Login</h3>
                                        <Form onSubmit={(e) => this.props.HandleSubmit(e, "login")} id="frmLogin">
                                            <div className="form-ctrl-div">

                                                <TextField
                                                    id="txtUserEmail"
                                                    label="Email Address"
                                                    type="text"
                                                    name="txtUserEmail"
                                                    value={this.props.mystate.txtUserEmail}
                                                    variant="outlined"
                                                    className="login-form-controls"
                                                    onChange={this.props.HandleUserInput}
                                                />
                                                {this.props.mystate.invalidEmail === true ? <div className="error-feedback">Invalid Email Address</div> : null}
                                            </div>
                                            <div className="form-ctrl-div">
                                                <TextField
                                                    id="txtUserPassword"
                                                    name="txtUserPassword"
                                                    value={this.props.mystate.txtUserPassword}
                                                    label="Password"
                                                    type="password"
                                                    variant="outlined"
                                                    className="login-form-controls"
                                                    onChange={this.props.HandleUserInput}

                                                />
                                                {this.props.mystate.invalidPass === true ? <div className="error-feedback">Invalid Password</div> : null}
                                            </div>
                                            <div className="form-ctrl-div">
                                                <Link className="forgot-pass-link" onClick={() => this.props.ShowFgPass()}>Forgot your password?</Link>
                                            </div>
                                            <div className="log-btn-div">
                                                <Button type="submit" className="login-btn">Login</Button>
                                            </div>
                                        </Form>
                                    </div>
                                    :
                                    <div className="login-container">
                                        <h3 className="login-heading">Forgot Password?</h3>
                                        <div className="form-ctrl-div">
                                            <h3 className="forgot-pass-desc"> Please enter your registered email address. We will sent you the link to reset your password.</h3>

                                        </div>
                                        <Form onSubmit={(e) => this.props.HandleSubmit(e, "forgot-pass")}>
                                            <div className="form-ctrl-div">
                                                <TextField
                                                    id="txtforgotEmail"
                                                    name="txtforgotEmail"
                                                    value={this.props.mystate.txtforgotEmail}
                                                    label="Enter Email Address"
                                                    type="text"
                                                    onChange={this.props.HandleUserInput}
                                                    variant="outlined"
                                                    className="login-form-controls"
                                                />
                                                {this.props.mystate.invalidFgEmail === true ? <div className="error-feedback">Invalid Email</div> : null}

                                            </div>
                                            {/* <div className="form-ctrl-div">
                                            <Link className="forgot-pass-link">Forgot your password?</Link>
                                        </div> */}
                                            <div className="log-btn-div">
                                                <Button className="login-btn" type="submit">Send Reset Link</Button>
                                            </div>
                                        </Form>
                                    </div>}
                            </div>
                        </div>
                    </Col>
                </Row>

            </div >

        )
    }
}