import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { PnkConnect } from "../../../pnk-react/pnk-miniredux/";
import Report from './report'
import Options from './options';
import { GetUserProfileList, GetCityList, GetUserList, GetLeadData, GetLeadSource } from '../../../models/users';
import { AdminSalesReport,CallBackList } from '../../../models/admin-dashboard'
import ViewData from './view-data-modal'
import MobCustomDatePicker from './mob-custom-picker'
import CallbackReport from './callback'
import PatchReport from './patch-report'
import AppUtils from '../../../apputils'
import DailyReport from './dialy-report'
import {AllCallList} from '../../../models/services'
import MeetingTracker from './meeting-tracker'
import UnderConstructionComp from './under-construction'
class reportPage extends React.Component {
    constructor(props) {
        super(props)
        this.props.componentprops.HandleBackButton(false);
        this.props.componentprops.HandleTitle('');
        this.props.componentprops.ShowAdvanceSearch(true);
        this.state = {
            currentSalesReportType: 'Today',
            txtCustomStartDate: new Date().setDate(new Date().getDate() - 29),
            txtCustomEndDate: new Date(),
            currentViewType:(this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== "")?this.props.componentprops.match.params.type : 'default',
            startDate: '',
            endDate: '',
            currentDisplayType: '',
            todayDate: '',
            yesterdayDate: '',
            weekDate: '',
            monthDate: '',
            getUser: false,
            getReportData: false,
            reportData: [],
            ddlPreferredCity: '-1',
            getCity: true,
            showCustomDateModal: false,
            getCallbackData:false,
            callbackReportData:[],
            hcPmScheduleType:'hc',
            getAllCalls:false,
            getCustomCallList:false,
            getMeetingdata:false,
            getCustomMeetingdata:false,
            getCustomCallbackData:false,
            meetingWeekStartDate:'',
            meetingWeekEndDate:'',
            getMeetingData:false,
            txtCustomMeetingStartDate:new Date(),
            txtCustomMeetingEndDate: new Date().setDate(new Date().getDate() + 7),
        

        }
        this.salesReportType = [
            'Today',
            'Yesterday',
            'Last 7 Days',
            // 'Last 30 Days',
            'Custom'
        ];
        this.callBackReportType=[
            'Today',
            'Coming Days',
            'Custom'
        ]
        this.currentCity = '';
        // Lead Generate
        this.todayLeadGeneratedData = []
        this.yesterdayLeadGeneratedData = []
        this.weekLeadGeneratedData = []
        this.monthLeadGeneratedData = []

        // Leads Pending
        this.todayPendingData = []
        this.yesterdayPendingData = []
        this.weekPendingData = []
        this.monthPendingData = []

        // Dead Lead
        this.todayDeadData = []
        this.yesterdayDeadData = []
        this.weekDeadData = []
        this.monthDeadData = []

        // Patch
        this.todayPatchSalesReport = []
        this.yesterdayPatchSalesReport = []
        this.weekPatchSalesReport = []
        this.monthPatchSalesReport = []

        this.customData = []

        this.todayPatchData = []
        this.yesterdayPatchData = []
        this.weekPatchData = []
        this.monthPatchData = []
        this.hcPmScheduleType='hc'

        // Follow Up
        this.todayFollowupReport=[]
        this.yesterdayFollowupReport=[]
        this.weekFollowupReport=[]
        this.monthFollowupReport=[]

        this.todayHCBookedReport = []
        this.yesterdayHCBookedReport = []
        this.weekHCBookedReport = []
        this.monthHCBookedReport = []
        
         // PM Booked
         this.todayPMBookedReport = []
         this.yesterdayPMBookedReport = []
         this.weekPMBookedReport = []
         this.monthPMBookedReport = []
          // Updated Time
        this.todayUpdateTimeData = []
        this.yesterdayUpdateTimeData = []
        this.weekUpdateTimeData = []
        this.monthUpdateTimeData = []
         // Callback
         this.todayCallbackData = []
         this.yesterdayCallbackData = []
         this.weekCallbackData = []
         this.monthCallbackData = []

           // Exotel called
        this.todayExotelCalled=[]
        this.yesterdayExotelCalled=[]
        this.weekExotelCalled=[]
        this.monthExotelCalled=[]
        
         // VC
         this.todayVCCompletedReport = []
         this.yesterdayVCCompletedReport = []
         this.weekVCCompletedReport = []


        //  For Meeting Report..Fetch Admin Data
        // Caller's could know whom to patch (Sales)

        // HC Booked For Meeting Tracker
        this.todayMeetingHCBookedReport=[]
        this.yesterdayMeetingHCBookedReport=[]
        this.weekMeetingHCBookedReport=[]

        // PM Booked For Meeting Tracker

        this.todayMeetingPMBookedReport=[]
        this.yesterdayMeetingPMBookedReport=[]
        this.weekMeetingPMBookedReport=[]

        // VC Completed for Meeting Tracker
        this.todaykMeetingVCCompletedReport=[]
        this.yesterdaykMeetingVCCompletedReport=[]
        this.weekkMeetingVCCompletedReport=[]

         // Under Construction
         this.todayUnderConstruction=[]
         this.yesterdayUnderConstruction=[]
         this.weekUnderConstruction=[]

        this.customCallList=[]
        this.adminReportAll=[]

    }
    componentDidMount = () => {
        var today = new Date();

        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let endDate = yyyy + '-' + mm + '-' + dd;

        let beforeThirtyDays = new Date(new Date().setDate(new Date().getDate() - 6))
        var before_dd = String(beforeThirtyDays.getDate()).padStart(2, '0');
        var before_mm = String(beforeThirtyDays.getMonth() + 1).padStart(2, '0'); //January is 0!
        var before_yyyy = beforeThirtyDays.getFullYear();
        let startDate = before_yyyy + '-' + before_mm + '-' + before_dd;

        let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
        var yester_dd = String(yesterdayDate.getDate()).padStart(2, '0');
        var yester_mm = String(yesterdayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yester_yyyy = yesterdayDate.getFullYear();
        let yesterday = yester_yyyy + '-' + yester_mm + '-' + yester_dd;

        let weekDate = new Date(new Date().setDate(new Date().getDate() - 6))
        var week_dd = String(weekDate.getDate()).padStart(2, '0');
        var week_mm = String(weekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var week_yyyy = weekDate.getFullYear();
        let weekStartDate = week_yyyy + '-' + week_mm + '-' + week_dd;

        let monthDate = new Date(new Date().setDate(new Date().getDate() - 29))
        var month_dd = String(monthDate.getDate()).padStart(2, '0');
        var month_mm = String(monthDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var month_yyyy = monthDate.getFullYear();
        let monthStartDate = month_yyyy + '-' + month_mm + '-' + month_dd;

        let comingWeekDate = new Date(new Date().setDate(new Date().getDate() +7))
        var meeting_week_dd = String(comingWeekDate.getDate()).padStart(2, '0');
        var meeting_week_mm = String(comingWeekDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var meeting_week_yyyy = comingWeekDate.getFullYear();
        let meetingWeekEndDate = meeting_week_yyyy + '-' + meeting_week_mm + '-' + meeting_week_dd;
        this.setState({
            startDate: startDate, endDate: endDate, getReportData: false, currentDisplayType: endDate,
            todayDate: endDate, yesterdayDate: yesterday, weekDate: weekStartDate, monthDate: monthStartDate,
            getAllCalls:false,meetingWeekStartDate:endDate,meetingWeekEndDate:meetingWeekEndDate
        })

        if (JSON.stringify(this.props.PnkStore.GetData('userlist')) === '{}') {
            this.setState({
                getUser: true
            })
        }
        if(this.props.componentprops.match.params.type !== undefined && this.props.componentprops.match.params.type !== ""){
            if(this.props.componentprops.match.params.type==='meeting-tracker'){
                this.setState({
                    getMeetingData:true
                })
            }
            else if(this.props.componentprops.match.params.type==='callback'){
                this.setState({
                    getCallbackData:true
                })    
            }
            else{
                this.setState({
                    getReportData:true,
                    getAllCalls:true
                   
                })
            }
        }
        else{
        this.setState({
            getReportData:true
        })
    }
      
    }
    HandleMeetingType=(type)=>{
        this.setState({
            hcPmScheduleType: type,
            // currentSalesReportType:'Today',
            // reportData:[]

        })
        this.hcPmScheduleType=type;
        this.RenderMeetingReport('Today')
    }

    HandleChangeDate = (type, date) => {
       
        this.setState({
            [type]: date
        });

    };
    HandleUserInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ddlPreferredCity") {
            this.currentCity = e.target.value;
            this.ChangeSalesReportType(this.state.currentSalesReportType);
        }

    }
    ChangeViewReport = (event) => {
        this.setState({ currentViewType: event.target.value, ddlPreferredCity: '-1', currentSalesReportType:'Today' })
        this.currentCity = ''
        if (this.state.currentSalesReportType !== "Custom") {
            this.RenderReport(event.target.value,'Today')
        }
        else if (this.state.currentSalesReportType === "Custom") {
            this.RenderCustomReport(event.target.value)
        }
        if(event.target.name==='currentViewType')
        {
           if(event.target.value==='callback'){
                this.setState({getCallbackData:true,getMeetingdata:false})
            }
            if(event.target.value==='meeting-tracker'){
                this.setState({getCallbackData:false,
                    getMeetingData:true,reportData:[]
                })
            }
            else if(this.adminReportAll.length<0){
                this.setState({
                    getReportData:true
                    });
            }
           

        } 
        if (window.location.href.indexOf("/dashboard/") > -1)
        this.props.componentprops.history.push(event.target.value);
    else
        this.props.componentprops.history.push('dashboard/' + event.target.value);
    }
    ChangeSalesReportType = (type) => {
        this.setState({
            currentSalesReportType: type,
            hcPmScheduleType:'hc'
        })

        if(this.state.currentViewType==='callback')
            this.RenderCallbackReport(this.callbackReportData,type)
        else if(this.state.currentViewType==='meeting-tracker')
        this.RenderMeetingReport(type)
        else
            this.RenderReport(this.state.currentViewType, type)
    
            
        // }
        // else if (type === "Custom") {
        //     this.RenderCustomReport(this.state.currentViewType)
        // }
    }
    ChangeSalesReportTypeMobile = (event) => {
        this.setState({
            currentSalesReportType: event.target.value
        })

        if (event.target.value !== "Custom") {
            this.setState({ showCustomDateModal: false })
            this.RenderReport(this.state.currentViewType, event.target.value)
        }
        else if (event.target.value === "Custom") {
            this.setState({ showCustomDateModal: true })

        }
    }


    ShowSalesInsideModal = (data) => {
        this.props.componentprops.OpenModal(ViewData, 'md', { modalClass: 'profile-entry-modal', headerClass: 'profile-entry-header', titleClass: 'profile-entry-title', title: '', icon: 'close' }, { action: this.state, name: 'Profile', close: this.HideModal, data: data })
    }
    HideModal = () => {
        this.props.componentprops.HandleCloseModal();
    }
    HandleGetMeetingReportResponse=(res)=>{
        // alert(1)
        this.setState({ getMeetingdata: false, isOptionDisabled: false })
        if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
            this.adminReportAll= res.data.adminfirstmonthdata.rows
            res.data.adminfirstmonthdata.rows.forEach((element, i) => {
               
                if (element.lead_department[0].profile_name.includes("Sales")) {

                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                        this.todayMeetingHCBookedReport.push(element)
                    }
                   
                    else if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayMeetingHCBookedReport.push(element);
                    }
                    if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                        console.log("element",element)
                        this.weekMeetingHCBookedReport.push(element)
                    }
                      // PM todayPMBookedReport
                      if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate)) {
                        this.todayMeetingPMBookedReport.push(element)
                    }
                    else if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayMeetingPMBookedReport.push(element);
                    }
                    if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate) {
                        this.weekMeetingPMBookedReport.push(element)
                    }
                      // VC Completed

                      if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate)) {
                        this.todaykMeetingVCCompletedReport.push(element)
                    }
                    else if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdaykMeetingVCCompletedReport.push(element);
                    }
                    if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate) {
                        this.weekkMeetingVCCompletedReport.push(element)
                    }
                   
                }
                if (res.data.adminfirstmonthdata.rows.length - 1 === i)
                    this.RenderMeetingReport(this.state.currentSalesReportType);
            })
        }

    }
    RenderMeetingReport=(type)=>{
        // alert(type)
            let users = this.props.PnkStore.GetData('userlist').rows;
            var hc_report = [];
                users.forEach((user, i) => {
                    if(user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales'))
                    {  
                        if (type === "Today") {
                            if(this.hcPmScheduleType==='hc')
                            {
                                this.setState({
                                    reportData:[]
                                })
                               
                                
                                this.todayMeetingHCBookedReport.forEach(todayHCData => {
                                    if(user.user_name===todayHCData.lead_owner){
                                            if (this.currentCity !== '' && (this.currentCity === todayHCData.lead_city)) {
                                            
                                            
                                                hc_report.push({
                                                    lead_owner: todayHCData.lead_owner, 
                                                    client_name: todayHCData.user_name, 
                                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                                    sales_tl:user.reporting_to,
                                                    location:todayHCData.preferred_area,
                                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                                    
                                                });
                                            }
                                            else if (this.currentCity === '') {
                                            
                                                hc_report.push({
                                                    lead_owner: todayHCData.lead_owner, 
                                                    client_name: todayHCData.user_name, 
                                                    hc_date_time: todayHCData.hc_booked_date_time, 
                                                    sales_tl:user.reporting_to,
                                                     location:todayHCData.preferred_area,
                                                    hc_completed_date_time:todayHCData.hc_completed_date_time
                                                    
                                                });
                                            }

                                    }
                                });
                        }
                        else if(this.hcPmScheduleType==="project-meeting"){
                            this.setState({
                                reportData:[]
                            })
            
                    
                            this.todayMeetingPMBookedReport.forEach(report => {
                                if(user.user_name===report.lead_owner){
                            
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                        hc_report.push({
                                            lead_owner: report.lead_owner, 
                                            client_name: report.user_name, 
                                            hc_date_time: report.hc_booked_date_time, 
                                            sales_tl:user.reporting_to,
                                            project_estimate:report.project_estimate,
                                            hc_completed_date_time:report.hc_completed_date_time,
                                            project_meeting_date_time:report.project_meeting_date_time,
                                            designer_name:report.designer_name
                                            
                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_report.push({
                                            lead_owner: report.lead_owner, 
                                            client_name: report.user_name, 
                                            hc_date_time: report.hc_booked_date_time, 
                                            sales_tl:user.reporting_to,
                                            project_estimate:report.project_estimate,
                                            hc_completed_date_time:report.hc_completed_date_time,
                                            project_meeting_date_time:report.project_meeting_date_time,
                                            designer_name:report.designer_name
                                            
                                        });
                                    }
                                
                                
                            }
                            })
                        
                        }
                            else if(this.hcPmScheduleType==="vc"){
                                this.setState({
                                    reportData:[]
                                })
                             
                               
                                this.todaykMeetingVCCompletedReport.forEach(report => {
                                    if(user.user_name===report.lead_owner){
                                
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner, 
                                                client_name: report.user_name, 
                                                hc_date_time: report.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                location:report.preferred_area,
                                                vc_completed_date_time:report.vc_completed_date_time
                                            
                                                
                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner, 
                                                client_name: report.user_name, 
                                                hc_date_time: report.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                location:report.preferred_area,
                                                vc_completed_date_time:report.vc_completed_date_time
                                            
                                                
                                            });
                                        }
                                    }
                                
                            
                            
                            })
                        }
                    }

                    else if (type === "Yesterday") {
                        if(this.hcPmScheduleType==='hc'){
                            this.setState({
                                reportData:[]
                            })
                           
                              
                            this.yesterdayMeetingHCBookedReport.forEach(yesterdayHCData => {
                                if(user.user_name===yesterdayHCData.lead_owner){
                                
                                        if (this.currentCity !== '' && (this.currentCity === yesterdayHCData.lead_city)) {
                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner, 
                                                client_name: yesterdayHCData.user_name, 
                                                hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                location:yesterdayHCData.preferred_area,
                                                hc_completed_date_time:yesterdayHCData.hc_completed_date_time

                                                
                                            });
                                        }
                                        else if (this.currentCity === '') {
                                        
                                            hc_report.push({
                                                lead_owner: yesterdayHCData.lead_owner, 
                                                client_name: yesterdayHCData.user_name, 
                                                hc_date_time: yesterdayHCData.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                location:yesterdayHCData.preferred_area,
                                                hc_completed_date_time:yesterdayHCData.hc_completed_date_time
                                                
                                            });
                                        }

                                
                                
                                }
                            });
                        }
                      
                      
                        else if(this.hcPmScheduleType==="project-meeting"){
                            this.setState({
                                reportData:[]
                            })
                            this.yesterdayMeetingPMBookedReport.forEach(report => {
                                if(user.user_name===report.lead_owner ){
                            
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    

                                        hc_report.push({
                                            lead_owner: report.lead_owner, 
                                            client_name: report.user_name, 
                                            hc_date_time: report.hc_booked_date_time, 
                                            sales_tl:user.reporting_to,
                                            project_estimate:report.project_estimate,
                                            hc_completed_date_time:report.hc_completed_date_time,
                                            project_meeting_date_time:report.project_meeting_date_time,
                                            designer_name:report.designer_name


                                            
                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_report.push({
                                            lead_owner: report.lead_owner, 
                                            client_name: report.user_name, 
                                            hc_date_time: report.hc_booked_date_time, 
                                            sales_tl:user.reporting_to,
                                            project_estimate:report.project_estimate,
                                            hc_completed_date_time:report.hc_completed_date_time,
                                            project_meeting_date_time:report.project_meeting_date_time,
                                            designer_name:report.designer_name
                                            
                                        });
                                    }
                            
                            }
                            })
                        
                        }
                        else if(this.hcPmScheduleType==="vc"){
                            this.setState({
                                reportData:[]
                            })
                          
                          
                            this.yesterdaykMeetingVCCompletedReport.forEach(report => {
                                if(user.user_name===report.lead_owner){
                            
                                    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                        hc_report.push({
                                            lead_owner: report.lead_owner, 
                                            client_name: report.user_name, 
                                            hc_date_time: report.hc_booked_date_time, 
                                            sales_tl:user.reporting_to,
                                            location:report.preferred_area,
                                            vc_completed_date_time:report.vc_completed_date_time
                                        
                                            
                                        });
                                    }
                                    else if (this.currentCity === '') {
                                        hc_report.push({
                                            lead_owner: report.lead_owner, 
                                            client_name: report.user_name, 
                                            hc_date_time: report.hc_booked_date_time, 
                                            sales_tl:user.reporting_to,
                                            location:report.preferred_area,
                                            vc_completed_date_time:report.vc_completed_date_time
                                        
                                            
                                        });
                                    }
                            
                        }
                        
                        })
                    }

                        }
                            else if (type === "Last 7 Days") {
                                this.setState({
                                    reportData:[]
                                })
                                if(this.hcPmScheduleType==='hc'){
                                this.weekMeetingHCBookedReport.forEach(weekyHCData => {
                                    if(user.user_name===weekyHCData.lead_owner){ 
                    
                                            if (this.currentCity !== '' && (this.currentCity === weekyHCData.lead_city)) {
                                                hc_report.push({
                                                    lead_owner: weekyHCData.lead_owner, 
                                                    client_name: weekyHCData.user_name, 
                                                    hc_date_time: weekyHCData.hc_booked_date_time, 
                                                    sales_tl:user.reporting_to,
                                                    location:weekyHCData.preferred_area,
                                                    hc_completed_date_time:weekyHCData.hc_completed_date_time
                                                    
                                                });
                                            }
                                            else if (this.currentCity === '') {
                                                hc_report.push({
                                                    lead_owner: weekyHCData.lead_owner, 
                                                    client_name: weekyHCData.user_name, 
                                                    hc_date_time: weekyHCData.hc_booked_date_time, 
                                                    sales_tl:user.reporting_to,
                                                    location:weekyHCData.preferred_area,
                                                    hc_completed_date_time:weekyHCData.hc_completed_date_time
                                                    
                                                });
                                            }

                                        
                                    }
                                
                                });
                            }
                            else if(this.hcPmScheduleType==="project-meeting"){
                                this.setState({
                                    reportData:[]
                                })
                              
                                this.weekMeetingPMBookedReport.forEach(report => {
                                    if(user.user_name===report.lead_owner){
                                
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                                            hc_report.push({
                                                lead_owner: report.lead_owner, 
                                                client_name: report.user_name, 
                                                hc_date_time: report.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                project_estimate:report.project_estimate,
                                                hc_completed_date_time:report.hc_completed_date_time,
                                                project_meeting_date_time:report.project_meeting_date_time,
                                                designer_name:report.designer_name
                                                
                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner, 
                                                client_name: report.user_name, 
                                                hc_date_time: report.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                project_estimate:report.project_estimate,
                                                hc_completed_date_time:report.hc_completed_date_time,
                                                project_meeting_date_time:report.project_meeting_date_time,
                                                designer_name:report.designer_name
                                                
                                            });
                                        }
                                
                                }
                                })
                            
                            }
                            else if(this.hcPmScheduleType==="vc"){
                                this.setState({
                                    reportData:[]
                                })
                                this.weekkMeetingVCCompletedReport.forEach(report => {
                                    if(user.user_name===report.lead_owner){
                                
                                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                            hc_report.push({
                                                lead_owner: report.lead_owner, 
                                                client_name: report.user_name, 
                                                hc_date_time: report.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                location:report.preferred_area,
                                                vc_completed_date_time:report.vc_completed_date_time
                                                
                                            });
                                        }
                                        else if (this.currentCity === '') {
                                            hc_report.push({
                                                lead_owner: report.lead_owner, 
                                                client_name: report.user_name, 
                                                hc_date_time: report.hc_booked_date_time, 
                                                sales_tl:user.reporting_to,
                                                location:report.preferred_area,
                                                vc_completed_date_time:report.vc_completed_date_time
                                                
                                            });
                                        }
                                
                                    }
                            
                                })
                            }
                    }
           
        
    }
          
        })
        //   alert(JSON.stringify(hc_report))
            this.setState({ reportData: hc_report })
        

    }
    HandleGetReportResponse = (res) => {
        
        this.setState({ getReportData: false, isOptionDisabled: false })
        if (res !== false) {
            if (res && res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.adminReportAll = res.data.adminfirstmonthdata.rows
                res.data.adminfirstmonthdata.rows.forEach((element, i) => {
                   
                    if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate)) {                        
                        this.todayPatchData.push(element)
                    }
                    else if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayPatchData.push(element);
                    }
                    if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate+' 23:59:59' && element.patch_date_time >= this.state.weekDate) {
                        this.weekPatchData.push(element)
                    }
                    if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate+' 23:59:59' && element.patch_date_time >= this.state.monthDate) {
                        this.monthPatchData.push(element)
                    }
                      // Lead Generate
                      if (element.date_time !== undefined && element.date_time.includes(this.state.todayDate)) {
                        this.todayLeadGeneratedData.push(element)
                    }
                    else if (element.date_time !== undefined && element.date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayLeadGeneratedData.push(element);

                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate+' 23:59:59' && element.date_time >= this.state.weekDate) {
                        this.weekLeadGeneratedData.push(element)

                    }
                    if (element.date_time !== undefined && element.date_time <= this.state.todayDate+' 23:59:59' && element.date_time >= this.state.monthDate) {
                        this.monthLeadGeneratedData.push(element)

                    }
                   
                    if (element.lead_department[0].profile_name.includes("Telecalling")) {
                       // Under Construction
                    
                    if (element.lead_current_stage==='Under Construction' && element.under_const_date_time !=='' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.todayDate)) {
                        this.todayUnderConstruction.push(element)
                    }
                    else if (element.lead_current_stage==='Under Construction' && element.under_const_date_time !== undefined && element.under_const_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayUnderConstruction.push(element);
                    }
                    if (element.lead_current_stage==='Under Construction' && element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.todayDate+' 23:59:59' && element.under_const_date_time >= this.state.weekDate) {
                        this.weekUnderConstruction.push(element)
                    }

                       
                       
                        if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.todayDate)) {
                          
                            this.todayPatchSalesReport.push(element)
                        }
                        else if (element.patch_date_time !== undefined && element.patch_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPatchSalesReport.push(element);
                        }
                        if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate+' 23:59:59' && element.patch_date_time >= this.state.weekDate) {
                            this.weekPatchSalesReport.push(element)
                        }
                        if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.todayDate+' 23:59:59' && element.patch_date_time >= this.state.monthDate) {
                            this.monthPatchSalesReport.push(element)
                        }


                      
                         // Update Time
                    if (element.updated_on !== undefined && element.updated_on.includes(this.state.todayDate)) {

                        this.todayUpdateTimeData.push(element)
                    }
                    else if (element.updated_on !== undefined && element.updated_on.includes(this.state.yesterdayDate)) {
                        this.yesterdayUpdateTimeData.push(element);
                    }
                    if (element.updated_on !== undefined && element.updated_on <= this.state.todayDate+' 23:59:59' && element.updated_on >= this.state.weekDate) {
                        this.weekUpdateTimeData.push(element)
                    }
                    if (element.updated_on !== undefined && element.updated_on <= this.state.todayDate+' 23:59:59' && element.updated_on >= this.state.monthDate) {
                        this.monthUpdateTimeData.push(element)
                    }
                     // Callback
                     if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.todayDate)) {

                        this.todayCallbackData.push(element)
                    }
                    else if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.yesterdayDate)) {
                        this.yesterdayCallbackData.push(element);
                    }
                    if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.todayDate+' 23:59:59' && element.callback_date_time >= this.state.weekDate) {
                        this.weekCallbackData.push(element)
                    }
                    if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.todayDate+' 23:59:59' && element.callback_date_time >= this.state.monthDate) {
                        this.monthCallbackData.push(element)
                    }

                        //    Dead
                       
                        if (element.lead_dead_date !== undefined && (element.lead_dead_date !== '' || element.lead_dead_date !== null) && element.lead_current_stage === "Dead" && element.lead_dead_date.includes(this.state.todayDate)) {
                            this.todayDeadData.push(element)
                        }
                        else if (element.lead_dead_date !== undefined && (element.lead_dead_date !== '' || element.lead_dead_date == null) && element.lead_current_stage === "Dead" && element.lead_dead_date.includes(this.state.yesterdayDate)) {
                            this.yesterdayDeadData.push(element);
                        }
                        if (element.lead_dead_date !== undefined && (element.lead_dead_date !== '' || element.lead_dead_date !== null) && element.lead_current_stage === "Dead" && element.lead_dead_date <= this.state.todayDate+' 23:59:59' && element.lead_dead_date >= this.state.weekDate) {
                            this.weekDeadData.push(element)
                        }
                        if (element.lead_dead_date !== undefined && (element.lead_dead_date !== '' || element.lead_dead_date !== null) && element.lead_current_stage === "Dead" && element.lead_dead_date <= this.state.todayDate+' 23:59:59'&& element.lead_dead_date >= this.state.monthDate) {
                            this.monthDeadData.push(element)
                        }
                        // Pending
                        if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time.includes(this.state.todayDate)) {
                            this.todayPendingData.push(element)
                        }
                        else if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPendingData.push(element);
                        }
                        if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.todayDate+' 23:59:59' && element.lead_dead_date >= this.state.weekDate) {
                            this.weekPendingData.push(element)
                        }
                        if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.todayDate+' 23:59:59' && element.date_time >= this.state.monthDate) {
                            this.monthPendingData.push(element)
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.todayDate)) {
                            this.todayHCBookedReport.push(element)
                        }
                       
                        else if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayHCBookedReport.push(element);
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.weekDate) {
                            this.weekHCBookedReport.push(element)
                        }
                        if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.todayDate+' 23:59:59' && element.hc_booked_date_time >= this.state.monthDate) {
                            this.monthHCBookedReport.push(element)
                        }
                         // PM todayPMBookedReport
                         if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.todayDate)) {
                            this.todayPMBookedReport.push(element)
                        }
                        else if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayPMBookedReport.push(element);
                        }
                        if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.weekDate) {
                            this.weekPMBookedReport.push(element)
                        }
                        if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.todayDate+' 23:59:59' && element.project_meeting_date_time >= this.state.monthDate) {
                            this.monthPMBookedReport.push(element)
                        }
                         // VC Completed

                         if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.todayDate)) {
                            this.todayVCCompletedReport.push(element)
                        }
                        else if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time.includes(this.state.yesterdayDate)) {
                            this.yesterdayVCCompletedReport.push(element);
                        }
                        if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.todayDate+' 23:59:59' && element.vc_completed_date_time >= this.state.weekDate) {
                            this.weekVCCompletedReport.push(element)
                        }
// Follow Up
if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.todayDate)) {
    this.todayFollowupReport.push(element)
}
else if (element.follow_up_date_time !== undefined && element.follow_up_date_time.includes(this.state.yesterdayDate)) {
    this.yesterdayFollowupReport.push(element);
}
if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate+' 23:59:59' && element.follow_up_date_time >= this.state.weekDate) {
    this.weekFollowupReport.push(element)
}
if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.todayDate+' 23:59:59' && element.follow_up_date_time >= this.state.monthDate) {
    this.monthFollowupReport.push(element)
}
                      
                    }

                    if (res.data.adminfirstmonthdata.rows.length - 1 === i)
                        this.RenderReport(this.state.currentViewType, this.state.currentSalesReportType);
                });
            }
        }
    }
    RenderReport = (viewType, type) => {
      
        var users = this.props.PnkStore.GetData('userlist').rows;
        let sales_report = [];
       
        if (viewType === "default") {
            this.setState({ reportData: [] })
                let total_lead = 0, total_lead_pending = 0, total_closure = 0, total_dead = 0, total_patch = 0;
                    if (type === "Today") {
                       
                        this.todayPatchSalesReport.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.tele_assignee ===  sessionStorage.getItem('current_user_name') && todayData.lead_owner!== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.tele_assignee ===  sessionStorage.getItem('current_user_name') && todayData.lead_owner!== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                        })
                        this.todayLeadGeneratedData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.lead_owner ===  sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(todayData.lead_department[0].profile_name.includes('Sales')===true && todayData.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(todayData.lead_department[0].profile_name.includes('Sales')===true && todayData.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                        })
                        this.todayPendingData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                        })
                        this.todayDeadData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                        })


                    }
                    else if (type === "Yesterday") {
                        this.yesterdayPatchSalesReport.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                        })
                        this.yesterdayLeadGeneratedData.forEach(report => {
                          
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(report.lead_department[0].profile_name.includes('Sales')===true  && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(report.lead_department[0].profile_name.includes('Sales')===true && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                        })
                        this.yesterdayPendingData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                        })
                        this.yesterdayDeadData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.llead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                        })
                    }
                    else if (type === 'Last 7 Days') {
                        this.weekPatchSalesReport.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                        })
                        this.weekLeadGeneratedData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(report.lead_department[0].profile_name.includes('Sales')===true && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(report.lead_department[0].profile_name.includes('Sales')===true && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                        })
                        this.weekPendingData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                        })
                        this.weekDeadData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                        })
                    }

                    else if (type === 'Last 30 Days' || type === 'Custom') {
                        this.monthPatchSalesReport.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                    if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                        total_patch++
                                    }
                                }
                            }
                        })
                        this.monthLeadGeneratedData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(report.lead_department[0].profile_name.includes('Sales')===true && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead++
                                }
                                else{
                                    if(report.lead_department[0].profile_name.includes('Sales')===true && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                        total_lead++
                                    }
                                }
                            }
                        })
                        this.monthPendingData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                    total_lead_pending++
                                }
                            }
                        })
                        this.monthDeadData.forEach(report => {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                            else if (this.currentCity === '') {
                                if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                    total_dead++
                                }
                            }
                        })
                    }
                
                if (total_patch > 0 || total_lead > 0 || total_lead_pending > 0 || total_dead) {
                    sales_report.push({
                        total_lead: total_lead, total_lead_pending: total_lead_pending, total_closure: total_closure, total_dead: total_dead, total_patch: total_patch
                    })
                }
           
            // let finaldata=[]
            // if(sales_report.length>0){
            // finaldata.push(sales_report.pop())
            // }

            this.setState({ reportData: sales_report })
        }
        else if(viewType==='under-construction'){
            let under_const_arr=[]
            if(type==="Today"){
                this.setState({reportData:[]})
                this.todayUnderConstruction.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                   
                        under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on

                           
                        });
                  
                }
        });
            }
            if(type==="Yesterday"){
                this.setState({reportData:[]})
                this.yesterdayUnderConstruction.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                
                      
                        under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on
                           
                        });
                    
                }
        });
            }
            if(type==="Last 7 Days"){
                this.setState({reportData:[]})
                this.weekUnderConstruction.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                   
                      
                        under_const_arr.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            under_const_date_time: report.under_const_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id,
                            date_time:report.date_time,
                            update_on:report.updated_on
                           
                        });
                    
                }
        });
            }
            this.setState({
                reportData:under_const_arr
            })
        }
        else if(viewType==="followupreport"){
           
            let follow_up_report = [];
            if(type==="Today"){
                follow_up_report=[]
            this.todayFollowupReport.forEach(report => {
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                            follow_up_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                follow_up_date_time: report.follow_up_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id
                               
                            });
                        }
                       
                    }
                    
                       
                }
                else if (this.currentCity === '') {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                            follow_up_report.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                follow_up_date_time: report.follow_up_date_time, 
                                client_mobile:report.user_mobile_no,
                                id:report._id
                               
                            });
                           
                        }
                    }
                   
                }
            })
        }
        else if(type==="Yesterday"){
            follow_up_report=[]
        this.yesterdayFollowupReport.forEach(report => {
            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                    }
                   
                }
                
                   
            }
            else if (this.currentCity === '') {
                if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                    if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                        follow_up_report.push({
                            lead_owner: report.lead_owner, 
                            client_name: report.user_name, 
                            follow_up_date_time: report.follow_up_date_time, 
                            client_mobile:report.user_mobile_no,
                            id:report._id
                           
                        });
                       
                    }
                }
               
            }
        })
    }
    if(type==="Last 7 Days"){
        follow_up_report=[]
    this.weekFollowupReport.forEach(report => {

        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                       if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '' ) {

                  
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                }
               
            }
            
               
        }
        else if (this.currentCity === '') {
            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
                  
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                   
                }
            }
           
        }
    })
}
else if (type === 'Last 30 Days' || type === 'Custom') {
    follow_up_report=[]
this.monthFollowupReport.forEach(report => {
    if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
              
                follow_up_report.push({
                    lead_owner: report.lead_owner, 
                    client_name: report.user_name, 
                    follow_up_date_time: report.follow_up_date_time, 
                    client_mobile:report.user_mobile_no,
                    id:report._id
                   
                });
            }
           
        }
        
           
    }
    else if (this.currentCity === '') {
        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
            if (report.follow_up_date_time !== undefined && report.follow_up_date_time !== '') {
              
                follow_up_report.push({
                    lead_owner: report.lead_owner, 
                    client_name: report.user_name, 
                    follow_up_date_time: report.follow_up_date_time, 
                    client_mobile:report.user_mobile_no,
                    id:report._id
                   
                });
               
            }
        }
       
    }
})
}
follow_up_report.sort((a,b)=> new Date(b.follow_up_date_time).getTime()-new Date(a.follow_up_date_time).getTime());
        this.setState({
            reportData:follow_up_report
        })
        }
        // else if(viewType==='HCPMSchedule'){
          
        //     let hc_time_list = []
        //     if (type === "Today") {
        //         if(this.hcPmScheduleType==='hc')
        //         {
        //             this.todayHCBookedReport.forEach(todayData => {
        //                 if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //                     if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                         if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                             hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                         }
                               
        //                     }
                            
                               
        //                 }
        //                 else if (this.currentCity === '') {
        //                     if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                         if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                             hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
                                   
        //                         }
        //                     }
                           
        //                 }
        //             })
        //     }
        //     else if(this.hcPmScheduleType==="project-meeting"){
        //         this.todayPMBookedReport.forEach(todayData => {
                    
        //             if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            
        //                     if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                     }
        //                 }
                        
                           
        //             }
        //             else if (this.currentCity === '') {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                           
        //                     if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                     }
        //                 }
                       
        //             }
        //         })

        //     }
        //     }
        //     else if (type === "Yesterday") {
        //         if(this.hcPmScheduleType==='hc')
        //         {
        //             this.yesterdayHCBookedReport.forEach(todayData => {
        //                 if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //                     if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                         if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                             hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                         }
                               
        //                     }
                            
                               
        //                 }
        //                 else if (this.currentCity === '') {
        //                     if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                         if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                             hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
                                   
        //                         }
        //                     }
                           
        //                 }
        //             })
        //     }
        //     else if(this.hcPmScheduleType==="project-meeting"){
        //         this.yesterdayPMBookedReport.forEach(todayData => {
                    
        //             if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            
        //                     if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                     }
        //                 }
                        
                           
        //             }
        //             else if (this.currentCity === '') {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                           
        //                     if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                     }
        //                 }
                       
        //             }
        //         })

        //     }

        // }
        // else if (type === "Last 7 Days") {
        //     if(this.hcPmScheduleType==='hc')
        //     {
        //         this.weekHCBookedReport.forEach(todayData => {
        //             if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                     if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                     }
                           
        //                 }
                        
                           
        //             }
        //             else if (this.currentCity === '') {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                     if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
                               
        //                     }
        //                 }
                       
        //             }
        //         })
        // }
        // else if(this.hcPmScheduleType==="project-meeting"){
        //     this.weekPMBookedReport.forEach(todayData => {
                
        //         if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //             if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                        
        //                 if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                     hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                 }
        //             }
                    
                       
        //         }
        //         else if (this.currentCity === '') {
        //             if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                       
        //                 if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                     hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                 }
        //             }
                   
        //         }
        //     })

        // } 
        // }
        // else if (type === "Last 30 Days" || type === "Custom") {
        //     if(this.hcPmScheduleType==='hc')
        //     {
        //         this.monthHCBookedReport.forEach(todayData => {
        //             if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                     if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                         hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                     }
                           
        //                 }
                        
                           
        //             }
        //             else if (this.currentCity === '') {
        //                 if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
        //                     if (todayData.hc_booked_date_time !== undefined && todayData.hc_booked_date_time !== '') {
        //                         console.log("todayData",todayData)
        //                         hc_time_list.push({'time':todayData.hc_booked_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
                               
        //                     }
        //                 }
                       
        //             }
        //         })
        // }
        // else if(this.hcPmScheduleType==="project-meeting"){
        //     this.monthPMBookedReport.forEach(todayData => {
                
        //         if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
        //             if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                        
        //                 if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                     hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                 }
        //             }
                    
                       
        //         }
        //         else if (this.currentCity === '') {
        //             if (todayData.lead_owner === sessionStorage.getItem('current_user_name')) {
                       
        //                 if (todayData.project_meeting_date_time !== undefined && todayData.project_meeting_date_time !== '') {
        //                     hc_time_list.push({'time':todayData.project_meeting_date_time,'user_name':todayData.user_name,'user_contact':todayData.user_mobile_no,'user_email':todayData.user_email});
        //                 }
        //             }
                   
        //         }
        //     })

        // }   
        // }
        
        //     hc_time_list.sort((a,b)=> new Date(a.time).getTime()-new Date(b.time).getTime());
        //     console.log("hc_time_list", hc_time_list)
        //     this.setState({ reportData: hc_time_list })
        // }
        else if (viewType === "details") {
         
           var current_details=[]
                let name = '', caller = '', source = '', number = '', stage = '', lead_acquisition_date = '', last_updated = '', city = '', lead_patched_date='';
           

                    if (type === "Today") {
                        current_details=[]
                        this.todayLeadGeneratedData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                           
                        })
                        

                    }
                    else if (type === "Yesterday") {
                        current_details=[]
                        this.yesterdayLeadGeneratedData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                           
                        })
                       
                    }
                    else if (type === 'Last 7 Days') {
                         current_details=[]
                        this.weekLeadGeneratedData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                            else if (this.currentCity === '') {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                   
                                    
                                 
                                }
                            }
                        })
                        
                            // sales_report.push({
                            //     lead_patched_date: lead_patched_date, lead_acquisition_date: lead_acquisition_date, last_edited: last_updated,
                            //     client_name: name, tele_caller: caller, client_number: number, lead_source: source, lead_stage: stage, city: city
                            // })
                        
                    }

                    else if (type === 'Last 30 Days' || type === 'Custom') {
                        current_details=[]
                        this.monthLeadGeneratedData.forEach(todayData => {
                            if (this.currentCity !== '' && (this.currentCity === todayData.lead_city)) {
                                if (todayData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                            else if (this.currentCity === '') {
                                
                                if (todayData.tele_assignee ===sessionStorage.getItem('current_user_name')) {
                                    current_details.push({
                                        client_name :todayData.user_name,
                                        tele_caller : todayData.tele_assignee,
                                        lead_source :todayData.lead_source,
                                        client_number : todayData.user_mobile_no,
                                        lead_stage : todayData.lead_current_stage,
                                        lead_acquisition_date :todayData.date_time,
                                        last_edited : todayData.updated_on,
                                        lead_patched_date : todayData.patch_date_time,
                                        city : todayData.lead_city,
                                    })
                                }
                            }
                        })
                        
                    }

                this.setState({ reportData: current_details })

           
        }
      
        else if(viewType==='daily-report'){
            let insideSalesData = []
           
                let lead_patched = 0, lead_generated = 0, lead_called = 0,  total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                if (type === "Today") {
                    //  lead_patched = 0, lead_generated = 0, lead_called = 0,  total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                    lead_patched = 0; lead_generated = 0; lead_called = 0;  total_dead = 0; total_pending = 0; total_rnr = 0;
                    total_hold = 0; total_callback = 0;
                    this.todayPatchData.forEach(todayPatchedData => {
                        if (todayPatchedData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === todayPatchedData.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }
                    });
                    this.todayExotelCalled.forEach(todayCalleddData => {
                       
                        if (todayCalleddData.caller_name === sessionStorage.getItem('current_user_name')) {
                            // if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                lead_called++
                            // }
                            // if (this.currentCity === '') {
                                // lead_called++
                            // }
                        }
                    });
                    this.todayLeadGeneratedData.forEach(todayLGData => {
                        if (todayLGData.tele_assignee === sessionStorage.getItem('current_user_name') || todayLGData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === todayLGData.lead_city)) {
                                lead_generated++
        
                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.todayUpdateTimeData.forEach(todayUpdatedData => {
                        
                        if (todayUpdatedData.lead_owner === sessionStorage.getItem('current_user_name') ) {
                          
                            if (this.currentCity !== '' && (this.currentCity === todayUpdatedData.lead_city)) {
                                if (todayUpdatedData.lead_current_stage === "RNR")
                                    total_rnr++
                                if (todayUpdatedData.lead_current_stage === "Hold")
                                    total_hold++
                            }
        
        
                            if (this.currentCity === '') {
        
                                if (todayUpdatedData.lead_current_stage === "RNR")
                                    total_rnr++
                                if (todayUpdatedData.lead_current_stage === "Hold")
                                    total_hold++
        
                            }
                        }
        
                    })
                    this.todayPendingData.forEach(todayPendingdData => {
                        if (todayPendingdData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === todayPendingdData.lead_city)) {
                                total_pending++
        
                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.todayCallbackData.forEach(todayCallbackData => {
                        if (todayCallbackData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === todayCallbackData.lead_city)) {
                                total_callback++
        
                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.todayDeadData.forEach(todayDeadData => {
                        if (todayDeadData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === todayDeadData.lead_city)) {
                                total_dead++
                               
                            }
        
        
                            if (this.currentCity === '') {
        
                                total_dead++
                                
        
                            }
                        }
                    });
                   
                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: sessionStorage.getItem('current_user_name')
        
                        })
                    }
                }
               else if (type === "Yesterday") {
                //  lead_patched = 0, lead_generated = 0, lead_called = 0,  total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                lead_patched = 0; lead_generated = 0; lead_called = 0;  total_dead = 0; total_pending = 0; total_rnr = 0;
                total_hold = 0; total_callback = 0;
                this.yesterdayPatchData.forEach(report => {
                    if (report.tele_assignee === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            lead_patched++
                        }
                        if (this.currentCity === '') {
                            lead_patched++
                        }
                    }
                });
                this.yesterdayExotelCalled.forEach(yesterdayCalleddData => {
                       
                    if (yesterdayCalleddData.caller_name === sessionStorage.getItem('current_user_name')) {
                        // if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                            lead_called++
                        // }
                        // if (this.currentCity === '') {
                            // lead_called++
                        // }
                    }
                });
                this.yesterdayLeadGeneratedData.forEach(report => {
                    if (report.tele_assignee === sessionStorage.getItem('current_user_name') || report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            lead_generated++
        
                        }
                        if (this.currentCity === '') {
                            lead_generated++
                        }
                    }
                });
                this.yesterdayUpdateTimeData.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if (report.lead_current_stage === "RNR")
                                total_rnr++
                            if (report.lead_current_stage === "Hold")
                                total_hold++
                        }
        
        
                        if (this.currentCity === '') {
        
                            
                            if (report.lead_current_stage === "RNR")
                                total_rnr++
                            if (report.lead_current_stage === "Hold")
                                total_hold++
        
                        }
                    }
        
                })
                this.yesterdayPendingData.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            total_pending++
        
                        }
                        if (this.currentCity === '') {
                            total_pending++
                        }
                    }
                })
                this.yesterdayCallbackData.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            total_callback++
        
                        }
                        if (this.currentCity === '') {
                            total_callback++
                        }
                    }
                })
                this.yesterdayDeadData.forEach(report => {
                    if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            total_dead++
                           
                        }
        
        
                        if (this.currentCity === '') {
        
                            total_dead++
                            
        
                        }
                    }
                });
               
                if (lead_generated > 0 || lead_patched > 0) {
                    insideSalesData.push({
                        lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                        total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: sessionStorage.getItem('current_user_name')
        
                    })
                }
                }
                else if (type === "Last 7 Days") {
                //  lead_patched = 0, lead_generated = 0, lead_called = 0,  total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                lead_patched = 0; lead_generated = 0; lead_called = 0;  total_dead = 0; total_pending = 0; total_rnr = 0;
                total_hold = 0; total_callback = 0;    
                this.weekPatchData.forEach(report => {
                        if (report.tele_assignee === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }
                    });
                    this.weekExotelCalled.forEach(weekCalleddData => {
                       
                        if (weekCalleddData.caller_name === sessionStorage.getItem('current_user_name')) {
                            // if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                lead_called++
                            // }
                            // if (this.currentCity === '') {
                                // lead_called++
                            // }
                        }
                    });
                    this.weekLeadGeneratedData.forEach(report => {
                        if (report.tele_assignee === sessionStorage.getItem('current_user_name') || report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_generated++
            
                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.weekUpdateTimeData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
                            }
            
            
                            if (this.currentCity === '') {
            
                               
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
            
                            }
                        }
            
                    })
                    this.weekPendingData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_pending++
            
                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.weekCallbackData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_callback++
            
                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.weekDeadData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_dead++
                               
                            }
            
            
                            if (this.currentCity === '') {
            
                                total_dead++
                                
            
                            }
                        }
                    });
                   
                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: sessionStorage.getItem('current_user_name')
            
                        })
                    }
                }
                
                else if(type === "Last 30 Days" || type === "Custom"){
                 lead_patched = 0; lead_generated = 0; lead_called = 0;  total_dead = 0; total_pending = 0; total_rnr = 0;
                  total_hold = 0; total_callback = 0;
                    this.monthPatchData.forEach(report => {
                        if (report.tele_assignee === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_patched++
                            }
                            if (this.currentCity === '') {
                                lead_patched++
                            }
                        }
                    });
                    this.monthExotelCalled.forEach(monthCalleddData => {
                       
                        if (monthCalleddData.caller_name === sessionStorage.getItem('current_user_name')) {
                            // if (this.currentCity !== '' && (this.currentCity === user.city_name)) {
                                lead_called++
                            // }
                            // if (this.currentCity === '') {
                                // lead_called++
                            // }
                        }
                    });
                 
                    this.monthLeadGeneratedData.forEach(report => {
                        if (report.tele_assignee === sessionStorage.getItem('current_user_name') || report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                lead_generated++
            
                            }
                            if (this.currentCity === '') {
                                lead_generated++
                            }
                        }
                    });
                    this.monthUpdateTimeData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
                            }
            
            
                            if (this.currentCity === '') {
            
                            
                                if (report.lead_current_stage === "RNR")
                                    total_rnr++
                                if (report.lead_current_stage === "Hold")
                                    total_hold++
            
                            }
                        }
            
                    })
                    this.monthPendingData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_pending++
            
                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    this.monthCallbackData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_callback++
            
                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })
                    this.monthDeadData.forEach(report => {
                        if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                total_dead++
                               
                            }
            
            
                            if (this.currentCity === '') {
            
                                total_dead++
                                
            
                            }
                        }
                    });
                   
                    if (lead_generated > 0 || lead_patched > 0) {
                        insideSalesData.push({
                            lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                            total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: sessionStorage.getItem('current_user_name')
            
                        })
                    }
        
                }
        
        
        
        
            
            this.setState({
               reportData:insideSalesData
            })
        }
        else if(viewType==="patch-report"){
           
            var patchReportData=[]
            let patch_report=[]
            if(type==='Today'){
                this.todayPatchData.forEach((element, i) => {
                   
                        if (element.tele_assignee === sessionStorage.getItem('current_user_name')) {
                           
                            let typeIndex = patch_report.findIndex(type => element.lead_owner ===type.lead_owner);
                            if (typeIndex !== -1) {
                                let patch_count= patch_report[typeIndex].patch_count+1
                                patch_report[typeIndex].patch_count=patch_count
                                patch_report[typeIndex].user_details.push({client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time})
                        
                            }
                            else {
                                patch_report.push({'lead_owner': element.lead_owner, 'patch_count':1,user_details:[{client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time}]});
                            }
        
                        }
                    
                   
                    
                 })
               
             }
             else if(type==='Yesterday'){
              
                this.yesterdayPatchData.forEach((element, i) => {
                   
                        if (element.tele_assignee === sessionStorage.getItem('current_user_name')) {
                           
                            let typeIndex = patch_report.findIndex(type => element.lead_owner ===type.lead_owner);
                            if (typeIndex !== -1) {
                                let patch_count= patch_report[typeIndex].patch_count+1
                                patch_report[typeIndex].patch_count=patch_count
                                patch_report[typeIndex].user_details.push({client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time})
                        
                            }
                            else {
                                patch_report.push({'lead_owner': element.lead_owner, 'patch_count':1,user_details:[{client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time}]});
                            }
        
                        }
                    
                   
                    
                 })
               
             }
             else if(type==='Last 7 Days'){
                
                this.weekPatchData.forEach((element, i) => {
                   
                        if (element.tele_assignee === sessionStorage.getItem('current_user_name')) {
                           
                            let typeIndex = patch_report.findIndex(type => element.lead_owner ===type.lead_owner);
                            if (typeIndex !== -1) {
                                let patch_count= patch_report[typeIndex].patch_count+1
                                patch_report[typeIndex].patch_count=patch_count
                                patch_report[typeIndex].user_details.push({client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time})
                        
                            }
                            else {
                                patch_report.push({'lead_owner': element.lead_owner, 'patch_count':1,user_details:[{client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time}]});
                            }
        
                        }
                    
                   
                    
                 })
               
             }
             else if(type==='Last 30 Days' || type === 'Custom'){
               
                this.monthPatchData.forEach((element, i) => {
                   
                        if (element.tele_assignee === sessionStorage.getItem('current_user_name')) {
                           
                            let typeIndex = patch_report.findIndex(type => element.lead_owner ===type.lead_owner);
                            if (typeIndex !== -1) {
                                let patch_count= patch_report[typeIndex].patch_count+1
                                patch_report[typeIndex].patch_count=patch_count
                                patch_report[typeIndex].user_details.push({client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time})
                        
                            }
                            else {
                                patch_report.push({'lead_owner': element.lead_owner, 'patch_count':1,user_details:[{client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time}]});
                            }
        
                        }
                    
                   
                    
                 })
               
             }
        
            
        
           this.setState({
            reportData:patch_report
           })
        }
       
    }
    RenderCustomMeetingReport=(customData)=>{
        console.log("customEndDate", this.state.customEndDate)
        console.log("customStartDate",this.state.customStartDate)
        let hc_reports = [];
        let hc_datetime_report = []
        let pm_date_time_report=[]
        let vc_date_time_report=[]
        let users = this.props.PnkStore.GetData('userlist').rows;
        customData.forEach((element, i) => {
            if (element.lead_department[0].profile_name.includes("Sales")) {

              
               
                if (element.hc_booked_date_time !== undefined && element.hc_booked_date_time <= this.state.customEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.customStartDate) {
                    console.log("element",element.hc_booked_date_time)
                   
                    hc_datetime_report.push(element)
                }
                if (element.project_meeting_date_time !== undefined && element.project_meeting_date_time <= this.state.customEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.customStartDate) {
                    pm_date_time_report.push(element)
                }
                if (element.vc_completed_date_time !== undefined && element.vc_completed_date_time <= this.state.customEndDate+' 23:59:59' && element.vc_completed_date_time >= this.state.customStartDate) {
                    vc_date_time_report.push(element)
                }
            }
            
           
        })
       
        users.forEach((user, i) => {
            if (user.user_profile[0].profile_name !== undefined && user.user_profile[0].profile_name.includes('Sales')) {
                if(this.hcPmScheduleType==='hc')
                {
                  
                hc_datetime_report.forEach(customHcData => {
                   
                        if (customHcData.lead_owner === user.user_name) {
                            if (this.currentCity !== '' && (this.currentCity === customHcData.lead_city)) {
                              
                                hc_reports.push({
                                    client_name : customHcData.user_name,
                                    hc_date_time :customHcData.hc_booked_date_time,
                                    lead_owner:customHcData.lead_owner,
                                    sales_tl:user.reporting_to,
                                    location:customHcData.preferred_area,
                                hc_completed_date_time:customHcData.hc_completed_date_time

                                })

                                
                            }
                            else if (this.currentCity === '') {
                                hc_reports.push({
                                    client_name : customHcData.user_name,
                                    hc_date_time :customHcData.hc_booked_date_time,
                                    lead_owner:customHcData.lead_owner,
                                    sales_tl:user.reporting_to,
                                    location:customHcData.preferred_area,
                                    hc_completed_date_time:customHcData.hc_completed_date_time
                                })
                            }
                        }
                    
                    
                   
                });
            }
            if(this.hcPmScheduleType==='project-meeting')
            {
                pm_date_time_report.forEach(report => {
                    if(user.user_name===report.lead_owner){
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {

                            hc_reports.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_reports.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                project_estimate:report.project_estimate,
                                hc_completed_date_time:report.hc_completed_date_time,
                                project_meeting_date_time:report.project_meeting_date_time,
                                designer_name:report.designer_name
                                
                            });
                        }
                   
                }
                })
            }

            if(this.hcPmScheduleType==="vc"){
                vc_date_time_report.forEach(report => {
                    if(user.user_name===report.lead_owner){
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            hc_reports.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                        else if (this.currentCity === '') {
                            hc_reports.push({
                                lead_owner: report.lead_owner, 
                                client_name: report.user_name, 
                                hc_date_time: report.hc_booked_date_time, 
                                sales_tl:user.reporting_to,
                                location:report.preferred_area,
                                vc_completed_date_time:report.vc_completed_date_time
                               
                                
                            });
                        }
                    

                  
            }
               
            })
            }
                
            
        }
        })
       
        this.setState({
            reportData: hc_reports
        })

    }
    RenderCustomReport = (viewtype) => {

        let patch_date_time_data = [];
        let lead_generate_data = []
        let dead_lead_data = []
        let pending_lead_data = []
        let sales_report = [];
        let patch_date_time_data_all=[]
       
        let users = this.props.PnkStore.GetData('userlist').rows;
var current_details=[]
        this.customData.forEach((element, i) => {
            if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate+' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                patch_date_time_data_all.push(element)
            }
            if (element.lead_department[0].profile_name.includes("Telecalling")) {
              
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate +' 23:59:59'&& element.patch_date_time >= this.state.customStartDate) {
                    patch_date_time_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate+' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    lead_generate_data.push(element)
                }
                if (element.lead_dead_date !== undefined && (element.lead_dead_date === '' || element.lead_dead_date == undefined) && element.lead_current_stage === "Dead" &&
                 element.lead_dead_date <= this.state.customEndDate+' 23:59:59' && element.lead_dead_date >= this.state.customStartDate) {
                    dead_lead_data.push(element)
                }
                
                if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate+' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    pending_lead_data.push(element)
                }
            }
        })
        if (viewtype === "default") {
            let total_lead = 0, total_lead_pending = 0, total_closure = 0, total_dead = 0, total_patch = 0;
                    patch_date_time_data.forEach(report => {
                        
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                total_patch++
                            }
                        }
                        else if (this.currentCity === '') {
                            if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                                if (report.tele_assignee ===  sessionStorage.getItem('current_user_name') && report.lead_owner !== sessionStorage.getItem('current_user_name')) {
                                    total_patch++
                                }
                            }
                        }
                    })
                    lead_generate_data.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                total_lead++
                            }
                            else{
                                if(report.lead_department[0].profile_name.includes('Sales')===true  && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                    total_lead++
                                }
                            }
                        }
                        else if (this.currentCity === '') {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                total_lead++
                            }
                            else{
                                if(report.lead_department[0].profile_name.includes('Sales')===true  && report.tele_assignee===sessionStorage.getItem('current_user_name')){
                                    total_lead++
                                }
                            }
                        }
                    })
                    pending_lead_data.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                total_lead_pending++
                            }
                        }
                        else if (this.currentCity === '') {
                            if (report.lead_owner === sessionStorage.getItem('current_user_name')) {
                                total_lead_pending++
                            }
                        }
                    })
                    dead_lead_data.forEach(report => {
                        if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                            if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                total_dead++
                            }
                        }
                        else if (this.currentCity === '') {
                            if (report.lead_owner ===sessionStorage.getItem('current_user_name')) {
                                total_dead++
                            }
                        }
                    })

                

          
            // if (total_patch > 0 || total_lead > 0 || total_lead_pending > 0 || total_dead>0) {
                sales_report.push({
                    total_lead: total_lead, total_lead_pending: total_lead_pending, total_closure: total_closure, total_dead: total_dead, total_patch: total_patch
                })
            // }
            this.setState({ reportData: sales_report })
        }
        else if (viewtype === "under-construction") {
            let under_const_data=[],under_const_arr=[];
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Telecalling")) {
                    if (element.lead_owner ===sessionStorage.getItem('current_user_name')) {
                            if (element.under_const_date_time !== undefined && element.under_const_date_time <= this.state.customEndDate+' 23:59:59' && element.under_const_date_time >= this.state.customStartDate) {
                                under_const_data.push(element)
                            }
                        }
                }
            })
            console.log("under_const_data",under_const_data)
            under_const_data.forEach(report => {
              
                    console.log("custome",report)
                  
                    under_const_arr.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        under_const_date_time: report.under_const_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id,
                        date_time:report.date_time,
                        update_on:report.updated_on

                      
                       
                    });
                
            
    });
    this.setState({
       reportData: under_const_arr
    })
        }
        else if(viewtype==='daily-report'){
            let insideSalesData = []
            let insidesales_patch_data = []
            let insidesales_lg_data = []
            let insidesales_called_data = []
            let insidesales_dead_data = []
            let insidesales_pending_data = []
            let insidesales_updated_data = []
            let insidesales_callback_data = []
            this.customData.forEach((element, i) => {
                if (element.patch_date_time !== undefined && element.patch_date_time <= this.state.customEndDate+' 23:59:59' && element.patch_date_time >= this.state.customStartDate) {
                    insidesales_patch_data.push(element)
                }
                if (element.date_time !== undefined && element.date_time <= this.state.customEndDate+' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_lg_data.push(element)
                   
                }
                if (element.lead_department[0].profile_name.includes("Telecalling")) {
               
                if (element.lead_dead_date !== undefined && element.lead_dead_date <= this.state.customEndDate+' 23:59:59' && element.lead_dead_date >= this.state.customStartDate) {
                    insidesales_dead_data.push(element)
                }
                // if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate && element.date_time >= this.state.customStartDate) {
                //     insidesales_pending_data.push(element)
                // }
                if (element.lead_current_stage !== undefined && element.lead_current_stage === "Pending" && element.date_time !== undefined && element.date_time <= this.state.customEndDate
                +' 23:59:59' && element.date_time >= this.state.customStartDate) {
                    insidesales_pending_data.push(element)
                }
                if (element.updated_on !== undefined && element.updated_on <= this.state.customEndDate+' 23:59:59' && element.updated_on >= this.state.customStartDate) {
                    insidesales_updated_data.push(element)
                }
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate+' 23:59:59' && element.callback_date_time >= this.state.customStartDate) {
                    insidesales_callback_data.push(element)
                }
            }


            })
            this.customCallList.forEach((element, i) => {
                if (element.date_created !== undefined && element.date_created <= this.state.customEndDate+' 23:59:59' && element.date_created >= this.state.customStartDate) {
                    insidesales_called_data.push(element)
                }
            })
           
                let lead_patched = 0, lead_generated = 0, lead_called = 0,  total_dead = 0, total_pending = 0, total_rnr = 0, total_hold = 0, total_callback = 0;
                insidesales_patch_data.forEach(customPatchedData => {
                    if (customPatchedData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === customPatchedData.lead_city)) {
                            lead_patched++
                        }
                        if (this.currentCity === '') {
                            lead_patched++
                        }
                    }

                });
                insidesales_called_data.forEach(customCalleddData => {
                    
                    if (customCalleddData.caller_name === sessionStorage.getItem('current_user_name')) {
                        // if (this.currentCity !== '' && (this.currentCity === customCalleddData.city_name)) {
                        //     lead_called++
                        // }
                        // if (this.currentCity === '') {
                            lead_called++
                        // }
                    }
                });
              
                insidesales_lg_data.forEach(customLGData => {
                    if (customLGData.tele_assignee === sessionStorage.getItem('current_user_name') || customLGData.lead_owner === sessionStorage.getItem('current_user_name')) {
                        if (this.currentCity !== '' && (this.currentCity === customLGData.lead_city)) {
                            lead_generated++

                        }
                        if (this.currentCity === '') {
                            lead_generated++
                        }
                    }
                });
                
                    insidesales_dead_data.forEach(customDeadData => {
                        if (customDeadData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === customDeadData.lead_city)) {
                                total_dead++

                            }
                            if (this.currentCity === '') {
                                total_dead++
                              
                            }
                        }
                    });
                
               
                    insidesales_pending_data.forEach(customPendingdData => {
                        if (customPendingdData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === customPendingdData.lead_city)) {
                                total_pending++

                            }
                            if (this.currentCity === '') {
                                total_pending++
                            }
                        }
                    })
                    insidesales_updated_data.forEach(customUpdatedData => {
                        if (customUpdatedData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === customUpdatedData.lead_city)) {
                                if (customUpdatedData.lead_current_stage === "RNR")
                                    total_rnr++
                                if (customUpdatedData.lead_current_stage === "Hold")
                                    total_hold++

                            }
                            if (this.currentCity === '') {
                            
                                if (customUpdatedData.lead_current_stage === "RNR")
                                    total_rnr++
                                if (customUpdatedData.lead_current_stage === "Hold")
                                    total_hold++
                            }
                        }
                    })
                    insidesales_callback_data.forEach(customCallbackData => {
                        if (customCallbackData.lead_owner === sessionStorage.getItem('current_user_name')) {
                            if (this.currentCity !== '' && (this.currentCity === customCallbackData.lead_city)) {
                                total_callback++

                            }
                            if (this.currentCity === '') {
                                total_callback++
                            }
                        }
                    })

                
                // if (lead_generated > 0 || lead_patched > 0 || lead_called>0 ) {
                    insideSalesData.push({
                        lead_generated: lead_generated, lead_patched: lead_patched, lead_called: lead_called, date_time: this.state.todayDate,
                        total_dead: total_dead, total_pending: total_pending, total_rnr: total_rnr, total_hold: total_hold, total_callback: total_callback, tele_caller: sessionStorage.getItem('current_user_name')

                    })
                // }
                this.setState({ reportData: insideSalesData })
            
            

        }
        else if (viewtype === 'followupreport') {
            let follow_up_report = [];
            let follow_up_date_time_report = []
            this.customData.forEach((element, i) => {
                if (element.lead_department[0].profile_name.includes("Telecalling")) {
               
                    if (element.follow_up_date_time !== undefined && element.follow_up_date_time <= this.state.customEndDate+' 23:59:59' && element.follow_up_date_time >= this.state.customStartDate) {
                        follow_up_date_time_report.push(element)
                    }
                }
            })
           
              
            follow_up_date_time_report.forEach(report => {
                      
                if (this.currentCity !== '' && (this.currentCity === report.lead_city)) {
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                }
                else if (this.currentCity === '') {
                  
                    follow_up_report.push({
                        lead_owner: report.lead_owner, 
                        client_name: report.user_name, 
                        follow_up_date_time: report.follow_up_date_time, 
                        client_mobile:report.user_mobile_no,
                        id:report._id
                       
                    });
                }
                    
                

            })
            follow_up_report.sort((a,b)=> new Date(b.follow_up_date_time).getTime()-new Date(a.follow_up_date_time).getTime());
            this.setState({
                reportData: follow_up_report
            })
        }
        else if (viewtype === "patch-report") {
            let patch_report=[]
            patch_date_time_data_all.forEach(element => {
                if (element.tele_assignee === sessionStorage.getItem('current_user_name')) {
                           
                    let typeIndex = patch_report.findIndex(type => element.lead_owner ===type.lead_owner);
                    if (typeIndex !== -1) {
                        let patch_count= patch_report[typeIndex].patch_count+1
                        patch_report[typeIndex].patch_count=patch_count
                        patch_report[typeIndex].user_details.push({client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time})
                
                    }
                    else {
                        patch_report.push({'lead_owner': element.lead_owner, 'patch_count':1,user_details:[{client_name:element.user_name,client_contact:element.user_mobile_no,date_time:element.date_time,patch_date_time:element.patch_date_time}]});
                    }

                }
            })
            this.setState({ reportData: patch_report })
        }
        else if (viewtype === "details") {
           current_details=[]
           lead_generate_data.forEach(patchData => {
                        if (this.currentCity !== '' && (this.currentCity === patchData.lead_city)) {
                            if (patchData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                current_details.push({
                                    client_name :patchData.user_name,
                                    tele_caller : patchData.tele_assignee,
                                    lead_source :patchData.lead_source,
                                    client_number : patchData.user_mobile_no,
                                    lead_stage : patchData.lead_current_stage,
                                    lead_acquisition_date :patchData.date_time,
                                    last_edited : patchData.updated_on,
                                    lead_patched_date : patchData.patch_date_time,
                                    city : patchData.lead_city,
                                })
                            }
                        }
                        else if (this.currentCity === '') {
                            if (patchData.tele_assignee === sessionStorage.getItem('current_user_name')) {
                                current_details.push({
                                    client_name :patchData.user_name,
                                    tele_caller : patchData.tele_assignee,
                                    lead_source :patchData.lead_source,
                                    client_number : patchData.user_mobile_no,
                                    lead_stage : patchData.lead_current_stage,
                                    lead_acquisition_date :patchData.date_time,
                                    last_edited : patchData.updated_on,
                                    lead_patched_date : patchData.patch_date_time,
                                    city : patchData.lead_city,
                                })
                            }
                        }
                    })
                    this.setState({ reportData: current_details })
                }
    }

    HandleGetUserListResponse = (res) => {
        if (res.data && res.data.userlist && res.data.userlist) {
            this.props.PnkStore.SetData('userlist', res.data.userlist);
            this.props.PnkStore.BroadCast();
        }
        this.setState({ getUser: false })
    }


    GetCustomData = () => {
        var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        let startDate = yyyy + '-' + mm + '-' + dd;
        var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        if(this.state.currentViewType==='daily-report')
        {
            this.setState({
                customStartDate: startDate,
                customEndDate: endDate,  getCustomCallList:true,
                ddlPreferredCity: '-1', getCustomCallbackData:false, getCustomData: false,
            })
        }
        else if(this.state.currentViewType==='callback'){
            this.setState({
                customStartDate: startDate,
                customEndDate: endDate,  getCustomCallbackData:true,
                ddlPreferredCity: '-1', getCustomCallList:false, getCustomData: false,
            })
        }
else
{
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate, getCustomData: true,
            ddlPreferredCity: '-1', getCustomCallbackData:false, getCustomCallList:false,
        })
    }
        this.currentCity = ''
    }
    HandleGetCustomMeetingReportResponse=(res)=>{
        this.setState({
            getCustomMeetingdata: false,
            showCustomDateModal: false
        })
        if (res !== false) {
           
            if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
               
                console.log("RenderCustomMeetingReport",this.state.customStartDate)
                this.RenderCustomMeetingReport(res.data.adminfirstmonthdata.rows)
            }
        }
       
    }
    HandleGetCustomDatatResponse = (res) => {
        if (res !== false) {
           
            if (res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length > 0) {
                this.customData = res.data.adminfirstmonthdata.rows
                this.RenderCustomReport(this.state.currentViewType)
            }
        }
        this.setState({
            getCustomData: false,
            showCustomDateModal: false
        })
    }


    HandleGetCityListSuccessResponse = (res) => {
        if (res.data && res.data.citylist && res.data.citylist.rows) {
            this.props.PnkStore.SetData('citylist', res.data.citylist);
            this.props.PnkStore.BroadCast();
        }

        this.setState({
            getCity: false
        })
    }
    HandleGetCallbackReportResponse=(res)=>{
       
        if (res !== false) {
            if (res.data && res.data.callbackleads && res.data.callbackleads.rows && res.data.callbackleads.rows.length > 0) {
                this.callbackReportData=res.data.callbackleads;
      
        this.RenderCallbackReport(res.data.callbackleads,this.state.currentSalesReportType)

    }
}
this.setState({getCallbackData:false,getCustomCallbackData:false})
    }
    RenderCallbackReport=(data,type)=>{
       var callbackReportData=[]
        if(type==='Today'){
            data.rows.forEach((element, i) => {
             
                if (element.callback_date_time !== undefined && element.callback_date_time.includes(this.state.todayDate)) {
                    if(element.lead_department[0].profile_name.includes('Telecalling')){
                        if (element.lead_owner === sessionStorage.getItem('current_user_name')) {
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }
                    }
                }
               
            })
        }
        if(type==='Coming Days'){
            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time>this.state.todayDate) {
                    if(element.lead_department[0].profile_name.includes('Telecalling')){
                        if (element.lead_owner === sessionStorage.getItem('current_user_name')) { 
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }
            }
                }
            })
        }
        if(type==='Custom'){
            data.rows.forEach((element, i) => {
                if (element.callback_date_time !== undefined && element.callback_date_time <= this.state.customEndDate+' 23:59:59' &&  element.callback_date_time >= this.state.customStartDate) {
                    if(element.lead_department[0].profile_name.includes('Telecalling')){
                        if (element.lead_owner === sessionStorage.getItem('current_user_name')) { 
                    callbackReportData.push({
                        acquisitionDate:element.date_time,
                        updatedOn:element.updated_on,
                        customerName:element.user_name,
                        customerContact:element.user_mobile_no,
                        customerEmail:element.user_email,
                        callbackDateTime:element.callback_date_time,
                        leadOwner:element.lead_owner,
                        id:element._id
                    })
                }
            }
                }
            })
        }
    
        callbackReportData.sort((a,b)=> new Date(a.callbackDateTime).getTime()-new Date(b.callbackDateTime).getTime());
  
      this.setState({
        callbackReportData:callbackReportData
      })
}

HandleHCSchedule = (type) => {
      
    this.setState({
        hcPmScheduleType: type,
        currentSalesReportType:'Today'

    })
    this.hcPmScheduleType=type;
    this.RenderReport("HCPMSchedule",'Today',)
}
GotoLeadEntry=(lead_id)=>{
    AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/'+lead_id+ '/' + this.state.currentViewType);
}
HandleGetAllCallsListSuccessResponse=(res)=>{

    this.setState({
        getAllCalls:false
    })
    
        if (res && res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows.length > 0) {
            res.data.calllist.rows.forEach((element, i) => {
    
                // Lead Called
                if (element.date_created !== undefined && element.date_created.includes(this.state.todayDate)) {
                this.todayExotelCalled.push(element)
    
    
                }
                else if (element.date_created !== undefined && element.date_created.includes(this.state.yesterdayDate)) {
                this.yesterdayExotelCalled.push(element);
    
                }
                if (element.date_created !== undefined && element.date_created <= this.state.todayDate+ ' 23:59:59' && element.date_created >= this.state.weekDate) {
                this.weekExotelCalled.push(element)
    
                }
                if (element.date_created !== undefined && element.date_created <= this.state.todayDate+ ' 23:59:59' && element.date_created >= this.state.monthDate) {
                this.monthExotelCalled.push(element)
    
                }
            })
        }
    
    
       
    
        }
        HandleGetCustomAllCallsListSuccessResponse=(res)=>{

            if (res && res.data && res.data.calllist && res.data.calllist.rows && res.data.calllist.rows.length > 0) {
                this.customCallList=res.data.calllist.rows
              
            }
            this.setState({
                getCustomCallList:false,
                getCustomData:true
            })
        }
        // GetCustomMeetingData=()=>{
        //     var dd = String(new Date(this.state.txtCustomStartDate).getDate()).padStart(2, '0');
        //     var mm = String(new Date(this.state.txtCustomStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        //     var yyyy = new Date(this.state.txtCustomStartDate).getFullYear();
        //     let startDate = yyyy + '-' + mm + '-' + dd;
        //     var end_dd = String(new Date(this.state.txtCustomEndDate).getDate()).padStart(2, '0');
        //     var end_mm = String(new Date(this.state.txtCustomEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        //     var end_yyyy = new Date(this.state.txtCustomEndDate).getFullYear();
        //     let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
        //     this.setState({
        //         customStartDate: startDate,
        //         customEndDate: endDate,
        //         getCustomMeetingdata:true
        //     })
        // }
        // GotoLeadEntry=(lead_id)=>{

        //     AppUtils.HandlePageClick(this.props.componentprops, 'lead-entry/'+lead_id);
        // }
        GetMeetingCustomData=()=>{
            var dd = String(new Date(this.state.txtCustomMeetingStartDate).getDate()).padStart(2, '0');
            var mm = String(new Date(this.state.txtCustomMeetingStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = new Date(this.state.txtCustomMeetingStartDate).getFullYear();
            let startDate = yyyy + '-' + mm + '-' + dd;
            var end_dd = String(new Date(this.state.txtCustomMeetingEndDate).getDate()).padStart(2, '0');
            var end_mm = String(new Date(this.state.txtCustomMeetingEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
            var end_yyyy = new Date(this.state.txtCustomMeetingEndDate).getFullYear();
            let endDate = end_yyyy + '-' + end_mm + '-' + end_dd;
            this.setState({
                meetingWeekStartDate:startDate,
                meetingWeekEndDate:endDate,
                getMeetingData:true
            })
           
        }
        HandleGetMeetingDatatResponse=(res)=>{
            // console.log("HandleGetMeetingDatatResponse",res.data)
            let meetingArray=[]
            if(res.data && res.data.adminfirstmonthdata && res.data.adminfirstmonthdata.rows && res.data.adminfirstmonthdata.rows.length>0){
                res.data.adminfirstmonthdata.rows.forEach(element=>{
                    if(element.lead_current_stage==="HC Booked" && element.hc_booked_date_time!==undefined && element.hc_booked_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate
                    ||
                    element.lead_current_stage==="Project Meeting" && element.project_meeting_date_time!==undefined && element.project_meeting_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate
                    ){
                        console.log("",element.hc_booked_date_time,element.project_meeting_date_time)
                        if(element.lead_current_stage==="HC Booked" && element.hc_booked_date_time !==undefined && element.hc_booked_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.hc_booked_date_time >= this.state.meetingWeekStartDate){
                            let typeIndex = meetingArray.findIndex(type => element.hc_booked_date_time.substr(0,10) ===type.date_time);
                            if (typeIndex !== -1) {
                                let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                                if (valIndex === -1) {
                                    meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type:'HC',user_details:[{client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city}] });
                                }
                                else{
                                    meetingArray[typeIndex].list[valIndex].user_details.push({client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city})
                                }
                            }
                            else{
                                meetingArray.push({'date_time': element.hc_booked_date_time.substr(0,10),list:[{'lead_owner': element.lead_owner, type:'HC',user_details:[{client_name:element.user_name,'time':element.hc_booked_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city}]}]});
                            }
                        }
                        if( element.lead_current_stage==="Project Meeting" && element.project_meeting_date_time !==undefined && element.project_meeting_date_time<= this.state.meetingWeekEndDate+' 23:59:59' && element.project_meeting_date_time >= this.state.meetingWeekStartDate){
                            let typeIndex = meetingArray.findIndex(type => element.project_meeting_date_time.substr(0,10) ===type.date_time);
                            if (typeIndex !== -1) {
                                let valIndex = meetingArray[typeIndex].list.findIndex(item => item.lead_owner === element.lead_owner)
                                if (valIndex === -1) {
                                    meetingArray[typeIndex].list.push({ 'lead_owner': element.lead_owner, type:'PM',user_details:[{client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city}] });
                                }
                                else{
                                    meetingArray[typeIndex].list[valIndex].user_details.push({client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city})
                                }
                            }
                            else{
                                meetingArray.push({'date_time': element.project_meeting_date_time.substr(0,10),list:[{'lead_owner': element.lead_owner, type:'PM',user_details:[{client_name:element.user_name,'time':element.project_meeting_date_time.substr(11,16),'area':element.preferred_area,'city':element.lead_city}]}]});
                            }
                        }
                   
                    }
                })
            }
            console.log("meetingArray",meetingArray)
            meetingArray.sort((a,b)=> new Date(b.date_time).getTime()-new Date(a.date_time).getTime());
            
            this.setState({
                getMeetingData:false,
                reportData:meetingArray
            })
        }
    render() {

        return (
            <Container fluid>
                <div className="tele-caller-db-head">Telecalling Dashboard</div>
                <Options mystate={this.state} {...this.props}
                    HandleUserInput={this.HandleUserInput}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    ChangeViewReport={this.ChangeViewReport}
                    ChangeSalesReportTypeMobile={this.ChangeSalesReportTypeMobile}
                    HandleHCSchedule={this.HandleHCSchedule}
                    callBackReportType={this.callBackReportType}
                    HandleMeetingType={this.HandleMeetingType}
                />
                {this.state.currentViewType === "default" || this.state.currentViewType === "details" || this.state.currentViewType ==='followupreport' ?
                <Report mystate={this.state} {...this.props}
                    salesReportType={this.salesReportType}
                    ChangeSalesReportType={this.ChangeSalesReportType}
                    HandleChangeDate={this.HandleChangeDate}
                    ShowSalesInsideModal={this.ShowSalesInsideModal}
                    GetCustomData={this.GetCustomData}
                    GotoLeadEntry={this.GotoLeadEntry}
                />:null}
                {this.state.showCustomDateModal === true ?
                    <MobCustomDatePicker
                        visible={this.state.showCustomDateModal}
                        mystate={this.state}
                        {...this.props}
                        HandleUserInput={this.HandleUserInput}
                        HideCustomDateModal={this.HideCustomDateModal}
                        GetCustomData={this.GetCustomData}
                    /> : null
                }
  {this.state.currentViewType === "callback" ?
                        <CallbackReport 
                        mystate={this.state} {...this.props}
                        callBackReportType={this.callBackReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        GotoLeadEntry={this.GotoLeadEntry}
                        />
                        :null
                }
                {this.state.currentViewType === "patch-report" ?
                        <PatchReport 
                        mystate={this.state} {...this.props}
                        callBackReportType={this.callBackReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        salesReportType={this.salesReportType}
                        />
                        :null
                }
                {this.state.currentViewType === "daily-report" ?
                        <DailyReport 
                        mystate={this.state} {...this.props}
                        callBackReportType={this.callBackReportType}
                        ChangeSalesReportType={this.ChangeSalesReportType}
                        HandleChangeDate={this.HandleChangeDate}
                        ShowSalesInsideModal={this.ShowSalesInsideModal}
                        GetCustomData={this.GetCustomData}
                        salesReportType={this.salesReportType}
                        />
                        :null
                }
                 {this.state.currentViewType === "meeting-tracker" ?
                <MeetingTracker
                 mystate={this.state} {...this.props}
                 GetMeetingCustomData={this.GetMeetingCustomData}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                />:null}
                 {this.state.currentViewType === "under-construction" ?
                        <UnderConstructionComp 
                        mystate={this.state} {...this.props}
                 salesReportType={this.salesReportType}
                 ChangeSalesReportType={this.ChangeSalesReportType}
                 HandleChangeDate={this.HandleChangeDate}
                 ShowSalesInsideModal={this.ShowSalesInsideModal}
                 GetCustomData={this.GetCustomData}
                        GotoLeadEntry={this.GotoLeadEntry}
                        />
                        :null
                }
                
                {this.state.getUser === true ? GetUserList(this.props.PnkStore, 0, 0, this.HandleGetUserListResponse) : ''}
                {(this.state.getReportData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate, this.HandleGetReportResponse) : ''}
                {(this.state.getCustomData === true) ? AdminSalesReport(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomDatatResponse) : ''}
                {((this.props.PnkStore.GetData("citylist") === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {(this.state.getCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'),this.state.startDate,this.state.endDate,this.HandleGetCallbackReportResponse) : ''}
                {((this.state.getAllCalls === true) ? AllCallList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.startDate, this.state.endDate,this.HandleGetAllCallsListSuccessResponse) : "")}
                {((this.state.getCustomCallList === true) ? AllCallList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate,this.HandleGetCustomAllCallsListSuccessResponse) : "")}
                {(this.state.getMeetingdata === true) ? AdminSalesReport(this.props.PnkStore,'Administrator' ,'prabhash', this.state.startDate, this.state.endDate, this.HandleGetMeetingReportResponse) : ''}
                {(this.state.getCustomMeetingdata === true) ? AdminSalesReport(this.props.PnkStore,'Administrator' ,'prabhash', this.state.customStartDate, this.state.customEndDate, this.HandleGetCustomMeetingReportResponse) : ''}
                {/*   {((this.props.PnkStore.GetData("citylist") === {} || this.state.getCity === true) ? GetCityList(this.props.PnkStore, this.HandleGetCityListSuccessResponse) : "")}
                {(this.state.getProfileData === true) ? GetUserProfileList(this.props.PnkStore, 1, '', this.HandleProfileGetResponse) : ''}
               

                {((this.state.getLeadData === true) ? GetLeadSource(this.props.PnkStore, this.HandleGetLeadSourceSuccessResponse) : "")} */}
                {(this.state.getCustomCallbackData === true) ? CallBackList(this.props.PnkStore, sessionStorage.getItem('current_user_profile'), sessionStorage.getItem('current_user_name'), this.state.customStartDate, this.state.customEndDate, this.HandleGetCallbackReportResponse) : ''}
                {(this.state.getMeetingData === true) ? AdminSalesReport(this.props.PnkStore,'Administrator', sessionStorage.getItem('current_user_name'), this.state.meetingWeekStartDate, this.state.meetingWeekEndDate, this.HandleGetMeetingDatatResponse) : ''}
            </Container>
        )
    }
}
export const TelecallingReportPage = PnkConnect(reportPage, "");